import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useCallback, useRef, useState } from "react";
import {
  BlockModel_blockModel,
  BlockModel_blockModel_regularImages,
  ImageFace,
} from "../../data/graphQLModel";
import CropperComponent from "../../lib/Cropper/CropperComponent";
import { CropperRef, CropperState } from "../../lib/Cropper/types";
import FlexBox from "../GeneralComponents/FlexBox";
import LoadingFitCenterRelative from "../GeneralComponents/LoadingFitCenterRelative";
import { H2 } from "../GeneralComponents/Typography";
import Loading from "../LoadingFullPage/loading";

export interface BlockFaceCropper {
  image: BlockModel_blockModel_regularImages;
  DialogProps?: Partial<DialogProps>;
  dialogTitle?: string;
  onCancel: () => void;
  open: boolean;
  block: BlockModel_blockModel;
  onConfirm: (file: string, face: ImageFace) => void;
}

const getBase64Image = async (blob: Blob): Promise<string | ArrayBuffer | null> => {
  const reader = new FileReader();
  await new Promise((resolve, reject) => {
    reader.onload = resolve;
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
  return reader.result;
};

// const mapImageFacesToEnum = {
//   [ImageFace.FRONT]: "frontFaceImage",
//   [ImageFace.BACK]: "backFaceImage",
//   [ImageFace.TOP]: "topFaceImage",
//   [ImageFace.LEFT]: "leftFaceImage",
//   [ImageFace.RIGHT]: "rightFaceImage",
//   [ImageFace.SINGLE]: "", // nothing
// };

const BlockFaceCropper: React.FC<BlockFaceCropper> = ({
  image,
  DialogProps,
  dialogTitle = "Cropping Block Face",
  onCancel,
  open,
  block,
  onConfirm,
}) => {
  const [cropState, setCropState] = useState<CropperState>();
  const cropperRef = useRef<CropperRef>();

  const onDragStop = useCallback((s: CropperState) => setCropState(s), []);
  const onChange = useCallback((s: CropperState) => setCropState(s), []);
  const [onPreview, setOnPreview] = useState<boolean>(false);

  const [croppedFile, setCroppedFile] = useState<Blob>();

  // const onUpload = async (): Promise<void> => {
  //   const variables: UpdateOneBlockStreamVariables = {
  //     update: {
  //       id: block.id,
  //       update: {},
  //     },
  //     [mapImageFacesToEnum[image.imageFace]]: !!croppedFile
  //       ? new File([croppedFile], "name")
  //       : null,
  //   };

  //   await uploadApolloClient
  //     .mutate<UpdateOneBlockStream, UpdateOneBlockStreamVariables>({
  //       mutation: MUTATION_UPDATE_ONE_BLOCK_STREAM,
  //       variables,
  //     })
  //     .then((res) => {
  //       swalert("Success", "Commercial Variety updated", "success");
  //     })
  //     .catch((err: ApolloError) => {
  //       let errorString =
  //         err.message +
  //         "; " +
  //         err.clientErrors.map((el) => el.message).join("; ") +
  //         err.graphQLErrors.map((el) => el.message).join("; ");
  //       swalert("Failed.", errorString, "error");
  //     });
  // };

  const handleDone = async () => {
    try {
      if (!!cropperRef?.current) {
        if (!!!croppedFile) {
          const blob = await cropperRef.current.done({
            preview: true,
            filterCvParams: {
              blur: false,
              grayScale: false,
              th: false,
              thBlockSize: undefined,
              thMax: undefined,
              thMeanCorrection: undefined,
              thMode: undefined,
            },
          });
          setCroppedFile(blob);
          setOnPreview(true);
        } else {
          const base64String = await getBase64Image(croppedFile);
          setCroppedFile(undefined);
          onConfirm(base64String as string, image.imageFace);
          setOnPreview(false);
        }
      } else {
        console.log("No Ref");
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleBackToCrop = () => {
    if (!!croppedFile) {
      setCroppedFile(undefined);
      cropperRef?.current?.backToCrop();
      setOnPreview(false);
    }
  };

  return (
    <Dialog
      {...DialogProps}
      open={open}
      maxWidth={"xl"}
      fullScreen
      fullWidth
      onClose={(e) => {
        setCroppedFile(undefined);
        onCancel();
      }}
    >
      <DialogTitle
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          pl: 2.5,
          pr: 2.5,
          zIndex: 101,
        }}
      >
        <H2>{dialogTitle}</H2>
        <FlexBox>
          <IconButton onClick={() => onCancel()}>
            <Close className="close" fontSize="medium" color="primary" />
          </IconButton>
        </FlexBox>
      </DialogTitle>
      <DialogContent>
        <FlexBox pt={2}>
          {cropState?.loading && <Loading />}
          <CropperComponent
            ref={cropperRef}
            image={image.original}
            onChange={onChange}
            onDragStop={onDragStop}
          />
        </FlexBox>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {!!croppedFile && (
          <Button
            sx={{ mr: 3 }}
            size="large"
            variant="contained"
            color="secondary"
            onClick={handleBackToCrop}
          >
            Undo
          </Button>
        )}
        <Button
          sx={{ mr: 3 }}
          size="large"
          variant="contained"
          color="primary"
          onClick={handleDone}
        >
          {onPreview ? "Done" : "Crop"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BlockFaceCropper;
