import api from "./api";
// import servicesFabricator from "./auth.Services";

class SlabsServices {
  getLastSlabs = async (scan) => {
    const queryslabs = `
        query{  
            lastslabs( scan: "${scan}") {
                id
                width
                height
                thickness
                createdate
                images
                area
            }
        }
    `;

    return new Promise((resolve, reject) => {
      api(queryslabs)
        .then((response) => {
          console.log(response);
          if (response) {
            resolve(response.lastslabs);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const slabsServices = new SlabsServices();

export default slabsServices;
