/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { weScanDateTime, weScanConnectionCursor, weScanUpload } from "../utils/Types";

// ====================================================
// GraphQL query operation: ShareLinks
// ====================================================

export interface ShareLinks_shareLinks_edges_node_package {
  id: string;
}

export interface ShareLinks_shareLinks_edges_node_project {
  id: string;
}

export interface ShareLinks_shareLinks_edges_node_block {
  id: string;
}

export interface ShareLinks_shareLinks_edges_node_slab {
  id: string | null;
}

export interface ShareLinks_shareLinks_edges_node {
  id: string;
  isValid: boolean;
  package: ShareLinks_shareLinks_edges_node_package | null;
  project: ShareLinks_shareLinks_edges_node_project | null;
  validUntil: weScanDateTime;
  keyValue: string;
  block: ShareLinks_shareLinks_edges_node_block | null;
  slab: ShareLinks_shareLinks_edges_node_slab | null;
}

export interface ShareLinks_shareLinks_edges {
  /**
   * The node containing the ShareLink
   */
  node: ShareLinks_shareLinks_edges_node;
}

export interface ShareLinks_shareLinks {
  /**
   * Array of edges.
   */
  edges: ShareLinks_shareLinks_edges[];
}

export interface ShareLinks {
  shareLinks: ShareLinks_shareLinks;
}

export interface ShareLinksVariables {
  filter?: ShareLinkFilter | null;
  paging?: CursorPaging | null;
  sorting?: ShareLinkSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Clients
// ====================================================

export interface Clients_clients_edges_node {
  id: string;
  name: string;
  code: string;
  provider: IAuthenticationProviders;
  lastName: string | null;
}

export interface Clients_clients_edges {
  /**
   * The node containing the Client
   */
  node: Clients_clients_edges_node;
}

export interface Clients_clients {
  /**
   * Array of edges.
   */
  edges: Clients_clients_edges[];
}

export interface Clients {
  clients: Clients_clients;
}

export interface ClientsVariables {
  filter?: ClientFilter | null;
  paging?: CursorPaging | null;
  sorting?: ClientSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: User
// ====================================================

export interface User_user_company {
  id: string;
  code: string;
  name: string;
}

export interface User_user {
  avatar: string | null;
  email: string;
  id: string;
  status: boolean | null;
  role: IRolesEnum | null;
  firstname: string | null;
  lastname: string | null;
  username: string;
  name: string | null;
  company: User_user_company | null;
}

export interface User {
  user: User_user | null;
}

export interface UserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EnvironmentalFactors
// ====================================================

export interface EnvironmentalFactors_environmentalFactors_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface EnvironmentalFactors_environmentalFactors_edges_node {
  application_type: ApplicationTypology;
  description: string | null;
  id: string | null;
  level: number;
  measurement: string;
  name: string;
  range_start: number | null;
  range_end: number | null;
}

export interface EnvironmentalFactors_environmentalFactors_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the EnvironmentalFactor
   */
  node: EnvironmentalFactors_environmentalFactors_edges_node;
}

export interface EnvironmentalFactors_environmentalFactors {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Paging information
   */
  pageInfo: EnvironmentalFactors_environmentalFactors_pageInfo;
  /**
   * Array of edges.
   */
  edges: EnvironmentalFactors_environmentalFactors_edges[];
}

export interface EnvironmentalFactors {
  environmentalFactors: EnvironmentalFactors_environmentalFactors;
}

export interface EnvironmentalFactorsVariables {
  filter?: EnvironmentalFactorFilter | null;
  paging?: CursorPaging | null;
  sorting?: EnvironmentalFactorSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ApplicationTypes
// ====================================================

export interface ApplicationTypes_applicationTypes_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface ApplicationTypes_applicationTypes_edges_node_application_site {
  createdAt: weScanDateTime;
  id: string | null;
  name: string;
  updatedAt: weScanDateTime | null;
}

export interface ApplicationTypes_applicationTypes_edges_node_development_type {
  createdAt: weScanDateTime;
  id: string | null;
  name: string;
  updatedAt: weScanDateTime | null;
}

export interface ApplicationTypes_applicationTypes_edges_node_description {
  createdAt: weScanDateTime;
  id: string | null;
  updatedAt: weScanDateTime | null;
  description: string | null;
  details: string;
  code: string;
}

export interface ApplicationTypes_applicationTypes_edges_node {
  application_site: ApplicationTypes_applicationTypes_edges_node_application_site | null;
  application_typology: ApplicationTypology;
  development_type: ApplicationTypes_applicationTypes_edges_node_development_type;
  description: ApplicationTypes_applicationTypes_edges_node_description | null;
  createdAt: weScanDateTime;
  updatedAt: weScanDateTime | null;
  id: string | null;
}

export interface ApplicationTypes_applicationTypes_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the ApplicationType
   */
  node: ApplicationTypes_applicationTypes_edges_node;
}

export interface ApplicationTypes_applicationTypes {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Paging information
   */
  pageInfo: ApplicationTypes_applicationTypes_pageInfo;
  /**
   * Array of edges.
   */
  edges: ApplicationTypes_applicationTypes_edges[];
}

export interface ApplicationTypes {
  applicationTypes: ApplicationTypes_applicationTypes;
}

export interface ApplicationTypesVariables {
  filter?: ApplicationTypeFilter | null;
  paging?: CursorPaging | null;
  sorting?: ApplicationTypeSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StoneLocations
// ====================================================

export interface StoneLocations_stoneLocations_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface StoneLocations_stoneLocations_edges_node {
  coast_line: boolean;
  country: string;
  country_side: boolean;
  industrialization: boolean;
  mountain: boolean;
  name: string;
  urbanization: boolean;
  createdAt: weScanDateTime;
  updatedAt: weScanDateTime | null;
  id: string | null;
}

export interface StoneLocations_stoneLocations_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the StoneLocation
   */
  node: StoneLocations_stoneLocations_edges_node;
}

export interface StoneLocations_stoneLocations {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Paging information
   */
  pageInfo: StoneLocations_stoneLocations_pageInfo;
  /**
   * Array of edges.
   */
  edges: StoneLocations_stoneLocations_edges[];
}

export interface StoneLocations {
  stoneLocations: StoneLocations_stoneLocations;
}

export interface StoneLocationsVariables {
  filter?: StoneLocationFilter | null;
  paging?: CursorPaging | null;
  sorting?: StoneLocationSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: QueryColors
// ====================================================

export interface QueryColors_colors_edges_node {
  code: string;
  color: string;
  id: string;
}

export interface QueryColors_colors_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Color
   */
  node: QueryColors_colors_edges_node;
}

export interface QueryColors_colors {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: QueryColors_colors_edges[];
}

export interface QueryColors {
  colors: QueryColors_colors;
}

export interface QueryColorsVariables {
  filter?: ColorFilter | null;
  paging?: CursorPaging | null;
  sorting?: ColorSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TwinStones
// ====================================================

export interface TwinStones_twinStones_company {
  id: string;
  code: string;
  name: string;
  contact: string;
}

export interface TwinStones_twinStones_slab_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface TwinStones_twinStones_slab_variety {
  normativedesig: string;
}

export interface TwinStones_twinStones_slab {
  id: string | null;
  code: string;
  width: number | null;
  area: number | null;
  height: number | null;
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: TwinStones_twinStones_slab_slabImages[];
  inStock: boolean;
  createdate: weScanDateTime | null;
  createdAt: weScanDateTime | null;
  variety: TwinStones_twinStones_slab_variety | null;
}

export interface TwinStones_twinStones {
  company: TwinStones_twinStones_company | null;
  createdAt: weScanDateTime | null;
  id: string;
  original: string;
  slab: TwinStones_twinStones_slab | null;
  thumbnail: string;
}

export interface TwinStones {
  twinStones: TwinStones_twinStones[];
}

export interface TwinStonesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Slab
// ====================================================

export interface Slab_slab_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface Slab_slab_typematerial_description {
  pt: string;
}

export interface Slab_slab_typematerial_companyNames {
  code: string;
  description: string;
}

export interface Slab_slab_typematerial {
  id: string;
  code: string;
  description: Slab_slab_typematerial_description;
  companyNames: Slab_slab_typematerial_companyNames[] | null;
}

export interface Slab_slab_category_description {
  pt: string;
}

export interface Slab_slab_category_companyNames {
  code: string;
  description: string;
}

export interface Slab_slab_category {
  id: string;
  code: string;
  description: Slab_slab_category_description;
  companyNames: Slab_slab_category_companyNames[] | null;
}

export interface Slab_slab_finish_description {
  pt: string;
}

export interface Slab_slab_finish {
  id: string;
  description: Slab_slab_finish_description;
  code: string;
}

export interface Slab_slab_project {
  id: string;
  project: string;
}

export interface Slab_slab_variety {
  id: string;
  normativedesig: string;
  macroscopicdesc: string | null;
}

export interface Slab_slab_scan {
  code: string;
  description: string;
}

export interface Slab_slab_job {
  description: string | null;
}

export interface Slab_slab_packing {
  id: string;
  code: string;
  packing: string;
}

export interface Slab_slab_company {
  code: string;
  name: string;
}

export interface Slab_slab_commercial_variety_materialtype_description {
  pt: string;
}

export interface Slab_slab_commercial_variety_materialtype {
  code: string;
  description: Slab_slab_commercial_variety_materialtype_description;
}

export interface Slab_slab_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: Slab_slab_commercial_variety_materialtype | null;
}

export interface Slab_slab_commercial_finishing_description {
  pt: string;
}

export interface Slab_slab_commercial_finishing_observation {
  pt: string;
}

export interface Slab_slab_commercial_finishing {
  code: string;
  description: Slab_slab_commercial_finishing_description;
  id: string;
  observation: Slab_slab_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface Slab_slab_stone_edge {
  code: string;
  id: string;
  name: string;
  description: string;
}

export interface Slab_slab_stone_model {
  code: string;
  id: string;
  name: string;
  description: string;
}

export interface Slab_slab {
  main_image: string | null;
  id: string | null;
  code: string;
  name: string;
  area: number | null;
  description: string | null;
  desigcomercial: string | null;
  width: number | null;
  height: number | null;
  thickness: number | null;
  extwidth: number | null;
  blocknumber: string | null;
  extheight: number | null;
  extthickness: number | null;
  stkwidth: number | null;
  stkheight: number | null;
  stkthickness: number | null;
  purchasewidth: number | null;
  purchaseheight: number | null;
  purchasethickness: number | null;
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: Slab_slab_slabImages[];
  iscatalogued: boolean;
  typematerial: Slab_slab_typematerial | null;
  category: Slab_slab_category | null;
  finish: Slab_slab_finish | null;
  project: Slab_slab_project | null;
  variety: Slab_slab_variety | null;
  observation: string | null;
  createdate: weScanDateTime | null;
  scan: Slab_slab_scan | null;
  job: Slab_slab_job | null;
  packing: Slab_slab_packing | null;
  inStock: boolean;
  company: Slab_slab_company | null;
  commercial_variety: Slab_slab_commercial_variety | null;
  commercial_finishing: Slab_slab_commercial_finishing | null;
  stone_edge: Slab_slab_stone_edge | null;
  stone_model: Slab_slab_stone_model | null;
}

export interface Slab {
  slab: Slab_slab | null;
}

export interface SlabVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: blobMetadata
// ====================================================

export interface blobMetadata_blobMetadata {
  metadata: string;
}

export interface blobMetadata {
  blobMetadata: blobMetadata_blobMetadata;
}

export interface blobMetadataVariables {
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Slabs
// ====================================================

export interface Slabs_slabs_pageInfo {
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
}

export interface Slabs_slabs_nodes_block {
  id: string;
  wescanCode: string;
  tenantCode: string;
}

export interface Slabs_slabs_nodes_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface Slabs_slabs_nodes_typematerial_description {
  pt: string;
}

export interface Slabs_slabs_nodes_typematerial {
  id: string;
  description: Slabs_slabs_nodes_typematerial_description;
}

export interface Slabs_slabs_nodes_category_description {
  pt: string;
}

export interface Slabs_slabs_nodes_category {
  id: string;
  description: Slabs_slabs_nodes_category_description;
}

export interface Slabs_slabs_nodes_finish_description {
  pt: string;
}

export interface Slabs_slabs_nodes_finish {
  id: string;
  description: Slabs_slabs_nodes_finish_description;
}

export interface Slabs_slabs_nodes_project {
  id: string;
  project: string;
}

export interface Slabs_slabs_nodes_variety {
  id: string;
  normativedesig: string;
  macroscopicdesc: string | null;
}

export interface Slabs_slabs_nodes_scan {
  code: string;
  description: string;
}

export interface Slabs_slabs_nodes_job {
  description: string | null;
}

export interface Slabs_slabs_nodes_packing {
  id: string;
  code: string;
  packing: string;
}

export interface Slabs_slabs_nodes_company {
  code: string;
  name: string;
}

export interface Slabs_slabs_nodes_commercial_variety_materialtype_description {
  pt: string;
}

export interface Slabs_slabs_nodes_commercial_variety_materialtype {
  code: string;
  description: Slabs_slabs_nodes_commercial_variety_materialtype_description;
}

export interface Slabs_slabs_nodes_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: Slabs_slabs_nodes_commercial_variety_materialtype | null;
}

export interface Slabs_slabs_nodes_commercial_finishing_description {
  pt: string;
}

export interface Slabs_slabs_nodes_commercial_finishing_observation {
  pt: string;
}

export interface Slabs_slabs_nodes_commercial_finishing {
  code: string;
  description: Slabs_slabs_nodes_commercial_finishing_description;
  id: string;
  observation: Slabs_slabs_nodes_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface Slabs_slabs_nodes_stone_edge {
  code: string;
  id: string;
  name: string;
  description: string;
}

export interface Slabs_slabs_nodes_stone_model {
  code: string;
  id: string;
  name: string;
  description: string;
}

export interface Slabs_slabs_nodes {
  iscatalogued: boolean;
  main_image: string | null;
  erp_id: string | null;
  id: string | null;
  code: string;
  name: string;
  area: number | null;
  description: string | null;
  desigcomercial: string | null;
  width: number | null;
  height: number | null;
  thickness: number | null;
  extwidth: number | null;
  blocknumber: string | null;
  block: Slabs_slabs_nodes_block | null;
  extheight: number | null;
  extthickness: number | null;
  stkwidth: number | null;
  stkheight: number | null;
  stkthickness: number | null;
  purchasewidth: number | null;
  purchaseheight: number | null;
  purchasethickness: number | null;
  cancelCataloguing: boolean;
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: Slabs_slabs_nodes_slabImages[];
  typematerial: Slabs_slabs_nodes_typematerial | null;
  category: Slabs_slabs_nodes_category | null;
  finish: Slabs_slabs_nodes_finish | null;
  project: Slabs_slabs_nodes_project | null;
  variety: Slabs_slabs_nodes_variety | null;
  observation: string | null;
  createdate: weScanDateTime | null;
  scan: Slabs_slabs_nodes_scan | null;
  job: Slabs_slabs_nodes_job | null;
  packing: Slabs_slabs_nodes_packing | null;
  inStock: boolean;
  company: Slabs_slabs_nodes_company | null;
  commercial_variety: Slabs_slabs_nodes_commercial_variety | null;
  commercial_finishing: Slabs_slabs_nodes_commercial_finishing | null;
  stone_edge: Slabs_slabs_nodes_stone_edge | null;
  stone_model: Slabs_slabs_nodes_stone_model | null;
}

export interface Slabs_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Paging information
   */
  pageInfo: Slabs_slabs_pageInfo;
  /**
   * Array of nodes.
   */
  nodes: Slabs_slabs_nodes[];
}

export interface Slabs {
  slabs: Slabs_slabs;
}

export interface SlabsVariables {
  filter?: SlabFilter | null;
  paging?: OffsetPaging | null;
  sorting?: SlabSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedSlabs
// ====================================================

export interface SharedSlabs_sharedSlabs_pageInfo {
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
}

export interface SharedSlabs_sharedSlabs_nodes_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SharedSlabs_sharedSlabs_nodes_company {
  code: string;
  name: string;
}

export interface SharedSlabs_sharedSlabs_nodes_commercial_variety_materialtype_description {
  pt: string;
}

export interface SharedSlabs_sharedSlabs_nodes_commercial_variety_materialtype {
  code: string;
  description: SharedSlabs_sharedSlabs_nodes_commercial_variety_materialtype_description;
}

export interface SharedSlabs_sharedSlabs_nodes_commercial_variety_annexedDocuments_nodes {
  /**
   * File URL
   */
  blob_url: string;
  id: string;
  /**
   * File Mime Type
   */
  mimetype: string;
  /**
   * Document name
   */
  name: string;
}

export interface SharedSlabs_sharedSlabs_nodes_commercial_variety_annexedDocuments {
  /**
   * Array of nodes.
   */
  nodes: SharedSlabs_sharedSlabs_nodes_commercial_variety_annexedDocuments_nodes[];
}

export interface SharedSlabs_sharedSlabs_nodes_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: SharedSlabs_sharedSlabs_nodes_commercial_variety_materialtype | null;
  annexedDocuments: SharedSlabs_sharedSlabs_nodes_commercial_variety_annexedDocuments | null;
}

export interface SharedSlabs_sharedSlabs_nodes_commercial_finishing_description {
  pt: string;
}

export interface SharedSlabs_sharedSlabs_nodes_commercial_finishing_observation {
  pt: string;
}

export interface SharedSlabs_sharedSlabs_nodes_commercial_finishing {
  code: string;
  description: SharedSlabs_sharedSlabs_nodes_commercial_finishing_description;
  id: string;
  observation: SharedSlabs_sharedSlabs_nodes_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface SharedSlabs_sharedSlabs_nodes_typematerial_description {
  pt: string;
}

export interface SharedSlabs_sharedSlabs_nodes_typematerial {
  description: SharedSlabs_sharedSlabs_nodes_typematerial_description;
}

export interface SharedSlabs_sharedSlabs_nodes_category_description {
  pt: string;
}

export interface SharedSlabs_sharedSlabs_nodes_category {
  description: SharedSlabs_sharedSlabs_nodes_category_description;
}

export interface SharedSlabs_sharedSlabs_nodes_finish_description {
  pt: string;
}

export interface SharedSlabs_sharedSlabs_nodes_finish {
  description: SharedSlabs_sharedSlabs_nodes_finish_description;
}

export interface SharedSlabs_sharedSlabs_nodes_variety {
  normativedesig: string;
}

export interface SharedSlabs_sharedSlabs_nodes {
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SharedSlabs_sharedSlabs_nodes_slabImages[];
  id: string | null;
  code: string;
  name: string;
  images: string[];
  width: number | null;
  height: number | null;
  thickness: number | null;
  createdate: weScanDateTime | null;
  blocknumber: string | null;
  inStock: boolean;
  company: SharedSlabs_sharedSlabs_nodes_company | null;
  commercial_variety: SharedSlabs_sharedSlabs_nodes_commercial_variety | null;
  commercial_finishing: SharedSlabs_sharedSlabs_nodes_commercial_finishing | null;
  typematerial: SharedSlabs_sharedSlabs_nodes_typematerial | null;
  category: SharedSlabs_sharedSlabs_nodes_category | null;
  finish: SharedSlabs_sharedSlabs_nodes_finish | null;
  variety: SharedSlabs_sharedSlabs_nodes_variety | null;
}

export interface SharedSlabs_sharedSlabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Paging information
   */
  pageInfo: SharedSlabs_sharedSlabs_pageInfo;
  /**
   * Array of nodes.
   */
  nodes: SharedSlabs_sharedSlabs_nodes[];
}

export interface SharedSlabs {
  sharedSlabs: SharedSlabs_sharedSlabs;
}

export interface SharedSlabsVariables {
  filter?: SlabFilter | null;
  paging?: OffsetPaging | null;
  sorting?: SlabSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedSlab
// ====================================================

export interface SharedSlab_sharedSlab_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SharedSlab_sharedSlab_typematerial_description {
  pt: string;
}

export interface SharedSlab_sharedSlab_typematerial_companyNames {
  code: string;
  description: string;
}

export interface SharedSlab_sharedSlab_typematerial {
  id: string;
  code: string;
  description: SharedSlab_sharedSlab_typematerial_description;
  companyNames: SharedSlab_sharedSlab_typematerial_companyNames[] | null;
}

export interface SharedSlab_sharedSlab_category_description {
  pt: string;
}

export interface SharedSlab_sharedSlab_category_companyNames {
  code: string;
  description: string;
}

export interface SharedSlab_sharedSlab_category {
  id: string;
  code: string;
  description: SharedSlab_sharedSlab_category_description;
  companyNames: SharedSlab_sharedSlab_category_companyNames[] | null;
}

export interface SharedSlab_sharedSlab_finish_description {
  pt: string;
}

export interface SharedSlab_sharedSlab_finish {
  id: string;
  description: SharedSlab_sharedSlab_finish_description;
  code: string;
}

export interface SharedSlab_sharedSlab_project {
  id: string;
  project: string;
}

export interface SharedSlab_sharedSlab_variety {
  id: string;
  normativedesig: string;
  macroscopicdesc: string | null;
}

export interface SharedSlab_sharedSlab_scan {
  code: string;
  description: string;
}

export interface SharedSlab_sharedSlab_job {
  description: string | null;
}

export interface SharedSlab_sharedSlab_packing {
  id: string;
  code: string;
  packing: string;
}

export interface SharedSlab_sharedSlab_company {
  code: string;
  name: string;
}

export interface SharedSlab_sharedSlab_commercial_variety_materialtype_description {
  pt: string;
}

export interface SharedSlab_sharedSlab_commercial_variety_materialtype {
  code: string;
  description: SharedSlab_sharedSlab_commercial_variety_materialtype_description;
}

export interface SharedSlab_sharedSlab_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: SharedSlab_sharedSlab_commercial_variety_materialtype | null;
}

export interface SharedSlab_sharedSlab_commercial_finishing_description {
  pt: string;
}

export interface SharedSlab_sharedSlab_commercial_finishing_observation {
  pt: string;
}

export interface SharedSlab_sharedSlab_commercial_finishing {
  code: string;
  description: SharedSlab_sharedSlab_commercial_finishing_description;
  id: string;
  observation: SharedSlab_sharedSlab_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface SharedSlab_sharedSlab_stone_edge {
  code: string;
  id: string;
  name: string;
  description: string;
}

export interface SharedSlab_sharedSlab_stone_model {
  code: string;
  id: string;
  name: string;
  description: string;
}

export interface SharedSlab_sharedSlab {
  main_image: string | null;
  id: string | null;
  code: string;
  name: string;
  area: number | null;
  description: string | null;
  desigcomercial: string | null;
  width: number | null;
  height: number | null;
  thickness: number | null;
  extwidth: number | null;
  blocknumber: string | null;
  extheight: number | null;
  extthickness: number | null;
  stkwidth: number | null;
  stkheight: number | null;
  stkthickness: number | null;
  purchasewidth: number | null;
  purchaseheight: number | null;
  purchasethickness: number | null;
  images: string[];
  iscatalogued: boolean;
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SharedSlab_sharedSlab_slabImages[];
  typematerial: SharedSlab_sharedSlab_typematerial | null;
  category: SharedSlab_sharedSlab_category | null;
  finish: SharedSlab_sharedSlab_finish | null;
  project: SharedSlab_sharedSlab_project | null;
  variety: SharedSlab_sharedSlab_variety | null;
  observation: string | null;
  createdate: weScanDateTime | null;
  scan: SharedSlab_sharedSlab_scan | null;
  job: SharedSlab_sharedSlab_job | null;
  packing: SharedSlab_sharedSlab_packing | null;
  inStock: boolean;
  company: SharedSlab_sharedSlab_company | null;
  commercial_variety: SharedSlab_sharedSlab_commercial_variety | null;
  commercial_finishing: SharedSlab_sharedSlab_commercial_finishing | null;
  stone_edge: SharedSlab_sharedSlab_stone_edge | null;
  stone_model: SharedSlab_sharedSlab_stone_model | null;
}

export interface SharedSlab {
  sharedSlab: SharedSlab_sharedSlab;
}

export interface SharedSlabVariables {
  id?: string | null;
  keyValue?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NextSlabs
// ====================================================

export interface NextSlabs_nextSlabs_job_finish_description {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface NextSlabs_nextSlabs_job_finish_observation {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface NextSlabs_nextSlabs_job_finish {
  code: string;
  description: NextSlabs_nextSlabs_job_finish_description;
  observation: NextSlabs_nextSlabs_job_finish_observation | null;
  id: string;
}

export interface NextSlabs_nextSlabs_job_category_companyNames {
  description: string;
  code: string;
}

export interface NextSlabs_nextSlabs_job_category_description {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface NextSlabs_nextSlabs_job_category {
  code: string;
  companyNames: NextSlabs_nextSlabs_job_category_companyNames[] | null;
  description: NextSlabs_nextSlabs_job_category_description;
  id: string;
}

export interface NextSlabs_nextSlabs_job_variety {
  id: string;
  code: string;
  date: weScanDateTime | null;
  absapatn: string | null;
  alternativedesig: string | null;
  observation: string | null;
  normativedesig: string;
  macroscopicdesc: string | null;
}

export interface NextSlabs_nextSlabs_job_company {
  code: string;
  name: string;
  id: string;
}

export interface NextSlabs_nextSlabs_job_jobtype {
  id: string;
  code: string | null;
  description: string | null;
}

export interface NextSlabs_nextSlabs_job_block_regularImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface NextSlabs_nextSlabs_job_block {
  baseArea: number | null;
  id: string;
  extwidth: number | null;
  extlength: number | null;
  extheight: number | null;
  description: string | null;
  tenantCode: string;
  wescanCode: string;
  regularImages: NextSlabs_nextSlabs_job_block_regularImages[] | null;
}

export interface NextSlabs_nextSlabs_job_commercial_finishing_description {
  pt: string;
  es: string | null;
  en: string | null;
}

export interface NextSlabs_nextSlabs_job_commercial_finishing_observation {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface NextSlabs_nextSlabs_job_commercial_finishing {
  code: string;
  description: NextSlabs_nextSlabs_job_commercial_finishing_description;
  id: string;
  observation: NextSlabs_nextSlabs_job_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface NextSlabs_nextSlabs_job_commercial_variety_materialtype_description {
  pt: string;
  es: string | null;
  en: string | null;
}

export interface NextSlabs_nextSlabs_job_commercial_variety_materialtype_companyNames {
  description: string;
  code: string;
}

export interface NextSlabs_nextSlabs_job_commercial_variety_materialtype {
  code: string;
  description: NextSlabs_nextSlabs_job_commercial_variety_materialtype_description;
  id: string;
  companyNames: NextSlabs_nextSlabs_job_commercial_variety_materialtype_companyNames[] | null;
}

export interface NextSlabs_nextSlabs_job_commercial_variety {
  id: string;
  name: string | null;
  code: string;
  description: string | null;
  materialtype: NextSlabs_nextSlabs_job_commercial_variety_materialtype | null;
}

export interface NextSlabs_nextSlabs_job_packing {
  id: string;
  code: string;
  observation: string | null;
  packing: string;
}

export interface NextSlabs_nextSlabs_job_project {
  id: string;
  code: string;
  description: string | null;
  project: string;
}

export interface NextSlabs_nextSlabs_job_typematerial_companyNames {
  description: string;
  company: string;
  code: string;
}

export interface NextSlabs_nextSlabs_job_typematerial_description {
  pt: string;
  es: string | null;
  en: string | null;
}

export interface NextSlabs_nextSlabs_job_typematerial {
  code: string;
  companyNames: NextSlabs_nextSlabs_job_typematerial_companyNames[] | null;
  description: NextSlabs_nextSlabs_job_typematerial_description;
  id: string;
}

export interface NextSlabs_nextSlabs_job {
  /**
   * Prefix of the slabs resulting from this Job.
   */
  prefix: string | null;
  /**
   * Start number for the slabs resulting from this Job.
   */
  startNumber: number | null;
  /**
   * Order of the numbers given to the slabs resulting from this Job.
   */
  numberingOrder: JobNumberingOrder | null;
  /**
   * Separator between the prefix and the numbering of the slabs resulting from this Job.
   */
  separator: string | null;
  sequence: number;
  description: string | null;
  createdAt: weScanDateTime | null;
  createdate: weScanDateTime | null;
  updatedAt: weScanDateTime | null;
  lastupdate: weScanDateTime | null;
  id: string;
  code: string;
  blocknumber: string | null;
  descstate: DescState | null;
  completed: boolean | null;
  finish: NextSlabs_nextSlabs_job_finish | null;
  category: NextSlabs_nextSlabs_job_category | null;
  variety: NextSlabs_nextSlabs_job_variety | null;
  totalslab: number;
  scannedSlabs: number;
  state: boolean | null;
  company: NextSlabs_nextSlabs_job_company | null;
  jobtype: NextSlabs_nextSlabs_job_jobtype | null;
  block: NextSlabs_nextSlabs_job_block | null;
  commercial_finishing: NextSlabs_nextSlabs_job_commercial_finishing | null;
  commercial_variety: NextSlabs_nextSlabs_job_commercial_variety | null;
  packing: NextSlabs_nextSlabs_job_packing | null;
  project: NextSlabs_nextSlabs_job_project | null;
  typematerial: NextSlabs_nextSlabs_job_typematerial | null;
}

export interface NextSlabs_nextSlabs {
  code: string;
  job: NextSlabs_nextSlabs_job | null;
}

export interface NextSlabs {
  /**
   * Query that can be called by regular users to get the next slabs of a Scanner.
   */
  nextSlabs: NextSlabs_nextSlabs[];
}

export interface NextSlabsVariables {
  scanner: string;
  next?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Categories
// ====================================================

export interface Categories_categories_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface Categories_categories_edges_node_description {
  en: string | null;
  pt: string;
  es: string | null;
}

export interface Categories_categories_edges_node_observation {
  en: string | null;
  pt: string;
  es: string | null;
}

export interface Categories_categories_edges_node_companyNames {
  code: string;
  description: string;
}

export interface Categories_categories_edges_node {
  id: string;
  code: string;
  createdAt: weScanDateTime | null;
  updatedAt: weScanDateTime | null;
  date: weScanDateTime | null;
  description: Categories_categories_edges_node_description;
  observation: Categories_categories_edges_node_observation | null;
  isdefault: boolean | null;
  companyNames: Categories_categories_edges_node_companyNames[] | null;
  /**
   * Flags a Category as a result of a Slab Scan
   */
  isResultOfSlabScan: boolean;
}

export interface Categories_categories_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Category
   */
  node: Categories_categories_edges_node;
}

export interface Categories_categories {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Paging information
   */
  pageInfo: Categories_categories_pageInfo;
  /**
   * Array of edges.
   */
  edges: Categories_categories_edges[];
}

export interface Categories {
  categories: Categories_categories;
}

export interface CategoriesVariables {
  filter?: CategoryFilter | null;
  paging?: CursorPaging | null;
  sorting?: CategorySort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MaterialTypes
// ====================================================

export interface MaterialTypes_materialTypes_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface MaterialTypes_materialTypes_edges_node_description {
  en: string | null;
  pt: string;
  es: string | null;
}

export interface MaterialTypes_materialTypes_edges_node_observation {
  en: string | null;
  pt: string;
  es: string | null;
}

export interface MaterialTypes_materialTypes_edges_node_companyNames {
  code: string;
  description: string;
}

export interface MaterialTypes_materialTypes_edges_node {
  id: string;
  code: string;
  createdAt: weScanDateTime | null;
  updatedAt: weScanDateTime | null;
  date: weScanDateTime | null;
  description: MaterialTypes_materialTypes_edges_node_description;
  observation: MaterialTypes_materialTypes_edges_node_observation | null;
  isdefault: boolean | null;
  companyNames: MaterialTypes_materialTypes_edges_node_companyNames[] | null;
}

export interface MaterialTypes_materialTypes_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the MaterialType
   */
  node: MaterialTypes_materialTypes_edges_node;
}

export interface MaterialTypes_materialTypes {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Paging information
   */
  pageInfo: MaterialTypes_materialTypes_pageInfo;
  /**
   * Array of edges.
   */
  edges: MaterialTypes_materialTypes_edges[];
}

export interface MaterialTypes {
  materialTypes: MaterialTypes_materialTypes;
}

export interface MaterialTypesVariables {
  filter?: MaterialTypeFilter | null;
  paging?: CursorPaging | null;
  sorting?: MaterialTypeSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Finishings
// ====================================================

export interface Finishings_finishings_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface Finishings_finishings_edges_node_description {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface Finishings_finishings_edges_node_observation {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface Finishings_finishings_edges_node {
  id: string;
  code: string;
  date: weScanDateTime | null;
  description: Finishings_finishings_edges_node_description;
  isdefault: boolean | null;
  observation: Finishings_finishings_edges_node_observation | null;
}

export interface Finishings_finishings_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Finishing
   */
  node: Finishings_finishings_edges_node;
}

export interface Finishings_finishings {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Paging information
   */
  pageInfo: Finishings_finishings_pageInfo;
  /**
   * Array of edges.
   */
  edges: Finishings_finishings_edges[];
}

export interface Finishings {
  finishings: Finishings_finishings;
}

export interface FinishingsVariables {
  filter?: FinishingFilter | null;
  paging?: CursorPaging | null;
  sorting?: FinishingSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Varieties
// ====================================================

export interface Varieties_varieties_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface Varieties_varieties_edges_node_lithologicalclass {
  code: string;
  description: string | null;
  id: string;
}

export interface Varieties_varieties_edges_node_lithologicalsubclass {
  code: string;
  description: string;
  id: string;
}

export interface Varieties_varieties_edges_node {
  id: string;
  code: string;
  date: weScanDateTime | null;
  absapatn: string | null;
  alternativedesig: string | null;
  observation: string | null;
  normativedesig: string;
  lithologicalclass: Varieties_varieties_edges_node_lithologicalclass | null;
  lithologicalsubclass: Varieties_varieties_edges_node_lithologicalsubclass | null;
  stonequarry: string;
  districtquarry: string;
  municipalityquarry: string;
}

export interface Varieties_varieties_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Variety
   */
  node: Varieties_varieties_edges_node;
}

export interface Varieties_varieties {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Paging information
   */
  pageInfo: Varieties_varieties_pageInfo;
  /**
   * Array of edges.
   */
  edges: Varieties_varieties_edges[];
}

export interface Varieties {
  varieties: Varieties_varieties;
}

export interface VarietiesVariables {
  filter?: VarietyFilter | null;
  paging?: CursorPaging | null;
  sorting?: VarietySort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Companies
// ====================================================

export interface Companies_companies_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface Companies_companies_edges_node {
  id: string;
  code: string;
  name: string;
  description: string;
  address: string;
  email: string;
  contact: string;
  image: string | null;
  stars: string | null;
}

export interface Companies_companies_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Company
   */
  node: Companies_companies_edges_node;
}

export interface Companies_companies {
  /**
   * Paging information
   */
  pageInfo: Companies_companies_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: Companies_companies_edges[];
}

export interface Companies {
  companies: Companies_companies;
}

export interface CompaniesVariables {
  filter?: CompanyFilter | null;
  paging?: CursorPaging | null;
  sorting?: CompanySort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Company
// ====================================================

export interface Company_company {
  id: string;
  code: string;
  name: string;
  description: string;
  address: string;
  email: string;
  contact: string;
  image: string | null;
  stars: string | null;
}

export interface Company {
  company: Company_company | null;
}

export interface CompanyVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Jobs
// ====================================================

export interface Jobs_jobs_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface Jobs_jobs_edges_node_taskprocesses_nodes {
  slabcount: number;
  totalslab: number;
}

export interface Jobs_jobs_edges_node_taskprocesses {
  /**
   * Array of nodes.
   */
  nodes: Jobs_jobs_edges_node_taskprocesses_nodes[];
}

export interface Jobs_jobs_edges_node_block {
  tenantCode: string;
  wescanCode: string;
}

export interface Jobs_jobs_edges_node_finish_description {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface Jobs_jobs_edges_node_finish_observation {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface Jobs_jobs_edges_node_finish {
  code: string;
  description: Jobs_jobs_edges_node_finish_description;
  observation: Jobs_jobs_edges_node_finish_observation | null;
  id: string;
}

export interface Jobs_jobs_edges_node_category_companyNames {
  description: string;
  code: string;
}

export interface Jobs_jobs_edges_node_category_description {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface Jobs_jobs_edges_node_category {
  code: string;
  companyNames: Jobs_jobs_edges_node_category_companyNames[] | null;
  description: Jobs_jobs_edges_node_category_description;
  id: string;
}

export interface Jobs_jobs_edges_node_variety {
  id: string;
  code: string;
  date: weScanDateTime | null;
  absapatn: string | null;
  alternativedesig: string | null;
  observation: string | null;
  normativedesig: string;
}

export interface Jobs_jobs_edges_node_scan {
  code: string;
  description: string;
  state: boolean | null;
  id: string;
}

export interface Jobs_jobs_edges_node_company {
  code: string;
  name: string;
  id: string;
}

export interface Jobs_jobs_edges_node_jobtype {
  id: string;
  code: string | null;
  description: string | null;
}

export interface Jobs_jobs_edges_node {
  taskprocesses: Jobs_jobs_edges_node_taskprocesses | null;
  block: Jobs_jobs_edges_node_block | null;
  sequence: number;
  description: string | null;
  createdAt: weScanDateTime | null;
  createdate: weScanDateTime | null;
  updatedAt: weScanDateTime | null;
  lastupdate: weScanDateTime | null;
  id: string;
  code: string;
  blocknumber: string | null;
  descstate: DescState | null;
  completed: boolean | null;
  finish: Jobs_jobs_edges_node_finish | null;
  category: Jobs_jobs_edges_node_category | null;
  variety: Jobs_jobs_edges_node_variety | null;
  height: number | null;
  width: number | null;
  thickness: number | null;
  totalslab: number;
  scannedSlabs: number;
  isblock: boolean | null;
  scan: Jobs_jobs_edges_node_scan | null;
  state: boolean | null;
  company: Jobs_jobs_edges_node_company | null;
  jobtype: Jobs_jobs_edges_node_jobtype | null;
}

export interface Jobs_jobs_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Job
   */
  node: Jobs_jobs_edges_node;
}

export interface Jobs_jobs {
  /**
   * Paging information
   */
  pageInfo: Jobs_jobs_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: Jobs_jobs_edges[];
}

export interface Jobs {
  jobs: Jobs_jobs;
}

export interface JobsVariables {
  filter?: JobFilter | null;
  paging?: CursorPaging | null;
  sorting?: JobSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Job
// ====================================================

export interface Job_job_taskprocesses_nodes {
  slabcount: number;
  totalslab: number;
}

export interface Job_job_taskprocesses {
  /**
   * Array of nodes.
   */
  nodes: Job_job_taskprocesses_nodes[];
}

export interface Job_job_block_regularImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface Job_job_block {
  baseArea: number | null;
  id: string;
  extwidth: number | null;
  extlength: number | null;
  extheight: number | null;
  description: string | null;
  tenantCode: string;
  wescanCode: string;
  regularImages: Job_job_block_regularImages[] | null;
}

export interface Job_job_finish_description {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface Job_job_finish_observation {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface Job_job_finish {
  code: string;
  description: Job_job_finish_description;
  observation: Job_job_finish_observation | null;
  id: string;
}

export interface Job_job_category_companyNames {
  description: string;
  code: string;
}

export interface Job_job_category_description {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface Job_job_category {
  code: string;
  companyNames: Job_job_category_companyNames[] | null;
  description: Job_job_category_description;
  id: string;
}

export interface Job_job_variety {
  id: string;
  code: string;
  date: weScanDateTime | null;
  absapatn: string | null;
  alternativedesig: string | null;
  observation: string | null;
  normativedesig: string;
}

export interface Job_job_commercial_finishing_description {
  pt: string;
}

export interface Job_job_commercial_finishing_observation {
  pt: string;
}

export interface Job_job_commercial_finishing {
  code: string;
  description: Job_job_commercial_finishing_description;
  id: string;
  observation: Job_job_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface Job_job_commercial_variety_materialtype_description {
  pt: string;
}

export interface Job_job_commercial_variety_materialtype {
  code: string;
  description: Job_job_commercial_variety_materialtype_description;
}

export interface Job_job_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: Job_job_commercial_variety_materialtype | null;
}

export interface Job_job_stone_edge {
  code: string;
  id: string;
  name: string;
  description: string;
}

export interface Job_job_stone_model {
  code: string;
  id: string;
  name: string;
  description: string;
}

export interface Job_job_packing {
  id: string;
  code: string;
  packing: string;
}

export interface Job_job_project {
  id: string;
  code: string;
  project: string;
}

export interface Job_job_typematerial_description {
  pt: string;
}

export interface Job_job_typematerial_companyNames {
  code: string;
  description: string;
}

export interface Job_job_typematerial {
  id: string;
  code: string;
  description: Job_job_typematerial_description;
  companyNames: Job_job_typematerial_companyNames[] | null;
}

export interface Job_job_scan {
  code: string;
  description: string;
  state: boolean | null;
  id: string;
}

export interface Job_job_company {
  code: string;
  name: string;
  id: string;
}

export interface Job_job_jobtype {
  id: string;
  code: string | null;
  description: string | null;
}

export interface Job_job {
  taskprocesses: Job_job_taskprocesses | null;
  /**
   * Prefix of the slabs resulting from this Job.
   */
  prefix: string | null;
  /**
   * Order of the numbers given to the slabs resulting from this Job.
   */
  numberingOrder: JobNumberingOrder | null;
  /**
   * Separator between the prefix and the numbering of the slabs resulting from this Job.
   */
  separator: string | null;
  /**
   * Start number for the slabs resulting from this Job.
   */
  startNumber: number | null;
  block: Job_job_block | null;
  sequence: number;
  description: string | null;
  createdAt: weScanDateTime | null;
  createdate: weScanDateTime | null;
  updatedAt: weScanDateTime | null;
  lastupdate: weScanDateTime | null;
  id: string;
  code: string;
  blocknumber: string | null;
  descstate: DescState | null;
  completed: boolean | null;
  finish: Job_job_finish | null;
  category: Job_job_category | null;
  variety: Job_job_variety | null;
  commercial_finishing: Job_job_commercial_finishing | null;
  commercial_variety: Job_job_commercial_variety | null;
  stone_edge: Job_job_stone_edge | null;
  stone_model: Job_job_stone_model | null;
  packing: Job_job_packing | null;
  project: Job_job_project | null;
  typematerial: Job_job_typematerial | null;
  height: number | null;
  width: number | null;
  thickness: number | null;
  scannedSlabs: number;
  totalslab: number;
  isblock: boolean | null;
  scan: Job_job_scan | null;
  state: boolean | null;
  company: Job_job_company | null;
  jobtype: Job_job_jobtype | null;
}

export interface Job {
  job: Job_job | null;
}

export interface JobVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobsForScannerConsole
// ====================================================

export interface JobsForScannerConsole_jobs_edges_node_finish_description {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface JobsForScannerConsole_jobs_edges_node_finish_observation {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface JobsForScannerConsole_jobs_edges_node_finish {
  code: string;
  description: JobsForScannerConsole_jobs_edges_node_finish_description;
  observation: JobsForScannerConsole_jobs_edges_node_finish_observation | null;
  id: string;
}

export interface JobsForScannerConsole_jobs_edges_node_category_companyNames {
  description: string;
  code: string;
}

export interface JobsForScannerConsole_jobs_edges_node_category_description {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface JobsForScannerConsole_jobs_edges_node_category {
  code: string;
  companyNames: JobsForScannerConsole_jobs_edges_node_category_companyNames[] | null;
  description: JobsForScannerConsole_jobs_edges_node_category_description;
  id: string;
}

export interface JobsForScannerConsole_jobs_edges_node_variety {
  id: string;
  code: string;
  date: weScanDateTime | null;
  absapatn: string | null;
  alternativedesig: string | null;
  observation: string | null;
  normativedesig: string;
  macroscopicdesc: string | null;
}

export interface JobsForScannerConsole_jobs_edges_node_company {
  code: string;
  name: string;
  id: string;
}

export interface JobsForScannerConsole_jobs_edges_node_jobtype {
  id: string;
  code: string | null;
  description: string | null;
}

export interface JobsForScannerConsole_jobs_edges_node_block_regularImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface JobsForScannerConsole_jobs_edges_node_block {
  baseArea: number | null;
  id: string;
  extwidth: number | null;
  extlength: number | null;
  extheight: number | null;
  description: string | null;
  tenantCode: string;
  wescanCode: string;
  regularImages: JobsForScannerConsole_jobs_edges_node_block_regularImages[] | null;
}

export interface JobsForScannerConsole_jobs_edges_node_commercial_finishing_description {
  pt: string;
  es: string | null;
  en: string | null;
}

export interface JobsForScannerConsole_jobs_edges_node_commercial_finishing_observation {
  en: string | null;
  es: string | null;
  pt: string;
}

export interface JobsForScannerConsole_jobs_edges_node_commercial_finishing {
  code: string;
  description: JobsForScannerConsole_jobs_edges_node_commercial_finishing_description;
  id: string;
  observation: JobsForScannerConsole_jobs_edges_node_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface JobsForScannerConsole_jobs_edges_node_commercial_variety_materialtype_description {
  pt: string;
  es: string | null;
  en: string | null;
}

export interface JobsForScannerConsole_jobs_edges_node_commercial_variety_materialtype_companyNames {
  description: string;
  code: string;
}

export interface JobsForScannerConsole_jobs_edges_node_commercial_variety_materialtype {
  code: string;
  description: JobsForScannerConsole_jobs_edges_node_commercial_variety_materialtype_description;
  id: string;
  companyNames:
    | JobsForScannerConsole_jobs_edges_node_commercial_variety_materialtype_companyNames[]
    | null;
}

export interface JobsForScannerConsole_jobs_edges_node_commercial_variety {
  id: string;
  name: string | null;
  code: string;
  description: string | null;
  materialtype: JobsForScannerConsole_jobs_edges_node_commercial_variety_materialtype | null;
}

export interface JobsForScannerConsole_jobs_edges_node_packing {
  id: string;
  code: string;
  observation: string | null;
  packing: string;
}

export interface JobsForScannerConsole_jobs_edges_node_project {
  id: string;
  code: string;
  description: string | null;
  project: string;
}

export interface JobsForScannerConsole_jobs_edges_node_typematerial_companyNames {
  description: string;
  company: string;
  code: string;
}

export interface JobsForScannerConsole_jobs_edges_node_typematerial_description {
  pt: string;
  es: string | null;
  en: string | null;
}

export interface JobsForScannerConsole_jobs_edges_node_typematerial {
  code: string;
  companyNames: JobsForScannerConsole_jobs_edges_node_typematerial_companyNames[] | null;
  description: JobsForScannerConsole_jobs_edges_node_typematerial_description;
  id: string;
}

export interface JobsForScannerConsole_jobs_edges_node {
  /**
   * Prefix of the slabs resulting from this Job.
   */
  prefix: string | null;
  /**
   * Start number for the slabs resulting from this Job.
   */
  startNumber: number | null;
  /**
   * Order of the numbers given to the slabs resulting from this Job.
   */
  numberingOrder: JobNumberingOrder | null;
  /**
   * Separator between the prefix and the numbering of the slabs resulting from this Job.
   */
  separator: string | null;
  sequence: number;
  description: string | null;
  createdAt: weScanDateTime | null;
  createdate: weScanDateTime | null;
  updatedAt: weScanDateTime | null;
  lastupdate: weScanDateTime | null;
  id: string;
  code: string;
  blocknumber: string | null;
  descstate: DescState | null;
  completed: boolean | null;
  finish: JobsForScannerConsole_jobs_edges_node_finish | null;
  category: JobsForScannerConsole_jobs_edges_node_category | null;
  variety: JobsForScannerConsole_jobs_edges_node_variety | null;
  totalslab: number;
  scannedSlabs: number;
  state: boolean | null;
  company: JobsForScannerConsole_jobs_edges_node_company | null;
  jobtype: JobsForScannerConsole_jobs_edges_node_jobtype | null;
  block: JobsForScannerConsole_jobs_edges_node_block | null;
  commercial_finishing: JobsForScannerConsole_jobs_edges_node_commercial_finishing | null;
  commercial_variety: JobsForScannerConsole_jobs_edges_node_commercial_variety | null;
  packing: JobsForScannerConsole_jobs_edges_node_packing | null;
  project: JobsForScannerConsole_jobs_edges_node_project | null;
  typematerial: JobsForScannerConsole_jobs_edges_node_typematerial | null;
}

export interface JobsForScannerConsole_jobs_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Job
   */
  node: JobsForScannerConsole_jobs_edges_node;
}

export interface JobsForScannerConsole_jobs_pageInfo {
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
}

export interface JobsForScannerConsole_jobs {
  /**
   * Array of edges.
   */
  edges: JobsForScannerConsole_jobs_edges[];
  /**
   * Paging information
   */
  pageInfo: JobsForScannerConsole_jobs_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
}

export interface JobsForScannerConsole {
  jobs: JobsForScannerConsole_jobs;
}

export interface JobsForScannerConsoleVariables {
  filter?: JobFilter | null;
  paging?: CursorPaging | null;
  sorting?: JobSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobsShort
// ====================================================

export interface JobsShort_jobs_edges_node {
  description: string | null;
  createdAt: weScanDateTime | null;
  updatedAt: weScanDateTime | null;
  id: string;
  code: string;
  descstate: DescState | null;
  completed: boolean | null;
  totalslab: number;
}

export interface JobsShort_jobs_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Job
   */
  node: JobsShort_jobs_edges_node;
}

export interface JobsShort_jobs_pageInfo {
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
}

export interface JobsShort_jobs {
  /**
   * Array of edges.
   */
  edges: JobsShort_jobs_edges[];
  /**
   * Paging information
   */
  pageInfo: JobsShort_jobs_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
}

export interface JobsShort {
  jobs: JobsShort_jobs;
}

export interface JobsShortVariables {
  filter?: JobFilter | null;
  paging?: CursorPaging | null;
  sorting?: JobSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JobTypes
// ====================================================

export interface JobTypes_jobtypes_edges_node {
  id: string;
  description: string | null;
  code: string | null;
}

export interface JobTypes_jobtypes_edges {
  /**
   * The node containing the Jobtype
   */
  node: JobTypes_jobtypes_edges_node;
}

export interface JobTypes_jobtypes {
  /**
   * Array of edges.
   */
  edges: JobTypes_jobtypes_edges[];
}

export interface JobTypes {
  jobtypes: JobTypes_jobtypes;
}

export interface JobTypesVariables {
  filter?: JobtypeFilter | null;
  paging?: CursorPaging | null;
  sorting?: JobtypeSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CommercialFinishings
// ====================================================

export interface CommercialFinishings_commercialFinishings_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface CommercialFinishings_commercialFinishings_edges_node_createdBy {
  username: string;
  avatar: string | null;
  firstname: string | null;
  lastname: string | null;
  id: string;
}

export interface CommercialFinishings_commercialFinishings_edges_node_description {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface CommercialFinishings_commercialFinishings_edges_node_observation {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface CommercialFinishings_commercialFinishings_edges_node {
  code: string;
  createdAt: weScanDateTime | null;
  createdBy: CommercialFinishings_commercialFinishings_edges_node_createdBy | null;
  description: CommercialFinishings_commercialFinishings_edges_node_description;
  id: string;
  observation: CommercialFinishings_commercialFinishings_edges_node_observation | null;
}

export interface CommercialFinishings_commercialFinishings_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the CommercialFinishing
   */
  node: CommercialFinishings_commercialFinishings_edges_node;
}

export interface CommercialFinishings_commercialFinishings {
  /**
   * Paging information
   */
  pageInfo: CommercialFinishings_commercialFinishings_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: CommercialFinishings_commercialFinishings_edges[];
}

export interface CommercialFinishings {
  commercialFinishings: CommercialFinishings_commercialFinishings;
}

export interface CommercialFinishingsVariables {
  filter?: CommercialFinishingFilter | null;
  paging?: CursorPaging | null;
  sorting?: CommercialFinishingSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CommercialFinishing
// ====================================================

export interface CommercialFinishing_commercialFinishing_createdBy {
  username: string;
  avatar: string | null;
  firstname: string | null;
  lastname: string | null;
  id: string;
}

export interface CommercialFinishing_commercialFinishing_description {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface CommercialFinishing_commercialFinishing_observation {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface CommercialFinishing_commercialFinishing {
  code: string;
  createdAt: weScanDateTime | null;
  createdBy: CommercialFinishing_commercialFinishing_createdBy | null;
  description: CommercialFinishing_commercialFinishing_description;
  id: string;
  observation: CommercialFinishing_commercialFinishing_observation | null;
}

export interface CommercialFinishing {
  commercialFinishing: CommercialFinishing_commercialFinishing | null;
}

export interface CommercialFinishingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CommercialVarieties
// ====================================================

export interface CommercialVarieties_commercialVarieties_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface CommercialVarieties_commercialVarieties_edges_node_createdBy {
  email: string;
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface CommercialVarieties_commercialVarieties_edges_node_materialtype_companyNames {
  code: string;
  description: string;
}

export interface CommercialVarieties_commercialVarieties_edges_node_materialtype_description {
  en: string | null;
  pt: string;
  es: string | null;
}

export interface CommercialVarieties_commercialVarieties_edges_node_materialtype {
  code: string;
  companyNames:
    | CommercialVarieties_commercialVarieties_edges_node_materialtype_companyNames[]
    | null;
  id: string;
  description: CommercialVarieties_commercialVarieties_edges_node_materialtype_description;
}

export interface CommercialVarieties_commercialVarieties_edges_node_system_variety {
  code: string;
  normativedesig: string;
  alternativedesig: string | null;
  absapatn: string | null;
  districtquarry: string;
  id: string;
}

export interface CommercialVarieties_commercialVarieties_edges_node_updatedBy {
  avatar: string | null;
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface CommercialVarieties_commercialVarieties_edges_node_user {
  avatar: string | null;
  email: string;
  id: string;
  lastname: string | null;
  firstname: string | null;
}

export interface CommercialVarieties_commercialVarieties_edges_node {
  description: string | null;
  id: string;
  code: string;
  createdAt: weScanDateTime | null;
  createdBy: CommercialVarieties_commercialVarieties_edges_node_createdBy | null;
  materialtype: CommercialVarieties_commercialVarieties_edges_node_materialtype | null;
  name: string | null;
  system_variety: CommercialVarieties_commercialVarieties_edges_node_system_variety | null;
  updatedAt: weScanDateTime | null;
  updatedBy: CommercialVarieties_commercialVarieties_edges_node_updatedBy | null;
  user: CommercialVarieties_commercialVarieties_edges_node_user | null;
}

export interface CommercialVarieties_commercialVarieties_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the CommercialVariety
   */
  node: CommercialVarieties_commercialVarieties_edges_node;
}

export interface CommercialVarieties_commercialVarieties {
  /**
   * Paging information
   */
  pageInfo: CommercialVarieties_commercialVarieties_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: CommercialVarieties_commercialVarieties_edges[];
}

export interface CommercialVarieties {
  commercialVarieties: CommercialVarieties_commercialVarieties;
}

export interface CommercialVarietiesVariables {
  filter?: CommercialVarietyFilter | null;
  paging?: CursorPaging | null;
  sorting?: CommercialVarietySort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CommercialVariety
// ====================================================

export interface CommercialVariety_commercialVariety_createdBy {
  email: string;
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface CommercialVariety_commercialVariety_materialtype_companyNames {
  code: string;
  description: string;
}

export interface CommercialVariety_commercialVariety_materialtype_description {
  en: string | null;
  pt: string;
  es: string | null;
}

export interface CommercialVariety_commercialVariety_materialtype {
  code: string;
  companyNames: CommercialVariety_commercialVariety_materialtype_companyNames[] | null;
  id: string;
  description: CommercialVariety_commercialVariety_materialtype_description;
}

export interface CommercialVariety_commercialVariety_system_variety {
  code: string;
  normativedesig: string;
  alternativedesig: string | null;
  absapatn: string | null;
  districtquarry: string;
  id: string;
}

export interface CommercialVariety_commercialVariety_updatedBy {
  avatar: string | null;
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface CommercialVariety_commercialVariety_user {
  avatar: string | null;
  email: string;
  id: string;
  lastname: string | null;
  firstname: string | null;
}

export interface CommercialVariety_commercialVariety_annexedDocuments_nodes {
  /**
   * File URL
   */
  blob_url: string;
  id: string;
  /**
   * File Mime Type
   */
  mimetype: string;
  /**
   * Document name
   */
  name: string;
}

export interface CommercialVariety_commercialVariety_annexedDocuments {
  /**
   * Array of nodes.
   */
  nodes: CommercialVariety_commercialVariety_annexedDocuments_nodes[];
}

export interface CommercialVariety_commercialVariety {
  description: string | null;
  id: string;
  code: string;
  createdAt: weScanDateTime | null;
  createdBy: CommercialVariety_commercialVariety_createdBy | null;
  materialtype: CommercialVariety_commercialVariety_materialtype | null;
  name: string | null;
  system_variety: CommercialVariety_commercialVariety_system_variety | null;
  updatedAt: weScanDateTime | null;
  updatedBy: CommercialVariety_commercialVariety_updatedBy | null;
  user: CommercialVariety_commercialVariety_user | null;
  annexedDocuments: CommercialVariety_commercialVariety_annexedDocuments | null;
}

export interface CommercialVariety {
  commercialVariety: CommercialVariety_commercialVariety | null;
}

export interface CommercialVarietyVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StoneModel
// ====================================================

export interface StoneModel_stoneModel {
  code: string;
  description: string;
  id: string;
  name: string;
  createdAt: weScanDateTime;
}

export interface StoneModel {
  stoneModel: StoneModel_stoneModel | null;
}

export interface StoneModelVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StoneModels
// ====================================================

export interface StoneModels_stoneModels_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface StoneModels_stoneModels_edges_node {
  code: string;
  description: string;
  id: string;
  name: string;
  createdAt: weScanDateTime;
}

export interface StoneModels_stoneModels_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the StoneModel
   */
  node: StoneModels_stoneModels_edges_node;
}

export interface StoneModels_stoneModels {
  /**
   * Paging information
   */
  pageInfo: StoneModels_stoneModels_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: StoneModels_stoneModels_edges[];
}

export interface StoneModels {
  stoneModels: StoneModels_stoneModels;
}

export interface StoneModelsVariables {
  filter?: StoneModelFilter | null;
  paging?: CursorPaging | null;
  sorting?: StoneModelSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StoneEdge
// ====================================================

export interface StoneEdge_stoneEdge {
  code: string;
  createdAt: weScanDateTime;
  id: string;
  name: string;
  description: string;
}

export interface StoneEdge {
  stoneEdge: StoneEdge_stoneEdge | null;
}

export interface StoneEdgeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StoneEdges
// ====================================================

export interface StoneEdges_stoneEdges_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface StoneEdges_stoneEdges_edges_node {
  code: string;
  createdAt: weScanDateTime;
  id: string;
  name: string;
  description: string;
}

export interface StoneEdges_stoneEdges_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the StoneEdge
   */
  node: StoneEdges_stoneEdges_edges_node;
}

export interface StoneEdges_stoneEdges {
  /**
   * Paging information
   */
  pageInfo: StoneEdges_stoneEdges_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: StoneEdges_stoneEdges_edges[];
}

export interface StoneEdges {
  stoneEdges: StoneEdges_stoneEdges;
}

export interface StoneEdgesVariables {
  filter?: StoneEdgeFilter | null;
  paging?: CursorPaging | null;
  sorting?: StoneEdgeSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Scanners
// ====================================================

export interface Scanners_scanners_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface Scanners_scanners_edges_node_company {
  name: string;
  id: string;
  code: string;
}

export interface Scanners_scanners_edges_node {
  code: string;
  id: string;
  company: Scanners_scanners_edges_node_company | null;
  description: string;
  scanner_model: ScannerModelEnum;
  snumber: string;
  state: boolean | null;
}

export interface Scanners_scanners_edges {
  /**
   * The node containing the Scanner
   */
  node: Scanners_scanners_edges_node;
}

export interface Scanners_scanners {
  /**
   * Paging information
   */
  pageInfo: Scanners_scanners_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: Scanners_scanners_edges[];
}

export interface Scanners {
  scanners: Scanners_scanners;
}

export interface ScannersVariables {
  filter?: ScannerFilter | null;
  paging?: CursorPaging | null;
  sorting?: ScannerSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Scanner
// ====================================================

export interface Scanner_scanner_company {
  name: string;
  id: string;
  code: string;
}

export interface Scanner_scanner {
  code: string;
  id: string;
  company: Scanner_scanner_company | null;
  description: string;
  scanner_model: ScannerModelEnum;
  snumber: string;
  state: boolean | null;
}

export interface Scanner {
  scanner: Scanner_scanner | null;
}

export interface ScannerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllSlabFilters
// ====================================================

export interface AllSlabFilters_categories_edges_node_description {
  en: string | null;
  pt: string;
  es: string | null;
}

export interface AllSlabFilters_categories_edges_node_observation {
  en: string | null;
  pt: string;
  es: string | null;
}

export interface AllSlabFilters_categories_edges_node_companyNames {
  code: string;
  description: string;
}

export interface AllSlabFilters_categories_edges_node {
  id: string;
  code: string;
  description: AllSlabFilters_categories_edges_node_description;
  observation: AllSlabFilters_categories_edges_node_observation | null;
  companyNames: AllSlabFilters_categories_edges_node_companyNames[] | null;
}

export interface AllSlabFilters_categories_edges {
  /**
   * The node containing the Category
   */
  node: AllSlabFilters_categories_edges_node;
}

export interface AllSlabFilters_categories {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_categories_edges[];
}

export interface AllSlabFilters_materialTypes_edges_node_description {
  en: string | null;
  pt: string;
  es: string | null;
}

export interface AllSlabFilters_materialTypes_edges_node_observation {
  en: string | null;
  pt: string;
  es: string | null;
}

export interface AllSlabFilters_materialTypes_edges_node_companyNames {
  code: string;
  description: string;
}

export interface AllSlabFilters_materialTypes_edges_node {
  id: string;
  code: string;
  description: AllSlabFilters_materialTypes_edges_node_description;
  observation: AllSlabFilters_materialTypes_edges_node_observation | null;
  companyNames: AllSlabFilters_materialTypes_edges_node_companyNames[] | null;
}

export interface AllSlabFilters_materialTypes_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the MaterialType
   */
  node: AllSlabFilters_materialTypes_edges_node;
}

export interface AllSlabFilters_materialTypes {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_materialTypes_edges[];
}

export interface AllSlabFilters_finishings_edges_node_description {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface AllSlabFilters_finishings_edges_node_observation {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface AllSlabFilters_finishings_edges_node {
  id: string;
  code: string;
  date: weScanDateTime | null;
  description: AllSlabFilters_finishings_edges_node_description;
  observation: AllSlabFilters_finishings_edges_node_observation | null;
}

export interface AllSlabFilters_finishings_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Finishing
   */
  node: AllSlabFilters_finishings_edges_node;
}

export interface AllSlabFilters_finishings {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_finishings_edges[];
}

export interface AllSlabFilters_varieties_edges_node_materialtype_description {
  pt: string;
  es: string | null;
  en: string | null;
}

export interface AllSlabFilters_varieties_edges_node_materialtype {
  description: AllSlabFilters_varieties_edges_node_materialtype_description;
  code: string;
  id: string;
}

export interface AllSlabFilters_varieties_edges_node {
  id: string;
  code: string;
  absapatn: string | null;
  alternativedesig: string | null;
  observation: string | null;
  normativedesig: string;
  stonequarry: string;
  districtquarry: string;
  municipalityquarry: string;
  materialtype: AllSlabFilters_varieties_edges_node_materialtype | null;
}

export interface AllSlabFilters_varieties_edges {
  /**
   * The node containing the Variety
   */
  node: AllSlabFilters_varieties_edges_node;
}

export interface AllSlabFilters_varieties {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_varieties_edges[];
}

export interface AllSlabFilters_commercialFinishings_edges_node_description {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface AllSlabFilters_commercialFinishings_edges_node_observation {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface AllSlabFilters_commercialFinishings_edges_node {
  code: string;
  description: AllSlabFilters_commercialFinishings_edges_node_description;
  id: string;
  observation: AllSlabFilters_commercialFinishings_edges_node_observation | null;
}

export interface AllSlabFilters_commercialFinishings_edges {
  /**
   * The node containing the CommercialFinishing
   */
  node: AllSlabFilters_commercialFinishings_edges_node;
}

export interface AllSlabFilters_commercialFinishings {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_commercialFinishings_edges[];
}

export interface AllSlabFilters_commercialVarieties_edges_node {
  code: string;
  name: string | null;
  id: string;
}

export interface AllSlabFilters_commercialVarieties_edges {
  /**
   * The node containing the CommercialVariety
   */
  node: AllSlabFilters_commercialVarieties_edges_node;
}

export interface AllSlabFilters_commercialVarieties {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_commercialVarieties_edges[];
}

export interface AllSlabFilters_scanners_edges_node {
  code: string;
  id: string;
  snumber: string;
  scanner_model: ScannerModelEnum;
  description: string;
}

export interface AllSlabFilters_scanners_edges {
  /**
   * The node containing the Scanner
   */
  node: AllSlabFilters_scanners_edges_node;
}

export interface AllSlabFilters_scanners {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_scanners_edges[];
}

export interface AllSlabFilters_jobs_edges_node_jobtype {
  code: string | null;
  id: string;
}

export interface AllSlabFilters_jobs_edges_node {
  code: string;
  id: string;
  blocknumber: string | null;
  description: string | null;
  jobtype: AllSlabFilters_jobs_edges_node_jobtype | null;
}

export interface AllSlabFilters_jobs_edges {
  /**
   * The node containing the Job
   */
  node: AllSlabFilters_jobs_edges_node;
}

export interface AllSlabFilters_jobs {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_jobs_edges[];
}

export interface AllSlabFilters_packings_edges_node_packingtype {
  code: string;
  id: string;
  observation: string | null;
  type: string | null;
}

export interface AllSlabFilters_packings_edges_node_project {
  id: string;
  code: string;
  project: string;
}

export interface AllSlabFilters_packings_edges_node {
  id: string;
  code: string;
  packing: string;
  observation: string | null;
  inStock: boolean;
  packingtype: AllSlabFilters_packings_edges_node_packingtype | null;
  project: AllSlabFilters_packings_edges_node_project | null;
}

export interface AllSlabFilters_packings_edges {
  /**
   * The node containing the Packing
   */
  node: AllSlabFilters_packings_edges_node;
}

export interface AllSlabFilters_packings {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_packings_edges[];
}

export interface AllSlabFilters_projects_edges_node {
  code: string;
  id: string;
  description: string | null;
  project: string;
}

export interface AllSlabFilters_projects_edges {
  /**
   * The node containing the Project
   */
  node: AllSlabFilters_projects_edges_node;
}

export interface AllSlabFilters_projects {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_projects_edges[];
}

export interface AllSlabFilters_stoneEdges_edges_node {
  code: string;
  createdAt: weScanDateTime;
  id: string;
  name: string;
  description: string;
}

export interface AllSlabFilters_stoneEdges_edges {
  /**
   * The node containing the StoneEdge
   */
  node: AllSlabFilters_stoneEdges_edges_node;
}

export interface AllSlabFilters_stoneEdges {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_stoneEdges_edges[];
}

export interface AllSlabFilters_stoneModels_edges_node {
  code: string;
  description: string;
  id: string;
  name: string;
  createdAt: weScanDateTime;
}

export interface AllSlabFilters_stoneModels_edges {
  /**
   * The node containing the StoneModel
   */
  node: AllSlabFilters_stoneModels_edges_node;
}

export interface AllSlabFilters_stoneModels {
  /**
   * Array of edges.
   */
  edges: AllSlabFilters_stoneModels_edges[];
}

export interface AllSlabFilters {
  categories: AllSlabFilters_categories;
  materialTypes: AllSlabFilters_materialTypes;
  finishings: AllSlabFilters_finishings;
  varieties: AllSlabFilters_varieties;
  commercialFinishings: AllSlabFilters_commercialFinishings;
  commercialVarieties: AllSlabFilters_commercialVarieties;
  scanners: AllSlabFilters_scanners;
  jobs: AllSlabFilters_jobs;
  packings: AllSlabFilters_packings;
  projects: AllSlabFilters_projects;
  stoneEdges: AllSlabFilters_stoneEdges;
  stoneModels: AllSlabFilters_stoneModels;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ImageColorFilters
// ====================================================

export interface ImageColorFilters_imageColorFilters_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface ImageColorFilters_imageColorFilters_edges_node_valueFilter {
  brightness: number;
  contrast: number;
  gamma: number;
  hue_rotation: number;
  saturation: number;
  temperature: number;
  exposure: number;
}

export interface ImageColorFilters_imageColorFilters_edges_node_commercial_variety {
  code: string;
  id: string;
  description: string | null;
  name: string | null;
}

export interface ImageColorFilters_imageColorFilters_edges_node_commercial_finishing_description {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface ImageColorFilters_imageColorFilters_edges_node_commercial_finishing {
  id: string;
  code: string;
  description: ImageColorFilters_imageColorFilters_edges_node_commercial_finishing_description;
}

export interface ImageColorFilters_imageColorFilters_edges_node {
  id: string;
  name: string;
  valueFilter: ImageColorFilters_imageColorFilters_edges_node_valueFilter;
  commercial_variety: ImageColorFilters_imageColorFilters_edges_node_commercial_variety | null;
  commercial_finishing: ImageColorFilters_imageColorFilters_edges_node_commercial_finishing | null;
}

export interface ImageColorFilters_imageColorFilters_edges {
  /**
   * The node containing the ImageColorFilter
   */
  node: ImageColorFilters_imageColorFilters_edges_node;
}

export interface ImageColorFilters_imageColorFilters {
  /**
   * Paging information
   */
  pageInfo: ImageColorFilters_imageColorFilters_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: ImageColorFilters_imageColorFilters_edges[];
}

export interface ImageColorFilters {
  imageColorFilters: ImageColorFilters_imageColorFilters;
}

export interface ImageColorFiltersVariables {
  filter?: ImageColorFilterFilter | null;
  paging?: CursorPaging | null;
  sorting?: ImageColorFilterSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ImageColorFilter
// ====================================================

export interface ImageColorFilter_imageColorFilter_valueFilter {
  brightness: number;
  contrast: number;
  gamma: number;
  hue_rotation: number;
  saturation: number;
}

export interface ImageColorFilter_imageColorFilter_commercial_variety {
  code: string;
  id: string;
  description: string | null;
  name: string | null;
}

export interface ImageColorFilter_imageColorFilter_commercial_finishing_description {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface ImageColorFilter_imageColorFilter_commercial_finishing {
  id: string;
  code: string;
  description: ImageColorFilter_imageColorFilter_commercial_finishing_description;
}

export interface ImageColorFilter_imageColorFilter {
  id: string;
  name: string;
  valueFilter: ImageColorFilter_imageColorFilter_valueFilter;
  commercial_variety: ImageColorFilter_imageColorFilter_commercial_variety | null;
  commercial_finishing: ImageColorFilter_imageColorFilter_commercial_finishing | null;
}

export interface ImageColorFilter {
  imageColorFilter: ImageColorFilter_imageColorFilter | null;
}

export interface ImageColorFilterVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlockModel
// ====================================================

export interface BlockModel_blockModel_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
}

export interface BlockModel_blockModel_commercial_variety {
  code: string;
  id: string;
  name: string | null;
  description: string | null;
}

export interface BlockModel_blockModel_materialtype_description {
  pt: string;
}

export interface BlockModel_blockModel_materialtype_companyNames {
  code: string;
  description: string;
}

export interface BlockModel_blockModel_materialtype {
  id: string;
  code: string;
  description: BlockModel_blockModel_materialtype_description;
  companyNames: BlockModel_blockModel_materialtype_companyNames[] | null;
}

export interface BlockModel_blockModel_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface BlockModel_blockModel_scannedBy {
  code: string;
  scanner_model: ScannerModelEnum;
}

export interface BlockModel_blockModel_regularImages {
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
  /**
   * Container in Azure Storage Account where blob is stored
   */
  azureContainer: string;
  /**
   * Azure Storage Account name
   */
  azureStorageAccount: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * ImageFace of Block, Single by default for regular images of slabs
   */
  imageFace: ImageFace;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
}

export interface BlockModel_blockModel_variety {
  id: string;
  normativedesig: string;
  alternativedesig: string | null;
  code: string;
}

export interface BlockModel_blockModel {
  baseArea: number | null;
  slabs: BlockModel_blockModel_slabs | null;
  commercial_variety: BlockModel_blockModel_commercial_variety | null;
  materialtype: BlockModel_blockModel_materialtype | null;
  createdAt: weScanDateTime;
  createdBy: BlockModel_blockModel_createdBy | null;
  creationType: BlockCreationType | null;
  description: string | null;
  extheight: number | null;
  extwidth: number | null;
  id: string;
  scannedBy: BlockModel_blockModel_scannedBy | null;
  tenantCode: string;
  updatedAt: weScanDateTime | null;
  regularImages: BlockModel_blockModel_regularImages[] | null;
  modelFileURL: string | null;
  modelFileExtension: string | null;
  modelFileMimetype: string | null;
  materialFileUrl: string | null;
  materialFileExtension: string | null;
  materialFileMimetype: string | null;
  wescanCode: string;
  variety: BlockModel_blockModel_variety | null;
  volume: number | null;
}

export interface BlockModel {
  blockModel: BlockModel_blockModel | null;
}

export interface BlockModelVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlockModels
// ====================================================

export interface BlockModels_blockModels_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface BlockModels_blockModels_edges_node_commercial_variety {
  code: string;
  id: string;
  name: string | null;
  description: string | null;
}

export interface BlockModels_blockModels_edges_node_materialtype_description {
  pt: string;
}

export interface BlockModels_blockModels_edges_node_materialtype_companyNames {
  code: string;
  description: string;
}

export interface BlockModels_blockModels_edges_node_materialtype {
  id: string;
  code: string;
  description: BlockModels_blockModels_edges_node_materialtype_description;
  companyNames: BlockModels_blockModels_edges_node_materialtype_companyNames[] | null;
}

export interface BlockModels_blockModels_edges_node_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface BlockModels_blockModels_edges_node_scannedBy {
  code: string;
  scanner_model: ScannerModelEnum;
}

export interface BlockModels_blockModels_edges_node_regularImages {
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
  /**
   * Container in Azure Storage Account where blob is stored
   */
  azureContainer: string;
  /**
   * Azure Storage Account name
   */
  azureStorageAccount: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * ImageFace of Block, Single by default for regular images of slabs
   */
  imageFace: ImageFace;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
}

export interface BlockModels_blockModels_edges_node_variety {
  id: string;
  normativedesig: string;
  alternativedesig: string | null;
  code: string;
}

export interface BlockModels_blockModels_edges_node {
  baseArea: number | null;
  commercial_variety: BlockModels_blockModels_edges_node_commercial_variety | null;
  materialtype: BlockModels_blockModels_edges_node_materialtype | null;
  createdAt: weScanDateTime;
  createdBy: BlockModels_blockModels_edges_node_createdBy | null;
  creationType: BlockCreationType | null;
  description: string | null;
  extheight: number | null;
  extwidth: number | null;
  id: string;
  scannedBy: BlockModels_blockModels_edges_node_scannedBy | null;
  tenantCode: string;
  updatedAt: weScanDateTime | null;
  regularImages: BlockModels_blockModels_edges_node_regularImages[] | null;
  wescanCode: string;
  variety: BlockModels_blockModels_edges_node_variety | null;
  volume: number | null;
}

export interface BlockModels_blockModels_edges {
  /**
   * The node containing the BlockModel
   */
  node: BlockModels_blockModels_edges_node;
}

export interface BlockModels_blockModels {
  /**
   * Paging information
   */
  pageInfo: BlockModels_blockModels_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: BlockModels_blockModels_edges[];
}

export interface BlockModels {
  blockModels: BlockModels_blockModels;
}

export interface BlockModelsVariables {
  filter?: BlockModelFilter | null;
  paging?: CursorPaging | null;
  sorting?: BlockModelSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlockModelsGallery
// ====================================================

export interface BlockModelsGallery_blockModels_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface BlockModelsGallery_blockModels_edges_node_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface BlockModelsGallery_blockModels_edges_node_slabs_edges_node {
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: BlockModelsGallery_blockModels_edges_node_slabs_edges_node_slabImages[];
  images: string[];
  id: string | null;
  code: string;
}

export interface BlockModelsGallery_blockModels_edges_node_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: BlockModelsGallery_blockModels_edges_node_slabs_edges_node;
}

export interface BlockModelsGallery_blockModels_edges_node_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: BlockModelsGallery_blockModels_edges_node_slabs_edges[];
}

export interface BlockModelsGallery_blockModels_edges_node_commercial_variety {
  code: string;
  id: string;
  name: string | null;
  description: string | null;
}

export interface BlockModelsGallery_blockModels_edges_node_materialtype_description {
  pt: string;
}

export interface BlockModelsGallery_blockModels_edges_node_materialtype_companyNames {
  code: string;
  description: string;
}

export interface BlockModelsGallery_blockModels_edges_node_materialtype {
  id: string;
  code: string;
  description: BlockModelsGallery_blockModels_edges_node_materialtype_description;
  companyNames: BlockModelsGallery_blockModels_edges_node_materialtype_companyNames[] | null;
}

export interface BlockModelsGallery_blockModels_edges_node_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface BlockModelsGallery_blockModels_edges_node_scannedBy {
  code: string;
  scanner_model: ScannerModelEnum;
}

export interface BlockModelsGallery_blockModels_edges_node_regularImages {
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
  /**
   * Container in Azure Storage Account where blob is stored
   */
  azureContainer: string;
  /**
   * Azure Storage Account name
   */
  azureStorageAccount: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * ImageFace of Block, Single by default for regular images of slabs
   */
  imageFace: ImageFace;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
}

export interface BlockModelsGallery_blockModels_edges_node_variety {
  id: string;
  normativedesig: string;
  alternativedesig: string | null;
  code: string;
}

export interface BlockModelsGallery_blockModels_edges_node {
  slabs: BlockModelsGallery_blockModels_edges_node_slabs | null;
  baseArea: number | null;
  commercial_variety: BlockModelsGallery_blockModels_edges_node_commercial_variety | null;
  materialtype: BlockModelsGallery_blockModels_edges_node_materialtype | null;
  createdAt: weScanDateTime;
  createdBy: BlockModelsGallery_blockModels_edges_node_createdBy | null;
  creationType: BlockCreationType | null;
  description: string | null;
  extheight: number | null;
  extwidth: number | null;
  id: string;
  scannedBy: BlockModelsGallery_blockModels_edges_node_scannedBy | null;
  tenantCode: string;
  updatedAt: weScanDateTime | null;
  regularImages: BlockModelsGallery_blockModels_edges_node_regularImages[] | null;
  wescanCode: string;
  variety: BlockModelsGallery_blockModels_edges_node_variety | null;
  volume: number | null;
}

export interface BlockModelsGallery_blockModels_edges {
  /**
   * The node containing the BlockModel
   */
  node: BlockModelsGallery_blockModels_edges_node;
}

export interface BlockModelsGallery_blockModels {
  /**
   * Paging information
   */
  pageInfo: BlockModelsGallery_blockModels_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: BlockModelsGallery_blockModels_edges[];
}

export interface BlockModelsGallery {
  blockModels: BlockModelsGallery_blockModels;
}

export interface BlockModelsGalleryVariables {
  filter?: BlockModelFilter | null;
  paging?: CursorPaging | null;
  sorting?: BlockModelSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlockModelsToFilter
// ====================================================

export interface BlockModelsToFilter_blockModels_edges_node {
  id: string;
  wescanCode: string;
  tenantCode: string;
}

export interface BlockModelsToFilter_blockModels_edges {
  /**
   * The node containing the BlockModel
   */
  node: BlockModelsToFilter_blockModels_edges_node;
}

export interface BlockModelsToFilter_blockModels {
  /**
   * Array of edges.
   */
  edges: BlockModelsToFilter_blockModels_edges[];
}

export interface BlockModelsToFilter {
  blockModels: BlockModelsToFilter_blockModels;
}

export interface BlockModelsToFilterVariables {
  filter?: BlockModelFilter | null;
  paging?: CursorPaging | null;
  sorting?: BlockModelSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedBlocks
// ====================================================

export interface SharedBlocks_sharedBlocks_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface SharedBlocks_sharedBlocks_edges_node_commercial_variety {
  code: string;
  id: string;
  name: string | null;
  description: string | null;
}

export interface SharedBlocks_sharedBlocks_edges_node_materialtype_description {
  pt: string;
}

export interface SharedBlocks_sharedBlocks_edges_node_materialtype_companyNames {
  code: string;
  description: string;
}

export interface SharedBlocks_sharedBlocks_edges_node_materialtype {
  id: string;
  code: string;
  description: SharedBlocks_sharedBlocks_edges_node_materialtype_description;
  companyNames: SharedBlocks_sharedBlocks_edges_node_materialtype_companyNames[] | null;
}

export interface SharedBlocks_sharedBlocks_edges_node_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface SharedBlocks_sharedBlocks_edges_node_scannedBy {
  code: string;
  scanner_model: ScannerModelEnum;
}

export interface SharedBlocks_sharedBlocks_edges_node_regularImages {
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
  /**
   * Container in Azure Storage Account where blob is stored
   */
  azureContainer: string;
  /**
   * Azure Storage Account name
   */
  azureStorageAccount: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * ImageFace of Block, Single by default for regular images of slabs
   */
  imageFace: ImageFace;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
}

export interface SharedBlocks_sharedBlocks_edges_node_variety {
  id: string;
  normativedesig: string;
  alternativedesig: string | null;
  code: string;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_commercial_variety_materialtype {
  description: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_commercial_variety {
  name: string | null;
  materialtype: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_commercial_variety_materialtype | null;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_typematerial {
  description: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_typematerial_description;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_finish_description {
  pt: string;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_finish {
  description: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_finish_description;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_variety {
  normativedesig: string;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_commercial_finishing {
  description: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_commercial_finishing_description;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges_node {
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_slabImages[];
  id: string | null;
  code: string;
  commercial_variety: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_commercial_variety | null;
  typematerial: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_typematerial | null;
  finish: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_finish | null;
  variety: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_variety | null;
  commercial_finishing: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node_commercial_finishing | null;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: SharedBlocks_sharedBlocks_edges_node_slabs_edges_node;
}

export interface SharedBlocks_sharedBlocks_edges_node_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: SharedBlocks_sharedBlocks_edges_node_slabs_edges[];
}

export interface SharedBlocks_sharedBlocks_edges_node {
  baseArea: number | null;
  commercial_variety: SharedBlocks_sharedBlocks_edges_node_commercial_variety | null;
  materialtype: SharedBlocks_sharedBlocks_edges_node_materialtype | null;
  createdAt: weScanDateTime;
  createdBy: SharedBlocks_sharedBlocks_edges_node_createdBy | null;
  creationType: BlockCreationType | null;
  description: string | null;
  extheight: number | null;
  extwidth: number | null;
  id: string;
  scannedBy: SharedBlocks_sharedBlocks_edges_node_scannedBy | null;
  tenantCode: string;
  updatedAt: weScanDateTime | null;
  regularImages: SharedBlocks_sharedBlocks_edges_node_regularImages[] | null;
  wescanCode: string;
  variety: SharedBlocks_sharedBlocks_edges_node_variety | null;
  volume: number | null;
  slabs: SharedBlocks_sharedBlocks_edges_node_slabs | null;
}

export interface SharedBlocks_sharedBlocks_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the BlockModel
   */
  node: SharedBlocks_sharedBlocks_edges_node;
}

export interface SharedBlocks_sharedBlocks {
  /**
   * Paging information
   */
  pageInfo: SharedBlocks_sharedBlocks_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: SharedBlocks_sharedBlocks_edges[];
}

export interface SharedBlocks {
  sharedBlocks: SharedBlocks_sharedBlocks;
}

export interface SharedBlocksVariables {
  filter?: BlockModelFilter | null;
  paging?: CursorPaging | null;
  sorting?: BlockModelSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Project
// ====================================================

export interface Project_project_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface Project_project_packings_edges_node_packingtype {
  id: string;
  code: string;
  type: string | null;
  observation: string | null;
}

export interface Project_project_packings_edges_node_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface Project_project_packings_edges_node_slabs_edges_node_company {
  code: string;
  name: string;
}

export interface Project_project_packings_edges_node_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface Project_project_packings_edges_node_slabs_edges_node_commercial_variety_materialtype {
  code: string;
  description: Project_project_packings_edges_node_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface Project_project_packings_edges_node_slabs_edges_node_commercial_variety_annexedDocuments_nodes {
  /**
   * File URL
   */
  blob_url: string;
  id: string;
  /**
   * File Mime Type
   */
  mimetype: string;
  /**
   * Document name
   */
  name: string;
}

export interface Project_project_packings_edges_node_slabs_edges_node_commercial_variety_annexedDocuments {
  /**
   * Array of nodes.
   */
  nodes: Project_project_packings_edges_node_slabs_edges_node_commercial_variety_annexedDocuments_nodes[];
}

export interface Project_project_packings_edges_node_slabs_edges_node_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: Project_project_packings_edges_node_slabs_edges_node_commercial_variety_materialtype | null;
  annexedDocuments: Project_project_packings_edges_node_slabs_edges_node_commercial_variety_annexedDocuments | null;
}

export interface Project_project_packings_edges_node_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface Project_project_packings_edges_node_slabs_edges_node_commercial_finishing_observation {
  pt: string;
}

export interface Project_project_packings_edges_node_slabs_edges_node_commercial_finishing {
  code: string;
  description: Project_project_packings_edges_node_slabs_edges_node_commercial_finishing_description;
  id: string;
  observation: Project_project_packings_edges_node_slabs_edges_node_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface Project_project_packings_edges_node_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface Project_project_packings_edges_node_slabs_edges_node_typematerial {
  description: Project_project_packings_edges_node_slabs_edges_node_typematerial_description;
}

export interface Project_project_packings_edges_node_slabs_edges_node_category_description {
  pt: string;
}

export interface Project_project_packings_edges_node_slabs_edges_node_category {
  description: Project_project_packings_edges_node_slabs_edges_node_category_description;
}

export interface Project_project_packings_edges_node_slabs_edges_node_finish_description {
  pt: string;
}

export interface Project_project_packings_edges_node_slabs_edges_node_finish {
  description: Project_project_packings_edges_node_slabs_edges_node_finish_description;
}

export interface Project_project_packings_edges_node_slabs_edges_node_variety {
  normativedesig: string;
}

export interface Project_project_packings_edges_node_slabs_edges_node {
  id: string | null;
  code: string;
  name: string;
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: Project_project_packings_edges_node_slabs_edges_node_slabImages[];
  width: number | null;
  height: number | null;
  thickness: number | null;
  createdate: weScanDateTime | null;
  blocknumber: string | null;
  inStock: boolean;
  company: Project_project_packings_edges_node_slabs_edges_node_company | null;
  commercial_variety: Project_project_packings_edges_node_slabs_edges_node_commercial_variety | null;
  commercial_finishing: Project_project_packings_edges_node_slabs_edges_node_commercial_finishing | null;
  typematerial: Project_project_packings_edges_node_slabs_edges_node_typematerial | null;
  category: Project_project_packings_edges_node_slabs_edges_node_category | null;
  finish: Project_project_packings_edges_node_slabs_edges_node_finish | null;
  variety: Project_project_packings_edges_node_slabs_edges_node_variety | null;
}

export interface Project_project_packings_edges_node_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: Project_project_packings_edges_node_slabs_edges_node;
}

export interface Project_project_packings_edges_node_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: Project_project_packings_edges_node_slabs_edges[];
}

export interface Project_project_packings_edges_node {
  code: string;
  id: string;
  packing: string;
  observation: string | null;
  packingtype: Project_project_packings_edges_node_packingtype | null;
  slabs: Project_project_packings_edges_node_slabs | null;
}

export interface Project_project_packings_edges {
  /**
   * The node containing the Packing
   */
  node: Project_project_packings_edges_node;
}

export interface Project_project_packings {
  /**
   * Array of edges.
   */
  edges: Project_project_packings_edges[];
}

export interface Project_project_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface Project_project_slabs_edges_node_company {
  code: string;
  name: string;
}

export interface Project_project_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface Project_project_slabs_edges_node_commercial_variety_materialtype {
  code: string;
  description: Project_project_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface Project_project_slabs_edges_node_commercial_variety_annexedDocuments_nodes {
  /**
   * File URL
   */
  blob_url: string;
  id: string;
  /**
   * File Mime Type
   */
  mimetype: string;
  /**
   * Document name
   */
  name: string;
}

export interface Project_project_slabs_edges_node_commercial_variety_annexedDocuments {
  /**
   * Array of nodes.
   */
  nodes: Project_project_slabs_edges_node_commercial_variety_annexedDocuments_nodes[];
}

export interface Project_project_slabs_edges_node_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: Project_project_slabs_edges_node_commercial_variety_materialtype | null;
  annexedDocuments: Project_project_slabs_edges_node_commercial_variety_annexedDocuments | null;
}

export interface Project_project_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface Project_project_slabs_edges_node_commercial_finishing_observation {
  pt: string;
}

export interface Project_project_slabs_edges_node_commercial_finishing {
  code: string;
  description: Project_project_slabs_edges_node_commercial_finishing_description;
  id: string;
  observation: Project_project_slabs_edges_node_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface Project_project_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface Project_project_slabs_edges_node_typematerial {
  description: Project_project_slabs_edges_node_typematerial_description;
}

export interface Project_project_slabs_edges_node_category_description {
  pt: string;
}

export interface Project_project_slabs_edges_node_category {
  description: Project_project_slabs_edges_node_category_description;
}

export interface Project_project_slabs_edges_node_finish_description {
  pt: string;
}

export interface Project_project_slabs_edges_node_finish {
  description: Project_project_slabs_edges_node_finish_description;
}

export interface Project_project_slabs_edges_node_variety {
  normativedesig: string;
}

export interface Project_project_slabs_edges_node {
  id: string | null;
  code: string;
  name: string;
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: Project_project_slabs_edges_node_slabImages[];
  width: number | null;
  height: number | null;
  thickness: number | null;
  createdate: weScanDateTime | null;
  blocknumber: string | null;
  inStock: boolean;
  company: Project_project_slabs_edges_node_company | null;
  commercial_variety: Project_project_slabs_edges_node_commercial_variety | null;
  commercial_finishing: Project_project_slabs_edges_node_commercial_finishing | null;
  typematerial: Project_project_slabs_edges_node_typematerial | null;
  category: Project_project_slabs_edges_node_category | null;
  finish: Project_project_slabs_edges_node_finish | null;
  variety: Project_project_slabs_edges_node_variety | null;
}

export interface Project_project_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: Project_project_slabs_edges_node;
}

export interface Project_project_slabs {
  /**
   * Array of edges.
   */
  edges: Project_project_slabs_edges[];
}

export interface Project_project {
  totalArea: number | null;
  createdAt: weScanDateTime | null;
  createdBy: Project_project_createdBy | null;
  description: string | null;
  id: string;
  updatedAt: weScanDateTime | null;
  active: boolean | null;
  code: string;
  project: string;
  inStock: boolean | null;
  observation: string | null;
  packings: Project_project_packings | null;
  slabs: Project_project_slabs | null;
}

export interface Project {
  project: Project_project | null;
}

export interface ProjectVariables {
  id: string;
  slabsPaging?: CursorPaging | null;
  packagesPaging?: CursorPaging | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectSimpleForEdit
// ====================================================

export interface ProjectSimpleForEdit_project {
  description: string | null;
  id: string;
  code: string;
  project: string;
  observation: string | null;
}

export interface ProjectSimpleForEdit {
  project: ProjectSimpleForEdit_project | null;
}

export interface ProjectSimpleForEditVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Projects
// ====================================================

export interface Projects_projects_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface Projects_projects_edges_node_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface Projects_projects_edges_node_packings {
  /**
   * Fetch total count of records
   */
  totalCount: number;
}

export interface Projects_projects_edges_node_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
}

export interface Projects_projects_edges_node {
  observation: string | null;
  createdAt: weScanDateTime | null;
  createdBy: Projects_projects_edges_node_createdBy | null;
  description: string | null;
  id: string;
  updatedAt: weScanDateTime | null;
  active: boolean | null;
  code: string;
  project: string;
  packings: Projects_projects_edges_node_packings | null;
  slabs: Projects_projects_edges_node_slabs | null;
}

export interface Projects_projects_edges {
  /**
   * The node containing the Project
   */
  node: Projects_projects_edges_node;
}

export interface Projects_projects {
  /**
   * Paging information
   */
  pageInfo: Projects_projects_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: Projects_projects_edges[];
}

export interface Projects {
  projects: Projects_projects;
}

export interface ProjectsVariables {
  filter?: ProjectFilter | null;
  paging?: CursorPaging | null;
  sorting?: ProjectSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedProject
// ====================================================

export interface SharedProject_sharedProject_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface SharedProject_sharedProject_packings_edges_node_packingtype {
  id: string;
  code: string;
  type: string | null;
  observation: string | null;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_company {
  code: string;
  name: string;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_materialtype {
  code: string;
  description: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_annexedDocuments_nodes {
  /**
   * File URL
   */
  blob_url: string;
  id: string;
  /**
   * File Mime Type
   */
  mimetype: string;
  /**
   * Document name
   */
  name: string;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_annexedDocuments {
  /**
   * Array of nodes.
   */
  nodes: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_annexedDocuments_nodes[];
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_materialtype | null;
  annexedDocuments: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_annexedDocuments | null;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_finishing_observation {
  pt: string;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_finishing {
  code: string;
  description: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_finishing_description;
  id: string;
  observation: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_typematerial {
  description: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_typematerial_description;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_category_description {
  pt: string;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_category {
  description: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_category_description;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_finish_description {
  pt: string;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_finish {
  description: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_finish_description;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node_variety {
  normativedesig: string;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges_node {
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_slabImages[];
  id: string | null;
  code: string;
  name: string;
  images: string[];
  width: number | null;
  height: number | null;
  thickness: number | null;
  createdate: weScanDateTime | null;
  blocknumber: string | null;
  inStock: boolean;
  company: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_company | null;
  commercial_variety: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety | null;
  commercial_finishing: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_commercial_finishing | null;
  typematerial: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_typematerial | null;
  category: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_category | null;
  finish: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_finish | null;
  variety: SharedProject_sharedProject_packings_edges_node_slabs_edges_node_variety | null;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: SharedProject_sharedProject_packings_edges_node_slabs_edges_node;
}

export interface SharedProject_sharedProject_packings_edges_node_slabs {
  /**
   * Array of edges.
   */
  edges: SharedProject_sharedProject_packings_edges_node_slabs_edges[];
}

export interface SharedProject_sharedProject_packings_edges_node {
  code: string;
  id: string;
  packing: string;
  observation: string | null;
  packingtype: SharedProject_sharedProject_packings_edges_node_packingtype | null;
  slabs: SharedProject_sharedProject_packings_edges_node_slabs | null;
}

export interface SharedProject_sharedProject_packings_edges {
  /**
   * The node containing the Packing
   */
  node: SharedProject_sharedProject_packings_edges_node;
}

export interface SharedProject_sharedProject_packings {
  /**
   * Array of edges.
   */
  edges: SharedProject_sharedProject_packings_edges[];
}

export interface SharedProject_sharedProject_slabs_edges_node_company {
  code: string;
  name: string;
}

export interface SharedProject_sharedProject_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SharedProject_sharedProject_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface SharedProject_sharedProject_slabs_edges_node_commercial_variety_materialtype {
  code: string;
  description: SharedProject_sharedProject_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface SharedProject_sharedProject_slabs_edges_node_commercial_variety_annexedDocuments_nodes {
  /**
   * File URL
   */
  blob_url: string;
  id: string;
  /**
   * File Mime Type
   */
  mimetype: string;
  /**
   * Document name
   */
  name: string;
}

export interface SharedProject_sharedProject_slabs_edges_node_commercial_variety_annexedDocuments {
  /**
   * Array of nodes.
   */
  nodes: SharedProject_sharedProject_slabs_edges_node_commercial_variety_annexedDocuments_nodes[];
}

export interface SharedProject_sharedProject_slabs_edges_node_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: SharedProject_sharedProject_slabs_edges_node_commercial_variety_materialtype | null;
  annexedDocuments: SharedProject_sharedProject_slabs_edges_node_commercial_variety_annexedDocuments | null;
}

export interface SharedProject_sharedProject_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface SharedProject_sharedProject_slabs_edges_node_commercial_finishing_observation {
  pt: string;
}

export interface SharedProject_sharedProject_slabs_edges_node_commercial_finishing {
  code: string;
  description: SharedProject_sharedProject_slabs_edges_node_commercial_finishing_description;
  id: string;
  observation: SharedProject_sharedProject_slabs_edges_node_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface SharedProject_sharedProject_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface SharedProject_sharedProject_slabs_edges_node_typematerial {
  description: SharedProject_sharedProject_slabs_edges_node_typematerial_description;
}

export interface SharedProject_sharedProject_slabs_edges_node_category_description {
  pt: string;
}

export interface SharedProject_sharedProject_slabs_edges_node_category {
  description: SharedProject_sharedProject_slabs_edges_node_category_description;
}

export interface SharedProject_sharedProject_slabs_edges_node_finish_description {
  pt: string;
}

export interface SharedProject_sharedProject_slabs_edges_node_finish {
  description: SharedProject_sharedProject_slabs_edges_node_finish_description;
}

export interface SharedProject_sharedProject_slabs_edges_node_variety {
  normativedesig: string;
}

export interface SharedProject_sharedProject_slabs_edges_node {
  id: string | null;
  code: string;
  name: string;
  images: string[];
  width: number | null;
  height: number | null;
  thickness: number | null;
  createdate: weScanDateTime | null;
  blocknumber: string | null;
  inStock: boolean;
  company: SharedProject_sharedProject_slabs_edges_node_company | null;
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SharedProject_sharedProject_slabs_edges_node_slabImages[];
  commercial_variety: SharedProject_sharedProject_slabs_edges_node_commercial_variety | null;
  commercial_finishing: SharedProject_sharedProject_slabs_edges_node_commercial_finishing | null;
  typematerial: SharedProject_sharedProject_slabs_edges_node_typematerial | null;
  category: SharedProject_sharedProject_slabs_edges_node_category | null;
  finish: SharedProject_sharedProject_slabs_edges_node_finish | null;
  variety: SharedProject_sharedProject_slabs_edges_node_variety | null;
}

export interface SharedProject_sharedProject_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: SharedProject_sharedProject_slabs_edges_node;
}

export interface SharedProject_sharedProject_slabs {
  /**
   * Array of edges.
   */
  edges: SharedProject_sharedProject_slabs_edges[];
}

export interface SharedProject_sharedProject {
  createdAt: weScanDateTime | null;
  observation: string | null;
  createdBy: SharedProject_sharedProject_createdBy | null;
  description: string | null;
  id: string;
  updatedAt: weScanDateTime | null;
  active: boolean | null;
  code: string;
  project: string;
  packings: SharedProject_sharedProject_packings | null;
  slabs: SharedProject_sharedProject_slabs | null;
}

export interface SharedProject {
  sharedProject: SharedProject_sharedProject;
}

export interface SharedProjectVariables {
  keyValue?: string | null;
  id?: string | null;
  slabsPaging?: CursorPaging | null;
  slabsFilter?: SlabFilter | null;
  slabsSorting?: SlabSort[] | null;
  packagesPaging?: CursorPaging | null;
  packagesFilter?: PackingFilter | null;
  packagesSorting?: PackingSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectGallery
// ====================================================

export interface ProjectGallery_projects_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_commercial_variety_materialtype {
  description: ProjectGallery_projects_edges_node_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_commercial_variety {
  name: string | null;
  materialtype: ProjectGallery_projects_edges_node_slabs_edges_node_commercial_variety_materialtype | null;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_typematerial {
  description: ProjectGallery_projects_edges_node_slabs_edges_node_typematerial_description;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_finish_description {
  pt: string;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_finish {
  description: ProjectGallery_projects_edges_node_slabs_edges_node_finish_description;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_variety {
  normativedesig: string;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node_commercial_finishing {
  description: ProjectGallery_projects_edges_node_slabs_edges_node_commercial_finishing_description;
}

export interface ProjectGallery_projects_edges_node_slabs_edges_node {
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: ProjectGallery_projects_edges_node_slabs_edges_node_slabImages[];
  id: string | null;
  code: string;
  commercial_variety: ProjectGallery_projects_edges_node_slabs_edges_node_commercial_variety | null;
  typematerial: ProjectGallery_projects_edges_node_slabs_edges_node_typematerial | null;
  finish: ProjectGallery_projects_edges_node_slabs_edges_node_finish | null;
  variety: ProjectGallery_projects_edges_node_slabs_edges_node_variety | null;
  commercial_finishing: ProjectGallery_projects_edges_node_slabs_edges_node_commercial_finishing | null;
}

export interface ProjectGallery_projects_edges_node_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: ProjectGallery_projects_edges_node_slabs_edges_node;
}

export interface ProjectGallery_projects_edges_node_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: ProjectGallery_projects_edges_node_slabs_edges[];
}

export interface ProjectGallery_projects_edges_node_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface ProjectGallery_projects_edges_node {
  slabs: ProjectGallery_projects_edges_node_slabs | null;
  id: string;
  code: string;
  description: string | null;
  observation: string | null;
  inStock: boolean | null;
  createdAt: weScanDateTime | null;
  createdBy: ProjectGallery_projects_edges_node_createdBy | null;
  updatedAt: weScanDateTime | null;
  project: string;
}

export interface ProjectGallery_projects_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Project
   */
  node: ProjectGallery_projects_edges_node;
}

export interface ProjectGallery_projects {
  /**
   * Paging information
   */
  pageInfo: ProjectGallery_projects_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: ProjectGallery_projects_edges[];
}

export interface ProjectGallery {
  projects: ProjectGallery_projects;
}

export interface ProjectGalleryVariables {
  filter?: ProjectFilter | null;
  paging?: CursorPaging | null;
  sorting?: ProjectSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedProjectSlabs
// ====================================================

export interface SharedProjectSlabs_sharedProject_slabs_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_company {
  code: string;
  name: string;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_variety_materialtype {
  code: string;
  description: SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_variety_annexedDocuments_nodes {
  /**
   * File URL
   */
  blob_url: string;
  id: string;
  /**
   * File Mime Type
   */
  mimetype: string;
  /**
   * Document name
   */
  name: string;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_variety_annexedDocuments {
  /**
   * Array of nodes.
   */
  nodes: SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_variety_annexedDocuments_nodes[];
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_variety_materialtype | null;
  annexedDocuments: SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_variety_annexedDocuments | null;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_finishing_observation {
  pt: string;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_finishing {
  code: string;
  description: SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_finishing_description;
  id: string;
  observation: SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_typematerial {
  description: SharedProjectSlabs_sharedProject_slabs_edges_node_typematerial_description;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_category_description {
  pt: string;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_category {
  description: SharedProjectSlabs_sharedProject_slabs_edges_node_category_description;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_finish_description {
  pt: string;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_finish {
  description: SharedProjectSlabs_sharedProject_slabs_edges_node_finish_description;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node_variety {
  normativedesig: string;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges_node {
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SharedProjectSlabs_sharedProject_slabs_edges_node_slabImages[];
  id: string | null;
  code: string;
  name: string;
  images: string[];
  width: number | null;
  height: number | null;
  thickness: number | null;
  createdate: weScanDateTime | null;
  blocknumber: string | null;
  inStock: boolean;
  company: SharedProjectSlabs_sharedProject_slabs_edges_node_company | null;
  commercial_variety: SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_variety | null;
  commercial_finishing: SharedProjectSlabs_sharedProject_slabs_edges_node_commercial_finishing | null;
  typematerial: SharedProjectSlabs_sharedProject_slabs_edges_node_typematerial | null;
  category: SharedProjectSlabs_sharedProject_slabs_edges_node_category | null;
  finish: SharedProjectSlabs_sharedProject_slabs_edges_node_finish | null;
  variety: SharedProjectSlabs_sharedProject_slabs_edges_node_variety | null;
}

export interface SharedProjectSlabs_sharedProject_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: SharedProjectSlabs_sharedProject_slabs_edges_node;
}

export interface SharedProjectSlabs_sharedProject_slabs {
  /**
   * Paging information
   */
  pageInfo: SharedProjectSlabs_sharedProject_slabs_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: SharedProjectSlabs_sharedProject_slabs_edges[];
}

export interface SharedProjectSlabs_sharedProject {
  id: string;
  slabs: SharedProjectSlabs_sharedProject_slabs | null;
}

export interface SharedProjectSlabs {
  sharedProject: SharedProjectSlabs_sharedProject;
}

export interface SharedProjectSlabsVariables {
  keyValue?: string | null;
  id?: string | null;
  slabsPaging?: CursorPaging | null;
  slabsFilter?: SlabFilter | null;
  slabsSorting?: SlabSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedProjectPackages
// ====================================================

export interface SharedProjectPackages_sharedProject_packings_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_materialtype {
  description: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety {
  name: string | null;
  materialtype: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety_materialtype | null;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_typematerial {
  description: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_typematerial_description;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_finish_description {
  pt: string;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_finish {
  description: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_finish_description;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_variety {
  normativedesig: string;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_commercial_finishing {
  description: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_commercial_finishing_description;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node {
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_slabImages[];
  id: string | null;
  code: string;
  commercial_variety: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_commercial_variety | null;
  typematerial: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_typematerial | null;
  finish: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_finish | null;
  variety: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_variety | null;
  commercial_finishing: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node_commercial_finishing | null;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges_node;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: SharedProjectPackages_sharedProject_packings_edges_node_slabs_edges[];
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_packingtype {
  id: string;
  code: string;
  type: string | null;
  observation: string | null;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node_project {
  id: string;
  code: string;
  project: string;
}

export interface SharedProjectPackages_sharedProject_packings_edges_node {
  slabs: SharedProjectPackages_sharedProject_packings_edges_node_slabs | null;
  id: string;
  code: string;
  packing: string;
  observation: string | null;
  inStock: boolean;
  packingtype: SharedProjectPackages_sharedProject_packings_edges_node_packingtype | null;
  createdAt: weScanDateTime | null;
  createdBy: SharedProjectPackages_sharedProject_packings_edges_node_createdBy | null;
  updatedAt: weScanDateTime | null;
  project: SharedProjectPackages_sharedProject_packings_edges_node_project | null;
}

export interface SharedProjectPackages_sharedProject_packings_edges {
  /**
   * The node containing the Packing
   */
  node: SharedProjectPackages_sharedProject_packings_edges_node;
}

export interface SharedProjectPackages_sharedProject_packings {
  /**
   * Paging information
   */
  pageInfo: SharedProjectPackages_sharedProject_packings_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: SharedProjectPackages_sharedProject_packings_edges[];
}

export interface SharedProjectPackages_sharedProject {
  id: string;
  packings: SharedProjectPackages_sharedProject_packings | null;
}

export interface SharedProjectPackages {
  sharedProject: SharedProjectPackages_sharedProject;
}

export interface SharedProjectPackagesVariables {
  keyValue?: string | null;
  id?: string | null;
  packagesPaging?: CursorPaging | null;
  packagesFilter?: PackingFilter | null;
  packagesSorting?: PackingSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Packing
// ====================================================

export interface Packing_packing_packingtype {
  id: string;
  code: string;
  type: string | null;
  observation: string | null;
}

export interface Packing_packing_project {
  id: string;
  project: string;
  code: string;
}

export interface Packing_packing_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface Packing_packing_slabs_edges_node_company {
  code: string;
  name: string;
}

export interface Packing_packing_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface Packing_packing_slabs_edges_node_commercial_variety_materialtype {
  code: string;
  description: Packing_packing_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface Packing_packing_slabs_edges_node_commercial_variety_annexedDocuments_nodes {
  /**
   * File URL
   */
  blob_url: string;
  id: string;
  /**
   * File Mime Type
   */
  mimetype: string;
  /**
   * Document name
   */
  name: string;
}

export interface Packing_packing_slabs_edges_node_commercial_variety_annexedDocuments {
  /**
   * Array of nodes.
   */
  nodes: Packing_packing_slabs_edges_node_commercial_variety_annexedDocuments_nodes[];
}

export interface Packing_packing_slabs_edges_node_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: Packing_packing_slabs_edges_node_commercial_variety_materialtype | null;
  annexedDocuments: Packing_packing_slabs_edges_node_commercial_variety_annexedDocuments | null;
}

export interface Packing_packing_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface Packing_packing_slabs_edges_node_commercial_finishing_observation {
  pt: string;
}

export interface Packing_packing_slabs_edges_node_commercial_finishing {
  code: string;
  description: Packing_packing_slabs_edges_node_commercial_finishing_description;
  id: string;
  observation: Packing_packing_slabs_edges_node_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface Packing_packing_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface Packing_packing_slabs_edges_node_typematerial {
  description: Packing_packing_slabs_edges_node_typematerial_description;
}

export interface Packing_packing_slabs_edges_node_category_description {
  pt: string;
}

export interface Packing_packing_slabs_edges_node_category {
  description: Packing_packing_slabs_edges_node_category_description;
}

export interface Packing_packing_slabs_edges_node_finish_description {
  pt: string;
}

export interface Packing_packing_slabs_edges_node_finish {
  description: Packing_packing_slabs_edges_node_finish_description;
}

export interface Packing_packing_slabs_edges_node_variety {
  normativedesig: string;
}

export interface Packing_packing_slabs_edges_node {
  id: string | null;
  code: string;
  name: string;
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: Packing_packing_slabs_edges_node_slabImages[];
  width: number | null;
  height: number | null;
  thickness: number | null;
  createdate: weScanDateTime | null;
  blocknumber: string | null;
  inStock: boolean;
  company: Packing_packing_slabs_edges_node_company | null;
  commercial_variety: Packing_packing_slabs_edges_node_commercial_variety | null;
  commercial_finishing: Packing_packing_slabs_edges_node_commercial_finishing | null;
  typematerial: Packing_packing_slabs_edges_node_typematerial | null;
  category: Packing_packing_slabs_edges_node_category | null;
  finish: Packing_packing_slabs_edges_node_finish | null;
  variety: Packing_packing_slabs_edges_node_variety | null;
}

export interface Packing_packing_slabs_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Slab
   */
  node: Packing_packing_slabs_edges_node;
}

export interface Packing_packing_slabs {
  /**
   * Array of edges.
   */
  edges: Packing_packing_slabs_edges[];
}

export interface Packing_packing {
  id: string;
  code: string;
  packing: string;
  observation: string | null;
  inStock: boolean;
  packingtype: Packing_packing_packingtype | null;
  project: Packing_packing_project | null;
  slabs: Packing_packing_slabs | null;
}

export interface Packing {
  packing: Packing_packing | null;
}

export interface PackingVariables {
  id: string;
  slabsPaging?: CursorPaging | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Packings
// ====================================================

export interface Packings_packings_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface Packings_packings_edges_node_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
}

export interface Packings_packings_edges_node_packingtype {
  id: string;
  code: string;
  type: string | null;
  observation: string | null;
}

export interface Packings_packings_edges_node_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface Packings_packings_edges_node_project {
  id: string;
  code: string;
  project: string;
}

export interface Packings_packings_edges_node {
  slabs: Packings_packings_edges_node_slabs | null;
  id: string;
  code: string;
  packing: string;
  observation: string | null;
  inStock: boolean;
  packingtype: Packings_packings_edges_node_packingtype | null;
  createdAt: weScanDateTime | null;
  createdBy: Packings_packings_edges_node_createdBy | null;
  updatedAt: weScanDateTime | null;
  project: Packings_packings_edges_node_project | null;
}

export interface Packings_packings_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Packing
   */
  node: Packings_packings_edges_node;
}

export interface Packings_packings {
  /**
   * Paging information
   */
  pageInfo: Packings_packings_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: Packings_packings_edges[];
}

export interface Packings {
  packings: Packings_packings;
}

export interface PackingsVariables {
  filter?: PackingFilter | null;
  paging?: CursorPaging | null;
  sorting?: PackingSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PackingsGallery
// ====================================================

export interface PackingsGallery_packings_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_commercial_variety_materialtype {
  description: PackingsGallery_packings_edges_node_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_commercial_variety {
  name: string | null;
  materialtype: PackingsGallery_packings_edges_node_slabs_edges_node_commercial_variety_materialtype | null;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_typematerial {
  description: PackingsGallery_packings_edges_node_slabs_edges_node_typematerial_description;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_finish_description {
  pt: string;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_finish {
  description: PackingsGallery_packings_edges_node_slabs_edges_node_finish_description;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_variety {
  normativedesig: string;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node_commercial_finishing {
  description: PackingsGallery_packings_edges_node_slabs_edges_node_commercial_finishing_description;
}

export interface PackingsGallery_packings_edges_node_slabs_edges_node {
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: PackingsGallery_packings_edges_node_slabs_edges_node_slabImages[];
  id: string | null;
  code: string;
  commercial_variety: PackingsGallery_packings_edges_node_slabs_edges_node_commercial_variety | null;
  typematerial: PackingsGallery_packings_edges_node_slabs_edges_node_typematerial | null;
  finish: PackingsGallery_packings_edges_node_slabs_edges_node_finish | null;
  variety: PackingsGallery_packings_edges_node_slabs_edges_node_variety | null;
  commercial_finishing: PackingsGallery_packings_edges_node_slabs_edges_node_commercial_finishing | null;
}

export interface PackingsGallery_packings_edges_node_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: PackingsGallery_packings_edges_node_slabs_edges_node;
}

export interface PackingsGallery_packings_edges_node_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: PackingsGallery_packings_edges_node_slabs_edges[];
}

export interface PackingsGallery_packings_edges_node_packingtype {
  id: string;
  code: string;
  type: string | null;
  observation: string | null;
}

export interface PackingsGallery_packings_edges_node_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface PackingsGallery_packings_edges_node_project {
  id: string;
  code: string;
  project: string;
}

export interface PackingsGallery_packings_edges_node {
  slabs: PackingsGallery_packings_edges_node_slabs | null;
  id: string;
  code: string;
  packing: string;
  observation: string | null;
  inStock: boolean;
  packingtype: PackingsGallery_packings_edges_node_packingtype | null;
  createdAt: weScanDateTime | null;
  createdBy: PackingsGallery_packings_edges_node_createdBy | null;
  updatedAt: weScanDateTime | null;
  project: PackingsGallery_packings_edges_node_project | null;
}

export interface PackingsGallery_packings_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Packing
   */
  node: PackingsGallery_packings_edges_node;
}

export interface PackingsGallery_packings {
  /**
   * Paging information
   */
  pageInfo: PackingsGallery_packings_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: PackingsGallery_packings_edges[];
}

export interface PackingsGallery {
  packings: PackingsGallery_packings;
}

export interface PackingsGalleryVariables {
  filter?: PackingFilter | null;
  paging?: CursorPaging | null;
  sorting?: PackingSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedPackage
// ====================================================

export interface SharedPackage_sharedPackage_packingtype {
  id: string;
  code: string;
  type: string | null;
  observation: string | null;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_company {
  code: string;
  name: string;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_commercial_variety_materialtype {
  code: string;
  description: SharedPackage_sharedPackage_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_commercial_variety_annexedDocuments_nodes {
  /**
   * File URL
   */
  blob_url: string;
  id: string;
  /**
   * File Mime Type
   */
  mimetype: string;
  /**
   * Document name
   */
  name: string;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_commercial_variety_annexedDocuments {
  /**
   * Array of nodes.
   */
  nodes: SharedPackage_sharedPackage_slabs_edges_node_commercial_variety_annexedDocuments_nodes[];
}

export interface SharedPackage_sharedPackage_slabs_edges_node_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: SharedPackage_sharedPackage_slabs_edges_node_commercial_variety_materialtype | null;
  annexedDocuments: SharedPackage_sharedPackage_slabs_edges_node_commercial_variety_annexedDocuments | null;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_commercial_finishing_observation {
  pt: string;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_commercial_finishing {
  code: string;
  description: SharedPackage_sharedPackage_slabs_edges_node_commercial_finishing_description;
  id: string;
  observation: SharedPackage_sharedPackage_slabs_edges_node_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_typematerial {
  description: SharedPackage_sharedPackage_slabs_edges_node_typematerial_description;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_category_description {
  pt: string;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_category {
  description: SharedPackage_sharedPackage_slabs_edges_node_category_description;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_finish_description {
  pt: string;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_finish {
  description: SharedPackage_sharedPackage_slabs_edges_node_finish_description;
}

export interface SharedPackage_sharedPackage_slabs_edges_node_variety {
  normativedesig: string;
}

export interface SharedPackage_sharedPackage_slabs_edges_node {
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SharedPackage_sharedPackage_slabs_edges_node_slabImages[];
  id: string | null;
  code: string;
  name: string;
  images: string[];
  width: number | null;
  height: number | null;
  thickness: number | null;
  createdate: weScanDateTime | null;
  blocknumber: string | null;
  inStock: boolean;
  company: SharedPackage_sharedPackage_slabs_edges_node_company | null;
  commercial_variety: SharedPackage_sharedPackage_slabs_edges_node_commercial_variety | null;
  commercial_finishing: SharedPackage_sharedPackage_slabs_edges_node_commercial_finishing | null;
  typematerial: SharedPackage_sharedPackage_slabs_edges_node_typematerial | null;
  category: SharedPackage_sharedPackage_slabs_edges_node_category | null;
  finish: SharedPackage_sharedPackage_slabs_edges_node_finish | null;
  variety: SharedPackage_sharedPackage_slabs_edges_node_variety | null;
}

export interface SharedPackage_sharedPackage_slabs_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Slab
   */
  node: SharedPackage_sharedPackage_slabs_edges_node;
}

export interface SharedPackage_sharedPackage_slabs {
  /**
   * Array of edges.
   */
  edges: SharedPackage_sharedPackage_slabs_edges[];
}

export interface SharedPackage_sharedPackage {
  id: string;
  code: string;
  packing: string;
  observation: string | null;
  inStock: boolean;
  packingtype: SharedPackage_sharedPackage_packingtype | null;
  slabs: SharedPackage_sharedPackage_slabs | null;
}

export interface SharedPackage {
  sharedPackage: SharedPackage_sharedPackage;
}

export interface SharedPackageVariables {
  keyValue?: string | null;
  id?: string | null;
  slabsPaging?: CursorPaging | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedPackages
// ====================================================

export interface SharedPackages_sharedPackages_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_commercial_variety_materialtype {
  description: SharedPackages_sharedPackages_edges_node_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_commercial_variety {
  name: string | null;
  materialtype: SharedPackages_sharedPackages_edges_node_slabs_edges_node_commercial_variety_materialtype | null;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_typematerial {
  description: SharedPackages_sharedPackages_edges_node_slabs_edges_node_typematerial_description;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_finish_description {
  pt: string;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_finish {
  description: SharedPackages_sharedPackages_edges_node_slabs_edges_node_finish_description;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_variety {
  normativedesig: string;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node_commercial_finishing {
  description: SharedPackages_sharedPackages_edges_node_slabs_edges_node_commercial_finishing_description;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges_node {
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SharedPackages_sharedPackages_edges_node_slabs_edges_node_slabImages[];
  id: string | null;
  code: string;
  commercial_variety: SharedPackages_sharedPackages_edges_node_slabs_edges_node_commercial_variety | null;
  typematerial: SharedPackages_sharedPackages_edges_node_slabs_edges_node_typematerial | null;
  finish: SharedPackages_sharedPackages_edges_node_slabs_edges_node_finish | null;
  variety: SharedPackages_sharedPackages_edges_node_slabs_edges_node_variety | null;
  commercial_finishing: SharedPackages_sharedPackages_edges_node_slabs_edges_node_commercial_finishing | null;
}

export interface SharedPackages_sharedPackages_edges_node_slabs_edges {
  /**
   * The node containing the Slab
   */
  node: SharedPackages_sharedPackages_edges_node_slabs_edges_node;
}

export interface SharedPackages_sharedPackages_edges_node_slabs {
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: SharedPackages_sharedPackages_edges_node_slabs_edges[];
}

export interface SharedPackages_sharedPackages_edges_node_packingtype {
  id: string;
  code: string;
  type: string | null;
  observation: string | null;
}

export interface SharedPackages_sharedPackages_edges_node_createdBy {
  id: string;
  name: string | null;
  lastname: string | null;
  firstname: string | null;
}

export interface SharedPackages_sharedPackages_edges_node_project {
  id: string;
  code: string;
  project: string;
}

export interface SharedPackages_sharedPackages_edges_node {
  slabs: SharedPackages_sharedPackages_edges_node_slabs | null;
  id: string;
  code: string;
  packing: string;
  observation: string | null;
  inStock: boolean;
  packingtype: SharedPackages_sharedPackages_edges_node_packingtype | null;
  createdAt: weScanDateTime | null;
  createdBy: SharedPackages_sharedPackages_edges_node_createdBy | null;
  updatedAt: weScanDateTime | null;
  project: SharedPackages_sharedPackages_edges_node_project | null;
}

export interface SharedPackages_sharedPackages_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Packing
   */
  node: SharedPackages_sharedPackages_edges_node;
}

export interface SharedPackages_sharedPackages {
  /**
   * Paging information
   */
  pageInfo: SharedPackages_sharedPackages_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: SharedPackages_sharedPackages_edges[];
}

export interface SharedPackages {
  sharedPackages: SharedPackages_sharedPackages;
}

export interface SharedPackagesVariables {
  filter?: PackingFilter | null;
  paging?: CursorPaging | null;
  sorting?: PackingSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedPackageSlabs
// ====================================================

export interface SharedPackageSlabs_sharedPackage_slabs_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_company {
  code: string;
  name: string;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_variety_materialtype_description {
  pt: string;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_variety_materialtype {
  code: string;
  description: SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_variety_materialtype_description;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_variety_annexedDocuments_nodes {
  /**
   * File URL
   */
  blob_url: string;
  id: string;
  /**
   * File Mime Type
   */
  mimetype: string;
  /**
   * Document name
   */
  name: string;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_variety_annexedDocuments {
  /**
   * Array of nodes.
   */
  nodes: SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_variety_annexedDocuments_nodes[];
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_variety_materialtype | null;
  annexedDocuments: SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_variety_annexedDocuments | null;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_finishing_description {
  pt: string;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_finishing_observation {
  pt: string;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_finishing {
  code: string;
  description: SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_finishing_description;
  id: string;
  observation: SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_typematerial_description {
  pt: string;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_typematerial {
  description: SharedPackageSlabs_sharedPackage_slabs_edges_node_typematerial_description;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_category_description {
  pt: string;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_category {
  description: SharedPackageSlabs_sharedPackage_slabs_edges_node_category_description;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_finish_description {
  pt: string;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_finish {
  description: SharedPackageSlabs_sharedPackage_slabs_edges_node_finish_description;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node_variety {
  normativedesig: string;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges_node {
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SharedPackageSlabs_sharedPackage_slabs_edges_node_slabImages[];
  id: string | null;
  code: string;
  name: string;
  images: string[];
  width: number | null;
  height: number | null;
  thickness: number | null;
  createdate: weScanDateTime | null;
  blocknumber: string | null;
  inStock: boolean;
  company: SharedPackageSlabs_sharedPackage_slabs_edges_node_company | null;
  commercial_variety: SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_variety | null;
  commercial_finishing: SharedPackageSlabs_sharedPackage_slabs_edges_node_commercial_finishing | null;
  typematerial: SharedPackageSlabs_sharedPackage_slabs_edges_node_typematerial | null;
  category: SharedPackageSlabs_sharedPackage_slabs_edges_node_category | null;
  finish: SharedPackageSlabs_sharedPackage_slabs_edges_node_finish | null;
  variety: SharedPackageSlabs_sharedPackage_slabs_edges_node_variety | null;
}

export interface SharedPackageSlabs_sharedPackage_slabs_edges {
  /**
   * Cursor for this node.
   */
  cursor: weScanConnectionCursor;
  /**
   * The node containing the Slab
   */
  node: SharedPackageSlabs_sharedPackage_slabs_edges_node;
}

export interface SharedPackageSlabs_sharedPackage_slabs {
  /**
   * Paging information
   */
  pageInfo: SharedPackageSlabs_sharedPackage_slabs_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: SharedPackageSlabs_sharedPackage_slabs_edges[];
}

export interface SharedPackageSlabs_sharedPackage {
  slabs: SharedPackageSlabs_sharedPackage_slabs | null;
}

export interface SharedPackageSlabs {
  sharedPackage: SharedPackageSlabs_sharedPackage;
}

export interface SharedPackageSlabsVariables {
  keyValue?: string | null;
  id?: string | null;
  slabsPaging?: CursorPaging | null;
  slabsFilter?: SlabFilter | null;
  slabsSorting?: SlabSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PackingTypes
// ====================================================

export interface PackingTypes_packingTypes_pageInfo {
  /**
   * The cursor of the last returned record.
   */
  endCursor: weScanConnectionCursor | null;
  /**
   * true if paging forward and there are more records.
   */
  hasNextPage: boolean | null;
  /**
   * true if paging backwards and there are more records.
   */
  hasPreviousPage: boolean | null;
  /**
   * The cursor of the first returned record.
   */
  startCursor: weScanConnectionCursor | null;
}

export interface PackingTypes_packingTypes_edges_node_company {
  id: string;
}

export interface PackingTypes_packingTypes_edges_node {
  id: string;
  code: string;
  observation: string | null;
  createdAt: weScanDateTime | null;
  type: string | null;
  company: PackingTypes_packingTypes_edges_node_company | null;
}

export interface PackingTypes_packingTypes_edges {
  /**
   * The node containing the PackingType
   */
  node: PackingTypes_packingTypes_edges_node;
}

export interface PackingTypes_packingTypes {
  /**
   * Paging information
   */
  pageInfo: PackingTypes_packingTypes_pageInfo;
  /**
   * Fetch total count of records
   */
  totalCount: number;
  /**
   * Array of edges.
   */
  edges: PackingTypes_packingTypes_edges[];
}

export interface PackingTypes {
  packingTypes: PackingTypes_packingTypes;
}

export interface PackingTypesVariables {
  filter?: PackingTypeFilter | null;
  paging?: CursorPaging | null;
  sorting?: PackingTypeSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PackingType
// ====================================================

export interface PackingType_packingType {
  id: string;
  code: string;
  observation: string | null;
  createdAt: weScanDateTime | null;
  type: string | null;
}

export interface PackingType {
  packingType: PackingType_packingType | null;
}

export interface PackingTypeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Counters
// ====================================================

export interface Counters_counters_edges_node_company {
  name: string;
  code: string;
}

export interface Counters_counters_edges_node {
  company: Counters_counters_edges_node_company | null;
  /**
   * Counter Type
   */
  counterType: CounterType;
  createdAt: weScanDateTime | null;
  /**
   * Counter Description
   */
  description: string;
  id: string;
  /**
   * Counter Sequence Value
   */
  sequence_value: number;
}

export interface Counters_counters_edges {
  /**
   * The node containing the Counter
   */
  node: Counters_counters_edges_node;
}

export interface Counters_counters {
  /**
   * Array of edges.
   */
  edges: Counters_counters_edges[];
}

export interface Counters {
  counters: Counters_counters;
}

export interface CountersVariables {
  filter?: CounterFilter | null;
  paging?: CursorPaging | null;
  sorting?: CounterSort[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_login {
  access_token: string;
}

export interface Login {
  login: Login_login;
}

export interface LoginVariables {
  loginInput: LoginUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AutenticateSharedLink
// ====================================================

export interface AutenticateSharedLink_autenticateSharedLink {
  createdAt: weScanDateTime;
  isValid: boolean;
  temporaryToken: string;
  validUntil: weScanDateTime;
}

export interface AutenticateSharedLink {
  autenticateSharedLink: AutenticateSharedLink_autenticateSharedLink;
}

export interface AutenticateSharedLinkVariables {
  keyValue: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadImagesMutation
// ====================================================

export interface UploadImagesMutation_uploadImages {
  id: string | null;
}

export interface UploadImagesMutation {
  uploadImages: UploadImagesMutation_uploadImages[];
}

export interface UploadImagesMutationVariables {
  input: UploadImagesInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteImage
// ====================================================

export interface DeleteImage_deleteImage_error {
  message: string;
  name: string;
}

export interface DeleteImage_deleteImage_response {
  isSuccessful: boolean;
  statusCode: number;
}

export interface DeleteImage_deleteImage {
  error: DeleteImage_deleteImage_error | null;
  response: DeleteImage_deleteImage_response;
  result: boolean;
}

export interface DeleteImage {
  deleteImage: DeleteImage_deleteImage;
}

export interface DeleteImageVariables {
  input: DeleteImageInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOneSlab
// ====================================================

export interface UpdateOneSlab_updateOneSlab_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface UpdateOneSlab_updateOneSlab_typematerial_description {
  pt: string;
}

export interface UpdateOneSlab_updateOneSlab_typematerial {
  id: string;
  description: UpdateOneSlab_updateOneSlab_typematerial_description;
}

export interface UpdateOneSlab_updateOneSlab_category_description {
  pt: string;
}

export interface UpdateOneSlab_updateOneSlab_category {
  id: string;
  description: UpdateOneSlab_updateOneSlab_category_description;
}

export interface UpdateOneSlab_updateOneSlab_finish_description {
  pt: string;
}

export interface UpdateOneSlab_updateOneSlab_finish {
  id: string;
  description: UpdateOneSlab_updateOneSlab_finish_description;
}

export interface UpdateOneSlab_updateOneSlab_project {
  id: string;
  project: string;
}

export interface UpdateOneSlab_updateOneSlab_variety {
  id: string;
  normativedesig: string;
  macroscopicdesc: string | null;
}

export interface UpdateOneSlab_updateOneSlab_scan {
  code: string;
  description: string;
}

export interface UpdateOneSlab_updateOneSlab_job {
  description: string | null;
}

export interface UpdateOneSlab_updateOneSlab_packing {
  id: string;
  code: string;
  packing: string;
}

export interface UpdateOneSlab_updateOneSlab_company {
  code: string;
  name: string;
}

export interface UpdateOneSlab_updateOneSlab_commercial_variety_materialtype_description {
  pt: string;
}

export interface UpdateOneSlab_updateOneSlab_commercial_variety_materialtype {
  code: string;
  description: UpdateOneSlab_updateOneSlab_commercial_variety_materialtype_description;
}

export interface UpdateOneSlab_updateOneSlab_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: UpdateOneSlab_updateOneSlab_commercial_variety_materialtype | null;
}

export interface UpdateOneSlab_updateOneSlab_commercial_finishing_description {
  pt: string;
}

export interface UpdateOneSlab_updateOneSlab_commercial_finishing_observation {
  pt: string;
}

export interface UpdateOneSlab_updateOneSlab_commercial_finishing {
  code: string;
  description: UpdateOneSlab_updateOneSlab_commercial_finishing_description;
  id: string;
  observation: UpdateOneSlab_updateOneSlab_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface UpdateOneSlab_updateOneSlab {
  main_image: string | null;
  id: string | null;
  code: string;
  name: string;
  area: number | null;
  description: string | null;
  desigcomercial: string | null;
  width: number | null;
  height: number | null;
  thickness: number | null;
  extwidth: number | null;
  blocknumber: string | null;
  extheight: number | null;
  extthickness: number | null;
  stkwidth: number | null;
  stkheight: number | null;
  stkthickness: number | null;
  purchasewidth: number | null;
  purchaseheight: number | null;
  purchasethickness: number | null;
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: UpdateOneSlab_updateOneSlab_slabImages[];
  typematerial: UpdateOneSlab_updateOneSlab_typematerial | null;
  category: UpdateOneSlab_updateOneSlab_category | null;
  finish: UpdateOneSlab_updateOneSlab_finish | null;
  project: UpdateOneSlab_updateOneSlab_project | null;
  variety: UpdateOneSlab_updateOneSlab_variety | null;
  observation: string | null;
  createdate: weScanDateTime | null;
  scan: UpdateOneSlab_updateOneSlab_scan | null;
  job: UpdateOneSlab_updateOneSlab_job | null;
  packing: UpdateOneSlab_updateOneSlab_packing | null;
  inStock: boolean;
  company: UpdateOneSlab_updateOneSlab_company | null;
  commercial_variety: UpdateOneSlab_updateOneSlab_commercial_variety | null;
  commercial_finishing: UpdateOneSlab_updateOneSlab_commercial_finishing | null;
}

export interface UpdateOneSlab {
  updateOneSlab: UpdateOneSlab_updateOneSlab;
}

export interface UpdateOneSlabVariables {
  input: UpdateOneSlabInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateManySlabs
// ====================================================

export interface UpdateManySlabs_updateManySlabs {
  /**
   * The number of records updated.
   */
  updatedCount: number;
}

export interface UpdateManySlabs {
  updateManySlabs: UpdateManySlabs_updateManySlabs;
}

export interface UpdateManySlabsVariables {
  input: UpdateManySlabsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadSlabs
// ====================================================

export interface UploadSlabs_uploadSlabs_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface UploadSlabs_uploadSlabs {
  main_image: string | null;
  id: string | null;
  code: string;
  name: string;
  width: number | null;
  height: number | null;
  thickness: number | null;
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: UploadSlabs_uploadSlabs_slabImages[];
  inStock: boolean;
}

export interface UploadSlabs {
  uploadSlabs: UploadSlabs_uploadSlabs[];
}

export interface UploadSlabsVariables {
  input: UploadSlabsWeScanHomeInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteManySlabs
// ====================================================

export interface DeleteManySlabs_deleteManySlabs {
  /**
   * The number of records deleted.
   */
  deletedCount: number;
}

export interface DeleteManySlabs {
  deleteManySlabs: DeleteManySlabs_deleteManySlabs;
}

export interface DeleteManySlabsVariables {
  input: DeleteManySlabsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetBlobMetadata
// ====================================================

export interface SetBlobMetadata {
  setBlobMetadata: boolean;
}

export interface SetBlobMetadataVariables {
  metadata: string;
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CatalogManySlabs
// ====================================================

export interface CatalogManySlabs_catalogManySlabs {
  code: string;
}

export interface CatalogManySlabs {
  catalogManySlabs: CatalogManySlabs_catalogManySlabs[];
}

export interface CatalogManySlabsVariables {
  input: CatalogManySlabsInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOneUser
// ====================================================

export interface CreateOneUser_createOneUser_company {
  code: string;
  name: string;
}

export interface CreateOneUser_createOneUser {
  avatar: string | null;
  company: CreateOneUser_createOneUser_company | null;
  createdAt: weScanDateTime | null;
  email: string;
  firstname: string | null;
  id: string;
  lastname: string | null;
  name: string | null;
  role: IRolesEnum | null;
  status: boolean | null;
  updatedAt: weScanDateTime | null;
  username: string;
}

export interface CreateOneUser {
  createOneUser: CreateOneUser_createOneUser;
}

export interface CreateOneUserVariables {
  input: CreateOneUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOneStoneEdge
// ====================================================

export interface CreateOneStoneEdge_createOneStoneEdge {
  code: string;
  name: string;
  id: string;
  description: string;
}

export interface CreateOneStoneEdge {
  createOneStoneEdge: CreateOneStoneEdge_createOneStoneEdge;
}

export interface CreateOneStoneEdgeVariables {
  input: CreateOneStoneEdgeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOneStoneEdge
// ====================================================

export interface DeleteOneStoneEdge_deleteOneStoneEdge {
  code: string | null;
}

export interface DeleteOneStoneEdge {
  deleteOneStoneEdge: DeleteOneStoneEdge_deleteOneStoneEdge;
}

export interface DeleteOneStoneEdgeVariables {
  input: DeleteOneStoneEdgeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOneStoneEdge
// ====================================================

export interface UpdateOneStoneEdge_updateOneStoneEdge {
  code: string;
}

export interface UpdateOneStoneEdge {
  updateOneStoneEdge: UpdateOneStoneEdge_updateOneStoneEdge;
}

export interface UpdateOneStoneEdgeVariables {
  input: UpdateOneStoneEdgeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOneStoneModel
// ====================================================

export interface CreateOneStoneModel_createOneStoneModel {
  code: string;
  name: string;
  id: string;
  description: string;
}

export interface CreateOneStoneModel {
  createOneStoneModel: CreateOneStoneModel_createOneStoneModel;
}

export interface CreateOneStoneModelVariables {
  input: CreateOneStoneModelInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOneStoneModel
// ====================================================

export interface DeleteOneStoneModel_deleteOneStoneModel {
  code: string | null;
}

export interface DeleteOneStoneModel {
  deleteOneStoneModel: DeleteOneStoneModel_deleteOneStoneModel;
}

export interface DeleteOneStoneModelVariables {
  input: DeleteOneStoneModelInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOneStoneModel
// ====================================================

export interface UpdateOneStoneModel_updateOneStoneModel {
  code: string;
}

export interface UpdateOneStoneModel {
  updateOneStoneModel: UpdateOneStoneModel_updateOneStoneModel;
}

export interface UpdateOneStoneModelVariables {
  input: UpdateOneStoneModelInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOneJob
// ====================================================

export interface CreateOneJob_createOneJob {
  id: string;
  code: string;
  totalslab: number;
  sequence: number;
}

export interface CreateOneJob {
  createOneJob: CreateOneJob_createOneJob;
}

export interface CreateOneJobVariables {
  input: CreateOneJobInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOneJob
// ====================================================

export interface UpdateOneJob_updateOneJob {
  id: string;
  code: string;
  totalslab: number;
  sequence: number;
}

export interface UpdateOneJob {
  updateOneJob: UpdateOneJob_updateOneJob;
}

export interface UpdateOneJobVariables {
  input: UpdateOneJobInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOneJob
// ====================================================

export interface DeleteOneJob_deleteOneJob {
  code: string | null;
}

export interface DeleteOneJob {
  deleteOneJob: DeleteOneJob_deleteOneJob;
}

export interface DeleteOneJobVariables {
  input: DeleteOneJobInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOneImageColorFilter
// ====================================================

export interface CreateOneImageColorFilter_createOneImageColorFilter_valueFilter {
  brightness: number;
  contrast: number;
  gamma: number;
  hue_rotation: number;
  saturation: number;
  temperature: number;
  exposure: number;
}

export interface CreateOneImageColorFilter_createOneImageColorFilter_commercial_variety {
  code: string;
  id: string;
  description: string | null;
  name: string | null;
}

export interface CreateOneImageColorFilter_createOneImageColorFilter_commercial_finishing_description {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface CreateOneImageColorFilter_createOneImageColorFilter_commercial_finishing {
  id: string;
  code: string;
  description: CreateOneImageColorFilter_createOneImageColorFilter_commercial_finishing_description;
}

export interface CreateOneImageColorFilter_createOneImageColorFilter {
  id: string;
  name: string;
  valueFilter: CreateOneImageColorFilter_createOneImageColorFilter_valueFilter;
  commercial_variety: CreateOneImageColorFilter_createOneImageColorFilter_commercial_variety | null;
  commercial_finishing: CreateOneImageColorFilter_createOneImageColorFilter_commercial_finishing | null;
}

export interface CreateOneImageColorFilter {
  createOneImageColorFilter: CreateOneImageColorFilter_createOneImageColorFilter;
}

export interface CreateOneImageColorFilterVariables {
  input: CreateOneImageColorFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOneImageColorFilter
// ====================================================

export interface UpdateOneImageColorFilter_updateOneImageColorFilter_valueFilter {
  brightness: number;
  contrast: number;
  gamma: number;
  hue_rotation: number;
  saturation: number;
  temperature: number;
  exposure: number;
}

export interface UpdateOneImageColorFilter_updateOneImageColorFilter_commercial_variety {
  code: string;
  id: string;
  description: string | null;
  name: string | null;
}

export interface UpdateOneImageColorFilter_updateOneImageColorFilter_commercial_finishing_description {
  pt: string;
  en: string | null;
  es: string | null;
}

export interface UpdateOneImageColorFilter_updateOneImageColorFilter_commercial_finishing {
  id: string;
  code: string;
  description: UpdateOneImageColorFilter_updateOneImageColorFilter_commercial_finishing_description;
}

export interface UpdateOneImageColorFilter_updateOneImageColorFilter {
  id: string;
  name: string;
  valueFilter: UpdateOneImageColorFilter_updateOneImageColorFilter_valueFilter;
  commercial_variety: UpdateOneImageColorFilter_updateOneImageColorFilter_commercial_variety | null;
  commercial_finishing: UpdateOneImageColorFilter_updateOneImageColorFilter_commercial_finishing | null;
}

export interface UpdateOneImageColorFilter {
  updateOneImageColorFilter: UpdateOneImageColorFilter_updateOneImageColorFilter;
}

export interface UpdateOneImageColorFilterVariables {
  input: UpdateOneImageColorFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOneImageColorFilter
// ====================================================

export interface DeleteOneImageColorFilter_deleteOneImageColorFilter {
  name: string | null;
}

export interface DeleteOneImageColorFilter {
  deleteOneImageColorFilter: DeleteOneImageColorFilter_deleteOneImageColorFilter;
}

export interface DeleteOneImageColorFilterVariables {
  input: DeleteOneImageColorFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOneProject
// ====================================================

export interface CreateOneProject_createOneProject {
  id: string;
  project: string;
}

export interface CreateOneProject {
  createOneProject: CreateOneProject_createOneProject;
}

export interface CreateOneProjectVariables {
  input: CreateOneProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOneProject
// ====================================================

export interface UpdateOneProject_updateOneProject {
  id: string;
  project: string;
}

export interface UpdateOneProject {
  updateOneProject: UpdateOneProject_updateOneProject;
}

export interface UpdateOneProjectVariables {
  input: UpdateOneProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOneProject
// ====================================================

export interface DeleteOneProject_deleteOneProject {
  project: string | null;
  id: string | null;
}

export interface DeleteOneProject {
  deleteOneProject: DeleteOneProject_deleteOneProject;
}

export interface DeleteOneProjectVariables {
  input: DeleteOneProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOnePacking
// ====================================================

export interface CreateOnePacking_createOnePacking {
  id: string;
  packing: string;
}

export interface CreateOnePacking {
  createOnePacking: CreateOnePacking_createOnePacking;
}

export interface CreateOnePackingVariables {
  input: CreateOnePackingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOnePacking
// ====================================================

export interface UpdateOnePacking_updateOnePacking {
  id: string;
  packing: string;
}

export interface UpdateOnePacking {
  updateOnePacking: UpdateOnePacking_updateOnePacking;
}

export interface UpdateOnePackingVariables {
  input: UpdateOnePackingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateManyPackings
// ====================================================

export interface UpdateManyPackings_updateManyPackings {
  /**
   * The number of records updated.
   */
  updatedCount: number;
}

export interface UpdateManyPackings {
  updateManyPackings: UpdateManyPackings_updateManyPackings;
}

export interface UpdateManyPackingsVariables {
  input: UpdateManyPackingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOnePacking
// ====================================================

export interface DeleteOnePacking_deleteOnePacking {
  id: string | null;
  packing: string | null;
}

export interface DeleteOnePacking {
  deleteOnePacking: DeleteOnePacking_deleteOnePacking;
}

export interface DeleteOnePackingVariables {
  input: DeleteOnePackingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteManyPackings
// ====================================================

export interface DeleteManyPackings_deleteManyPackings {
  /**
   * The number of records deleted.
   */
  deletedCount: number;
}

export interface DeleteManyPackings {
  deleteManyPackings: DeleteManyPackings_deleteManyPackings;
}

export interface DeleteManyPackingsVariables {
  input: DeleteManyPackingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOneShareLink
// ====================================================

export interface CreateOneShareLink_createOneShareLink_package {
  id: string;
}

export interface CreateOneShareLink_createOneShareLink_project {
  id: string;
}

export interface CreateOneShareLink_createOneShareLink_block {
  id: string;
}

export interface CreateOneShareLink_createOneShareLink_slab {
  id: string | null;
}

export interface CreateOneShareLink_createOneShareLink {
  id: string;
  isValid: boolean;
  package: CreateOneShareLink_createOneShareLink_package | null;
  project: CreateOneShareLink_createOneShareLink_project | null;
  validUntil: weScanDateTime;
  keyValue: string;
  block: CreateOneShareLink_createOneShareLink_block | null;
  slab: CreateOneShareLink_createOneShareLink_slab | null;
}

export interface CreateOneShareLink {
  createOneShareLink: CreateOneShareLink_createOneShareLink;
}

export interface CreateOneShareLinkVariables {
  input: CreateOneShareLinkInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOneShareLink
// ====================================================

export interface UpdateOneShareLink_updateOneShareLink {
  id: string;
}

export interface UpdateOneShareLink {
  updateOneShareLink: UpdateOneShareLink_updateOneShareLink;
}

export interface UpdateOneShareLinkVariables {
  input: UpdateOneShareLinkInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOneAnnexedDocument
// ====================================================

export interface CreateOneAnnexedDocument_createOneAnnexedDocument {
  id: string;
}

export interface CreateOneAnnexedDocument {
  createOneAnnexedDocument: CreateOneAnnexedDocument_createOneAnnexedDocument;
}

export interface CreateOneAnnexedDocumentVariables {
  file: weScanUpload;
  input: CreateOneAnnexedDocumentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOneAnnexedDocument
// ====================================================

export interface DeleteOneAnnexedDocument_deleteOneAnnexedDocument {
  id: string | null;
}

export interface DeleteOneAnnexedDocument {
  deleteOneAnnexedDocument: DeleteOneAnnexedDocument_deleteOneAnnexedDocument;
}

export interface DeleteOneAnnexedDocumentVariables {
  input: DeleteOneAnnexedDocumentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOneCommercialVariety
// ====================================================

export interface CreateOneCommercialVariety_createOneCommercialVariety {
  id: string;
}

export interface CreateOneCommercialVariety {
  createOneCommercialVariety: CreateOneCommercialVariety_createOneCommercialVariety;
}

export interface CreateOneCommercialVarietyVariables {
  input: CreateOneCommercialVarietyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOneCommercialVariety
// ====================================================

export interface UpdateOneCommercialVariety_updateOneCommercialVariety {
  id: string;
}

export interface UpdateOneCommercialVariety {
  updateOneCommercialVariety: UpdateOneCommercialVariety_updateOneCommercialVariety;
}

export interface UpdateOneCommercialVarietyVariables {
  input: UpdateOneCommercialVarietyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOneCommercialVariety
// ====================================================

export interface DeleteOneCommercialVariety_deleteOneCommercialVariety {
  id: string | null;
}

export interface DeleteOneCommercialVariety {
  deleteOneCommercialVariety: DeleteOneCommercialVariety_deleteOneCommercialVariety;
}

export interface DeleteOneCommercialVarietyVariables {
  input: DeleteOneCommercialVarietyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOneCommercialFinishing
// ====================================================

export interface CreateOneCommercialFinishing_createOneCommercialFinishing {
  id: string;
}

export interface CreateOneCommercialFinishing {
  createOneCommercialFinishing: CreateOneCommercialFinishing_createOneCommercialFinishing;
}

export interface CreateOneCommercialFinishingVariables {
  input: CreateOneCommercialFinishingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOneCommercialFinishing
// ====================================================

export interface UpdateOneCommercialFinishing_updateOneCommercialFinishing {
  id: string;
}

export interface UpdateOneCommercialFinishing {
  updateOneCommercialFinishing: UpdateOneCommercialFinishing_updateOneCommercialFinishing;
}

export interface UpdateOneCommercialFinishingVariables {
  input: UpdateOneCommercialFinishingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOneCommercialFinishing
// ====================================================

export interface DeleteOneCommercialFinishing_deleteOneCommercialFinishing {
  id: string | null;
}

export interface DeleteOneCommercialFinishing {
  deleteOneCommercialFinishing: DeleteOneCommercialFinishing_deleteOneCommercialFinishing;
}

export interface DeleteOneCommercialFinishingVariables {
  input: DeleteOneCommercialFinishingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOneBlockModel
// ====================================================

export interface DeleteOneBlockModel_deleteOneBlockModel {
  id: string | null;
}

export interface DeleteOneBlockModel {
  deleteOneBlockModel: DeleteOneBlockModel_deleteOneBlockModel;
}

export interface DeleteOneBlockModelVariables {
  input: DeleteOneBlockModelInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteManyBlockModels
// ====================================================

export interface DeleteManyBlockModels_deleteManyBlockModels {
  /**
   * The number of records deleted.
   */
  deletedCount: number;
}

export interface DeleteManyBlockModels {
  deleteManyBlockModels: DeleteManyBlockModels_deleteManyBlockModels;
}

export interface DeleteManyBlockModelsVariables {
  input: DeleteManyBlockModelsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOneBlockStream
// ====================================================

export interface UpdateOneBlockStream_updateOneBlockStream {
  id: string;
}

export interface UpdateOneBlockStream {
  updateOneBlockStream: UpdateOneBlockStream_updateOneBlockStream;
}

export interface UpdateOneBlockStreamVariables {
  update: UpdateOneBlockModelUploadInputType;
  frontFaceImage?: weScanUpload | null;
  backFaceImage?: weScanUpload | null;
  topFaceImage?: weScanUpload | null;
  leftFaceImage?: weScanUpload | null;
  rightFaceImage?: weScanUpload | null;
  modelFile?: weScanUpload | null;
  materialFile?: weScanUpload | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOnePackageType
// ====================================================

export interface CreateOnePackageType_createOnePackingType {
  id: string;
}

export interface CreateOnePackageType {
  createOnePackingType: CreateOnePackageType_createOnePackingType;
}

export interface CreateOnePackageTypeVariables {
  input: CreateOnePackingTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOnePackageType
// ====================================================

export interface UpdateOnePackageType_updateOnePackingType {
  id: string;
}

export interface UpdateOnePackageType {
  updateOnePackingType: UpdateOnePackageType_updateOnePackingType;
}

export interface UpdateOnePackageTypeVariables {
  input: UpdateOnePackingTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteOnePackageType
// ====================================================

export interface DeleteOnePackageType_deleteOnePackingType {
  id: string | null;
}

export interface DeleteOnePackageType {
  deleteOnePackingType: DeleteOnePackageType_deleteOnePackingType;
}

export interface DeleteOnePackageTypeVariables {
  input: DeleteOnePackingTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: SubscribeToScannerUpload
// ====================================================

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_payload {
  area: number | null;
  dpi: number | null;
  height: number | null;
  strfiletype: string | null;
  thickness: number | null;
  width: number | null;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_scanner {
  id: string;
  description: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_block {
  id: string;
  wescanCode: string;
  tenantCode: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_slabImages {
  _id: string;
  /**
   * Edited Image URL
   */
  edited: string | null;
  /**
   * Original Image URL
   */
  original: string;
  /**
   * Edited Thumbnail URL
   */
  thumbnail_edited: string | null;
  /**
   * Original Image Thumbnail URL
   */
  thumbnail_original: string;
  /**
   * Type of image to show as main. Defaults to original
   */
  mainType: MainImageType;
  /**
   * Unique Azure Blob ID
   */
  azureBlobID: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_typematerial_description {
  pt: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_typematerial {
  id: string;
  description: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_typematerial_description;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_category_description {
  pt: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_category {
  id: string;
  description: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_category_description;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_finish_description {
  pt: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_finish {
  id: string;
  description: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_finish_description;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_project {
  id: string;
  project: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_variety {
  id: string;
  normativedesig: string;
  macroscopicdesc: string | null;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_scan {
  code: string;
  description: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_job {
  description: string | null;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_packing {
  id: string;
  code: string;
  packing: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_company {
  code: string;
  name: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_variety_materialtype_description {
  pt: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_variety_materialtype {
  code: string;
  description: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_variety_materialtype_description;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_variety {
  id: string;
  code: string;
  description: string | null;
  name: string | null;
  materialtype: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_variety_materialtype | null;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_finishing_description {
  pt: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_finishing_observation {
  pt: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_finishing {
  code: string;
  description: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_finishing_description;
  id: string;
  observation: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_finishing_observation | null;
  createdAt: weScanDateTime | null;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_stone_edge {
  code: string;
  id: string;
  name: string;
  description: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_stone_model {
  code: string;
  id: string;
  name: string;
  description: string;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab {
  iscatalogued: boolean;
  main_image: string | null;
  erp_id: string | null;
  id: string | null;
  code: string;
  name: string;
  area: number | null;
  description: string | null;
  desigcomercial: string | null;
  width: number | null;
  height: number | null;
  thickness: number | null;
  extwidth: number | null;
  blocknumber: string | null;
  block: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_block | null;
  extheight: number | null;
  extthickness: number | null;
  stkwidth: number | null;
  stkheight: number | null;
  stkthickness: number | null;
  purchasewidth: number | null;
  purchaseheight: number | null;
  purchasethickness: number | null;
  cancelCataloguing: boolean;
  images: string[];
  /**
   * String matching the "azureBlobId" of one of the Image Entities in "slabImages"
   */
  mainImage: string | null;
  /**
   * Array of Slab's Image Entities
   */
  slabImages: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_slabImages[];
  typematerial: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_typematerial | null;
  category: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_category | null;
  finish: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_finish | null;
  project: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_project | null;
  variety: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_variety | null;
  observation: string | null;
  createdate: weScanDateTime | null;
  scan: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_scan | null;
  job: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_job | null;
  packing: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_packing | null;
  inStock: boolean;
  company: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_company | null;
  commercial_variety: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_variety | null;
  commercial_finishing: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_commercial_finishing | null;
  stone_edge: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_stone_edge | null;
  stone_model: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab_stone_model | null;
}

export interface SubscribeToScannerUpload_subscribeToScannerSlabUploadState {
  stateDescription: string;
  state: ScannerStateEnum;
  numberOfImagesToUpload: number | null;
  startedAt: weScanDateTime;
  payload: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_payload | null;
  scanner: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_scanner;
  imagesArray: string[] | null;
  slab: SubscribeToScannerUpload_subscribeToScannerSlabUploadState_slab | null;
}

export interface SubscribeToScannerUpload {
  /**
   * Subscribe to Scanner Slab Uploads (states are managed by the API)
   */
  subscribeToScannerSlabUploadState: SubscribeToScannerUpload_subscribeToScannerSlabUploadState;
}

export interface SubscribeToScannerUploadVariables {
  filter?: ScannerFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: SubscribeToScannerOperation
// ====================================================

export interface SubscribeToScannerOperation_subscribeToScannerOperationState_payload {
  area: number | null;
  dpi: number | null;
  height: number | null;
  strfiletype: string | null;
  thickness: number | null;
  width: number | null;
}

export interface SubscribeToScannerOperation_subscribeToScannerOperationState_scanner {
  id: string;
  description: string;
}

export interface SubscribeToScannerOperation_subscribeToScannerOperationState {
  stateDescription: string;
  state: ScannerStateEnum;
  numberOfImagesToUpload: number | null;
  startedAt: weScanDateTime;
  payload: SubscribeToScannerOperation_subscribeToScannerOperationState_payload | null;
  scanner: SubscribeToScannerOperation_subscribeToScannerOperationState_scanner;
}

export interface SubscribeToScannerOperation {
  /**
   * Subscribe to Scanner Operation State (states are managed by the Scanner)
   */
  subscribeToScannerOperationState: SubscribeToScannerOperation_subscribeToScannerOperationState;
}

export interface SubscribeToScannerOperationVariables {
  filter?: ScannerFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicationTypeSortFields {
  application_site = "application_site",
  application_typology = "application_typology",
  createdAt = "createdAt",
  createdBy = "createdBy",
  description = "description",
  development_type = "development_type",
  id = "id",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}

export enum ApplicationTypology {
  Exterior = "Exterior",
  Interior = "Interior",
}

/**
 * Either SCANNER or USER
 */
export enum BlockCreationType {
  JOB = "JOB",
  SCANNER = "SCANNER",
  USER = "USER",
}

export enum BlockModelSortFields {
  baseArea = "baseArea",
  commercial_finishing = "commercial_finishing",
  commercial_variety = "commercial_variety",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  creationType = "creationType",
  description = "description",
  extheight = "extheight",
  extlength = "extlength",
  extwidth = "extwidth",
  id = "id",
  isSharedPublicly = "isSharedPublicly",
  isSharedWithClients = "isSharedWithClients",
  materialFileExtension = "materialFileExtension",
  materialFileMimetype = "materialFileMimetype",
  materialFileUrl = "materialFileUrl",
  materialtype = "materialtype",
  modelFileExtension = "modelFileExtension",
  modelFileMimetype = "modelFileMimetype",
  modelFileURL = "modelFileURL",
  project = "project",
  scannedBy = "scannedBy",
  tenantCode = "tenantCode",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  variety = "variety",
  volume = "volume",
  wescanCode = "wescanCode",
}

export enum CategorySortFields {
  code = "code",
  createdAt = "createdAt",
  createdBy = "createdBy",
  date = "date",
  id = "id",
  isResultOfSlabScan = "isResultOfSlabScan",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

export enum ClientSortFields {
  code = "code",
  createdAt = "createdAt",
  createdBy = "createdBy",
  email = "email",
  firstName = "firstName",
  id = "id",
  isdelete = "isdelete",
  lastName = "lastName",
  name = "name",
  phone = "phone",
  phonePure = "phonePure",
  provider = "provider",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}

export enum ColorSortFields {
  code = "code",
  color = "color",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  createdate = "createdate",
  descriptiveone = "descriptiveone",
  descriptivetwo = "descriptivetwo",
  id = "id",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

export enum CommercialFinishingSortFields {
  code = "code",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  id = "id",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

export enum CommercialVarietySortFields {
  code = "code",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  description = "description",
  id = "id",
  materialtype = "materialtype",
  name = "name",
  system_variety = "system_variety",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

export enum CompanySortFields {
  address = "address",
  code = "code",
  contact = "contact",
  createdAt = "createdAt",
  createdBy = "createdBy",
  date = "date",
  email = "email",
  erp = "erp",
  fabricator = "fabricator",
  id = "id",
  name = "name",
  stars = "stars",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

export enum CounterSortFields {
  company = "company",
  counterType = "counterType",
  createdAt = "createdAt",
  description = "description",
  id = "id",
  sequence_value = "sequence_value",
  updatedAt = "updatedAt",
}

/**
 * Type of Counter
 */
export enum CounterType {
  JOB = "JOB",
  SLAB = "SLAB",
}

export enum DescState {
  CANCELED = "CANCELED",
  CONCLUDED = "CONCLUDED",
  EXECUTION = "EXECUTION",
  PENDING = "PENDING",
  STOPPED = "STOPPED",
}

/**
 * Enum to know what type of entity this document is referencing
 */
export enum DocumentReferenceType {
  commercial_finishing = "commercial_finishing",
  commercial_variety = "commercial_variety",
  material_type = "material_type",
  system_finishing = "system_finishing",
  system_variety = "system_variety",
}

export enum ERPType {
  SAGEX3 = "SAGEX3",
}

export enum EnvironmentalFactorSortFields {
  aa = "aa",
  application_type = "application_type",
  cdt = "cdt",
  createdAt = "createdAt",
  createdBy = "createdBy",
  description = "description",
  dg = "dg",
  factor_type = "factor_type",
  id = "id",
  level = "level",
  me = "me",
  measurement = "measurement",
  mv = "mv",
  name = "name",
  pa = "pa",
  range_end = "range_end",
  range_start = "range_start",
  rc = "rc",
  rd = "rd",
  rf = "rf",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}

export enum FinishingSortFields {
  code = "code",
  createdAt = "createdAt",
  createdBy = "createdBy",
  date = "date",
  id = "id",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

/**
 * Enum of possible external Authentication Providers (Google, Microsoft, WeChat, etc...)
 */
export enum IAuthenticationProviders {
  google = "google",
  microsoft = "microsoft",
  wechat = "wechat",
  wescan = "wescan",
}

export enum IRolesEnum {
  basic = "basic",
  blockscanneradmin = "blockscanneradmin",
  companyadmin = "companyadmin",
  guest = "guest",
  partner = "partner",
  partneradmin = "partneradmin",
  superadmin = "superadmin",
}

export enum ImageColorFilterSortFields {
  commercial_finishing = "commercial_finishing",
  commercial_variety = "commercial_variety",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  id = "id",
  inactive = "inactive",
  name = "name",
  prevName = "prevName",
  prev_commercial_finishing = "prev_commercial_finishing",
  prev_commercial_variety = "prev_commercial_variety",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}

/**
 * Enum of which of the stone block's face the image is from
 */
export enum ImageFace {
  BACK = "BACK",
  FRONT = "FRONT",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  SINGLE = "SINGLE",
  TOP = "TOP",
}

/**
 * Enum of which type of lighting was applied when scanning
 */
export enum ImageScanType {
  Backlight = "Backlight",
  Default = "Default",
}

/**
 * Enum of upload types
 */
export enum ImageUploadType {
  MOBILE = "MOBILE",
  PLATFORM = "PLATFORM",
  SCANNER = "SCANNER",
}

/**
 * Order of the numbers given to the slabs resulting from this Job.
 */
export enum JobNumberingOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum JobSortFields {
  block = "block",
  blocknumber = "blocknumber",
  category = "category",
  code = "code",
  commercial_finishing = "commercial_finishing",
  commercial_variety = "commercial_variety",
  company = "company",
  completed = "completed",
  createdAt = "createdAt",
  createdBy = "createdBy",
  createdate = "createdate",
  descstate = "descstate",
  fabricator = "fabricator",
  finish = "finish",
  id = "id",
  isblock = "isblock",
  jobtype = "jobtype",
  lastupdate = "lastupdate",
  numberingOrder = "numberingOrder",
  packing = "packing",
  prefix = "prefix",
  project = "project",
  scan = "scan",
  scannedSlabs = "scannedSlabs",
  scheduledAt = "scheduledAt",
  separator = "separator",
  sequence = "sequence",
  startNumber = "startNumber",
  state = "state",
  stone_edge = "stone_edge",
  stone_model = "stone_model",
  totalslab = "totalslab",
  typematerial = "typematerial",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
  variety = "variety",
}

export enum JobtypeSortFields {
  code = "code",
  createdAt = "createdAt",
  createdBy = "createdBy",
  createdate = "createdate",
  description = "description",
  id = "id",
  isDefault = "isDefault",
  isSingle = "isSingle",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}

/**
 * Enum of which of the images (edited or original) is the main image now
 */
export enum MainImageType {
  edited = "edited",
  original = "original",
}

export enum MaterialTypeSortFields {
  code = "code",
  createdAt = "createdAt",
  date = "date",
  id = "id",
  updatedAt = "updatedAt",
}

export enum PackingSortFields {
  code = "code",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  id = "id",
  inStock = "inStock",
  isSharedPublicly = "isSharedPublicly",
  isSharedWithClients = "isSharedWithClients",
  packing = "packing",
  packingtype = "packingtype",
  project = "project",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

export enum PackingTypeSortFields {
  code = "code",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  id = "id",
  observation = "observation",
  type = "type",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

export enum ProjectSortFields {
  active = "active",
  code = "code",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  createdate = "createdate",
  description = "description",
  fabricator = "fabricator",
  finaldate = "finaldate",
  id = "id",
  inStock = "inStock",
  initdate = "initdate",
  isSharedPublicly = "isSharedPublicly",
  isSharedWithClients = "isSharedWithClients",
  observation = "observation",
  project = "project",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

/**
 * Scanner Enum Type
 */
export enum ScannerModelEnum {
  Block = "Block",
  Horizontal = "Horizontal",
  Vertical = "Vertical",
}

export enum ScannerSortFields {
  code = "code",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  createdate = "createdate",
  description = "description",
  fabricator = "fabricator",
  id = "id",
  scanner_model = "scanner_model",
  snumber = "snumber",
  state = "state",
  subscription = "subscription",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

/**
 * Possible states of a Scanner
 */
export enum ScannerStateEnum {
  Done = "Done",
  Failed = "Failed",
  Scanning = "Scanning",
  Uploading = "Uploading",
  Waiting = "Waiting",
}

export enum ShareLinkSortFields {
  block = "block",
  clientOnly = "clientOnly",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  deleted = "deleted",
  id = "id",
  isValid = "isValid",
  keyValue = "keyValue",
  package = "package",
  project = "project",
  slab = "slab",
  updatedAt = "updatedAt",
  validUntil = "validUntil",
}

export enum SlabSortFields {
  block = "block",
  blocknumber = "blocknumber",
  cancelCataloguing = "cancelCataloguing",
  category = "category",
  classplogic = "classplogic",
  code = "code",
  commercial_finishing = "commercial_finishing",
  commercial_variety = "commercial_variety",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  createdate = "createdate",
  erp_id = "erp_id",
  finish = "finish",
  id = "id",
  inStock = "inStock",
  isSharedPublicly = "isSharedPublicly",
  isSharedWithClients = "isSharedWithClients",
  isblock = "isblock",
  isblockjob = "isblockjob",
  iscatalogued = "iscatalogued",
  ismobile = "ismobile",
  job = "job",
  packing = "packing",
  process = "process",
  project = "project",
  scan = "scan",
  stone_edge = "stone_edge",
  stone_model = "stone_model",
  subclass = "subclass",
  subproject = "subproject",
  typematerial = "typematerial",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  updatedate = "updatedate",
  updateuser = "updateuser",
  user = "user",
  variety = "variety",
}

/**
 * Sort Directions
 */
export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * Sort Nulls Options
 */
export enum SortNulls {
  NULLS_FIRST = "NULLS_FIRST",
  NULLS_LAST = "NULLS_LAST",
}

export enum StoneEdgeSortFields {
  code = "code",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  description = "description",
  id = "id",
  name = "name",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}

export enum StoneLocationSortFields {
  coast_line = "coast_line",
  country = "country",
  country_side = "country_side",
  createdAt = "createdAt",
  createdBy = "createdBy",
  id = "id",
  industrialization = "industrialization",
  mountain = "mountain",
  name = "name",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  urbanization = "urbanization",
}

export enum StoneModelSortFields {
  code = "code",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  description = "description",
  id = "id",
  name = "name",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
}

export enum VarietySortFields {
  alternativedesig = "alternativedesig",
  code = "code",
  color = "color",
  colordescriptiveone = "colordescriptiveone",
  colordescriptivetwo = "colordescriptivetwo",
  company = "company",
  createdAt = "createdAt",
  createdBy = "createdBy",
  date = "date",
  districtquarry = "districtquarry",
  id = "id",
  lithologicalclass = "lithologicalclass",
  lithologicalsubclass = "lithologicalsubclass",
  macroscopicdesc = "macroscopicdesc",
  materialtype = "materialtype",
  microscopicdesc = "microscopicdesc",
  municipalityquarry = "municipalityquarry",
  normativedesig = "normativedesig",
  observation = "observation",
  stonequarry = "stonequarry",
  updatedAt = "updatedAt",
  updatedBy = "updatedBy",
  user = "user",
}

/**
 * Input type of an Annexed Document
 */
export interface AnnexedDocumentInput {
  name: string;
  ref_commercial_finishing?: string | null;
  ref_commercial_variety?: string | null;
  ref_material_type?: string | null;
  ref_system_finishing?: string | null;
  ref_system_variety?: string | null;
  reference_type: DocumentReferenceType;
}

export interface ApplicationTypeFilter {
  and?: ApplicationTypeFilter[] | null;
  application_site?: IDFilterComparison | null;
  application_typology?: ApplicationTypologyFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  description?: IDFilterComparison | null;
  development_type?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  or?: ApplicationTypeFilter[] | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
}

export interface ApplicationTypeSort {
  direction: SortDirection;
  field: ApplicationTypeSortFields;
  nulls?: SortNulls | null;
}

export interface ApplicationTypologyFilterComparison {
  eq?: ApplicationTypology | null;
  gt?: ApplicationTypology | null;
  gte?: ApplicationTypology | null;
  iLike?: ApplicationTypology | null;
  in?: ApplicationTypology[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  like?: ApplicationTypology | null;
  lt?: ApplicationTypology | null;
  lte?: ApplicationTypology | null;
  neq?: ApplicationTypology | null;
  notILike?: ApplicationTypology | null;
  notIn?: ApplicationTypology[] | null;
  notLike?: ApplicationTypology | null;
}

export interface BlockCreationTypeFilterComparison {
  eq?: BlockCreationType | null;
  gt?: BlockCreationType | null;
  gte?: BlockCreationType | null;
  iLike?: BlockCreationType | null;
  in?: BlockCreationType[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  like?: BlockCreationType | null;
  lt?: BlockCreationType | null;
  lte?: BlockCreationType | null;
  neq?: BlockCreationType | null;
  notILike?: BlockCreationType | null;
  notIn?: BlockCreationType[] | null;
  notLike?: BlockCreationType | null;
}

export interface BlockModelDeleteFilter {
  and?: BlockModelDeleteFilter[] | null;
  baseArea?: NumberFieldComparison | null;
  commercial_finishing?: IDFilterComparison | null;
  commercial_variety?: IDFilterComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  creationType?: BlockCreationTypeFilterComparison | null;
  description?: StringFieldComparison | null;
  extheight?: NumberFieldComparison | null;
  extlength?: NumberFieldComparison | null;
  extwidth?: NumberFieldComparison | null;
  id?: IDFilterComparison | null;
  isSharedPublicly?: BooleanFieldComparison | null;
  isSharedWithClients?: BooleanFieldComparison | null;
  materialFileExtension?: StringFieldComparison | null;
  materialFileMimetype?: StringFieldComparison | null;
  materialFileUrl?: StringFieldComparison | null;
  materialtype?: IDFilterComparison | null;
  modelFileExtension?: StringFieldComparison | null;
  modelFileMimetype?: StringFieldComparison | null;
  modelFileURL?: StringFieldComparison | null;
  or?: BlockModelDeleteFilter[] | null;
  project?: IDFilterComparison | null;
  scannedBy?: IDFilterComparison | null;
  tenantCode?: StringFieldComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  variety?: IDFilterComparison | null;
  volume?: NumberFieldComparison | null;
  wescanCode?: StringFieldComparison | null;
}

export interface BlockModelFilter {
  and?: BlockModelFilter[] | null;
  baseArea?: NumberFieldComparison | null;
  commercial_finishing?: IDFilterComparison | null;
  commercial_variety?: IDFilterComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  creationType?: BlockCreationTypeFilterComparison | null;
  description?: StringFieldComparison | null;
  extheight?: NumberFieldComparison | null;
  extlength?: NumberFieldComparison | null;
  extwidth?: NumberFieldComparison | null;
  id?: IDFilterComparison | null;
  isSharedPublicly?: BooleanFieldComparison | null;
  isSharedWithClients?: BooleanFieldComparison | null;
  materialFileExtension?: StringFieldComparison | null;
  materialFileMimetype?: StringFieldComparison | null;
  materialFileUrl?: StringFieldComparison | null;
  materialtype?: IDFilterComparison | null;
  modelFileExtension?: StringFieldComparison | null;
  modelFileMimetype?: StringFieldComparison | null;
  modelFileURL?: StringFieldComparison | null;
  or?: BlockModelFilter[] | null;
  project?: IDFilterComparison | null;
  scannedBy?: IDFilterComparison | null;
  tenantCode?: StringFieldComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  variety?: IDFilterComparison | null;
  volume?: NumberFieldComparison | null;
  wescanCode?: StringFieldComparison | null;
}

export interface BlockModelSort {
  direction: SortDirection;
  field: BlockModelSortFields;
  nulls?: SortNulls | null;
}

export interface BlockModelUpdate {
  baseArea?: number | null;
  commercial_variety?: string | null;
  description?: string | null;
  extheight?: number | null;
  extlength?: number | null;
  extwidth?: number | null;
  materialFileExtension?: string | null;
  materialFileMimetype?: string | null;
  materialFileUrl?: string | null;
  materialtype?: string | null;
  modelFileExtension?: string | null;
  modelFileMimetype?: string | null;
  modelFileURL?: string | null;
  project?: string | null;
  regularImages?: ImageInputDTO[] | null;
  tenantCode?: string | null;
  variety?: string | null;
  volume?: number | null;
}

export interface BooleanFieldComparison {
  is?: boolean | null;
  isNot?: boolean | null;
}

export interface BundleCreation {
  prefix: string;
  start?: number | null;
}

export interface CatalogManySlabsInputType {
  catalog: IDAndCatalogDTO;
}

export interface CategoryFilter {
  and?: CategoryFilter[] | null;
  code?: StringFieldComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  date?: DateFieldComparison | null;
  id?: IDFilterComparison | null;
  isResultOfSlabScan?: BooleanFieldComparison | null;
  or?: CategoryFilter[] | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface CategorySort {
  direction: SortDirection;
  field: CategorySortFields;
  nulls?: SortNulls | null;
}

export interface ClientFilter {
  and?: ClientFilter[] | null;
  code?: StringFieldComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  email?: StringFieldComparison | null;
  firstName?: StringFieldComparison | null;
  id?: IDFilterComparison | null;
  isdelete?: BooleanFieldComparison | null;
  lastName?: StringFieldComparison | null;
  name?: StringFieldComparison | null;
  or?: ClientFilter[] | null;
  phone?: StringFieldComparison | null;
  phonePure?: StringFieldComparison | null;
  provider?: IAuthenticationProvidersFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
}

export interface ClientSort {
  direction: SortDirection;
  field: ClientSortFields;
  nulls?: SortNulls | null;
}

export interface ColorFilter {
  and?: ColorFilter[] | null;
  code?: StringFieldComparison | null;
  color?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  createdate?: DateFieldComparison | null;
  descriptiveone?: IDFilterComparison | null;
  descriptivetwo?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  or?: ColorFilter[] | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface ColorSort {
  direction: SortDirection;
  field: ColorSortFields;
  nulls?: SortNulls | null;
}

export interface CommercialFinishingFilter {
  and?: CommercialFinishingFilter[] | null;
  code?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  or?: CommercialFinishingFilter[] | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface CommercialFinishingInput {
  code: string;
  description: DescriptionObjectInputType;
  observation?: DescriptionObjectInputType | null;
}

export interface CommercialFinishingSort {
  direction: SortDirection;
  field: CommercialFinishingSortFields;
  nulls?: SortNulls | null;
}

export interface CommercialFinishingUpdate {
  description?: DescriptionObjectInputType | null;
  observation?: DescriptionObjectInputType | null;
}

export interface CommercialVarietyFilter {
  and?: CommercialVarietyFilter[] | null;
  code?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  description?: StringFieldComparison | null;
  id?: IDFilterComparison | null;
  materialtype?: IDFilterComparison | null;
  name?: StringFieldComparison | null;
  or?: CommercialVarietyFilter[] | null;
  system_variety?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface CommercialVarietyInput {
  code: string;
  description?: string | null;
  materialtype: string;
  name?: string | null;
  system_variety?: string | null;
}

export interface CommercialVarietySort {
  direction: SortDirection;
  field: CommercialVarietySortFields;
  nulls?: SortNulls | null;
}

export interface CommercialVarietyUpdate {
  description?: string | null;
  materialtype?: string | null;
  name?: string | null;
  system_variety?: string | null;
}

export interface CompanyFilter {
  address?: StringFieldComparison | null;
  and?: CompanyFilter[] | null;
  code?: StringFieldComparison | null;
  contact?: StringFieldComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  date?: DateFieldComparison | null;
  email?: StringFieldComparison | null;
  erp?: ERPTypeFilterComparison | null;
  fabricator?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  name?: StringFieldComparison | null;
  or?: CompanyFilter[] | null;
  stars?: StringFieldComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface CompanySort {
  direction: SortDirection;
  field: CompanySortFields;
  nulls?: SortNulls | null;
}

export interface CounterFilter {
  and?: CounterFilter[] | null;
  company?: IDFilterComparison | null;
  counterType?: CounterTypeFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  description?: StringFieldComparison | null;
  id?: IDFilterComparison | null;
  or?: CounterFilter[] | null;
  sequence_value?: IntFieldComparison | null;
  updatedAt?: DateFieldComparison | null;
}

export interface CounterSort {
  direction: SortDirection;
  field: CounterSortFields;
  nulls?: SortNulls | null;
}

export interface CounterTypeFilterComparison {
  eq?: CounterType | null;
  gt?: CounterType | null;
  gte?: CounterType | null;
  iLike?: CounterType | null;
  in?: CounterType[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  like?: CounterType | null;
  lt?: CounterType | null;
  lte?: CounterType | null;
  neq?: CounterType | null;
  notILike?: CounterType | null;
  notIn?: CounterType[] | null;
  notLike?: CounterType | null;
}

export interface CreateOneAnnexedDocumentInput {
  annexedDocument: AnnexedDocumentInput;
}

export interface CreateOneCommercialFinishingInput {
  commercialFinishing: CommercialFinishingInput;
}

export interface CreateOneCommercialVarietyInput {
  commercialVariety: CommercialVarietyInput;
}

export interface CreateOneImageColorFilterInput {
  imageColorFilter: ImageColorFilterInput;
}

export interface CreateOneJobInput {
  job: JobInput;
}

export interface CreateOnePackingInput {
  packing: PackingInput;
}

export interface CreateOnePackingTypeInput {
  packingType: PackingTypeInput;
}

export interface CreateOneProjectInput {
  project: ProjectInput;
}

export interface CreateOneShareLinkInput {
  shareLink: ShareLinkInput;
}

export interface CreateOneStoneEdgeInput {
  stoneEdge: StoneEdgeInput;
}

export interface CreateOneStoneModelInput {
  stoneModel: StoneModelInput;
}

export interface CreateOneUserInput {
  user: UserInput;
}

export interface CursorPaging {
  after?: weScanConnectionCursor | null;
  before?: weScanConnectionCursor | null;
  first?: number | null;
  last?: number | null;
}

export interface DateFieldComparison {
  between?: DateFieldComparisonBetween | null;
  eq?: weScanDateTime | null;
  gt?: weScanDateTime | null;
  gte?: weScanDateTime | null;
  in?: weScanDateTime[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  lt?: weScanDateTime | null;
  lte?: weScanDateTime | null;
  neq?: weScanDateTime | null;
  notBetween?: DateFieldComparisonBetween | null;
  notIn?: weScanDateTime[] | null;
}

export interface DateFieldComparisonBetween {
  lower: weScanDateTime;
  upper: weScanDateTime;
}

export interface DeleteImageInputType {
  delete: SlabImageDeleteInput;
}

export interface DeleteManyBlockModelsInput {
  filter: BlockModelDeleteFilter;
}

export interface DeleteManyPackingsInput {
  filter: PackingDeleteFilter;
}

export interface DeleteManySlabsInput {
  filter: SlabDeleteFilter;
}

export interface DeleteOneAnnexedDocumentInput {
  id: string;
}

export interface DeleteOneBlockModelInput {
  id: string;
}

export interface DeleteOneCommercialFinishingInput {
  id: string;
}

export interface DeleteOneCommercialVarietyInput {
  id: string;
}

export interface DeleteOneImageColorFilterInput {
  id: string;
}

export interface DeleteOneJobInput {
  id: string;
}

export interface DeleteOnePackingInput {
  id: string;
}

export interface DeleteOnePackingTypeInput {
  id: string;
}

export interface DeleteOneProjectInput {
  id: string;
}

export interface DeleteOneStoneEdgeInput {
  id: string;
}

export interface DeleteOneStoneModelInput {
  id: string;
}

export interface DescStateFilterComparison {
  eq?: DescState | null;
  gt?: DescState | null;
  gte?: DescState | null;
  iLike?: DescState | null;
  in?: DescState[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  like?: DescState | null;
  lt?: DescState | null;
  lte?: DescState | null;
  neq?: DescState | null;
  notILike?: DescState | null;
  notIn?: DescState[] | null;
  notLike?: DescState | null;
}

export interface DescriptionObjectInputType {
  en?: string | null;
  es?: string | null;
  pt: string;
}

export interface ERPTypeFilterComparison {
  eq?: ERPType | null;
  gt?: ERPType | null;
  gte?: ERPType | null;
  iLike?: ERPType | null;
  in?: ERPType[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  like?: ERPType | null;
  lt?: ERPType | null;
  lte?: ERPType | null;
  neq?: ERPType | null;
  notILike?: ERPType | null;
  notIn?: ERPType[] | null;
  notLike?: ERPType | null;
}

export interface EnvironmentalFactorFilter {
  aa?: BooleanFieldComparison | null;
  and?: EnvironmentalFactorFilter[] | null;
  application_type?: ApplicationTypologyFilterComparison | null;
  cdt?: BooleanFieldComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  description?: StringFieldComparison | null;
  dg?: BooleanFieldComparison | null;
  factor_type?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  level?: IntFieldComparison | null;
  me?: BooleanFieldComparison | null;
  measurement?: StringFieldComparison | null;
  mv?: BooleanFieldComparison | null;
  name?: StringFieldComparison | null;
  or?: EnvironmentalFactorFilter[] | null;
  pa?: BooleanFieldComparison | null;
  range_end?: FloatFieldComparison | null;
  range_start?: FloatFieldComparison | null;
  rc?: BooleanFieldComparison | null;
  rd?: BooleanFieldComparison | null;
  rf?: BooleanFieldComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
}

export interface EnvironmentalFactorSort {
  direction: SortDirection;
  field: EnvironmentalFactorSortFields;
  nulls?: SortNulls | null;
}

export interface FinishingFilter {
  and?: FinishingFilter[] | null;
  code?: StringFieldComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  date?: DateFieldComparison | null;
  id?: IDFilterComparison | null;
  or?: FinishingFilter[] | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface FinishingSort {
  direction: SortDirection;
  field: FinishingSortFields;
  nulls?: SortNulls | null;
}

export interface FloatFieldComparison {
  between?: FloatFieldComparisonBetween | null;
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  notBetween?: FloatFieldComparisonBetween | null;
  notIn?: number[] | null;
}

export interface FloatFieldComparisonBetween {
  lower: number;
  upper: number;
}

export interface IAuthenticationProvidersFilterComparison {
  eq?: IAuthenticationProviders | null;
  gt?: IAuthenticationProviders | null;
  gte?: IAuthenticationProviders | null;
  iLike?: IAuthenticationProviders | null;
  in?: IAuthenticationProviders[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  like?: IAuthenticationProviders | null;
  lt?: IAuthenticationProviders | null;
  lte?: IAuthenticationProviders | null;
  neq?: IAuthenticationProviders | null;
  notILike?: IAuthenticationProviders | null;
  notIn?: IAuthenticationProviders[] | null;
  notLike?: IAuthenticationProviders | null;
}

export interface IDAndCatalogDTO {
  bundleRules?: BundleCreation | null;
  data: SlabCatalogUpdate;
  slabs: string[];
}

export interface IDFilterComparison {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  iLike?: string | null;
  in?: string[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  notILike?: string | null;
  notIn?: string[] | null;
  notLike?: string | null;
}

export interface ImageColorFilterFilter {
  and?: ImageColorFilterFilter[] | null;
  commercial_finishing?: IDFilterComparison | null;
  commercial_variety?: IDFilterComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  inactive?: BooleanFieldComparison | null;
  name?: StringFieldComparison | null;
  or?: ImageColorFilterFilter[] | null;
  prevName?: StringFieldComparison | null;
  prev_commercial_finishing?: IDFilterComparison | null;
  prev_commercial_variety?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
}

export interface ImageColorFilterInput {
  commercial_finishing?: string | null;
  commercial_variety: string;
  inactive?: boolean | null;
  name: string;
  valueFilter: ImageColorFilterValuesInput;
}

export interface ImageColorFilterSort {
  direction: SortDirection;
  field: ImageColorFilterSortFields;
  nulls?: SortNulls | null;
}

export interface ImageColorFilterUpdate {
  commercial_finishing?: string | null;
  commercial_variety?: string | null;
  inactive?: boolean | null;
  name?: string | null;
  valueFilter?: ImageColorFilterValuesInput | null;
}

export interface ImageColorFilterValuesInput {
  brightness: number;
  contrast: number;
  exposure: number;
  gamma: number;
  hue_rotation: number;
  saturation: number;
  temperature: number;
}

export interface ImageInputDTO {
  appliedFilter?: string | null;
  appliedFilterAt?: weScanDateTime | null;
  azureBlobID: string;
  azureContainer: string;
  azureStorageAccount: string;
  dpi?: number | null;
  edited?: string | null;
  encoding?: string | null;
  imageFace?: ImageFace | null;
  imageScanType?: ImageScanType | null;
  mainType?: MainImageType | null;
  mimetype: string;
  original: string;
  scannedAt?: weScanDateTime | null;
  scannedBy?: string | null;
  thumbnail_edited?: string | null;
  thumbnail_original: string;
  uploadType?: ImageUploadType | null;
  uploadedBy?: string | null;
}

export interface IntFieldComparison {
  between?: IntFieldComparisonBetween | null;
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  notBetween?: IntFieldComparisonBetween | null;
  notIn?: number[] | null;
}

export interface IntFieldComparisonBetween {
  lower: number;
  upper: number;
}

export interface JobFilter {
  and?: JobFilter[] | null;
  block?: IDFilterComparison | null;
  blocknumber?: StringFieldComparison | null;
  category?: IDFilterComparison | null;
  code?: StringFieldComparison | null;
  commercial_finishing?: IDFilterComparison | null;
  commercial_variety?: IDFilterComparison | null;
  company?: IDFilterComparison | null;
  completed?: BooleanFieldComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  createdate?: DateFieldComparison | null;
  descstate?: DescStateFilterComparison | null;
  fabricator?: IDFilterComparison | null;
  finish?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  isblock?: BooleanFieldComparison | null;
  jobtype?: IDFilterComparison | null;
  lastupdate?: DateFieldComparison | null;
  numberingOrder?: JobNumberingOrderFilterComparison | null;
  or?: JobFilter[] | null;
  packing?: IDFilterComparison | null;
  prefix?: StringFieldComparison | null;
  project?: IDFilterComparison | null;
  scan?: IDFilterComparison | null;
  scannedSlabs?: IntFieldComparison | null;
  scheduledAt?: DateFieldComparison | null;
  separator?: StringFieldComparison | null;
  sequence?: IntFieldComparison | null;
  startNumber?: IntFieldComparison | null;
  state?: BooleanFieldComparison | null;
  stone_edge?: IDFilterComparison | null;
  stone_model?: IDFilterComparison | null;
  totalslab?: IntFieldComparison | null;
  typematerial?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
  variety?: IDFilterComparison | null;
}

export interface JobInput {
  block?: string | null;
  blocknumber?: string | null;
  category?: string | null;
  code: string;
  commercial_finishing?: string | null;
  commercial_variety?: string | null;
  description?: string | null;
  descstate?: DescState | null;
  finish?: string | null;
  height?: number | null;
  isblock?: boolean | null;
  jobtype?: string | null;
  numberingOrder?: JobNumberingOrder | null;
  packing?: string | null;
  prefix?: string | null;
  project?: string | null;
  scan: string;
  separator?: string | null;
  startNumber?: number | null;
  stone_edge?: string | null;
  stone_model?: string | null;
  thickness?: number | null;
  totalslab?: number | null;
  typematerial?: string | null;
  variety?: string | null;
  width?: number | null;
}

export interface JobNumberingOrderFilterComparison {
  eq?: JobNumberingOrder | null;
  gt?: JobNumberingOrder | null;
  gte?: JobNumberingOrder | null;
  iLike?: JobNumberingOrder | null;
  in?: JobNumberingOrder[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  like?: JobNumberingOrder | null;
  lt?: JobNumberingOrder | null;
  lte?: JobNumberingOrder | null;
  neq?: JobNumberingOrder | null;
  notILike?: JobNumberingOrder | null;
  notIn?: JobNumberingOrder[] | null;
  notLike?: JobNumberingOrder | null;
}

export interface JobSort {
  direction: SortDirection;
  field: JobSortFields;
  nulls?: SortNulls | null;
}

export interface JobUpdate {
  block?: string | null;
  blocknumber?: string | null;
  category?: string | null;
  commercial_finishing?: string | null;
  commercial_variety?: string | null;
  description?: string | null;
  descstate?: DescState | null;
  finish?: string | null;
  height?: number | null;
  isblock?: boolean | null;
  jobtype?: string | null;
  numberingOrder?: JobNumberingOrder | null;
  packing?: string | null;
  prefix?: string | null;
  project?: string | null;
  scan?: string | null;
  separator?: string | null;
  startNumber?: number | null;
  stone_edge?: string | null;
  stone_model?: string | null;
  thickness?: number | null;
  totalslab?: number | null;
  typematerial?: string | null;
  variety?: string | null;
  width?: number | null;
}

export interface JobtypeFilter {
  and?: JobtypeFilter[] | null;
  code?: StringFieldComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  createdate?: DateFieldComparison | null;
  description?: StringFieldComparison | null;
  id?: IDFilterComparison | null;
  isDefault?: BooleanFieldComparison | null;
  isSingle?: BooleanFieldComparison | null;
  or?: JobtypeFilter[] | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
}

export interface JobtypeSort {
  direction: SortDirection;
  field: JobtypeSortFields;
  nulls?: SortNulls | null;
}

export interface LoginUserInput {
  password: string;
  username: string;
}

export interface MaterialTypeFilter {
  and?: MaterialTypeFilter[] | null;
  code?: StringFieldComparison | null;
  createdAt?: DateFieldComparison | null;
  date?: DateFieldComparison | null;
  id?: IDFilterComparison | null;
  or?: MaterialTypeFilter[] | null;
  updatedAt?: DateFieldComparison | null;
}

export interface MaterialTypeSort {
  direction: SortDirection;
  field: MaterialTypeSortFields;
  nulls?: SortNulls | null;
}

export interface NumberFieldComparison {
  between?: NumberFieldComparisonBetween | null;
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
  notBetween?: NumberFieldComparisonBetween | null;
  notIn?: number[] | null;
}

export interface NumberFieldComparisonBetween {
  lower: number;
  upper: number;
}

export interface OffsetPaging {
  limit?: number | null;
  offset?: number | null;
}

export interface PackingDeleteFilter {
  and?: PackingDeleteFilter[] | null;
  code?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  inStock?: BooleanFieldComparison | null;
  isSharedPublicly?: BooleanFieldComparison | null;
  isSharedWithClients?: BooleanFieldComparison | null;
  or?: PackingDeleteFilter[] | null;
  packing?: StringFieldComparison | null;
  packingtype?: IDFilterComparison | null;
  project?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface PackingFilter {
  and?: PackingFilter[] | null;
  code?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  inStock?: BooleanFieldComparison | null;
  isSharedPublicly?: BooleanFieldComparison | null;
  isSharedWithClients?: BooleanFieldComparison | null;
  or?: PackingFilter[] | null;
  packing?: StringFieldComparison | null;
  packingtype?: IDFilterComparison | null;
  project?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface PackingInput {
  code: string;
  inStock?: boolean | null;
  observation?: string | null;
  packing: string;
  packingtype?: string | null;
  project?: string | null;
}

export interface PackingSort {
  direction: SortDirection;
  field: PackingSortFields;
  nulls?: SortNulls | null;
}

export interface PackingTypeFilter {
  and?: PackingTypeFilter[] | null;
  code?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  observation?: StringFieldComparison | null;
  or?: PackingTypeFilter[] | null;
  type?: StringFieldComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface PackingTypeInput {
  code: string;
  observation?: string | null;
  type?: string | null;
}

export interface PackingTypeSort {
  direction: SortDirection;
  field: PackingTypeSortFields;
  nulls?: SortNulls | null;
}

export interface PackingTypeUpdate {
  observation?: string | null;
  type?: string | null;
}

export interface PackingUpdate {
  inStock?: boolean | null;
  observation?: string | null;
  packing?: string | null;
  packingtype?: string | null;
  project?: string | null;
}

export interface PackingUpdateFilter {
  and?: PackingUpdateFilter[] | null;
  code?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  inStock?: BooleanFieldComparison | null;
  isSharedPublicly?: BooleanFieldComparison | null;
  isSharedWithClients?: BooleanFieldComparison | null;
  or?: PackingUpdateFilter[] | null;
  packing?: StringFieldComparison | null;
  packingtype?: IDFilterComparison | null;
  project?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface ProjectFilter {
  active?: BooleanFieldComparison | null;
  and?: ProjectFilter[] | null;
  code?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  createdate?: DateFieldComparison | null;
  description?: StringFieldComparison | null;
  fabricator?: IDFilterComparison | null;
  finaldate?: DateFieldComparison | null;
  id?: IDFilterComparison | null;
  inStock?: BooleanFieldComparison | null;
  initdate?: DateFieldComparison | null;
  isSharedPublicly?: BooleanFieldComparison | null;
  isSharedWithClients?: BooleanFieldComparison | null;
  observation?: StringFieldComparison | null;
  or?: ProjectFilter[] | null;
  project?: StringFieldComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface ProjectInput {
  active?: boolean | null;
  code: string;
  description?: string | null;
  finaldate?: weScanDateTime | null;
  inStock?: boolean | null;
  initdate?: weScanDateTime | null;
  observation?: string | null;
  project: string;
}

export interface ProjectSort {
  direction: SortDirection;
  field: ProjectSortFields;
  nulls?: SortNulls | null;
}

export interface ProjectUpdate {
  active?: boolean | null;
  description?: string | null;
  finaldate?: weScanDateTime | null;
  inStock?: boolean | null;
  initdate?: weScanDateTime | null;
  observation?: string | null;
  project?: string | null;
}

export interface ScannerFilter {
  and?: ScannerFilter[] | null;
  code?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  createdate?: DateFieldComparison | null;
  description?: StringFieldComparison | null;
  fabricator?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  or?: ScannerFilter[] | null;
  scanner_model?: ScannerModelEnumFilterComparison | null;
  snumber?: StringFieldComparison | null;
  state?: BooleanFieldComparison | null;
  subscription?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface ScannerModelEnumFilterComparison {
  eq?: ScannerModelEnum | null;
  gt?: ScannerModelEnum | null;
  gte?: ScannerModelEnum | null;
  iLike?: ScannerModelEnum | null;
  in?: ScannerModelEnum[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  like?: ScannerModelEnum | null;
  lt?: ScannerModelEnum | null;
  lte?: ScannerModelEnum | null;
  neq?: ScannerModelEnum | null;
  notILike?: ScannerModelEnum | null;
  notIn?: ScannerModelEnum[] | null;
  notLike?: ScannerModelEnum | null;
}

export interface ScannerSort {
  direction: SortDirection;
  field: ScannerSortFields;
  nulls?: SortNulls | null;
}

export interface ShareLinkFilter {
  and?: ShareLinkFilter[] | null;
  block?: IDFilterComparison | null;
  clientOnly?: BooleanFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  deleted?: BooleanFieldComparison | null;
  id?: IDFilterComparison | null;
  isValid?: BooleanFieldComparison | null;
  keyValue?: StringFieldComparison | null;
  or?: ShareLinkFilter[] | null;
  package?: IDFilterComparison | null;
  project?: IDFilterComparison | null;
  slab?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  validUntil?: DateFieldComparison | null;
}

export interface ShareLinkInput {
  block?: string | null;
  clientOnly?: boolean | null;
  clients?: string[] | null;
  deleted?: boolean | null;
  package?: string | null;
  project?: string | null;
  slab?: string | null;
  validUntil?: weScanDateTime | null;
}

export interface ShareLinkSort {
  direction: SortDirection;
  field: ShareLinkSortFields;
  nulls?: SortNulls | null;
}

export interface SlabCatalogUpdate {
  area?: number | null;
  block?: string | null;
  blocknumber?: string | null;
  cancelCataloguing?: boolean | null;
  category?: string | null;
  code?: string | null;
  commercial_finishing?: string | null;
  commercial_variety?: string | null;
  extheight?: number | null;
  extthickness?: number | null;
  extwidth?: number | null;
  finish?: string | null;
  height?: number | null;
  iscatalogued?: boolean | null;
  name?: string | null;
  packing?: string | null;
  project?: string | null;
  purchaseheight?: number | null;
  purchasethickness?: number | null;
  purchasewidth?: number | null;
  stkheight?: number | null;
  stkthickness?: number | null;
  stkwidth?: number | null;
  stone_edge?: string | null;
  stone_model?: string | null;
  thickness?: number | null;
  typematerial?: string | null;
  variety?: string | null;
  width?: number | null;
}

export interface SlabDeleteFilter {
  and?: SlabDeleteFilter[] | null;
  block?: IDFilterComparison | null;
  blocknumber?: StringFieldComparison | null;
  cancelCataloguing?: BooleanFieldComparison | null;
  category?: IDFilterComparison | null;
  classplogic?: IDFilterComparison | null;
  code?: StringFieldComparison | null;
  commercial_finishing?: IDFilterComparison | null;
  commercial_variety?: IDFilterComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  createdate?: DateFieldComparison | null;
  erp_id?: StringFieldComparison | null;
  finish?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  inStock?: BooleanFieldComparison | null;
  isSharedPublicly?: BooleanFieldComparison | null;
  isSharedWithClients?: BooleanFieldComparison | null;
  isblock?: BooleanFieldComparison | null;
  isblockjob?: BooleanFieldComparison | null;
  iscatalogued?: BooleanFieldComparison | null;
  ismobile?: BooleanFieldComparison | null;
  job?: IDFilterComparison | null;
  or?: SlabDeleteFilter[] | null;
  packing?: IDFilterComparison | null;
  process?: IDFilterComparison | null;
  project?: IDFilterComparison | null;
  scan?: IDFilterComparison | null;
  stone_edge?: IDFilterComparison | null;
  stone_model?: IDFilterComparison | null;
  subclass?: IDFilterComparison | null;
  subproject?: IDFilterComparison | null;
  typematerial?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  updatedate?: DateFieldComparison | null;
  updateuser?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
  variety?: IDFilterComparison | null;
}

export interface SlabFilter {
  and?: SlabFilter[] | null;
  block?: IDFilterComparison | null;
  blocknumber?: StringFieldComparison | null;
  cancelCataloguing?: BooleanFieldComparison | null;
  category?: IDFilterComparison | null;
  classplogic?: IDFilterComparison | null;
  code?: StringFieldComparison | null;
  commercial_finishing?: IDFilterComparison | null;
  commercial_variety?: IDFilterComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  createdate?: DateFieldComparison | null;
  erp_id?: StringFieldComparison | null;
  finish?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  inStock?: BooleanFieldComparison | null;
  isSharedPublicly?: BooleanFieldComparison | null;
  isSharedWithClients?: BooleanFieldComparison | null;
  isblock?: BooleanFieldComparison | null;
  isblockjob?: BooleanFieldComparison | null;
  iscatalogued?: BooleanFieldComparison | null;
  ismobile?: BooleanFieldComparison | null;
  job?: IDFilterComparison | null;
  or?: SlabFilter[] | null;
  packing?: IDFilterComparison | null;
  process?: IDFilterComparison | null;
  project?: IDFilterComparison | null;
  scan?: IDFilterComparison | null;
  stone_edge?: IDFilterComparison | null;
  stone_model?: IDFilterComparison | null;
  subclass?: IDFilterComparison | null;
  subproject?: IDFilterComparison | null;
  typematerial?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  updatedate?: DateFieldComparison | null;
  updateuser?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
  variety?: IDFilterComparison | null;
}

export interface SlabImageDeleteInput {
  id: string;
  image: string;
}

export interface SlabImageInput {
  id: string;
  metadata?: string | null;
  strfile: string;
  strfiletype: string;
}

export interface SlabSort {
  direction: SortDirection;
  field: SlabSortFields;
  nulls?: SortNulls | null;
}

export interface SlabUpdate {
  area?: number | null;
  block?: string | null;
  blocknumber?: string | null;
  cancelCataloguing?: boolean | null;
  category?: string | null;
  code?: string | null;
  commercial_finishing?: string | null;
  commercial_variety?: string | null;
  company?: string | null;
  description?: string | null;
  desigcomercial?: string | null;
  designormativa?: string | null;
  dpi?: number | null;
  editedImages?: string[] | null;
  erp_id?: string | null;
  extheight?: number | null;
  extthickness?: number | null;
  extwidth?: number | null;
  finish?: string | null;
  height?: number | null;
  images?: string[] | null;
  inStock?: boolean | null;
  isblock?: boolean | null;
  isblockjob?: boolean | null;
  iscatalogued?: boolean | null;
  ismobile?: boolean | null;
  job?: string | null;
  mainImage?: string | null;
  main_image?: string | null;
  name?: string | null;
  observation?: string | null;
  packing?: string | null;
  project?: string | null;
  purchaseheight?: number | null;
  purchasethickness?: number | null;
  purchasewidth?: number | null;
  scan?: string | null;
  slabImages?: ImageInputDTO[] | null;
  stkheight?: number | null;
  stkthickness?: number | null;
  stkwidth?: number | null;
  stone_edge?: string | null;
  stone_model?: string | null;
  thickness?: number | null;
  typematerial?: string | null;
  user?: string | null;
  variety?: string | null;
  width?: number | null;
}

export interface SlabUpdateFilter {
  and?: SlabUpdateFilter[] | null;
  block?: IDFilterComparison | null;
  blocknumber?: StringFieldComparison | null;
  cancelCataloguing?: BooleanFieldComparison | null;
  category?: IDFilterComparison | null;
  classplogic?: IDFilterComparison | null;
  code?: StringFieldComparison | null;
  commercial_finishing?: IDFilterComparison | null;
  commercial_variety?: IDFilterComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  createdate?: DateFieldComparison | null;
  erp_id?: StringFieldComparison | null;
  finish?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  inStock?: BooleanFieldComparison | null;
  isSharedPublicly?: BooleanFieldComparison | null;
  isSharedWithClients?: BooleanFieldComparison | null;
  isblock?: BooleanFieldComparison | null;
  isblockjob?: BooleanFieldComparison | null;
  iscatalogued?: BooleanFieldComparison | null;
  ismobile?: BooleanFieldComparison | null;
  job?: IDFilterComparison | null;
  or?: SlabUpdateFilter[] | null;
  packing?: IDFilterComparison | null;
  process?: IDFilterComparison | null;
  project?: IDFilterComparison | null;
  scan?: IDFilterComparison | null;
  stone_edge?: IDFilterComparison | null;
  stone_model?: IDFilterComparison | null;
  subclass?: IDFilterComparison | null;
  subproject?: IDFilterComparison | null;
  typematerial?: IDFilterComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  updatedate?: DateFieldComparison | null;
  updateuser?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
  variety?: IDFilterComparison | null;
}

/**
 * Upload Type of Slabs from WeScanHome platform
 */
export interface SlabUploadInput {
  metadata?: string | null;
  name: string;
  strfile: string;
  strfiletype: string;
}

export interface StoneEdgeFilter {
  and?: StoneEdgeFilter[] | null;
  code?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  description?: StringFieldComparison | null;
  id?: IDFilterComparison | null;
  name?: StringFieldComparison | null;
  or?: StoneEdgeFilter[] | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
}

export interface StoneEdgeInput {
  code: string;
  description: string;
  name: string;
}

export interface StoneEdgeSort {
  direction: SortDirection;
  field: StoneEdgeSortFields;
  nulls?: SortNulls | null;
}

export interface StoneEdgeUpdate {
  description?: string | null;
  name?: string | null;
}

export interface StoneLocationFilter {
  and?: StoneLocationFilter[] | null;
  coast_line?: BooleanFieldComparison | null;
  country?: StringFieldComparison | null;
  country_side?: BooleanFieldComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  id?: IDFilterComparison | null;
  industrialization?: BooleanFieldComparison | null;
  mountain?: BooleanFieldComparison | null;
  name?: StringFieldComparison | null;
  or?: StoneLocationFilter[] | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  urbanization?: BooleanFieldComparison | null;
}

export interface StoneLocationSort {
  direction: SortDirection;
  field: StoneLocationSortFields;
  nulls?: SortNulls | null;
}

export interface StoneModelFilter {
  and?: StoneModelFilter[] | null;
  code?: StringFieldComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  description?: StringFieldComparison | null;
  id?: IDFilterComparison | null;
  name?: StringFieldComparison | null;
  or?: StoneModelFilter[] | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
}

export interface StoneModelInput {
  code: string;
  description: string;
  name: string;
}

export interface StoneModelSort {
  direction: SortDirection;
  field: StoneModelSortFields;
  nulls?: SortNulls | null;
}

export interface StoneModelUpdate {
  description?: string | null;
  name?: string | null;
}

export interface StringFieldComparison {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  iLike?: string | null;
  in?: string[] | null;
  is?: boolean | null;
  isNot?: boolean | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  notILike?: string | null;
  notIn?: string[] | null;
  notLike?: string | null;
}

export interface UpdateManyPackingsInput {
  filter: PackingUpdateFilter;
  update: PackingUpdate;
}

export interface UpdateManySlabsInput {
  filter: SlabUpdateFilter;
  update: SlabUpdate;
}

export interface UpdateOneBlockModelUploadInputType {
  id: string;
  update: BlockModelUpdate;
}

export interface UpdateOneCommercialFinishingInput {
  id: string;
  update: CommercialFinishingUpdate;
}

export interface UpdateOneCommercialVarietyInput {
  id: string;
  update: CommercialVarietyUpdate;
}

export interface UpdateOneImageColorFilterInput {
  id: string;
  update: ImageColorFilterUpdate;
}

export interface UpdateOneJobInput {
  id: string;
  update: JobUpdate;
}

export interface UpdateOnePackingInput {
  id: string;
  update: PackingUpdate;
}

export interface UpdateOnePackingTypeInput {
  id: string;
  update: PackingTypeUpdate;
}

export interface UpdateOneProjectInput {
  id: string;
  update: ProjectUpdate;
}

export interface UpdateOneShareLinkInput {
  id: string;
  update: UpdateShareLink;
}

export interface UpdateOneSlabInput {
  id: string;
  update: SlabUpdate;
}

export interface UpdateOneStoneEdgeInput {
  id: string;
  update: StoneEdgeUpdate;
}

export interface UpdateOneStoneModelInput {
  id: string;
  update: StoneModelUpdate;
}

export interface UpdateShareLink {
  block?: string | null;
  clientOnly?: boolean | null;
  clients?: string[] | null;
  company?: string | null;
  createdAt?: weScanDateTime | null;
  createdBy?: string | null;
  deleted?: boolean | null;
  id?: string | null;
  isValid?: boolean | null;
  keyValue?: string | null;
  package?: string | null;
  project?: string | null;
  slab?: string | null;
  updatedAt?: weScanDateTime | null;
  validUntil?: weScanDateTime | null;
}

export interface UploadImagesInputType {
  images: SlabImageInput[];
}

export interface UploadSlabsWeScanHomeInputType {
  upload: SlabUploadInput[];
}

export interface UserInput {
  avatar?: string | null;
  company: string;
  email: string;
  firstname: string;
  lastname: string;
  name: string;
  password: string;
  role?: IRolesEnum | null;
  status: boolean;
}

export interface VarietyFilter {
  alternativedesig?: StringFieldComparison | null;
  and?: VarietyFilter[] | null;
  code?: StringFieldComparison | null;
  color?: IDFilterComparison | null;
  colordescriptiveone?: IDFilterComparison | null;
  colordescriptivetwo?: IDFilterComparison | null;
  company?: IDFilterComparison | null;
  createdAt?: DateFieldComparison | null;
  createdBy?: IDFilterComparison | null;
  date?: DateFieldComparison | null;
  districtquarry?: StringFieldComparison | null;
  id?: IDFilterComparison | null;
  lithologicalclass?: IDFilterComparison | null;
  lithologicalsubclass?: IDFilterComparison | null;
  macroscopicdesc?: StringFieldComparison | null;
  materialtype?: IDFilterComparison | null;
  microscopicdesc?: StringFieldComparison | null;
  municipalityquarry?: StringFieldComparison | null;
  normativedesig?: StringFieldComparison | null;
  observation?: StringFieldComparison | null;
  or?: VarietyFilter[] | null;
  stonequarry?: StringFieldComparison | null;
  updatedAt?: DateFieldComparison | null;
  updatedBy?: IDFilterComparison | null;
  user?: IDFilterComparison | null;
}

export interface VarietySort {
  direction: SortDirection;
  field: VarietySortFields;
  nulls?: SortNulls | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
