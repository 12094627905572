import { Container } from "@material-ui/core";
import { layoutConstant } from "../../utils/constants";

export default function Content({ children }: any) {
  return (
    <Container
      style={{
        justifyContent: "stretch",
        width: "100%",
        marginTop: layoutConstant.headerHeight,
      }}
      maxWidth={"xl"}
    >
      {children}
    </Container>
  );
}
