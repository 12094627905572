import { useMutation } from "@apollo/react-hooks";
import React from "react";
import CreateEntityForm from "../../../components/Form/CreateEntityForm";
import {
  CreateOneStoneModel,
  CreateOneStoneModelVariables,
  StoneModelInput,
} from "../../../data/graphQLModel";
import { MUTATION_CREATE_ONE_STONE_MODEL } from "../../../data/graphQLQueries";

const form: StoneModelInput = {
  code: "",
  name: "",
  description: "",
};

const requiredFields: (keyof StoneModelInput)[] = ["code", "description", "name"];

export default function CreateStoneModel() {
  const [mutationCreateOneStoneModel] = useMutation<
    CreateOneStoneModel,
    CreateOneStoneModelVariables
  >(MUTATION_CREATE_ONE_STONE_MODEL);

  const createOne = async (form: StoneModelInput) => {
    await mutationCreateOneStoneModel({
      variables: {
        input: {
          stoneModel: form,
        },
      },
    });
  };

  return (
    <CreateEntityForm
      mutation={createOne}
      entityForm={form}
      entityName={"Stone Model"}
      path={"/customerstables/stonemodel"}
      requiredFields={requiredFields}
    />
  );
}
