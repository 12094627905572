/*eslint-disable */
import { useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core/";
import { Helmet } from "react-helmet";
import GallerySlabs from "../../../../components/projectgallery/slab.projectgallery";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import "./styles.css";
import { useQuery } from "@apollo/react-hooks";
import {
  CursorPaging,
  SharedPackage,
  SharedPackageSlabs,
  SharedPackageSlabsVariables,
  SharedPackageVariables,
  SharedSlabs,
  SharedSlabsVariables,
  SlabSortFields,
  SortDirection,
} from "../../../../data/graphQLModel";
import {
  QUERY_MANY_SHARED_SLABS,
  QUERY_ONE_SHARED_PACKING,
  QUERY_SHARED_PACKING_SLABS,
} from "../../../../data/graphQLQueries";
import FlexBox from "../../../../components/GeneralComponents/FlexBox";
import { H2 } from "../../../../components/GeneralComponents/Typography";
import queryString from "query-string";
import { onChangePageCursor } from "../../../../utils/utils";
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProps } from "../../../../theme/theme";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    "& > *": {
      padding: 1,
      marginBottom: theme.spacing(4),
    },
  },
  boxTop: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
  },
  caption: {
    marginTop: "0.8rem",
  },
}));

export default function ProjectGallery() {
  const history = useHistory();
  const classes = useStyles();

  const { parpack } = useParams() as { parpack: string };
  const { search } = useLocation();
  const { projectKeyValue, pck } = queryString.parse(search || parpack) as {
    pck?: string;
    projectKeyValue?: string;
  };

  const tryToDecode = (code: string): string => {
    try {
      return atob(code);
    } catch (err) {
      return code;
    }
  };

  const scrollToY = (offSetTop: number | undefined) => {
    if (
      !!window &&
      typeof window !== "undefined" &&
      typeof offSetTop === "number" &&
      offSetTop >= 0
    ) {
      window.scrollTo({ top: offSetTop });
    }
  };

  const { loading, error, data } = useQuery<SharedPackage, SharedPackageVariables>(
    QUERY_ONE_SHARED_PACKING,
    {
      variables: {
        [!!pck ? "id" : parpack ? "keyValue" : ""]: pck ? tryToDecode(pck) : tryToDecode(parpack),
        slabsPaging: {
          first: 1,
        },
      },
      fetchPolicy: "cache-first",
      pollInterval: 30000,
    }
  );

  // ########################################## START SLABS ##########################################
  const slabsParentRef = useRef<HTMLElement>(null);
  const [slabsPageNumber, setSlabsPageNumber] = useState(0);
  const [slabsPaging, setSlabsPaging] = useState<CursorPaging>({ first: 9 });

  const {
    data: slabsData,
    loading: slabsLoading,
    error: slabsError,
    previousData: slabsPreviousData,
    ...slabs
  } = useQuery<SharedPackageSlabs, SharedPackageSlabsVariables>(QUERY_SHARED_PACKING_SLABS, {
    variables: {
      slabsSorting: [
        {
          direction: SortDirection.DESC,
          field: SlabSortFields.createdAt,
        },
      ],
      slabsPaging,
      [!!pck ? "id" : parpack ? "keyValue" : ""]: pck ? tryToDecode(pck) : tryToDecode(parpack),
    },
    fetchPolicy: "cache-first",
    pollInterval: 30000,
  });
  const handleSlabPageChange = (e: any, page: number) => {
    onChangePageCursor(
      e,
      page,
      slabsPageNumber,
      !!slabsPaging.first ? slabsPaging.first : !!slabsPaging.last ? slabsPaging.last : 10,
      slabsData?.sharedPackage?.slabs?.pageInfo || ({} as any),
      (pageNumber: number, newPaging: CursorPaging) => {
        setSlabsPaging(newPaging);
        setSlabsPageNumber(pageNumber);
        scrollToY(slabsParentRef?.current?.offsetTop);
      }
    );
  };

  // ########################################## END SLABS ##########################################

  return (
    <div className="container">
      <Helmet>
        <title>Bundle Share | WeScan</title>
      </Helmet>
      <Box ref={slabsParentRef}>
        <Card style={{ marginTop: "50px", marginBottom: "10px" }}>
          <CardContent>
            <FlexBox flexDirection={"row"} justifyContent="space-between" mb={3}>
              <H2>Shared Bundle</H2>
            </FlexBox>
            <Grid container xs={12} maxWidth="xl" justifyContent={"space-between"}>
              <Grid container spacing={3} item md={5} xs={12}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    {loading ? (
                      <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                        <TextField value={""} />
                      </Skeleton>
                    ) : (
                      <TextField
                        id="CodePackage"
                        label="Code"
                        name="code"
                        value={data?.sharedPackage?.code}
                        placeholder="Code"
                        disabled
                        variant="outlined"
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    {loading ? (
                      <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                        <TextField value={""} />
                      </Skeleton>
                    ) : (
                      <TextField
                        id="descPackage"
                        label="Description"
                        name="description"
                        disabled
                        value={data?.sharedPackage?.packing}
                        placeholder="Description"
                        required
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    {loading ? (
                      <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                        <TextField value={""} />
                      </Skeleton>
                    ) : (
                      <TextField
                        id="packPackage"
                        label="Package Type"
                        name="package"
                        value={
                          data?.sharedPackage?.packingtype?.observation ||
                          data?.sharedPackage?.packingtype?.type
                        }
                        placeholder="Package"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth variant="outlined">
                  {loading ? (
                    <Skeleton
                      component={"div"}
                      variant="rectangular"
                      width={"100%"}
                      height={270}
                      sx={{ marginTop: 2 }}
                    >
                      <TextField value={""} />
                    </Skeleton>
                  ) : (
                    <TextField
                      id="obsProject"
                      label="Observation"
                      name="observation"
                      placeholder="Observation"
                      disabled
                      value={data?.sharedPackage?.observation}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline
                      rows={11}
                      variant="outlined"
                    />
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardContent>
            <h3 style={{ marginTop: 10 }}>
              Slabs{" "}
              {slabsData?.sharedPackage?.slabs?.totalCount ||
              slabsPreviousData?.sharedPackage?.slabs?.totalCount
                ? `(${
                    slabsData?.sharedPackage?.slabs?.totalCount ||
                    slabsPreviousData?.sharedPackage?.slabs?.totalCount
                  })`
                : "Loading..."}
            </h3>
          </CardContent>
        </Card>
        {slabsData &&
        !slabsLoading &&
        !slabsError &&
        slabsData?.sharedPackage?.slabs?.edges &&
        slabsData.sharedPackage.slabs.edges.length > 0 ? (
          <Grid container spacing={2} mt={1}>
            {slabsData.sharedPackage.slabs?.edges.map((stn, key) => (
              <Grid item xs={12} md={4}>
                <GallerySlabs key={key} slab={stn.node} keyValue={projectKeyValue || parpack} />
              </Grid>
            ))}
          </Grid>
        ) : (
          slabs &&
          !slabsLoading && (
            <Box sx={{ alignItems: "center", justifyContent: "center", p: 5, width: "100%" }}>
              <h3
                style={{
                  textAlign: "center",
                  verticalAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {slabsError ? slabsError.message : "No Results"}
              </h3>
            </Box>
          )
        )}
        {(slabsLoading || (!!!slabsData && !!slabsPreviousData)) && (
          <Grid container spacing={2} mt={1} height={1200}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((stn, key) => (
              <Grid item xs={12} md={4} key={key}>
                <Skeleton variant="rectangular" height="100%" />
              </Grid>
            ))}
          </Grid>
        )}
        <Box
          // className={classes.root}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: 10,
          }}
        >
          {!!(
            slabsData?.sharedPackage?.slabs?.totalCount ||
            slabsPreviousData?.sharedPackage?.slabs?.totalCount
          ) &&
            slabsPaging?.first &&
            (slabsData?.sharedPackage?.slabs?.totalCount ||
              slabsPreviousData?.sharedPackage?.slabs?.totalCount ||
              0) > slabsPaging?.first && (
              <FlexBox sx={{ alignItems: "center", justifyContent: "center", p: 5 }}>
                <TablePagination
                  classes={{
                    selectLabel: classes.caption,
                    displayedRows: classes.caption,
                    root: classes.root,
                  }}
                  count={
                    slabsData?.sharedPackage?.slabs?.totalCount ||
                    slabsPreviousData?.sharedPackage?.slabs?.totalCount ||
                    0
                  }
                  rowsPerPage={slabsPaging.first}
                  page={slabsPageNumber}
                  onPageChange={handleSlabPageChange}
                  onRowsPerPageChange={(event) => {
                    let newLimit = parseInt(event.target.value, 10);
                    setSlabsPaging({ first: newLimit });
                    setSlabsPageNumber(0);
                  }}
                  rowsPerPageOptions={[9, 12, 15, 18]}
                  labelRowsPerPage="Results per page"
                />
              </FlexBox>
            )}
        </Box>
      </Box>
      {parpack?.split("-")?.length !== 5 && (
        <div className="btnform">
          <div>
            <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
              <Button
                type="submit"
                onClick={() => {
                  history.goBack();
                }}
                fullWidth
                color="primary"
                variant="contained"
                style={{ height: "50px", width: "200px", fontSize: "20px" }}
              >
                <ArrowBackIosIcon />
                Back
              </Button>
            </FormControl>
          </div>
        </div>
      )}
    </div>
  );
}
