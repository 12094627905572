/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Close,
  Link,
  Share,
  Send as SendIcon,
  RemoveCircle,
  Public,
  Undo,
} from "@material-ui/icons";
import Radio from "@material-ui/core/Radio";
import {
  DialogProps,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
  TextField,
  CircularProgress,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Grid,
  Chip,
  Skeleton,
  LinearProgress,
  ToggleButtonGroup,
  ToggleButton,
  ButtonProps,
  Select,
  MenuItem,
  Stack,
  DialogActions,
} from "@material-ui/core";
import { H2, H3 } from "../../components/GeneralComponents/Typography";
import FlexBox from "../../components/GeneralComponents/FlexBox";
import { SxProps } from "@material-ui/system";
import {
  Clients,
  ClientSort,
  ClientsVariables,
  Clients_clients_edges_node,
  CreateOneShareLink,
  CreateOneShareLinkVariables,
  CreateOneShareLink_createOneShareLink,
  CursorPaging,
  ShareLinkFilter,
  ShareLinkInput,
  ShareLinks,
  ShareLinkSort,
  ShareLinksVariables,
  UpdateOneShareLink,
  UpdateOneShareLinkVariables,
} from "../../data/graphQLModel";
import toast from "react-hot-toast";
import { add, format, startOfDay } from "date-fns";
import AutocompleteWithQuery from "../Form/AutocompleteWithQuery";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  MUTATION_CREATE_ONE_SHAREABLE_LINK,
  MUTATION_UPDATE_ONE_SHAREABLE_LINK,
  QUERY_MANY_CLIENTS,
  QUERY_MANY_SHAREABLE_LINKS,
} from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { useFormik } from "formik";
import * as yup from "yup";
import { weScanDateTime } from "../../utils/Types";
import SelectDate from "../../components/CatalogFilters/SelectDate";
import PublicIcon from "@material-ui/icons/Public";
import LockIcon from "@material-ui/icons/Lock";

// TODO UseEffect para internamente construir o base URL para partilhar consoante o shareType
type ShareType = "package" | "block" | "slab" | "project" | "uam outra";

enum PrivacyOptions {
  "privateOnly" = "Share with different clients",
  "public" = "Public Link",
}

interface ShareLinkDialogProps {
  DialogProps?: Partial<DialogProps>;
  buttonTitle?: string;
  buttonProps?: Partial<ButtonProps>;
  disabled?: boolean;
  dialogTitle?: string;
  parentBoxSxProps?: SxProps<any>;
  shareType: ShareType & keyof ShareLinkInput;
  sharedId: string;
}

const checkoutSchema = (shareType: ShareType, minDate: Date) =>
  yup.object().shape({
    block: shareType === "block" ? yup.string().required("Required") : yup.string().nullable(),
    package: shareType === "package" ? yup.string().required("Required") : yup.string().nullable(),
    project: shareType === "project" ? yup.string().required("Required") : yup.string().nullable(),
    slab: shareType === "slab" ? yup.string().required("Required") : yup.string().nullable(),
    validUntil: yup
      .date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) {
          return value;
        }
        const result = new Date(originalValue);
        return result;
      })
      .typeError("Invalid date!")
      .min(minDate),
    clientsOnly: yup.boolean(),
    clients: yup.array().when("clientsOnly", {
      // verifies if clientsOnly is true
      is: (clientsOnly: boolean) => {
        return !!clientsOnly;
      },
      // true
      then: () => yup.array().min(1).of(yup.string()).defined().required(),
      // false
      otherwise: () => yup.array().of(yup.string()),
    }),
  });

// TODO: SLAB e BLOCK sharePages with API_v2
const getBaseURL = (shareType: ShareType & keyof ShareLinkInput): string => {
  switch (shareType) {
    case "package":
      return `${window.location.href.replace(
        window.location.pathname,
        ""
      )}/packagegallery/sharepackages/`;
    case "block":
      return "";
    case "slab":
      return "";
    case "project":
      return `${window.location.href.replace(
        window.location.pathname,
        ""
      )}/projectgallery/shareproject/`;
  }
};

export default function ShareLinkButtonDialog({
  DialogProps,
  buttonTitle = "Link to Share",
  buttonProps,
  disabled = false,
  parentBoxSxProps,
  dialogTitle = "Link to Share",
  shareType,
  sharedId,
}: ShareLinkDialogProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleDialog = () => setIsOpen(!isOpen);
  const [generatedURL, setGeneratedURL] = useState<string>(getBaseURL(shareType));

  // export default function MaterialUIPickers() {
  //   const [value, setValue] = React.useState<Dayjs | null>(
  //     dayjs('2014-08-18T21:11:54'),
  //   );

  //   const handleChange = (newValue: Dayjs | null) => {
  //     setValue(newValue);
  //   };

  const getInitialValues = (): ShareLinkInput => {
    return {
      [shareType]: sharedId,
      validUntil: new weScanDateTime(add(new Date(), { years: 69 })),
      clientOnly: false,
    };
  };

  const [mutationCreateOneShareableLink] = useMutation<
    CreateOneShareLink,
    CreateOneShareLinkVariables
  >(MUTATION_CREATE_ONE_SHAREABLE_LINK);

  const [mutationUpdateOneShareableLink] = useMutation<
    UpdateOneShareLink,
    UpdateOneShareLinkVariables
  >(MUTATION_UPDATE_ONE_SHAREABLE_LINK);

  // Client AutoCompleteWithQuery
  const [privacyOption, setPrivacyOption] = useState<PrivacyOptions>(PrivacyOptions.privateOnly);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [always, setAlways] = useState<boolean>(false);
  const [clientSearch, setClientSearch] = useState<string>();
  const [selectedClients, setSelectedClients] = useState<Clients_clients_edges_node[]>([]);
  const [clients, setClients] = useState<Clients_clients_edges_node[]>([]);
  const [prefix, setPrefix] = useState<string>();

  const [isCreatingShareable, setIsCreatingShareable] = useState<boolean>(false);
  const [shareableData, setShareableData] = useState<CreateOneShareLink_createOneShareLink>();
  const createShareableLink = async (values: ShareLinkInput) => {
    setIsCreatingShareable(true);
    await mutationCreateOneShareableLink({
      variables: {
        input: {
          shareLink: values,
        },
      },
    }).then(async (res) => {
      if (!!res.errors) {
        toast.error(res?.errors?.toString());
      } else {
        setShareableData(res.data?.createOneShareLink);
        setGeneratedURL(generatedURL + res.data?.createOneShareLink.keyValue);
      }
    });
    setIsCreatingShareable(false);
  };

  const { values, dirty, errors, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik(
    {
      initialValues: getInitialValues(),
      onSubmit: createShareableLink,
      validationSchema: checkoutSchema(shareType, startOfDay(add(new Date(), { days: 7 }))),
    }
  );

  // Handler for Privacy toggle options
  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, value: PrivacyOptions) => {
    setPrivacyOption(value);
  };

  const onCopy = async () => {
    if (shareableData) {
      if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(generatedURL);
      } else {
        document.execCommand("copy", true, generatedURL);
      }
      toast.success("Copied to clipboard!");
    }
  };

  const { loading, error, data } = useQuery<ShareLinks, ShareLinksVariables>(
    QUERY_MANY_SHAREABLE_LINKS,
    {
      variables: {
        filter: {
          isValid: { is: true },
          [shareType]: { eq: sharedId },
          deleted: { is: false },
        },
      },
      onCompleted(data) {
        let newshareableResult = data?.shareLinks.edges[0]?.node;
        if (!!newshareableResult) {
          setShareableData(newshareableResult);
          setGeneratedURL(generatedURL + newshareableResult?.keyValue);
        }
      },
      skip: !!!shareType || !!!sharedId || sharedId?.trim() === "",
    }
  );

  // Almost certain it does not match task pre-requesites   => DELETE????
  const getShareableLinks = async (values: any) => {
    await queryValuesForFilter<ShareLinkFilter, CursorPaging, ShareLinkSort, ShareLinks>(
      values,
      QUERY_MANY_SHAREABLE_LINKS,
      (res) => {
        let shareableResult = res.data.shareLinks.edges.map((el) => el.node);
        setShareableData(shareableResult[0]);
      },
      {
        filter: {
          [shareType]: { eq: sharedId },
          isValid: { is: true },
        },
      }
    );
  };

  /**
   * Function that handles text change on AutoComplete;
   * @param value AutoCompleteWithQuery text value;
   */
  const handleOnTextChange = async (value: string) => {
    await queryValuesForFilter<ClientsVariables["filter"], CursorPaging, ClientSort, Clients>(
      value,
      QUERY_MANY_CLIENTS,
      (res) => {
        let endRes = res?.data?.clients.edges?.map((el) => el.node);
        setClients(endRes);
      },
      {
        filter: {
          and: [
            { or: [{ code: { iLike: value } }, { name: { iLike: value } }] },
            {
              id: {
                notIn: selectedClients.map((el) => {
                  return el.id;
                }),
              },
            },
          ],
        },
        paging: { first: 25 },
      },
      "network-only",
      true
    );
  };
  useEffect(() => {
    setFieldValue(shareType, sharedId);
  }, [sharedId]);

  useEffect(() => {
    handleOnTextChange("");
  }, [selectedClients]);

  return (
    <Box sx={parentBoxSxProps}>
      <Button
        {...buttonProps}
        disabled={disabled}
        startIcon={<Share />}
        onClick={() => {
          toggleDialog();
        }}
      >
        {buttonTitle}
      </Button>
      <Dialog
        maxWidth={"lg"}
        fullWidth
        {...DialogProps}
        open={isOpen}
        onClose={(e) => {
          toggleDialog();
        }}
      >
        <DialogTitle
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            pl: 2.5,
            pr: 2.5,
            zIndex: 101,
          }}
        >
          <FlexBox flexDirection={"row"} alignItems="center">
            <Share sx={{ marginRight: "1rem" }} />
            <H2 sx={{ textAlign: "center" }}>{dialogTitle}</H2>
          </FlexBox>
          <FlexBox>
            <IconButton onClick={() => toggleDialog()}>
              <Close className="close" fontSize="medium" color="primary" />
            </IconButton>
          </FlexBox>
        </DialogTitle>
        <DialogContent>
          <FlexBox flexDirection={"row"}>
            {loading ? (
              <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                <TextField value={""} />
              </Skeleton>
            ) : (
              <TextField
                InputLabelProps={{ shrink: false }}
                fullWidth
                label=" "
                sx={{ flex: 6 }}
                InputProps={{
                  endAdornment: isCreatingShareable ? <CircularProgress size={20} /> : <></>,
                }}
                value={
                  !!shareableData && !!generatedURL ? generatedURL : "Your URL will show up here!"
                }
              />
            )}
            {/* TODO o CreateShareableLink deve poder receber parametros ex : clientes, valid until,flag clientOnly que diz se é cliente || public, etc */}
            {!shareableData && (
              <Button
                onClick={(values) => {
                  getShareableLinks(values);
                  handleSubmit();
                }}
                sx={{ ml: "1rem", flex: 1 }}
                fullWidth
                startIcon={<Link />}
              >
                Create
              </Button>
            )}
            {!!shareableData && (
              <>
                <Button
                  onClick={async () => {
                    await mutationUpdateOneShareableLink({
                      variables: { input: { id: shareableData.id, update: { deleted: true } } },
                    });
                    resetForm({ values: getInitialValues() });
                    setGeneratedURL(getBaseURL(shareType));
                    setShareableData(undefined);
                  }}
                  sx={{ ml: "1rem" }}
                  startIcon={<Undo />}
                >
                  Undo
                </Button>
                <Button onClick={() => onCopy()} sx={{ ml: "1rem" }} startIcon={<Link />}>
                  Copy
                </Button>
              </>
            )}
          </FlexBox>
          {loading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <FlexBox
              mt={"1rem"}
              mb={"1rem"}
              flexDirection={"row"}
              sx={{ justifyContent: "flex-start" }}
            >
              {/* <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Availability:{" "}
                  <Typography fontSize={14} color={"primary"} justifySelf={"center"}>
                    {always
                      ? "Always"
                      : `Until ${format(values?.validUntil as Date, "yyyy-MM-dd")}`}
                  </Typography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={"7"}
                >
                  <FormControlLabel
                    value="7"
                    control={<Radio />}
                    label="7 Days"
                    onClick={() => {
                      setFieldValue("validUntil", new weScanDateTime(add(new Date(), { days: 7 })));
                      setAlways(false);
                      setDatePickerOpen(false);
                    }}
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="1 Month"
                    onClick={() => {
                      setFieldValue(
                        "validUntil",
                        new weScanDateTime(add(new Date(), { months: 1 }))
                      );
                      setAlways(false);
                      setDatePickerOpen(false);
                    }}
                  />
                  <FormControlLabel
                    value="always"
                    control={<Radio />}
                    label="Always"
                    onClick={() => {
                      setFieldValue(
                        "validUntil",
                        new weScanDateTime(add(new Date(), { years: 69 }))
                      );
                      setAlways(true);
                      setDatePickerOpen(false);
                    }}
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Custom"
                    onClick={() => {
                      setDatePickerOpen(true);
                      setAlways(false);
                    }}
                  />

                  {datePickerOpen && (
                    <FlexBox>
                      <SelectDate
                        label={"Select custom date"}
                        selectedDate={values.validUntil}
                        handleDateChange={(el) => {
                          setFieldValue("validUntil", new weScanDateTime(el));
                        }}
                        minDate={new Date()}
                      />
                    </FlexBox>
                  )}
                </RadioGroup>
              </FormControl> */}
            </FlexBox>
          )}

          {/* <Grid sx={{ display: " flex" }}>
            <Select
              label="Visibility"
              value={privacyOption}
              onChange={(value) => {
                setPrivacyOption(value?.target?.value as PrivacyOptions);
              }}
            >
              <MenuItem value={PrivacyOptions.privateOnly}>
                <Stack direction={"row"} spacing={1}>
                  <Typography>{PrivacyOptions.privateOnly}</Typography>
                  <LockIcon fontSize="small" color="primary" />
                </Stack>
              </MenuItem>
              <MenuItem value={PrivacyOptions.public}>
                <Stack direction={"row"} spacing={1}>
                  <Typography>{PrivacyOptions.public}</Typography>
                  <PublicIcon fontSize="small" color="primary" />
                </Stack>
              </MenuItem>
            </Select>
          </Grid> */}

          {/* {privacyOption === PrivacyOptions.privateOnly && (
            <Box sx={{ mt: 2 }}>
              <H3 sx={{ textAlign: "start", marginBottom: "1rem" }}>Add Clients:</H3>

              <FormControl>
                <FlexBox flexDirection={"column"} display={"flex"} justifyContent={"start"}>
                  <AutocompleteWithQuery
                    clearTextOnSelect
                    items={clients}
                    label="Clients"
                    name="clients"
                    title="Clients"
                    onChange={(e: any) => {
                      let clientId = e.target.value;
                      let foundClient = clients.find((el) => el.id === clientId);
                      if (!!foundClient) {
                        let newSelectedClients = [...selectedClients, foundClient];
                        setClientSearch(foundClient.name);
                        setSelectedClients(newSelectedClients);
                        setClientSearch(undefined);
                        setFieldValue(
                          "clients",
                          newSelectedClients.map((el) => el.id)
                        );
                      }
                    }}
                    value={clientSearch}
                    onTextChange={handleOnTextChange}
                    getField={(node: Clients_clients_edges_node) => {
                      return `${node.name} `;
                    }}
                  />
                  <FlexBox>
                    {selectedClients.map((clt) => (
                      <Grid item>
                        <Chip
                          label={clt?.name}
                          onDelete={() => {
                            let newSelectedClient = [...selectedClients];
                            setSelectedClients(newSelectedClient.filter((el) => el.id !== clt.id));
                            setFieldValue(
                              "clients",
                              newSelectedClient.map((el) => el.id)
                            );
                            // let index = newSelectedClient.findIndex((el) => el.id === clt.id);
                            // if (index !== -1) {
                            //   newSelectedClient.splice(index, 1);
                            //   setSelectedClients(newSelectedClient);
                            // }
                          }}
                          deleteIcon={<RemoveCircle />}
                        />
                      </Grid>
                    ))}
                  </FlexBox>
                </FlexBox>
              </FormControl>
            </Box>
          )} */}
        </DialogContent>
        {/* <DialogActions sx={{ p: 3 }}>
          <Button variant="contained" onClick={() => onCopy()} startIcon={<SendIcon />}>
            Share
          </Button>
        </DialogActions> */}
      </Dialog>
    </Box>
  );
}

{
  /* Eg that i don't recall why it's here => <>
<Hidden only={["sm", "xs"]}>
  <Grid
    container
    style={{
      display: "flex",
      flexDirection: "row",
      marginTop: 5,
      maxHeight: layoutConstant.headerHeight,
      marginLeft: 2,
    }}
    spacing={1}
  >
    <Grid item>
      <H3 sx={{ fontWeight: "bold" }}>Filters: </H3>
    </Grid>
    {dataForChips.map((el) => (
      <Grid item>
        <Chip
          label={el?.label}
          onDelete={() => {
            let newFilters = copyObject(globalFilters, []);
            delete newFilters[el?.key];
            setGlobalFilters(newFilters);
            let newFiltersUIValue = copyObject(filtersUIValue, []);
            delete newFiltersUIValue[el?.key];
            setFiltersUIValue(newFiltersUIValue);
          }}
          deleteIcon={<RemoveCircle />}
        />
      </Grid>
    ))}
    {(!!!dataForChips || dataForChips.length === 0) && (
      <Grid item>
        <H3 sx={{ fontWeight: "300" }}>Empty</H3>
      </Grid>
    )}
  </Grid>
</Hidden>
<Hidden only={["lg", "md", "xl"]}>
  <Grid container></Grid>
</Hidden>
</> */
}
