import { Card, Grid, Button, Container, Chip, Box, Typography } from "@material-ui/core";
import * as yup from "yup";
import { Formik } from "formik";
import {
  ApplicationTypeFilter,
  ApplicationTypes,
  ApplicationTypesVariables,
  ApplicationTypology,
  QueryColors,
  QueryColorsVariables,
  QueryColors_colors_edges_node,
  StoneLocationFilter,
  StoneLocations,
  StoneLocationsVariables,
} from "../../../data/graphQLModel";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProps } from "../../../theme/theme";
import { ArrowBack, RemoveCircle, Clear, Search } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import AutocompleteFilterJustText from "../../../components/Filters/AutoCompleteFilterJustText";
import { useQuery } from "@apollo/react-hooks";
import { APPLICATION_TYPES, QUERY_COLORS, STONE_LOCATIONS } from "../../../data/graphQLQueries";
import { useState } from "react";
import AutocompleteFilterObject from "../../../components/Filters/AutoCompleteFilterObjects";
import { error } from "../../../theme/themeColors";

const useStyles = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  images: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: 5,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
}));

export const SmartStoneForm = () => {
  const classes = useStyles();
  const history = useHistory();

  // LOCATION
  const [locationFilters, setLocationFilters] = useState<StoneLocationFilter | null | undefined>();
  const {
    loading: location_loading,
    error: location_error,
    data: location_data,
    refetch: location_refetch,
  } = useQuery<StoneLocations, StoneLocationsVariables>(STONE_LOCATIONS, {
    variables: {
      filter: locationFilters,
    },
  });

  // APPLICATION TYPES (DESCRIPTION, APPLICATION_SITE, DEVELOPMENT_TYPE)
  const [applicationTypeFilters, setApplicationTypeFilters] = useState<
    ApplicationTypeFilter | null | undefined
  >();
  const {
    loading: app_type_loading,
    error: app_type_error,
    data: app_type_data,
    refetch: app_type_refetch,
  } = useQuery<ApplicationTypes, ApplicationTypesVariables>(APPLICATION_TYPES, {
    variables: {
      filter: applicationTypeFilters,
    },
  });

  // COLORS
  const [selectedColors, setSelectedColors] = useState<
    QueryColors_colors_edges_node[] | undefined
  >();
  const {
    loading: colors_loading,
    error: colors_error,
    data: colors_data,
    refetch: colors_refetch,
  } = useQuery<QueryColors, QueryColorsVariables>(QUERY_COLORS, {
    variables: {
      paging: { first: 30 },
      filter: selectedColors ? { id: { notIn: selectedColors?.map((el) => el.id) } } : {},
    },
  });

  // Handles changes on form by changing the filters
  const handleChangesOnForm = (target: {
    name: keyof IFormSmartStone;
    value: string | undefined;
  }) => {
    if (target.name === "development_type") {
      if (target.value && target.value.trim() !== "") {
        setApplicationTypeFilters({
          ...applicationTypeFilters,
          development_type: { eq: target.value },
        });
      } else {
        const newFilter = { ...applicationTypeFilters };
        delete newFilter.development_type;
        setApplicationTypeFilters(newFilter);
      }
    }
    if (target.name === "application_typology") {
      if (target.value && target.value.trim() !== "") {
        setApplicationTypeFilters({
          ...applicationTypeFilters,
          application_typology: { eq: target.value as ApplicationTypology },
        });
      } else {
        const newFilter = { ...applicationTypeFilters };
        delete newFilter.application_typology;
        setApplicationTypeFilters(newFilter);
      }
    }
    if (target.name === "application_site") {
      if (target.value && target.value.trim() !== "") {
        setApplicationTypeFilters({
          ...applicationTypeFilters,
          application_site: { eq: target.value },
        });
      } else {
        const newFilter = { ...applicationTypeFilters };
        delete newFilter.application_site;
        setApplicationTypeFilters(newFilter);
      }
    }
    if (target.name === "colors") {
      const newArray = selectedColors ? selectedColors?.map((el) => el) : [];
      const newColor = colors_data?.colors.edges
        .map((el_edges) => el_edges.node)
        .find((el) => el.id === target.value) as QueryColors_colors_edges_node;
      newArray?.push(newColor);
      setSelectedColors(newArray);
    }
    // TODO:
    // if (target.name === 'description') {
    //   setApplicationTypeFilters({ ...applicationTypeFilters, description: { eq: target.value } })
    // }
  };

  const GenerateColorChips = (): JSX.Element => {
    return (
      <Box style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
        {selectedColors?.map((el_color) => {
          return (
            <Chip
              label={el_color.color}
              onDelete={() => {
                const newColors = selectedColors?.filter((el) => el.color !== el_color.color);
                setSelectedColors(newColors);
              }}
              deleteIcon={<RemoveCircle />}
            />
          );
        })}
      </Box>
    );
  };

  const handleFormSubmit = async (values: any) => {
    console.log(values);
  };

  return (
    <Container fixed>
      <Helmet>
        <title>Smart Stone | WeScan</title>
      </Helmet>
      <Grid container md={12} className={classes.images}>
        <Grid item xs={2}>
          <img width="80%" height="80%" src={"images/logo_wescan_orange.png"}></img>
        </Grid>
      </Grid>
      <Card sx={{ p: 5 }}>
        <Typography
          variant={"h4"}
          textAlign={"center"}
          color={error[600]}
          sx={{ fontStyle: "italic", mb: 5 }}
        >
          In Development
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setErrors,
            setTouched,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <AutocompleteFilterObject
                    items={
                      location_data
                        ? [...location_data.stoneLocations.edges.map((el) => el.node), ""]
                        : []
                    }
                    title={"Stone Location"}
                    label={"Stone Location"}
                    value={values.location}
                    onBlur={handleBlur}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleChangesOnForm(e.target);
                      handleChange(e);
                    }}
                    field="name"
                    name={"location"}
                    extraFields={["country"]}
                    error={!!touched.location && !!errors.location}
                    helperText={touched.location && errors.location}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <AutocompleteFilterObject
                    items={
                      app_type_data
                        ? [
                            ...app_type_data.applicationTypes.edges.map(
                              (el) => el.node.development_type
                            ),
                            "",
                          ]
                        : []
                    }
                    title={"Development Type"}
                    label={"Development Type"}
                    value={values.development_type}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleChangesOnForm(e.target);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    field="name"
                    name={"development_type"}
                    error={!!touched.development_type && !!errors.development_type}
                    helperText={touched.development_type && errors.development_type}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <AutocompleteFilterJustText
                    items={[ApplicationTypology.Exterior, ApplicationTypology.Interior]}
                    title={"Application Typology"}
                    label={"Application Typology"}
                    value={values.application_typology}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleChangesOnForm(e.target);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    field=""
                    name={"application_typology"}
                    error={!!touched.application_typology && !!errors.application_typology}
                    helperText={touched.application_typology && errors.application_typology}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <AutocompleteFilterObject
                    items={
                      app_type_data
                        ? [
                            ...app_type_data.applicationTypes.edges.map(
                              (el) => el.node.application_site
                            ),
                            "",
                          ]
                        : []
                    }
                    title={"Application Site"}
                    label={"Application Site"}
                    value={values.application_site}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleChangesOnForm(e.target);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    field="name"
                    name={"application_site"}
                    error={!!touched.application_site && !!errors.application_site}
                    helperText={touched.application_site && errors.application_site}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <AutocompleteFilterObject
                    items={
                      app_type_data
                        ? [
                            ...app_type_data.applicationTypes.edges.map(
                              (el) => el.node.description
                            ),
                            "",
                          ]
                        : []
                    }
                    title={"Description"}
                    label={"Description"}
                    value={values.description}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleChangesOnForm(e.target);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    field="description"
                    name={"description"}
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                {values.description && values.description.trim() !== "" && (
                  <Grid item sm={12} xs={12}>
                    <AutocompleteFilterObject
                      items={
                        app_type_data
                          ? [
                              ...app_type_data.applicationTypes.edges.map(
                                (el) => el.node.description
                              ),
                              "",
                            ]
                          : []
                      }
                      title={"Details"}
                      label={"Details"}
                      value={values.details}
                      onChange={(e: React.ChangeEvent<any>) => {
                        handleChangesOnForm(e.target);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      field="details"
                      name={"details"}
                      extraFields={["code"]}
                      error={!!touched.details && !!errors.details}
                      helperText={touched.details && errors.details}
                    />
                  </Grid>
                )}
                {selectedColors && GenerateColorChips()}
                <Grid item sm={12} xs={12}>
                  <AutocompleteFilterObject
                    items={colors_data ? [...colors_data.colors.edges.map((el) => el.node)] : []}
                    title={"Pick Colors"}
                    label={"Pick Colors"}
                    value={undefined}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleChangesOnForm(e.target);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    field="color"
                    name={"colors"}
                    error={!!touched.details && !!errors.details}
                    helperText={touched.details && errors.details}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: "25px", justifyContent: "space-around" }}>
                <Button variant="contained" color="primary" type="submit" sx={{ width: "120px" }}>
                  <Search style={{ marginRight: 5 }} />
                  Search
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ width: "120px" }}
                  onClick={() => {
                    resetForm();
                    setSelectedColors(undefined);
                  }}
                >
                  <Clear style={{ marginRight: 5 }} />
                  Clear
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </Card>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        sx={{ mt: "25px", width: "150px", mb: "25px" }}
        onClick={() => {
          history.goBack();
        }}
      >
        <ArrowBack style={{ marginRight: 5 }} />
        Go Back
      </Button>
    </Container>
  );
};

export interface IFormSmartStone {
  location: string | undefined;
  development_type: string | undefined;
  application_typology: string | undefined;
  application_site: string | undefined;
  description: string | undefined;
  details: string | undefined;
  colors: string[] | undefined | null;
}

const initialValues: IFormSmartStone = {
  location: undefined,
  development_type: undefined,
  application_typology: undefined,
  application_site: undefined,
  description: undefined,
  details: undefined,
  colors: undefined,
};

const checkoutSchema = yup.object().shape({
  location: yup.string().required("Required"),
  development_type: yup.string().required("Required"),
  application_typology: yup.string().required("Required"),
  application_site: yup.string().required("Required"),
  description: yup.string().required("Required"),
  details: yup.string().required("Required"),
  colors: yup.array(yup.string()).notRequired(),
});
