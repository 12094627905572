/* eslint-disable */
import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { setUserData } from "../../actions/accountActions";
import { loadingComponent, notLoadingComponent } from "../../actions/isLoadingActions";
import authServices from "../../services/auth.Services";

function Auth({ children }) {
  const dispatch = useDispatch();

  const initAuth = useCallback(async () => {
    dispatch(loadingComponent());
    if (authServices.isAuthenticated()) {
      dispatch(setUserData());
    }
    dispatch(notLoadingComponent());
  }, [dispatch]);

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  return children;
}

export default Auth;
