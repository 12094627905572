import { Box, IconButton, Typography } from "@material-ui/core";
import { CommercialVariety_commercialVariety_annexedDocuments_nodes } from "../../data/graphQLModel";
import { grey } from "../../theme/themeColors";
import FileIconSwitch from "../../utils/FileIconSwitch";
import { getFile } from "../../utils/utils";
import FlexBox from "../GeneralComponents/FlexBox";
import { CloudDownload, Visibility } from "@material-ui/icons";
import { H1 } from "../GeneralComponents/Typography";

interface ListOfAnnexedDocumentsProps {
  documents?: CommercialVariety_commercialVariety_annexedDocuments_nodes[];
  direction?: "row" | "column";
}

const ListOfAnnexedDocuments = ({
  documents = undefined,
  direction = "column",
}: ListOfAnnexedDocumentsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: direction,
      }}
    >
      {!!documents && documents.length > 0 ? (
        documents.map((file, ind) => {
          return (
            <Box
              height={"10rem"}
              width={"10rem"}
              padding={3}
              minWidth={"10rem"}
              bgcolor="white"
              borderRadius="10px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid"
              borderColor={grey[300]}
              style={{ cursor: "pointer" }}
              ml={ind === 0 ? "auto" : 0}
              mr={ind === documents.length - 1 ? "auto" : "10px"}
              key={ind + ""}
              onClick={() => {
                getFile(file.name, file.blob_url, file.mimetype, "view");
              }}
            >
              <FlexBox sx={{ flexDirection: "column", alignItems: "center" }}>
                {FileIconSwitch(file.name)}
                <Typography>{file.name}</Typography>
                <FlexBox
                  sx={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <IconButton>
                    <CloudDownload color="secondary" />
                  </IconButton>
                  <IconButton>
                    <Visibility color="primary" />
                  </IconButton>
                </FlexBox>
              </FlexBox>
            </Box>
          );
        })
      ) : (
        <H1 sx={{ textAlign: "center", width: "100%" }}>No Files Attached</H1>
      )}
    </Box>
  );
};

export default ListOfAnnexedDocuments;
