import PromiseBlueBird from "bluebird";
import FileSaver from "file-saver";
import JsZip from "jszip";
import { removeIllegalFilenameCharacters } from "./utils";

export interface IBlobsWithNames {
  name: string;
  blobs: Blob[];
}

export interface IURLAndNameInput {
  name: string;
  images: string[];
}

export interface ToBeZipped {
  blobsWithNames: IBlobsWithNames[];
  fileName: string;
  fileToAppend?: string | Blob | ArrayBuffer | number[] | Uint8Array;
  fileToAppendExtension?: string;
}

export const downloadAndZip = async (
  urlsAndName: IURLAndNameInput[],
  fileName: string,
  fileToAppend?: string | Blob | ArrayBuffer | number[] | Uint8Array,
  fileToAppendExtension?: string
): Promise<void> => {
  return await downloadByGroup(urlsAndName, 10, fileName, fileToAppend, fileToAppendExtension).then(
    exportZip
  );
};

export const exportZip = async (toBeZipped: ToBeZipped): Promise<void> => {
  const zip = JsZip();
  toBeZipped?.fileToAppend && zip.file(toBeZipped.fileName + ".xlsx", toBeZipped.fileToAppend);
  await Promise.all(
    toBeZipped.blobsWithNames.map(async (obj: IBlobsWithNames, obj_ind: number): Promise<void> => {
      await Promise.all(
        obj.blobs.map(async (blob: Blob, ind: number): Promise<void> => {
          zip.file(
            `${removeIllegalFilenameCharacters(`${obj.name}`)}_${ind + 1}.${blob.type
              .split("/")
              .pop()}`,
            blob
          );
        })
      );
    })
  );
  await zip.generateAsync({ type: "blob" }).then((zipFile) => {
    const finalFileName = `${removeIllegalFilenameCharacters(toBeZipped.fileName)}.zip`;
    FileSaver.saveAs(zipFile, finalFileName);
  });
};

export const download = async (url: string) => {
  return await fetch(url).then((resp) => resp.blob());
};

export const downloadByGroup = async (
  objectWithImages: IURLAndNameInput[],
  files_per_group = 10,
  fileName: string,
  fileToAppend?: string | Blob | ArrayBuffer | number[] | Uint8Array,
  fileToAppendExtension?: string
): Promise<ToBeZipped> => {
  const blobsWithNames = await Promise.all(
    objectWithImages.map(async (obj: IURLAndNameInput): Promise<IBlobsWithNames> => {
      const blobs = await PromiseBlueBird.map(
        obj.images as string[],
        async (url: string) => {
          return await download(url);
        },
        { concurrency: files_per_group }
      );
      return {
        blobs: blobs,
        name: obj.name,
      };
    })
  );

  return {
    blobsWithNames: blobsWithNames,
    fileToAppend: fileToAppend,
    fileName: fileName,
    fileToAppendExtension: fileToAppendExtension,
  };
};
