/* eslint-disable */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Box, Card, CardContent, Container } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import swalert from "sweetalert";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { DeleteOutline } from "@material-ui/icons";
import { loadingComponent, notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  DeleteOnePackageType,
  DeleteOnePackageTypeVariables,
  PackingType,
  PackingTypeVariables,
} from "../../../data/graphQLModel";
import {
  MUTATION_DELETE_ONE_PACKAGE_TYPE,
  QUERY_ONE_PACKAGE_TYPE,
} from "../../../data/graphQLQueries";
import { decodeBase64 } from "../../../utils/utils";
import { MuiThemeProps } from "../../../theme/theme";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  margin: {
    marginBottom: "2ch",
    marginTop: "2ch",
  },
  marginobs: {
    width: "70ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function DetailsProject() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setForm] = useState({ id: "", code: "", type: "", observation: "" });

  let { parptype } = useParams() as { parptype: string };

  const [mutationDeletePackageType] = useMutation<
    DeleteOnePackageType,
    DeleteOnePackageTypeVariables
  >(MUTATION_DELETE_ONE_PACKAGE_TYPE);

  useQuery<PackingType, PackingTypeVariables>(QUERY_ONE_PACKAGE_TYPE, {
    variables: {
      id: decodeBase64(parptype),
    },
    onCompleted(data) {
      if (!!data?.packingType) {
        setForm({
          id: data.packingType?.id,
          code: data.packingType?.code,
          observation: data.packingType?.observation || "",
          type: data.packingType?.type || "",
        });
      }
    },
  });

  async function submitForm(e: any) {
    e.preventDefault();

    if (parptype === "" || parptype.length < 2) {
      swalert("Information!", "Missing information!", "warning");
      return;
    }

    dispatch(loadingComponent());

    try {
      var res = await mutationDeletePackageType({
        variables: {
          input: {
            id: form.id,
          },
        },
      });
      if (res) swalert("Success.", "Package Type delete with success", "success");
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert(
        "Failed.",
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Erro",
        "error"
      );
      return;
    }
    dispatch(notLoadingComponent());
    history.push("/customerstables/packagetypes");
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/customerstables/packagetypes");
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <Box>
                <h2 style={{ marginBottom: "-10px" }}>Delete Package Type</h2>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm} style={{ width: "100%" }}>
              {form.code && (
                <>
                  <div>
                    <FormControl className={classes.marginCode} fullWidth variant="outlined">
                      <TextField
                        id="CodeProject"
                        label="Code"
                        name="code"
                        value={form.code}
                        placeholder="Code"
                        disabled
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth variant="outlined" className={classes.marginCode}>
                      <TextField
                        id="typepack"
                        label="Package Type"
                        name="type"
                        disabled
                        value={form.type}
                        placeholder="Package Type"
                        required
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth variant="outlined" className={classes.marginobs}>
                      <TextField
                        id="obsPackage"
                        label="Observation"
                        name="observation"
                        placeholder="Observation"
                        disabled
                        value={form.observation}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={9}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                </>
              )}
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      onClick={handleCancel}
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      onClick={submitForm}
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                      color="secondary"
                    >
                      Delete
                      <DeleteOutline style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
