import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  TextField,
  Box,
  FormControlLabel,
  Switch,
  Button,
} from "@material-ui/core";
import { Close, CloudDownload, RemoveCircle } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProps } from "../../theme/theme";
import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import downloadImage from "../../utils/downloadImage";
import { Slab_slab } from "../../data/graphQLModel";

const useStylesDialog = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    margin: "auto",
    overflow: "hidden",
    transition: "all 250ms ease-in-out",
    borderRadius: "8px",

    "&:hover": {
      "& $imageHolder": {
        "& .extra-icons": {
          display: "flex",
        },
      },
    },

    "@media only screen and (max-width: 768px)": {
      "& $imageHolder": {
        "& .extra-icons": {
          display: "flex",
        },
      },
    },
  },
  imageHolder: {
    position: "relative",
    display: "inlin-block",
    textAlign: "center",

    "& .extra-icons": {
      display: "none",
      flexDirection: "column",
      position: "absolute",
      top: "7px",
      right: "15px",
      cursor: "pointer",
      zIndex: 2,
    },

    [theme.breakpoints.down(1)]: {
      display: "block",
    },
  },
  offerChip: {
    position: "absolute",
    fontSize: "10px",
    fontWeight: 600,
    paddingLeft: 3,
    paddingRight: 3,
    top: "10px",
    left: "10px",
  },
  details: {
    padding: "1rem",

    "& .title, & .categories": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    "& .icon-holder": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },

    "& .favorite-icon": {
      cursor: "pointer",
    },
  },
  dialogContent: {
    paddingBottom: "1.25rem",
  },
}));

export interface DownloadImageDialogProps {
  open: boolean;
  url: string;
  toggle: () => void;
  slab: Slab_slab;
  metadata?: object;
}

export default function DownloadImageDialog({
  open,
  url,
  toggle,
  metadata,
  slab,
}: DownloadImageDialogProps) {
  const classesDialog = useStylesDialog();

  const handleFormSubmit = async (values: IFormImageDownload) => {
    // console.log(values)
    downloadImage(
      url,
      values.extension,
      values.name,
      values.quality,
      metadata,
      values.download_metadata
    );
  };

  const formik_ref = useFormik({
    initialValues: initialValues,
    validationSchema: checkoutSchema,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    if (url) {
      formik_ref.setFieldValue("extension", url.split(".")[url.split(".").length - 1]);
      let category = slab?.category
        ? !!slab?.category.companyNames && slab?.category.companyNames.length > 0
          ? slab.category.companyNames[0].code
          : slab.category.code
        : ``;
      let model = !!slab?.stone_model?.code ? slab?.stone_model?.code : "";
      let typeMaterial = slab?.typematerial
        ? !!slab?.typematerial.companyNames && slab?.typematerial.companyNames.length > 0
          ? slab.typematerial.companyNames[0].code
          : slab.typematerial.code
        : ``;
      let variety = !!slab?.commercial_variety?.code
        ? slab?.commercial_variety?.code
        : !!slab?.variety?.normativedesig
        ? slab?.variety?.normativedesig
        : "";
      let finishig = !!slab?.commercial_finishing?.code
        ? slab?.commercial_finishing?.code
        : !!slab?.finish?.code
        ? slab?.finish?.code
        : "";
      let edge = !!slab.stone_edge?.code ? slab.stone_edge?.code : "";
      let shouldAddUnderScore =
        !!category || !!model || !!typeMaterial || !!variety || !!finishig || !!edge;
      formik_ref.setFieldValue(
        "name",
        `${category}${model}${typeMaterial}${variety}${finishig}${edge}${
          shouldAddUnderScore ? "_" : ""
        }${slab.code}`
      );
    }
  }, [url]);

  return (
    <Dialog open={open} fullWidth maxWidth={"md"} onClose={() => toggle()}>
      <DialogContent className={classesDialog.dialogContent}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Download Options
        </Typography>
        <IconButton style={{ position: "absolute", top: "0", right: "0" }} onClick={() => toggle()}>
          <Close className="close" fontSize="medium" color="primary" />
        </IconButton>
        <Box sx={{ mt: 2 }}>
          <form onSubmit={formik_ref.handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box
                sx={{
                  alignItems: "center",
                  width: "400px",
                  height: "auto",
                }}
              >
                <img
                  src={url}
                  style={{
                    alignSelf: "center",
                    width: "400px",
                    height: "auto",
                    maxHeight: "600px",
                  }}
                />
                <TextField
                  sx={{ mt: 2 }}
                  name="name"
                  label="Photo name"
                  fullWidth
                  onBlur={formik_ref.handleBlur}
                  onChange={formik_ref.handleChange}
                  value={formik_ref.values.name || ""}
                  error={!!formik_ref.touched.name && !!formik_ref.errors.name}
                  helperText={formik_ref.touched.name && formik_ref.errors.name}
                />
              </Box>
              <Box sx={{ ml: 1 }}>
                <FormControlLabel
                  style={{ alignSelf: "center" }}
                  control={
                    <Switch
                      checked={formik_ref.values.download_metadata}
                      onChange={formik_ref.handleChange}
                      color="secondary"
                      name="download_metadata"
                      inputProps={{
                        "aria-label": "primary checkbox",
                      }}
                    />
                  }
                  // label="View Products in Stock Only"
                  labelPlacement="start"
                  label={
                    <Typography variant="h5" sx={{ mb: 1 }}>
                      Metadata
                    </Typography>
                  }
                />
                {formik_ref.values.download_metadata &&
                  metadata &&
                  Object.entries(metadata).map(([key, value]) => (
                    <Box
                      key={key}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          mr: 1,
                        }}
                      >
                        {key}:
                      </Typography>
                      <Typography>{value}</Typography>
                    </Box>
                  ))}
                {/* {formik_ref.values.download_metadata && <Box sx={{ width: '100%', mb: 2 }}>
                  <Typography variant='h5' sx={{ mb: 2 }} >Settings</Typography>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <AutocompleteFilterJustText
                        items={['jpeg', 'tiff', 'webp', '']}
                        title={'File extension'}
                        onBlur={formik_ref.handleBlur}
                        onChange={formik_ref.handleChange}
                        value={formik_ref.values.extension || ''}
                        name={'extension'}
                        error={!!formik_ref.touched.extension && !!formik_ref.errors.extension}
                        helperText={formik_ref.touched.extension && formik_ref.errors.extension}
                        label='File extension'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AutocompleteFilterJustText
                        items={['Original', 'Medium', 'Low', '']}
                        title={'Image quality'}
                        onBlur={formik_ref.handleBlur}
                        onChange={formik_ref.handleChange}
                        value={formik_ref.values.quality || ''}
                        name={'quality'}
                        error={!!formik_ref.touched.quality && !!formik_ref.errors.quality}
                        helperText={formik_ref.touched.quality && formik_ref.errors.quality}
                        label='Image quality'
                      />
                    </Grid>
                  </Grid>
                </Box>} */}
              </Box>
            </Box>
            <Button variant="contained" color="info" type="submit">
              <CloudDownload fontSize="large" color="secondary" />
              Download
            </Button>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export interface IFormImageDownload {
  name: string;
  extension: string;
  download_metadata: boolean;
  quality: "Original" | "Medium" | "Low";
}

const initialValues: IFormImageDownload = {
  name: "",
  extension: "",
  download_metadata: false,
  quality: "Original",
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Required"),
  extension: yup.string().nullable().required("Required"),
  download_metadata: yup.bool(),
  quality: yup.string().nullable().required("Required"),
});
