import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
// import layoutReducer from "./ducks/layout";
// import authReducer from "./ducks/auth";
import reducer from "../reducers";
 
export default configureStore({ 
    reducer: reducer
//     reducer: {
//         accountReducer,
//  //       layout: layoutReducer,
//  //       auth: authReducer,
//     }
}, applyMiddleware(thunk));