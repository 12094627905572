import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@material-ui/core";
import AccountProfile from "./AccountProfile";
import AccountProfileDetails from "./AccountProfileDetails";
import AccountCompanyDetails from "./AccountCompanyDetails";

export default function Account() {
  const account = useSelector((state: any) => state.account.user);

  return (
    <>
      <Helmet>
        <title>Account | We Scan Home </title>
      </Helmet>
      {account && (
        <Container maxWidth="lg" sx={{ mt: 10 }}>
          <Grid container spacing={3}>
            <Grid item container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                <AccountProfile user={account} />
              </Grid>
              <Grid item lg={8} md={6} xs={12}>
                <AccountProfileDetails user={account} />
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item lg={12} md={6} xs={12}>
                <AccountCompanyDetails user={account} />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
