import { Dialog, DialogContent, Typography, IconButton, Grid, TextField, Box, Chip, Button } from "@material-ui/core"
import { Add, AddCircle, Close, RemoveCircle } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { MuiThemeProps } from "../../theme/theme"
import * as yup from 'yup'
import { useFormik } from 'formik';
import { useEffect } from "react"
import { SetBlobMetadata, SetBlobMetadataVariables } from "../../data/graphQLModel"
import { SET_BLOB_METADATA } from "../../data/graphQLQueries"
import { useMutation } from "@apollo/react-hooks"

const useStylesDialog = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    margin: 'auto',
    overflow: 'hidden',
    transition: 'all 250ms ease-in-out',
    borderRadius: '8px',

    '&:hover': {
      '& $imageHolder': {
        '& .extra-icons': {
          display: 'flex',
        },
      },
    },

    '@media only screen and (max-width: 768px)': {
      '& $imageHolder': {
        '& .extra-icons': {
          display: 'flex',
        },
      },
    },
  },
  imageHolder: {
    position: 'relative',
    display: 'inlin-block',
    textAlign: 'center',

    '& .extra-icons': {
      display: 'none',
      flexDirection: 'column',
      position: 'absolute',
      top: '7px',
      right: '15px',
      cursor: 'pointer',
      zIndex: 2,
    },

    [theme.breakpoints.down(1)]: {
      display: 'block',
    },
  },
  offerChip: {
    position: 'absolute',
    fontSize: '10px',
    fontWeight: 600,
    paddingLeft: 3,
    paddingRight: 3,
    top: '10px',
    left: '10px',
  },
  details: {
    padding: '1rem',

    '& .title, & .categories': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '& .icon-holder': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },

    '& .favorite-icon': {
      cursor: 'pointer',
    },
  },
  dialogContent: {
    paddingBottom: '1.25rem',
  },
}))

export interface IFormImageAddMetadata {
  metadata_input_key: string,
  metadata: { [key: string]: string },
  metadata_input_value: string,
}

const initialValues: IFormImageAddMetadata = {
  metadata: {},
  metadata_input_key: '',
  metadata_input_value: '',
}

const checkoutSchema = yup.object().shape({
  metadata_input_key: yup.string(),
  metadata_input_value: yup.string(),
})

export interface AddMetadataDialogProps {
  open: boolean;
  url: string;
  toggle: () => void;
  refetch?: Function;
}

export default function AddMetadataDialog({ open, url, toggle, refetch }: AddMetadataDialogProps) {
  const classesDialog = useStylesDialog();

  const [mutationSetBlobMetadata] = useMutation<SetBlobMetadata, SetBlobMetadataVariables>(SET_BLOB_METADATA)


  const handleFormSubmit = async (values: IFormImageAddMetadata) => {
    // console.log(values)
  }

  const formik_ref = useFormik({
    initialValues: initialValues,
    validationSchema: checkoutSchema,
    onSubmit: handleFormSubmit
  })

  useEffect(() => {
    if (url) {
      formik_ref.setFieldValue('extension', url.split('.')[url.split('.').length - 1])
    }
  }, [url])

  const setBlobMetadata = async () => {
    await mutationSetBlobMetadata({
      variables: {
        metadata: JSON.stringify(formik_ref.values.metadata),
        url: url
      }
    }).then((res) => {
      // console.log(res)
    }).catch((err) => {
      console.log(err)
    })
    toggle()
  }

  // Metadata
  const addMetadata = () => {
    if (formik_ref.values.metadata_input_key.trim() !== '' && formik_ref.values.metadata_input_value.trim() !== '') {
      let newMetadata = { ...formik_ref.values.metadata }
      newMetadata[formik_ref.values.metadata_input_key] = formik_ref.values.metadata_input_value
      formik_ref.setFieldValue('metadata', newMetadata)
      formik_ref.setFieldValue('metadata_input_key', '')
      formik_ref.setFieldValue('metadata_input_value', '')
    }
  }

  const GenerateMetadataChips = (): JSX.Element => {
    return (
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }}>
        {Object.entries(formik_ref.values.metadata).map(([key, value]) => (
          <Grid item xs={3} sm={3} md={3} key={key}>
            <Chip
              label={`${key}: ${value}`}
              onDelete={() => {
                const newMetadata = formik_ref.values.metadata
                delete newMetadata[key]
                formik_ref.setFieldValue('metadata', newMetadata)
              }}
              deleteIcon={<RemoveCircle />}
            />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <Dialog open={open} fullWidth maxWidth={'md'} onClose={() => toggle()} >
      <DialogContent className={classesDialog.dialogContent}>
        <Typography variant='h4' sx={{ mb: 1 }}>Set Metadata</Typography>
        <IconButton
          style={{ position: 'absolute', top: '0', right: '0' }}
          onClick={() => toggle()}
        >
          <Close className="close" fontSize="medium" color="primary" />
        </IconButton>
        <Box sx={{ mt: 2 }}>
          <form onSubmit={formik_ref.handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{
                alignItems: 'center',
                width: '400px', height: 'auto'
              }}>
                <img src={url}
                  style={{
                    alignSelf: 'center',
                    width: '400px',
                    height: 'auto',
                    maxHeight: '600px'
                  }} />
              </Box>
              <Grid xs={12} md={12} sm={12} sx={{ ml: 1 }}>
                {<Box>
                  <Typography variant='subtitle2' sx={{ mb: 1 }}>Extra Metadata (Optional)</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                    <TextField
                      name="metadata_input_key"
                      label="Metadata Key"
                      fullWidth
                      onBlur={formik_ref.handleBlur}
                      onChange={formik_ref.handleChange}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          addMetadata()
                        }
                      }}
                      value={formik_ref.values.metadata_input_key || ''}
                      error={!!formik_ref.touched.metadata_input_key && !!formik_ref.errors.metadata_input_key}
                      helperText={formik_ref.touched.metadata_input_key && formik_ref.errors.metadata_input_key}
                    />
                    <TextField
                      name="metadata_input_value"
                      label="Value"
                      fullWidth
                      onBlur={formik_ref.handleBlur}
                      onChange={formik_ref.handleChange}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          addMetadata()
                        }
                      }}
                      value={formik_ref.values.metadata_input_value || ''}
                      error={!!formik_ref.touched.metadata_input_value && !!formik_ref.errors.metadata_input_value}
                      helperText={formik_ref.touched.metadata_input_value && formik_ref.errors.metadata_input_value}
                    />
                    <IconButton
                      onClick={() => {
                        addMetadata()
                      }}
                    >
                      <Add fontSize="medium" color="primary" />
                    </IconButton>
                  </Box>
                  {formik_ref.values.metadata && Object.keys(formik_ref.values.metadata).length > 0 && GenerateMetadataChips()}
                </Box>}
              </Grid>
            </Box>
            <Button
              variant="contained"
              color="info"
              type="submit"
              onClick={() => {
                setBlobMetadata()
              }}
            >
              <AddCircle fontSize="large" color="secondary" />
              Add Metadata
            </Button>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}