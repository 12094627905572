/* eslint-disable */
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import swalert from "sweetalert";
import { Box, Card, CardContent, Container, IconButton } from "@material-ui/core";
import { MUTATION_DELETE_ONE_JOB, QUERY_ONE_JOB } from "../../../data/graphQLQueries";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  DeleteOneJob,
  DeleteOneJobVariables,
  Job,
  JobInput,
  JobNumberingOrder,
  JobVariables,
} from "../../../data/graphQLModel";
import * as yup from "yup";
import { copyObject } from "../../../utils/utils";
import { isNumeric } from "../../utils/utils";
import JobForm from "./forms/JobForm";
import toast from "react-hot-toast";
import { H2 } from "../../../components/GeneralComponents/Typography";
import FlexBox from "../../../components/GeneralComponents/FlexBox";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const LOADING_TOAST = "LOADING_TOAST";

export default function DeleteJob() {
  const [values, setValues] = useState<JobInput & { endNumber?: number | null | undefined }>();
  const history = useHistory();

  const [mutationDeleteOneJob] = useMutation<DeleteOneJob, DeleteOneJobVariables>(
    MUTATION_DELETE_ONE_JOB
  );

  const { jb } = useParams<{ jb: string }>();

  const { loading, error, data } = useQuery<Job, JobVariables>(QUERY_ONE_JOB, {
    variables: {
      id: jb,
    },
    onCompleted: (data) => {
      let newData = data.job;
      if (newData) {
        setValues({
          code: newData.code,
          description: newData.description,
          jobtype: newData?.jobtype?.id ? newData?.jobtype?.id : "",
          scan: newData?.scan?.id ? newData?.scan?.id : "",
          blocknumber: newData.blocknumber,
          category: newData.category?.id,
          commercial_finishing: newData.commercial_finishing?.id,
          commercial_variety: newData.commercial_variety?.id,
          descstate: newData.descstate,
          finish: newData.finish?.id,
          height: newData.height,
          isblock: newData.isblock,
          packing: newData.packing?.id,
          project: newData.project?.id,
          stone_edge: newData.stone_edge?.id,
          stone_model: newData.stone_model?.id,
          thickness: newData.thickness,
          totalslab: newData.totalslab,
          typematerial: newData.typematerial?.id,
          variety: newData.variety?.id,
          width: newData.width,
          block: newData?.block?.id,
          numberingOrder: newData.numberingOrder,
          prefix: newData.prefix,
          separator: newData.separator,
          startNumber: newData.startNumber,
          endNumber:
            newData.numberingOrder === JobNumberingOrder.DESC
              ? (newData.startNumber || 1) - (newData.totalslab - 1)
              : (newData.startNumber || 1) + (newData.totalslab - 1),
        });
      }
    },
  });

  async function submitForm(form: JobInput) {
    try {
      await mutationDeleteOneJob({
        variables: {
          input: {
            id: jb,
          },
        },
      });
      swalert("Job", "Job was deleted", "success");
      history.push("/slabsprocess/jobs");
    } catch (error: any) {
      console.log(error);
      swalert("Job", `Error deleting Job: ${error?.toString()}`, "warning");
    }
  }

  async function confirmSubmission(form: JobInput) {
    swalert({
      title: "DELETE",
      text: "Are you sure you want to delete this Job?",
      icon: "warning",
      //@ts-ignore
      buttons: true,
      dangerMode: true,
    }).then((willDelete: boolean) => {
      if (willDelete) {
        submitForm(form);
      }
    });
  }

  const handleCancel = () => {
    history.goBack();
  };

  if (!!error) {
    swalert("Job", `Error getting Job: ${error?.message}`, "warning");
    history.goBack();
  }

  if (loading) {
    toast.loading("Loading...", { id: LOADING_TOAST });
    return <></>;
  } else {
    toast.dismiss(LOADING_TOAST);
  }

  return (
    <Container sx={{ pt: "1rem" }}>
      <Box mb={1}>
        <Card sx={{ p: 1 }}>
          <FlexBox sx={{ alignItems: "center" }}>
            <IconButton
              type="submit"
              disabled={history?.length <= 1}
              onClick={() => history?.goBack()}
              color="primary"
              sx={{ mr: 1 }}
            >
              <ArrowBackIosIcon fontSize="large" />
            </IconButton>{" "}
            <H2>Delete Job</H2>
          </FlexBox>
        </Card>
      </Box>
      {data && data?.job && !loading && !!values && (
        <Card sx={{ p: "2rem" }}>
          <JobForm
            handleCancel={handleCancel}
            initialValues={values}
            validationSchema={(data) => checkoutSchema}
            submitForm={confirmSubmission}
            blockUpdate={true}
            isUpdate={true}
            data={data.job}
            isDelete={true}
          />
        </Card>
      )}
    </Container>
  );
}

const checkoutSchema = yup.object().shape({});
