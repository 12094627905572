import { Helmet } from "react-helmet";
import { Box, Button, Card, CardContent, Container, Grid } from "@material-ui/core";
import AccountProfile from "./CompanyProfile";
import { useHistory } from "react-router-dom";

export default function Account() {
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>Administrator | We Scan Home </title>
      </Helmet>
      <div className="container" style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Box>
            <Card>
              <CardContent>
                <Box
                  style={{
                    maxWidth: 500,
                    marginBottom: "-20px",
                  }}
                >
                  <h2>Administration Panel</h2>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box
          style={{
            backgroundColor: "background.default",
            minHeight: "100%",
            marginTop: "40px",
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                <AccountProfile name="Companies" valor="5" />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <AccountProfile name="Scans" valor="12" />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <AccountProfile name="Users" valor="30" />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    mt: "25px",
                    width: "150px",
                    mb: "25px",
                  }}
                  onClick={() => {
                    history.push("./admin/createuser");
                  }}
                >
                  Add User
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} xs={12}>
                <AccountProfile name="Clients" valor="100" />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <AccountProfile name="Subscriptions" valor="10" />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <AccountProfile name="XXXX" valor="8" />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    </>
  );
}
