import { useEffect, useState } from "react";
import { BlockModel_blockModel, ImageFace } from "../../data/graphQLModel";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import { Box } from "@material-ui/system";
import {
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  styled,
  Switch,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import FlexBox from "../GeneralComponents/FlexBox";
import BazarCard from "../GeneralComponents/BazarCard";
import DefaultImage from "../GeneralComponents/DefaultImage";
import { BsEye } from "react-icons/bs";
import StyledSwitch from "../GeneralComponents/StyledSwitch";

export interface ViewBlockModelComponentProps {
  isOpen: boolean;
  toggle: () => void;
  block: BlockModel_blockModel;
}

const ViewBlockModelComponent = ({ isOpen, block, toggle }: ViewBlockModelComponentProps) => {
  // show final
  const [show, setShow] = useState<boolean>(false);

  const [shouldAnimate, setShouldAnimate] = useState(false);

  const [showMenu, setShowMenu] = useState(true);

  const [measurements, setMeasurements] = useState<{
    width: number;
    height: number;
    depth: number;
  }>({ depth: 10, height: 10, width: 10 });

  // const SceneOptions = () => (
  //   <FlexBox
  //     sx={{
  //       display: show ? "block" : "none",
  //       position: "fixed",
  //       right: "5rem",
  //       top: "12rem",
  //       zIndex: 100000000,
  //       height: "100%",
  //       width: "20%",
  //     }}
  //   >
  //     <FlexBox flexDirection={"column"}>
  //       <BazarCard sx={{ backgroundColor: "white", flexDirection: "row", width: "100%", p: 3 }}>
  //         <TextField
  //           id="width"
  //           label="Width"
  //           name="width"
  //           placeholder="Search Block"
  //           value={measurements.width || ""}
  //           type="number"
  //           onChange={(e) => {
  //             setMeasurements({ ...measurements, width: Number(e.target.value) });
  //           }}
  //           onKeyPress={(e) => {
  //             if (e.key === "Enter") {
  //               bootstrap();
  //             }
  //           }}
  //         />
  //         <TextField
  //           id="height"
  //           label="Height"
  //           name="height"
  //           placeholder="Search Block"
  //           value={measurements.height || ""}
  //           type="number"
  //           onChange={(e) => {
  //             setMeasurements({ ...measurements, height: Number(e.target.value) });
  //           }}
  //           onKeyPress={(e) => {
  //             if (e.key === "Enter") {
  //               bootstrap();
  //             }
  //           }}
  //         />
  //         <TextField
  //           id="depth"
  //           label="Depth"
  //           name="depth"
  //           placeholder="Search Block"
  //           value={measurements.depth || ""}
  //           type="number"
  //           onChange={(e) => {
  //             setMeasurements({ ...measurements, depth: Number(e.target.value) });
  //           }}
  //           onKeyPress={(e) => {
  //             if (e.key === "Enter") {
  //               bootstrap();
  //             }
  //           }}
  //         />

  //         <Button variant="contained" color="primary" onClick={() => bootstrap()}>
  //           Render
  //         </Button>
  //         <DefaultImage
  //           src="/images/block_measurement_desc.jfif"
  //           sx={{
  //             display: "block",
  //             width: "90%",
  //             mt: "1.5rem",
  //           }}
  //         />
  //         <Button
  //           variant="contained"
  //           color="primary"
  //           onClick={() => setShouldAnimate(!shouldAnimate)}
  //         >
  //           Animate
  //         </Button>
  //       </BazarCard>
  //     </FlexBox>
  //   </FlexBox>
  // );

  const SceneButton = () => {
    return (
      <FlexBox
        sx={{
          display: show ? "block" : "none",
          position: "fixed",
          right: 0,
          top: "5rem",
          zIndex: 100000000,
          height: "100%",
          width: "10%",
        }}
      >
        <FlexBox flexDirection={"column"}>
          <IconButton
            onClick={() => {
              setShow(false);
              toggle();
            }}
            color="info"
          >
            <Close sx={{ width: "3rem", height: "3rem" }} />
          </IconButton>
        </FlexBox>
      </FlexBox>
    );
  };

  const bootstrap = () => {
    if (isOpen && !!block && block.materialFileUrl && block.modelFileURL) {
      let top = block?.regularImages?.find((el) => el.imageFace === ImageFace.TOP)
        ?.original as string;
      let back = block?.regularImages?.find((el) => el.imageFace === ImageFace.BACK)
        ?.original as string;
      let front = block?.regularImages?.find((el) => el.imageFace === ImageFace.FRONT)
        ?.original as string;
      let right = block?.regularImages?.find((el) => el.imageFace === ImageFace.RIGHT)
        ?.original as string;
      let left = block?.regularImages?.find((el) => el.imageFace === ImageFace.LEFT)
        ?.original as string;
      loadThreeJS(top, back, front, right, left, block.materialFileUrl, block.modelFileURL);
    } else if (isOpen) {
      let top = block?.regularImages?.filter((el) => el.imageFace === ImageFace.TOP).pop()
        ?.original as string;

      let back = block?.regularImages?.filter((el) => el.imageFace === ImageFace.BACK).pop()
        ?.original as string;

      let front = block?.regularImages?.filter((el) => el.imageFace === ImageFace.FRONT).pop()
        ?.original as string;

      let right = block?.regularImages?.filter((el) => el.imageFace === ImageFace.RIGHT).pop()
        ?.original as string;

      let left = block?.regularImages?.filter((el) => el.imageFace === ImageFace.LEFT).pop()
        ?.original as string;

      loadThreeJS(top, back, front, right, left);
    }
  };

  useEffect(() => {
    bootstrap();
  }, [isOpen, shouldAnimate]);

  const loadThreeJS = async (
    topUrl: string,
    backUrl: string,
    frontUrl: string,
    rightUrl: string,
    leftUrl: string,
    mtlUrl?: string,
    objUrl?: string
  ) => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xe0e0e0);

    const camera = new THREE.PerspectiveCamera(50, window.outerWidth / window.outerHeight, 1, 500);

    camera.position.z = 96;

    const canvas = document.getElementById("myThreeJsCanvas") as HTMLElement;
    const root = document.getElementById("root") as HTMLElement;

    const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
    renderer.setSize(window.outerWidth, window.outerHeight);
    // document.body.appendChild(renderer.domElement);
    root.appendChild(renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0x404040, 10);
    ambientLight.castShadow = true;
    scene.add(ambientLight);

    const spotLight = new THREE.SpotLight(0xffffff, 10);
    spotLight.castShadow = true;
    spotLight.position.set(0, 64, 32);
    scene.add(spotLight);

    let obj: THREE.Group | THREE.Mesh;

    if (!!mtlUrl && !!objUrl) {
      const mtlLoader = new MTLLoader();
      const materials = await mtlLoader.loadAsync(mtlUrl);
      if (materials?.materialsInfo?.Back?.map_kd) {
        materials.materialsInfo.Back.map_kd = backUrl;
      }
      if (materials?.materialsInfo?.Front?.map_kd) {
        materials.materialsInfo.Front.map_kd = frontUrl;
      }
      if (materials?.materialsInfo?.Left?.map_kd) {
        materials.materialsInfo.Left.map_kd = leftUrl;
      }
      if (materials?.materialsInfo?.Right?.map_kd) {
        materials.materialsInfo.Right.map_kd = rightUrl;
      }
      if (materials?.materialsInfo?.Top?.map_kd) {
        materials.materialsInfo.Top.map_kd = topUrl;
      }
      materials.preload();

      const objLoader = new OBJLoader();
      objLoader.setMaterials(materials);
      obj = await objLoader.loadAsync(objUrl);
      scene.add(obj);
    } else {
      let geometry = new THREE.BoxGeometry(
        measurements.width,
        measurements.height,
        measurements.depth
      );
      const loader = new THREE.TextureLoader();
      loader.crossOrigin = "Anonymous";

      let front = new THREE.MeshBasicMaterial({
        map: loader.load(frontUrl),
      });
      let back = new THREE.MeshBasicMaterial({
        map: loader.load(backUrl),
      });
      let top = new THREE.MeshBasicMaterial({
        map: loader.load(topUrl),
      });
      let left = new THREE.MeshBasicMaterial({
        map: loader.load(leftUrl),
      });
      let right = new THREE.MeshBasicMaterial({
        map: loader.load(rightUrl),
      });

      const cubeMaterials1 = [right, left, top, top, front, back];

      obj = new THREE.Mesh(geometry, cubeMaterials1);
      scene.add(obj);
    }

    // orbit controls
    const controls = new OrbitControls(camera, renderer.domElement);

    const animate = () => {
      controls.update();
      renderer.render(scene, camera);
      if (shouldAnimate) {
        obj.rotation.x += 0.001;
        obj.rotation.y += 0.001;
      }
      window.requestAnimationFrame(animate);
    };
    animate();
    fitCameraToObject(camera, obj, controls, 1.2, () => setShow(true));
  };

  const fitCameraToObject = function (
    camera: THREE.PerspectiveCamera,
    object: THREE.Group | THREE.Mesh,
    orbitControls: OrbitControls,
    offset: number,
    setShow: () => void
  ) {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(object);

    var size = new THREE.Vector3();
    boundingBox.getSize(size);

    // figure out how to fit the box in the view:
    // 1. figure out horizontal FOV (on non-1.0 aspects)
    // 2. figure out distance from the object in X and Y planes
    // 3. select the max distance (to fit both sides in)
    //
    // The reason is as follows:
    //
    // Imagine a bounding box (BB) is centered at (0,0,0).
    // Camera has vertical FOV (camera.fov) and horizontal FOV
    // (camera.fov scaled by aspect, see fovh below)
    //
    // Therefore if you want to put the entire object into the field of view,
    // you have to compute the distance as: z/2 (half of Z size of the BB
    // protruding towards us) plus for both X and Y size of BB you have to
    // figure out the distance created by the appropriate FOV.
    //
    // The FOV is always a triangle:
    //
    //  (size/2)
    // +--------+
    // |       /
    // |      /
    // |     /
    // | F° /
    // |   /
    // |  /
    // | /
    // |/
    //
    // F° is half of respective FOV, so to compute the distance (the length
    // of the straight line) one has to: `size/2 / Math.tan(F)`.
    //
    // FTR, from https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
    // the camera.fov is the vertical FOV.

    const fov = camera.fov * (Math.PI / 180);
    const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
    let dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2));
    let dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));
    let cameraZ = Math.max(dx, dy);

    // offset the camera, if desired (to avoid filling the whole canvas)
    if (offset !== undefined && offset !== 0) cameraZ *= offset;

    camera.position.set(0, 0, cameraZ);

    // set the far plane of the camera so that it easily encompasses the whole object
    const minZ = boundingBox.min.z;
    const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

    camera.far = cameraToFarEdge * 3;
    camera.updateProjectionMatrix();

    if (orbitControls !== undefined) {
      // set camera to rotate around the center
      orbitControls.target = new THREE.Vector3(0, 0, 0);

      // prevent camera from zooming out far enough to create far plane cutoff
      orbitControls.maxDistance = cameraToFarEdge * 2;
    }
    setShow();
  };

  return (
    <Box>
      <canvas
        id="myThreeJsCanvas"
        style={{ position: "fixed", left: 0, top: 0, display: show ? "block" : "none" }}
      />
      {/* {showMenu && <SceneOptions />} */}
      {show && <SceneButton />}
      {show && (
        <Box
          sx={{
            position: "absolute",
            right: 10,
            bottom: 50,
            zIndex: 1000000000,
            display: show ? "block" : "none",
          }}
        >
          <FormControlLabel
            control={
              <StyledSwitch size="medium" onClick={() => setShouldAnimate(!shouldAnimate)} />
            }
            label="Animation"
            labelPlacement="end"
          />
        </Box>
      )}
      {/* <Button
        sx={{
          position: "absolute",
          right: 10,
          bottom: 50,
          zIndex: 100,
          display: show ? "block" : "none",
        }}
        variant="contained"
        color="primary"
        onClick={() => setShowMenu(!showMenu)}
      >
        <BsEye />
      </Button> */}
    </Box>
  );
};

export default ViewBlockModelComponent;
