import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { Company, CompanyVariables, User_user } from "../../data/graphQLModel";
import { QUERY_ONE_COMPANY } from "../../data/graphQLQueries";
import LoadingFitCenter from "../../components/GeneralComponents/LoadingFitCenter";
import { UserToken } from "../../utils/Types";

const AccountProfileDetails = (props: { user: UserToken }) => {
  const { data, loading, error } = useQuery<Company, CompanyVariables>(QUERY_ONE_COMPANY, {
    variables: {
      id: props.user.company,
    },
  });

  return (
    <>
      {!!data && data?.company ? (
        <Card>
          <CardHeader subheader="The information about Company" title="Details - Company" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <Box
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "-10px",
                  }}
                >
                  <img
                    src={data.company?.image || ""}
                    alt={data.company?.code}
                    style={{ height: 160, width: "auto", borderRadius: "20px" }}
                  />
                </Box>
              </Grid>
              <Grid item md={8} xs={12}>
                {/* <Grid item md={6} xs={12} > */}
                <TextField
                  fullWidth
                  label="Company"
                  defaultValue={data.company?.code + " " + data.company?.name}
                  disabled
                  style={{ marginTop: "-10px" }}
                />
                <TextField
                  fullWidth
                  label="Description"
                  defaultValue={data.company?.description}
                  disabled
                  style={{ marginTop: "10px" }}
                />
                {/* </Grid> */}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  defaultValue={data.company?.email}
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  defaultValue={data.company?.contact}
                  disabled
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  defaultValue={data.company?.address}
                  disabled
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : null}
      {loading && <LoadingFitCenter />}
      {!!error && <Alert severity="error">{!!error ? error.message : "Erro"}</Alert>}
    </>
  );
};

export default AccountProfileDetails;
