import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import Home from "../Home";
import { useSelector } from "react-redux";


function  GuestRoute({ component: Component, ...rest}) {
    const account = useSelector(state => state.account);
    const isAuthenticated = Boolean(account.user);
    const history = useHistory();
    
    useEffect(() => {
        const validate = async () =>{
            if (isAuthenticated) {
                history.push('/');
            }
        }
        validate();
        //eslint-disable-next-line
    }, []);
    


    return(
        <Route {...rest} render={props => (
            isAuthenticated ?  <Home {...props}/> : (<Component {...props}/>)
		)}/>
    )
}


export default GuestRoute;
