import { useMutation } from "@apollo/react-hooks";
import { useLocation } from "react-router-dom";
import DeleteOneEntityForm from "../../../components/Form/DeleteEntityForm";
import LoadingFitCenter from "../../../components/GeneralComponents/LoadingFitCenter";
import {
  DeleteOneStoneModel,
  DeleteOneStoneModelVariables,
  StoneModels_stoneModels_edges_node,
} from "../../../data/graphQLModel";
import { MUTATION_DELETE_ONE_STONE_MODEL } from "../../../data/graphQLQueries";

export default function DeleteStoneModel() {
  const location = useLocation<
    StoneModels_stoneModels_edges_node[] | StoneModels_stoneModels_edges_node
  >();

  const [mutationDeleteOneStoneModel] = useMutation<
    DeleteOneStoneModel,
    DeleteOneStoneModelVariables
  >(MUTATION_DELETE_ONE_STONE_MODEL);

  const deleteOne = async (id: string) => {
    await mutationDeleteOneStoneModel({
      variables: {
        input: {
          id: id,
        },
      },
    });
  };

  return !!location.state ? (
    <DeleteOneEntityForm
      mutation={deleteOne}
      entity_object={
        (location.state as StoneModels_stoneModels_edges_node[]).length > 0
          ? (location.state as StoneModels_stoneModels_edges_node[])[0]
          : (location.state as StoneModels_stoneModels_edges_node)
      }
      entity_name={"Stone Model"}
      path={"/customerstables/stonemodel"}
    />
  ) : (
    <LoadingFitCenter />
  );
}
