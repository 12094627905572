import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Card, CardActionArea, CardContent } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: 'auto',
    maxWidth: 500,
    position: "absolute",
    alignSelf: "center",
    bottom: '0',
    alignItems: "center",
  },
  media: {
  },
});

export default function StoneCatalog({ stone, mykey }) {
  const classes = useStyles();
  let i = stone.images[0] ? stone.images[0] : 'images/imagemissing.png';
  return (
    // <div className="card" style={{width:"95%", height:"95%"}}>
    // 	<img src={i} className="card-img-top"  style={{width:"100%", height:"100%"}} alt={stone.code}/>
    // 	<div className={classes.root} >
    // 		<Typography variant="h4" gutterBottom align="center" style={{marginBottom:'0'}}>
    // 			{`w: ${stone.width}` `  X  h: ${stone.height}` `+  X  t: ${stone.thickness}`}
    // 		</Typography>
    // 	</div>
    // </div>
    <div className="container" style={{ alignItems: "center", justifyContent: "center", display: "flex", marginBottom: "50px" }}>
      <Card style={{ width: "90%", height: "90%" }} >
        <Typography variant="h6" gutterBottom align="center">
          {(mykey === 0) ? 'Current - ' + stone.createdate : 'Previous - ' + stone.createdate}
        </Typography>
        <CardActionArea className={classes.media}>
          <img src={i} className="card-img-top" alt={stone.code} />
          <CardContent>
            <Typography variant="h4" gutterBottom align="center" style={{ marginBottom: '0' }}>
              WxHxT: {stone.width} X {stone.height} X {stone.thickness}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}