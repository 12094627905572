import { Backdrop, Box, CircularProgress, colors } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProps } from "../../theme/theme";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

export default function LoadingFitCenterRelative() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress size={"4rem"} />
    </Box>
  );
}
