/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { Box, Card, CardContent, Container, Grid, TextField } from "@material-ui/core";
import swalert from "sweetalert";
import { DeleteOutline } from "@material-ui/icons";
import { MuiThemeProps } from "../../theme/theme";
import { loadingComponent, notLoadingComponent } from "../../actions/isLoadingActions";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "80%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
}));

interface DeleteOneEntityFormProps {
  mutation: (id: string) => Promise<void>;
  entity_object: { [key: string]: any; id: string };
  entity_name: string;
  path: string;
}

export default function DeleteOneEntityForm({
  mutation,
  entity_object,
  entity_name,
  path,
}: DeleteOneEntityFormProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  async function submitForm(e: any) {
    e.preventDefault();

    dispatch(loadingComponent());

    try {
      await mutation(entity_object.id).then((res) => {
        swalert("Success.", `${entity_name} created with success`, "success");
      });
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert("Failed.", error, "error");
      return;
    }
    dispatch(notLoadingComponent());
    history.push(path);
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push(path);
  }
  // console.log(variety)
  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>Delete {entity_name}</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm} style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {Object.keys(entity_object).map((key) => {
                  if (key === "id" || key === "createdAt") {
                    return <></>;
                  }
                  return (
                    <FormControl fullWidth variant="outlined" sx={{ height: 100 }}>
                      <TextField
                        sx={{ height: 100 }}
                        id={key}
                        label={key}
                        name={key}
                        value={entity_object[key]}
                        disabled={true}
                        placeholder={key}
                        variant="outlined"
                      />
                    </FormControl>
                  );
                })}
              </Box>
              <div className="btnform" style={{ width: "100%", justifyContent: "space-between" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{
                        height: "50px",
                        width: "200px",
                        fontSize: "20px",
                      }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{
                        height: "50px",
                        width: "200px",
                        fontSize: "20px",
                        backgroundColor: "red",
                      }}
                    >
                      Delete
                      <DeleteOutline style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
