import React, { isValidElement, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Table, { TableProps } from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer, { TableContainerProps } from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Avatar, Box, lighten, LinearProgress } from "@material-ui/core";
import { Check, Delete, Edit, NotInterested } from "@material-ui/icons";
//@ts-ignore
import { useHistory } from "react-router-dom";
import moment from "moment";
import { MuiThemeProps } from "../../theme/theme";
import { ApolloError } from "@apollo/react-hooks";
import { SortDirection } from "../../data/graphQLModel";

function EnhancedTableHead(props: EnhancedTableHeadPropTypes) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    sortableFields,
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {!props.disableSelection && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          )}
        </TableCell>
        {props.headCells.map((headCell: any) => {
          const sortable = sortableFields.indexOf(headCell.id) !== -1;
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={
                orderBy === headCell.id ? (order.toString().toLowerCase() as "desc" | "asc") : false
              }
            >
              {sortable ? (
                <TableSortLabel
                  active={sortable && orderBy === headCell.id}
                  direction={
                    orderBy === headCell.id
                      ? (order.toString().toLowerCase() as "desc" | "asc" | undefined)
                      : "asc"
                  }
                  onClick={sortable ? (e) => onRequestSort(e, headCell.id) : undefined}
                >
                  {headCell.label}
                  {orderBy === headCell.id && sortable ? (
                    <span className={classes.visuallyHidden}>
                      {order === SortDirection.DESC ? "sorted descending" : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <>{headCell.label}</>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf([SortDirection.ASC, SortDirection.DESC]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
  sortableFields: PropTypes.array.isRequired,
};

interface EnhancedTableHeadPropTypes {
  classes: any;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, property: string) => void;
  onSelectAllClick: (...args: unknown[]) => unknown;
  order: SortDirection.ASC | SortDirection.DESC;
  orderBy: string;
  rowCount: number;
  headCells: ICompleteHeadCells[];
  disableSelection: boolean;
  sortableFields: string[];
}

const useToolbarStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    marginLeft: "1rem",
    marginTop: "0.2rem",
  },
}));

const EnhancedTableToolbar = ({
  path,
  editPath,
  selected,
  setSelected,
  deletePath,
  base64OrDataOnEdit,
  base64OrDataOrIdOnDelete,
  tableSubTitle,
  disableEdit,
  disableRemove,
  disableMultipleSelection,
  aditionalOptions,
  shouldToolTipShowForMoreThanOne = false,
}: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const history = useHistory();
  let c = selected && selected.length > 0 ? selected[0]?.id : undefined;
  let numSelected = !!selected ? selected.length : 0;
  const handleDelete = () => {
    let finalPath = !!deletePath ? deletePath : `${path}/delete`;
    if (base64OrDataOrIdOnDelete === "Base64" && c) {
      history.push({ pathname: `${finalPath}/` + btoa(c) });
    } else if (base64OrDataOrIdOnDelete === "ID") {
      history.push({ pathname: `${finalPath}/` + c });
    } else if (base64OrDataOrIdOnDelete === "Data") {
      history.push({
        pathname: finalPath,
        state: selected,
        //@ts-ignore
        data: selected,
      });
    }
  };

  const handleEdit = () => {
    let finalPath = !!editPath ? editPath : `${path}/edit`;
    if (base64OrDataOnEdit === "Base64" && c) {
      history.push({ pathname: `${finalPath}/` + btoa(c) });
    } else {
      history.push({ pathname: `${finalPath}/` + c });
    }
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {tableSubTitle && typeof tableSubTitle === "string" && tableSubTitle !== "" ? (
        <Typography component={"h5"} style={{ fontSize: 30, marginLeft: 15 }}>
          {tableSubTitle}
        </Typography>
      ) : isValidElement(tableSubTitle) ? (
        tableSubTitle
      ) : (
        <></>
      )}

      {numSelected > 0 && (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {disableMultipleSelection ? "Selected" : numSelected + " selected"}
        </Typography>
      )}

      {numSelected > 0 && (shouldToolTipShowForMoreThanOne || numSelected === 1) && (
        <>
          {!disableEdit && (
            <Tooltip title="Edit">
              <IconButton aria-label="edit" onClick={handleEdit}>
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {!disableRemove && (
            <Tooltip title="Delete" onClick={handleDelete}>
              <IconButton aria-label="delete">
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      {selected &&
        selected.length > 0 &&
        aditionalOptions &&
        aditionalOptions.map((opt) => (
          <Tooltip
            title={opt.title}
            onClick={(e) => {
              opt.onClick([...selected]);
              setSelected([]);
            }}
          >
            {opt.element}
          </Tooltip>
        ))}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  disableEdit: PropTypes.bool.isRequired,
  disableRemove: PropTypes.bool.isRequired,
  disableMultipleSelection: PropTypes.bool.isRequired,
  base64OrDataOnEdit: PropTypes.string.isRequired,
  tableSubTitle: PropTypes.string || PropTypes.element,
  editPath: PropTypes.string,
  base64OrDataOrIdOnDelete: PropTypes.string,
  deletePath: PropTypes.string,
  aditionalOptions: PropTypes.array,
  shouldToolTipShowForMoreThanOne: PropTypes.bool,
  setSelected: PropTypes.func.isRequired,
};

interface EnhancedTableToolbarProps {
  selected: { [key: string]: any; id: string }[];
  setSelected: React.Dispatch<
    React.SetStateAction<
      {
        [key: string]: any;
        id: string;
      }[]
    >
  >;
  path: string;
  disableEdit: boolean;
  disableRemove: boolean;
  disableMultipleSelection: boolean;
  tableSubTitle?: string | undefined | JSX.Element;
  base64OrDataOnEdit: "Base64" | "Data" | "ID";
  editPath?: string;
  base64OrDataOrIdOnDelete?: "Base64" | "Data" | "ID";
  deletePath?: string;
  aditionalOptions?: GeneralTableToolbarOptions[];
  shouldToolTipShowForMoreThanOne?: boolean;
}

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  caption: {
    marginTop: "0.8rem",
  },
}));

const createTableCells = (
  row: any,
  rowValues: ICompleteHeadCells[],
  isItemSelected: any,
  labelId: string,
  disableSelection: boolean,
  onClickCheckBox?: (
    event: any,
    id: string,
    dat: {
      [key: string]: any;
      id: string;
    }
  ) => void
) => {
  return (
    <>
      <TableCell padding="checkbox">
        {!disableSelection && (
          <Checkbox
            style={{ zIndex: 1000 }}
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(e) => {
              onClickCheckBox && onClickCheckBox(e, row.id, row);
            }}
          />
        )}
      </TableCell>
      {Array.isArray(rowValues[0].id) ? (
        <TableCell>
          <Avatar src={rowValues[0].id.length > 0 ? rowValues[0].id[0] : ""} component={"image"} />
        </TableCell>
      ) : (
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="normal"
          key={"" + rowValues[0].id + " " + 0}
        >
          {rowValues[0].id}
        </TableCell>
      )}
      {rowValues.map((el, ind: number) => {
        if (ind !== 0) {
          let valueOfField = "";
          if (possibleDates.indexOf(el.label) !== -1) {
            valueOfField = moment(el.id).format("YYYY-MM-DD");
          } else {
            valueOfField = el.id as string;
          }
          if (el.isBoolean) {
            return (
              <TableCell key={"" + el.id + " " + ind} colSpan={1} align={el.align}>
                {valueOfField === "true" && (
                  <Check
                    style={{
                      color: "green",
                      width: 30,
                      height: 30,
                      marginLeft: 5,
                    }}
                  />
                )}
                {valueOfField === "false" && (
                  <NotInterested
                    style={{
                      color: "red",
                      width: 30,
                      height: 30,
                      marginLeft: 5,
                    }}
                  />
                )}
              </TableCell>
            );
          } else if (!!el.element) {
            const ComponentToRead = el.element;
            return (
              <TableCell key={"" + el.id + " " + ind} colSpan={1} align={el.align}>
                <ComponentToRead selected={row} />
              </TableCell>
            );
          } else {
            return (
              <TableCell key={"" + el.id + " " + ind} colSpan={1} align={el.align}>
                {valueOfField}
              </TableCell>
            );
          }
        }
      })}
    </>
  );
};

const possibleDates = [
  "createdAt",
  "updatedAt",
  "createdate",
  "date",
  "Date",
  "enddate",
  "startdate",
];

export interface ICompleteHeadCells {
  id: string | string[];
  secondary?: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  isBoolean?: boolean;
  element?: React.FC<{ selected: any }>;
}

export interface GeneralTableToolbarOptions {
  onClick: (selected: { [key: string]: any; id: string }[]) => void;
  element: React.ReactElement;
  title: string;
}

export interface NewGeneralTableComponentProps {
  headCells: ICompleteHeadCells[];
  pathEntity: string;
  disableEdit?: boolean;
  disableRemove?: boolean;
  data: any;
  tableSubTitle?: string | undefined | JSX.Element;
  disableDetails?: boolean;
  disableSelection?: boolean;
  disableSelectAll?: boolean;
  disableMultipleSelection?: boolean;
  tableComponent?: string;
  getValues: (row: any, index?: number) => ICompleteHeadCells[];
  base64OrDataOnEdit?: "Base64" | "Data" | "ID";
  base64OrDataOnDetails?: "Base64" | "Data" | "ID";
  base64OrDataOrIdOnDelete?: "Base64" | "Data" | "ID";
  editPath?: string;
  detailsPath?: string;
  deletePath?: string;
  PaperAndTableWrapperProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  tableContainerProps?: TableContainerProps;
  tableProps?: TableProps;
  paperProps?: PaperProps;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  totalCount: number;
  page: number;
  rowsPerPage: number;
  loading: boolean;
  error: ApolloError | undefined;
  LoadingComponent?: () => JSX.Element;
  ErrorComponent?: ({ msg }: { msg: string }) => JSX.Element;
  orderDirection: SortDirection;
  orderByField: string;
  handleSort: (field: string, order: SortDirection) => void;
  sortableFields: string[];
  toolbarOptions?: GeneralTableToolbarOptions[];
  rowsPerPageOptions?: number[];
  onClickRow?: (
    event: any,
    id: string,
    dat: {
      [key: string]: any;
      id: string;
    }
  ) => void;
  ignoreEmptyRows?: boolean;
  shouldToolTipShowForMoreThanOne?: boolean;
}

export default function NewGeneralTableComponent({
  data,
  headCells,
  pathEntity,
  getValues,
  page,
  rowsPerPage,
  editPath = undefined,
  detailsPath = undefined,
  deletePath = undefined,
  disableEdit = false,
  disableRemove = false,
  disableDetails = false,
  disableSelection = false,
  disableSelectAll = false,
  disableMultipleSelection = false,
  tableSubTitle = undefined,
  tableComponent = "container",
  base64OrDataOnEdit = "Base64",
  base64OrDataOnDetails = "Base64",
  base64OrDataOrIdOnDelete = "Data",
  PaperAndTableWrapperProps,
  tableContainerProps,
  tableProps,
  paperProps,
  onChangePage,
  onRowsPerPageChange,
  totalCount,
  loading,
  error,
  orderDirection: order,
  orderByField: orderBy,
  handleSort,
  sortableFields,
  toolbarOptions,
  LoadingComponent = () => (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ),
  ErrorComponent = ({ msg }: { msg: string }) => <div>{msg}</div>,
  rowsPerPageOptions = [5, 10, 25],
  onClickRow,
  ignoreEmptyRows = false,
  shouldToolTipShowForMoreThanOne,
}: NewGeneralTableComponentProps) {
  const classes = useStyles();
  const history = useHistory();
  const [selected, setSelected] = React.useState<{ [key: string]: any; id: string }[]>([]);
  const [emptyRows, setEmptyRows] = React.useState<number>();

  // change empty rows
  useEffect(() => {
    setEmptyRows(ignoreEmptyRows ? 1 : rowsPerPage - data.length);
  }, [data, page, rowsPerPage]);

  const handleRequestSort = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string
  ) => {
    const isDesc = orderBy === property && order === SortDirection.DESC;
    onChangePage(event as any, 0);
    handleSort(property, !isDesc ? SortDirection.DESC : SortDirection.ASC);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n: any) => n);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    if (!!loading && !!selected && selected.length > 0) {
      setSelected([]);
    }
  }, [loading]);

  const handleClick = (event: any, id: string, dat: { [key: string]: any; id: string }) => {
    //@ts-ignore
    const selectedIndex = selected.findIndex((el) => el.id === id);
    let newSelected = [] as { [key: string]: any; id: string }[];
    if (disableMultipleSelection) {
      if (selectedIndex === -1) {
        newSelected = [dat];
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, dat);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    }

    setSelected(newSelected);
  };

  //@ts-ignore
  const isSelected = (id: string) => selected.findIndex((el) => el.id === id) !== -1;

  const handleDetails = (sub: any) => {
    let path = !!detailsPath ? detailsPath : `${pathEntity}/details`;
    if (base64OrDataOnDetails === "ID") {
      history.push({ pathname: `${path}/` + sub.id });
    } else if (base64OrDataOnDetails === "Base64") {
      history.push({ pathname: `${path}/` + btoa(sub.id) });
    } else {
      history.push({
        pathname: `${path}/details`,
        data: sub,
      } as any);
    }
  };

  return (
    <div {...PaperAndTableWrapperProps}>
      <Paper className={classes.paper} {...paperProps}>
        <EnhancedTableToolbar
          selected={selected}
          setSelected={setSelected}
          path={pathEntity}
          editPath={editPath}
          disableEdit={disableEdit}
          disableRemove={disableRemove}
          tableSubTitle={tableSubTitle}
          base64OrDataOnEdit={base64OrDataOnEdit}
          base64OrDataOrIdOnDelete={base64OrDataOrIdOnDelete}
          deletePath={deletePath}
          disableMultipleSelection={disableMultipleSelection}
          aditionalOptions={toolbarOptions}
          shouldToolTipShowForMoreThanOne={shouldToolTipShowForMoreThanOne}
        />
        {
          <TableContainer sx={{ maxHeight: 440 }} {...tableContainerProps}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
              stickyHeader
              {...tableProps}
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data?.length}
                headCells={headCells}
                disableSelection={disableSelectAll}
                sortableFields={sortableFields}
              />
              <TableBody>
                {!!data &&
                  !loading &&
                  !!!error &&
                  data.map((row: any, index: number) => {
                    const isItemSelected = isSelected(row.id);
                    // isSelect = isSelected(row.code)
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event: any) => {
                          if (!!onClickRow) {
                            onClickRow(event, row.id, row);
                          } else if (!disableSelection) {
                            handleClick(event, row.id, row);
                          }
                        }}
                        onDoubleClick={() => {
                          if (!disableDetails) {
                            handleDetails(row);
                          }
                        }}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.code}
                        selected={isItemSelected}
                        sx={{
                          maxHeight: 40,
                          cursor: disableSelection && !!!onClickRow ? "cursor" : "pointer",
                        }}
                      >
                        {createTableCells(
                          row,
                          getValues(row, index),
                          isItemSelected,
                          labelId,
                          disableSelection,
                          !disableSelection ? handleClick : undefined
                        )}
                      </TableRow>
                    );
                  })}
                {!!emptyRows && emptyRows > 0 && (
                  <TableRow style={{ height: (true ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {loading && <LoadingComponent />}
        {!!error && <ErrorComponent msg={error.message} />}
        <TablePagination
          classes={{
            selectLabel: classes.caption,
            displayedRows: classes.caption,
          }}
          backIconButtonProps={
            loading
              ? {
                  disabled: true,
                }
              : undefined
          }
          nextIconButtonProps={
            loading
              ? {
                  disabled: true,
                }
              : undefined
          }
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Paper>
    </div>
  );
}
