import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { Box, Card, CardContent, Container } from "@material-ui/core";
import swalert from "sweetalert";
import { MuiThemeProps } from "../../../theme/theme";
import { useMutation } from "@apollo/react-hooks";
import { CreateOneProject, CreateOneProjectVariables } from "../../../data/graphQLModel";
import { MUTATION_CREATE_ONE_PROJECT } from "../../../data/graphQLQueries";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "80%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function CreateProject() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setForm] = useState({ code: "", project: "", description: "", observation: "" });

  const [mutationCreateOneProject] = useMutation<CreateOneProject, CreateOneProjectVariables>(
    MUTATION_CREATE_ONE_PROJECT
  );

  function changeform(e: any) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  async function submitForm(e: any) {
    e.preventDefault();

    if (form.code === "" || form.code.length < 2 || form.description === "") {
      swalert("Information!", "Missing information!", "warning");
      return;
    }

    try {
      // let res = await mutationService.createProject(form.code, form.project, form.description, form.observation);
      let res = await mutationCreateOneProject({
        variables: {
          input: {
            project: {
              code: form.code,
              project: form.project,
              description: form.description,
              observation: form.observation,
            },
          },
        },
      });
      if (res) swalert("Success.", "Project created with success", "success");
    } catch (error) {
      dispatch(notLoadingComponent());
      console.log(error);
      swalert("Failed.", typeof error === "string" ? error : "Erro", "error");
      return;
    }
    // dispatch(notLoadingComponent());
    history.push("/customerstables/projects");
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/customerstables/projects");
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>New Project</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm} style={{ width: "100%" }}>
              <div>
                <FormControl fullWidth className={classes.marginCode} variant="outlined">
                  <TextField
                    id="CodeProject"
                    label="Code"
                    name="code"
                    placeholder="Code"
                    required
                    focused
                    onChange={changeform}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <TextField
                    id="projProject"
                    label="Project"
                    name="project"
                    onChange={changeform}
                    placeholder="Project"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <TextField
                    id="descProject"
                    label="Description"
                    name="description"
                    onChange={changeform}
                    placeholder="Description"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <TextField
                    id="obsProject"
                    label="Observation"
                    name="observation"
                    placeholder="Observation"
                    onChange={changeform}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={5}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      disabled={
                        !!!form.code ||
                        form.code === "" ||
                        !!!form.description ||
                        form.description === "" ||
                        !!!form.project ||
                        form.project === ""
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Create
                      <Save style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
