import "date-fns";
import ptLocale from "date-fns/locale/pt";
import { makeStyles } from "@material-ui/styles";
import DatePicker from "@material-ui/lab/DatePicker";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { MuiThemeProps } from "../../theme/theme";
import { Input, TextFieldProps, Box, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useState } from "react";
import { format } from "date-fns";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 180,
    alignSelf: "center",
  },
}));

export interface SelectDateProps {
  selectedDate: any;
  handleDateChange: (date: unknown, keyboardInputValue?: string | undefined) => void;
  label?: string;
  minDate?: any;
  maxDate?: any;
}

export default function SelectDate(
  {
    selectedDate,
    handleDateChange,
    minDate = undefined,
    maxDate = undefined,
    label = "Date",
  }: SelectDateProps,
  ...props: any
) {
  const classes = useStyles();
  // using this state to make it possible for the DatePicker to open when clicking anywhere in the TextField
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // function to block text input
  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const renderInput = (props: TextFieldProps, selectedDate: any): any => {
    return (
      <Input
        type="text"
        inputRef={props.inputRef}
        inputProps={{
          ...props.inputProps,
          value: !!selectedDate ? format(selectedDate, "dd/MM/yyyy") : label,
        }}
        value={selectedDate}
        onClick={props.onClick}
        onChange={props.onChange}
        endAdornment={
          !!selectedDate && (
            <IconButton
              color="inherit"
              aria-label="clear date"
              onClick={(e) => {
                handleDateChange(null, undefined);
              }}
              size="small"
            >
              <Close />
            </IconButton>
          )
        }
        startAdornment={
          <Box ml={-2} mr={1}>
            {props.InputProps?.endAdornment}
          </Box>
        }
        onKeyDown={onKeyDown}
      />
    );
  };

  const getDateIntervalProps = (): any => {
    let props = {} as any;
    if (minDate) props.minDate = new Date(minDate);
    if (maxDate) props.maxDate = new Date(maxDate);
    return props;
  };

  return (
    <Box className={classes.formControl}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
        <DatePicker
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          label={label}
          value={selectedDate}
          clearable
          clearText="Clear"
          onChange={(date, value) => {
            handleDateChange(date, value);
          }}
          renderInput={(props) =>
            renderInput({ ...props, onClick: () => setIsOpen(true) }, selectedDate)
          }
          {...getDateIntervalProps()}
        />
      </LocalizationProvider>
    </Box>
  );
}
