import { CardContent, IconButton, useTheme } from "@material-ui/core";
import { Check, NotInterested, OpenInNew } from "@material-ui/icons";
import { Box } from "@material-ui/system";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ImageBox from "../GeneralComponents/ImageBox";
import { H3, H4, H5 } from "../GeneralComponents/Typography";
import {
  PackingsGallery_packings_edges_node,
  PackingsGallery_packings_edges_node_slabs_edges_node,
} from "../../data/graphQLModel";
import { getSlabImageSafe, openNewTab } from "../../utils/utils";
import FlexBox from "../GeneralComponents/FlexBox";

const getVariety = (slab: PackingsGallery_packings_edges_node_slabs_edges_node) => {
  return slab?.commercial_variety?.name
    ? slab?.commercial_variety.name
    : slab?.variety?.normativedesig
    ? slab.variety.normativedesig
    : "";
};

const getFinish = (slab: PackingsGallery_packings_edges_node_slabs_edges_node) => {
  return slab?.commercial_finishing?.description
    ? slab?.commercial_finishing?.description?.pt
    : slab?.finish?.description?.pt
    ? slab?.finish?.description?.pt
    : "";
};

const getMaterial = (slab: PackingsGallery_packings_edges_node_slabs_edges_node) => {
  return slab?.commercial_variety?.materialtype?.description?.pt
    ? slab?.commercial_variety?.materialtype?.description.pt
    : slab?.typematerial?.description?.pt
    ? slab?.typematerial?.description?.pt
    : "";
};

interface BundleGalleryCardProps {
  bundle: PackingsGallery_packings_edges_node;
}

const BundleGalleryCard = ({ bundle }: BundleGalleryCardProps) => {
  const theme = useTheme();
  const history = useHistory();
  const [firstSlab, setFirstSlab] =
    useState<PackingsGallery_packings_edges_node_slabs_edges_node | null>(
      bundle?.slabs && bundle?.slabs?.edges?.length > 0 ? bundle?.slabs?.edges[0]?.node : null
    );

  const handleGetPackageDetails = () => {
    history.push({
      pathname: "/customerstables/packages/detailspackage/" + btoa(bundle?.id),
    });
  };

  return (
    <ImageBox
      sx={{
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        position: "relative",
      }}
      className="card"
      hoverEffect
    >
      <a
        href={"/customerstables/packages/detailspackage/" + btoa(bundle?.id)}
        onClick={handleGetPackageDetails}
      >
        <img
          src={
            !!firstSlab
              ? getSlabImageSafe(firstSlab, true) || "/images/imagemissing.png"
              : "/images/imagemissing.png"
          }
          alt={bundle.code}
        />
        <CardContent>
          <Box>
            <H3
              fontWeight="bold"
              fontSize={18}
              ellipsis
              mt={1}
              color="grey.800"
              textTransform="capitalize"
            >
              {bundle.code}
            </H3>
            <H3
              fontWeight="bold"
              fontSize={18}
              ellipsis
              color="grey.800"
              textTransform="capitalize"
            >
              {bundle.observation}
            </H3>
            {firstSlab && (
              <H5 fontWeight="bold" fontSize={17}>
                {getVariety(firstSlab)}
              </H5>
            )}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <H5 fontWeight="light" fontSize={17} color={"grey.600"} textTransform="capitalize">
                {"Finishing: "}
              </H5>
              {firstSlab && (
                <H5
                  ellipsis
                  fontWeight="bold"
                  fontSize={17}
                  color={"primary.main"}
                  ml={1}
                  textTransform="capitalize"
                >
                  {getFinish(firstSlab)}
                </H5>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <H5 fontWeight="light" fontSize={17} color={"grey.600"} textTransform="capitalize">
                {"Material: "}
              </H5>
              {firstSlab && (
                <H5
                  ellipsis
                  fontWeight="bold"
                  fontSize={17}
                  color={"primary.main"}
                  ml={1}
                  textTransform="capitalize"
                >
                  {getMaterial(firstSlab)}
                </H5>
              )}
            </Box>
          </Box>
          <Box>
            <Box flexDirection="row" display="flex" justifyContent={"space-between"}>
              <H4>{bundle?.slabs?.totalCount || 0} Slabs</H4>
              <Box>
                {" "}
                {bundle.inStock ? (
                  <Check
                    style={{
                      color: "green",
                      width: 30,
                      height: 30,
                      marginLeft: 5,
                    }}
                  />
                ) : (
                  <NotInterested
                    style={{
                      color: "red",
                      width: 30,
                      height: 30,
                      marginLeft: 5,
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </a>
      <FlexBox sx={{ justifyContent: "flex-end" }}>
        <IconButton
          color="primary"
          onClick={() => {
            openNewTab("/customerstables/packages/detailspackage/" + btoa(bundle?.id));
          }}
        >
          <OpenInNew />
        </IconButton>
      </FlexBox>
    </ImageBox>
  );
};

export default BundleGalleryCard;
