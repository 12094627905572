import { useState } from "react";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  CursorPaging,
  FinishingFilter,
  Finishings,
  FinishingSort,
  Finishings_finishings_edges_node,
} from "../../data/graphQLModel";
import { QUERY_FINISHINGS } from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { EntityAutocompleteProps } from "./types";

/**
 * ## Reusable Finishing Autocomplete Component
 *
 * ### Default Autocomplete property values:
 *
 * #### name: finish
 * #### label: System Finishing
 * #### continueToLoadResultsInComponent: false
 * #### shouldFieldBeLocked: false
 *
 */
const FinishingAutocomplete = ({
  errors,
  continueToLoadResultsInComponent = false,
  shouldFieldBeLocked = false,
  helperText,
  value,
  onChange,
  ...props
}: EntityAutocompleteProps) => {
  const [finishings, setFinishings] = useState<Finishings_finishings_edges_node[]>([]);

  return (
    <AutocompleteWithQuery
      onChange={onChange}
      value={value}
      items={finishings}
      label="System Finishing"
      name="finish"
      title="System Finishing"
      TextFieldProps={{
        error: !!errors,
        helperText: helperText,
        label: "System Finishing",
        disabled: shouldFieldBeLocked,
      }}
      continueToLoadResultsInComponent={continueToLoadResultsInComponent}
      onTextChange={async (value: string) => {
        await queryValuesForFilter<FinishingFilter, CursorPaging, FinishingSort, Finishings>(
          value,
          QUERY_FINISHINGS,
          (res) => {
            let endRes = res?.data?.finishings?.edges?.map((el) => el.node);
            setFinishings(endRes);
          },
          {
            filter: {
              or: [{ code: { iLike: value } }],
            },
            paging: {
              first: 200,
            },
          }
        );
      }}
      getField={(node: Finishings_finishings_edges_node) => {
        return `${node.code} - ${node?.description?.pt ? node?.description?.pt : ""}`;
      }}
      {...props}
      AutocompleteProps={{
        disabled: shouldFieldBeLocked,
        ...props?.AutocompleteProps,
      }}
    />
  );
};

export default FinishingAutocomplete;
