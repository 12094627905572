import React from 'react';
import 'date-fns';
import { TextField, Autocomplete } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import { MuiThemeProps } from '../../theme/theme';
import { primary, success } from '../../theme/themeColors'

const useStyles = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  formControl: {
    minWidth: 200,
  },
}));

// https://stackoverflow.com/questions/58984406/setting-text-color-outline-and-padding-on-material-ui-autocomplete-component
const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-inputRoot": {
    color: primary.main,
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: primary.main
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: success.main
    }
  }
});

const getField = (row: any, id: string) => {
  let result = ""
  if (id.indexOf('.') >= 0) {
    result = row
    let fields = id.split('.')
    fields.forEach((el) => {
      result = result[el as keyof typeof result] as string
    });
  } else {
    result = row[id]
  }
  return result
}

export interface AutocompleteFilterNewProps {
  items: any;
  title: string;
  value: any;
  label: string;
  name: string;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  }
  field: string;
  extraFields?: string[];
  error?: boolean | undefined;
  helperText?: string | false | undefined;
  onBlur?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  }
  [key: string]: any;
}

export default function AutocompleteFilterObject(
  { items, title, value, label, onChange, onBlur, name, field, error, helperText, extraFields, ...rest }: AutocompleteFilterNewProps) {
  const classes = useStyles();

  const defaultVal = value && items.find((el: any) => el.id === value) ? items.find((el: any) => el.id === value) : null
  return (
    <StyledAutocomplete id={name} className={classes.formControl}{...rest}
      onChange={(event, value: any) => {
        onChange({ ...event, target: { ...event.target, value: value ? value.id : "", name: name } })
      }}
      title={title}
      value={defaultVal}
      options={items}
      isOptionEqualToValue={(option: any, value: any) => {
        return option.id === value.id
      }}
      disabled={!items || items.length === 0}
      clearOnEscape={true}
      getOptionLabel={(i) => {
        if (typeof i === 'string') {
          return ''
        }
        return "" + getField(i, field)
          + (extraFields ? " - " + extraFields.map(el => getField(i, el)).join(' ') : "")
      }}
      renderInput={(params) => {
        return <TextField {...params} onBlur={onBlur} label={label} error={error} helperText={helperText} name={name} />
      }}
    />
  );
}