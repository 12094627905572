import { useState } from "react";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  CursorPaging,
  MaterialTypeFilter,
  MaterialTypes,
  MaterialTypeSort,
  MaterialTypes_materialTypes_edges_node,
} from "../../data/graphQLModel";
import { QUERY_MATERIALTYPES } from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { EntityAutocompleteProps } from "./types";

/**
 * ## Reusable Material Type Autocomplete Component
 *
 * ### Default Autocomplete property values:
 *
 * #### name: typematerial (change this to your form object property if needed)
 * #### label: Materials Type (only visual)
 * #### continueToLoadResultsInComponent: false
 * #### shouldFieldBeLocked: false
 *
 */
const MaterialTypeAutocomplete = ({
  errors,
  continueToLoadResultsInComponent = false,
  shouldFieldBeLocked = false,
  helperText,
  value,
  onChange,
  ...props
}: EntityAutocompleteProps) => {
  const [materialTypes, setMaterialTypes] = useState<MaterialTypes_materialTypes_edges_node[]>([]);
  return (
    <AutocompleteWithQuery
      onChange={onChange}
      value={value}
      items={materialTypes}
      label="Material Type"
      name="typematerial"
      title="Materials Type"
      TextFieldProps={{
        error: !!errors,
        helperText: helperText,
        label: "Material Type",
        disabled: shouldFieldBeLocked,
      }}
      continueToLoadResultsInComponent={continueToLoadResultsInComponent}
      onTextChange={async (value: string) => {
        await queryValuesForFilter<
          MaterialTypeFilter,
          CursorPaging,
          MaterialTypeSort,
          MaterialTypes
        >(
          value,
          QUERY_MATERIALTYPES,
          (res) => {
            let endRes = res?.data?.materialTypes?.edges?.map((el) => el.node);
            setMaterialTypes(endRes);
          },
          {
            filter: { or: [{ code: { iLike: value } }] },
            paging: { first: 70 },
          }
        );
      }}
      getField={(node: MaterialTypes_materialTypes_edges_node) => {
        if (!!node.companyNames && node.companyNames.length > 0) {
          let obj = node.companyNames[0];
          return `${obj.code} - ${obj.description}`;
        }
        return `${node.code} - ${node.description.pt}`;
      }}
      {...props}
      AutocompleteProps={{
        disabled: shouldFieldBeLocked,
        ...props?.AutocompleteProps,
      }}
    />
  );
};

export default MaterialTypeAutocomplete;
