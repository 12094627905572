import {
  Card,
  Button,
  Typography,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Menu,
} from "@material-ui/core";
import {
  Assignment,
  CheckCircleOutline,
  Clear,
  Edit,
  Image,
  MoreVert,
  Block,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/system";
import { useState } from "react";
import OptionsSticky from "../../components/CatalogFilters/OptionsSticky";
import { Slabs_slabs_nodes } from "../../data/graphQLModel";
import theme, { MuiThemeProps } from "../../theme/theme";

const useStyles = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  boxTop: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
  },
  boxBottom: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
  },
  buttonStyle: {
    height: "50px",
    width: "200px",
    fontSize: "20px",
  },
}));

interface SlabSelectionMenuTabProps {
  toggleIsFixed: (fixed: any) => void;
  isFixed: boolean;
  handleCatalog: (cancelCatalog?: boolean) => void;
  handleEditImage: () => void;
  lisSlab: Slabs_slabs_nodes[];
  onClear: () => void;
  selectAllSlabsInPage: () => void;
}

const SlabSelectionMenuTab = ({
  toggleIsFixed,
  isFixed,
  handleCatalog,
  handleEditImage,
  lisSlab,
  onClear: handleClear,
  selectAllSlabsInPage,
}: SlabSelectionMenuTabProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MenuComponent = () => {
    return (
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <Paper sx={{ width: 320, maxWidth: "100%" }}>
          <MenuList>
            <MenuItem
              onClick={() => {
                handleCatalog(true);
              }}
            >
              <ListItemIcon>
                <Block fontSize="large" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.text.secondary,
                    ml: 1,
                  }}
                >
                  Cancel Cataloguing
                </Typography>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Menu>
    );
  };

  return (
    <OptionsSticky
      fixedOn={0} // 70 to fix under navbar
      onSticky={toggleIsFixed}
      sticky={isFixed}
      alwaysSticky={false}
    >
      <Card sx={{ width: "100%", p: 2, borderRadius: 2 }}>
        <Box className={classes.boxBottom}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                handleClear();
              }}
            >
              <Clear />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: 80,
                justifyContent: "space-evenly",
              }}
            >
              <Typography variant="h5">{lisSlab.length}</Typography>
              <Image fontSize="large" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              style={{
                height: "50px",
                width: "200px",
                fontSize: "20px",
                marginRight: "8px",
              }}
              variant="contained"
              onClick={() => handleCatalog()}
              startIcon={<Assignment sx={{ mt: 0.5 }} fontSize="large" />}
            >
              Catalog
            </Button>
            <Button
              style={{
                height: "50px",
                width: "200px",
                fontSize: "20px",
                marginRight: "8px",
              }}
              variant="contained"
              onClick={() => handleEditImage()}
              startIcon={<Edit sx={{ mt: 0.5 }} fontSize="large" />}
            >
              Image Filters
            </Button>
            <Button
              style={{
                height: "50px",
                width: "200px",
                fontSize: "20px",
              }}
              variant="contained"
              onClick={selectAllSlabsInPage}
              startIcon={<CheckCircleOutline sx={{ mt: 0.5 }} fontSize="large" />}
            >
              Select All
            </Button>
            <IconButton color="primary" onClick={handleClick}>
              <MoreVert fontSize="large" />
            </IconButton>
            <MenuComponent />
          </Box>
        </Box>
      </Card>
    </OptionsSticky>
  );
};

export default SlabSelectionMenuTab;
