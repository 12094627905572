export const LOADING = '@ISLOADING/LOADING';
export const NOT_LOADING = '@ISLOADING/NOT_LOADING';

const loadingComponent = () => {
    return (dispatch) => {
        dispatch({
            type: LOADING,
            payload: {
                loading: true
            }
        })
    }
}

const notLoadingComponent = () => {
    return (dispatch) => {
        dispatch({
            type: NOT_LOADING,
            payload: {
                loading: false
            }
        })
    }
}

export { loadingComponent, notLoadingComponent };