import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Companies,
  CompaniesVariables,
  CreateOneUser,
  CreateOneUserVariables,
  IRolesEnum,
} from "../../data/graphQLModel";
import { MUTATION_CREATE_ONE_USER, QUERY_COMPANIES } from "../../data/graphQLQueries";
import * as yup from "yup";
import { Grid, Button, Container, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import AutocompleteFilterJustText from "../../components/Filters/AutoCompleteFilterJustText";
import AutocompleteFilterObject from "../../components/Filters/AutoCompleteFilterObjects";
import { Helmet } from "react-helmet";
import { copyObject } from "../../utils/utils";
import toast from "react-hot-toast";

export interface IFormUser {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  company: string;
  avatar: string;
  role: IRolesEnum;
}

const initialValues: IFormUser = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  company: "",
  avatar: "",
  role: IRolesEnum.basic,
};

const checkoutSchema = yup.object().shape({
  firstname: yup.string().required("Required"),
  lastname: yup.string().required("Required"),
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
  company: yup.string().required("Required"),
  avatar: yup.string().required("Required"),
  role: yup.string().required("Required"),
});

const CreateUserPage = () => {
  const {
    loading: companies_loading,
    error: companies_error,
    data: companies_data,
  } = useQuery<Companies, CompaniesVariables>(QUERY_COMPANIES, {
    variables: {
      paging: {
        first: 30,
      },
    },
  });

  const [mutationCreateOneUser] = useMutation<CreateOneUser, CreateOneUserVariables>(
    MUTATION_CREATE_ONE_USER
  );

  const handleFormSubmit = async (values: IFormUser) => {
    let inputV1 = copyObject(values, ["role"]);
    await mutationCreateOneUser({
      variables: {
        input: {
          user: {
            company: values.company,
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            name: `${values.firstname} ${values.lastname}`,
            password: values.password,
            status: true,
            avatar: values.avatar,
            role: values.role,
          },
        },
      },
    }).catch((err) => {
      toast.error(`Failed to create user: ${err.toString()}`);
    });
  };

  return (
    <Container fixed sx={{ mt: "2rem" }}>
      <Helmet>
        <title>Create User | WeScan</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid item sm={12} xs={12} mb={"1rem"}>
              <TextField
                name="firstname"
                label="First Name"
                placeholder="First Name"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstname || ""}
                error={!!touched.firstname && !!errors.firstname}
                helperText={touched.firstname && errors.firstname}
              />
            </Grid>

            <Grid item sm={12} xs={12} mb={"1rem"}>
              <TextField
                name="lastname"
                label="Last Name"
                placeholder="Last Name"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastname || ""}
                error={!!touched.lastname && !!errors.lastname}
                helperText={touched.lastname && errors.lastname}
              />
            </Grid>

            <Grid item sm={12} xs={12} mb={"1rem"}>
              <TextField
                name="email"
                label="Email"
                placeholder="Email"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email || ""}
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
            </Grid>

            <Grid item sm={12} xs={12} mb={"1rem"}>
              <TextField
                name="password"
                label="Password"
                placeholder="Password"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password || ""}
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
              />
            </Grid>

            <Grid item sm={12} xs={12} mb={"1rem"}>
              <TextField
                name="avatar"
                label="Avatar URL"
                placeholder="Avatar URL"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.avatar || ""}
                error={!!touched.avatar && !!errors.avatar}
                helperText={touched.avatar && errors.avatar}
              />
            </Grid>

            <Grid container spacing={3}>
              <Grid item sm={12} xs={12}>
                <AutocompleteFilterObject
                  items={
                    companies_data
                      ? [...companies_data.companies.edges.map((el) => el.node), ""]
                      : []
                  }
                  title={"Company"}
                  label={"Company"}
                  value={values.company}
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<any>) => {
                    handleChange(e);
                  }}
                  field="name"
                  name={"company"}
                  extraFields={["name"]}
                  error={!!touched.company && !!errors.company}
                  helperText={touched.company && errors.company}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <AutocompleteFilterJustText
                  items={Object.keys(IRolesEnum)}
                  title={"User Role"}
                  label={"User Role"}
                  value={values.role}
                  onChange={(e: React.ChangeEvent<any>) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  field=""
                  name={"role"}
                  error={!!touched.role && !!errors.role}
                  helperText={touched.role && errors.role}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: "25px", justifyContent: "space-around" }}>
              <Button variant="contained" color="primary" type="submit" sx={{ width: "200px" }}>
                Create User
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CreateUserPage;
