import { format } from "date-fns";
import { ICompleteHeadCells } from "../../../components/Table/NewGeneralTableComponent";
import { PackingTypes_packingTypes_edges_node } from "../../../data/graphQLModel";

export const packageTypesHeadCellsDefault: ICompleteHeadCells[] = [
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "type", numeric: false, disablePadding: false, label: "Type" },
  { id: "observation", numeric: false, disablePadding: false, label: "Observation" },
  { id: "system", numeric: false, disablePadding: false, label: "System Type", isBoolean: true },
  { id: "createdAt", numeric: false, disablePadding: false, label: "Created At" },
];

export const getPackageTypesRowDefaultValue = (
  row: PackingTypes_packingTypes_edges_node
): ICompleteHeadCells[] => {
  return [
    {
      id: row?.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    {
      id: row?.type || "",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: row.observation || "",
      numeric: false,
      disablePadding: false,
      label: "Observation",
    },
    {
      id: !!!row?.company + "" || "",
      numeric: false,
      disablePadding: false,
      label: "System Type",
      isBoolean: true,
    },
    {
      id: row?.createdAt ? format(new Date(row?.createdAt), "yyyy-MM-dd") : "",
      numeric: false,
      disablePadding: false,
      label: "Created At",
    },
  ];
};
