import { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import PrivacyPolicy from "../PrivacyPolicy/privacypolicy";
import Home from "../Home";
import HomeScan from "../Home/homescan";
import Catalog from "../Catalog";
import EditImages from "../editimages";
import {
  StoneEdges,
  CreateStoneEdge,
  DeleteStoneEdge,
  EditStoneEdge,
} from "../CustomsTable/StoneEdges/stoneedges.router";
import {
  StoneModels,
  CreateStoneModel,
  DeleteStoneModel,
  EditStoneModel,
} from "../CustomsTable/StoneModels/stonemodel.router";

import { Material } from "../MasterTable/Material/material.routers";
import { Category } from "../MasterTable/Category/category.routers";
import { Finish } from "../MasterTable/Finish/finish.routers";
import { EnvironmentalFactors } from "../SmartStone/EnvironmentalFactors/envfactors.routers";
import { ApplicationTypesMasterTable } from "../SmartStone/ApplicationTypes/appli_types.routers";

import {
  Project,
  ProjectCreate,
  ProjectEdit,
  ProjectDelete,
  ProjectDetails,
  AddPackingProject,
} from "../CustomsTable/Project/projects.routers";
import {
  Package,
  PackageCreate,
  PackageEdit,
  PackageDelete,
  PackageDetails,
} from "../CustomsTable/Packages/package.routers";
import {
  PackageType,
  PackageTypeCreate,
  PackageTypeEdit,
  PackageTypeDelete,
} from "../CustomsTable/PackagesTypes/packagetype.routers";
import {
  CommercialVarieties,
  CreateCommercialVariety,
  DeleteCommercialVariety,
  DetailsCommercialVariety,
} from "../CustomsTable/CommercialVarieties/commvarieties.routers";
import {
  CommercialFinishings,
  CreateCommercialFinishing,
  EditCommercialFinishing,
  DeleteCommercialFinishing,
} from "../CustomsTable/CommercialFinishings/commfinishings.routers";

import { Variety } from "../MasterTable/Variety/variety.routers";
import { Slabs, SlabsDelete, SlabsCreate, SlabsDetails } from "../Slab/slabs.routers";

import {
  ProjectGallery,
  SlabGalleryDetails,
} from "../CustomsTable/Project/ProjectGallery/projectsgallry.routers";
import { PackageGallery } from "../CustomsTable/Packages/PackageGallery/packagegallry.routers";
import BlockInProjectGallery from "../CustomsTable/StoneBlocs/DetailsStoneBlocProjectGallery";
// import { ProjectSlabPackage } from "../CustomsTable/Project/ProjectSlabPackage/projectsslabpackage.routers";
import {
  Jobs,
  JobsDetailsOrUpdate,
  JobsCreate,
  JobsDelete,
} from "../SlabsProcess/Jobs/jobs.routers";

import Account from "../account";
import Admin from "../admin";
import Gallery from "../Gallery";

import Login from "../Login/SignIn";
import Register from "../Register/Register";
import GuestRoute from "./GuestRoute";
import ComponenteDePagina404 from "../NotFound";
import Dashboard from "../Dashboard";
import Auth from "../../components/Auth";
import { SmartStoneForm } from "../SmartStone/Form";
import CreateUserPage from "../admin/CreateUser";
import { authShareable } from "../../actions/accountActions";
import { Blocks, DetailsBlock } from "../Blocks/blocks.routers";
import SensorLogs from "../Sensor/SensorLogs";
// Scanners START
import ScannersPage from "../Scanner";
import ScannersConsole from "../Scanner/ScannerConsole";
// Scanners END

function PrivateRoute({ component: Component, ...rest }: any) {
  const user = useSelector((state: any) => state.account.user);
  const token = localStorage.getItem("accessTokenV2");
  const history = useHistory();
  if (!user && !token) {
    history.push({
      pathname: "/login",
      state: { hadLogin: true, previousPath: history.location.pathname },
    });
    return <></>;
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
}

function ShareableRoute({ component: Component, ...rest }: any) {
  const user = useSelector((state: any) => state.account.user);
  const tokenV2 = localStorage.getItem("accessTokenV2");
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (!user && !tokenV2) {
      // try to find key value in URL param and URL search query
      let search = queryString.parse(history.location.search);
      let keyValSearchParam = Object.keys(search).find(
        (el) => el.toUpperCase().indexOf("KEYVALUE") !== -1
      );
      if (!!keyValSearchParam) {
        dispatch(authShareable(search?.[keyValSearchParam]));
      } else {
        dispatch(authShareable(history.location.pathname.split("/").pop()));
      }
    }
  }, []);
  if (!user) {
    return <></>;
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
}

export default function Routes() {
  return (
    <Auth>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/home/:scan" component={HomeScan} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/catalog" component={Catalog} />
        <PrivateRoute exact path="/catalog/editimage/:imgedit" component={EditImages} />

        <PrivateRoute exact path="/gallery" component={Gallery} />
        <PrivateRoute exact path="/scanners" component={ScannersPage} />
        <PrivateRoute exact path="/scanners/:scanner" component={ScannersConsole} />

        <PrivateRoute exact path="/" component={Gallery} />

        <GuestRoute exact path="/login" component={Login} />
        <GuestRoute exact path="/register" component={Register} />

        <PrivateRoute exact path="/slabmanagement" component={Slabs} />
        <PrivateRoute exact path="/slabmanagement/create" component={SlabsCreate} />
        <PrivateRoute exact path="/slabmanagement/details/:slbs" component={SlabsDetails} />
        <PrivateRoute exact path="/slabmanagement/delete" component={SlabsDelete} />

        <PrivateRoute exact path="/slabsprocess/jobs" component={Jobs} />
        <PrivateRoute exact path="/slabsprocess/jobs/create" component={JobsCreate} />
        <PrivateRoute exact path="/slabsprocess/jobs/details/:jb" component={JobsDetailsOrUpdate} />
        <PrivateRoute exact path="/slabsprocess/jobs/edit/:jb" component={JobsDetailsOrUpdate} />
        <PrivateRoute exact path="/slabsprocess/jobs/delete/:jb" component={JobsDelete} />

        <PrivateRoute exact path="/slabsprocess/blocks" component={Blocks} />
        <PrivateRoute exact path="/slabsprocess/blocks/details/:block" component={DetailsBlock} />

        {/* <PrivateRoute exact path="/jobprocess/create" component={JobsCreate} />
				<PrivateRoute exact path="/jobprocess/delete" component={JobsDelete} /> */}

        <PrivateRoute exact path="/variety" component={Variety} />

        <PrivateRoute exact path="/customerstables/projects" component={Project} />
        <PrivateRoute exact path="/customerstables/projects/create" component={ProjectCreate} />
        <PrivateRoute
          exact
          path="/customerstables/projects/editproject/:prj"
          component={ProjectEdit}
        />
        <PrivateRoute
          exact
          path="/customerstables/projects/detailsproject/:prj"
          component={ProjectDetails}
        />
        <PrivateRoute
          exact
          path="/customerstables/projects/detailsproject/addpackaging/:prj"
          component={AddPackingProject}
        />
        <PrivateRoute exact path="/customerstables/projects/delete" component={ProjectDelete} />

        <ShareableRoute exact path="/projectgallery/shareproject/:prj" component={ProjectGallery} />
        <ShareableRoute exact path="/share/slab/:slb" component={SlabGalleryDetails} />
        <ShareableRoute exact path="/share/slab" component={SlabGalleryDetails} />

        <ShareableRoute
          exact
          path="/packagegallery/sharepackages/:parpack"
          component={PackageGallery}
        />
        <ShareableRoute exact path="/packagegallery/sharepackages/" component={PackageGallery} />
        <Route exact path="/blockgallery" component={BlockInProjectGallery} />

        {/* Customers Table */}
        <PrivateRoute exact path="/customerstables/packages" component={Package} />
        <PrivateRoute exact path="/customerstables/packages/create" component={PackageCreate} />
        <PrivateRoute
          exact
          path="/customerstables/packages/editpackage/:parpack"
          component={PackageEdit}
        />
        <PrivateRoute
          exact
          path="/customerstables/packages/detailspackage/:parpack"
          component={PackageDetails}
        />
        <PrivateRoute exact path="/customerstables/packages/delete" component={PackageDelete} />

        <PrivateRoute exact path="/customerstables/packagetypes" component={PackageType} />
        <PrivateRoute
          exact
          path="/customerstables/packagetypes/create"
          component={PackageTypeCreate}
        />
        <PrivateRoute
          exact
          path="/customerstables/packagetypes/editpackagetype/:parptype"
          component={PackageTypeEdit}
        />
        <PrivateRoute
          exact
          path="/customerstables/packagetypes/delete/:parptype"
          component={PackageTypeDelete}
        />

        <PrivateRoute exact path="/customerstables/commvarieties" component={CommercialVarieties} />
        <PrivateRoute
          exact
          path="/customerstables/commvarieties/create"
          component={CreateCommercialVariety}
        />
        <PrivateRoute
          exact
          path="/customerstables/commvarieties/details/:commvar"
          component={DetailsCommercialVariety}
        />
        <PrivateRoute
          exact
          path="/customerstables/commvarieties/delete"
          component={DeleteCommercialVariety}
        />

        <PrivateRoute
          exact
          path="/customerstables/commfinishings"
          component={CommercialFinishings}
        />
        <PrivateRoute
          exact
          path="/customerstables/commfinishings/create"
          component={CreateCommercialFinishing}
        />
        <PrivateRoute
          exact
          path="/customerstables/commfinishings/edit/:commfin"
          component={EditCommercialFinishing}
        />
        <PrivateRoute
          exact
          path="/customerstables/commfinishings/delete"
          component={DeleteCommercialFinishing}
        />

        <PrivateRoute exact path="/finish" component={Finish} />

        <PrivateRoute exact path="/category" component={Category} />

        <PrivateRoute exact path="/material" component={Material} />

        {/* SMART STONE */}
        {/* ENVIRONMENTAL FACTORS */}
        <PrivateRoute exact path="/envfactor" component={EnvironmentalFactors} />

        {/* APPLICATION_TYPES */}
        <PrivateRoute exact path="/applicationtypes" component={ApplicationTypesMasterTable} />
        <PrivateRoute exact path="/smartstone" component={SmartStoneForm} />

        <PrivateRoute exact path="/account" component={Account} />
        <PrivateRoute exact path="/admin" component={Admin} />
        <PrivateRoute exact path="/admin/createuser" component={CreateUserPage} />
        <PrivateRoute exact path="/sensorlogs" component={SensorLogs} />
        {/* STONE EDGES */}
        <PrivateRoute exact path="/customerstables/stoneedge" component={StoneEdges} />
        <PrivateRoute exact path="/customerstables/stoneedge/create" component={CreateStoneEdge} />
        <PrivateRoute
          exact
          path="/customerstables/stoneedge/edit/:stoneedge"
          component={EditStoneEdge}
        />
        <PrivateRoute exact path="/customerstables/stoneedge/delete" component={DeleteStoneEdge} />
        {/* STONE MODELS */}
        <PrivateRoute exact path="/customerstables/stonemodel" component={StoneModels} />
        <PrivateRoute
          exact
          path="/customerstables/stonemodel/create"
          component={CreateStoneModel}
        />
        <PrivateRoute
          exact
          path="/customerstables/stonemodel/delete"
          component={DeleteStoneModel}
        />
        <PrivateRoute
          exact
          path="/customerstables/stonemodel/edit/:stonemodel"
          component={EditStoneModel}
        />
        <PrivateRoute path="*" component={ComponenteDePagina404} />
      </Switch>
    </Auth>
  );
}
