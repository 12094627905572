import React, { Fragment } from "react";

import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";

import "./styles.css";

export default function Layout({ children, iam, isAuthenticated }) {
  return (
    <Fragment>
      <Header isAuthenticated={isAuthenticated} isFixed={true} />
      <Content>{children}</Content>
      <Footer />
    </Fragment>
  );
}
