import { useState } from "react";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  CursorPaging,
  ScannerFilter,
  Scanners,
  ScannerSort,
  Scanners_scanners_edges_node,
} from "../../data/graphQLModel";
import { QUERY_SCANNERS } from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { EntityAutocompleteProps } from "./types";
import { getDefaultValueOfAutocompleteFirstQuery } from "./utils";

/**
 * ## Reusable Scanner Autocomplete Component
 *
 * ### Default Autocomplete property values:
 *
 * #### name: scan
 * #### label: Scanner
 * #### continueToLoadResultsInComponent: true
 * #### shouldFieldBeLocked: false
 *
 */
const ScannerAutocomplete = ({
  errors,
  continueToLoadResultsInComponent = true,
  shouldFieldBeLocked = false,
  helperText,
  value,
  onChange,
  selectItemOnFirstQueryIfSoloRecord,
  filterToSelectDefaultItemForFirstQueryResults,
  ...props
}: EntityAutocompleteProps) => {
  const [scans, setScans] = useState<Scanners_scanners_edges_node[]>([]);
  const [firstQueryDone, setFirstQueryDone] = useState<boolean>(false);

  return (
    <AutocompleteWithQuery
      items={scans}
      onChange={onChange}
      value={value}
      label="Scans"
      name="scan"
      title="Scanner"
      TextFieldProps={{
        error: !!errors,
        helperText: helperText,
        label: "Scanner",
        disabled: shouldFieldBeLocked,
      }}
      onTextChange={async (value: string) => {
        await queryValuesForFilter<ScannerFilter, CursorPaging, ScannerSort, Scanners>(
          value,
          QUERY_SCANNERS,
          (res) => {
            let endRes = res?.data?.scanners?.edges?.map((el) => el.node);
            if (!firstQueryDone) {
              setFirstQueryDone(true);
              if (!!endRes && endRes.length === 1 && !!selectItemOnFirstQueryIfSoloRecord) {
                onChange({
                  target: {
                    value: endRes[0].id,
                    name: "scan",
                  },
                });
              } else if (
                !!filterToSelectDefaultItemForFirstQueryResults &&
                !!endRes &&
                endRes.length > 1
              ) {
                let defaultItem = getDefaultValueOfAutocompleteFirstQuery(
                  endRes,
                  filterToSelectDefaultItemForFirstQueryResults
                );
                !!defaultItem &&
                  onChange({
                    target: {
                      value: defaultItem.id,
                      name: "scan",
                    },
                  });
              }
            }
            setScans(endRes);
          },
          {
            filter: {
              or: [{ code: { iLike: value } }, { description: { iLike: value } }],
            },
            paging: {
              first: 30,
            },
          }
        );
      }}
      getField={(node: Scanners_scanners_edges_node) => {
        return `${node.code} - ${node.description}`;
      }}
      {...props}
      AutocompleteProps={{
        disabled: shouldFieldBeLocked,
        ...props?.AutocompleteProps,
      }}
    />
  );
};

export default ScannerAutocomplete;
