import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { Box, Card, CardContent, Container, Grid, Skeleton, Typography } from "@material-ui/core";
import swalert from "sweetalert";
import { MuiThemeProps } from "../../theme/theme";
import { loadingComponent, notLoadingComponent } from "../../actions/isLoadingActions";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

interface EditEntityFormProps {
  mutation: ([...args]: any) => Promise<void>;
  entityForm: { [key: string]: any };
  currentData: { [key: string]: any };
  entityName: string;
  path: string;
  requiredFields: string[];
  isLoadingRecord: boolean | undefined;
}

export default function EditEntityForm({
  mutation,
  entityForm,
  entityName,
  path,
  requiredFields,
  isLoadingRecord = true,
  currentData,
}: EditEntityFormProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setForm] = useState(entityForm);

  useEffect(() => {
    setForm(entityForm);
  }, [entityForm]);

  function changeform(e: any) {
    const { name, value } = e.target;
    let finalValue = !!value ? value : null;
    setForm({ ...form, [name]: finalValue });
  }

  async function submitForm(e: any) {
    e.preventDefault();

    dispatch(loadingComponent());

    try {
      await mutation(form).then((res) => {
        swalert("Success.", `${entityName} created with success`, "success");
      });
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert("Failed.", error, "error");
      return;
    }
    dispatch(notLoadingComponent());
    history.push(path);
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push(path);
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>Edit {entityName}</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {Object.keys(currentData)
                  .filter((el) => !!!Object.keys(entityForm).find((tochange) => tochange === el))
                  .map((el) => {
                    return (
                      <FormControl fullWidth className={classes.marginCode} variant="outlined">
                        {!isLoadingRecord ? (
                          <TextField
                            sx={{
                              height: el === "description" ? 100 : "auto",
                            }}
                            id={el}
                            label={el}
                            name={el}
                            placeholder={el}
                            disabled
                            focused
                            onChange={changeform}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={currentData?.[el]}
                            variant="outlined"
                          />
                        ) : (
                          <TextField>
                            <Skeleton />
                          </TextField>
                        )}
                      </FormControl>
                    );
                  })}
                {Object.keys(entityForm).map((el) => {
                  return (
                    <FormControl fullWidth className={classes.marginCode} variant="outlined">
                      {!isLoadingRecord ? (
                        <TextField
                          sx={{
                            height: el === "description" ? 100 : "auto",
                          }}
                          multiline={el === "description"}
                          rows={el === "description" ? 3 : 1}
                          id={el}
                          label={el}
                          name={el}
                          placeholder={el}
                          required={
                            !!requiredFields ? !!requiredFields?.find((key) => el === key) : true
                          }
                          focused
                          onChange={changeform}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          value={form?.[el] || ""}
                        />
                      ) : (
                        <TextField>
                          <Skeleton />
                        </TextField>
                      )}
                    </FormControl>
                  );
                })}
              </Box>
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      disabled={
                        !!Object.keys(form)?.find((key) => !!!form?.[key] || form?.[key] === "")
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Save
                      <Save style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
