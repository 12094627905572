import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import Budget from '../../components/dashboards/Budget';
import LatestOrders from '../../components/dashboards/LatestOrders';
// import LatestProducts from '../../components/dashboards/LatestProducts';
import Sales from '../../components/dashboards/Sales';
import TasksProgress from '../../components/dashboards/TasksProgress';
import TotalCustomers from '../../components/dashboards/TotalCustomers';
import TotalProfit from '../../components/dashboards/TotalProfit';
import TrafficByDevice from '../../components/dashboards/TrafficByDevice';

export default function Dashboard() {
  return(
        <>
          <Helmet>
            <title>Dashboard | We Scan Home</title>
          </Helmet>
          <div>
            <Box style={{ backgroundColor: 'background.default', minHeight: '100%', py: 3, marginTop:'70px' }} >
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Budget />
                  </Grid>
                  <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <TotalCustomers />
                  </Grid>
                  <Grid item lg={3} sm={6} xl={3} xs={12} >
                    <TasksProgress />
                  </Grid>
                  <Grid item lg={3} sm={6} xl={3} xs={12} >
                    <TotalProfit style={{ height: '100%' }} />
                  </Grid>
                  <Grid item lg={8} md={12} xl={9} xs={12} >
                    <Sales />
                    <Box style={{ marginTop: '20px' }}>
                      <LatestOrders />
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} xl={3} xs={12} >
                    <TrafficByDevice style={{ height: '100%' }} />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </div>
        </>
      )
};
