import { useState } from "react";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  CursorPaging,
  StoneEdgeFilter,
  StoneEdges,
  StoneEdgeSort,
  StoneEdges_stoneEdges_edges_node,
} from "../../data/graphQLModel";
import { QUERY_STONE_EDGES } from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { EntityAutocompleteProps } from "./types";

/**
 * ## Reusable Stone Edge Autocomplete Component
 *
 * ### Default Autocomplete property values:
 *
 * #### name: stone_edge
 * #### label: Edges
 * #### continueToLoadResultsInComponent: true
 * #### shouldFieldBeLocked: false
 *
 */
const StoneEdgeAutocomplete = ({
  errors,
  continueToLoadResultsInComponent = true,
  shouldFieldBeLocked = false,
  helperText,
  value,
  onChange,
  ...props
}: EntityAutocompleteProps) => {
  const [stoneEdges, setStoneEdges] = useState<StoneEdges_stoneEdges_edges_node[]>([]);

  return (
    <AutocompleteWithQuery
      onChange={onChange}
      value={value}
      items={stoneEdges}
      label="Edges"
      name="stone_edge"
      title="Edges"
      TextFieldProps={{
        error: !!errors,
        helperText: helperText,
        label: "Edges",
        disabled: shouldFieldBeLocked,
      }}
      onTextChange={async (value: string) => {
        await queryValuesForFilter<StoneEdgeFilter, CursorPaging, StoneEdgeSort, StoneEdges>(
          value,
          QUERY_STONE_EDGES,
          (res) => {
            let endRes = res?.data?.stoneEdges?.edges?.map((el) => el.node);
            setStoneEdges(endRes);
          },
          {
            filter: {
              or: [
                { code: { iLike: value } },
                { name: { iLike: value } },
                { description: { iLike: value } },
              ],
            },
            paging: { first: 40 },
          }
        );
      }}
      getField={(node: StoneEdges_stoneEdges_edges_node) => {
        return `${node.code} - ${node.name}`;
      }}
      {...props}
      AutocompleteProps={{
        disabled: shouldFieldBeLocked,
        ...props?.AutocompleteProps,
      }}
    />
  );
};

export default StoneEdgeAutocomplete;
