import { Button, Divider, Typography } from "@material-ui/core";
import { Cancel, CloudUpload } from "@material-ui/icons";
import { Box } from "@material-ui/system";
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { grey } from "../../theme/themeColors";

export interface DropZoneImageUploadProps {
  onUpload: (file: File[]) => Promise<void>;
  refresh?: boolean;
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropZoneImageUpload: React.FC<DropZoneImageUploadProps> = ({
  onUpload,
  refresh,
  setRefresh,
}) => {
  const [files, setFiles] = React.useState<File[]>();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    multiple: true,
    accept: ".jpeg,.jpg,.png,.gif,.webp,.tiff",
    maxFiles: 5,
  });

  const onImageRemove = () => {
    setFiles(undefined);
  };

  useEffect(() => {
    if (refresh) {
      onImageRemove();
      setRefresh && setRefresh(false);
    }
  }, [refresh]);

  const isImported = () => {
    if (acceptedFiles && files && files.length > 0) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
          border="1px dashed"
          borderColor="grey.400"
          borderRadius="10px"
          bgcolor={isDragActive ? "grey.200" : "none"}
          sx={{
            transition: "all 250ms ease-in-out",
            outline: "none",
            p: 2,
          }}
        >
          <Divider sx={{ width: "200px", mx: "auto" }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {files &&
              files.length > 0 &&
              files.map((image, ind) => {
                const objectUrl = URL.createObjectURL(image);
                return (
                  <Box
                    height={128}
                    width={128}
                    minWidth={128}
                    bgcolor="white"
                    borderRadius="10px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="1px solid"
                    borderColor={grey[300]}
                    style={{ cursor: "pointer" }}
                    ml={ind === 0 ? "auto" : 0}
                    mr={ind === files.length - 1 ? "auto" : "10px"}
                    key={ind + ""}
                  >
                    {/* @ts-ignore */}
                    <img src={objectUrl} style={{ width: 100, height: "auto", maxHeight: 120 }} />
                  </Box>
                );
              })}
          </Box>
          <input {...getInputProps()} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              color="secondary"
              type="button"
              sx={{
                bgcolor: "primary.light",
                px: "2rem",
                mt: "22px",
                width: "250px",
                mr: 2,
              }}
              onClick={() => onUpload(files)}
              startIcon={<CloudUpload />}
            >
              Upload
            </Button>
            <Button
              color="primary"
              type="button"
              sx={{
                bgcolor: "primary.light",
                px: "2rem",
                mt: "22px",
                width: "250px",
              }}
              onClick={() => onImageRemove()}
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
          border="1px dashed"
          borderColor="grey.400"
          borderRadius="10px"
          bgcolor={isDragActive ? "grey.200" : "none"}
          sx={{
            transition: "all 250ms ease-in-out",
            outline: "none",
            p: 2,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Typography variant="h5" mb={2} color="grey.600">
            Drag & drop Image
          </Typography>

          <Divider sx={{ width: "200px", mx: "auto" }} />

          <Typography
            color="grey.600"
            bgcolor={isDragActive ? "grey.200" : "background.paper"}
            lineHeight="1"
            px={2}
            mt={-1.25}
            mb={2}
          >
            on
          </Typography>

          <Button
            color="primary"
            type="button"
            sx={{
              bgcolor: "primary.light",
              px: "2rem",
              mb: "22px",
            }}
          >
            Select files
          </Button>

          <Typography color="grey.600">Upload limit 500mb</Typography>
        </Box>
      );
    }
  };
  return isImported();
};

export default DropZoneImageUpload;
