import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import TablePageHeaderComponent from "../../components/Table/TablePageHeaderComponent";
import SlabsTable from "../../components/slabs.components/SlabsTable";
import {
  getSlabRowValuesForBlockDetails,
  slabHeadCellsForBlockDetails,
} from "../../components/Blocks/BlockDetailsSlabTableFields";

export default function SlabsList() {
  return (
    <>
      <Helmet>
        <title>Slabs | WeScan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container>
          <TablePageHeaderComponent
            buttonTitle={"Upload"}
            entityNamePlural={"Products"}
            pathEntity={"/slabmanagement"}
          />
          <Box>
            <SlabsTable
              showHeader={false}
              filter={{}}
              tableProps={{
                headCells: slabHeadCellsForBlockDetails,
                getValues: getSlabRowValuesForBlockDetails,
                disableSelection: false,
                paperProps: {
                  style: {
                    height: "100%",
                  },
                },
                disableSelectAll: true,
                disableMultipleSelection: false,
                base64OrDataOrIdOnDelete: "Data",
                disableRemove: false,
                disableDetails: false,
                onClickRow: undefined,
                shouldToolTipShowForMoreThanOne: true,
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
}
