import React, { useEffect, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import swalert from "sweetalert";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { loadingComponent, notLoadingComponent } from "../../actions/isLoadingActions";
import { Box, Card, CardContent, Grid, IconButton, Typography } from "@material-ui/core";
import { Check, NotInterested } from "@material-ui/icons";
import "../../layout/Content/index";
import { ERPDataInfoCard } from "../../components/ERPInfoCards/SAGEX3InfoCard";
import { TwinStonesComponent } from "../../components/slabs.components/TwinStone";
import { MuiThemeProps } from "../../theme/theme";
import { SlabDetailsComponent } from "../../components/slabs.components/SlabDetailsComponent";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  DeleteImage,
  DeleteImageVariables,
  Slab,
  SlabVariables,
  Slab_slab,
  UpdateOneSlab,
  UpdateOneSlabVariables,
  UploadImagesMutation,
  UploadImagesMutationVariables,
} from "../../data/graphQLModel";
import {
  MUTATION_DELETE_IMAGE,
  MUTATION_UPDATE_ONE_SLAB,
  MUTATION_UPLOAD_IMAGES,
  SLAB_BY_ID,
} from "../../data/graphQLQueries";
import DropZoneImageUpload from "../../components/ImageUpload/DropZoneImageUpload";
import { file2Base64 } from "../../utils/utils";
import toast from "react-hot-toast";
import getKeyValueMetadata from "../../utils/getKeyValueMetadata";
import { H2 } from "../../components/GeneralComponents/Typography";

const LOADING_TOAST = "LOADING_TOAST";
const UPLOADING_TOAST = "UPLOADING_TOAST";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  first: {
    width: "90%",
    marginBottom: "3ch",
  },
  second: {
    width: "100%",
    marginBottom: "3ch",
  },
  margin: {
    marginBottom: "3ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
  headerMatType: {
    fontSize: 20,
    fontWeight: "lighter",
    marginRight: 10,
  },
  headerVariety: {
    fontWeight: "bold",
    fontSize: 20,
  },
  descriptionDefault: {
    color: theme.palette.grey[600],
    fontSize: 14,
    marginBottom: "3px",
  },
  aboutHeader: {
    fontSize: 18,
  },
  aboutText: {
    color: theme.palette.grey[600],
    fontSize: 14,
  },
}));

export default function DetailsSlab() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [refreshImages, setRefreshImages] = useState<boolean>(false);

  let { slbs } = useParams() as { slbs: string };

  const validateSlab = () => {
    if (slbs === undefined || slbs === "") {
      history.push("/slabmanagement");
      return;
    }
  };

  useEffect(() => {
    validateSlab();
  }, []);

  const [mutationUploadImages] = useMutation<UploadImagesMutation, UploadImagesMutationVariables>(
    MUTATION_UPLOAD_IMAGES
  );
  const [mutationDeleteImage] = useMutation<DeleteImage, DeleteImageVariables>(
    MUTATION_DELETE_IMAGE
  );
  const [mutationUpdateSlab] = useMutation<UpdateOneSlab, UpdateOneSlabVariables>(
    MUTATION_UPDATE_ONE_SLAB
  );
  const { loading, error, data, refetch } = useQuery<Slab, SlabVariables>(SLAB_BY_ID, {
    variables: { id: atob(slbs) },
  });

  useEffect(() => {
    if (loading) {
      toast.loading("Loading...", { id: LOADING_TOAST });
    } else {
      toast.dismiss(LOADING_TOAST);
    }
  }, [loading]);

  if (error) {
    swalert("Error", error.toString(), "error");
    return <></>;
  }

  function handleGoBack(event: any) {
    event.preventDefault();
    history.goBack();
  }

  // const handleStockMovement = async (e: any, slab: Slab_slab) => {
  //   e.preventDefault();
  //   swalert({
  //     text: "Observation",
  //     //@ts-ignore
  //     content: "input",
  //     button: {
  //       text: "Send",
  //       closeModal: false,
  //     },
  //   }).then((obs) => {
  //     if (obs) {
  //       let opt = slab.inStock ? "SHIPPED" : "RECEIVED";
  //       confirmStockMovement(e, obs, opt, slab);
  //     }
  //   });
  // };

  // async function confirmStockMovement(e: any, obs: any, mov: any, slab: Slab_slab) {
  //   e.preventDefault();

  //   swalert({
  //     title: "Product Stock Movement",
  //     text: "Are you sure?",
  //     icon: "warning",
  //     //@ts-ignore
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       submitStockMovement(e, obs, mov, slab);
  //     }
  //   });
  // }

  // async function submitStockMovement(e: any, obs: any, mov: any, slab: Slab_slab) {
  //   e.preventDefault();

  //   dispatch(loadingComponent());
  //   try {
  //     //GUILHERME
  //     let messageMovStock = "";
  //     if (slab.inStock) {
  //       messageMovStock = await movStockServices.createManyMovStock([slab], mov, obs);
  //     } else {
  //       messageMovStock = await movStockServices.deleteMovStockProduct(slab.id);
  //     }
  //     swalert("Success.", messageMovStock, "success");
  //   } catch (error: any) {
  //     dispatch(notLoadingComponent());
  //     swalert("Failed.", error, "error");
  //     return;
  //   }
  //   dispatch(notLoadingComponent());
  // }

  const handleUploadImages = async (images: File[]) => {
    if (data?.slab?.id) {
      let image_base64_fileextension: {
        strfile: string;
        strfiletype: string;
        metadata: string;
      }[] = [];
      for (let index = 0; index < images.length; index++) {
        const element = images[index];
        const metadata = await getKeyValueMetadata(element);
        const base = await file2Base64(element);
        image_base64_fileextension.push({
          strfile: base.substring(base.indexOf(",") + 1),
          strfiletype: base.substring(base.indexOf("/") + 1, base.indexOf(";")),
          metadata: JSON.stringify(metadata),
        });
      }
      toast.loading("Loading...", { id: UPLOADING_TOAST });
      await mutationUploadImages({
        variables: {
          input: {
            images: image_base64_fileextension.map((img) => {
              return {
                id: data?.slab?.id ? data.slab.id : "",
                strfile: img.strfile,
                strfiletype: img.strfiletype,
                metadata: img.metadata,
              };
            }),
          },
        },
      })
        .then(async (res) => {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          if (!res?.errors) {
            swalert("Success", "", "success");
          } else {
            swalert("Upload Error", "", "error");
          }
        })
        .catch((err) => {
          swalert("Upload Error", err.toString(), "error");
        });
      setRefreshImages(true);
      toast.dismiss(UPLOADING_TOAST);
      refetch();
    }
  };

  const onDelete = async (index: number) => {
    swalert({
      title: "Delete Image",
      text: "Are you sure?",
      icon: "warning",
      // @ts-ignore
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await handleDeleteImage(index);
      }
    });
  };

  const handleDeleteImage = async (index: number) => {
    if (data?.slab?.id) {
      dispatch(loadingComponent());
      await mutationDeleteImage({
        variables: {
          input: {
            delete: {
              id: data?.slab?.id,
              image: data?.slab?.slabImages?.[index]?.original,
            },
          },
        },
      })
        .then((res) => {
          // console.log(res)
          if (res.data?.deleteImage.response.isSuccessful) {
            swalert("Deleted Image", "", "success");
          } else {
            let error_message = res.data?.deleteImage.error
              ? res.data?.deleteImage.error.message
              : "";
            swalert("Delete Error", error_message, "error");
          }
        })
        .catch((err) => {
          swalert("Delete Error", err.toString(), "error");
        });
      dispatch(notLoadingComponent());
      refetch();
    }
  };

  const onMarkAsPrimary = async (index: number) => {
    swalert({
      title: "Primary Image",
      text: "Are you sure?",
      icon: "warning",
      // @ts-ignore
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await handleMarkAsPrimary(index);
      }
    });
  };

  const handleMarkAsPrimary = async (index: number) => {
    if (data?.slab?.id) {
      dispatch(loadingComponent());
      await mutationUpdateSlab({
        variables: {
          input: {
            id: data?.slab?.id,
            update: {
              mainImage: data?.slab?.slabImages?.[index]?.azureBlobID,
            },
          },
        },
      })
        .then((res) => {
          if (!res?.errors) {
            swalert("Updated Main Image", "", "success");
          } else {
            let error_message = res?.errors;
            swalert("Failed to Update Main Image", error_message.toString(), "error");
          }
        })
        .catch((err) => {
          swalert("Update Main Image Error", err.toString(), "error");
        });
      dispatch(notLoadingComponent());
      refetch();
    }
  };

  // ########################################## END STOCK MOVEMENT ##########################################
  return (
    <div>
      {!loading && data && data.slab && (
        <>
          <Box style={{ marginBottom: "10px" }}>
            <Card sx={{ mt: 8 }}>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl variant="outlined">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      type="submit"
                      onClick={handleGoBack}
                      disabled={history?.length <= 1}
                      color="primary"
                      sx={{ mr: 1 }}
                    >
                      <ArrowBackIosIcon fontSize="large" />
                    </IconButton>
                    <H2>{data.slab.code}</H2>
                  </Box>
                </FormControl>
                {data.slab.inStock && (
                  <Box style={{ alignItems: "center" }}>
                    <Check
                      style={{
                        color: "green",
                        width: 30,
                        height: 30,
                        marginLeft: 15,
                      }}
                    />
                    <Typography>In Stock</Typography>
                  </Box>
                )}
                {!data.slab.inStock && (
                  <Box style={{ alignItems: "center" }}>
                    <NotInterested
                      style={{
                        color: "red",
                        width: 30,
                        height: 30,
                        marginLeft: 15,
                      }}
                    />
                    <Typography>No Stock</Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>
          <hr />
          <Card>
            <CardContent>
              <form className={classes.form}>
                {data && data.slab && (
                  <>
                    {/* <Box
                      style={{
                        display: "flex",
                        flexBasis: 3,
                        margin: 20,
                      }}
                    >
                      <h4 style={{ flex: 2 }}>Stock Actions:</h4>
                      <Button
                        onClick={(e) => handleStockMovement(e, data.slab as Slab_slab)}
                        color="primary"
                        variant="contained"
                        style={{ position: "relative" }}
                      >
                        {data.slab.inStock ? "Remove From Stock" : "Undo Stock Movement"}
                      </Button>
                    </Box> */}
                    <SlabDetailsComponent
                      refetch={setRefreshImages}
                      slab={data.slab}
                      onDelete={onDelete}
                      onMarkPrimary={onMarkAsPrimary}
                    />
                    <Box sx={{ p: 1 }}>
                      <DropZoneImageUpload
                        refresh={refreshImages}
                        setRefresh={setRefreshImages}
                        onUpload={handleUploadImages}
                      />
                    </Box>
                    <hr />
                    {data.slab.id && <TwinStonesComponent slab_id={data.slab.id} />}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid style={{ flex: 1 }}>
                        <Typography
                          style={{
                            marginTop: "10px",
                            marginBottom: "5px",
                          }}
                          className={classes.aboutHeader}
                        >
                          ABOUT{" "}
                          {data.slab?.commercial_variety ? data.slab?.commercial_variety.name : ""}
                        </Typography>
                        <Typography
                          style={{
                            marginBottom: "10px",
                          }}
                          className={classes.aboutText}
                        >
                          {data.slab?.commercial_variety?.description
                            ? data.slab?.commercial_variety.description
                            : "No Description"}
                        </Typography>
                      </Grid>
                    </Box>
                  </>
                )}
                <div className="btnform">
                  <div>
                    <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                      <Button
                        type="submit"
                        onClick={handleGoBack}
                        fullWidth
                        color="primary"
                        variant="contained"
                        // className={classes.submit}
                        style={{
                          height: "50px",
                          width: "200px",
                          fontSize: "20px",
                        }}
                      >
                        <ArrowBackIosIcon />
                        Back
                      </Button>
                    </FormControl>
                  </div>
                </div>
              </form>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
}
