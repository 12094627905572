import React, { useState, Fragment, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import IconButton from "@material-ui/core/IconButton";
import { NavLink, useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Dashboard,
  Person,
  ExitToApp,
  Label,
  Apps,
  Bookmarks,
  Bookmark,
  BurstMode,
  PeopleAlt,
  CollectionsBookmark,
  LabelImportant,
  BusinessCenter,
  Description,
  DevicesOther,
  Reorder,
  Settings,
  SearchOutlined,
} from "@material-ui/icons";
import ListSubheader from "@material-ui/core/ListSubheader";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Avatar, Typography, Box, Switch, FormControlLabel } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { changeStockFilter, signOut } from "../../actions/accountActions";
import { IRolesEnum, User, UserVariables } from "../../data/graphQLModel";
import { UserToken } from "../../utils/Types";
import { MuiThemeProps } from "../../theme/theme";
import { useQuery } from "@apollo/react-hooks";
import { USER_BY_ID } from "../../data/graphQLQueries";

const useStyles = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  root: {
    width: "100%",
    maxWidth: 350,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
}));

export default function DrawerManagement({ user }: { user?: UserToken }) {
  const classes = useStyles();
  const [openIotMenu, setOpenIotMenu] = useState(false);
  const [openSmartStoneMenu, setOpenSmartStoneMenu] = useState(false);
  const [opensubmenu, setOpenSubMenu] = useState(false);
  const [opensubmprocess, setOpenSubMenuProcess] = useState(false);
  const [opensubmcustomestables, setOpenSubmCustomesTables] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [state, setState] = useState(false);

  const { data } = useQuery<User, UserVariables>(USER_BY_ID, {
    variables: {
      id: user?.id as string,
    },
    skip: !!!user?.id,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector((state: any) => state.account.filter_stock);

  const handleClickIotMenu = () => {
    setOpenIotMenu(!openIotMenu);
  };

  const handleClickSmartStoneMenu = () => {
    setOpenSmartStoneMenu(!openSmartStoneMenu);
  };

  const handleClick = () => {
    setOpenSubMenu(!opensubmenu);
  };

  const handleClickSlabProcess = () => {
    setOpenSubMenuProcess(!opensubmprocess);
  };

  const handleClickCUstomersTable = () => {
    setOpenSubmCustomesTables(!opensubmcustomestables);
  };

  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
  };

  const setLocalStorageSettings = (filterStock: boolean) => {
    dispatch(changeStockFilter(filterStock));
  };

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState(open);
  };

  const handleSignOut = () => {
    toggleDrawer(false);
    dispatch(signOut());
  };
  return (
    <div>
      <Fragment>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
        >
          <NavLink to="#" activeClassName="active" className="nav-link">
            <MenuIcon />
          </NavLink>
        </IconButton>
        <Drawer anchor={"left"} open={state} onClose={toggleDrawer(false)}>
          <div role="presentation">
            <List
              component="nav"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  style={{
                    backgroundColor: "lightgray",
                    height: "75px",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  <h4>Management</h4>
                </ListSubheader>
              }
            ></List>
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                marginBottom: "8px",
              }}
            >
              <Avatar
                component={NavLink}
                src={user?.avatar}
                onClick={toggleDrawer(false)}
                style={{
                  width: "80px",
                  cursor: "pointer",
                  height: 80,
                }}
                to="/account"
              />
              <Typography color="textPrimary" variant="h6">
                {user?.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user?.username}
              </Typography>
            </Box>
            <List className={classes.root}>
              <Divider />
              {/* <ListItem
								button
								component={NavLink}
								to="/dashboard"
								onClick={toggleDrawer(false)}
								onKeyDown={toggleDrawer(false)}
							>
								<ListItemIcon>
									<Dashboard color={"primary"} />
								</ListItemIcon>
								<ListItemText primary="DashBoards" />
							</ListItem> */}
              <ListItem
                button
                component={NavLink}
                to="/account"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <ListItemIcon>
                  <Person color={"primary"} />
                </ListItemIcon>
                <ListItemText primary="User Account" />
              </ListItem>
              {data?.user?.role === IRolesEnum.superadmin ||
              data?.user?.role === IRolesEnum.partneradmin ? (
                <ListItem
                  button
                  component={NavLink}
                  to="/admin"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <ListItemIcon>
                    <BusinessCenter color={"secondary"} />
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                </ListItem>
              ) : null}
              <Divider />
              {/* Iot START */}
              {data?.user ? (
                <>
                  {data?.user?.role === IRolesEnum.partneradmin ||
                    (data?.user?.role === IRolesEnum.superadmin && (
                      <ListItem button onClick={handleClickIotMenu}>
                        <ListItemIcon>
                          <DevicesOther color={"secondary"} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Internet of Things"
                          style={{ marginRight: "35px" }}
                        />
                        {openIotMenu ? (
                          <ExpandLess style={{ marginRight: "10px" }} />
                        ) : (
                          <ExpandMore style={{ marginRight: "10px" }} />
                        )}
                      </ListItem>
                    ))}
                  <Collapse in={openIotMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        component={NavLink}
                        to="/sensorlogs"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                      >
                        <ListItemIcon>
                          <Description color={"primary"} />
                        </ListItemIcon>
                        <ListItemText primary="Sensor Logs" />
                      </ListItem>
                    </List>
                  </Collapse>
                  {data?.user?.role === IRolesEnum.partneradmin ||
                    (data?.user?.role === IRolesEnum.superadmin && (
                      <ListItem button onClick={handleClickSmartStoneMenu}>
                        <ListItemIcon>
                          <SearchOutlined color={"secondary"} />
                        </ListItemIcon>
                        <ListItemText primary="SmartStone" style={{ marginRight: "35px" }} />
                        {openSmartStoneMenu ? (
                          <ExpandLess style={{ marginRight: "10px" }} />
                        ) : (
                          <ExpandMore style={{ marginRight: "10px" }} />
                        )}
                      </ListItem>
                    ))}
                  <Collapse in={openSmartStoneMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        component={NavLink}
                        to="/envfactor"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                      >
                        <ListItemIcon>
                          <Description color={"primary"} />
                        </ListItemIcon>
                        <ListItemText primary="Environmental Factors" />
                      </ListItem>
                      <ListItem
                        button
                        className={classes.nested}
                        component={NavLink}
                        to="/applicationtypes"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                      >
                        <ListItemIcon>
                          <Description color={"primary"} />
                        </ListItemIcon>
                        <ListItemText primary="Application Types" />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              ) : null}
              {/* Iot END */}
              <ListItem button onClick={handleClick}>
                <ListItemIcon>
                  <Apps color={"secondary"} />
                </ListItemIcon>
                <ListItemText primary="Master Tables" style={{ marginRight: "35px" }} />
                {opensubmenu ? (
                  <ExpandLess style={{ marginRight: "10px" }} />
                ) : (
                  <ExpandMore style={{ marginRight: "10px" }} />
                )}
              </ListItem>
              <Collapse in={opensubmenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={NavLink}
                    to="/category"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <Label color={"primary"} />
                    </ListItemIcon>
                    <ListItemText primary="Category" />
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to="/material"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <Label color={"primary"} />
                    </ListItemIcon>
                    <ListItemText primary="Type Material" />
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to="/variety"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <Label color={"primary"} />
                    </ListItemIcon>
                    <ListItemText primary="Variety" />
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to="/finish"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <Label color={"primary"} />
                    </ListItemIcon>
                    <ListItemText primary="Finishing" />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem button onClick={handleClickSlabProcess}>
                <ListItemIcon>
                  <Bookmarks color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Fabricator Tables" style={{ marginRight: "35px" }} />
                {opensubmprocess ? (
                  <ExpandLess style={{ marginRight: "10px" }} />
                ) : (
                  <ExpandMore style={{ marginRight: "10px" }} />
                )}
              </ListItem>
              <Collapse in={opensubmprocess} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {/* <ListItem
                    component={NavLink}
                    to="/customers"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <PeopleAlt color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Customers" />
                  </ListItem> */}
                  <ListItem
                    component={NavLink}
                    to="/customerstables/projects"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <LabelImportant color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Projects" />
                  </ListItem>
                  {/* <ListItem
                    component={NavLink}
                    to="/customerstables/subprojects"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <LabelImportant color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="SubProjects" />
                  </ListItem> */}
                  <ListItem
                    component={NavLink}
                    to="/customerstables/packages"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <LabelImportant color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Packages" />
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to="/customerstables/packagetypes"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <LabelImportant color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="PackageTypes" />
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to="/customerstables/commvarieties"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <LabelImportant color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Commercial Varieties" />
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to="/customerstables/commfinishings"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <LabelImportant color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Commercial Finishings" />
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to="/customerstables/stoneedge"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <LabelImportant color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Stone Edges" />
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to="/customerstables/stonemodel"
                    button
                    className={classes.nested}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <LabelImportant color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Stone Models" />
                  </ListItem>
                </List>
              </Collapse>
            </List>
            <ListItem button onClick={handleClickCUstomersTable}>
              <ListItemIcon>
                <CollectionsBookmark color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Fabricator Management" style={{ marginRight: "35px" }} />
              {opensubmcustomestables ? (
                <ExpandLess style={{ marginRight: "10px" }} />
              ) : (
                <ExpandMore style={{ marginRight: "10px" }} />
              )}
            </ListItem>
            <Collapse in={opensubmcustomestables} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={NavLink}
                  to="/slabmanagement"
                  button
                  className={classes.nested}
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <ListItemIcon>
                    <BurstMode color={"primary"} />
                  </ListItemIcon>
                  <ListItemText primary="Products" />
                </ListItem>
                <ListItem
                  component={NavLink}
                  to="/slabsprocess/jobs"
                  button
                  className={classes.nested}
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <ListItemIcon>
                    <Bookmark color={"primary"} />
                  </ListItemIcon>
                  <ListItemText primary="Jobs" />
                </ListItem>
                <ListItem
                  component={NavLink}
                  to="/slabsprocess/blocks"
                  button
                  className={classes.nested}
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <ListItemIcon>
                    <Bookmark color={"primary"} />
                  </ListItemIcon>
                  <ListItemText primary="Blocks" />
                </ListItem>
              </List>
            </Collapse>
            {/* <Divider/>
                    <List>
                        <ListItem component={NavLink} to="/customers" button className={classes.nested} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                            <ListItemIcon>
                                <PeopleAlt />
                            </ListItemIcon>
                            <ListItemText primary="Customers"/>
                        </ListItem>
                    </List> */}
            <Divider />
            <ListItem button onClick={() => handleClickSettings()}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Settings" style={{ marginRight: "35px" }} />
              {openSettings ? (
                <ExpandLess style={{ marginRight: "10px" }} />
              ) : (
                <ExpandMore style={{ marginRight: "10px" }} />
              )}
            </ListItem>
            <Collapse in={openSettings} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText
                    style={{
                      alignSelf: "center",
                      marginBottom: 15,
                    }}
                    disableTypography={false}
                    primary={<Typography variant="h6">View Stock Only</Typography>}
                  />
                  <FormControlLabel
                    style={{ alignSelf: "center" }}
                    control={
                      <Switch
                        checked={account}
                        onChange={() => setLocalStorageSettings(!account)}
                        color="secondary"
                        name="edit"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    }
                    // label="View Products in Stock Only"
                    labelPlacement="end"
                    label={<Typography variant="h6"></Typography>}
                  />
                </ListItem>
              </List>
            </Collapse>
            <List>
              <ListItem button onClick={handleSignOut}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary="LogOut" />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </Fragment>
    </div>
  );
}
