import { useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, LinearProgress } from "@material-ui/core";
import NewTablePageHeaderComponent from "../../../components/Table/NewTablePageHeaderComponent";
import {
  CommercialFinishingFilter,
  CommercialFinishings,
  CommercialFinishingSort,
  CommercialFinishingSortFields,
  CommercialFinishingsVariables,
  CommercialFinishings_commercialFinishings_edges_node,
  CursorPaging,
  SortDirection,
} from "../../../data/graphQLModel";
import { NetworkStatus, useQuery } from "@apollo/react-hooks";
import { QUERY_COMMERCIAL_FINISHINGS } from "../../../data/graphQLQueries";
import NewGeneralTableComponent, {
  ICompleteHeadCells,
} from "../../../components/Table/NewGeneralTableComponent";
import { onChangePageCursor } from "../../../utils/utils";
import { format } from "date-fns";

export const defaultCommercialFinishingsHeadCells = [
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "description.pt", numeric: false, disablePadding: false, label: "Description" },
  { id: "observation.pt", numeric: false, disablePadding: false, label: "Observation" },
  { id: "createdAt", numeric: false, disablePadding: false, label: "Date Create" },
];

export const getCommercialFinishingsRowDefaultValue = (
  row: CommercialFinishings_commercialFinishings_edges_node
): ICompleteHeadCells[] => {
  return [
    {
      id: row?.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    {
      id: row?.description.pt,
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: row.observation?.pt || "",
      numeric: false,
      disablePadding: false,
      label: "Observation",
    },
    {
      id: row?.createdAt ? format(new Date(row?.createdAt), "yyyy-MM-dd") : "",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
};

const DEFAULT_LIMIT = 10;
const DEFAULT_SORTING: CommercialFinishingSort[] = [
  { direction: SortDirection.DESC, field: CommercialFinishingSortFields.createdAt },
];
const DEFAULT_PAGING: CursorPaging = { first: DEFAULT_LIMIT };

export default function CommercialFinishingsList() {
  const [filter, setFilter] = useState<CommercialFinishingFilter>({});
  const [paging, setPaging] = useState<CursorPaging>(DEFAULT_PAGING);
  const [sorting, setSorting] = useState<CommercialFinishingSort[]>(DEFAULT_SORTING);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { loading, error, data, networkStatus } = useQuery<
    CommercialFinishings,
    CommercialFinishingsVariables
  >(QUERY_COMMERCIAL_FINISHINGS, {
    variables: {
      paging: paging,
      sorting: sorting,
      filter: filter,
    },
  });

  return (
    <>
      <Helmet>
        <title>Commercial Finishings | WeScan</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ mt: 10 }}>
        <NewTablePageHeaderComponent
          entityNamePlural={"Commercial Finishings"}
          pathEntity={"/customerstables/commfinishings"}
          gridProps={{ sx: { mb: 2, mt: 2 } }}
          buttonTitle={"New Commercial Finishing"}
        />
        <NewGeneralTableComponent
          data={
            !!data?.commercialFinishings
              ? data?.commercialFinishings?.edges?.map((edge) => edge.node)
              : []
          }
          loading={loading || networkStatus === NetworkStatus.fetchMore}
          error={error}
          LoadingComponent={() => (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          onChangePage={(e: any, page: number) => {
            onChangePageCursor(
              e,
              page,
              pageNumber,
              !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
              data?.commercialFinishings?.pageInfo || ({} as any),
              (pageNumber: number, newPaging: CursorPaging) => {
                setPaging(newPaging);
                setPageNumber(pageNumber);
              }
            );
          }}
          onRowsPerPageChange={(event) => {
            setPaging({ first: parseInt(event.target.value, 10) });
          }}
          page={pageNumber}
          rowsPerPage={!!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT}
          headCells={defaultCommercialFinishingsHeadCells}
          totalCount={data?.commercialFinishings?.totalCount || 0}
          handleSort={(field, order) => {
            setSorting([{ field: field as CommercialFinishingSortFields, direction: order }]);
          }}
          orderByField={sorting[0].field}
          orderDirection={sorting[0].direction}
          sortableFields={Object.values(CommercialFinishingSortFields)}
          key={"commfin"}
          getValues={getCommercialFinishingsRowDefaultValue}
          disableSelectAll
          disableMultipleSelection
          PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
          tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          editPath={"/customerstables/commfinishings/edit"}
          pathEntity={"/customerstables/commfinishings"}
          deletePath={"/customerstables/commfinishings/delete"}
          detailsPath={"/customerstables/commfinishings/edit"}
          base64OrDataOrIdOnDelete={"Data"}
        />
      </Container>
    </>
  );
}
