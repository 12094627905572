import {
  LOGIN_SUCESS,
  SILENT_SUCESS,
  SIGNOUT,
  CHANGE_FILTER,
  TOKENV2_STATE,
  SHAREABLE_AUTH,
} from "../actions/accountActions";

export const INITIAL_STATE = {
  user: null,
  filter_stock: localStorage.getItem("FILTER_STOCK") === "true" ? true : false,
  token: localStorage.getItem("accessTokenV2"),
};

const accountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCESS: {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case CHANGE_FILTER: {
      return {
        ...state,
        filter_stock: action.payload.filter_stock,
      };
    }
    case SILENT_SUCESS: {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case SIGNOUT: {
      return {
        ...state,
        user: null,
        token: null,
      };
    }
    case TOKENV2_STATE: {
      return {
        ...state,
        token: action.payload.tokenV2,
      };
    }
    case SHAREABLE_AUTH: {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    }
    default: {
      return state;
    }
  }
};

export default accountReducer;
