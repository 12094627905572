import { CardContent, IconButton, useTheme } from "@material-ui/core";
import { Check, NotInterested, OpenInNew } from "@material-ui/icons";
import { Box } from "@material-ui/system";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ImageBox from "../GeneralComponents/ImageBox";
import { H3, H4 } from "../GeneralComponents/Typography";
import {
  PackingsGallery_packings_edges_node_slabs_edges_node,
  ProjectGallery_projects_edges_node,
} from "../../data/graphQLModel";
import { getSlabImageSafe, openNewTab } from "../../utils/utils";
import FlexBox from "../GeneralComponents/FlexBox";

interface ProjectGalleryCardProps {
  project: ProjectGallery_projects_edges_node;
}

const ProjectGalleryCard = ({ project }: ProjectGalleryCardProps) => {
  const theme = useTheme();
  const history = useHistory();
  const [firstSlab, setFirstSlab] =
    useState<PackingsGallery_packings_edges_node_slabs_edges_node | null>(
      project?.slabs && project?.slabs?.edges?.length > 0 ? project?.slabs?.edges[0]?.node : null
    );
  const handleGetPackageDetails = () => {
    history.push({
      pathname: "/customerstables/projects/detailsproject/" + btoa(project?.id),
    });
  };

  return (
    <ImageBox
      sx={{
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        position: "relative",
      }}
      className="card"
      hoverEffect
    >
      <a
        href={"/customerstables/projects/detailsproject/" + btoa(project?.id)}
        onClick={handleGetPackageDetails}
      >
        <img
          src={
            !!firstSlab
              ? getSlabImageSafe(firstSlab, true) || "/images/imagemissing.png"
              : "/images/imagemissing.png"
          }
          alt={project.code}
        />
        <CardContent>
          <Box>
            <H3
              fontWeight="bold"
              fontSize={18}
              ellipsis
              mt={1}
              color="grey.800"
              textTransform="capitalize"
            >
              {project.code}
            </H3>
            <H3
              fontWeight="bold"
              fontSize={18}
              ellipsis
              color="grey.800"
              textTransform="capitalize"
            >
              {project.observation}
            </H3>
          </Box>
          <Box>
            <Box flexDirection="row" display="flex" justifyContent={"space-between"}>
              <H4>{project?.slabs?.totalCount || 0} Slabs</H4>
              <Box>
                {" "}
                {project.inStock ? (
                  <Check
                    style={{
                      color: "green",
                      width: 30,
                      height: 30,
                      marginLeft: 5,
                    }}
                  />
                ) : (
                  <NotInterested
                    style={{
                      color: "red",
                      width: 30,
                      height: 30,
                      marginLeft: 5,
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </a>
      <FlexBox sx={{ justifyContent: "flex-end" }}>
        <IconButton
          color="primary"
          onClick={() => {
            openNewTab("/customerstables/projects/detailsproject/" + btoa(project?.id));
          }}
        >
          <OpenInNew />
        </IconButton>
      </FlexBox>
    </ImageBox>
  );
};

export default ProjectGalleryCard;
