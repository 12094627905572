import { useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, LinearProgress } from "@material-ui/core";
import { IHeadCells } from "../../../components/Table/GeneralTableComponent";
import { NetworkStatus, useQuery } from "@apollo/react-hooks";
import {
  CursorPaging,
  SortDirection,
  Varieties,
  VarietiesVariables,
  Varieties_varieties_edges_node,
  VarietyFilter,
  VarietySort,
  VarietySortFields,
} from "../../../data/graphQLModel";
import { QUERY_VARIETIES } from "../../../data/graphQLQueries";
import NewTablePageHeaderComponent from "../../../components/Table/NewTablePageHeaderComponent";
import NewGeneralTableComponent from "../../../components/Table/NewGeneralTableComponent";
import { onChangePageCursor } from "../../../utils/utils";

const headCells = [
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "normativedesig", numeric: false, disablePadding: false, label: "Norm. Desig" },
  { id: "alternativedesig", numeric: false, disablePadding: false, label: "Alt. Desig" },
  { id: "lithologicalclass", numeric: false, disablePadding: false, label: "Lith. Class" },
  { id: "lithologicalsubclass", numeric: false, disablePadding: false, label: "Lith. SubClass" },
  { id: "districtquarry", numeric: false, disablePadding: false, label: "Quarry" },
];

const getRowValues = (row: Varieties_varieties_edges_node): IHeadCells[] => {
  return [
    {
      id: row.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    { id: row.normativedesig, numeric: false, disablePadding: false, label: "Norm. Desig" },
    {
      id: row?.alternativedesig ? row?.alternativedesig : "---",
      numeric: false,
      disablePadding: false,
      label: "Alt. Desig",
    },
    {
      id: row.lithologicalclass?.description ? row.lithologicalclass?.description : "---",
      numeric: false,
      disablePadding: false,
      label: "Lith. Class",
    },
    {
      id: row.lithologicalsubclass?.description ? row.lithologicalsubclass?.description : "---",
      numeric: false,
      disablePadding: false,
      label: "Lith. SubClass",
    },
    { id: row.districtquarry, numeric: false, disablePadding: false, label: "Quarry" },
  ];
};

const DEFAULT_LIMIT = 10;
const DEFAULT_SORTING: VarietySort[] = [
  { direction: SortDirection.DESC, field: VarietySortFields.createdAt },
];
const DEFAULT_PAGING: CursorPaging = { first: DEFAULT_LIMIT };

export default function VarietiesList() {
  const [filter, setFilter] = useState<VarietyFilter>({});
  const [paging, setPaging] = useState<CursorPaging>(DEFAULT_PAGING);
  const [sorting, setSorting] = useState<VarietySort[]>(DEFAULT_SORTING);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { loading, error, data, networkStatus, previousData } = useQuery<
    Varieties,
    VarietiesVariables
  >(QUERY_VARIETIES, {
    variables: {
      paging,
      sorting,
      filter,
    },
  });

  return (
    <>
      <Helmet>
        <title>Varieties | WeScan</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ mt: 10 }}>
        <NewTablePageHeaderComponent
          hasButton={false}
          entityNamePlural={"Varieties"}
          gridProps={{ sx: { mb: 2, mt: 2 } }}
        />
        <NewGeneralTableComponent
          data={!!data?.varieties ? data?.varieties?.edges?.map((edge) => edge.node) : []}
          loading={loading || networkStatus === NetworkStatus.fetchMore}
          error={error}
          LoadingComponent={() => (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          onChangePage={(e: any, page: number) => {
            onChangePageCursor(
              e,
              page,
              pageNumber,
              !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
              data?.varieties?.pageInfo || ({} as any),
              (pageNumber: number, newPaging: CursorPaging) => {
                setPaging(newPaging);
                setPageNumber(pageNumber);
              }
            );
          }}
          onRowsPerPageChange={(event) => {
            setPaging({ first: parseInt(event.target.value, 10) });
          }}
          page={pageNumber}
          rowsPerPage={!!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT}
          headCells={headCells}
          totalCount={data?.varieties?.totalCount || previousData?.varieties?.totalCount || 0}
          handleSort={(field, order) => {
            setSorting([{ field: field as VarietySortFields, direction: order }]);
          }}
          orderByField={sorting[0].field}
          orderDirection={sorting[0].direction}
          sortableFields={Object.values(VarietySortFields)}
          key={"categories"}
          getValues={getRowValues}
          disableRemove
          disableSelection
          disableSelectAll
          disableMultipleSelection
          disableDetails
          disableEdit
          PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
          tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pathEntity={""}
        />
      </Container>
    </>
  );
}
