import React from "react";
import { Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import DefaultImage from "../../components/GeneralComponents/DefaultImage";
import FlexBox from "../../components/GeneralComponents/FlexBox";

const NotFound = () => {
  const history = useHistory();

  return (
    <FlexBox
      flexDirection="column"
      minHeight={window.innerHeight / 2}
      justifyContent="center"
      alignItems="center"
      px={2}
    >
      <DefaultImage
        src="/images/404.svg"
        sx={{
          display: "block",
          width: "35%",
          maxWidth: "350px",
          mb: "1.5rem",
        }}
      />
      <FlexBox flexWrap="wrap">
        <Button
          variant="outlined"
          color="primary"
          sx={{ m: "0.5rem" }}
          onClick={() => {
            history.goBack();
          }}
        >
          Go Back
        </Button>
        <Link to="/">
          <Button variant="contained" color="primary" sx={{ m: "0.5rem" }}>
            Go to Home
          </Button>
        </Link>
      </FlexBox>
    </FlexBox>
  );
};
export default NotFound;
