import "../../pages/editimages/fabricjs_filters";
import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { EditImageEventTarget } from "../../pages/editimages";
import { ImageColorFilters_imageColorFilters_edges_node_valueFilter } from "../../data/graphQLModel";
import { Box } from "@material-ui/system";
import { H4, H5 } from "../GeneralComponents/Typography";
import useWindowSize from "../../hooks/useWindowSize";
import {
  calcAndSetImageSize,
  validateValueOfType,
  validateValueOfTypeWithoutDefault,
} from "../../utils/utils";
import { Stack, ToggleButton, ToggleButtonGroup } from "@material-ui/core";
import { Brush, Highlight, PanTool } from "@material-ui/icons";
import { BsEraser } from "react-icons/bs";
import theme from "../../theme/theme";

/**
 * Resize Image Return
 */
export interface ResizeImageReturnType {
  /**
   * New Data URL
   */
  dataUrl: string;
  /**
   * Original Height
   */
  originalHeight?: number;
  /**
   * Original Width
   */
  originalWidth?: number;
}

interface FabricJSCanvasProps {
  /**
   * Canvas ID
   */
  id: string;
  /**
   * Image URL
   */
  imageUrl: string;
  /**
   * Filter
   */
  filter: ImageColorFilters_imageColorFilters_edges_node_valueFilter;
  /**
   * Whether it is a selectable filter
   */
  filterbar: boolean;
  /**
   * ParentRef
   */
  parentRef: React.MutableRefObject<HTMLDivElement | undefined>;
}

const CanvasActionButtons = ["select", "erase", "draw", "undo"] as const;
type CanvasActionButtonsType = (typeof CanvasActionButtons)[number];

export const FabricJSCanvasComponent = (props: FabricJSCanvasProps) => {
  const { filter: pFilter, filterbar, id, parentRef } = props;
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const [erasingRemovesErasedObjects, setErasingRemovesErasedObjects] = useState<boolean>(true);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [currImage, setCurrImage] = useState<ResizeImageReturnType>();
  const [drawColor, setDrawColor] = useState<string>("#000000");
  const width = useWindowSize();
  const [activeButton, setActiveButton] = useState<CanvasActionButtonsType>();

  function changeAction(action: CanvasActionButtonsType) {
    if (!!canvas) {
      let state = validateValueOfTypeWithoutDefault(action, CanvasActionButtons);
      setActiveButton(state);
      switch (state) {
        case "select":
          if (activeButton === "select") {
            setActiveButton(undefined);
          } else {
            canvas.isDrawingMode = false;
          }
          break;
        case "erase":
          if (activeButton === "erase") {
            setActiveButton(undefined);
            canvas.isDrawingMode = false;
          } else {
            //@ts-ignore TODO
            canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
            canvas.freeDrawingBrush.width = 10;
            canvas.isDrawingMode = true;
          }
          break;
        case "undo":
          if (activeButton === "undo") {
            setActiveButton(undefined);
            canvas.isDrawingMode = false;
          } else {
            //@ts-ignore TODO
            canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
            canvas.freeDrawingBrush.width = 10;
            //@ts-ignore TODO
            canvas.freeDrawingBrush.inverted = true;
            canvas.isDrawingMode = true;
          }
          break;
        case "draw":
          if (activeButton === "draw") {
            setActiveButton(undefined);
            canvas.isDrawingMode = false;
          } else {
            canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
            canvas.freeDrawingBrush.width = 10;
            canvas.isDrawingMode = true;
            // canvas.freeDrawingBrush.color = state.drawColor;
          }
          break;
        default:
          setActiveButton(undefined);
          break;
      }
    }
  }

  /**
   *
   * @param maxWidth
   * @param imageUrl
   * @param canvas
   * @returns
   */
  async function resizeImage(
    maxWidth: number,
    maxHeight: number,
    imageUrl: string,
    canvas: fabric.Canvas
  ): Promise<ResizeImageReturnType> {
    return new Promise((resolve) => {
      let image = new Image();
      image.crossOrigin = "anonymous";

      image.src = imageUrl;
      image.onload = (img) => {
        //check if resizing is required
        let imgTarget = img?.target as EditImageEventTarget;
        if (!!imgTarget && Math.max(imgTarget?.width, imgTarget?.height) > maxWidth) {
          let originalHeight = imgTarget.height;
          let originalWidth = imgTarget.width;
          const sizes = calcAndSetImageSize(originalWidth, originalHeight, maxWidth, maxHeight);
          canvas.setHeight(sizes.height);
          canvas.setWidth(sizes.width);
          //draw to canvas
          let context = canvas.getContext();
          if (!!context) {
            //@ts-ignore
            context.drawImage(imgTarget, 0, 0, canvas.width, canvas.height);
            //assign new image url
            resolve({
              dataUrl: context.canvas.toDataURL(),
              originalHeight,
              originalWidth,
            });
          }
        }
        resolve({ dataUrl: imageUrl });
      };
    });
  }

  function renderCanvas(showOriginal = false) {
    //@ts-ignore TODO isImageLoaded
    if (!!canvas && canvas?.isImageLoaded) {
      let filters: ImageColorFilters_imageColorFilters_edges_node_valueFilter;
      if (showOriginal && !canvas.isDrawingMode) {
        filters = {
          exposure: 0,
          brightness: 0,
          contrast: 0,
          gamma: 1,
          saturation: 0,
          hue_rotation: 0,
          temperature: 0,
        };
      } else {
        filters = JSON.parse(JSON.stringify(props.filter));
      }
      if (!!canvas.backgroundImage && typeof canvas.backgroundImage === "object") {
        const fabricFilters = [
          //@ts-ignore TODO Exposure
          new fabric.Image.filters.Exposure({ exposure: filters.exposure }),
          new fabric.Image.filters.Brightness({ brightness: filters.brightness }),
          new fabric.Image.filters.Contrast({ contrast: filters.contrast }),
          //@ts-ignore TODO Gamma
          new fabric.Image.filters.Gamma({ gamma: [filters.gamma, filters.gamma, filters.gamma] }),
          new fabric.Image.filters.Saturation({ saturation: filters.saturation }),
          //@ts-ignore TODO HueRotation
          new fabric.Image.filters.HueRotation({ rotation: filters.hue_rotation }),
          //@ts-ignore TODO Temperature
          new fabric.Image.filters.Temperature({ temperature: filters.temperature }),
        ];
        canvas.backgroundImage.filters = fabricFilters;
        canvas.backgroundImage.applyFilters();
        canvas.renderAll();
      }
      canvas.renderAll();
    }
  }

  async function initCanvas(imgMaxWidth: number, imgMaxHeight: number) {
    //@ts-ignore TODO
    if (fabric.isWebglSupported()) {
      fabric.filterBackend = new fabric.WebglFilterBackend();
      fabric.textureSize = 2048;
    }

    let newCanvas = !!canvas
      ? canvas
      : new fabric.Canvas("fabricjs" + String(id), {
          selection: false,
          renderOnAddRemove: true,
          // make selectable filters cursor a pointer
          defaultCursor: filterbar ? "pointer" : undefined,
        });

    //@ts-ignore TODO
    newCanvas.isImageLoaded = false;
    let resizeResult = filterbar
      ? await resizeImage(
          !!imgMaxWidth ? imgMaxWidth : 100,
          imgMaxHeight || 100,
          props.imageUrl,
          newCanvas
        )
      : await resizeImage(
          !!imgMaxWidth ? imgMaxWidth : 1080,
          imgMaxHeight || 0,
          props.imageUrl,
          newCanvas
        );

    setCurrImage(resizeResult);

    //@ts-ignore TODO
    newCanvas.isImageLoaded = false;

    fabric.Image.fromURL(
      resizeResult.dataUrl,
      function (img) {
        img.filters = [
          //@ts-ignore TODO
          new fabric.Image.filters.Exposure({ exposure: pFilter.exposure }),
          new fabric.Image.filters.Brightness({ brightness: pFilter.brightness }),
          new fabric.Image.filters.Contrast({ contrast: pFilter.contrast }),
          //@ts-ignore TODO
          new fabric.Image.filters.Gamma({
            gamma: [pFilter.gamma, pFilter.gamma, pFilter.gamma],
          }),
          new fabric.Image.filters.Saturation({ saturation: pFilter.saturation }),
          //@ts-ignore TODO
          new fabric.Image.filters.HueRotation({ rotation: pFilter.hue_rotation }),
          //@ts-ignore TODO
          new fabric.Image.filters.Temperature({ temperature: pFilter.temperature }),
        ];
        img.applyFilters();
        newCanvas.backgroundImage = img;
        //@ts-ignore TODO
        newCanvas.backgroundImage.erasable = false;

        newCanvas.renderAll();
        //@ts-ignore TODO
        newCanvas.isImageLoaded = true;
      },
      { crossOrigin: "anonymous" }
    );
    if (!!!canvas) {
      setCanvas(newCanvas);
    }
  }

  useEffect(() => {
    if (!!!canvas) {
      return;
    }

    canvas.on("erasing:end", ({ targets }: any) => {
      if (erasingRemovesErasedObjects) {
        targets.forEach((obj: any) => obj.group?.removeWithUpdate(obj) || canvas.remove(obj));
      }
    });

    canvas.on("mouse:down", () => {
      renderCanvas(true);
    });

    canvas.on("mouse:up", () => {
      renderCanvas(false);
    });

    // canvas.on("mouse:wheel", function (opt) {
    //   var delta = opt.e.deltaY;
    //   var zoom = canvas.getZoom();
    //   zoom *= 0.999 ** delta;
    //   if (zoom > 20) zoom = 20;
    //   if (zoom < 0.01) zoom = 0.01;
    //   canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    //   opt.e.preventDefault();
    //   opt.e.stopPropagation();
    // });
  }, [canvas]);

  useEffect(() => {
    if (!!canvas) {
      renderCanvas(false);
    }
  }, [
    pFilter?.brightness,
    pFilter?.contrast,
    pFilter?.exposure,
    pFilter?.gamma,
    pFilter?.hue_rotation,
    pFilter?.saturation,
    pFilter?.temperature,
  ]);

  useEffect(() => {
    if (parentRef?.current?.clientWidth && parentRef?.current?.clientHeight) {
      initCanvas(parentRef?.current?.clientWidth * 0.9, parentRef?.current?.clientHeight * 0.9);
    }
  }, [parentRef?.current, width]);

  return (
    <Box>
      {filterbar ? null : (
        <div>
          <ToggleButtonGroup color="primary" value={activeButton} exclusive aria-label="action">
            <ToggleButton value="select" onClick={() => changeAction(CanvasActionButtons[0])}>
              <Stack direction={"row"} spacing={1}>
                <PanTool />
                <H5 color={activeButton === "select" ? theme.palette.primary.main : "none"}>
                  Select
                </H5>
              </Stack>
            </ToggleButton>
            <ToggleButton value="draw" onClick={() => changeAction(CanvasActionButtons[2])}>
              <Stack direction={"row"} spacing={1}>
                <Brush />
                <H5 color={activeButton === "draw" ? theme.palette.primary.main : "none"}>Draw</H5>
              </Stack>
            </ToggleButton>
            <ToggleButton value="erase" onClick={() => changeAction(CanvasActionButtons[1])}>
              <Stack direction={"row"} spacing={1}>
                <BsEraser size={25} />
                <H5 color={activeButton === "erase" ? theme.palette.primary.main : "none"}>
                  Erase
                </H5>
              </Stack>
            </ToggleButton>
          </ToggleButtonGroup>
          {/* <input type="color" name="option_color" value="#000000" list="presetColors"></input>
      <datalist id="presetColors">
        <option>#000000</option>
        <option>#ff0000</option>
        <option>#009933</option>
        <option>#0033cc</option>
      </datalist> */}
          {/* <input id="b" type="checkbox" onChange={setErasingRemovesErasedObjects}></input> */}
        </div>
      )}
      <canvas id={"fabricjs" + id} />
    </Box>
  );
};
