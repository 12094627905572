/* eslint-disable */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { loadingComponent, notLoadingComponent } from "../../../actions/isLoadingActions";
import { Box, Card, CardContent, Container, Typography } from "@material-ui/core";
import swalert from "sweetalert";
import { DeleteOutline } from "@material-ui/icons";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import {
  DeleteManyPackings,
  DeleteManyPackingsVariables,
  PackingsGallery_packings_edges_node,
} from "../../../data/graphQLModel";
import { getSlabImageSafe } from "../../../utils/utils";
import { useMutation } from "@apollo/react-hooks";
import { MUTATION_DELETE_MANY_PACKINGS } from "../../../data/graphQLQueries";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    flex: "1",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    overflow: "hidden",
  },
  imageList: {
    width: "250ch",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

export default function EditPackage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<PackingsGallery_packings_edges_node[]>();
  const [packages_data, setPackages_Data] = useState<PackingsGallery_packings_edges_node[]>([]);

  const [mutationDeletePackages] = useMutation<DeleteManyPackings, DeleteManyPackingsVariables>(
    MUTATION_DELETE_MANY_PACKINGS
  );

  useEffect(() => {
    getDetailsPackages();
  }, []);

  const getDetailsPackages = async () => {
    if (location.state === undefined) {
      history.push("/customerstables/packages");
      return;
    }
    setPackages_Data(location.state);
  };

  async function confirmSubmission(e: any) {
    e.preventDefault();

    swalert({
      title: "DELETE",
      text: "Are you sure you want to delete these Packages?",
      icon: "warning",
      //@ts-ignore
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        submitForm(e);
      }
    });
  }

  async function submitForm(e: any) {
    e.preventDefault();
    const pInput = packages_data.map((el) => el.id);
    dispatch(loadingComponent());

    try {
      await mutationDeletePackages({
        variables: {
          input: {
            filter: {
              id: { in: pInput },
            },
          },
        },
      });
      swalert("Success.", "Packages deleted", "success");
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert(
        "Failed.",
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Erro",
        "error"
      );
      return;
    }
    dispatch(notLoadingComponent());
    history.push("/customerstables/packages");
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/customerstables/packages/");
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>Delete Packages</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={confirmSubmission} style={{ width: "100%" }}>
              {packages_data && (
                <div className={classes.root}>
                  <ImageList
                    rowHeight={100}
                    className={classes.imageList}
                    cols={packages_data.length < 4 ? packages_data.length : 3}
                    gap={5}
                  >
                    {packages_data.map((item) => (
                      <ImageListItem key={item.id}>
                        {!!item?.slabs?.edges?.[0]?.node?.slabImages &&
                        item?.slabs?.edges?.[0]?.node?.slabImages.length > 0 ? (
                          <img src={getSlabImageSafe(item?.slabs?.edges?.[0]?.node) || ""} />
                        ) : (
                          <Typography>No Image</Typography>
                        )}
                        <ImageListItemBar
                          title={item.code}
                          subtitle={
                            <>
                              <span>Description: {item.packing}</span>
                              <br />
                              <span>Slabs: {item.slabs?.totalCount}</span>
                            </>
                          }
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>
              )}
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      onClick={handleCancel}
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      onClick={confirmSubmission}
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                      color="secondary"
                    >
                      Delete
                      <DeleteOutline style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
