/* eslint-disable */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import swalert from "sweetalert";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { Box, Card, CardContent, Container, Grid } from "@material-ui/core";
import { loadingComponent, notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  PackingType,
  PackingTypeVariables,
  UpdateOnePackageType,
  UpdateOnePackageTypeVariables,
} from "../../../data/graphQLModel";
import {
  MUTATION_UPDATE_ONE_PACKAGE_TYPE,
  QUERY_ONE_PACKAGE_TYPE,
} from "../../../data/graphQLQueries";
import { decodeBase64 } from "../../../utils/utils";
import { MuiThemeProps } from "../../../theme/theme";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "80%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function EditPackageType() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setForm] = useState<{
    id?: string;
    code?: string;
    type?: string | null;
    observation?: string | null;
  }>({ id: "", code: "", type: "", observation: "" });

  let { parptype } = useParams() as { parptype: string };

  useQuery<PackingType, PackingTypeVariables>(QUERY_ONE_PACKAGE_TYPE, {
    variables: {
      id: decodeBase64(parptype),
    },
    onCompleted(data) {
      if (!!data?.packingType) {
        setForm({
          id: data.packingType?.id,
          code: data.packingType?.code,
          observation: data.packingType?.observation,
          type: data.packingType?.type,
        });
      }
    },
  });

  const [mutationUpdatePackingType] = useMutation<
    UpdateOnePackageType,
    UpdateOnePackageTypeVariables
  >(MUTATION_UPDATE_ONE_PACKAGE_TYPE);

  function changeform(e: any) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  async function submitForm(e: any) {
    e.preventDefault();

    dispatch(loadingComponent());

    try {
      await mutationUpdatePackingType({
        variables: {
          input: {
            id: form.id as string,
            update: {
              observation: form.observation,
              type: form.type,
            },
          },
        },
      });
      swalert("Success.", "Package update with success", "success");
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert(
        "Failed.",
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Erro",
        "error"
      );
      return;
    }
    dispatch(notLoadingComponent());
    history.push("/customerstables/packagetypes");
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/customerstables/packagetypes/");
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>Edit Package Type</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm} style={{ width: "100%" }}>
              {form.code && (
                <>
                  <div>
                    <FormControl fullWidth className={classes.marginCode} variant="outlined">
                      <TextField
                        id="CodePackingType"
                        label="Code"
                        name="code"
                        value={form.code}
                        placeholder="Code"
                        disabled
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                      <TextField
                        id="idType"
                        label="type"
                        name="type"
                        value={form.type}
                        onChange={changeform}
                        placeholder="Type"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                      <TextField
                        id="obsPacking"
                        label="Observation"
                        name="observation"
                        placeholder="Observation"
                        onChange={changeform}
                        value={form.observation}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        multiline
                        rows={5}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                </>
              )}
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      disabled={
                        !!!form.type ||
                        form.type === "" ||
                        !!!form.code ||
                        form.code === "" ||
                        !!!form.id ||
                        form.id === ""
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Save
                      <Save style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
