import { CursorPaging, IRolesEnum, OffsetPaging } from "../data/graphQLModel";

export class weScanDateTime extends Date {
  constructor(value: any) {
    // console.log(value);
    super(new Date(value));
  }

  parseValue(value: any) {
    // console.log(value);
  }

  serialize(value: any) {
    // console.log(value);
  }

  parseLiteral(value: any) {
    // console.log(value);
  }
}

export type weScanConnectionCursor = string;
export type weScanUpload = File;

export interface WithLimitOffsetPaging extends OffsetPaging {
  limit: number;
}

export interface WithFirstCursorPaging extends CursorPaging {
  first: number;
}

export enum AppInsightsErrorType {
  "graphql" = "graphql",
  "network" = "network",
}

export enum TokenType {
  "user" = "user",
  "scanner" = "scanner",
  "shareable_link" = "shareable_link",
  "client" = "client",
}

export interface UserToken {
  username: string;
  id: string;
  company: string;
  name: string;
  role: IRolesEnum;
  avatar: string;
  sub: string;
  type: TokenType;
  iat: number;
  exp: number;
}
