import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { Box, Card, CardContent, Container } from "@material-ui/core";
import swalert from "sweetalert";
import { MuiThemeProps } from "../../../theme/theme";
import { useMutation } from "@apollo/react-hooks";
import { CreateOnePackageType, CreateOnePackageTypeVariables } from "../../../data/graphQLModel";
import { MUTATION_CREATE_ONE_PACKAGE_TYPE } from "../../../data/graphQLQueries";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "80%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function CreatePackageType() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setForm] = useState({ code: "", type: "", observation: "" });

  const [mutationCreateOnePackageType] = useMutation<
    CreateOnePackageType,
    CreateOnePackageTypeVariables
  >(MUTATION_CREATE_ONE_PACKAGE_TYPE);

  function changeform(e: any) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  async function submitForm(e: any) {
    e.preventDefault();

    if (form.code === "" || form.code.length < 2 || form.type === "" || form.type.length < 5) {
      swalert("Information!", "Missing information!", "warning");
      return;
    }
    // dispatch(loadingComponent());
    try {
      var res = await mutationCreateOnePackageType({
        variables: {
          input: {
            packingType: {
              code: form.code,
              observation: form.observation,
              type: form.type,
            },
          },
        },
      });
      if (res) swalert("Success.", "Package Type created with success", "success");
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert(
        "Failed.",
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Erro",
        "error"
      );
      return;
    }
    // dispatch(notLoadingComponent());
    history.push("/customerstables/packagetypes");
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/customerstables/packagetypes");
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>New Package Type</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm} style={{ width: "100%" }}>
              <div>
                <FormControl fullWidth className={classes.marginCode} variant="outlined">
                  <TextField
                    id="CodePackagesType"
                    label="Code"
                    name="code"
                    placeholder="Code"
                    required
                    focused
                    onChange={changeform}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <TextField
                    id="PackageType"
                    label="Type"
                    name="type"
                    onChange={changeform}
                    placeholder="Type"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <TextField
                    id="obsPackageType"
                    label="Observation"
                    name="observation"
                    placeholder="Observation"
                    onChange={changeform}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={5}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      disabled={
                        form.code === "" || form.code.length < 2 || !!!form.type || form.type === ""
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Create
                      <Save style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
