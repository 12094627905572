import { gql } from "apollo-boost";

/*
 * ################################################################################
 * ################################################################################
 * ################                      QUERIES                   ################
 * ################################################################################
 * ################################################################################
 */
//#region QUERY

// SHAREABLE LINK

export const QUERY_MANY_SHAREABLE_LINKS = gql`
  query ShareLinks($filter: ShareLinkFilter, $paging: CursorPaging, $sorting: [ShareLinkSort!]) {
    shareLinks(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id
          isValid
          package {
            id
          }
          project {
            id
          }
          validUntil
          keyValue
          block {
            id
          }
          slab {
            id
          }
        }
      }
    }
  }
`;

// CLIENTS

export const QUERY_MANY_CLIENTS = gql`
  query Clients($filter: ClientFilter, $paging: CursorPaging, $sorting: [ClientSort!]) {
    clients(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id
          name
          code
          provider
          lastName
        }
      }
    }
  }
`;

// USERS

export const USER_BY_ID = gql`
  query User($id: ID!) {
    user(id: $id) {
      avatar
      email
      id
      status
      role
      firstname
      lastname
      username
      name
      company {
        id
        code
        name
      }
    }
  }
`;

// ENVIRONMENTAL_FACTORS

export const ENVIRONMENTAL_FACTORS = gql`
  query EnvironmentalFactors(
    $filter: EnvironmentalFactorFilter
    $paging: CursorPaging
    $sorting: [EnvironmentalFactorSort!]
  ) {
    environmentalFactors(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          application_type
          description
          id
          level
          measurement
          name
          range_start
          range_end
          description
        }
      }
    }
  }
`;

// APPLICATION_TYPES

export const APPLICATION_TYPES = gql`
  query ApplicationTypes(
    $filter: ApplicationTypeFilter
    $paging: CursorPaging
    $sorting: [ApplicationTypeSort!]
  ) {
    applicationTypes(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          application_site {
            createdAt
            id
            name
            updatedAt
          }
          application_typology
          development_type {
            createdAt
            id
            name
            updatedAt
          }
          description {
            createdAt
            id
            updatedAt
            description
            details
            code
          }
          createdAt
          updatedAt
          id
        }
      }
    }
  }
`;

// STONE_LOCATION

export const STONE_LOCATIONS = gql`
  query StoneLocations(
    $filter: StoneLocationFilter
    $paging: CursorPaging
    $sorting: [StoneLocationSort!]
  ) {
    stoneLocations(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          coast_line
          country
          country_side
          industrialization
          mountain
          name
          urbanization
          createdAt
          updatedAt
          id
        }
      }
    }
  }
`;

// COLORS

export const QUERY_COLORS = gql`
  query QueryColors($filter: ColorFilter, $paging: CursorPaging, $sorting: [ColorSort!]) {
    colors(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      edges {
        cursor
        node {
          code
          color
          id
        }
      }
    }
  }
`;

// TWIN_AI

export const TWIN_AI = gql`
  query TwinStones($id: String!) {
    twinStones(id: $id) {
      company {
        id
        code
        name
        contact
      }
      createdAt
      id
      original
      slab {
        id
        code
        width
        area
        height
        images
        mainImage
        slabImages {
          _id
          edited
          original
          thumbnail_edited
          thumbnail_original
          mainType
          azureBlobID
        }
        inStock
        createdate
        createdAt
        variety {
          normativedesig
        }
      }
      thumbnail
    }
  }
`;

// SLABS

export const SLAB_BY_ID = gql`
  query Slab($id: ID!) {
    slab(id: $id) {
      main_image
      id
      code
      name
      area
      description
      desigcomercial
      width
      height
      thickness
      extwidth
      blocknumber
      extheight
      extthickness
      stkwidth
      stkheight
      stkthickness
      purchasewidth
      purchaseheight
      purchasethickness
      images
      mainImage
      slabImages {
        _id
        edited
        original
        thumbnail_edited
        thumbnail_original
        mainType
        azureBlobID
      }
      iscatalogued
      typematerial {
        id
        code
        description {
          pt
        }
        companyNames {
          code
          description
        }
      }
      category {
        id
        code
        description {
          pt
        }
        companyNames {
          code
          description
        }
      }
      finish {
        id
        description {
          pt
        }
        code
      }
      project {
        id
        project
      }
      variety {
        id
        normativedesig
        macroscopicdesc
      }
      observation
      createdate
      scan {
        code
        description
      }
      job {
        description
      }
      packing {
        id
        code
        packing
      }
      inStock
      company {
        code
        name
      }
      commercial_variety {
        id
        code
        description
        name
        materialtype {
          code
          description {
            pt
          }
        }
      }
      commercial_finishing {
        code
        description {
          pt
        }
        id
        observation {
          pt
        }
        createdAt
      }
      stone_edge {
        code
        id
        name
        description
      }
      stone_model {
        code
        id
        name
        description
      }
    }
  }
`;

export const QUERY_BLOB_METADATA = gql`
  query blobMetadata($url: String!) {
    blobMetadata(url: $url) {
      metadata
    }
  }
`;

export const QUERY_SLABS = gql`
  query Slabs($filter: SlabFilter, $paging: OffsetPaging, $sorting: [SlabSort!]) {
    slabs(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        iscatalogued
        main_image
        erp_id
        id
        code
        name
        area
        description
        desigcomercial
        width
        height
        thickness
        extwidth
        blocknumber
        block {
          id
          wescanCode
          tenantCode
        }
        extheight
        extthickness
        stkwidth
        stkheight
        stkthickness
        purchasewidth
        purchaseheight
        purchasethickness
        iscatalogued
        cancelCataloguing
        images
        mainImage
        slabImages {
          _id
          edited
          original
          thumbnail_edited
          thumbnail_original
          mainType
          azureBlobID
        }
        typematerial {
          id
          description {
            pt
          }
        }
        category {
          id
          description {
            pt
          }
        }
        finish {
          id
          description {
            pt
          }
        }
        project {
          id
          project
        }
        variety {
          id
          normativedesig
          macroscopicdesc
        }
        observation
        createdate
        scan {
          code
          description
        }
        job {
          description
        }
        packing {
          id
          code
          packing
        }
        inStock
        company {
          code
          name
        }
        commercial_variety {
          id
          code
          description
          name
          materialtype {
            code
            description {
              pt
            }
          }
        }
        commercial_finishing {
          code
          description {
            pt
          }
          id
          observation {
            pt
          }
          createdAt
        }
        stone_edge {
          code
          id
          name
          description
        }
        stone_model {
          code
          id
          name
          description
        }
      }
    }
  }
`;

export const QUERY_MANY_SHARED_SLABS = gql`
  query SharedSlabs($filter: SlabFilter, $paging: OffsetPaging, $sorting: [SlabSort!]) {
    sharedSlabs(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        mainImage
        slabImages {
          _id
          edited
          original
          thumbnail_edited
          thumbnail_original
          mainType
          azureBlobID
        }
        id
        code
        name
        images
        width
        height
        thickness
        createdate
        blocknumber
        inStock
        company {
          code
          name
        }
        commercial_variety {
          id
          code
          description
          name
          materialtype {
            code
            description {
              pt
            }
          }
          annexedDocuments {
            nodes {
              blob_url
              id
              mimetype
              name
            }
          }
        }
        commercial_finishing {
          code
          description {
            pt
          }
          id
          observation {
            pt
          }
          createdAt
        }
        typematerial {
          description {
            pt
          }
        }
        category {
          description {
            pt
          }
        }
        finish {
          description {
            pt
          }
        }
        variety {
          normativedesig
        }
      }
    }
  }
`;

export const QUERY_ONE_SHARED_SLAB = gql`
  query SharedSlab($id: String, $keyValue: String) {
    sharedSlab(id: $id, keyValue: $keyValue) {
      main_image
      id
      code
      name
      area
      description
      desigcomercial
      width
      height
      thickness
      extwidth
      blocknumber
      extheight
      extthickness
      stkwidth
      stkheight
      stkthickness
      purchasewidth
      purchaseheight
      purchasethickness
      images
      iscatalogued
      mainImage
      slabImages {
        _id
        edited
        original
        thumbnail_edited
        thumbnail_original
        mainType
        azureBlobID
      }
      typematerial {
        id
        code
        description {
          pt
        }
        companyNames {
          code
          description
        }
      }
      category {
        id
        code
        description {
          pt
        }
        companyNames {
          code
          description
        }
      }
      finish {
        id
        description {
          pt
        }
        code
      }
      project {
        id
        project
      }
      variety {
        id
        normativedesig
        macroscopicdesc
      }
      observation
      createdate
      scan {
        code
        description
      }
      job {
        description
      }
      packing {
        id
        code
        packing
      }
      inStock
      company {
        code
        name
      }
      commercial_variety {
        id
        code
        description
        name
        materialtype {
          code
          description {
            pt
          }
        }
      }
      commercial_finishing {
        code
        description {
          pt
        }
        id
        observation {
          pt
        }
        createdAt
      }
      stone_edge {
        code
        id
        name
        description
      }
      stone_model {
        code
        id
        name
        description
      }
    }
  }
`;

export const QUERY_NEXT_SLABS = gql`
  query NextSlabs($scanner: ID!, $next: Int) {
    nextSlabs(scanner: $scanner, next: $next) {
      code
      job {
        prefix
        startNumber
        numberingOrder
        separator
        sequence
        description
        createdAt
        createdate
        updatedAt
        lastupdate
        id
        code
        blocknumber
        descstate
        completed
        finish {
          code
          description {
            en
            es
            pt
          }
          observation {
            en
            es
            pt
          }
          id
        }
        category {
          code
          companyNames {
            description
            code
          }
          description {
            en
            es
            pt
          }
          id
        }
        variety {
          id
          code
          date
          absapatn
          alternativedesig
          observation
          normativedesig
          macroscopicdesc
        }
        totalslab
        scannedSlabs
        state
        company {
          code
          name
          id
        }
        jobtype {
          id
          code
          description
        }
        block {
          baseArea
          id
          extwidth
          extlength
          extheight
          description
          tenantCode
          wescanCode
          regularImages {
            _id
            edited
            original
            thumbnail_edited
            thumbnail_original
            mainType
            azureBlobID
          }
        }
        commercial_finishing {
          code
          description {
            pt
            es
            en
          }
          id
          observation {
            en
            es
            pt
          }
          createdAt
        }
        commercial_variety {
          id
          name
          code
          description
          materialtype {
            code
            description {
              pt
              es
              en
            }
            id
            companyNames {
              description
              code
            }
          }
        }
        packing {
          id
          code
          observation
          packing
        }
        project {
          id
          code
          description
          project
        }
        typematerial {
          code
          companyNames {
            description
            company
            code
          }
          description {
            pt
            es
            en
          }
          id
        }
      }
    }
  }
`;

// CATEGORIES

export const QUERY_CATEGORIES = gql`
  query Categories($filter: CategoryFilter, $paging: CursorPaging, $sorting: [CategorySort!]) {
    categories(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          code
          createdAt
          updatedAt
          date
          description {
            en
            pt
            es
          }
          observation {
            en
            pt
            es
          }
          isdefault
          companyNames {
            code
            description
          }
          isResultOfSlabScan
        }
      }
    }
  }
`;

// MATERIAL TYPES

export const QUERY_MATERIALTYPES = gql`
  query MaterialTypes(
    $filter: MaterialTypeFilter
    $paging: CursorPaging
    $sorting: [MaterialTypeSort!]
  ) {
    materialTypes(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          code
          createdAt
          updatedAt
          date
          description {
            en
            pt
            es
          }
          observation {
            en
            pt
            es
          }
          isdefault
          companyNames {
            code
            description
          }
        }
      }
    }
  }
`;

// FINISHINGS
export const QUERY_FINISHINGS = gql`
  query Finishings($filter: FinishingFilter, $paging: CursorPaging, $sorting: [FinishingSort!]) {
    finishings(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          code
          date
          description {
            pt
            en
            es
          }
          isdefault
          observation {
            pt
            en
            es
          }
        }
      }
    }
  }
`;

// VARIETIES
export const QUERY_VARIETIES = gql`
  query Varieties($filter: VarietyFilter, $paging: CursorPaging, $sorting: [VarietySort!]) {
    varieties(filter: $filter, paging: $paging, sorting: $sorting) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          code
          date
          absapatn
          alternativedesig
          observation
          normativedesig
          lithologicalclass {
            code
            description
            id
          }
          lithologicalsubclass {
            code
            description
            id
          }
          stonequarry
          districtquarry
          municipalityquarry
        }
      }
    }
  }
`;

// COMPANIES
export const QUERY_COMPANIES = gql`
  query Companies($filter: CompanyFilter, $paging: CursorPaging, $sorting: [CompanySort!]) {
    companies(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          code
          name
          description
          address
          email
          contact
          image
          stars
        }
      }
    }
  }
`;

export const QUERY_ONE_COMPANY = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      code
      name
      description
      address
      email
      contact
      image
      stars
    }
  }
`;

// JOBS

export const QUERY_JOBS = gql`
  query Jobs($filter: JobFilter, $paging: CursorPaging, $sorting: [JobSort!]) {
    jobs(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          taskprocesses {
            nodes {
              slabcount
              totalslab
            }
          }
          block {
            tenantCode
            wescanCode
          }
          sequence
          description
          createdAt
          createdate
          updatedAt
          lastupdate
          id
          code
          blocknumber
          descstate
          completed
          finish {
            code
            description {
              en
              es
              pt
            }
            observation {
              en
              es
              pt
            }
            id
          }
          category {
            code
            companyNames {
              description
              code
            }
            description {
              en
              es
              pt
            }
            id
          }
          variety {
            id
            code
            date
            absapatn
            alternativedesig
            observation
            normativedesig
          }
          height
          width
          thickness
          totalslab
          scannedSlabs
          isblock
          scan {
            code
            description
            state
            id
          }
          state
          company {
            code
            name
            id
          }
          jobtype {
            id
            code
            description
          }
        }
      }
    }
  }
`;

export const QUERY_ONE_JOB = gql`
  query Job($id: ID!) {
    job(id: $id) {
      taskprocesses {
        nodes {
          slabcount
          totalslab
        }
      }
      prefix
      numberingOrder
      separator
      startNumber
      block {
        baseArea
        id
        extwidth
        extlength
        extheight
        description
        tenantCode
        wescanCode
        regularImages {
          _id
          edited
          original
          thumbnail_edited
          thumbnail_original
          mainType
          azureBlobID
        }
      }
      sequence
      description
      createdAt
      createdate
      updatedAt
      lastupdate
      id
      code
      blocknumber
      descstate
      completed
      finish {
        code
        description {
          en
          es
          pt
        }
        observation {
          en
          es
          pt
        }
        id
      }
      category {
        code
        companyNames {
          description
          code
        }
        description {
          en
          es
          pt
        }
        id
      }
      variety {
        id
        code
        date
        absapatn
        alternativedesig
        observation
        normativedesig
      }
      commercial_finishing {
        code
        description {
          pt
        }
        id
        observation {
          pt
        }
        createdAt
      }
      commercial_variety {
        id
        code
        description
        name
        materialtype {
          code
          description {
            pt
          }
        }
      }
      stone_edge {
        code
        id
        name
        description
      }
      stone_model {
        code
        id
        name
        description
      }
      packing {
        id
        code
        packing
      }
      project {
        id
        code
        project
      }
      typematerial {
        id
        code
        description {
          pt
        }
        companyNames {
          code
          description
        }
      }
      height
      width
      thickness
      scannedSlabs
      totalslab
      isblock
      scan {
        code
        description
        state
        id
      }
      state
      company {
        code
        name
        id
      }
      jobtype {
        id
        code
        description
      }
    }
  }
`;

export const QUERY_JOBS_FOR_SCANNER_CONSOLE = gql`
  query JobsForScannerConsole($filter: JobFilter, $paging: CursorPaging, $sorting: [JobSort!]) {
    jobs(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        cursor
        node {
          prefix
          startNumber
          numberingOrder
          separator
          sequence
          description
          createdAt
          createdate
          updatedAt
          lastupdate
          id
          code
          blocknumber
          descstate
          completed
          finish {
            code
            description {
              en
              es
              pt
            }
            observation {
              en
              es
              pt
            }
            id
          }
          category {
            code
            companyNames {
              description
              code
            }
            description {
              en
              es
              pt
            }
            id
          }
          variety {
            id
            code
            date
            absapatn
            alternativedesig
            observation
            normativedesig
            macroscopicdesc
          }
          totalslab
          scannedSlabs
          state
          company {
            code
            name
            id
          }
          jobtype {
            id
            code
            description
          }
          block {
            baseArea
            id
            extwidth
            extlength
            extheight
            description
            tenantCode
            wescanCode
            regularImages {
              _id
              edited
              original
              thumbnail_edited
              thumbnail_original
              mainType
              azureBlobID
            }
          }
          commercial_finishing {
            code
            description {
              pt
              es
              en
            }
            id
            observation {
              en
              es
              pt
            }
            createdAt
          }
          commercial_variety {
            id
            name
            code
            description
            materialtype {
              code
              description {
                pt
                es
                en
              }
              id
              companyNames {
                description
                code
              }
            }
          }
          packing {
            id
            code
            observation
            packing
          }
          project {
            id
            code
            description
            project
          }
          typematerial {
            code
            companyNames {
              description
              company
              code
            }
            description {
              pt
              es
              en
            }
            id
          }
        }
      }
      pageInfo {
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export const QUERY_JOBS_SHORT = gql`
  query JobsShort($filter: JobFilter, $paging: CursorPaging, $sorting: [JobSort!]) {
    jobs(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        cursor
        node {
          description
          createdAt
          updatedAt
          id
          code
          descstate
          completed
          totalslab
        }
      }
      pageInfo {
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

// JOB TYPES
export const QUERY_JOB_TYPES = gql`
  query JobTypes($filter: JobtypeFilter, $paging: CursorPaging, $sorting: [JobtypeSort!]) {
    jobtypes(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id
          description
          code
        }
      }
    }
  }
`;

// COMMERCIAL FINISHING
export const QUERY_COMMERCIAL_FINISHINGS = gql`
  query CommercialFinishings(
    $filter: CommercialFinishingFilter
    $paging: CursorPaging
    $sorting: [CommercialFinishingSort!]
  ) {
    commercialFinishings(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          code
          createdAt
          createdBy {
            username
            avatar
            firstname
            lastname
            id
          }
          description {
            pt
            en
            es
          }
          id
          observation {
            pt
            en
            es
          }
        }
      }
    }
  }
`;
export const QUERY_ONE_COMMERCIAL_FINISHING = gql`
  query CommercialFinishing($id: ID!) {
    commercialFinishing(id: $id) {
      code
      createdAt
      createdBy {
        username
        avatar
        firstname
        lastname
        id
      }
      description {
        pt
        en
        es
      }
      id
      observation {
        pt
        en
        es
      }
    }
  }
`;

// COMMERCIAL VARIETIES
export const QUERY_COMMERCIAL_VARIETIES = gql`
  query CommercialVarieties(
    $filter: CommercialVarietyFilter
    $paging: CursorPaging
    $sorting: [CommercialVarietySort!]
  ) {
    commercialVarieties(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          description
          id
          code
          createdAt
          createdBy {
            email
            id
            name
            lastname
            firstname
          }
          materialtype {
            code
            companyNames {
              code
              description
            }
            id
            description {
              en
              pt
              es
            }
          }
          name
          system_variety {
            code
            normativedesig
            alternativedesig
            absapatn
            districtquarry
            id
          }
          updatedAt
          updatedBy {
            avatar
            id
            name
            lastname
            firstname
          }
          user {
            avatar
            email
            id
            lastname
            firstname
          }
        }
      }
    }
  }
`;

export const QUERY_ONE_COMMERCIAL_VARIETY = gql`
  query CommercialVariety($id: ID!) {
    commercialVariety(id: $id) {
      description
      id
      code
      createdAt
      createdBy {
        email
        id
        name
        lastname
        firstname
      }
      materialtype {
        code
        companyNames {
          code
          description
        }
        id
        description {
          en
          pt
          es
        }
      }
      name
      system_variety {
        code
        normativedesig
        alternativedesig
        absapatn
        districtquarry
        id
      }
      updatedAt
      updatedBy {
        avatar
        id
        name
        lastname
        firstname
      }
      user {
        avatar
        email
        id
        lastname
        firstname
      }
      annexedDocuments {
        nodes {
          blob_url
          id
          mimetype
          name
        }
      }
    }
  }
`;

// STONE MODELS
export const QUERY_ONE_STONE_MODEL = gql`
  query StoneModel($id: ID!) {
    stoneModel(id: $id) {
      code
      description
      id
      name
      createdAt
    }
  }
`;

export const QUERY_STONE_MODELS = gql`
  query StoneModels($filter: StoneModelFilter, $paging: CursorPaging, $sorting: [StoneModelSort!]) {
    stoneModels(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          code
          description
          id
          name
          createdAt
        }
      }
    }
  }
`;

// STONE EDGES
export const QUERY_ONE_STONE_EDGE = gql`
  query StoneEdge($id: ID!) {
    stoneEdge(id: $id) {
      code
      createdAt
      id
      name
      description
    }
  }
`;

export const QUERY_STONE_EDGES = gql`
  query StoneEdges($filter: StoneEdgeFilter, $paging: CursorPaging, $sorting: [StoneEdgeSort!]) {
    stoneEdges(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          code
          createdAt
          id
          name
          description
        }
      }
    }
  }
`;

// SCANS

export const QUERY_SCANNERS = gql`
  query Scanners($filter: ScannerFilter, $paging: CursorPaging, $sorting: [ScannerSort!]) {
    scanners(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        node {
          code
          id
          company {
            name
            id
            code
          }
          description
          scanner_model
          snumber
          state
        }
      }
    }
  }
`;

export const QUERY_ONE_SCANNER = gql`
  query Scanner($id: ID!) {
    scanner(id: $id) {
      code
      id
      company {
        name
        id
        code
      }
      description
      scanner_model
      snumber
      state
    }
  }
`;

// ALL
export const QUERY_ALL_SLAB_FILTERS = gql`
  query AllSlabFilters {
    categories(paging: { first: 200 }) {
      edges {
        node {
          id
          code
          description {
            en
            pt
            es
          }
          observation {
            en
            pt
            es
          }
          companyNames {
            code
            description
          }
        }
      }
    }
    materialTypes(paging: { first: 200 }) {
      edges {
        cursor
        node {
          id
          code
          description {
            en
            pt
            es
          }
          observation {
            en
            pt
            es
          }
          companyNames {
            code
            description
          }
        }
      }
    }
    finishings(paging: { first: 200 }) {
      edges {
        cursor
        node {
          id
          code
          date
          description {
            pt
            en
            es
          }
          observation {
            pt
            en
            es
          }
        }
      }
    }
    varieties(paging: { first: 200 }) {
      edges {
        node {
          id
          code
          absapatn
          alternativedesig
          observation
          normativedesig
          stonequarry
          districtquarry
          municipalityquarry
          materialtype {
            description {
              pt
              es
              en
            }
            code
            id
          }
        }
      }
    }
    commercialFinishings(paging: { first: 200 }) {
      edges {
        node {
          code
          description {
            pt
            en
            es
          }
          id
          observation {
            pt
            en
            es
          }
        }
      }
    }
    commercialVarieties(paging: { first: 200 }) {
      edges {
        node {
          code
          name
          id
        }
      }
    }
    scanners(paging: { first: 50 }) {
      edges {
        node {
          code
          id
          snumber
          scanner_model
          description
        }
      }
    }
    jobs(sorting: [{ direction: DESC, field: createdAt }], paging: { first: 50 }) {
      edges {
        node {
          code
          id
          blocknumber
          description
          jobtype {
            code
            id
          }
        }
      }
    }
    packings(sorting: [{ direction: DESC, field: createdAt }], paging: { first: 50 }) {
      edges {
        node {
          id
          code
          packing
          observation
          inStock
          packingtype {
            code
            id
            observation
            type
          }
          project {
            id
            code
            project
          }
        }
      }
    }
    projects(sorting: [{ direction: DESC, field: createdAt }], paging: { first: 50 }) {
      edges {
        node {
          code
          id
          description
          project
        }
      }
    }
    stoneEdges(paging: { first: 200 }) {
      edges {
        node {
          code
          createdAt
          id
          name
          description
        }
      }
    }
    stoneModels(paging: { first: 200 }) {
      edges {
        node {
          code
          description
          id
          name
          createdAt
        }
      }
    }
  }
`;

// COLOR FILTERS
export const QUERY_MANY_IMAGECOLORFILTERS = gql`
  query ImageColorFilters(
    $filter: ImageColorFilterFilter
    $paging: CursorPaging
    $sorting: [ImageColorFilterSort!]
  ) {
    imageColorFilters(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        node {
          id
          name
          valueFilter {
            brightness
            contrast
            gamma
            hue_rotation
            saturation
            temperature
            exposure
          }
          commercial_variety {
            code
            id
            description
            name
          }
          commercial_finishing {
            id
            code
            description {
              pt
              en
              es
            }
          }
        }
      }
    }
  }
`;

export const QUERY_ONE_IMAGECOLORFILTER = gql`
  query ImageColorFilter($id: ID!) {
    imageColorFilter(id: $id) {
      id
      name
      valueFilter {
        brightness
        contrast
        gamma
        hue_rotation
        saturation
      }
      commercial_variety {
        code
        id
        description
        name
      }
      commercial_finishing {
        id
        code
        description {
          pt
          en
          es
        }
      }
    }
  }
`;

// BLOCKS
export const QUERY_ONE_BLOCKMODEL = gql`
  query BlockModel($id: ID!) {
    blockModel(id: $id) {
      baseArea
      slabs {
        totalCount
      }
      commercial_variety {
        code
        id
        name
        description
      }
      materialtype {
        id
        code
        description {
          pt
        }
        companyNames {
          code
          description
        }
      }
      createdAt
      createdBy {
        id
        name
        lastname
        firstname
      }
      creationType
      description
      extheight
      extwidth
      id
      scannedBy {
        code
        scanner_model
      }
      tenantCode
      updatedAt
      regularImages {
        azureBlobID
        azureContainer
        azureStorageAccount
        edited
        imageFace
        mainType
        original
        thumbnail_edited
        thumbnail_original
      }
      modelFileURL
      modelFileExtension
      modelFileMimetype
      materialFileUrl
      materialFileExtension
      materialFileMimetype
      wescanCode

      variety {
        id
        normativedesig
        alternativedesig
        code
      }
      volume
    }
  }
`;

export const QUERY_MANY_BLOCKMODELS = gql`
  query BlockModels($filter: BlockModelFilter, $paging: CursorPaging, $sorting: [BlockModelSort!]) {
    blockModels(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        node {
          baseArea
          commercial_variety {
            code
            id
            name
            description
          }
          materialtype {
            id
            code
            description {
              pt
            }
            companyNames {
              code
              description
            }
          }
          createdAt
          createdBy {
            id
            name
            lastname
            firstname
          }
          creationType
          description
          extheight
          extwidth
          id
          scannedBy {
            code
            scanner_model
          }
          tenantCode
          updatedAt
          regularImages {
            azureBlobID
            azureContainer
            azureStorageAccount
            edited
            imageFace
            mainType
            original
            thumbnail_edited
            thumbnail_original
          }
          wescanCode

          variety {
            id
            normativedesig
            alternativedesig
            code
          }
          volume
        }
      }
    }
  }
`;

export const QUERY_MANY_BLOCKMODELS_GALLERY = gql`
  query BlockModelsGallery(
    $filter: BlockModelFilter
    $paging: CursorPaging
    $sorting: [BlockModelSort!]
  ) {
    blockModels(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        node {
          slabs(paging: { first: 1 }) {
            totalCount
            edges {
              node {
                mainImage
                slabImages {
                  _id
                  edited
                  original
                  thumbnail_edited
                  thumbnail_original
                  mainType
                  azureBlobID
                }
                images
                id
                code
              }
            }
          }
          baseArea
          commercial_variety {
            code
            id
            name
            description
          }
          materialtype {
            id
            code
            description {
              pt
            }
            companyNames {
              code
              description
            }
          }
          createdAt
          createdBy {
            id
            name
            lastname
            firstname
          }
          creationType
          description
          extheight
          extwidth
          id
          scannedBy {
            code
            scanner_model
          }
          tenantCode
          updatedAt
          regularImages {
            azureBlobID
            azureContainer
            azureStorageAccount
            edited
            imageFace
            mainType
            original
            thumbnail_edited
            thumbnail_original
          }
          wescanCode

          variety {
            id
            normativedesig
            alternativedesig
            code
          }
          volume
        }
      }
    }
  }
`;

export const QUERY_BLOCKS_TO_FILTER = gql`
  query BlockModelsToFilter(
    $filter: BlockModelFilter
    $paging: CursorPaging
    $sorting: [BlockModelSort!]
  ) {
    blockModels(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id
          wescanCode
          tenantCode
        }
      }
    }
  }
`;

export const QUERY_MANY_SHARED_BLOCKS = gql`
  query SharedBlocks(
    $filter: BlockModelFilter
    $paging: CursorPaging
    $sorting: [BlockModelSort!]
  ) {
    sharedBlocks(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          baseArea
          commercial_variety {
            code
            id
            name
            description
          }
          materialtype {
            id
            code
            description {
              pt
            }
            companyNames {
              code
              description
            }
          }
          createdAt
          createdBy {
            id
            name
            lastname
            firstname
          }
          creationType
          description
          extheight
          extwidth
          id
          scannedBy {
            code
            scanner_model
          }
          tenantCode
          updatedAt
          regularImages {
            azureBlobID
            azureContainer
            azureStorageAccount
            edited
            imageFace
            mainType
            original
            thumbnail_edited
            thumbnail_original
          }
          wescanCode

          variety {
            id
            normativedesig
            alternativedesig
            code
          }
          volume
          slabs(paging: { first: 1 }) {
            totalCount
            edges {
              node {
                images
                mainImage
                slabImages {
                  _id
                  edited
                  original
                  thumbnail_edited
                  thumbnail_original
                  mainType
                  azureBlobID
                }
                id
                code
                commercial_variety {
                  name
                  materialtype {
                    description {
                      pt
                    }
                  }
                }
                typematerial {
                  description {
                    pt
                  }
                }
                finish {
                  description {
                    pt
                  }
                }
                variety {
                  normativedesig
                }
                commercial_finishing {
                  description {
                    pt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// PROJECT
export const QUERY_ONE_PROJECT = gql`
  query Project($id: ID!, $slabsPaging: CursorPaging, $packagesPaging: CursorPaging) {
    project(id: $id) {
      totalArea
      createdAt
      createdBy {
        id
        name
        lastname
        firstname
      }
      description
      id
      updatedAt
      active
      code
      project
      inStock
      observation
      packings(paging: $packagesPaging) {
        edges {
          node {
            code
            id
            packing
            observation
            packingtype {
              id
              code
              type
              observation
            }
            slabs {
              totalCount
              edges {
                node {
                  id
                  code
                  name
                  images
                  mainImage
                  slabImages {
                    _id
                    edited
                    original
                    thumbnail_edited
                    thumbnail_original
                    mainType
                    azureBlobID
                  }
                  width
                  height
                  thickness
                  createdate
                  blocknumber
                  inStock
                  company {
                    code
                    name
                  }
                  commercial_variety {
                    id
                    code
                    description
                    name
                    materialtype {
                      code
                      description {
                        pt
                      }
                    }
                    annexedDocuments {
                      nodes {
                        blob_url
                        id
                        mimetype
                        name
                      }
                    }
                  }
                  commercial_finishing {
                    code
                    description {
                      pt
                    }
                    id
                    observation {
                      pt
                    }
                    createdAt
                  }
                  typematerial {
                    description {
                      pt
                    }
                  }
                  category {
                    description {
                      pt
                    }
                  }
                  finish {
                    description {
                      pt
                    }
                  }
                  variety {
                    normativedesig
                  }
                }
              }
            }
          }
        }
      }
      slabs(paging: $slabsPaging) {
        edges {
          node {
            id
            code
            name
            images
            mainImage
            slabImages {
              _id
              edited
              original
              thumbnail_edited
              thumbnail_original
              mainType
              azureBlobID
            }
            width
            height
            thickness
            createdate
            blocknumber
            inStock
            company {
              code
              name
            }
            commercial_variety {
              id
              code
              description
              name
              materialtype {
                code
                description {
                  pt
                }
              }
              annexedDocuments {
                nodes {
                  blob_url
                  id
                  mimetype
                  name
                }
              }
            }
            commercial_finishing {
              code
              description {
                pt
              }
              id
              observation {
                pt
              }
              createdAt
            }
            typematerial {
              description {
                pt
              }
            }
            category {
              description {
                pt
              }
            }
            finish {
              description {
                pt
              }
            }
            variety {
              normativedesig
            }
          }
        }
      }
    }
  }
`;

export const QUERY_ONE_PROJECT_FOR_SIMPLE_EDIT = gql`
  query ProjectSimpleForEdit($id: ID!) {
    project(id: $id) {
      description
      id
      code
      project
      observation
    }
  }
`;

export const QUERY_MANY_PROJECTS = gql`
  query Projects($filter: ProjectFilter, $paging: CursorPaging, $sorting: [ProjectSort!]) {
    projects(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        node {
          observation
          createdAt
          createdBy {
            id
            name
            lastname
            firstname
          }
          description
          id
          updatedAt
          active
          code
          project
          packings {
            totalCount
          }
          slabs {
            totalCount
          }
        }
      }
    }
  }
`;

export const QUERY_ONE_SHARED_PROJECT = gql`
  query SharedProject(
    $keyValue: String
    $id: String
    $slabsPaging: CursorPaging
    $slabsFilter: SlabFilter
    $slabsSorting: [SlabSort!]
    $packagesPaging: CursorPaging
    $packagesFilter: PackingFilter
    $packagesSorting: [PackingSort!]
  ) {
    sharedProject(keyValue: $keyValue, id: $id) {
      createdAt
      observation
      createdBy {
        id
        name
        lastname
        firstname
      }
      description
      id
      updatedAt
      active
      code
      project
      packings(paging: $packagesPaging, filter: $packagesFilter, sorting: $packagesSorting) {
        edges {
          node {
            code
            id
            packing
            observation
            packingtype {
              id
              code
              type
              observation
            }
            slabs {
              edges {
                node {
                  mainImage
                  slabImages {
                    _id
                    edited
                    original
                    thumbnail_edited
                    thumbnail_original
                    mainType
                    azureBlobID
                  }
                  id
                  code
                  name
                  images
                  width
                  height
                  thickness
                  createdate
                  blocknumber
                  inStock
                  company {
                    code
                    name
                  }
                  commercial_variety {
                    id
                    code
                    description
                    name
                    materialtype {
                      code
                      description {
                        pt
                      }
                    }
                    annexedDocuments {
                      nodes {
                        blob_url
                        id
                        mimetype
                        name
                      }
                    }
                  }
                  commercial_finishing {
                    code
                    description {
                      pt
                    }
                    id
                    observation {
                      pt
                    }
                    createdAt
                  }
                  typematerial {
                    description {
                      pt
                    }
                  }
                  category {
                    description {
                      pt
                    }
                  }
                  finish {
                    description {
                      pt
                    }
                  }
                  variety {
                    normativedesig
                  }
                }
              }
            }
          }
        }
      }
      slabs(paging: $slabsPaging, filter: $slabsFilter, sorting: $slabsSorting) {
        edges {
          node {
            id
            code
            name
            images
            width
            height
            thickness
            createdate
            blocknumber
            inStock
            company {
              code
              name
            }
            mainImage
            slabImages {
              _id
              edited
              original
              thumbnail_edited
              thumbnail_original
              mainType
              azureBlobID
            }
            commercial_variety {
              id
              code
              description
              name
              materialtype {
                code
                description {
                  pt
                }
              }
              annexedDocuments {
                nodes {
                  blob_url
                  id
                  mimetype
                  name
                }
              }
            }
            commercial_finishing {
              code
              description {
                pt
              }
              id
              observation {
                pt
              }
              createdAt
            }
            typematerial {
              description {
                pt
              }
            }
            category {
              description {
                pt
              }
            }
            finish {
              description {
                pt
              }
            }
            variety {
              normativedesig
            }
          }
        }
      }
    }
  }
`;

export const QUERY_MANY_PROJECTS_GALLERY = gql`
  query ProjectGallery($filter: ProjectFilter, $paging: CursorPaging, $sorting: [ProjectSort!]) {
    projects(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          slabs(paging: { first: 1 }) {
            totalCount
            edges {
              node {
                images
                mainImage
                slabImages {
                  _id
                  edited
                  original
                  thumbnail_edited
                  thumbnail_original
                  mainType
                  azureBlobID
                }
                id
                code
                commercial_variety {
                  name
                  materialtype {
                    description {
                      pt
                    }
                  }
                }
                typematerial {
                  description {
                    pt
                  }
                }
                finish {
                  description {
                    pt
                  }
                }
                variety {
                  normativedesig
                }
                commercial_finishing {
                  description {
                    pt
                  }
                }
              }
            }
          }
          id
          code
          description
          observation
          inStock
          createdAt
          createdBy {
            id
            name
            lastname
            firstname
          }
          updatedAt
          project
        }
      }
    }
  }
`;

export const QUERY_SHARED_PROJECT_SLABS = gql`
  query SharedProjectSlabs(
    $keyValue: String
    $id: String
    $slabsPaging: CursorPaging
    $slabsFilter: SlabFilter
    $slabsSorting: [SlabSort!]
  ) {
    sharedProject(keyValue: $keyValue, id: $id) {
      id
      slabs(paging: $slabsPaging, filter: $slabsFilter, sorting: $slabsSorting) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
        edges {
          node {
            mainImage
            slabImages {
              _id
              edited
              original
              thumbnail_edited
              thumbnail_original
              mainType
              azureBlobID
            }
            id
            code
            name
            images
            width
            height
            thickness
            createdate
            blocknumber
            inStock
            company {
              code
              name
            }
            commercial_variety {
              id
              code
              description
              name
              materialtype {
                code
                description {
                  pt
                }
              }
              annexedDocuments {
                nodes {
                  blob_url
                  id
                  mimetype
                  name
                }
              }
            }
            commercial_finishing {
              code
              description {
                pt
              }
              id
              observation {
                pt
              }
              createdAt
            }
            typematerial {
              description {
                pt
              }
            }
            category {
              description {
                pt
              }
            }
            finish {
              description {
                pt
              }
            }
            variety {
              normativedesig
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SHARED_PROJECT_PACKAGES = gql`
  query SharedProjectPackages(
    $keyValue: String
    $id: String
    $packagesPaging: CursorPaging
    $packagesFilter: PackingFilter
    $packagesSorting: [PackingSort!]
  ) {
    sharedProject(keyValue: $keyValue, id: $id) {
      id
      packings(paging: $packagesPaging, filter: $packagesFilter, sorting: $packagesSorting) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
        edges {
          node {
            slabs(paging: { first: 1 }) {
              totalCount
              edges {
                node {
                  images
                  mainImage
                  slabImages {
                    _id
                    edited
                    original
                    thumbnail_edited
                    thumbnail_original
                    mainType
                    azureBlobID
                  }
                  id
                  code
                  commercial_variety {
                    name
                    materialtype {
                      description {
                        pt
                      }
                    }
                  }
                  typematerial {
                    description {
                      pt
                    }
                  }
                  finish {
                    description {
                      pt
                    }
                  }
                  variety {
                    normativedesig
                  }
                  commercial_finishing {
                    description {
                      pt
                    }
                  }
                }
              }
            }
            id
            code
            packing
            observation
            inStock
            packingtype {
              id
              code
              type
              observation
            }
            createdAt
            createdBy {
              id
              name
              lastname
              firstname
            }
            updatedAt
            project {
              id
              code
              project
            }
          }
        }
      }
    }
  }
`;
// PACKAGES

export const QUERY_ONE_PACKING = gql`
  query Packing($id: ID!, $slabsPaging: CursorPaging) {
    packing(id: $id) {
      id
      code
      packing
      observation
      inStock
      packingtype {
        id
        code
        type
        observation
      }
      project {
        id
        project
        code
      }
      slabs(paging: $slabsPaging) {
        edges {
          cursor
          node {
            id
            code
            name
            images
            mainImage
            slabImages {
              _id
              edited
              original
              thumbnail_edited
              thumbnail_original
              mainType
              azureBlobID
            }
            width
            height
            thickness
            createdate
            blocknumber
            inStock
            company {
              code
              name
            }
            commercial_variety {
              id
              code
              description
              name
              materialtype {
                code
                description {
                  pt
                }
              }
              annexedDocuments {
                nodes {
                  blob_url
                  id
                  mimetype
                  name
                }
              }
            }
            commercial_finishing {
              code
              description {
                pt
              }
              id
              observation {
                pt
              }
              createdAt
            }
            typematerial {
              description {
                pt
              }
            }
            category {
              description {
                pt
              }
            }
            finish {
              description {
                pt
              }
            }
            variety {
              normativedesig
            }
          }
        }
      }
    }
  }
`;

export const QUERY_MANY_PACKINGS = gql`
  query Packings($filter: PackingFilter, $paging: CursorPaging, $sorting: [PackingSort!]) {
    packings(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          slabs {
            totalCount
          }
          id
          code
          packing
          observation
          inStock
          packingtype {
            id
            code
            type
            observation
          }
          createdAt
          createdBy {
            id
            name
            lastname
            firstname
          }
          updatedAt
          project {
            id
            code
            project
          }
        }
      }
    }
  }
`;

export const QUERY_MANY_PACKINGS_GALLERY = gql`
  query PackingsGallery($filter: PackingFilter, $paging: CursorPaging, $sorting: [PackingSort!]) {
    packings(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          slabs(paging: { first: 1 }) {
            totalCount
            edges {
              node {
                images
                mainImage
                slabImages {
                  _id
                  edited
                  original
                  thumbnail_edited
                  thumbnail_original
                  mainType
                  azureBlobID
                }
                id
                code
                commercial_variety {
                  name
                  materialtype {
                    description {
                      pt
                    }
                  }
                }
                typematerial {
                  description {
                    pt
                  }
                }
                finish {
                  description {
                    pt
                  }
                }
                variety {
                  normativedesig
                }
                commercial_finishing {
                  description {
                    pt
                  }
                }
              }
            }
          }
          id
          code
          packing
          observation
          inStock
          packingtype {
            id
            code
            type
            observation
          }
          createdAt
          createdBy {
            id
            name
            lastname
            firstname
          }
          updatedAt
          project {
            id
            code
            project
          }
        }
      }
    }
  }
`;

export const QUERY_ONE_SHARED_PACKING = gql`
  query SharedPackage($keyValue: String, $id: String, $slabsPaging: CursorPaging) {
    sharedPackage(keyValue: $keyValue, id: $id) {
      id
      code
      packing
      observation
      inStock
      packingtype {
        id
        code
        type
        observation
      }
      slabs(paging: $slabsPaging) {
        edges {
          cursor
          node {
            mainImage
            slabImages {
              _id
              edited
              original
              thumbnail_edited
              thumbnail_original
              mainType
              azureBlobID
            }
            mainImage
            id
            code
            name
            images
            width
            height
            thickness
            createdate
            blocknumber
            inStock
            company {
              code
              name
            }
            commercial_variety {
              id
              code
              description
              name
              materialtype {
                code
                description {
                  pt
                }
              }
              annexedDocuments {
                nodes {
                  blob_url
                  id
                  mimetype
                  name
                }
              }
            }
            commercial_finishing {
              code
              description {
                pt
              }
              id
              observation {
                pt
              }
              createdAt
            }
            typematerial {
              description {
                pt
              }
            }
            category {
              description {
                pt
              }
            }
            finish {
              description {
                pt
              }
            }
            variety {
              normativedesig
            }
          }
        }
      }
    }
  }
`;

export const QUERY_MANY_SHARED_PACKAGES = gql`
  query SharedPackages($filter: PackingFilter, $paging: CursorPaging, $sorting: [PackingSort!]) {
    sharedPackages(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          slabs(paging: { first: 1 }) {
            totalCount
            edges {
              node {
                images
                mainImage
                slabImages {
                  _id
                  edited
                  original
                  thumbnail_edited
                  thumbnail_original
                  mainType
                  azureBlobID
                }
                id
                code
                commercial_variety {
                  name
                  materialtype {
                    description {
                      pt
                    }
                  }
                }
                typematerial {
                  description {
                    pt
                  }
                }
                finish {
                  description {
                    pt
                  }
                }
                variety {
                  normativedesig
                }
                commercial_finishing {
                  description {
                    pt
                  }
                }
              }
            }
          }
          id
          code
          packing
          observation
          inStock
          packingtype {
            id
            code
            type
            observation
          }
          createdAt
          createdBy {
            id
            name
            lastname
            firstname
          }
          updatedAt
          project {
            id
            code
            project
          }
        }
      }
    }
  }
`;

export const QUERY_SHARED_PACKING_SLABS = gql`
  query SharedPackageSlabs(
    $keyValue: String
    $id: String
    $slabsPaging: CursorPaging
    $slabsFilter: SlabFilter
    $slabsSorting: [SlabSort!]
  ) {
    sharedPackage(keyValue: $keyValue, id: $id) {
      slabs(paging: $slabsPaging, filter: $slabsFilter, sorting: $slabsSorting) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
        edges {
          cursor
          node {
            mainImage
            slabImages {
              _id
              edited
              original
              thumbnail_edited
              thumbnail_original
              mainType
              azureBlobID
            }
            mainImage
            id
            code
            name
            images
            width
            height
            thickness
            createdate
            blocknumber
            inStock
            company {
              code
              name
            }
            commercial_variety {
              id
              code
              description
              name
              materialtype {
                code
                description {
                  pt
                }
              }
              annexedDocuments {
                nodes {
                  blob_url
                  id
                  mimetype
                  name
                }
              }
            }
            commercial_finishing {
              code
              description {
                pt
              }
              id
              observation {
                pt
              }
              createdAt
            }
            typematerial {
              description {
                pt
              }
            }
            category {
              description {
                pt
              }
            }
            finish {
              description {
                pt
              }
            }
            variety {
              normativedesig
            }
          }
        }
      }
    }
  }
`;

// PACKAGE TYPES
export const QUERY_MANY_PACKAGE_TYPES = gql`
  query PackingTypes(
    $filter: PackingTypeFilter
    $paging: CursorPaging
    $sorting: [PackingTypeSort!]
  ) {
    packingTypes(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        node {
          id
          code
          observation
          createdAt
          type
          company {
            id
          }
        }
      }
    }
  }
`;

export const QUERY_ONE_PACKAGE_TYPE = gql`
  query PackingType($id: ID!) {
    packingType(id: $id) {
      id
      code
      observation
      createdAt
      type
    }
  }
`;

// COUNTERS

export const QUERY_MANY_COUNTERS = gql`
  query Counters($filter: CounterFilter, $paging: CursorPaging, $sorting: [CounterSort!]) {
    counters(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          company {
            name
            code
          }
          counterType
          createdAt
          description
          id
          sequence_value
        }
      }
    }
  }
`;

//#endregion

/*
 * ################################################################################
 * ################################################################################
 * ################                     MUTATIONS                  ################
 * ################################################################################
 * ################################################################################
 */
//#region MUTATIONS

// AUTH

export const MUTATION_LOGIN = gql`
  mutation Login($loginInput: LoginUserInput!) {
    login(loginInput: $loginInput) {
      access_token
    }
  }
`;

export const MUTATION_AUTH_SHARED_LINK = gql`
  mutation AutenticateSharedLink($keyValue: String!) {
    autenticateSharedLink(keyValue: $keyValue) {
      createdAt
      isValid
      temporaryToken
      validUntil
    }
  }
`;

// SLABS & IMAGES
export const MUTATION_UPLOAD_IMAGES = gql`
  mutation UploadImagesMutation($input: UploadImagesInputType!) {
    uploadImages(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_IMAGE = gql`
  mutation DeleteImage($input: DeleteImageInputType!) {
    deleteImage(input: $input) {
      error {
        message
        name
      }
      response {
        isSuccessful
        statusCode
      }
      result
    }
  }
`;

export const MUTATION_UPDATE_ONE_SLAB = gql`
  mutation UpdateOneSlab($input: UpdateOneSlabInput!) {
    updateOneSlab(input: $input) {
      main_image
      id
      code
      name
      area
      description
      desigcomercial
      width
      height
      thickness
      extwidth
      blocknumber
      extheight
      extthickness
      stkwidth
      stkheight
      stkthickness
      purchasewidth
      purchaseheight
      purchasethickness
      images
      mainImage
      slabImages {
        _id
        edited
        original
        thumbnail_edited
        thumbnail_original
        mainType
        azureBlobID
      }
      typematerial {
        id
        description {
          pt
        }
      }
      category {
        id
        description {
          pt
        }
      }
      finish {
        id
        description {
          pt
        }
      }
      project {
        id
        project
      }
      variety {
        id
        normativedesig
        macroscopicdesc
      }
      observation
      createdate
      scan {
        code
        description
      }
      job {
        description
      }
      packing {
        id
        code
        packing
      }
      inStock
      company {
        code
        name
      }
      commercial_variety {
        id
        code
        description
        name
        materialtype {
          code
          description {
            pt
          }
        }
      }
      commercial_finishing {
        code
        description {
          pt
        }
        id
        observation {
          pt
        }
        createdAt
      }
    }
  }
`;

export const MUTATION_UPDATE_MANY_SLABS = gql`
  mutation UpdateManySlabs($input: UpdateManySlabsInput!) {
    updateManySlabs(input: $input) {
      updatedCount
    }
  }
`;

export const UPLOAD_SLAB_WESCAN = gql`
  mutation UploadSlabs($input: UploadSlabsWeScanHomeInputType!) {
    uploadSlabs(input: $input) {
      main_image
      id
      code
      name
      width
      height
      thickness
      images
      mainImage
      slabImages {
        _id
        edited
        original
        thumbnail_edited
        thumbnail_original
        mainType
        azureBlobID
      }
      inStock
    }
  }
`;

export const DELETE_MANY_SLABS = gql`
  mutation DeleteManySlabs($input: DeleteManySlabsInput!) {
    deleteManySlabs(input: $input) {
      deletedCount
    }
  }
`;

export const SET_BLOB_METADATA = gql`
  mutation SetBlobMetadata($metadata: String!, $url: String!) {
    setBlobMetadata(metadata: $metadata, url: $url)
  }
`;

export const MUTATION_CATALOG_SLABS = gql`
  mutation CatalogManySlabs($input: CatalogManySlabsInputType!) {
    catalogManySlabs(input: $input) {
      code
    }
  }
`;

// USERS
export const MUTATION_CREATE_ONE_USER = gql`
  mutation CreateOneUser($input: CreateOneUserInput!) {
    createOneUser(input: $input) {
      avatar
      company {
        code
        name
      }
      createdAt
      email
      firstname
      id
      lastname
      name
      role
      status
      updatedAt
      username
    }
  }
`;

// STONE EDGES
export const MUTATION_CREATE_ONE_STONE_EDGE = gql`
  mutation CreateOneStoneEdge($input: CreateOneStoneEdgeInput!) {
    createOneStoneEdge(input: $input) {
      code
      name
      id
      description
    }
  }
`;

export const MUTATION_DELETE_ONE_STONE_EDGE = gql`
  mutation DeleteOneStoneEdge($input: DeleteOneStoneEdgeInput!) {
    deleteOneStoneEdge(input: $input) {
      code
    }
  }
`;

export const MUTATION_UPDATE_ONE_STONE_EDGE = gql`
  mutation UpdateOneStoneEdge($input: UpdateOneStoneEdgeInput!) {
    updateOneStoneEdge(input: $input) {
      code
    }
  }
`;

// STONE MODELS
export const MUTATION_CREATE_ONE_STONE_MODEL = gql`
  mutation CreateOneStoneModel($input: CreateOneStoneModelInput!) {
    createOneStoneModel(input: $input) {
      code
      name
      id
      description
    }
  }
`;

export const MUTATION_DELETE_ONE_STONE_MODEL = gql`
  mutation DeleteOneStoneModel($input: DeleteOneStoneModelInput!) {
    deleteOneStoneModel(input: $input) {
      code
    }
  }
`;

export const MUTATION_UPDATE_ONE_STONE_MODEL = gql`
  mutation UpdateOneStoneModel($input: UpdateOneStoneModelInput!) {
    updateOneStoneModel(input: $input) {
      code
    }
  }
`;

// JOBS
export const MUTATION_CREATE_ONE_JOB = gql`
  mutation CreateOneJob($input: CreateOneJobInput!) {
    createOneJob(input: $input) {
      id
      code
      totalslab
      sequence
    }
  }
`;

export const MUTATION_UPDATE_ONE_JOB = gql`
  mutation UpdateOneJob($input: UpdateOneJobInput!) {
    updateOneJob(input: $input) {
      id
      code
      totalslab
      sequence
    }
  }
`;

export const MUTATION_DELETE_ONE_JOB = gql`
  mutation DeleteOneJob($input: DeleteOneJobInput!) {
    deleteOneJob(input: $input) {
      code
    }
  }
`;

// COLOR FILTERS
export const MUTATION_CREATE_ONE_IMAGECOLORFILTER = gql`
  mutation CreateOneImageColorFilter($input: CreateOneImageColorFilterInput!) {
    createOneImageColorFilter(input: $input) {
      id
      name
      valueFilter {
        brightness
        contrast
        gamma
        hue_rotation
        saturation
        temperature
        exposure
      }
      commercial_variety {
        code
        id
        description
        name
      }
      commercial_finishing {
        id
        code
        description {
          pt
          en
          es
        }
      }
    }
  }
`;

export const MUTATION_UPDATE_ONE_IMAGECOLORFILTER = gql`
  mutation UpdateOneImageColorFilter($input: UpdateOneImageColorFilterInput!) {
    updateOneImageColorFilter(input: $input) {
      id
      name
      valueFilter {
        brightness
        contrast
        gamma
        hue_rotation
        saturation
        temperature
        exposure
      }
      commercial_variety {
        code
        id
        description
        name
      }
      commercial_finishing {
        id
        code
        description {
          pt
          en
          es
        }
      }
    }
  }
`;

export const MUTATION_DELETE_ONE_IMAGECOLORFILTER = gql`
  mutation DeleteOneImageColorFilter($input: DeleteOneImageColorFilterInput!) {
    deleteOneImageColorFilter(input: $input) {
      name
    }
  }
`;

// PROJECT
export const MUTATION_CREATE_ONE_PROJECT = gql`
  mutation CreateOneProject($input: CreateOneProjectInput!) {
    createOneProject(input: $input) {
      id
      project
    }
  }
`;

export const MUTATION_UPDATE_ONE_PROJECT = gql`
  mutation UpdateOneProject($input: UpdateOneProjectInput!) {
    updateOneProject(input: $input) {
      id
      project
    }
  }
`;

export const MUTATION_DELETE_ONE_PROJECT = gql`
  mutation DeleteOneProject($input: DeleteOneProjectInput!) {
    deleteOneProject(input: $input) {
      project
      id
    }
  }
`;

// PACKING/PACKAGE
export const MUTATION_CREATE_ONE_PACKING = gql`
  mutation CreateOnePacking($input: CreateOnePackingInput!) {
    createOnePacking(input: $input) {
      id
      packing
    }
  }
`;

export const MUTATION_UPDATE_ONE_PACKING = gql`
  mutation UpdateOnePacking($input: UpdateOnePackingInput!) {
    updateOnePacking(input: $input) {
      id
      packing
    }
  }
`;

export const MUTATION_UPDATE_MANY_PACKINGS = gql`
  mutation UpdateManyPackings($input: UpdateManyPackingsInput!) {
    updateManyPackings(input: $input) {
      updatedCount
    }
  }
`;

export const MUTATION_DELETE_ONE_PACKING = gql`
  mutation DeleteOnePacking($input: DeleteOnePackingInput!) {
    deleteOnePacking(input: $input) {
      id
      packing
    }
  }
`;

export const MUTATION_DELETE_MANY_PACKINGS = gql`
  mutation DeleteManyPackings($input: DeleteManyPackingsInput!) {
    deleteManyPackings(input: $input) {
      deletedCount
    }
  }
`;

// Shareable Links
export const MUTATION_CREATE_ONE_SHAREABLE_LINK = gql`
  mutation CreateOneShareLink($input: CreateOneShareLinkInput!) {
    createOneShareLink(input: $input) {
      id
      isValid
      package {
        id
      }
      project {
        id
      }
      validUntil
      keyValue
      block {
        id
      }
      slab {
        id
      }
    }
  }
`;

export const MUTATION_UPDATE_ONE_SHAREABLE_LINK = gql`
  mutation UpdateOneShareLink($input: UpdateOneShareLinkInput!) {
    updateOneShareLink(input: $input) {
      id
    }
  }
`;

// ANNEXED DOCUMENTS
export const UPLOAD_ONE_ANNEXED_DOCUMENT = gql`
  mutation CreateOneAnnexedDocument($file: Upload!, $input: CreateOneAnnexedDocumentInput!) {
    createOneAnnexedDocument(file: $file, input: $input) {
      id
    }
  }
`;

export const DELETE_ONE_ANNEXED_DOCUMENT = gql`
  mutation DeleteOneAnnexedDocument($input: DeleteOneAnnexedDocumentInput!) {
    deleteOneAnnexedDocument(input: $input) {
      id
    }
  }
`;

// COMMERCIAL VARIETIES
export const MUTATION_CREATE_ONE_COMMERCIAL_VARIETY = gql`
  mutation CreateOneCommercialVariety($input: CreateOneCommercialVarietyInput!) {
    createOneCommercialVariety(input: $input) {
      id
    }
  }
`;

export const MUTATION_UPDATE_ONE_COMMERCIAL_VARIETY = gql`
  mutation UpdateOneCommercialVariety($input: UpdateOneCommercialVarietyInput!) {
    updateOneCommercialVariety(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_ONE_COMMERCIAL_VARIETY = gql`
  mutation DeleteOneCommercialVariety($input: DeleteOneCommercialVarietyInput!) {
    deleteOneCommercialVariety(input: $input) {
      id
    }
  }
`;

// COMMERCIAL FINISHINGS
export const MUTATION_CREATE_ONE_COMMERCIAL_FINISHING = gql`
  mutation CreateOneCommercialFinishing($input: CreateOneCommercialFinishingInput!) {
    createOneCommercialFinishing(input: $input) {
      id
    }
  }
`;

export const MUTATION_UPDATE_ONE_COMMERCIAL_FINISHING = gql`
  mutation UpdateOneCommercialFinishing($input: UpdateOneCommercialFinishingInput!) {
    updateOneCommercialFinishing(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_ONE_COMMERCIAL_FINISHING = gql`
  mutation DeleteOneCommercialFinishing($input: DeleteOneCommercialFinishingInput!) {
    deleteOneCommercialFinishing(input: $input) {
      id
    }
  }
`;

// BLOCKMODEL
export const MUTATION_DELETE_ONE_BLOCKMODEL = gql`
  mutation DeleteOneBlockModel($input: DeleteOneBlockModelInput!) {
    deleteOneBlockModel(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_MANY_BLOCKMODELS = gql`
  mutation DeleteManyBlockModels($input: DeleteManyBlockModelsInput!) {
    deleteManyBlockModels(input: $input) {
      deletedCount
    }
  }
`;

export const MUTATION_UPDATE_ONE_BLOCK_STREAM = gql`
  mutation UpdateOneBlockStream(
    $update: UpdateOneBlockModelUploadInputType!
    $frontFaceImage: Upload
    $backFaceImage: Upload
    $topFaceImage: Upload
    $leftFaceImage: Upload
    $rightFaceImage: Upload
    $modelFile: Upload
    $materialFile: Upload
  ) {
    updateOneBlockStream(
      update: $update
      frontFaceImage: $frontFaceImage
      backFaceImage: $backFaceImage
      topFaceImage: $topFaceImage
      leftFaceImage: $leftFaceImage
      rightFaceImage: $rightFaceImage
      modelFile: $modelFile
      materialFile: $materialFile
    ) {
      id
    }
  }
`;

// PACKAGE TYPES
export const MUTATION_CREATE_ONE_PACKAGE_TYPE = gql`
  mutation CreateOnePackageType($input: CreateOnePackingTypeInput!) {
    createOnePackingType(input: $input) {
      id
    }
  }
`;

export const MUTATION_UPDATE_ONE_PACKAGE_TYPE = gql`
  mutation UpdateOnePackageType($input: UpdateOnePackingTypeInput!) {
    updateOnePackingType(input: $input) {
      id
    }
  }
`;

export const MUTATION_DELETE_ONE_PACKAGE_TYPE = gql`
  mutation DeleteOnePackageType($input: DeleteOnePackingTypeInput!) {
    deleteOnePackingType(input: $input) {
      id
    }
  }
`;

//#endregion

/*
 * ################################################################################
 * ################################################################################
 * ################                  SUBSCRIPTIONS                 ################
 * ################################################################################
 * ################################################################################
 */
//#region SUBSCRIPTION

/**
 * Subscribe to Scanner Slab Uploads (states are managed by the API)
 */
export const SCANNER_UPLOAD_STATE_SUBSCRIPTION = gql`
  subscription SubscribeToScannerUpload($filter: ScannerFilter) {
    subscribeToScannerSlabUploadState(filter: $filter) {
      stateDescription
      state
      numberOfImagesToUpload
      startedAt
      payload {
        area
        dpi
        height
        strfiletype
        thickness
        width
      }
      scanner {
        id
        description
      }
      imagesArray
      slab {
        iscatalogued
        main_image
        erp_id
        id
        code
        name
        area
        description
        desigcomercial
        width
        height
        thickness
        extwidth
        blocknumber
        block {
          id
          wescanCode
          tenantCode
        }
        extheight
        extthickness
        stkwidth
        stkheight
        stkthickness
        purchasewidth
        purchaseheight
        purchasethickness
        iscatalogued
        cancelCataloguing
        images
        mainImage
        slabImages {
          _id
          edited
          original
          thumbnail_edited
          thumbnail_original
          mainType
          azureBlobID
        }
        typematerial {
          id
          description {
            pt
          }
        }
        category {
          id
          description {
            pt
          }
        }
        finish {
          id
          description {
            pt
          }
        }
        project {
          id
          project
        }
        variety {
          id
          normativedesig
          macroscopicdesc
        }
        observation
        createdate
        scan {
          code
          description
        }
        job {
          description
        }
        packing {
          id
          code
          packing
        }
        inStock
        company {
          code
          name
        }
        commercial_variety {
          id
          code
          description
          name
          materialtype {
            code
            description {
              pt
            }
          }
        }
        commercial_finishing {
          code
          description {
            pt
          }
          id
          observation {
            pt
          }
          createdAt
        }
        stone_edge {
          code
          id
          name
          description
        }
        stone_model {
          code
          id
          name
          description
        }
      }
    }
  }
`;

/**
 * Subscribe to Scanner Operation State (states are managed by the Scanner)
 */
export const SCANNER_OPERATION_STATE_SUBSCRIPTION = gql`
  subscription SubscribeToScannerOperation($filter: ScannerFilter) {
    subscribeToScannerOperationState(filter: $filter) {
      stateDescription
      state
      numberOfImagesToUpload
      startedAt
      payload {
        area
        dpi
        height
        strfiletype
        thickness
        width
      }
      scanner {
        id
        description
      }
    }
  }
`;

//#endregion
