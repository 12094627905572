import { Box, Button, Card, CardContent, Grid, GridProps, IconButton } from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { H1 } from "../GeneralComponents/Typography";

interface NewTablePageHeaderComponentProps {
  entityNamePlural?: string;
  title?: string;
  buttonTitle?: string;
  pathEntity?: string;
  hasButton?: boolean;
  gridProps?: GridProps;
}

const NewTablePageHeaderComponent = ({
  entityNamePlural,
  title,
  buttonTitle = "",
  pathEntity = "",
  hasButton = true,
  gridProps = {},
}: NewTablePageHeaderComponentProps) => {
  const history = useHistory();

  function handleGoBack(event: any) {
    event.preventDefault();
    history.goBack();
  }

  return (
    <Grid {...gridProps}>
      <Card>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Box
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton onClick={handleGoBack} disabled={history?.length <= 1} color="primary">
              <ArrowBackIosIcon fontSize="large" />
            </IconButton>
            {!!title ? <H1>{title}</H1> : <H1>List of {entityNamePlural}</H1>}
          </Box>
          <Box
            style={{
              alignItems: "center",
            }}
          >
            {hasButton && (
              <NavLink to={`${pathEntity}/create`}>
                <Button color="primary" variant="contained">
                  {buttonTitle}
                </Button>
              </NavLink>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default NewTablePageHeaderComponent;
