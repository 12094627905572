import React from "react";
import { Backdrop, CircularProgress, CircularProgressProps } from "@material-ui/core";

interface FullScreenActivityIndicatorProps {
  ProgressProps?: CircularProgressProps;
}

export default function FullScreenActivityIndicator({
  ProgressProps,
}: FullScreenActivityIndicatorProps) {
  return (
    <Backdrop
      style={{
        zIndex: 10000,
        color: "#fff",
      }}
      open
    >
      <CircularProgress {...ProgressProps} />
    </Backdrop>
  );
}
