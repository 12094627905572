import React, { useState, useCallback, useEffect } from "react";
import { Typography, IconButton, CardContent, Box, useTheme, Grid } from "@material-ui/core";
import {
  RemoveRedEye,
  CheckCircleOutline,
  CheckCircle,
  Image as ImageIcon,
  BurstMode,
  Block,
  NotInterested,
  Check,
  OpenInNew,
} from "@material-ui/icons";
import "./styles.css";
import { getSlabImageSafe, getThumbnail, openNewTab } from "../../utils/utils";
import { Slabs_slabs_nodes } from "../../data/graphQLModel";
import { grey } from "../../theme/themeColors";
import FlexBox from "../GeneralComponents/FlexBox";
import ImageBox from "../GeneralComponents/ImageBox";
import { H3, H5 } from "../GeneralComponents/Typography";
import SlabDetailsDialog from "../slabs.components/SlabDetailsDialog";
import { layoutConstant } from "../../utils/constants";

const getVariety = (slab: Slabs_slabs_nodes) => {
  return (
    " " +
    (slab?.commercial_variety?.name
      ? slab?.commercial_variety.name
      : slab?.variety?.normativedesig
      ? slab.variety.normativedesig
      : "< >")
  );
};

const getFinish = (slab: Slabs_slabs_nodes) => {
  return slab?.commercial_finishing?.description
    ? slab?.commercial_finishing?.description?.pt
    : slab?.finish?.description?.pt
    ? slab?.finish?.description?.pt
    : "< >";
};

const getMaterial = (slab: Slabs_slabs_nodes) => {
  return slab?.commercial_variety?.materialtype?.description?.pt
    ? slab?.commercial_variety?.materialtype?.description.pt
    : slab?.typematerial?.description?.pt
    ? slab?.typematerial?.description?.pt
    : "< >";
};

const transformDate = (date: any) => {
  let date_hour = date.split("T");
  return `${date_hour[0]} ${date_hour[1].split(".")[0]}`;
};

interface StoneProps {
  stone: Slabs_slabs_nodes;
  onChange: any;
  isChecked?: boolean;
  listOfChecked?: Slabs_slabs_nodes[] | null | undefined;
}

export default function Stone({
  stone,
  onChange,
  isChecked = false,
  listOfChecked = null,
}: StoneProps) {
  const [checked, setChecked] = useState(isChecked);
  const theme = useTheme();

  const [selectedDetails, setSelectedDetails] = useState<string | null>(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const toggleDialog = useCallback(() => {
    setOpenDetailsDialog((openDetailsDialog) => !openDetailsDialog);
  }, []);

  // hver
  const [mouseHover, setMouseHover] = useState<boolean>(false);

  let i = getSlabImageSafe(stone, true) ?? "../images/imagemissing.png";
  const onClickSlab = (e: any, check: boolean) => {
    console.log(check);
    setChecked(check);
    onChange(stone, check);
  };

  // refresh on slabSelection
  useEffect(() => {
    if (listOfChecked) {
      setChecked(!!listOfChecked.find((el) => el.id === stone.id));
    }
  }, [listOfChecked]);

  return (
    <ImageBox
      className="card"
      sx={{
        cursor: "pointer",
        borderWidth: 0,
        ":hover": {
          boxShadow: !checked ? theme.shadows[24] : "none",
          borderWidth: 3,
          borderColor: !checked ? theme.palette.grey[600] : theme.palette.grey[100],
        },
      }}
      hoverEffect
      style={
        checked
          ? {
              borderColor: theme.palette.secondary.main,
              borderWidth: 3,
            }
          : {}
      }
      onMouseEnter={() => {
        if (!mouseHover && !checked) {
          setMouseHover(true);
        }
      }}
      onMouseLeave={() => {
        if (mouseHover && !checked) {
          setMouseHover(false);
        }
      }}
    >
      <div className="extra-icons">
        <IconButton
          style={{
            padding: "3px",
            position: "absolute",
            top: 0,
            right: 0,
            cursor: "pointer",
            zIndex: layoutConstant.topBarZIndex - 1,
          }}
          onClick={() => {
            setSelectedDetails(stone?.id);
            toggleDialog();
          }}
          size="medium"
        >
          <RemoveRedEye color="secondary" fontSize="medium" />
        </IconButton>
        <Box
          style={{
            padding: "3px",
            position: "absolute",
            top: 0,
            left: 0,
            cursor: "pointer",
            zIndex: layoutConstant.topBarZIndex - 1,
          }}
        >
          {mouseHover || checked ? (
            <CheckCircle color="secondary" fontSize="medium" />
          ) : (
            <CheckCircleOutline color="secondary" fontSize="medium" />
          )}
        </Box>
      </div>
      <img src={i} alt={stone.code} onClick={(e) => onClickSlab(e, !checked)} />
      {!!stone?.slabImages && stone?.slabImages?.length > 0 && (
        <FlexBox
          sx={{
            flexDirection: "row",
            width: 80,
            justifyContent: "space-evenly",
            position: "absolute",
            top: "260px",
            right: 0,
            backgroundColor: grey.light,
          }}
        >
          <Typography variant="h5">{stone?.slabImages?.length}</Typography>
          <ImageIcon fontSize="large" color="action" />
        </FlexBox>
      )}

      <CardContent onClick={(e) => onClickSlab(e, !checked)}>
        <FlexBox>
          <FlexBox flexDirection={"column"} flex={3}>
            <H3 fontWeight="bold" fontSize={18} color={"grey.600"} ellipsis>
              {stone.code}
            </H3>
            <H3 fontWeight="bold" fontSize={18}>
              {getVariety(stone)}
            </H3>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <H5 fontWeight="light" fontSize={17} color={"grey.600"}>
                {"Finishing: "}
              </H5>
              <H5 fontWeight="bold" fontSize={17} marginLeft={1} color={"primary.main"}>
                {getFinish(stone)}
              </H5>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <H5 fontWeight="light" fontSize={17} color={"grey.600"}>
                {"Material: "}
              </H5>
              <H5 fontWeight="bold" fontSize={17} marginLeft={1} color={"primary.main"}>
                {getMaterial(stone)}
              </H5>
            </Box>
            <H5 fontWeight="bold" fontSize={17} color={"grey.600"}>
              {stone?.createdate && transformDate(stone.createdate.toString())}
            </H5>
            <H5 fontWeight="bold" fontSize={17} color={"grey.600"}>
              {stone.width ? stone.width : 0} X {stone.height ? stone.height : 0} X{" "}
              {stone.thickness ? stone.thickness : 0}
            </H5>
          </FlexBox>
          <Grid container alignSelf={"flex-start"} flex={1} spacing={2}>
            <Grid item>
              {stone.inStock ? (
                <Check
                  style={{
                    color: "green",
                    width: 30,
                    height: 30,
                    marginLeft: 5,
                  }}
                />
              ) : (
                <NotInterested
                  style={{
                    color: "red",
                    width: 30,
                    height: 30,
                    marginLeft: 5,
                  }}
                />
              )}
            </Grid>
            <Grid item>
              {stone.cancelCataloguing ? (
                <Block fontSize="large" color="action" titleAccess="Canceled" />
              ) : stone.iscatalogued ? (
                <BurstMode fontSize="large" color="action" titleAccess="Catalogued" />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item>
              {stone?.packing?.code && (
                <Box
                  fontWeight="fontWeightBold"
                  fontSize={14}
                  color={"grey.600"}
                  style={{
                    borderRadius: 5,
                    backgroundColor: theme.palette.warning.light,
                    paddingRight: 5,
                    paddingLeft: 5,
                  }}
                >
                  {stone?.packing?.code}
                </Box>
              )}
            </Grid>
          </Grid>
        </FlexBox>
      </CardContent>
      <FlexBox sx={{ justifyContent: "flex-end" }}>
        <IconButton
          color="primary"
          onClick={() => {
            openNewTab("/slabmanagement/details/" + btoa(stone?.id as string));
          }}
          size={"medium"}
        >
          <OpenInNew fontSize="medium" />
        </IconButton>
      </FlexBox>
      {selectedDetails && (
        <SlabDetailsDialog open={openDetailsDialog} toggle={toggleDialog} slab={selectedDetails} />
      )}
    </ImageBox>
  );
}
