import { Box, BoxProps, useTheme } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import React from "react";

type ImageBoxProps = {
  hoverEffect?: boolean;
};

const ImageBox = styled<React.FC<ImageBoxProps & BoxProps>>(
  ({ hoverEffect, children, ...rest }) => {
    const theme = useTheme();
    return (
      <Box
        {...rest}
        sx={{
          borderRadius: "8px",
          overflow: "unset",
          transition: "all 250ms ease-in-out",
          "&:hover": hoverEffect
            ? {
                boxShadow: hoverEffect ? theme.shadows[3] : "",
                backgroundColor: theme.palette.grey[300],
              }
            : undefined,
        }}
      >
        {children}
      </Box>
    );
  }
)<ImageBoxProps>(({ theme, hoverEffect, ...props }) => () => {
  return `
    ${
      hoverEffect
        ? `
    a {
      color: inherit; /* blue colors for links too */
      text-decoration: none; /* no underline */
    }
    a:hover {
      color: inherit; /* blue colors for links too */
      text-decoration: none; /* no underline */
    }
    img {
      --s: 15px;  /l* size of the frame */
      --b: 2px;   /* border thickness */
      --w: 25vh; /* width of the image */
      
      width: 100%;
      aspect-ratio: 1;
      object-fit: contain;
      transition: .4s;
      cursor: pointer;
      max-height: 60%;
      height: 60%
    }
    `
        : `
        a {
          color: inherit; /* blue colors for links too */
          text-decoration: inherit; /* no underline */
        }
        img {
          width: 100%;
          aspect-ratio: 1;
          object-fit: contain;
          transition: .4s;
          cursor: default;
          max-height: 60%;
          height: 60%;
        }
        `
    }
    `;
});

ImageBox.defaultProps = {
  hoverEffect: false,
};

export default ImageBox;
