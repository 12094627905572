import React from "react";
import "date-fns";
import { TextField, Autocomplete, AutocompleteProps } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/styles";
import { MuiThemeProps } from "../../theme/theme";
import { primary, success } from "../../theme/themeColors";

const useStyles = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  formControl: {
    minWidth: 200,
  },
}));

// https://stackoverflow.com/questions/58984406/setting-text-color-outline-and-padding-on-material-ui-autocomplete-component
const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-inputRoot": {
    color: primary.main,
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: primary.main,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: success.main,
    },
  },
});

const getField = (row: any, id: string) => {
  let result = "";
  if (id.indexOf(".") >= 0) {
    result = row;
    let fields = id.split(".");
    fields.forEach((el) => {
      result = result[el as keyof typeof result] as string;
    });
  } else {
    result = row[id];
  }
  return result;
};

export interface AutocompleteFilterNewProps {
  items: any;
  title: string;
  value: any;
  label: string;
  name: string;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  field?: string;
  extraFields?: string[];
  error?: boolean | undefined;
  helperText?: string | false | undefined;
  onBlur?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  AutocompleteProps?: Partial<AutocompleteProps<any, any, any, any, any>>;
  [key: string]: any;
}

export default function AutocompleteFilterJustText({
  items,
  title,
  value,
  label,
  onChange,
  error,
  helperText,
  onBlur,
  name,
  field,
  extraFields,
  AutocompleteProps,
  ...rest
}: AutocompleteFilterNewProps) {
  const classes = useStyles();
  const defaultVal = value ? value : null;
  return (
    <StyledAutocomplete
      {...AutocompleteProps}
      id={name}
      className={classes.formControl}
      onChange={(event, value) => {
        onChange({ ...event, target: { ...event.target, value: value, name: name } });
      }}
      title={title}
      value={defaultVal}
      options={items}
      disabled={!items || items.length === 0}
      clearOnEscape={true}
      getOptionLabel={(i) => {
        return (
          "" +
          (field ? getField(i, field) : i) +
          (extraFields ? " - " + extraFields.map((el) => getField(i, el)).join(" ") : "")
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onBlur={onBlur}
            label={label}
            error={error}
            helperText={helperText}
            name={name}
            style={{ color: "green" }}
          />
        );
      }}
      {...rest}
    />
  );
}
