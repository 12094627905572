import { Box, Button, Card, CardContent } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const TablePageHeaderComponent = ({
	entityNamePlural,
	buttonTitle,
	pathEntity,
	hasButton = true,
}) => (
	<div className="container" style={{ marginTop: "70px" }}>
		<Box style={{ marginBottom: "10px" }}>
			<Box>
				<Card>
					<CardContent
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Box>
							<h2 style={{ marginBottom: "-10px" }}>
								List of {entityNamePlural}
							</h2>
						</Box>
						<Box
							style={{
								alignItems: "center",
								marginBottom: "-10px",
							}}
						>
							{hasButton && (
								<NavLink to={`${pathEntity}/create`}>
									<Button color="primary" variant="contained">
										{buttonTitle}
									</Button>
								</NavLink>
							)}
						</Box>
					</CardContent>
				</Card>
			</Box>
		</Box>
	</div>
);

export default TablePageHeaderComponent;
