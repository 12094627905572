import { ICompleteHeadCells } from "../../../components/Table/NewGeneralTableComponent";
import { PackingsGallery_packings_edges_node } from "../../../data/graphQLModel";
import { getImageEntityURLString } from "../../../utils/utils";

export const packingHeadCellsDefault: ICompleteHeadCells[] = [
  { id: "slabImages", numeric: false, disablePadding: false, label: "" },
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "packing", numeric: false, disablePadding: false, label: "Package" },
  { id: "packingType", numeric: false, disablePadding: false, label: "Packing Type" },
  { id: "observation", numeric: false, disablePadding: false, label: "Observation" },
  { id: "slabs", numeric: false, disablePadding: false, label: "Slabs" },
];

export const getPackagesRowDefaultValue = (
  row: PackingsGallery_packings_edges_node
): ICompleteHeadCells[] => {
  return [
    {
      id:
        row?.slabs?.edges?.[0]?.node?.slabImages?.map((el) => getImageEntityURLString(el) || "") ||
        "",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: row?.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    {
      id: row?.packing,
      numeric: false,
      disablePadding: false,
      label: "Package",
    },
    {
      id: row.packingtype?.type || "",
      numeric: false,
      disablePadding: false,
      label: "Packing Type",
    },
    {
      id: row?.observation || "",
      numeric: false,
      disablePadding: false,
      label: "Observation",
    },
    {
      id: (row?.slabs?.totalCount || 0) + "",
      numeric: true,
      disablePadding: false,
      label: "Slabs",
    },
  ];
};

export const packingHeadCellsWithAction: ICompleteHeadCells[] = [
  { id: "slabImages", numeric: false, disablePadding: false, label: "" },
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "packing", numeric: false, disablePadding: false, label: "Package" },
  { id: "packingType", numeric: false, disablePadding: false, label: "Packing Type" },
  { id: "observation", numeric: false, disablePadding: false, label: "Observation" },
  { id: "slabs", numeric: false, disablePadding: false, label: "Slabs" },
  { id: "", numeric: false, disablePadding: false, label: "" },
];

export const getPackagesRowWithAction = (
  element: React.FC<{ selected: PackingsGallery_packings_edges_node }>
): ((row: PackingsGallery_packings_edges_node) => ICompleteHeadCells[]) => {
  return (row: PackingsGallery_packings_edges_node): ICompleteHeadCells[] => [
    {
      id:
        row?.slabs?.edges?.[0]?.node?.slabImages?.map((el) => getImageEntityURLString(el) || "") ||
        "",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: row?.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    {
      id: row?.packing,
      numeric: false,
      disablePadding: false,
      label: "Package",
    },
    {
      id: row.packingtype?.type || "",
      numeric: false,
      disablePadding: false,
      label: "Packing Type",
    },
    {
      id: row?.observation || "",
      numeric: false,
      disablePadding: false,
      label: "Observation",
    },
    {
      id: (row?.slabs?.totalCount || 0) + "",
      numeric: true,
      disablePadding: false,
      label: "Slabs",
    },
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
      element: element,
    },
  ];
};
