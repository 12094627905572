import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import MuiTheme from "./theme/MuiTheme";
import { ApolloGlobalProvider } from "./utils/ApolloGlobalProvider";
import { Toaster } from "react-hot-toast";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import "core-js";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./services/azureAppInsights";

export const cache = createCache({ key: "css", prepend: true });
const Root = () => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <CacheProvider value={cache}>
        <BrowserRouter>
          <Provider store={store}>
            <ApolloGlobalProvider>
              <MuiTheme>
                <App />
              </MuiTheme>
              <Toaster />
            </ApolloGlobalProvider>
          </Provider>
        </BrowserRouter>
      </CacheProvider>
    </AppInsightsContext.Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
