import {
  BlockModelFilter,
  Categories_categories_pageInfo,
  CursorPaging,
  MainImageType,
  OffsetPaging,
  Slabs_slabs_nodes,
  Slabs_slabs_pageInfo,
} from "../data/graphQLModel";

import {
  isBrowser,
  isMobile,
  browserName,
  browserVersion,
  isChrome,
  isFirefox,
} from "react-device-detect";
import { weScanDateTime } from "./Types";
import { ISelectedImageInfo } from "../components/Blocks/BlocksDetailsComponent";
import { Buffer } from "buffer";

export const file2Base64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = (error) => reject(error);
  });
};

export const getUploadedImageSizes = (file: File): Promise<{ [key: string]: any }> => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = async (e: any) => {
      var image = new Image();
      image.src = e.target.result;
      await image.decode();
      resolve({ width: image.width, height: image.height });
    };
    reader.readAsDataURL(file);
  });
};

export const buildMetadataForGraphQL = (object: Object): string => {
  return JSON.stringify(object).replace(/"/g, "/");
};

export const download = async (url: string): Promise<Blob> => {
  return await fetch(url).then((resp) => resp.blob());
};

export const getThumbnail = (url: string): string => {
  // get last index of '/'
  let last_index = url?.lastIndexOf("/");
  // slice the string in 2, one from the start to the lastIndexOf
  // and then replace the word in the rest
  let pat = new RegExp("/", "i");
  url = url?.slice(0, last_index) + url?.slice(last_index)?.replace(pat, "/thumbnail_");
  return url;
};

export const copyObject = <T>(obj: T, keysToRemove: Array<keyof T>): T => {
  const newObj = {} as T;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const element = obj[key];
      if (keysToRemove.indexOf(key) === -1) {
        newObj[key] = element;
      }
    }
  }
  return newObj;
};

/**
 * Function to find the value of a given object's keys with a preference order
 * @param obj Object with wanted values
 * @param arrOfFieldOrderedByPreference Array of keys of obj ordered by preference of which keys' value we are looking for
 * @returns Value of first found valid field or empty string if nothing was found
 */
export const getFieldValueOfObjectByPreference = <T>(
  obj: T,
  arrOfFieldOrderedByPreference: Array<keyof T>
): any => {
  for (let index = 0; index < arrOfFieldOrderedByPreference.length; index++) {
    const element = obj[arrOfFieldOrderedByPreference[index]];
    if (!!element || (typeof element === "number" && element === 0)) {
      if (typeof element === "string") {
        if (element.trim() !== "") {
          return element;
        }
      }
      return element;
    }
  }
  return "";
};

/**
 * Checks the true truthy value of string, and returns the string if true
 * @param str String to check value of
 * @returns True value of string
 */
export const trueValueOfString = (str: string | null | undefined): boolean | string => {
  return !!str && typeof str === "string" && str.trim() !== "" && str;
};

export const removeIllegalFilenameCharacters = (fileName: string, replaceCharacter = "-") => {
  return fileName.replace(/[/\\?%*:|"<>]/g, replaceCharacter);
};

export const onChangePageCursor = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  newPage: number,
  currPage: number,
  rowsPerPage: number,
  data: Categories_categories_pageInfo,
  sideEffect: (pageNumber: number, newPaging: CursorPaging) => void
) => {
  let newPaging: CursorPaging = {};
  if (!!data) {
    if (newPage > currPage) {
      newPaging = { after: data.endCursor, first: rowsPerPage };
    } else {
      newPaging = { before: data.startCursor, last: rowsPerPage };
    }
  }
  if (newPage === 0) {
    newPaging = { first: rowsPerPage };
  }
  sideEffect(newPage, newPaging);
};

export const onChangePageOffset = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  newPage: number,
  currPage: number,
  rowsPerPage: number,
  data: Slabs_slabs_pageInfo,
  sideEffect: (pageNumber: number, newPaging: OffsetPaging) => void
) => {
  let newPaging: OffsetPaging = {};
  if (!!data) {
    if ((newPage > currPage && data.hasNextPage) || (newPage < currPage && data.hasPreviousPage)) {
      newPaging = { limit: rowsPerPage, offset: rowsPerPage * newPage };
    }
  }
  sideEffect(newPage, newPaging);
};

// gets file and either opens a new tab to show it or downloads the file
export const getFile = async (
  filename: string,
  path: string,
  type: string,
  action: "download" | "view"
): Promise<void> => {
  await fetch(path).then(async (response) => {
    if (response.status === 200) {
      await response?.blob().then(async (blobResponse) => {
        const newBlob = new Blob([blobResponse], { type: type });
        const url = URL.createObjectURL(newBlob);
        let browserInfo = getBrowserInfo();
        let canBeOpened = canFileTypeBeViewedOnBrowser(
          filename.split(".").pop() || "",
          browserInfo
        );
        if (!canBeOpened && action === "view") action = "download";
        if (action === "download") {
          await onDownload(url, filename);
        } else {
          onView(url);
        }
      });
    } else {
      throw new Error(`Erro ao descarregar ficheiro ${response?.statusText}`);
    }
  });
};

// downloads file
const onDownload = async (url: string, filename: string): Promise<void> => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`);
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
};

// view file
const onView = (path: string) => {
  window.open(path, "_blank");
};

export const canFileTypeBeViewedOnBrowser = (
  fileExtension: string,
  browserInfo: GetBrowserResponseInfo
): boolean => {
  const viewableChrome =
    "doc,docx,ppt,pptx,3gp,avi,mov,mp4,m4v,m4a,mp3,mkv,ogv,ogm,ogg,oga,webm,wav,bmp,gif,jpg,jpeg,png,webp,zip,rar,txt,pdf";
  if (browserInfo.isChrome) {
    return viewableChrome.split(",").indexOf(fileExtension) !== -1;
  } else {
    return true;
  }
};

export interface GetBrowserResponseInfo {
  isBrowser: boolean;
  browserName: any;
  isMobile: boolean;
  isChrome: boolean;
  browserVersion: string;
  isFirefox: boolean;
}

export const getBrowserInfo = (): GetBrowserResponseInfo => {
  return {
    isBrowser,
    browserName,
    isMobile,
    isChrome,
    browserVersion,
    isFirefox,
  };
};

export function getNumberFromEnd(str: string) {
  const matches = str.match(/[0-9]+$/);

  if (matches) {
    return parseInt(matches[0], 10);
  }

  return null;
}

export const getStartDateFromFilter = (
  filter: BlockModelFilter
): weScanDateTime | null | undefined => {
  let andfilter = filter?.and && filter?.and[0];
  if (andfilter) {
    let gteFilter = filter.and?.find(
      (el) => !!el.createdAt && Object.keys(el.createdAt).indexOf("gte") !== -1
    );
    if (gteFilter) {
      return gteFilter.createdAt?.gte;
    }
  }
  return undefined;
};

export const getEndDateFromFilter = (
  filter: BlockModelFilter
): weScanDateTime | null | undefined => {
  let andfilter = filter?.and && filter?.and;
  if (andfilter) {
    let ltefilter = filter.and?.find(
      (el) => !!el.createdAt && Object.keys(el.createdAt).indexOf("lte") !== -1
    );
    if (ltefilter) {
      return ltefilter.createdAt?.lte;
    }
  }
  return undefined;
};

export const onStartDateChangeGraphQLFilters = (
  filter: BlockModelFilter,
  date: any,
  callback: (newAnd: BlockModelFilter["and"]) => void
) => {
  let endDate = getEndDateFromFilter(filter);
  if (!!date) {
    let datan = new Date(date);
    let newAnd: BlockModelFilter[] = [
      {
        createdAt: { gte: new weScanDateTime(datan) },
      },
    ];
    if (!!endDate) {
      newAnd.push({
        createdAt: { lte: endDate },
      });
    }
    callback(newAnd);
  } else {
    let newAnd = [];
    if (!!endDate) {
      newAnd.push({
        createdAt: { lte: endDate },
      });
    }
    callback(newAnd);
  }
};

export const onEndDateChangeGraphQLFilters = (
  filter: BlockModelFilter,
  date: any,
  callback: (newAnd: BlockModelFilter["and"]) => void
) => {
  let startDate = getStartDateFromFilter(filter);
  if (!!date) {
    let datan = new Date(date).setHours(23, 59, 59);
    let newAnd: BlockModelFilter[] = [
      {
        createdAt: { lte: new weScanDateTime(datan) },
      },
    ];
    if (!!startDate) {
      newAnd.push({
        createdAt: { gte: startDate },
      });
    }
    callback(newAnd);
  } else {
    let newAnd = [];
    if (!!startDate) {
      newAnd.push({
        createdAt: { gte: startDate },
      });
    }
    callback(newAnd);
  }
};

/**
 * Guard to assert whether an unknown field is a AggregateByType.
 * If it isn't or it's undefined or null, return default value
 */
export function validateValueOfType<T>(
  maybeAggValue: unknown,
  possibleValues: readonly T[],
  defaultValue: (typeof possibleValues)[number]
): (typeof possibleValues)[number] {
  if (!!maybeAggValue) {
    const foundName = possibleValues.find((validName: any) => validName === maybeAggValue);
    if (foundName) {
      // `foundName` comes from the list of `AggregateByValues` so the compiler is happy.
      return foundName;
    }
  }
  return defaultValue;
}

/**
 * Guard to assert whether an unknown field is from the given type.
 * If it isn't or it's undefined or null, return undefined
 */
export function validateValueOfTypeWithoutDefault<T>(
  maybeAggValue: unknown,
  possibleValues: readonly T[]
): (typeof possibleValues)[number] | undefined {
  if (!!maybeAggValue) {
    const foundName = possibleValues.find((validName: any) => validName === maybeAggValue);
    if (foundName) {
      // `foundName` comes from the list of `AggregateByValues` so the compiler is happy.
      return foundName;
    }
  }
  return undefined;
}

/**
 * Get Greatest Common Divisor of the two given numbers
 * @param a Number
 * @param b Number
 * @returns Greatest Common Divisor
 */
export function gcd(a: number, b: number): number {
  return b == 0 ? a : gcd(b, a % b);
}

/**
 * Get Aspect Ratio of given Width and Height
 * @param w Width
 * @param h Height
 * @returns Aspect Ratio
 */
export const getAspectRatio = (w: number, h: number): { aw: number; ah: number } => {
  // get GCD
  let r = gcd(w, h);
  return {
    aw: w / r,
    ah: h / r,
  };
};

/**
 * Get Variety text
 * @param slab Slab Node
 * @returns Variety text
 */
export const getSlabVariety = (slab: Slabs_slabs_nodes) => {
  return (
    " " +
    (slab?.commercial_variety?.name
      ? slab?.commercial_variety.name
      : slab?.variety?.normativedesig
      ? slab.variety.normativedesig
      : "< >")
  );
};

/**
 * Get Material text
 * @param slab Slab Node
 * @returns Material text
 */
export const getSlabMaterial = (slab: Slabs_slabs_nodes) => {
  return slab?.commercial_variety?.materialtype?.description?.pt
    ? slab?.commercial_variety?.materialtype?.description.pt
    : slab?.typematerial?.description?.pt
    ? slab?.typematerial?.description?.pt
    : "";
};

export const openNewTab = (url: string) => {
  if (!!window) {
    window.open(url, "_blank", "noopener,noreferrer");
  }
};

export const calcAndSetImageSize = (
  natWidth: number,
  natHeight: number,
  maxWidth: number,
  maxHeight: number,
  canEnlarge = false
): ISelectedImageInfo => {
  let newWidth = (natWidth * maxHeight) / natHeight;
  // if width too large set new height based on possible max width
  if (newWidth > maxWidth) {
    return {
      height: (natHeight * newWidth) / natWidth,
      width: maxWidth,
      naturalHeight: natHeight,
      naturalWidth: natWidth,
    };
  } else if (canEnlarge) {
    let newHeight = (natHeight * maxWidth) / natWidth;
    return {
      height: newHeight,
      width: maxWidth,
      naturalHeight: natHeight,
      naturalWidth: natWidth,
    };
  } else {
    return {
      height: maxHeight,
      width: newWidth,
      naturalHeight: natHeight,
      naturalWidth: natWidth,
    };
  }
};

/**
 * TODO: remove when safe
 * @param slab
 * @param isThumbnail
 * @param mainId
 * @returns
 */
export const getSlabImageSafe = (
  slab: {
    slabImages?: {
      edited?: string | null;
      original: string;
      thumbnail_edited?: string | null;
      thumbnail_original: string;
      azureBlobID: string;
      mainType: MainImageType;
    }[];
    images: string[];
    mainImage?: string | null;
  },
  isThumbnail = false
): string | undefined | null => {
  let src: string | undefined | null;
  if (!!slab?.slabImages) {
    src = getSlabImage(slab.slabImages, isThumbnail, slab.mainImage);
  } else {
    src = isThumbnail ? getThumbnail(slab.images[0]) : slab.images[0];
  }
  return src === null ? null : src === undefined ? undefined : String(src);
};

/**
 * Get Slab's main image
 * @param slabImage Image Entity to show
 * @param showThumbnail Whether Thumbnail should be shown
 * @param mainID Azure Blob ID of image to show
 * @returns Image url or undefined
 */
export const getSlabImage = (
  slabImage: {
    edited?: string | null;
    original: string;
    thumbnail_edited?: string | null;
    thumbnail_original: string;
    azureBlobID: string;
    mainType: MainImageType;
  }[],
  showThumbnail: boolean,
  mainID?: string | null
): string | undefined | null => {
  let found = !!slabImage?.find((img) => img.azureBlobID === mainID)
    ? slabImage?.find((img) => img.azureBlobID === mainID)
    : slabImage[0];
  if (!!found) {
    return found.mainType === MainImageType.edited
      ? showThumbnail
        ? found.thumbnail_edited ?? found.edited
        : found.edited
      : showThumbnail
      ? found.thumbnail_original ?? found.original
      : found.original;
  } else {
    return undefined;
  }
};

export const getImageEntityURLString = (
  slabImage: {
    edited?: string | null;
    original: string;
    thumbnail_edited?: string | null;
    thumbnail_original: string;
    azureBlobID: string;
    mainType: MainImageType;
  },
  thumbnail = true
) => {
  return slabImage.mainType === MainImageType.edited
    ? thumbnail
      ? slabImage.thumbnail_edited ?? slabImage.edited
      : slabImage.edited
    : thumbnail
    ? slabImage.thumbnail_original ?? slabImage.original
    : slabImage.original;
};

/**
 * Input a number (n) and add round it to make sure it is divisble by the divisor (d)
 * @param n Number to make divisible
 * @param d Divisor
 * @returns Divisble Number
 */
export function roundTo(n: number, d: number) {
  return Math.floor((n + d - 1) / d) * d;
}

/**
 * Decode Base64 string
 * @param str
 */
export function decodeBase64(str: string) {
  const buff = Buffer.from(str, "base64");
  return buff.toString("utf-8");
}

export const isNumeric = (str: any) => {
  if (typeof str != "string" && !Number.isNaN(Number(str))) return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

/**
 * 
 * @param obj 
 * @param numberFields 
 * @param keysToRemove 
 * @returns 
 */
export const deleteZerosAndEmptyStringsAndOtherKeys = <T>(
  obj: T,
  numberFields: (keyof T)[],
  keysToRemove?: (keyof T)[]
): T => {
  const input_data = copyObject(obj, keysToRemove ?? []);

  for (const key in input_data) {
    if (Object.prototype.hasOwnProperty.call(input_data, key)) {
      let typed_key = key as keyof typeof input_data;
      const element = input_data[typed_key];
      //0's, empty strings
      if (element === 0 || (typeof element === "string" && element.trim() === "")) {
        delete input_data[typed_key];
      } else {
        if (numberFields.indexOf(typed_key) !== -1 && isNumeric(element)) {
          //@ts-ignore
          input_data[typed_key] = Number(element);
        } else if (!element && typeof element === "undefined") {
          //@ts-ignore
          input_data[typed_key] = null;
        }
      }
    }
  }

  return input_data;
};
