import { combineReducers } from "redux";

import accountReducer from "./accountReducer";
import isLoadingReducer from "./isLoadingReducer";

export default combineReducers({
  account: accountReducer,
  isloading: isLoadingReducer,
})

