import { ApolloClient, FetchPolicy, NormalizedCacheObject } from "@apollo/client";
import { ApolloQueryResult } from "@apollo/react-hooks";
import { DocumentNode } from "graphql";
import toast from "react-hot-toast";
import { Project, ProjectVariables, Project_project } from "../data/graphQLModel";
import { QUERY_ONE_PROJECT } from "../data/graphQLQueries";
import { apolloClient } from "../services_v2/apollo.graphql";
import ObjectID from "bson-objectid";

interface IGeneralGraphQLAPIFilter {
  and?: IGeneralGraphQLAPIFilter[];
  or?: IGeneralGraphQLAPIFilter[];
  [keys: string]: any;
}

/**
 * Checks if a string value is a valid MongoDB ObjectId
 * @param possibleId Possible MongoDB ObjectId
 * @returns If value is a valid MongoDB ObjectId
 */
export const isObjectId = (possibleId: string): boolean => {
  return (
    // Use Mongoose's ObjectId.isValid first (this might give an incorrect result as every 12 char string, for example, will be considered a valid ObjectId)
    ObjectID.isValid(possibleId) &&
    /**
     * To make sure we are not "fooled" by problems like the 12 char string one,
     * create an ObjectId, cast it to String and compare that value to the value of the possibleId string.
     * If the values are different, then it's not an ObjectId.
     */
    new ObjectID(possibleId)?.toString() === String(possibleId)
  );
};

export const getProject = async (
  variables: ProjectVariables
): Promise<Project_project | void | null> => {
  const _apolloClient = apolloClient as ApolloClient<NormalizedCacheObject>;

  return await _apolloClient
    .query<Project, ProjectVariables>({
      query: QUERY_ONE_PROJECT,
      variables,
    })
    .then((res) => {
      return res.data.project;
    })
    .catch((err) => {
      toast.error(`Error while querying full Project data: ${err[0].message}`);
    });
};

/**
 * Dynamic function to query results with the given GQL Document and Filter
 * Runs a callback in the end with the query result as the parameter
 * @param value Form value
 * @param document GQL Document to use
 * @param filter Filter to use on query
 * @param onEnd Callback when query ends
 */
export async function queryValuesForFilter<
  QueryFiltersType,
  QueryPagingType,
  QuerySortingType,
  ResultType
>(
  value: string | undefined | null,
  document: DocumentNode,
  onEnd: (res: ApolloQueryResult<ResultType>) => void,
  variables: {
    filter: QueryFiltersType;
    paging?: QueryPagingType;
    sorting?: QuerySortingType;
  },
  fetchPolicy: FetchPolicy = "cache-first",
  useFilterIfEmpty = false
): Promise<void> {
  const _apolloClient = apolloClient as ApolloClient<NormalizedCacheObject>;

  // check if not empty
  let toUseFilter: IGeneralGraphQLAPIFilter | undefined | null = undefined;
  if (!!value && value.trim() !== "") {
    // if value is a valid ObjectID, change filter
    if (isObjectId(value)) {
      toUseFilter = { id: { eq: value } };
    } else {
      toUseFilter = variables.filter as IGeneralGraphQLAPIFilter;
    }
  } else if (useFilterIfEmpty) {
    toUseFilter = variables.filter as IGeneralGraphQLAPIFilter;
  }
  await _apolloClient
    .query<ResultType, any>({
      query: document,
      variables: {
        filter: toUseFilter,
        sorting: !!variables.sorting ? variables.sorting : undefined,
        paging: !!variables.paging ? variables.paging : undefined,
      },
      fetchPolicy,
    })
    .then((res) => {
      onEnd(res);
    })
    .catch((err) => {
      toast.error(`Error while querying data`);
    });
}
