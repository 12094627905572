import { FormControl, TextField, IconButton, Grid, useTheme } from "@material-ui/core";
import { Clear, Search } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/system";
import { filter } from "lodash";
import SelectDate from "../../components/CatalogFilters/SelectDate";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  BlockModelFilter,
  CursorPaging,
  BlockModelSort,
  BlockModelsToFilter,
  CategoriesVariables,
  CategorySort,
  Categories,
  Categories_categories_edges_node,
  MaterialTypeFilter,
  MaterialTypeSort,
  MaterialTypes,
  MaterialTypes_materialTypes_edges_node,
  CommercialVarietiesVariables,
  CommercialVarietySort,
  CommercialVarieties,
  CommercialVarieties_commercialVarieties_edges_node,
  VarietyFilter,
  VarietySort,
  Varieties,
  Varieties_varieties_edges_node,
  StoneModelFilter,
  StoneModelSort,
  StoneModels,
  StoneModels_stoneModels_edges_node,
  CommercialFinishingFilter,
  CommercialFinishingSort,
  CommercialFinishings,
  CommercialFinishings_commercialFinishings_edges_node,
  FinishingFilter,
  FinishingSort,
  Finishings,
  Finishings_finishings_edges_node,
  StoneEdgeFilter,
  StoneEdgeSort,
  StoneEdges,
  StoneEdges_stoneEdges_edges_node,
  PackingFilter,
  PackingSort,
  Packings,
  Packings_packings_edges_node,
  ProjectFilter,
  ProjectSort,
  Projects,
  Projects_projects_edges_node,
  JobFilter,
  JobSort,
  Jobs,
  ScannerFilter,
  ScannerSort,
  Scanners,
  Jobs_jobs_edges_node,
  Scanners_scanners_edges_node,
  BlockModelsToFilter_blockModels_edges_node,
  SlabFilter,
} from "../../data/graphQLModel";
import {
  QUERY_BLOCKS_TO_FILTER,
  QUERY_CATEGORIES,
  QUERY_MATERIALTYPES,
  QUERY_COMMERCIAL_VARIETIES,
  QUERY_VARIETIES,
  QUERY_STONE_MODELS,
  QUERY_COMMERCIAL_FINISHINGS,
  QUERY_FINISHINGS,
  QUERY_STONE_EDGES,
  QUERY_MANY_PACKINGS,
  QUERY_MANY_PROJECTS,
  QUERY_JOBS,
  QUERY_SCANNERS,
} from "../../data/graphQLQueries";
import { MuiThemeProps } from "../../theme/theme";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { copyObject, getEndDateFromFilter, getStartDateFromFilter } from "../../utils/utils";

export type PossibleTimeFields = "createdate" | "updatedAt" | "createdAt";

export interface SlabFiltersComponentProps {
  // Filters
  textSearch: string | undefined;
  setTextSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  jobs: Jobs_jobs_edges_node[];
  setJobs: React.Dispatch<React.SetStateAction<Jobs_jobs_edges_node[]>>;
  scans: Scanners_scanners_edges_node[];
  setScans: React.Dispatch<React.SetStateAction<Scanners_scanners_edges_node[]>>;
  varieties: Varieties_varieties_edges_node[];
  setVarieties: React.Dispatch<React.SetStateAction<Varieties_varieties_edges_node[]>>;
  finishings: Finishings_finishings_edges_node[];
  setFinishings: React.Dispatch<React.SetStateAction<Finishings_finishings_edges_node[]>>;
  commercialVarieties: CommercialVarieties_commercialVarieties_edges_node[];
  setCommercialVarieties: React.Dispatch<
    React.SetStateAction<CommercialVarieties_commercialVarieties_edges_node[]>
  >;
  materialTypes: MaterialTypes_materialTypes_edges_node[];
  setMaterialTypes: React.Dispatch<React.SetStateAction<MaterialTypes_materialTypes_edges_node[]>>;
  commercialFinishings: CommercialFinishings_commercialFinishings_edges_node[];
  setCommercialFinishings: React.Dispatch<
    React.SetStateAction<CommercialFinishings_commercialFinishings_edges_node[]>
  >;
  categories: Categories_categories_edges_node[];
  setCategories: React.Dispatch<React.SetStateAction<Categories_categories_edges_node[]>>;
  stoneModels: StoneModels_stoneModels_edges_node[];
  setStoneModels: React.Dispatch<React.SetStateAction<StoneModels_stoneModels_edges_node[]>>;
  stoneEdges: StoneEdges_stoneEdges_edges_node[];
  setStoneEdges: React.Dispatch<React.SetStateAction<StoneEdges_stoneEdges_edges_node[]>>;
  blocks: BlockModelsToFilter_blockModels_edges_node[];
  setBlocks: React.Dispatch<React.SetStateAction<BlockModelsToFilter_blockModels_edges_node[]>>;
  packages: Packings_packings_edges_node[];
  setPackages: React.Dispatch<React.SetStateAction<Packings_packings_edges_node[]>>;
  projects: Projects_projects_edges_node[];
  setProjects: React.Dispatch<React.SetStateAction<Projects_projects_edges_node[]>>;
  globalFilters: SlabFilter;
  setGlobalFilters: React.Dispatch<React.SetStateAction<SlabFilter>>;
  handleAutoCompleteChange: (e: any) => void;
  handleSearch: (value: string | undefined) => void;
  timeField: PossibleTimeFields;
  handleStartDateChange: (date: any) => void;
  handleEndDateChange: (date: any) => void;
}

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(10),
    },
  },
  boxTop: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(10),
  },
  margin: {
    width: "40ch",
  },
}));

const SlabFiltersComponent = ({
  blocks,
  categories,
  commercialFinishings,
  commercialVarieties,
  finishings,
  globalFilters,
  jobs,
  materialTypes,
  packages,
  projects,
  scans,
  setBlocks,
  setCategories,
  setCommercialFinishings,
  setCommercialVarieties,
  setFinishings,
  setGlobalFilters,
  setJobs,
  setMaterialTypes,
  setPackages,
  setProjects,
  setScans,
  setStoneEdges,
  setStoneModels,
  setTextSearch,
  setVarieties,
  stoneEdges,
  stoneModels,
  textSearch,
  varieties,
  handleAutoCompleteChange,
  handleSearch,
  timeField,
  handleStartDateChange,
  handleEndDateChange,
}: SlabFiltersComponentProps) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.boxTop}>
        <SelectDate
          label={"Start Date"}
          selectedDate={getStartDateFromFilter(globalFilters)}
          handleDateChange={handleStartDateChange}
          maxDate={getEndDateFromFilter(globalFilters)}
        />
        <SelectDate
          label={"End Date"}
          selectedDate={getEndDateFromFilter(globalFilters)}
          handleDateChange={handleEndDateChange}
          minDate={getStartDateFromFilter(globalFilters)}
        />
      </Box>
      <hr />
      <FormControl fullWidth variant="outlined">
        <TextField
          id="textsearch"
          label="Search"
          name="textsearch"
          placeholder="Search Slab"
          value={textSearch || ""}
          onChange={(e) => {
            setTextSearch(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch(textSearch);
            }
          }}
          InputProps={{
            startAdornment: (
              <IconButton
                onClick={() => {
                  handleSearch(textSearch);
                }}
              >
                <Search fontSize="medium" />
              </IconButton>
            ),
            endAdornment: !!textSearch && textSearch.trim() !== "" && (
              <IconButton
                onClick={() => {
                  setTextSearch(undefined);
                  const newGlobalFilters = copyObject(globalFilters, ["code"]);
                  setGlobalFilters(newGlobalFilters);
                }}
              >
                <Clear fontSize="medium" />
              </IconButton>
            ),
          }}
        />
      </FormControl>
      <Grid mt={1}>
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl" }}
          items={blocks}
          label="Block"
          name="block"
          onChange={handleAutoCompleteChange}
          title="Block"
          TextFieldProps={{
            label: "Block",
          }}
          value={globalFilters?.block?.eq}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<
              BlockModelFilter,
              CursorPaging,
              BlockModelSort,
              BlockModelsToFilter
            >(
              value,
              QUERY_BLOCKS_TO_FILTER,
              (res) => {
                let endRes = res?.data?.blockModels?.edges?.map((el) => el.node);
                setBlocks(endRes);
              },
              {
                filter: {
                  or: [{ wescanCode: { iLike: value } }, { tenantCode: { iLike: value } }],
                },
                paging: {
                  first: 40,
                },
              }
            );
          }}
          getField={(node: typeof blocks[number]) => {
            return !!node.tenantCode ? node.tenantCode : node.wescanCode;
          }}
        />
      </Grid>
      <hr />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={categories}
        label="Category"
        name="category"
        title="Categories"
        onChange={handleAutoCompleteChange}
        value={globalFilters?.category?.eq}
        TextFieldProps={{
          label: "Category",
        }}
        continueToLoadResultsInComponent={false}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<
            CategoriesVariables["filter"],
            CursorPaging,
            CategorySort,
            Categories
          >(
            value,
            QUERY_CATEGORIES,
            (res) => {
              let endRes = res?.data?.categories?.edges?.map((el) => el.node);
              setCategories(endRes);
            },
            {
              filter: { and: [{ code: { iLike: value } }] },
              paging: { first: 40 },
            }
          );
        }}
        getField={(node: Categories_categories_edges_node) => {
          if (!!node.companyNames && node.companyNames.length > 0) {
            let obj = node.companyNames[0];
            return `${obj.code} - ${obj.description}`;
          }
          return `${node.code} - ${node.description.pt}`;
        }}
      />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={materialTypes}
        label="Material Type"
        name="typematerial"
        onChange={handleAutoCompleteChange}
        title="Materials Type"
        value={globalFilters?.typematerial?.eq}
        TextFieldProps={{
          label: "Material Type",
        }}
        continueToLoadResultsInComponent={false}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<
            MaterialTypeFilter,
            CursorPaging,
            MaterialTypeSort,
            MaterialTypes
          >(
            value,
            QUERY_MATERIALTYPES,
            (res) => {
              let endRes = res?.data?.materialTypes?.edges?.map((el) => el.node);
              setMaterialTypes(endRes);
            },
            {
              filter: { or: [{ code: { iLike: value } }] },
              paging: { first: 70 },
            }
          );
        }}
        getField={(node: MaterialTypes_materialTypes_edges_node) => {
          if (!!node.companyNames && node.companyNames.length > 0) {
            let obj = node.companyNames[0];
            return `${obj.code} - ${obj.description}`;
          }
          return `${node.code} - ${node.description.pt}`;
        }}
      />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={commercialVarieties}
        label="Commercial Variety"
        name="commercial_variety"
        title="Commercial Variety"
        onChange={handleAutoCompleteChange}
        value={globalFilters?.commercial_variety?.eq}
        TextFieldProps={{
          label: "Commercial Variety",
        }}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<
            CommercialVarietiesVariables["filter"],
            CursorPaging,
            CommercialVarietySort,
            CommercialVarieties
          >(
            value,
            QUERY_COMMERCIAL_VARIETIES,
            (res) => {
              let endRes = res?.data?.commercialVarieties?.edges?.map((el) => el.node);
              setCommercialVarieties(endRes);
            },
            {
              filter: {
                or: [{ code: { iLike: value } }, { name: { iLike: value } }],
              },
              paging: { first: 25 },
            }
          );
        }}
        getField={(node: CommercialVarieties_commercialVarieties_edges_node) => {
          return `${node.code} - ${node.name}`;
        }}
      />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={varieties}
        label="System Variety"
        name="variety"
        value={globalFilters?.variety?.eq}
        onChange={handleAutoCompleteChange}
        title="System Variety"
        TextFieldProps={{
          label: "System Variety",
        }}
        continueToLoadResultsInComponent={false}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<VarietyFilter, CursorPaging, VarietySort, Varieties>(
            value,
            QUERY_VARIETIES,
            (res) => {
              let endRes = res?.data?.varieties?.edges?.map((el) => el.node);
              setVarieties(endRes);
            },
            {
              filter: {
                or: [{ code: { iLike: value } }, { normativedesig: { iLike: value } }],
              },
              paging: { first: 200 },
            }
          );
        }}
        getField={(node: Varieties_varieties_edges_node) => {
          return `${node.code} - ${node.normativedesig}`;
        }}
      />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={stoneModels}
        label="Models"
        name="stone_model"
        onChange={handleAutoCompleteChange}
        title="Models"
        value={globalFilters?.stone_model?.eq}
        TextFieldProps={{
          label: "Models",
        }}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<StoneModelFilter, CursorPaging, StoneModelSort, StoneModels>(
            value,
            QUERY_STONE_MODELS,
            (res) => {
              let endRes = res?.data?.stoneModels?.edges?.map((el) => el.node);
              setStoneModels(endRes);
            },
            {
              filter: {
                or: [{ code: { iLike: value } }, { name: { iLike: value } }],
              },
              paging: { first: 40 },
            }
          );
        }}
        getField={(node: StoneModels_stoneModels_edges_node) => {
          return `${node.code} - ${node.name}`;
        }}
      />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={commercialFinishings}
        label="Commercial Finishings"
        name="commercial_finishing"
        onChange={handleAutoCompleteChange}
        title="Commercial Finishings"
        value={globalFilters?.commercial_finishing?.eq}
        TextFieldProps={{
          label: "Commercial Finishings",
        }}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<
            CommercialFinishingFilter,
            CursorPaging,
            CommercialFinishingSort,
            CommercialFinishings
          >(
            value,
            QUERY_COMMERCIAL_FINISHINGS,
            (res) => {
              let endRes = res?.data?.commercialFinishings?.edges?.map((el) => el.node);
              setCommercialFinishings(endRes);
            },
            {
              filter: { or: [{ code: { iLike: value } }] },
              paging: { first: 40 },
            }
          );
        }}
        getField={(node: CommercialFinishings_commercialFinishings_edges_node) => {
          return `${node.code} - ${node.description.pt}`;
        }}
      />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={finishings}
        label="System Finishing"
        name="finish"
        value={globalFilters?.finish?.eq}
        onChange={handleAutoCompleteChange}
        title="System Finishing"
        TextFieldProps={{
          label: "System Finishing",
        }}
        continueToLoadResultsInComponent={false}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<FinishingFilter, CursorPaging, FinishingSort, Finishings>(
            value,
            QUERY_FINISHINGS,
            (res) => {
              let endRes = res?.data?.finishings?.edges?.map((el) => el.node);
              setFinishings(endRes);
            },
            {
              filter: {
                or: [{ code: { iLike: value } }],
              },
              paging: {
                first: 200,
              },
            }
          );
        }}
        getField={(node: Finishings_finishings_edges_node) => {
          return `${node.code} - ${node?.description?.pt ? node?.description?.pt : ""}`;
        }}
      />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={stoneEdges}
        label="Edges"
        name="stone_edge"
        onChange={handleAutoCompleteChange}
        title="Edges"
        value={globalFilters?.stone_edge?.eq}
        TextFieldProps={{
          label: "Edges",
        }}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<StoneEdgeFilter, CursorPaging, StoneEdgeSort, StoneEdges>(
            value,
            QUERY_STONE_EDGES,
            (res) => {
              let endRes = res?.data?.stoneEdges?.edges?.map((el) => el.node);
              setStoneEdges(endRes);
            },
            {
              filter: {
                or: [
                  { code: { iLike: value } },
                  { name: { iLike: value } },
                  { description: { iLike: value } },
                ],
              },
              paging: { first: 40 },
            }
          );
        }}
        getField={(node: StoneEdges_stoneEdges_edges_node) => {
          return `${node.code} - ${node.name}`;
        }}
      />
      <hr />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={packages}
        label="Bundles"
        name="packing"
        value={globalFilters?.packing?.eq}
        onChange={handleAutoCompleteChange}
        title="Bundles"
        TextFieldProps={{
          label: "Bundles",
        }}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<PackingFilter, CursorPaging, PackingSort, Packings>(
            value,
            QUERY_MANY_PACKINGS,
            (res) => {
              let endRes = res?.data?.packings?.edges?.map((el) => el.node);
              setPackages(endRes);
            },
            {
              filter: {
                or: [{ code: { iLike: value } }, { packing: { iLike: value } }],
              },
              paging: { first: 40 },
            }
          );
        }}
        getField={(node: Packings_packings_edges_node) => {
          return node.packing;
        }}
      />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={projects}
        label="Projects"
        name="project"
        onChange={handleAutoCompleteChange}
        title="Projects"
        value={globalFilters?.project?.eq}
        TextFieldProps={{
          label: "Projects",
        }}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<ProjectFilter, CursorPaging, ProjectSort, Projects>(
            value,
            QUERY_MANY_PROJECTS,
            (res) => {
              let endRes = res?.data?.projects?.edges?.map((el) => el.node);
              setProjects(endRes);
            },
            {
              filter: {
                or: [
                  { code: { iLike: value } },
                  { description: { iLike: value } },
                  { observation: { iLike: value } },
                  { project: { iLike: value } },
                ],
              },
              paging: {
                first: 30,
              },
            }
          );
        }}
        getField={(node: Projects_projects_edges_node) => {
          return node.project;
        }}
      />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={jobs}
        label="Jobs"
        name="job"
        onChange={handleAutoCompleteChange}
        title="Jobs"
        value={globalFilters?.job?.eq}
        TextFieldProps={{
          label: "Jobs",
        }}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<JobFilter, CursorPaging, JobSort, Jobs>(
            value,
            QUERY_JOBS,
            (res) => {
              let endRes = res?.data?.jobs?.edges?.map((el) => el.node);
              setJobs(endRes);
            },
            {
              filter: {
                or: [{ code: { iLike: value } }],
              },
              paging: {
                first: 30,
              },
            }
          );
        }}
        getField={(node: Jobs_jobs_edges_node) => {
          return `${node.code}`;
        }}
      />
      <AutocompleteWithQuery
        AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
        items={scans}
        label="Scans"
        name="scan"
        onChange={handleAutoCompleteChange}
        title="Scans"
        value={globalFilters?.scan?.eq}
        TextFieldProps={{
          label: "Scans",
        }}
        onTextChange={async (value: string) => {
          await queryValuesForFilter<ScannerFilter, CursorPaging, ScannerSort, Scanners>(
            value,
            QUERY_SCANNERS,
            (res) => {
              let endRes = res?.data?.scanners?.edges?.map((el) => el.node);
              setScans(endRes);
            },
            {
              filter: {
                or: [{ code: { iLike: value } }, { description: { iLike: value } }],
              },
              paging: {
                first: 30,
              },
            }
          );
        }}
        getField={(node: Scanners_scanners_edges_node) => {
          return `${node.code} - ${node.description}`;
        }}
      />
    </>
  );
};

export default SlabFiltersComponent;
