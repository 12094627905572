import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { defaultDataIdFromObject, DefaultOptions } from "@apollo/react-hooks";
import { NormalizedCacheObject } from "apollo-boost";
import { useMemo } from "react";
import { relayStylePagination, getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { appInsightsFrontEnd } from "../services/azureAppInsights";
import { AppInsightsErrorType } from "../utils/Types";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

const prod = "https://api-wescanhome.azurewebsites.net/graphql";
const envvar = process.env.REACT_APP_API_ENDPOINT_V2;
const GRAPHQL_ENDPOINT = !!envvar ? envvar : prod;

// Exports ApolloClient instance, this makes it possible to query without Hooks
// Comes in handy when you need to query outside of a Functional Component
export let apolloClient: ApolloClient<NormalizedCacheObject>;
export let uploadApolloClient: ApolloClient<NormalizedCacheObject>;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export function useApollo() {
  const tkn = localStorage.getItem("accessTokenV2");
  const store = useMemo(() => initializeApollo(tkn), [tkn]);
  return store;
}

export function initializeApollo(tkn: string | null | undefined) {
  if (!tkn || tkn.trim() === "") {
    tkn = localStorage.getItem("accessTokenV2");
  }
  const _apolloClient = createApolloClient(tkn);
  const _uploadApolloClient = createUploadApolloClient(tkn);
  apolloClient = _apolloClient;
  uploadApolloClient = _uploadApolloClient;

  return _apolloClient;
}

const OnErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  let operationProperties = {
    query: operation.query,
    variables: operation.variables,
    operationName: operation.operationName,
    extensions: operation.extensions,
  };
  // TODO : if status error >500 severity level =4
  if (networkError) {
    let customProperties = {
      errorProperties: networkError,
      operationProperties,
      errorType: AppInsightsErrorType.network,
    };
    appInsightsFrontEnd.trackException(
      {
        exception: networkError,
        properties: { ...networkError },
      },
      customProperties
    );
    appInsightsFrontEnd.trackTrace(
      { message: networkError.message, severityLevel: SeverityLevel.Error },
      customProperties
    );
  }

  if (graphQLErrors) {
    let unauthorized = false;
    graphQLErrors?.forEach((error) => {
      let customProperties = {
        errorProperties: error,
        operationProperties,
        errorType: AppInsightsErrorType.graphql,
      };
      appInsightsFrontEnd.trackException(
        { exception: new Error(error.message), properties: { ...error } },
        customProperties
      );
      appInsightsFrontEnd.trackTrace(
        { message: error.message, severityLevel: SeverityLevel.Error },
        customProperties
      );
      if (error.extensions?.response?.statusCode === 401) {
        unauthorized = true;
      } else {
        if (error.message === "Unauthorized") {
          unauthorized = true;
        }
      }
    });
    if (unauthorized) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("accessTokenV2");
    }
  }
});

const getAuthHeaders = (tkn: string | null | undefined) => {
  if (tkn) {
    return { Authorization: `Bearer ${tkn}` };
  } else {
    return {};
  }
};

function createApolloClient(tkn: string | null | undefined) {
  if (!tkn || tkn.trim() === "") {
    tkn = localStorage.getItem("accessTokenV2");
  }

  const httpLink = new HttpLink({
    uri: GRAPHQL_ENDPOINT,
    headers: getAuthHeaders(tkn),
  });

  let subscriptionUrl = `${
    GRAPHQL_ENDPOINT.indexOf("https") !== -1 ? "wss" : "ws"
  }://${GRAPHQL_ENDPOINT.split("//").slice(1).join("")}`;

  // Subscription Link
  const wsLink = new GraphQLWsLink(
    createClient({
      url: subscriptionUrl,
      connectionParams: {
        ...getAuthHeaders(tkn),
      },
    })
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    ssrMode: false, // set to true for SSR
    link: ApolloLink.from([OnErrorLink, splitLink]),
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies: {
        Query: {
          fields: {
            jobs: relayStylePagination(),
            sharedProject: {
              keyArgs: ["id", "packings", "slabs"],
            },
            sharedProjects: {
              keyArgs: ["id", "packings", "slabs"],
            },
            sharedPackage: {
              keyArgs: ["id", "slabs"],
            },
            sharedPackages: {
              keyArgs: ["id", "slabs"],
            },
          },
        },
      },
    }),
    defaultOptions: defaultOptions,
    connectToDevTools: true,
  });
}

function createUploadApolloClient(tkn: string | null | undefined) {
  if (!tkn || tkn.trim() === "") {
    tkn = localStorage.getItem("accessTokenV2");
  }
  // console.log("createApolloClient", tkn);

  return new ApolloClient({
    ssrMode: false, // set to true for SSR
    link: createUploadLink({
      uri: GRAPHQL_ENDPOINT,
      headers: getAuthHeaders(tkn),
    }),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: defaultOptions,
  });
}
