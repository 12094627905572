import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import swalert from "sweetalert";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { MUTATION_UPDATE_ONE_JOB, QUERY_ONE_JOB } from "../../../data/graphQLQueries";
import { NetworkStatus, useMutation, useQuery } from "@apollo/react-hooks";
import {
  DescState,
  Job,
  JobInput,
  JobNumberingOrder,
  JobUpdate,
  JobVariables,
  Job_job,
  UpdateOneJob,
  UpdateOneJobVariables,
} from "../../../data/graphQLModel";
import * as yup from "yup";
import { copyObject } from "../../../utils/utils";
import { isNumeric } from "../../utils/utils";
import JobForm from "./forms/JobForm";
import toast from "react-hot-toast";
import { H2 } from "../../../components/GeneralComponents/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FlexBox from "../../../components/GeneralComponents/FlexBox";
import { Cancel, PlayArrow, Stop } from "@material-ui/icons";

const LOADING_TOAST = "LOADING_TOAST";

export default function DetailsOrUpdateJob() {
  const theme = useTheme();
  const [values, setValues] = useState<JobInput & { endNumber?: number | null | undefined }>();
  const [blockReason, setBlockReason] = useState<string>();
  const history = useHistory();
  const { jb } = useParams<{ jb: string }>();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const { error, data, refetch, networkStatus } = useQuery<Job, JobVariables>(QUERY_ONE_JOB, {
    variables: {
      id: jb,
    },
    onCompleted: (data) => {
      let newData = data.job;
      if (newData) {
        setValues({
          code: newData.code,
          description: newData.description,
          jobtype: newData?.jobtype?.id ? newData?.jobtype?.id : "",
          scan: newData?.scan?.id ? newData?.scan?.id : "",
          blocknumber: newData.blocknumber,
          category: newData.category?.id,
          commercial_finishing: newData.commercial_finishing?.id,
          commercial_variety: newData.commercial_variety?.id,
          descstate: newData.descstate,
          finish: newData.finish?.id,
          height: newData.height,
          isblock: newData.isblock,
          packing: newData.packing?.id,
          project: newData.project?.id,
          stone_edge: newData.stone_edge?.id,
          stone_model: newData.stone_model?.id,
          thickness: newData.thickness,
          totalslab: newData.totalslab,
          typematerial: newData.typematerial?.id,
          variety: newData.variety?.id,
          width: newData.width,
          block: newData?.block?.id,
          numberingOrder: newData.numberingOrder,
          prefix: newData.prefix,
          separator: newData.separator,
          startNumber: newData.startNumber,
          endNumber:
            newData.numberingOrder === JobNumberingOrder.DESC
              ? (newData.startNumber || 1) - (newData.totalslab - 1)
              : (newData.startNumber || 1) + (newData.totalslab - 1),
        });
        if (newData.descstate === DescState.EXECUTION) {
          setBlockReason("Job in EXECUTION");
        } else if (newData.descstate === DescState.CONCLUDED) {
          setBlockReason("Job is CONCLUDED");
        } else if (newData.scannedSlabs >= newData.totalslab) {
          setBlockReason("Job is CONCLUDED");
        } else {
          setBlockReason(undefined);
        }
      }
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
    skip: !!!jb,
  });

  const [mutationUpdateOneJob] = useMutation<UpdateOneJob, UpdateOneJobVariables>(
    MUTATION_UPDATE_ONE_JOB
  );

  /**
   * Update Job after form submition
   * @param form form value of JobUpdate object
   */
  async function submitForm(form: JobUpdate, shouldGoBack = true) {
    let input_data = copyObject(form, []);
    const numberFields: (keyof UpdateOneJobVariables["input"]["update"])[] = [
      "totalslab",
      "startNumber",
    ];

    try {
      // remove variables from form
      //delete code
      //@ts-ignore
      delete input_data?.code;
      //delete 0's, empty strings
      for (const key in input_data) {
        if (Object.prototype.hasOwnProperty.call(input_data, key)) {
          let typed_key = key as keyof typeof input_data;
          const element = input_data[typed_key];
          if (numberFields.indexOf(typed_key) !== -1 && isNumeric(element)) {
            //@ts-ignore
            input_data[typed_key] = Number(element);
          } else if (!element && typeof element === "undefined") {
            //@ts-ignore
            input_data[typed_key] = null;
          }
        }
      }
      await mutationUpdateOneJob({
        variables: {
          input: {
            id: jb,
            update: input_data,
          },
        },
      });
      swalert("Job", "Job was updated", "success");
      if (
        (data?.job?.descstate === DescState.EXECUTION &&
          input_data.descstate === DescState.PENDING) ||
        !shouldGoBack
      ) {
        // clear previous form
        setValues(undefined);
        // refetch data
        refetch();
      } else {
        history.goBack();
      }
    } catch (error: any) {
      console.log(error);
      swalert("Job", `Error updating Job: ${error?.toString()}`, "warning");
    }
  }

  const handleCancel = () => {
    history.goBack();
  };

  if (!!error) {
    swalert("Job", `Error getting Job: ${error?.message}`, "warning");
    history.goBack();
  }

  if (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch) {
    toast.loading("Loading...", { id: LOADING_TOAST });
    return <></>;
  } else {
    toast.dismiss(LOADING_TOAST);
  }

  return (
    <Container sx={{ pt: "1rem" }}>
      {data && data?.job && !!values && (
        <>
          <Box mb={1}>
            <Card sx={{ p: 1 }}>
              <Grid container>
                <Grid container item xs={12} sm={4}>
                  <FlexBox sx={{ alignItems: "center" }}>
                    <IconButton
                      disabled={history?.length <= 1}
                      onClick={() => history?.goBack()}
                      color="primary"
                      sx={{ mr: 1 }}
                    >
                      <ArrowBackIosIcon fontSize="large" />
                    </IconButton>
                    <H2>Job ({!!blockReason ? blockReason : data?.job.code})</H2>
                  </FlexBox>
                </Grid>
                {data?.job?.descstate !== DescState.CONCLUDED && (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={8}
                    alignItems="center"
                    gap={3}
                    sx={{ justifyContent: !sm ? "space-around" : "flex-end" }}
                  >
                    <Grid item xs={4}>
                      <Button
                        disabled={!!!values || data?.job?.descstate === DescState.CANCELED}
                        onClick={() => {
                          submitForm({ descstate: DescState.CANCELED }, false);
                        }}
                        fullWidth
                        color="error"
                        variant="contained"
                      >
                        <Cancel sx={{ marginRight: "0.5rem" }} />
                        Cancel Job
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      {data?.job?.descstate === DescState.EXECUTION ? (
                        <Button
                          disabled={!!!values}
                          onClick={() => {
                            submitForm({ descstate: DescState.STOPPED }, false);
                          }}
                          fullWidth
                          variant="contained"
                          color="warning"
                          style={{ color: theme.palette.text.primary }}
                        >
                          <Stop sx={{ marginRight: "0.5rem" }} />
                          Pause Job
                        </Button>
                      ) : (
                        <Button
                          disabled={!!!values}
                          onClick={() => {
                            submitForm({ descstate: DescState.EXECUTION }, false);
                          }}
                          fullWidth
                          variant="contained"
                          color="success"
                        >
                          <PlayArrow sx={{ marginRight: "0.5rem" }} />
                          Resume Job
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Box>
          <Card sx={{ p: "2rem" }}>
            <JobForm
              handleCancel={handleCancel}
              initialValues={values}
              validationSchema={(data) => checkoutSchema(data as Job_job)}
              submitForm={submitForm}
              isUpdate={true}
              data={data?.job}
              blockUpdate={
                data?.job?.descstate === DescState.EXECUTION ||
                data?.job?.descstate === DescState.CONCLUDED ||
                data?.job?.scannedSlabs >= data?.job?.totalslab
              }
            />
          </Card>
        </>
      )}
    </Container>
  );
}

const checkoutSchema = (job: Job_job) =>
  yup.object().shape(
    {
      description: yup.string().nullable().notRequired(),
      jobtype: yup.string().required("Required"),
      scan: yup.string().required("Required"),
      blocknumber: yup.string().notRequired().nullable(),
      category: yup.string().notRequired().nullable(),
      commercial_finishing: yup.string().notRequired().nullable(),
      commercial_variety: yup.string().notRequired().nullable(),
      finish: yup.string().notRequired().nullable(),
      packing: yup.string().notRequired().nullable(),
      project: yup.string().notRequired().nullable(),
      stone_edge: yup.string().notRequired().nullable(),
      stone_model: yup.string().notRequired().nullable(),
      typematerial: yup.string().notRequired().nullable(),
      variety: yup.string().notRequired().nullable(),
      isblock: yup.boolean().nullable(),
      descstate: yup.mixed<DescState>().oneOf(Object.values(DescState)).notRequired(),
      separator: yup.string().nullable().notRequired(),
      block: yup.string().nullable().notRequired(),
      numberingOrder: yup
        .mixed<JobNumberingOrder>()
        .oneOf(Object.values(JobNumberingOrder))
        .notRequired(),
      startNumber: yup
        .number()
        .when(["numberingOrder", "endNumber"], (values: any[], schema: yup.NumberSchema) => {
          let numberingOrder = values[0] as JobNumberingOrder;
          let endNumber = values[1] as number;
          return numberingOrder === JobNumberingOrder.DESC
            ? schema.min(endNumber || 1, "Should be greater or equal to End Number").required()
            : schema.max(endNumber || 1, "Should be less or equal to End Number").required();
        }),
      endNumber: yup
        .number()
        .when(["numberingOrder", "startNumber"], (values: any[], schema: yup.NumberSchema) => {
          let numberingOrder = values[0] as JobNumberingOrder;
          let startNumber = values[1] as number;
          return numberingOrder === JobNumberingOrder.ASC
            ? schema.min(startNumber || 1, "Should be greater or equal to Start Number").required()
            : schema.max(startNumber || 1, "Should be less or equal to Start Number").required();
        }),
      prefix: yup.string().required("Prefix is required").nullable(),
    },
    [["endNumber", "startNumber"]]
  );
