import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  ContainerProps,
  Drawer,
  Grid,
  GridProps,
  Hidden,
  IconButton,
  Skeleton,
  styled,
  useMediaQuery,
} from "@material-ui/core";
import { keyframes } from "@emotion/react";
import { layoutConstant } from "../../utils/constants";
import BazarCard from "../../components/GeneralComponents/BazarCard";
import { makeStyles } from "@material-ui/styles";
import { Close } from "@material-ui/icons";
import { MuiThemeProps } from "../../theme/theme";
import LoadingFitCenter from "../../components/GeneralComponents/LoadingFitCenter";
import LoadingFitCenterRelative from "../../components/GeneralComponents/LoadingFitCenterRelative";

interface PaginationContainerStructureProps {
  children: any;
  top: JSX.Element;
  side: JSX.Element;
  isOpen: boolean;
  toggle: () => void;
  sideMobile?: JSX.Element;
  sideMobileActions?: JSX.Element;
  resultsLoading: boolean;
  childrenPagination: JSX.Element;
}

const StyledGrid = styled<React.FC<GridProps>>(({ children, ...rest }) => (
  <Grid {...rest}>{children}</Grid>
))<ContainerProps>(({ theme }) => ({
  ".sidenav": {
    position: "fixed",
    width: layoutConstant.grocerySidenavWidth,
    top: layoutConstant.headerHeight + layoutConstant.topBarPaginationContainerHeight,
    height: `calc(80vh - ${layoutConstant.headerHeight}px)`,
    bottom: 0,
    left: 0,
    zIndex: 20,
  },
  ".structureContainer": {
    position: "fixed",
    top: layoutConstant.headerHeight,
    width: "100%",
    maxHeight: layoutConstant.topBarPaginationContainerHeight,
    height: layoutConstant.topBarPaginationContainerHeight,
    alignItems: "center",
    zIndex: 30,
  },
  ".topBarContainer": {
    position: "fixed",
    top: layoutConstant.headerHeight,
    width: "100%",
    zIndex: 30,
    maxHeight: layoutConstant.topBarPaginationContainerHeight,
    height: layoutConstant.topBarPaginationContainerHeight,
    left: 0,
  },
  "@keyframes slideDown": {
    "0%": {
      opacity: 0,
      transform: "translateY(0)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  [theme.breakpoints.down("md")]: {
    ".sidenav": {
      display: "none",
    },
    ".pageContent": {
      width: "100% !important",
      marginLeft: "auto !important",
      marginRight: "auto !important",
      left: "0px !important",
    },
  },
}));

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  paper: {
    width: "100%",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}));

const myEffect = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-200%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
const myEffectExit = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-200%);
  }
`;

export default function PaginationContainerStructure({
  children,
  isOpen,
  toggle,
  side,
  top,
  sideMobile,
  sideMobileActions,
  resultsLoading,
  childrenPagination,
}: PaginationContainerStructureProps) {
  const theme = useTheme();
  const classes = useStyles();
  const childrenContainerDesktopRef = useRef<any>(null);
  const childrenContainerMobileRef = useRef<any>(null);
  const [childrenContainerHeight, setChildrenContainerHeight] = useState<number>(0);
  const [childrenContainerWidth, setChildrenContainerWidth] = useState<number>(0);
  const isSmallerThanMedium = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (!!childrenContainerDesktopRef?.current && !isSmallerThanMedium) {
      setChildrenContainerHeight(childrenContainerDesktopRef?.current?.clientHeight || 0);
      setChildrenContainerWidth(childrenContainerDesktopRef?.current?.clientWidth || 0);
    } else if (!!childrenContainerMobileRef?.current && isSmallerThanMedium) {
      setChildrenContainerHeight(childrenContainerMobileRef?.current?.clientHeight || 0);
      setChildrenContainerWidth(childrenContainerMobileRef?.current?.clientWidth || 0);
    }
  }, [resultsLoading]);

  return (
    <StyledGrid>
      <BazarCard
        className={clsx({
          structureContainer: true,
        })}
      >
        <BazarCard
          className={clsx({
            topBarContainer: true,
          })}
        >
          {top}
        </BazarCard>
      </BazarCard>
      <hr />
      <div className="container-fluid" role="bar">
        <div
          className="row"
          style={{ marginTop: layoutConstant.topBarPaginationContainerHeight, paddingTop: 20 }}
        >
          <Hidden only={["sm", "xs"]}>
            <BazarCard
              sx={{
                backgroundColor: theme.palette.common.white,
                animation: isOpen
                  ? `${myEffect} 300ms ${theme.transitions.easing.easeInOut}`
                  : `${myEffectExit} 300ms ${theme.transitions.easing.easeInOut};`,
                opacity: isOpen ? 1 : 0,
                transform: isOpen ? "" : "translateX(-200%)",
                p: "20px 20px 14px 24px",
                borderRadius: "5px",
                overflow: "auto",
                minHeight: window.innerHeight - layoutConstant.headerHeight * 2.1,
              }}
              className={clsx({
                "col-md-3 col-lg-3 col-xxl-2 d-md-block sidebar collapse": true,
                sidenav: true,
              })}
            >
              {side}
            </BazarCard>
          </Hidden>
          {isOpen && (
            <>
              <Hidden only={["sm", "xs"]}>
                <Box className="col-md-3 col-lg-3 col-xxl-3 d-md-block sidebar collapse" />
              </Hidden>
              <Hidden only={["lg", "md", "xl"]}>
                <Drawer open={isOpen} onBackdropClick={toggle} classes={{ paper: classes.paper }}>
                  <div className={classes.drawerHeader}>
                    <IconButton onClick={toggle}>
                      <Close />
                    </IconButton>
                  </div>
                  <BazarCard
                    sx={{
                      backgroundColor: theme.palette.common.white,
                      opacity: isOpen ? 1 : 0,
                      p: "20px 20px 14px 24px",
                      borderRadius: "5px",
                      overflow: "auto",
                    }}
                  >
                    {!!sideMobile ? sideMobile : side}
                  </BazarCard>
                  {sideMobileActions}
                </Drawer>
              </Hidden>
            </>
          )}
          <Hidden only={["sm", "xs"]}>
            <Box
              className={
                isOpen
                  ? "col-md-9 ms-sm-auto col-lg-9 col-xxl-10"
                  : "col-md-12 col-lg-12 ms-sm-auto col-xxl-12"
              }
              justifyContent={isOpen ? "flex-start" : "stretch"}
              ref={childrenContainerDesktopRef}
            >
              {resultsLoading && (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={!!childrenContainerWidth ? childrenContainerWidth : "100%"}
                  height={!!childrenContainerHeight ? childrenContainerHeight : "100%"}
                />
              )}
              {resultsLoading && <LoadingFitCenterRelative />}
              {!!!resultsLoading && children}
              <hr />
              {!!!resultsLoading && childrenPagination}
            </Box>
          </Hidden>
          <Hidden only={["lg", "md", "xl"]}>
            <Box ref={childrenContainerMobileRef}>
              {resultsLoading && (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={!!childrenContainerWidth ? childrenContainerWidth : "100%"}
                  height={!!childrenContainerHeight ? childrenContainerHeight : "100%"}
                />
              )}
              {resultsLoading && <LoadingFitCenterRelative />}
              {!!!resultsLoading && children}
              <hr />
              {!!!resultsLoading && childrenPagination}
            </Box>
          </Hidden>
        </div>
      </div>
    </StyledGrid>
  );
}
