import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@material-ui/core";
import {
  Bookmark,
  Class,
  Close,
  CloudDownload,
  Delete,
  Info,
  AddCircle,
  KeyboardArrowRight,
  OpenInNew,
} from "@material-ui/icons";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { blobMetadata, blobMetadataVariables, Slab_slab } from "../../data/graphQLModel";
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProps } from "../../theme/theme";
import { ImageListComponent } from "./ImageListComponent";
import { QUERY_BLOB_METADATA } from "../../data/graphQLQueries";
import { useQuery } from "@apollo/react-hooks";
import { grey } from "../../theme/themeColors";
import DownloadImageDialog from "./DownloadImageDialog";
import AddMetadataDialog from "./AddMetadataDialog";
import ReactImageMagnify from "@blacklab/react-image-magnify";
import { ISelectedImageInfo } from "../Blocks/BlocksDetailsComponent";
import { useHistory } from "react-router-dom";
import { getImageEntityURLString, openNewTab } from "../../utils/utils";

export interface SlabDetailsComponentProps {
  slab: Slab_slab;
  onDelete?: (index: number) => Promise<void>;
  onMarkPrimary?: (index: number) => Promise<void>;
  refetch?: Function;
  hideMagnify?: boolean;
  showNavButtons?: boolean;
}

const useStyles = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  first: {
    width: "90%",
    marginBottom: "3ch",
  },
  second: {
    width: "100%",
    marginBottom: "3ch",
  },
  margin: {
    marginBottom: "3ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
  headerMatType: {
    fontSize: 20,
    fontWeight: "lighter",
    marginRight: 10,
  },
  headerVariety: {
    fontWeight: "bold",
    fontSize: 20,
  },
  descriptionDefault: {
    color: palette.grey[600],
    fontSize: 14,
    marginBottom: "3px",
  },
  aboutHeader: {
    fontSize: 18,
  },
  aboutText: {
    color: palette.grey[600],
    fontSize: 14,
  },
}));

const useStylesDialog = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    margin: "auto",
    overflow: "hidden",
    transition: "all 250ms ease-in-out",
    borderRadius: "8px",

    "&:hover": {
      "& $imageHolder": {
        "& .extra-icons": {
          display: "flex",
        },
      },
    },

    "@media only screen and (max-width: 768px)": {
      "& $imageHolder": {
        "& .extra-icons": {
          display: "flex",
        },
      },
    },
  },
  imageHolder: {
    position: "relative",
    display: "inlin-block",
    textAlign: "center",

    "& .extra-icons": {
      display: "none",
      flexDirection: "column",
      position: "absolute",
      top: "7px",
      right: "15px",
      cursor: "pointer",
      zIndex: 2,
    },

    [theme.breakpoints.down(1)]: {
      display: "block",
    },
  },
  offerChip: {
    position: "absolute",
    fontSize: "10px",
    fontWeight: 600,
    paddingLeft: 3,
    paddingRight: 3,
    top: "10px",
    left: "10px",
  },
  details: {
    padding: "1rem",

    "& .title, & .categories": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    "& .icon-holder": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },

    "& .favorite-icon": {
      cursor: "pointer",
    },
  },
  dialogContent: {
    paddingBottom: "1.25rem",
  },
}));

const getHeader = (slab: Slab_slab, className: string, classNameVar: string) => {
  const matType = slab?.commercial_variety?.materialtype
    ? slab?.commercial_variety?.materialtype?.description?.pt
    : slab?.typematerial?.description?.pt
    ? slab?.typematerial?.description?.pt
    : "No Type";
  const variety = slab?.commercial_variety
    ? slab?.commercial_variety?.name
    : slab?.variety?.normativedesig
    ? slab?.variety?.normativedesig
    : "No Name";
  return (
    <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
      <Typography className={className}>{matType}</Typography>
      <Typography className={className}>{"-"}</Typography>
      <Typography className={classNameVar}>{variety}</Typography>
    </Box>
  );
};

export const SlabDetailsComponent = ({
  slab,
  onDelete,
  onMarkPrimary,
  refetch,
  hideMagnify,
  showNavButtons = false,
}: SlabDetailsComponentProps) => {
  const history = useHistory();
  const classes = useStyles();
  const classesDialog = useStylesDialog();
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [isMainSelected, setIsMainSelected] = useState<boolean>(true);
  const [metadata, setMetadata] = useState<object>();

  // metadata dialog states
  const [openMetadataDialog, setOpenMetadataDialog] = useState(false);
  const toggleDialog = useCallback(() => {
    setOpenMetadataDialog((openMetadataDialog) => !openMetadataDialog);
  }, []);

  // download dialog states
  const [openDownloadDialog, setOpenDonwloadDialog] = useState(false);
  const toggleDownloadDialog = useCallback(() => {
    setOpenDonwloadDialog((openDownloadDialog) => !openDownloadDialog);
  }, []);

  // Add metadata dialog states
  const [openAddMetadataDialog, setOpenAddMetadataDialog] = useState(false);
  const toggleAddMetadataDialog = useCallback(() => {
    setOpenAddMetadataDialog((openAddMetadataDialog) => !openAddMetadataDialog);
  }, []);

  const { data } = useQuery<blobMetadata, blobMetadataVariables>(QUERY_BLOB_METADATA, {
    variables: {
      url: getImageEntityURLString(slab?.slabImages?.[selectedImage], false) as string,
    },
  });

  const checkIfPrimarySelected = () => {
    let main_image = slab?.mainImage ? slab?.mainImage : slab?.main_image;
    if (
      main_image === slab?.slabImages?.[selectedImage]?.azureBlobID ||
      main_image === getImageEntityURLString(slab?.slabImages?.[selectedImage], false)
    ) {
      setIsMainSelected(true);
    } else {
      setIsMainSelected(false);
    }
  };

  const getMetadata = async () => {
    let meta = data?.blobMetadata.metadata.replace(/\\g/, "");
    const objectMeta = typeof meta === "string" ? JSON.parse(meta) : "";
    // const image = await download(slab?.images[selectedImage])
    // const actualMeta = await getImageMetadata(image)
    setMetadata(objectMeta);
  };

  useEffect(() => {
    checkIfPrimarySelected();
  }, [selectedImage]);

  useEffect(() => {
    if (data) {
      getMetadata();
    }
  }, [selectedImage, data]);

  const imageContainerRef = useRef<any>(null);
  const [imageContainerHeight, setImageContainerHeight] = useState<number>(0);
  const [imageContainerWidth, setImageContainerWidth] = useState<number>(0);

  const [selectedImageInfo, setSelectedImageInfo] = useState<ISelectedImageInfo>({
    height: 400,
    width: 600,
  });

  useEffect(() => {
    if (!!imageContainerRef?.current) {
      setImageContainerHeight(imageContainerRef?.current?.offsetHeight || 0);
      // get true width by subtracting imagelist width from parent width
      let firstChildWidth = imageContainerRef?.current?.firstChild?.offsetWidth;
      let containerWidth = imageContainerRef?.current?.offsetWidth;
      setImageContainerWidth(
        !!firstChildWidth && !!containerWidth
          ? containerWidth - firstChildWidth
          : containerWidth || 0
      );
    }
  }, [imageContainerRef?.current]);

  const calcAndSetImageSize = (natWidth: number, natHeight: number) => {
    if (!!natWidth && !!natHeight) {
      let newWidth = (natWidth * imageContainerHeight) / natHeight;
      // if width too large set new height based on possible max width
      if (newWidth > imageContainerWidth) {
        setSelectedImageInfo({
          height: (natHeight * newWidth) / natWidth,
          width: imageContainerWidth,
          naturalHeight: natHeight,
          naturalWidth: natWidth,
        });
      } else {
        setSelectedImageInfo({
          height: imageContainerHeight,
          width: newWidth,
          naturalHeight: natHeight,
          naturalWidth: natWidth,
        });
      }
    }
  };

  const onScroll = (e: any) => {
    calcAndSetImageSize(selectedImageInfo.width, selectedImageInfo.height);
  };

  return (
    <Fragment>
      {slab && (
        <div onScroll={onScroll}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Grid
              item
              md={8}
              xs={12}
              style={{ flex: 1, flexDirection: "row", display: "flex", height: "40vh" }}
              ref={imageContainerRef}
            >
              <ImageListComponent
                images={slab?.slabImages?.map((el) => getImageEntityURLString(el) || "")}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                direction="vertical"
              />
              <Box
                sx={{
                  alignItems: "center",
                  height: "auto",
                }}
              >
                {(slab.images || slab?.slabImages) &&
                  (slab.images.length > 0 || slab?.slabImages?.length > 0) && (
                    <Box
                      className="extra-icons"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        position: "absolute",
                        zIndex: 10,
                        ml: 2,
                      }}
                    >
                      {(slab.images.length > 0 || slab?.slabImages?.length > 0) &&
                        (onMarkPrimary || onDelete) && (
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            {(slab.images.length > 1 || slab?.slabImages?.length > 1) &&
                              onMarkPrimary && (
                                <IconButton
                                  onClick={() => onMarkPrimary(selectedImage)}
                                  title="Mark as Primary"
                                  disabled={isMainSelected}
                                >
                                  <Bookmark
                                    sx={{
                                      cursor: "pointer",
                                      ":hover": { backgroundColor: grey[400], borderRadius: 3 },
                                      height: 40,
                                      width: 40,
                                    }}
                                    fontSize="large"
                                    color={!isMainSelected ? "primary" : "disabled"}
                                  />
                                </IconButton>
                              )}
                            {onDelete && (
                              <IconButton
                                onClick={() => onDelete(selectedImage)}
                                title="Delete Photo"
                              >
                                <Delete
                                  sx={{
                                    cursor: "pointer",
                                    ":hover": { backgroundColor: grey[400], borderRadius: 3 },
                                    height: 40,
                                    width: 40,
                                  }}
                                  fontSize="large"
                                  color={"secondary"}
                                />
                              </IconButton>
                            )}
                          </Box>
                        )}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          ml:
                            (slab.images.length > 1 || slab?.slabImages?.length > 1) &&
                            (onMarkPrimary || onDelete)
                              ? "200px"
                              : 0,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            toggleDialog();
                          }}
                          title="Image Information"
                        >
                          <Info
                            sx={{
                              cursor: "pointer",
                              ":hover": { backgroundColor: grey[400], borderRadius: 3 },
                              height: 40,
                              width: 40,
                              right: 0,
                            }}
                            fontSize="large"
                            color={"info"}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                {/* Download box */}
                <Grid item xs={11}>
                  <a
                    href={
                      getImageEntityURLString(slab?.slabImages?.[selectedImage], false) ||
                      "../../images/imagemissing.png"
                    }
                    style={{ cursor: "default" }}
                  >
                    {(!!slab?.images || !!slab?.slabImages) &&
                    (slab?.images.length > 0 || slab?.slabImages?.length > 0) &&
                    !hideMagnify ? (
                      !!imageContainerHeight &&
                      (!!selectedImage || selectedImage === 0) && (
                        <ReactImageMagnify
                          imageProps={{
                            src:
                              getImageEntityURLString(slab?.slabImages?.[selectedImage], false) ||
                              "../../images/imagemissing.png",
                            width: selectedImageInfo.width,
                            height: selectedImageInfo.height,
                            onLoad(e) {
                              //@ts-ignore
                              let natWidth = e?.target?.naturalWidth as number;
                              //@ts-ignore
                              let natHeight = e?.target?.naturalHeight as number;
                              calcAndSetImageSize(natWidth, natHeight);
                            },
                            style: {
                              width: selectedImageInfo.width,
                              height: selectedImageInfo.height,
                            },
                          }}
                          magnifiedImageProps={{
                            height: selectedImageInfo.height * 5,
                            width: selectedImageInfo.width * 5,
                            src:
                              getImageEntityURLString(slab?.slabImages?.[selectedImage], false) ||
                              "../../images/imagemissing.png",
                          }}
                          // Portal is the element where the magnified image appears
                          portalProps={{
                            style: {
                              zIndex: 101,
                              position: "absolute",
                              inset: "0px auto auto 0px",
                              // this calc was wrong in the lib so we had to fix it
                              // imageContainerRef.current?.children[1]?.offsetLeft is the number of pixels that the Grid containing the image is from the left end
                              // of the window, add that to the width of the image and you get the correct position of the Portal
                              transform: `translate(${
                                imageContainerRef.current?.children[1]?.offsetLeft +
                                selectedImageInfo.width
                              }px, ${imageContainerRef.current.offsetTop}px)`,
                            },
                          }}
                        />
                      )
                    ) : (
                      <img
                        style={{
                          width: selectedImageInfo.width,
                          height: selectedImageInfo.height,
                        }}
                        src={
                          getImageEntityURLString(slab?.slabImages?.[selectedImage], false) ||
                          "../../images/imagemissing.png"
                        }
                      />
                    )}
                  </a>
                </Grid>
              </Box>
            </Grid>
            <Grid style={{ flex: 1, marginLeft: "20px" }}>
              {getHeader(slab, classes.headerMatType, classes.headerVariety)}
              {slab?.category && (
                <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                  <Class color={"disabled"} />
                  <Typography className={classes.descriptionDefault}>
                    {slab?.category ? slab.category.description.pt : ""}
                  </Typography>
                </Box>
              )}
              <Typography className={classes.descriptionDefault}>
                Dimensions: {slab.width ? slab.width / 10 : 0}cm by{" "}
                {slab.height ? slab.height / 10 : 0}cm
              </Typography>
              <Typography className={classes.descriptionDefault}>
                Thickness: {slab.thickness ? slab.thickness / 10 : 0}cm
              </Typography>
              <Typography className={classes.descriptionDefault}>
                Area: {slab.area ? slab.area : "? "}m2
              </Typography>
              <Typography className={classes.descriptionDefault}>
                Stone Type:{" "}
                {slab?.commercial_variety?.materialtype
                  ? slab?.commercial_variety?.materialtype?.description?.pt
                  : slab?.typematerial
                  ? slab?.typematerial?.description?.pt
                  : ""}
              </Typography>
              <Typography className={classes.descriptionDefault}>
                Stone Name:{" "}
                {slab?.commercial_variety
                  ? slab?.commercial_variety.name
                  : slab?.variety?.normativedesig
                  ? slab?.variety?.normativedesig
                  : ""}
              </Typography>
              <Typography className={classes.descriptionDefault}>
                Finishing:{" "}
                {slab?.commercial_finishing ? slab?.commercial_finishing.description.pt : ""}
              </Typography>
              <Typography className={classes.descriptionDefault}>
                Package: {slab?.packing ? slab?.packing.packing : ""}
              </Typography>
              <Typography className={classes.descriptionDefault}>
                Location:{" "}
                {slab.inStock ? (slab?.company?.name ? slab?.company?.name : "") : "Customer"}
              </Typography>
              <Typography className={classes.descriptionDefault}>
                Product ID: {slab.code}
              </Typography>
              <Stack
                flexDirection={"column"}
                alignItems="flex-start"
                mt={2}
                spacing={1}
                sx={{ display: showNavButtons ? "flex" : "none" }}
              >
                <Button
                  onClick={(e) => {
                    openNewTab("/slabmanagement/details/" + btoa(slab?.id as string));
                  }}
                  color="primary"
                  variant="contained"
                  endIcon={<OpenInNew />}
                >
                  Open in new tab
                </Button>
                <Button
                  onClick={(e) => {
                    history.push({
                      pathname: "/slabmanagement/details/" + btoa(slab?.id as string),
                    });
                  }}
                  color="primary"
                  variant="contained"
                  endIcon={<KeyboardArrowRight />}
                >
                  Go to details
                </Button>
              </Stack>
              {
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography className={classes.headerVariety} sx={{ fontWeight: "700", mt: 1 }}>
                    Metadata (short):
                  </Typography>
                  <IconButton onClick={() => toggleAddMetadataDialog()} sx={{ mt: 0.8 }}>
                    <AddCircle className="close" fontSize="medium" color="primary" />
                  </IconButton>
                </Box>
              }
              {metadata &&
                Object.entries(metadata).map(([key, value]) => {
                  if (toNotListMetadataKeys.indexOf(key) === -1)
                    return (
                      <Box key={key} sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          className={classes.descriptionDefault}
                          sx={{ fontWeight: "700", mr: 1 }}
                        >
                          {key}:
                        </Typography>
                        <Typography className={classes.descriptionDefault}>{value}</Typography>
                      </Box>
                    );
                })}
            </Grid>
          </Box>
          {((slab.images && slab.images.length > 0 && slab?.images?.[selectedImage]) ||
            (slab?.slabImages &&
              slab?.slabImages?.length &&
              slab?.slabImages?.[selectedImage])) && (
            <Button
              color="secondary"
              type="button"
              sx={{
                bgcolor: "primary.light",
                alignSelf: "center",
                mt: 2,
                ml: 2,
              }}
              onClick={() => toggleDownloadDialog()}
              startIcon={<CloudDownload />}
            >
              Download Selected Image
            </Button>
          )}
        </div>
      )}
      {metadata && (
        <Dialog open={openMetadataDialog} maxWidth={false} onClose={() => toggleDialog()}>
          <DialogContent className={classesDialog.dialogContent}>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Metadata
            </Typography>
            {Object.entries(metadata).map(([key, value]) => (
              <Box key={key} sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ fontWeight: "700", mr: 1 }}>{key}:</Typography>
                <Typography>{value}</Typography>
              </Box>
            ))}
            {/* <ReactJson src={metadata} enableClipboard /> */}
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}
              onClick={() => toggleDialog()}
            >
              <Close className="close" fontSize="medium" color="primary" />
            </IconButton>
          </DialogContent>
        </Dialog>
      )}
      {openDownloadDialog && (
        <DownloadImageDialog
          open={openDownloadDialog}
          toggle={toggleDownloadDialog}
          url={getImageEntityURLString(slab?.slabImages?.[selectedImage], false) as string}
          metadata={metadata}
          slab={slab}
        />
      )}
      {openAddMetadataDialog && (
        <AddMetadataDialog
          open={openAddMetadataDialog}
          toggle={toggleAddMetadataDialog}
          url={getImageEntityURLString(slab?.slabImages?.[selectedImage], false) as string}
          refetch={refetch}
        />
      )}
    </Fragment>
  );
};

const toNotListMetadataKeys = [
  "MetadataDate",
  "Thumbnail",
  "hierarchicalSubject",
  "ModifyDate",
  "DocumentID",
  "InstanceID",
  "OriginalDocumentID",
  "PhotographicSensitivity",
  "Latitude",
  "Longitude",
  "Altitude",
  "CreatorTool",
  "description",
  "rights",
  "LensInfo",
  "Lens",
  "LastKeywordXMP",
  "LastKeywordIPTC",
  "CreateDate",
  "DateCreated",
];
