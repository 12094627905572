import React, { useEffect, useState } from "react";
//@ts-ignore
import { Helmet } from "react-helmet";
import { Box, Container, LinearProgress } from "@material-ui/core";
import TablePageHeaderComponent from "../../../components/Table/TablePageHeaderComponent";
import GeneralTableComponent, { IHeadCells } from "../../../components/Table/GeneralTableComponent";
import { NetworkStatus, useQuery } from "@apollo/react-hooks";
import { QUERY_STONE_EDGES, QUERY_STONE_MODELS } from "../../../data/graphQLQueries";
import {
  CursorPaging,
  SortDirection,
  StoneEdges,
  StoneEdgesVariables,
  StoneEdges_stoneEdges_edges_node,
  StoneModelFilter,
  StoneModels,
  StoneModelSort,
  StoneModelSortFields,
  StoneModelsVariables,
  StoneModels_stoneModels_edges_node,
} from "../../../data/graphQLModel";
import toast from "react-hot-toast";
import NewTablePageHeaderComponent from "../../../components/Table/NewTablePageHeaderComponent";
import NewGeneralTableComponent from "../../../components/Table/NewGeneralTableComponent";
import { onChangePageCursor } from "../../../utils/utils";
const LOADING_TOAST = "LOADING_TOAST";

const headCells = [
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  { id: "description", numeric: false, disablePadding: false, label: "Description" },
  { id: "date", numeric: false, disablePadding: false, label: "Date" },
];

const getRowValues = (row: StoneModels_stoneModels_edges_node): IHeadCells[] => {
  return [
    {
      id: row.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    {
      id: row.name,
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: row.description,
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: String(row.createdAt),
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
};

const DEFAULT_LIMIT = 10;
const DEFAULT_SORTING: StoneModelSort[] = [
  { direction: SortDirection.DESC, field: StoneModelSortFields.createdAt },
];
const DEFAULT_PAGING: CursorPaging = { first: DEFAULT_LIMIT };

export default function StoneModelList() {
  const [filter, setFilter] = useState<StoneModelFilter>({});
  const [paging, setPaging] = useState<CursorPaging>(DEFAULT_PAGING);
  const [sorting, setSorting] = useState<StoneModelSort[]>(DEFAULT_SORTING);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { loading, error, data, networkStatus } = useQuery<StoneModels, StoneModelsVariables>(
    QUERY_STONE_MODELS,
    {
      variables: {
        paging,
        sorting,
        filter,
      },
    }
  );

  return (
    <>
      <Helmet>
        <title>Stone Models | WeScan</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ mt: 10 }}>
        <NewTablePageHeaderComponent
          entityNamePlural={"Stone Models"}
          pathEntity={"/customerstables/stonemodel"}
          gridProps={{ sx: { mb: 2, mt: 2 } }}
          buttonTitle={"New Stone Model"}
        />
        <NewGeneralTableComponent
          data={!!data?.stoneModels ? data?.stoneModels?.edges?.map((edge) => edge.node) : []}
          loading={loading || networkStatus === NetworkStatus.fetchMore}
          error={error}
          LoadingComponent={() => (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          onChangePage={(e: any, page: number) => {
            onChangePageCursor(
              e,
              page,
              pageNumber,
              !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
              data?.stoneModels?.pageInfo || ({} as any),
              (pageNumber: number, newPaging: CursorPaging) => {
                setPaging(newPaging);
                setPageNumber(pageNumber);
              }
            );
          }}
          onRowsPerPageChange={(event) => {
            setPaging({ first: parseInt(event.target.value, 10) });
          }}
          page={pageNumber}
          rowsPerPage={!!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT}
          headCells={headCells}
          totalCount={data?.stoneModels?.totalCount || 0}
          handleSort={(field, order) => {
            setSorting([{ field: field as StoneModelSortFields, direction: order }]);
          }}
          orderByField={sorting[0].field}
          orderDirection={sorting[0].direction}
          sortableFields={Object.values(StoneModelSortFields)}
          key={"pck"}
          getValues={getRowValues}
          disableSelectAll
          PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
          tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          editPath={"/customerstables/stonemodel/edit"}
          pathEntity={"/customerstables/stonemodel"}
          deletePath={"/customerstables/stonemodel/delete"}
          detailsPath={"/customerstables/stonemodel/edit"}
          base64OrDataOrIdOnDelete={"Data"}
          disableMultipleSelection
        />
      </Container>
    </>
  );
}
