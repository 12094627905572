import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";

const useFullWindowSize = () => {
  const [size, setSize] = useState({
    innerWidth: 0,
    innerHeight: 0,
    outerWidth: 0,
    outerHeight: 0,
  });

  const windowListener = useCallback(
    debounce(() => {
      if (window)
        setSize({
          innerWidth: window.innerWidth,
          innerHeight: window.innerHeight,
          outerHeight: window.outerHeight,
          outerWidth: window.outerWidth,
        });
    }, 250),
    []
  );

  useEffect(() => {
    if (window) {
      setSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        outerWidth: window.outerWidth,
        outerHeight: window.outerHeight,
      });
      window.addEventListener("resize", windowListener);
    }
    return () => {
      windowListener.cancel();
      window && window.removeEventListener("resize", windowListener);
    };
  }, []);

  return size;
};

export default useFullWindowSize;
