import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { PropsWithChildren } from "react";
import theme from "./theme";
import { StyledEngineProvider } from "@material-ui/core/styles";

const MuiTheme: React.FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </ThemeProvider>
  );
};

export default MuiTheme;
