import { ICompleteHeadCells } from "../../components/Table/NewGeneralTableComponent";
import { Slabs_slabs_nodes } from "../../data/graphQLModel";
import { getFieldValueOfObjectByPreference, getImageEntityURLString } from "../../utils/utils";

export const slabHeadCellsForBlockDetails: ICompleteHeadCells[] = [
  { id: "slabImages", numeric: false, disablePadding: false, label: "" },
  { id: "slab", numeric: false, disablePadding: false, label: "Slab" },
  { id: "scan", numeric: false, disablePadding: false, label: "Scan" },
  { id: "WxHxT, mm", numeric: false, disablePadding: false, label: "WxHxT, mm" },
  { id: "m2", numeric: false, disablePadding: false, label: "m2" },
  {
    id: "iscatalogued",
    numeric: false,
    disablePadding: false,
    label: "Catalogued",
    isBoolean: true,
  },
  { id: "inStock", numeric: false, disablePadding: false, label: "Stock", isBoolean: true },
  { id: "createdate", numeric: false, disablePadding: false, label: "Date" },
];

export const getSlabRowValuesForBlockDetails = (row: Slabs_slabs_nodes): ICompleteHeadCells[] => {
  return [
    {
      id: row?.slabImages.map((el) => getImageEntityURLString(el) || "") || "",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: row?.code,
      numeric: false,
      disablePadding: false,
      label: "Slab",
    },
    {
      id: row?.scan?.description || "",
      numeric: false,
      disablePadding: false,
      label: "Scanner",
    },
    {
      id: `${row.width ? row.width : 0}x${row.height ? row.height : 0}x${
        row.thickness ? row.thickness : 0
      }`,
      numeric: false,
      disablePadding: false,
      label: "WxHxT, mm",
    },
    {
      id: row?.area ? row?.area + "" : "",
      numeric: false,
      disablePadding: false,
      label: "M2",
    },
    {
      id: row?.iscatalogued + "" || "",
      numeric: false,
      disablePadding: false,
      label: "Catalogued",
      isBoolean: true,
    },
    {
      id: row?.inStock + "" || "",
      numeric: false,
      disablePadding: false,
      label: "Stock",
      isBoolean: true,
    },
    {
      id: String(getFieldValueOfObjectByPreference(row, ["createdate"])),
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
};
