import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Table, { TableProps } from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer, { TableContainerProps } from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Avatar, lighten } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
//@ts-ignore
import { useHistory } from "react-router-dom";
import moment from "moment";
import { MuiThemeProps } from "../../theme/theme";
import { getThumbnail } from "../../utils/utils";

let data = {};

function EnhancedTableHead(props: EnhancedTableHeadPropTypes) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {!props.disableSelection && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          )}
        </TableCell>
        {props.headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};

interface EnhancedTableHeadPropTypes {
  classes: any;
  numSelected: number;
  onRequestSort: (...args: unknown[]) => unknown;
  onSelectAllClick: (...args: unknown[]) => unknown;
  order: "asc" | "desc";
  orderBy: string;
  rowCount: number;
  headCells: IHeadCells[];
  disableSelection: boolean;
}

const useToolbarStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const history = useHistory();
  const { numSelected } = props;
  let c = numSelected === 1 && (data as any);
  const handleDelete = () => {
    let path = !!props.deletePath ? props.deletePath : `${props.path}/delete`;
    if (props.base64OrDataOrIdOnDelete === "Base64") {
      history.push({ pathname: `${path}/` + btoa(c.id) });
    } else if (props.base64OrDataOrIdOnDelete === "Data") {
      history.push({
        pathname: path,
        data: c,
      } as any);
    } else {
      history.push({ pathname: `${path}/` + c.id });
    }
  };

  const handleEdit = () => {
    let path = !!props.editPath ? props.editPath : `${props.path}/edit`;
    if (props.base64OrDataOnEdit === "Base64") {
      history.push({ pathname: `${path}/` + btoa(c.id) });
    } else if (props.base64OrDataOnEdit === "Data") {
      history.push({
        pathname: path,
        data: c,
      } as any);
    } else {
      history.push({ pathname: `${path}/` + c.id });
    }
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {props.tableSubTitle && props.tableSubTitle !== "" && (
        <Typography component={"h5"} style={{ fontSize: 30, marginLeft: 15 }}>
          {props.tableSubTitle}
        </Typography>
      )}

      {numSelected > 0 && (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      )}

      {numSelected > 0 && numSelected === 1 && (
        <>
          {!props.disableEdit && (
            <Tooltip title="Edit">
              <IconButton aria-label="edit" onClick={handleEdit}>
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {!props.disableRemove && (
            <Tooltip title="Delete" onClick={handleDelete}>
              <IconButton aria-label="delete">
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  disableEdit: PropTypes.bool.isRequired,
  disableRemove: PropTypes.bool.isRequired,
  base64OrDataOnEdit: PropTypes.string.isRequired,
  tableSubTitle: PropTypes.string,
  editPath: PropTypes.string,
  base64OrDataOrIdOnDelete: PropTypes.string,
  deletePath: PropTypes.string,
};

interface EnhancedTableToolbarProps {
  numSelected: number;
  path: string;
  disableEdit: boolean;
  disableRemove: boolean;
  tableSubTitle?: string | undefined;
  base64OrDataOnEdit: "Base64" | "Data" | "ID";
  editPath?: string;
  base64OrDataOrIdOnDelete?: "Base64" | "Data" | "ID";
  deletePath?: string;
}

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const createTableCells = (
  headCells: IHeadCells[],
  row: any,
  isItemSelected: any,
  labelId: string,
  disableSelection: boolean,
  getValues?: (row: any) => IHeadCells[]
) => {
  if (getValues) {
    const rowValues = getValues(row);
    return (
      <>
        <TableCell padding="checkbox">
          {!disableSelection && (
            <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
          )}
        </TableCell>
        {Array.isArray(rowValues[0].id) ? (
          <TableCell>
            <Avatar
              src={rowValues[0].id.length > 0 ? getThumbnail(rowValues[0].id[0]) : ""}
              component={"image"}
            />
          </TableCell>
        ) : (
          <TableCell component="th" id={labelId} scope="row" padding="normal">
            {rowValues[0].id}
          </TableCell>
        )}
        {rowValues.map((el, ind: number) => {
          if (ind !== 0) {
            let valueOfField = "";
            if (possibleDates.indexOf(el.label) !== -1) {
              valueOfField = moment(el.id).format("YYYY-MM-DD");
            } else {
              valueOfField = el.id;
            }
            return (
              <TableCell align="left" key={"" + el.id + " " + ind}>
                {valueOfField}
              </TableCell>
            );
          }
        })}
      </>
    );
  } else {
    return (
      <>
        <TableCell padding="checkbox">
          {!disableSelection && (
            <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
          )}
        </TableCell>
        {Array.isArray(row[headCells[0].id]) ? (
          <TableCell>
            <Avatar
              src={row[headCells[0].id].length > 0 ? getThumbnail(row[headCells[0].id][0]) : ""}
              component={"image"}
            />
          </TableCell>
        ) : (
          <TableCell component="th" id={labelId} scope="row" padding="normal">
            {headCells[0].id.indexOf(".") !== -1
              ? getField(row, headCells[0].id)
              : getField(row, headCells[0].id)}
          </TableCell>
        )}
        {headCells.map((el: any, ind: number) => {
          if (ind !== 0)
            return (
              <TableCell align="left" key={"" + el.id + " " + ind}>
                {getField(row, el.id)}
              </TableCell>
            );
        })}
      </>
    );
  }
};

const possibleDates = [
  "createdAt",
  "updatedAt",
  "createdate",
  "date",
  "Date",
  "enddate",
  "startdate",
];

const getField = (row: any, id: string) => {
  let result: any;
  if (id.indexOf(".") >= 0) {
    result = row;
    let fields = id.split(".");
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index] as keyof typeof result;
      if (result[element] === null) {
        result = "None";
        break;
      }
      //@ts-ignore
      result = result[element];
    }
  } else if (Array.isArray(row)) {
    //@ts-ignore
    return row[id][0];
  } else {
    result = row[id];
  }
  // is Date?
  if (possibleDates.indexOf(id) !== -1) {
    result = moment(result).format("YYYY-MM-DD");
  }
  return result || result === 0
    ? result.length >= 25
      ? result.slice(0, 25) + "..."
      : result
    : "---";
};

export interface IHeadCells {
  id: string;
  secondary?: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
}

export interface GeneralTableComponentProps {
  orderByDefault: string;
  headCells: IHeadCells[];
  pathEntity: string;
  disableEdit?: boolean;
  disableRemove?: boolean;
  data: any;
  tableSubTitle?: string | undefined;
  disableDetails?: boolean;
  disableSelection?: boolean;
  tableComponent?: string;
  getValues?: (row: any) => IHeadCells[];
  base64OrDataOnEdit?: "Base64" | "Data" | "ID";
  base64OrDataOnDetails?: "Base64" | "Data" | "ID";
  base64OrDataOrIdOnDelete?: "Base64" | "Data" | "ID";
  editPath?: string;
  detailsPath?: string;
  deletePath?: string;
  startRowsPerPage?: number;
  PaperAndTableWrapperProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  tableContainerProps?: TableContainerProps;
  tableProps?: TableProps;
  paperProps?: PaperProps;
}

export default function GeneralTableComponent({
  data: dataInput,
  orderByDefault,
  headCells,
  pathEntity,
  getValues,
  editPath = undefined,
  detailsPath = undefined,
  deletePath = undefined,
  disableEdit = false,
  disableRemove = false,
  disableDetails = false,
  disableSelection = false,
  tableSubTitle = undefined,
  tableComponent = "container",
  base64OrDataOnEdit = "Base64",
  base64OrDataOnDetails = "Base64",
  base64OrDataOrIdOnDelete = "Data",
  startRowsPerPage = 5,
  PaperAndTableWrapperProps,
  tableContainerProps,
  tableProps,
  paperProps,
}: GeneralTableComponentProps) {
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = React.useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = React.useState(orderByDefault);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(startRowsPerPage);

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = dataInput.map((n: any) => n.code);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, code: any, dat: any) => {
    //@ts-ignore
    const selectedIndex = selected.indexOf(code);
    let newSelected = [] as any;
    dataInput = dat;
    data = dat;
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, code);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //@ts-ignore
  const isSelected = (code: any) => selected.indexOf(code) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataInput.length - page * rowsPerPage);
  const handleDetails = (sub: any) => {
    let path = !!detailsPath ? detailsPath : `${pathEntity}/details`;
    if (base64OrDataOnDetails === "Base64") {
      history.push({ pathname: `${path}/` + sub.id });
    } else {
      history.push({
        pathname: `${path}/details`,
        data: sub,
      } as any);
    }
  };

  return (
    <div className={tableComponent} {...PaperAndTableWrapperProps}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          path={pathEntity}
          editPath={editPath}
          disableEdit={disableEdit}
          disableRemove={disableRemove}
          tableSubTitle={tableSubTitle}
          base64OrDataOnEdit={base64OrDataOnEdit}
          base64OrDataOrIdOnDelete={base64OrDataOrIdOnDelete}
          deletePath={deletePath}
        />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={dataInput.length}
              headCells={headCells}
              disableSelection={disableSelection}
            />
            <TableBody>
              {dataInput
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  const isItemSelected = isSelected(row.code);
                  // isSelect = isSelected(row.code)
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event: any) => {
                        if (!disableSelection) {
                          handleClick(event, row.code, row);
                        }
                      }}
                      onDoubleClick={() => {
                        if (!disableDetails) {
                          handleDetails(row);
                        }
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.code}
                      selected={isItemSelected}
                    >
                      {createTableCells(
                        headCells,
                        row,
                        isItemSelected,
                        labelId,
                        disableSelection,
                        getValues
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (true ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataInput.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
