import { useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, IconButton, LinearProgress } from "@material-ui/core";
import {
  CursorPaging,
  PackingTypeFilter,
  PackingTypes,
  PackingTypeSort,
  PackingTypeSortFields,
  PackingTypesVariables,
  PackingTypes_packingTypes_edges_node,
  SortDirection,
} from "../../../data/graphQLModel";
import { NetworkStatus, useQuery } from "@apollo/react-hooks";
import { QUERY_MANY_PACKAGE_TYPES } from "../../../data/graphQLQueries";
import NewTablePageHeaderComponent from "../../../components/Table/NewTablePageHeaderComponent";
import NewGeneralTableComponent from "../../../components/Table/NewGeneralTableComponent";
import { onChangePageCursor } from "../../../utils/utils";
import { getPackageTypesRowDefaultValue, packageTypesHeadCellsDefault } from "./utils";
import { Delete, Edit } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

const DEFAULT_LIMIT = 10;
const DEFAULT_SORTING: PackingTypeSort[] = [
  { direction: SortDirection.DESC, field: PackingTypeSortFields.createdAt },
];
const DEFAULT_PAGING: CursorPaging = { first: DEFAULT_LIMIT };

export default function PackageTypeList() {
  const history = useHistory();

  const [filter, setFilter] = useState<PackingTypeFilter>({});
  const [paging, setPaging] = useState<CursorPaging>(DEFAULT_PAGING);
  const [sorting, setSorting] = useState<PackingTypeSort[]>(DEFAULT_SORTING);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { loading, error, data, networkStatus } = useQuery<PackingTypes, PackingTypesVariables>(
    QUERY_MANY_PACKAGE_TYPES,
    {
      variables: {
        paging: paging,
        sorting: sorting,
        filter: filter,
      },
    }
  );

  return (
    <>
      <Helmet>
        <title>Package Types | WeScan</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ mt: 10 }}>
        <NewTablePageHeaderComponent
          entityNamePlural={"Package Types"}
          pathEntity={"/customerstables/packagetypes"}
          gridProps={{ sx: { mb: 2, mt: 2 } }}
          buttonTitle={"New Package Type"}
        />
        <NewGeneralTableComponent
          data={!!data?.packingTypes ? data?.packingTypes?.edges?.map((edge) => edge.node) : []}
          loading={loading || networkStatus === NetworkStatus.fetchMore}
          error={error}
          LoadingComponent={() => (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          onChangePage={(e: any, page: number) => {
            onChangePageCursor(
              e,
              page,
              pageNumber,
              !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
              data?.packingTypes?.pageInfo || ({} as any),
              (pageNumber: number, newPaging: CursorPaging) => {
                setPaging(newPaging);
                setPageNumber(pageNumber);
              }
            );
          }}
          onRowsPerPageChange={(event) => {
            setPaging({ first: parseInt(event.target.value, 10) });
          }}
          page={pageNumber}
          rowsPerPage={!!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT}
          headCells={packageTypesHeadCellsDefault}
          totalCount={data?.packingTypes?.totalCount || 0}
          handleSort={(field, order) => {
            setSorting([{ field: field as PackingTypeSortFields, direction: order }]);
          }}
          orderByField={sorting[0].field}
          orderDirection={sorting[0].direction}
          sortableFields={Object.values(PackingTypeSortFields)}
          key={"pcktyp"}
          getValues={getPackageTypesRowDefaultValue}
          disableSelectAll
          disableRemove
          disableEdit
          disableMultipleSelection
          PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
          tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pathEntity={"/customerstables/packagetypes"}
          deletePath={"/customerstables/packagetypes/delete"}
          detailsPath={"/customerstables/packagetypes/editpackagetype"}
          toolbarOptions={[
            {
              element: (
                <IconButton aria-label="edit">
                  <Edit />
                </IconButton>
              ),
              onClick: (selected) => {
                if (!!!(selected as PackingTypes_packingTypes_edges_node[])[0]?.company) {
                  toast.error("Can't Edit System Packaga Types");
                } else {
                  history.push({
                    pathname: `/customerstables/packagetypes/editpackagetype/${btoa(
                      selected[0].id
                    )}`,
                  });
                }
              },
              title: "",
            },
            {
              element: (
                <IconButton aria-label="delete">
                  <Delete />
                </IconButton>
              ),
              onClick: (selected) => {
                if (!!!(selected as PackingTypes_packingTypes_edges_node[])[0]?.company) {
                  toast.error("Can't Delete System Packaga Types");
                } else {
                  history.push({
                    pathname: `/customerstables/packagetypes/delete/${btoa(selected[0].id)}`,
                  });
                }
              },
              title: "",
            },
          ]}
        />
      </Container>
    </>
  );
}
