import {
  JobNumberingOrder,
  JobsForScannerConsole_jobs_edges_node,
  SubscribeToScannerOperation,
  SubscribeToScannerUpload,
} from "../../data/graphQLModel";

export const getSlabCodeWithJob = (
  job: JobsForScannerConsole_jobs_edges_node,
  shouldBeNext = false
): string => {
  let code: string;
  let slabNumbering =
    job.numberingOrder === JobNumberingOrder.ASC
      ? (job.scannedSlabs || 0) +
        +(job.startNumber || 0) +
        (shouldBeNext ? (job.numberingOrder === JobNumberingOrder.ASC ? 1 : -1) : 0)
      : (job.startNumber ? job.startNumber : job.totalslab) -
        (job.scannedSlabs + (shouldBeNext ? 1 : 0));

  // Get slab code
  if (!job.prefix) {
    if (job.block) {
      code = `${job.block.tenantCode}${job.separator ?? ""}${slabNumbering.toLocaleString("en-US", {
        minimumIntegerDigits: 3,
      })}`;
    } else {
      code = `${job.code}${job.separator ?? ""}${slabNumbering.toLocaleString("en-US", {
        minimumIntegerDigits: 3,
      })}`;
    }
  } else {
    code = `${job.prefix}${job.separator ?? ""}${slabNumbering.toLocaleString("en-US", {
      minimumIntegerDigits: 3,
    })}`;
  }
  return code;
};

/**
 * Checks if state is of type SubscribeToScannerUpload["subscribeToScannerSlabUploadState"]
 * @param state Scanner State
 * @returns If state is a SlabUploadState
 */
export function isSlabUploadState(
  state:
    | SubscribeToScannerUpload["subscribeToScannerSlabUploadState"]
    | SubscribeToScannerOperation["subscribeToScannerOperationState"]
    | undefined
): state is SubscribeToScannerUpload["subscribeToScannerSlabUploadState"] {
  return (
    (state as SubscribeToScannerUpload["subscribeToScannerSlabUploadState"])?.slab !== undefined ||
    (state as SubscribeToScannerUpload["subscribeToScannerSlabUploadState"])?.imagesArray !==
      undefined
  );
}

/**
 * Checks if state is of type SubscribeToScannerOperation["subscribeToScannerOperationState"]
 * @param state Scanner State
 * @returns If state is a SlabOperationState
 */
export function isSlabOperationState(
  state:
    | SubscribeToScannerUpload["subscribeToScannerSlabUploadState"]
    | SubscribeToScannerOperation["subscribeToScannerOperationState"]
): state is SubscribeToScannerUpload["subscribeToScannerSlabUploadState"] {
  return (
    (state as SubscribeToScannerUpload["subscribeToScannerSlabUploadState"])?.slab === undefined &&
    (state as SubscribeToScannerUpload["subscribeToScannerSlabUploadState"])?.imagesArray ===
      undefined
  );
}
