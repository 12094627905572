/* eslint-disable */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import swalert from "sweetalert";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { loadingComponent, notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { Box, Card, CardContent, Container } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Project,
  ProjectSimpleForEdit,
  ProjectSimpleForEditVariables,
  ProjectVariables,
  UpdateOneProject,
  UpdateOneProjectVariables,
} from "../../../data/graphQLModel";
import {
  MUTATION_UPDATE_ONE_PROJECT,
  QUERY_ONE_PROJECT_FOR_SIMPLE_EDIT,
} from "../../../data/graphQLQueries";
import { decodeBase64 } from "../../../utils/utils";
import { MuiThemeProps } from "../../../theme/theme";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  form: {
    width: "80%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function EditProject() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setForm] = useState({
    id: "",
    code: "",
    project: "",
    description: "",
    observation: "",
  });

  let { prj } = useParams() as { prj: string };

  const project = useQuery<ProjectSimpleForEdit, ProjectSimpleForEditVariables>(
    QUERY_ONE_PROJECT_FOR_SIMPLE_EDIT,
    {
      variables: {
        id: decodeBase64(prj),
      },
      fetchPolicy: "network-only",
      onCompleted(data) {
        if (!!data?.project) {
          setForm({
            code: data.project.code,
            description: data.project.description as string,
            id: data.project.id,
            observation: data.project.observation as string,
            project: data.project.project,
          });
        }
      },
    }
  );

  const [mutationUpdateOneProject] = useMutation<UpdateOneProject, UpdateOneProjectVariables>(
    MUTATION_UPDATE_ONE_PROJECT
  );

  function changeform(e: any) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  async function submitForm(e: any) {
    e.preventDefault();

    if (
      form.code === "" ||
      form.code.length < 2 ||
      form.description === "" ||
      form.description.length < 5
    ) {
      swalert("Information!", "Missing information!", "warning");
      return;
    }

    dispatch(loadingComponent());

    try {
      await mutationUpdateOneProject({
        variables: {
          input: {
            id: form.id,
            update: {
              observation: form.observation,
              description: form.description,
              project: form.project,
            },
          },
        },
      });
      swalert("Success.", "Project update with success", "success");
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert(
        "Failed.",
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Erro",
        "error"
      );
      return;
    }
    dispatch(notLoadingComponent());
    history.push("/customerstables/projects");
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/customerstables/projects");
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>Edit Project</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm} style={{ width: "100%" }}>
              {form.code && (
                <>
                  <div>
                    <FormControl fullWidth className={classes.marginCode} variant="outlined">
                      <TextField
                        id="CodeProject"
                        label="Code"
                        name="code"
                        value={form.code}
                        placeholder="Code"
                        disabled
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                      <TextField
                        id="projProject"
                        label="Project"
                        name="project"
                        value={form.project}
                        onChange={changeform}
                        placeholder="Project"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                      <TextField
                        id="descProject"
                        label="Description"
                        name="description"
                        focused
                        onChange={changeform}
                        value={form.description}
                        placeholder="Description"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                      <TextField
                        id="obsProject"
                        label="Observation"
                        name="observation"
                        placeholder="Observation"
                        onChange={changeform}
                        value={form.observation}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        multiline
                        rows={5}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                </>
              )}
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      disabled={
                        !!!form.code ||
                        form.code === "" ||
                        !!!form.description ||
                        form.description === "" ||
                        !!!form.project ||
                        form.project === ""
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Save
                      <Save style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
