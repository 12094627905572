import { useState } from "react";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  CursorPaging,
  StoneModelFilter,
  StoneModels,
  StoneModelSort,
  StoneModels_stoneModels_edges_node,
} from "../../data/graphQLModel";
import { QUERY_STONE_MODELS } from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { EntityAutocompleteProps } from "./types";

/**
 * ## Reusable Stone Model Autocomplete Component
 *
 * ### Default Autocomplete property values:
 *
 * #### name: stone_model
 * #### label: Models
 * #### continueToLoadResultsInComponent: true
 * #### shouldFieldBeLocked: false
 *
 */
const StoneModelAutocomplete = ({
  errors,
  continueToLoadResultsInComponent = true,
  shouldFieldBeLocked = false,
  helperText,
  value,
  onChange,
  ...props
}: EntityAutocompleteProps) => {
  const [stoneModels, setStoneModels] = useState<StoneModels_stoneModels_edges_node[]>([]);

  return (
    <AutocompleteWithQuery
      onChange={onChange}
      value={value}
      items={stoneModels}
      label="Models"
      name="stone_model"
      title="Models"
      TextFieldProps={{
        error: !!errors,
        helperText: helperText,
        label: "Models",
        disabled: shouldFieldBeLocked,
      }}
      onTextChange={async (value: string) => {
        await queryValuesForFilter<StoneModelFilter, CursorPaging, StoneModelSort, StoneModels>(
          value,
          QUERY_STONE_MODELS,
          (res) => {
            let endRes = res?.data?.stoneModels?.edges?.map((el) => el.node);
            setStoneModels(endRes);
          },
          {
            filter: {
              or: [{ code: { iLike: value } }, { name: { iLike: value } }],
            },
            paging: { first: 40 },
          }
        );
      }}
      getField={(node: StoneModels_stoneModels_edges_node) => {
        return `${node.code} - ${node.name}`;
      }}
      {...props}
      AutocompleteProps={{
        disabled: shouldFieldBeLocked,
        ...props?.AutocompleteProps,
      }}
    />
  );
};

export default StoneModelAutocomplete;
