import React, { useEffect, useState } from "react";
import { Box, Container, Divider, Typography, useTheme } from "@material-ui/core";
import useWindowSize from "../../hooks/useWindowSize";
import footerBanner from "../../globalImages/Wescan_Logo Home - White.png";

const timer = 500;

export default function Footer() {
  const [hasScrollBar, setHasScrollBar] = useState();
  const windowSizes = useWindowSize();
  const theme = useTheme();

  useEffect(() => {
    const interval = setInterval(() => {
      if (document.body.scrollHeight > window.innerHeight) {
        setHasScrollBar(true);
      } else {
        setHasScrollBar(false);
      }
    }, timer);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [windowSizes]);

  return (
    <footer
      style={
        hasScrollBar === true
          ? { padding: 10, backgroundColor: "#0c0e30" }
          : { position: "fixed", bottom: 0, width: "100%", padding: 10, backgroundColor: "#0c0e30" }
      }
    >
      <Box>
        <Container sx={{ p: "1rem", color: "white" }}>
          <Box style={{ textAlign: "center", overflow: "hidden" }}>
            <Box color="grey.500" mb={1}>
              <img style={{ alignSelf: "center" }} src={footerBanner} height={"40px"} />
            </Box>
            <Divider color="white" />
            <Box mt={1} style={{ textAlign: "center" }}>
              <Typography color={theme.palette.background} component="div" fontSize="15px">
                Powered by{" "}
                <Typography
                  display="inline"
                  fontWeight="700"
                  fontSize="inherit"
                  color={theme.palette.background}
                >
                  Stonify
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </footer>
  );
}
