/*eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Chip,
  Hidden,
  useTheme,
  Fab,
  MenuItem,
  Select,
} from "@material-ui/core/";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import {
  SlabFilter,
  Slabs,
  SlabSortFields,
  SlabsVariables,
  SortDirection,
  BlockModelsToFilter_blockModels_edges_node,
  BooleanFieldComparison,
  Categories_categories_edges_node,
  CommercialFinishings_commercialFinishings_edges_node,
  CommercialVarieties_commercialVarieties_edges_node,
  Finishings_finishings_edges_node,
  Jobs_jobs_edges_node,
  MaterialTypes_materialTypes_edges_node,
  Packings_packings_edges_node,
  Projects_projects_edges_node,
  Scanners_scanners_edges_node,
  StoneEdges_stoneEdges_edges_node,
  StoneModels_stoneModels_edges_node,
  Varieties_varieties_edges_node,
} from "../../data/graphQLModel";
import { MuiThemeProps } from "../../theme/theme";
import { QUERY_SLABS } from "../../data/graphQLQueries";
import { apolloClient } from "../../services_v2/apollo.graphql";
import {
  copyObject,
  onEndDateChangeGraphQLFilters,
  onStartDateChangeGraphQLFilters,
} from "../../utils/utils";
import { ClearAll, RemoveCircle, Sync, Tune } from "@material-ui/icons";
import { ApolloQueryResult, FetchPolicy } from "apollo-boost";
import { H3 } from "../GeneralComponents/Typography";
import { layoutConstant } from "../../utils/constants";
import PaginationContainerStructure from "../../pages/Catalog/PaginationContainerStructure";
import BazarCard from "../GeneralComponents/BazarCard";
import SlabFiltersComponent, { PossibleTimeFields } from "../../pages/Catalog/SlabFiltersComponent";
import FlexBox from "../GeneralComponents/FlexBox";
import SlabGalleryCard from "./SlabGalleryCard";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(10),
    },
  },
  boxTop: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(10),
  },
  margin: {
    width: "40ch",
  },
}));

interface SlabGalleryProps {
  TopElement: () => JSX.Element;
  isSideOpen: boolean;
  toggleSide: () => void;
}

export default function SlabGallery({ TopElement, isSideOpen, toggleSide }: SlabGalleryProps) {
  const theme = useTheme();
  const classes = useStyles();
  const [isLoadingSlabs, setIsLoadingSlabs] = useState<boolean>(false);
  const [timeField, setTimeField] = useState<PossibleTimeFields>("createdAt");

  const defaultDateFilters = {};

  const account = useSelector<any>((state) => state.account.filter_stock) as
    | boolean
    | undefined
    | null;

  // Filter values
  const [textSearch, setTextSearch] = useState<string | undefined>();
  const [jobs, setJobs] = useState<Jobs_jobs_edges_node[]>([]);
  const [scans, setScans] = useState<Scanners_scanners_edges_node[]>([]);
  const [varieties, setVarieties] = useState<Varieties_varieties_edges_node[]>([]);
  const [finishings, setFinishings] = useState<Finishings_finishings_edges_node[]>([]);
  const [commercialVarieties, setCommercialVarieties] = useState<
    CommercialVarieties_commercialVarieties_edges_node[]
  >([]);
  const [materialTypes, setMaterialTypes] = useState<MaterialTypes_materialTypes_edges_node[]>([]);
  const [commercialFinishings, setCommercialFinishings] = useState<
    CommercialFinishings_commercialFinishings_edges_node[]
  >([]);
  const [categories, setCategories] = useState<Categories_categories_edges_node[]>([]);
  const [stoneModels, setStoneModels] = useState<StoneModels_stoneModels_edges_node[]>([]);
  const [stoneEdges, setStoneEdges] = useState<StoneEdges_stoneEdges_edges_node[]>([]);
  const [blocks, setBlocks] = useState<BlockModelsToFilter_blockModels_edges_node[]>([]);
  const [packages, setPackages] = useState<Packings_packings_edges_node[]>([]);
  const [projects, setProjects] = useState<Projects_projects_edges_node[]>([]);

  // global filters
  const [globalFilters, setGlobalFilters] = useState<SlabFilter>({
    ...defaultDateFilters,
  });
  // filters value of getField shown in UI
  const [filtersUIValue, setFiltersUIValue] = useState<{
    [key in keyof Partial<SlabFilter>]: string;
  }>({});

  // GOTO PAGE
  const [goToPageNumber, setGoToPageNumber] = useState(1);

  //Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [slabsPerPage, setSlabsPerPage] = useState<number>(12);

  const [slabsV2, setSlabsV2] = useState<ApolloQueryResult<Slabs>>();

  useEffect(() => {
    if (account) {
      setGlobalFilters({ ...globalFilters, inStock: { is: account } });
    } else {
      const newGlobalFilters = copyObject<SlabFilter>(globalFilters, ["inStock"]);
      setGlobalFilters(newGlobalFilters);
    }
  }, [account]);

  useEffect(() => {
    // only run if timeField exists, date filters exist and timeField has trully changed
    if (!!timeField && globalFilters?.and && timeField !== Object.keys(globalFilters?.and[0])[0]) {
      // get previous time field
      let previousTimeField: PossibleTimeFields = Object.keys(
        globalFilters?.and[0]
      )[0] as PossibleTimeFields;
      // build new and with previous values but new key (timeField)
      let newAnd = [
        { [timeField]: globalFilters.and[0][previousTimeField] },
        { [timeField]: globalFilters.and[1][previousTimeField] },
      ];
      // build new filters, removing previous "and" and adding the previously built one
      let newFilters = { ...copyObject(globalFilters, ["and"]), and: newAnd };
      // set value
      setGlobalFilters(newFilters);
    }
  }, [timeField]);

  const getSlabsV2 = async (
    globalFilters: SlabFilter,
    slabsPerPage: number,
    pageNumber: number,
    fetchPolicy?: FetchPolicy
  ) => {
    setIsLoadingSlabs(true);
    const result = await apolloClient.query<Slabs, SlabsVariables>({
      query: QUERY_SLABS,
      variables: {
        filter: globalFilters,
        sorting: [
          {
            direction: SortDirection.DESC,
            field: SlabSortFields[timeField],
          },
        ],
        paging: {
          limit: slabsPerPage,
          offset: slabsPerPage * pageNumber,
        },
      },
      fetchPolicy,
    });
    setIsLoadingSlabs(false);
    //@ts-ignore
    setSlabsV2(result);
  };

  // changes on filters or number of slabs per page
  useEffect(() => {
    if (pageNumber >= 0) {
      getSlabsV2(globalFilters, slabsPerPage, pageNumber);
    } else {
      setPageNumber(0);
    }
  }, [pageNumber, slabsPerPage]);

  useEffect(() => {
    getSlabsV2(globalFilters, slabsPerPage, 0);
  }, [globalFilters]);

  const changePage = async (event: any, value: number) => {
    if (value - 1 === pageNumber) return;

    setPageNumber(value - 1);
  };

  const handleStartDateChange = (date: any) => {
    onStartDateChangeGraphQLFilters(globalFilters, date, (newAnd) => {
      setGlobalFilters({
        ...globalFilters,
        and: newAnd,
      });
    });
  };

  const handleEndDateChange = (date: any) => {
    onEndDateChangeGraphQLFilters(globalFilters, date, (newAnd) => {
      setGlobalFilters({
        ...globalFilters,
        and: newAnd,
      });
    });
  };

  const handleAutoCompleteChange = (e: any) => {
    let key = e.target.name as keyof SlabFilter;
    let value = e.target.value as string;
    let uiValue = e.target.fieldUiValue as string;
    if (e.target.value) {
      setGlobalFilters({ ...globalFilters, [key]: { eq: value } });
      setFiltersUIValue({ ...filtersUIValue, [key]: uiValue });
    } else {
      const newGlobalFilters = copyObject(globalFilters, [key]);
      setGlobalFilters(newGlobalFilters);
      const newFiltersUIValue = copyObject(filtersUIValue, [key]);
      setFiltersUIValue(newFiltersUIValue);
    }
  };

  const handleClearFilter = async () => {
    setPageNumber(0);
    setGlobalFilters(defaultDateFilters);
  };

  const handleSearch = (value: string | undefined) => {
    if (!!value && value.trim() !== "") {
      setGlobalFilters({
        ...globalFilters,
        code: {
          iLike: value,
        },
      });
    }
  };

  const GenerateFilterChips = (): JSX.Element => {
    let keysToIgnore: (keyof SlabFilter)[] = ["and"];
    let keysToOnlyShowIfTrue: (keyof SlabFilter)[] = ["cancelCataloguing", "iscatalogued"];
    let activeKeys = Object.keys(globalFilters) as (keyof SlabFilter)[];

    const dataForChips: { key: keyof SlabFilter; label: string }[] = [];

    for (let index = 0; index < activeKeys.length; index++) {
      const key = activeKeys[index];
      if (keysToIgnore.indexOf(key) !== -1) {
        // do nothing
      } else if (keysToOnlyShowIfTrue.indexOf(key) !== -1) {
        let filter = globalFilters[key] as BooleanFieldComparison;
        if (filter.isNot) {
          // do nothing
        } else {
          dataForChips.push({
            label: key,
            key: key,
          });
        }
      } else {
        dataForChips.push({
          label: filtersUIValue[key] as string,
          key: key,
        });
      }
    }

    return (
      <>
        <Hidden only={["sm", "xs"]}>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 5,
              maxHeight: layoutConstant.headerHeight,
              marginLeft: 2,
            }}
            spacing={1}
          >
            <Grid item>
              <H3 sx={{ fontWeight: "bold" }}>Filters: </H3>
            </Grid>
            {dataForChips.map((el) => (
              <Grid item>
                <Chip
                  label={el?.label}
                  onDelete={() => {
                    let newFilters = copyObject(globalFilters, []);
                    delete newFilters[el?.key];
                    setGlobalFilters(newFilters);
                    let newFiltersUIValue = copyObject(filtersUIValue, []);
                    delete newFiltersUIValue[el?.key];
                    setFiltersUIValue(newFiltersUIValue);
                  }}
                  deleteIcon={<RemoveCircle />}
                />
              </Grid>
            ))}
            {(!!!dataForChips || dataForChips.length === 0) && (
              <Grid item>
                <H3 sx={{ fontWeight: "300" }}>Empty</H3>
              </Grid>
            )}
          </Grid>
        </Hidden>
        <Hidden only={["lg", "md", "xl"]}>
          <Grid container></Grid>
        </Hidden>
      </>
    );
  };

  const FilterComponent = () => {
    return (
      <SlabFiltersComponent
        {...{
          blocks,
          categories,
          commercialFinishings,
          commercialVarieties,
          finishings,
          globalFilters,
          jobs,
          materialTypes,
          packages,
          projects,
          scans,
          setBlocks,
          setCategories,
          setCommercialFinishings,
          setCommercialVarieties,
          setFinishings,
          setGlobalFilters,
          setJobs,
          setMaterialTypes,
          setPackages,
          setProjects,
          setScans,
          setStoneEdges,
          setStoneModels,
          setTextSearch,
          setVarieties,
          stoneEdges,
          stoneModels,
          textSearch,
          varieties,
          handleAutoCompleteChange,
          handleSearch,
          timeField,
          handleStartDateChange,
          handleEndDateChange,
        }}
      />
    );
  };

  //#endregion Filters
  return (
    <>
      <PaginationContainerStructure
        isOpen={isSideOpen}
        toggle={toggleSide}
        top={
          <BazarCard
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FlexBox sx={{ flexDirection: "column" }}>
              <TopElement />
              {GenerateFilterChips()}
            </FlexBox>
            <FlexBox>
              <Button
                value="check"
                color="inherit"
                contextMenu={"Sync Results"}
                onClick={() => {
                  getSlabsV2(globalFilters, slabsPerPage, pageNumber, "network-only");
                }}
                sx={{ mr: 2, borderWidth: "0px", "&:focus": { border: "none" } }}
              >
                <Typography>Sync</Typography>
                <Sync fontSize="large" color="action" titleAccess="Sync Slabs" />
              </Button>
              <Button
                value="check"
                color="inherit"
                contextMenu={"Open filter"}
                onClick={toggleSide}
                sx={{ mr: 2, borderWidth: "0px", "&:focus": { border: "none" } }}
              >
                {!!isSideOpen ? (
                  <Typography>Close Filter</Typography>
                ) : (
                  <Typography>
                    Filter
                    {!!filtersUIValue ? `  (${Object.keys(filtersUIValue).length})` : ""}
                  </Typography>
                )}
                <Tune fontSize="large" color="action" titleAccess="Open Filter" />
              </Button>
            </FlexBox>
          </BazarCard>
        }
        side={
          <>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                onClick={handleClearFilter}
                fullWidth
                startIcon={<ClearAll fontSize="large" />}
              >
                Clear Filters {!!filtersUIValue ? `  (${Object.keys(filtersUIValue).length})` : ""}
              </Button>
            </Box>
            {FilterComponent()}
          </>
        }
        sideMobile={<>{FilterComponent()}</>}
        sideMobileActions={
          <Box bgcolor={theme.palette.common.white} width={"100%"} p={2}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={6}>
                <Fab
                  sx={{
                    p: 2,
                    zIndex: 30,
                    width: "100%",
                    borderRadius: 5,
                  }}
                  color="primary"
                  onClick={handleClearFilter}
                  disabled={!Object.keys(filtersUIValue)?.length}
                >
                  Clear {!!filtersUIValue ? `  (${Object.keys(filtersUIValue).length})` : ""}
                </Fab>
              </Grid>
              <Grid item sm={6} xs={6}>
                <Fab
                  sx={{
                    p: 2,
                    zIndex: 30,
                    width: "100%",
                    borderRadius: 5,
                  }}
                  onClick={toggleSide}
                  color="secondary"
                >
                  Apply
                </Fab>
              </Grid>
            </Grid>
          </Box>
        }
        resultsLoading={isLoadingSlabs || !!slabsV2?.loading}
        childrenPagination={
          <Box
            className={classes.root}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {!!slabsV2?.data?.slabs?.totalCount &&
              Math.round(slabsV2?.data?.slabs?.totalCount / slabsPerPage) > 1 && (
                <FlexBox sx={{ alignItems: "center" }}>
                  <FlexBox mr={2}>
                    <Typography sx={{ mr: 1, mt: 0.8 }}>Results per page:</Typography>
                    <Select
                      labelId="results-per-page-select-label"
                      id="results-per-page-select"
                      value={slabsPerPage}
                      onChange={(value) => {
                        setSlabsPerPage(Number(value.target.value));
                        setPageNumber(0);
                      }}
                      variant="standard"
                    >
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={18}>18</MenuItem>
                    </Select>
                  </FlexBox>
                  <Pagination
                    count={Math.round(slabsV2?.data?.slabs?.totalCount / slabsPerPage)}
                    page={pageNumber >= 0 ? pageNumber + 1 : pageNumber + 2}
                    onChange={changePage}
                    variant="outlined"
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                </FlexBox>
              )}
            {!!slabsV2?.data?.slabs.totalCount &&
              Math.round(slabsV2?.data?.slabs?.totalCount / slabsPerPage) > 1 && (
                <Box style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <Button
                    className="btncatalog"
                    style={{
                      fontSize: "15px",
                      width: "100px",
                      marginRight: "10px",
                    }}
                    variant="contained"
                    onClick={() => changePage(null, goToPageNumber)}
                  >
                    Go To
                  </Button>
                  <TextField
                    variant="standard"
                    value={goToPageNumber}
                    type={"number"}
                    inputProps={{
                      min: 1,
                      max: slabsV2?.data?.slabs.totalCount,
                    }}
                    onChange={(e: any) => {
                      setGoToPageNumber(e.target.value);
                    }}
                  />
                </Box>
              )}
          </Box>
        }
      >
        <>
          {slabsV2 &&
          !slabsV2?.loading &&
          !slabsV2?.errors &&
          slabsV2?.data &&
          slabsV2?.data.slabs.nodes.length > 0 ? (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={isSideOpen ? { sm: 1, md: 3 } : { sm: 1, md: 3 }}
              columns={
                isSideOpen
                  ? { xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }
                  : { xs: 1, sm: 2, md: 4, lg: 5, xl: 6 }
              }
              alignItems="center"
              alignSelf="center"
            >
              {slabsV2?.data &&
                slabsV2?.data?.slabs &&
                slabsV2?.data?.slabs?.nodes.map((slb, index) => {
                  return (
                    <Grid item lg={1} md={1} sm={1} key={index} xs={1}>
                      <SlabGalleryCard key={slb.id} slab={slb} />
                    </Grid>
                  );
                })}
            </Grid>
          ) : (
            slabsV2 &&
            !slabsV2?.loading && (
              <Box sx={{ alignItems: "center" }}>
                <h3
                  style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  No Results
                </h3>
              </Box>
            )
          )}
        </>
      </PaginationContainerStructure>
    </>
  );
}
