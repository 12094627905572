import { useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, IconButton, LinearProgress } from "@material-ui/core";
import {
  CursorPaging,
  PackingFilter,
  PackingsGallery,
  PackingsGalleryVariables,
  PackingSort,
  PackingSortFields,
  SortDirection,
} from "../../../data/graphQLModel";
import { NetworkStatus, useQuery } from "@apollo/react-hooks";
import { QUERY_MANY_PACKINGS_GALLERY } from "../../../data/graphQLQueries";
import NewTablePageHeaderComponent from "../../../components/Table/NewTablePageHeaderComponent";
import NewGeneralTableComponent from "../../../components/Table/NewGeneralTableComponent";
import { onChangePageCursor } from "../../../utils/utils";
import {
  getPackagesRowDefaultValue,
  getPackagesRowWithAction,
  packingHeadCellsDefault,
  packingHeadCellsWithAction,
} from "./utils";
import { ArrowForward } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const DEFAULT_LIMIT = 10;
const DEFAULT_SORTING: PackingSort[] = [
  { direction: SortDirection.DESC, field: PackingSortFields.createdAt },
];
const DEFAULT_PAGING: CursorPaging = { first: DEFAULT_LIMIT };

export default function PackagesList() {
  const history = useHistory();

  const [filter, setFilter] = useState<PackingFilter>({});
  const [paging, setPaging] = useState<CursorPaging>(DEFAULT_PAGING);
  const [sorting, setSorting] = useState<PackingSort[]>(DEFAULT_SORTING);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { loading, error, data, networkStatus } = useQuery<
    PackingsGallery,
    PackingsGalleryVariables
  >(QUERY_MANY_PACKINGS_GALLERY, {
    variables: {
      paging: paging,
      sorting: sorting,
      filter: filter,
    },
  });

  return (
    <>
      <Helmet>
        <title>Packages | WeScan</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ mt: 10 }}>
        <NewTablePageHeaderComponent
          entityNamePlural={"Packages"}
          pathEntity={"/customerstables/packages"}
          gridProps={{ sx: { mb: 2, mt: 2 } }}
          buttonTitle={"New Package"}
        />
        <NewGeneralTableComponent
          data={!!data?.packings ? data?.packings?.edges?.map((edge) => edge.node) : []}
          loading={loading || networkStatus === NetworkStatus.fetchMore}
          error={error}
          LoadingComponent={() => (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          onChangePage={(e: any, page: number) => {
            onChangePageCursor(
              e,
              page,
              pageNumber,
              !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
              data?.packings?.pageInfo || ({} as any),
              (pageNumber: number, newPaging: CursorPaging) => {
                setPaging(newPaging);
                setPageNumber(pageNumber);
              }
            );
          }}
          onRowsPerPageChange={(event) => {
            setPaging({ first: parseInt(event.target.value, 10) });
          }}
          page={pageNumber}
          rowsPerPage={!!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT}
          headCells={packingHeadCellsWithAction}
          totalCount={data?.packings?.totalCount || 0}
          handleSort={(field, order) => {
            setSorting([{ field: field as PackingSortFields, direction: order }]);
          }}
          orderByField={sorting[0].field}
          orderDirection={sorting[0].direction}
          sortableFields={Object.values(PackingSortFields)}
          key={"pck"}
          getValues={getPackagesRowWithAction(({ selected }) => (
            <IconButton
              onClick={() => {
                history.push({
                  pathname: `/customerstables/packages/detailspackage/` + btoa(selected.id),
                });
              }}
            >
              <ArrowForward />
            </IconButton>
          ))}
          disableSelectAll
          PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
          tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          editPath={"/customerstables/packages/editpackage"}
          pathEntity={"/customerstables/packages"}
          deletePath={"/customerstables/packages/delete"}
          detailsPath={"/customerstables/packages/detailspackage"}
          base64OrDataOrIdOnDelete={"Data"}
        />
      </Container>
    </>
  );
}
