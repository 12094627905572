import { useEffect, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  useTheme,
} from "@material-ui/core";
import { NetworkStatus } from "apollo-boost";
import {
  OffsetPaging,
  SlabFilter,
  Slabs,
  SlabSort,
  SlabSortFields,
  SlabsVariables,
  Slabs_slabs_nodes,
  SortDirection,
} from "../../data/graphQLModel";
import {
  slabHeadCellsDefault,
  getSlabRowValuesDefault,
  getSlabRowValuesWithAction,
  slabRowValuesWithActionHeadCells,
} from "../../pages/Slab/utils";
import { WithLimitOffsetPaging } from "../../utils/Types";
import {
  getSlabImageSafe,
  getSlabMaterial,
  getSlabVariety,
  onChangePageOffset,
} from "../../utils/utils";
import NewGeneralTableComponent, {
  NewGeneralTableComponentProps,
} from "../Table/NewGeneralTableComponent";
import NewTablePageHeaderComponent from "../Table/NewTablePageHeaderComponent";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_SLABS } from "../../data/graphQLQueries";
import Lightbox, { Slide } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import ThumbnailsPlugin from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { H2 } from "../GeneralComponents/Typography";
import { ArrowRightOutlined, CloudDownload, Visibility } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import downloadImage from "../../utils/downloadImage";
import FlexBox from "../GeneralComponents/FlexBox";

interface SlabsTableProps {
  filter: SlabFilter;
  parentProps?: BoxProps;
  tableProps?: Partial<NewGeneralTableComponentProps>;
  showHeader?: boolean;
  requestAll?: boolean;
  showEye?: boolean;
}

const SlabsTable = ({
  filter,
  parentProps,
  tableProps,
  showHeader = true,
  requestAll = false,
  showEye = false,
}: SlabsTableProps) => {
  const theme = useTheme();
  const history = useHistory();
  const [paging, setPaging] = useState<WithLimitOffsetPaging>({ limit: requestAll ? 200 : 10 });
  const [sorting, setSorting] = useState<SlabSort[]>([
    { direction: SortDirection.DESC, field: SlabSortFields.createdAt },
  ]);
  const { loading, error, data, networkStatus, refetch, variables } = useQuery<
    Slabs,
    SlabsVariables
  >(QUERY_SLABS, {
    variables: {
      filter,
      paging,
      sorting,
    },
  });

  useEffect(() => {
    if (!!data && !!filter) {
      refetch(variables);
    }
  }, [filter]);

  // light box states
  const [selectedSlab, setSelectedSlab] = useState<Slabs_slabs_nodes>();

  const buildSlides = (slabs: Slabs_slabs_nodes[]): Slide[] => {
    return slabs.map((el) => ({
      src: getSlabImageSafe(el, false) || "images/imagemissing.png",
      title: el.code,
      alt: el.code,
    }));
  };

  return (
    <>
      <Box {...parentProps}>
        {showHeader && (
          <NewTablePageHeaderComponent
            hasButton={false}
            entityNamePlural={"Slabs"}
            gridProps={{ sx: { mb: 2, mt: 2 } }}
          />
        )}
        <NewGeneralTableComponent
          data={!!data?.slabs ? data?.slabs?.nodes : []}
          error={error}
          LoadingComponent={() => (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          onChangePage={(e: any, page: number) => {
            onChangePageOffset(
              e,
              page,
              paging.offset ? paging.offset / paging.limit : 0,
              paging.limit,
              data?.slabs?.pageInfo || ({} as any),
              (pageNumber: number, newPaging: OffsetPaging) => {
                setPaging(newPaging as WithLimitOffsetPaging);
              }
            );
          }}
          onRowsPerPageChange={(event) => {
            setPaging({ ...paging, limit: parseInt(event.target.value, 10) });
          }}
          page={paging.offset ? paging.offset / paging.limit : 0}
          rowsPerPage={paging.limit}
          rowsPerPageOptions={requestAll ? [data?.slabs?.totalCount || 0] : undefined}
          headCells={showEye ? slabRowValuesWithActionHeadCells : slabHeadCellsDefault}
          totalCount={data?.slabs?.totalCount || 0}
          handleSort={(field, order) => {
            setSorting([{ field: field as SlabSortFields, direction: order }]);
          }}
          orderByField={sorting[0].field}
          orderDirection={sorting[0].direction}
          sortableFields={Object.values(SlabSortFields)}
          pathEntity={"/slabmanagement"}
          editPath={"/slabmanagement/details"}
          detailsPath={"/slabmanagement/details"}
          key={"Slabs"}
          getValues={
            showEye
              ? getSlabRowValuesWithAction(({ selected }) => (
                  <IconButton
                    onClick={() => {
                      setSelectedSlab(selected);
                    }}
                  >
                    <Visibility />
                  </IconButton>
                ))
              : getSlabRowValuesDefault
          }
          base64OrDataOnEdit={"Base64"}
          base64OrDataOnDetails={"Base64"}
          base64OrDataOrIdOnDelete={"Base64"}
          deletePath={"/slabmanagement/delete"}
          disableRemove={true}
          disableEdit={true}
          disableSelection={false}
          disableSelectAll={true}
          disableMultipleSelection={false}
          PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
          disableDetails
          onClickRow={
            showEye
              ? undefined
              : (e, id, data) => {
                  setSelectedSlab(data as Slabs_slabs_nodes);
                }
          }
          ignoreEmptyRows
          {...tableProps}
          loading={loading || networkStatus === NetworkStatus.fetchMore || !!tableProps?.loading}
        />
      </Box>
      {!!data?.slabs?.totalCount && data?.slabs?.totalCount > 0 && (
        <Lightbox
          open={!!selectedSlab}
          styles={{
            container: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            },
            thumbnailsContainer: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            },
          }}
          on={{
            click(index) {
              setSelectedSlab(data.slabs.nodes[index]);
            },
            view(index) {
              setSelectedSlab(data.slabs.nodes[index]);
            },
          }}
          render={{
            slideFooter(slide) {
              // alt is slab's code
              let slab = data.slabs.nodes.find((el) => el.code === slide.alt) as Slabs_slabs_nodes;
              return (
                <Box
                  style={{
                    position: "fixed",
                    bottom: 0,
                    display: "flex",
                    alignItems: "flex-start",
                    width: window.innerWidth * 0.6,
                  }}
                >
                  <Grid container spacing={5}>
                    <Grid item>
                      <H2 color={theme.palette.common.white}>{getSlabVariety(slab)}</H2>
                    </Grid>
                    <Grid item>
                      <H2 color={theme.palette.common.white}>{getSlabMaterial(slab)}</H2>
                    </Grid>
                    <Grid item>
                      <H2 color={theme.palette.common.white}>{`${slab.width ? slab.width : 0}x${
                        slab.height ? slab.height : 0
                      }x${slab.thickness ? slab.thickness : 0}`}</H2>
                    </Grid>
                    <Grid item>
                      <H2 color={theme.palette.common.white}>{slab.area || 0 + "m" + "\u00b2"}</H2>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{
                          alignSelf: "center",
                          mt: 0.5,
                          color: theme.palette.common.white,
                          borderColor: theme.palette.common.white,
                          borderRadius: 10,
                          ":hover": {
                            backgroundColor: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                          },
                        }}
                        onClick={() => {
                          downloadImage(
                            slide.src,
                            slide.src.split(".").pop()?.trim() as string,
                            slab.code,
                            "Original"
                          );
                        }}
                        startIcon={<CloudDownload />}
                      >
                        <Typography color={"inherit"}>Download Image</Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{
                          alignSelf: "center",
                          alignItems: "center",
                          mt: 0.5,
                          color: theme.palette.common.white,
                          borderColor: theme.palette.common.white,
                          borderRadius: 10,
                          ":hover": {
                            backgroundColor: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                          },
                        }}
                        onClick={() => {
                          history.push({
                            pathname: `/slabmanagement/details/${btoa(slab.id as string)}`,
                          });
                        }}
                        startIcon={<ArrowRightOutlined />}
                      >
                        <Typography color={"inherit"}>Details</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              );
            },
            thumbnail({ slide, rect, imageFit }) {
              let slab = data.slabs.nodes.find((el) => el.code === slide.alt) as Slabs_slabs_nodes;
              let isSelected = !!selectedSlab && selectedSlab.code === slab.code;
              return (
                <FlexBox
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: isSelected ? "#292929" : "none",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={
                      getSlabImageSafe(slab as Slabs_slabs_nodes, false) ||
                      "images/imagemissing.png"
                    }
                    style={{
                      width: rect.width * 0.95,
                      height: rect.height * 0.95,
                      objectFit: imageFit,
                    }}
                  />
                </FlexBox>
              );
            },
          }}
          close={() => setSelectedSlab(undefined)}
          slides={buildSlides(data.slabs.nodes)}
          plugins={[
            Zoom,
            (props) => {
              return ThumbnailsPlugin(props);
            },
            Fullscreen,
            Captions,
          ]}
          carousel={{ finite: true, preload: data?.slabs?.totalCount }}
          zoom={{ scrollToZoom: true }}
          index={data.slabs.nodes.findIndex((el) => el.id === selectedSlab?.id) || 0}
          animation={{
            fade: 0.5,
            zoom: 0.5,
          }}
        />
      )}
    </>
  );
};

export default SlabsTable;
