import React, { useEffect, useState } from "react";
//@ts-ignore
import { Helmet } from "react-helmet";
import { Box, Container, LinearProgress } from "@material-ui/core";
import TablePageHeaderComponent from "../../../components/Table/TablePageHeaderComponent";
import GeneralTableComponent, { IHeadCells } from "../../../components/Table/GeneralTableComponent";
import { NetworkStatus, useQuery } from "@apollo/react-hooks";
import {
  CursorPaging,
  FinishingFilter,
  Finishings,
  FinishingSort,
  FinishingSortFields,
  FinishingsVariables,
  Finishings_finishings_edges_node,
  SortDirection,
} from "../../../data/graphQLModel";
import { QUERY_FINISHINGS } from "../../../data/graphQLQueries";
import toast from "react-hot-toast";
import NewTablePageHeaderComponent from "../../../components/Table/NewTablePageHeaderComponent";
import NewGeneralTableComponent from "../../../components/Table/NewGeneralTableComponent";
import { onChangePageCursor } from "../../../utils/utils";
const LOADING_TOAST = "LOADING_TOAST";

const headCells = [
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "description", numeric: false, disablePadding: false, label: "Description" },
  { id: "observation", numeric: false, disablePadding: false, label: "Observation" },
  { id: "date", numeric: false, disablePadding: false, label: "Date" },
];

const getRowValues = (row: Finishings_finishings_edges_node): IHeadCells[] => {
  return [
    {
      id: row.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    { id: row.description.pt, numeric: false, disablePadding: false, label: "Description" },
    {
      id: row?.observation?.pt ? row?.observation?.pt : "No observation",
      numeric: false,
      disablePadding: false,
      label: "Observation",
    },
    { id: String(row.date), numeric: false, disablePadding: false, label: "Date" },
  ];
};

const DEFAULT_LIMIT = 10;
const DEFAULT_SORTING: FinishingSort[] = [
  { direction: SortDirection.DESC, field: FinishingSortFields.createdAt },
];
const DEFAULT_PAGING: CursorPaging = { first: DEFAULT_LIMIT };

export default function FinishingsList() {
  const [filter, setFilter] = useState<FinishingFilter>({});
  const [paging, setPaging] = useState<CursorPaging>(DEFAULT_PAGING);
  const [sorting, setSorting] = useState<FinishingSort[]>(DEFAULT_SORTING);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { loading, error, data, networkStatus, previousData } = useQuery<
    Finishings,
    FinishingsVariables
  >(QUERY_FINISHINGS, {
    variables: {
      paging,
      sorting,
      filter,
    },
  });

  return (
    <>
      <Helmet>
        <title>Finishings | WeScan</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ mt: 10 }}>
        <NewTablePageHeaderComponent
          hasButton={false}
          entityNamePlural={"System Finishings"}
          gridProps={{ sx: { mb: 2, mt: 2 } }}
        />
        <NewGeneralTableComponent
          data={!!data?.finishings ? data?.finishings?.edges?.map((edge) => edge.node) : []}
          loading={loading || networkStatus === NetworkStatus.fetchMore}
          error={error}
          LoadingComponent={() => (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          onChangePage={(e: any, page: number) => {
            onChangePageCursor(
              e,
              page,
              pageNumber,
              !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
              data?.finishings?.pageInfo || ({} as any),
              (pageNumber: number, newPaging: CursorPaging) => {
                setPaging(newPaging);
                setPageNumber(pageNumber);
              }
            );
          }}
          onRowsPerPageChange={(event) => {
            setPaging({ first: parseInt(event.target.value, 10) });
          }}
          page={pageNumber}
          rowsPerPage={!!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT}
          headCells={headCells}
          totalCount={data?.finishings?.totalCount || previousData?.finishings?.totalCount || 0}
          handleSort={(field, order) => {
            setSorting([{ field: field as FinishingSortFields, direction: order }]);
          }}
          orderByField={sorting[0].field}
          orderDirection={sorting[0].direction}
          sortableFields={Object.values(FinishingSortFields)}
          key={"finish"}
          getValues={getRowValues}
          disableRemove
          disableSelection
          disableSelectAll
          disableMultipleSelection
          disableDetails
          disableEdit
          PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
          tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pathEntity={""}
        />
      </Container>
    </>
  );
}
