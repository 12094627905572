import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./home.css";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/system";

let theme = createTheme();
// theme = responsiveFontSizes(theme);
theme.typography.h1 = {
  fontSize: "80px",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "200px",
  },
};

export default function Home() {
  const account = useSelector((state: any) => state.account);
  const history = useHistory();

  useEffect(() => {
    setTimeout(function () {
      if (account && !!!account.user) {
        history.push("/login");
      }
    }, 500);
  }, []);

  if (account && !!!account.user) {
    return <></>;
  }
  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: window.innerWidth,
        height: window.innerHeight,
        position: "absolute",
        top: 0,
        left: 0,
      }}
    >
      <Helmet>
        <title>We Scan Home</title>
      </Helmet>
      <div className="container text-center">
        <div className="mt-4">
          <ThemeProvider theme={theme}>
            <img
              style={{ marginTop: "60px", alignSelf: "center" }}
              width="100%"
              src={"images/wescan/Wescan_Logo Home.png"}
            ></img>
          </ThemeProvider>
        </div>
      </div>
    </Box>
  );
}
