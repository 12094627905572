import React, { useState, useCallback } from "react";
import { Typography, CardContent, Box, useTheme, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  RemoveRedEye,
  Image as ImageIcon,
  Check,
  NotInterested,
  OpenInNew,
} from "@material-ui/icons";
import { grey } from "../../theme/themeColors";
import { getSlabImageSafe, openNewTab } from "../../utils/utils";
import { MainImageType, Slabs_slabs_nodes } from "../../data/graphQLModel";
import FlexBox from "../GeneralComponents/FlexBox";
import ImageBox from "../GeneralComponents/ImageBox";
import { H3, H5 } from "../GeneralComponents/Typography";
import SlabDetailsDialog from "./SlabDetailsDialog";
import { weScanDateTime } from "../../utils/Types";

export const getVariety = (slab: {
  commercial_variety?: { name: string | null | undefined } | null | undefined;
  variety?: { normativedesig: string | null | undefined } | null | undefined;
}) => {
  return (
    " " +
    (slab?.commercial_variety?.name
      ? slab?.commercial_variety.name
      : slab?.variety?.normativedesig
      ? slab.variety.normativedesig
      : "")
  );
};

export const getFinish = (slab: {
  commercial_finishing?:
    | {
        description: {
          pt: string;
        };
      }
    | null
    | undefined;
  finish?:
    | {
        description: {
          pt: string;
        };
      }
    | null
    | undefined;
}) => {
  return slab?.commercial_finishing?.description
    ? slab?.commercial_finishing?.description?.pt
    : slab?.finish?.description?.pt
    ? slab?.finish?.description?.pt
    : "";
};

export const getMaterial = (slab: {
  commercial_variety?:
    | {
        materialtype:
          | {
              description: {
                pt: string;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  typematerial?: { description: { pt: string } } | null | undefined;
}) => {
  return slab?.commercial_variety?.materialtype?.description?.pt
    ? slab?.commercial_variety?.materialtype?.description.pt
    : slab?.typematerial?.description?.pt
    ? slab?.typematerial?.description?.pt
    : "";
};

export const transformDate = (date: string) => {
  let date_hour = date.split("T");
  return `${date_hour[0]} ${date_hour[1].split(".")[0]}`;
};

interface SlabData {
  id: string | null;
  code: string | null;
  width: number | null;
  height: number | null;
  thickness: number | null;
  images: string[];
  slabImages?: {
    edited?: string | null;
    original: string;
    thumbnail_edited?: string | null;
    thumbnail_original: string;
    azureBlobID: string;
    mainType: MainImageType;
  }[];
  mainImage?: string | null;
  commercial_variety: Slabs_slabs_nodes["commercial_variety"] | undefined;
  variety: Slabs_slabs_nodes["variety"] | undefined;
  commercial_finishing: Slabs_slabs_nodes["commercial_finishing"] | undefined;
  finish: Slabs_slabs_nodes["finish"] | undefined;
  typematerial: Slabs_slabs_nodes["typematerial"] | undefined;
  packing: Slabs_slabs_nodes["packing"] | undefined;
  inStock?: boolean;
  createdate?: weScanDateTime | null;
}

interface SlabGalleryCardProps {
  slab: SlabData;
  enableDetails?: boolean;
  enableOpenInNewTab?: boolean;
  hoverEffect?: boolean;
  showStock?: boolean;
}

export default function SlabGalleryCard({
  slab,
  enableDetails = true,
  enableOpenInNewTab = true,
  hoverEffect = true,
  showStock = true,
}: SlabGalleryCardProps) {
  const history = useHistory();
  const theme = useTheme();
  let l = getSlabImageSafe(slab, true) ?? "/images/imagemissing.png";

  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<string>();
  const toggleDialog = useCallback(() => {
    setOpenDetailsDialog((openDetailsDialog) => !openDetailsDialog);
  }, []);

  const handleGetSlabDetails = () => {
    history.push({ pathname: "/slabmanagement/details/" + btoa(slab?.id as string) });
  };
  return (
    <ImageBox
      className="card"
      sx={{
        backgroundColor: theme.palette.background.paper,
        cursor: hoverEffect ? "pointer" : "default",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      hoverEffect={hoverEffect}
    >
      {enableDetails && (
        <div className="extra-icons">
          <Box
            style={{
              padding: "3px",
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              zIndex: 10,
            }}
            onClick={() => {
              setSelectedDetails(slab?.id as string);
              toggleDialog();
            }}
          >
            <RemoveRedEye color="secondary" fontSize="medium" />
          </Box>
        </div>
      )}
      <a
        href={enableDetails ? "/slabmanagement/details/" + btoa(slab?.id as string) : "#"}
        style={enableDetails ? undefined : { pointerEvents: "none" }}
      >
        <img src={l} alt={slab?.code || ""} />
        {!!slab?.slabImages && slab?.slabImages?.length > 0 && (
          <FlexBox
            sx={{
              flexDirection: "row",
              width: 80,
              justifyContent: "space-evenly",
              position: "absolute",
              top: "260px",
              right: 0,
              backgroundColor: grey.light,
            }}
          >
            <Typography variant="h5">{slab?.slabImages?.length}</Typography>
            <ImageIcon fontSize="large" color="action" />
          </FlexBox>
        )}
        <CardContent onClick={handleGetSlabDetails}>
          <H3 fontWeight="bold" fontSize={18} color={"grey.600"} ellipsis>
            {slab.code}
          </H3>
          <H3 fontWeight="bold" fontSize={18}>
            {getVariety(slab)}
          </H3>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <H5 fontWeight="light" fontSize={17} color={"grey.600"}>
              {"Finishing: "}
            </H5>
            <H5 fontWeight="bold" fontSize={17} marginLeft={1} color={"primary.main"}>
              {getFinish(slab)}
            </H5>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <H5 fontWeight="light" fontSize={17} color={"grey.600"}>
              {"Material: "}
            </H5>
            <H5 fontWeight="bold" fontSize={17} marginLeft={1} color={"primary.main"}>
              {getMaterial(slab)}
            </H5>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <H5 fontWeight="bold" fontSize={17} color={"grey.600"}>
              {slab.width ? slab.width : 0} X {slab.height ? slab.height : 0} X{" "}
              {slab.thickness ? slab.thickness : 0}
            </H5>
            {slab?.packing?.code && (
              <Box
                fontWeight="bold"
                fontSize={17}
                marginLeft={1}
                color={"grey.600"}
                style={{
                  borderRadius: 5,
                  backgroundColor: theme.palette.warning.light,
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                {slab?.packing?.code}
              </Box>
            )}
          </Box>
          <Box fontWeight="bold" fontSize={17} color={"grey.600"}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <H5 fontWeight="bold" fontSize={17} color={"grey.600"}>
                {slab?.createdate && transformDate(slab.createdate.toString())}
              </H5>
              {showStock && (
                <>
                  {slab.inStock ? (
                    <Check
                      style={{
                        color: "green",
                        width: 30,
                        height: 30,
                        marginLeft: 5,
                      }}
                    />
                  ) : (
                    <NotInterested
                      style={{
                        color: "red",
                        width: 30,
                        height: 30,
                        marginLeft: 5,
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </Box>
        </CardContent>
      </a>
      <FlexBox sx={{ justifyContent: "flex-end" }}>
        {enableOpenInNewTab && (
          <IconButton
            color="primary"
            onClick={() => {
              openNewTab("/slabmanagement/details/" + btoa(slab?.id as string));
            }}
          >
            <OpenInNew />
          </IconButton>
        )}
      </FlexBox>
      {selectedDetails && (
        <SlabDetailsDialog open={openDetailsDialog} toggle={toggleDialog} slab={selectedDetails} />
      )}
    </ImageBox>
  );
}
