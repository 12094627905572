import { Box } from "@material-ui/system";
import useFullWindowSize from "../../hooks/useFullWindowSize";
import { layoutConstant } from "../../utils/constants";

const SensorLogs = () => {
  const size = useFullWindowSize();

  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: size.innerWidth - 20,
        height: size.innerHeight - layoutConstant.topbarHeight + 25,
        position: "absolute",
        top: layoutConstant.topbarHeight + 25,
        left: 0,
      }}
    >
      <iframe
        src={
          "http://swapelc.westeurope.cloudapp.azure.com:3000/d/c3271645-592f-4d59-b132-1a4ca89c3219/toolsuite?orgId=1&theme=light"
        }
        height={"100%"}
        width={size.innerWidth - 20}
        style={{ borderWidth: 0, alignSelf: "flex-start" }}
      />
    </Box>
  );
};

export default SensorLogs;
