import React from "react";
import Cropper from "./Cropper";
import { CropperProps } from "./types";

/**
 * Component that wraps Cropper in order to type it
 * @param props CropperProps
 * @returns Typed Cropper Component
 */
const CropperComponent = React.forwardRef<any, CropperProps>((props, ref) => {
  return <Cropper {...props} ref={ref} />;
});

export default CropperComponent;
