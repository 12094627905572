/* eslint-disable */
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHelperText from "@material-ui/core/FormHelperText";
import { LOGIN_SUCESS, signIn } from "../../actions/accountActions";
// import Loadingpage from "../../components/LoadingFullPage/loading";
import { loadingComponent, notLoadingComponent } from "../../actions/isLoadingActions";
import swalert from "sweetalert";
import { Helmet } from "react-helmet";
import { createTheme } from "@material-ui/core/styles";
import { colors } from "@material-ui/core";
import { MuiThemeProps } from "../../theme/theme";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { useGoogleAuthentication } from "../../services/providers.auth";

const theme = createTheme();
// theme = responsiveFontSizes(theme);
theme.typography.h1 = {
  fontSize: "80px",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
};

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[300],
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  helper: {
    fontSize: "1.1em",
    marginBottom: "3px",
    marginTop: "-4px",
  },
  images: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form, setForm] = useState({ username: "", password: "" });
  const [errormessage, setErrorMessage] = useState("");
  const user = useSelector<any>((state) => state.account.user);
  const { handleSuccess } = useGoogleAuthentication();
  const [loginType, setLoginType] = useState<"client" | "tenant">("tenant");

  const isloged = () => {
    if (Boolean(user)) {
      history.go(-3);
    }
  };

  useEffect(() => {
    isloged();
  }, []);

  function changeform(e: any) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  useEffect(() => {
    try {
      const initClient = () => {
        gapi?.client?.init({
          clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          scope: "email",
        });
      };
      gapi?.load("client:auth2", initClient);
    } catch (err) {
      console.log("Err Google");
    }
  });

  async function submitForm(e: any) {
    e.preventDefault();
    dispatch(loadingComponent());

    try {
      const historyState = history.location?.state as any;
      await signIn(form.username, form.password).then((resUser) => {
        dispatch({
          type: LOGIN_SUCESS,
          payload: {
            user: resUser,
          },
        });
        if (historyState && historyState.hadLogin && historyState.previousPath.trim() !== "") {
          history.push(historyState.previousPath);
        } else {
          history.push("/catalog");
        }
      });
      setForm({ username: "", password: "" });
    } catch (error: any) {
      dispatch(notLoadingComponent());
      let err =
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Error";
      setErrorMessage(err);
      swalert("Failed.", err, "error");
    }
    dispatch(notLoadingComponent());
  }

  const onSucces = (res: any) => {
    console.log("success");
  };

  const onFailureGoogle = (err: any) => {
    alertProviderLoginErrorTreatment(err);
  };

  const alertProviderLoginErrorTreatment = (err: any) => {
    console.log(err);
    let strErr: string =
      typeof err === "string"
        ? err
        : !!err?.toString()
        ? (err?.toString() as string)
        : JSON.stringify(err);
    swalert("Authentication failed.", strErr, "error");
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Grid style={{ flexDirection: "column", display: "flex" }}>
        <Container component="main" maxWidth="xs" sx={{ borderWidth: 2, borderColor: "grey" }}>
          <div
            className={classes.paper}
            style={{
              backgroundColor: colors.grey[200],
              padding: 40,
            }}
          >
            <Grid>
              <img width="100%" src={"images/wescan/Wescan_Logo Home.png"}></img>
            </Grid>
            {/* <ButtonGroup fullWidth sx={{ mt: "1rem", mb: "1rem" }}>
              <Button
                fullWidth
                variant={loginType === "tenant" ? "contained" : "outlined"}
                onClick={() => {
                  setLoginType("tenant");
                }}
              >
                Tenant
              </Button>
              <Button
                fullWidth
                variant={loginType === "client" ? "contained" : "outlined"}
                onClick={() => {
                  setLoginType("client");
                }}
              >
                Client
              </Button>
            </ButtonGroup> */}
            {loginType === "tenant" && (
              <>
                <form className={classes.form} onSubmit={submitForm}>
                  <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    onChange={changeform}
                    label="Email Address"
                    id="username"
                    name="username"
                    autoComplete="email"
                    autoFocus
                  />

                  <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    onChange={changeform}
                    label="Password"
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />

                  <FormControlLabel control={<Checkbox value="remember" />} label="Remember me" />
                  <Button type="submit" fullWidth variant="contained" className={classes.submit}>
                    Sign In
                  </Button>

                  {errormessage ? (
                    <Grid container>
                      <Grid>
                        <FormHelperText error className={classes.helper}>
                          {errormessage}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  ) : null}

                  {/* <Grid container>
                    <Grid item>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/register" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid> */}
                </form>
              </>
            )}
            {/* <FlexBox mt={"0.5rem"} flexDirection="row" justifyContent={"space-around"}>
              <Typography fontSize={16} flex={1}>
                OR
              </Typography>
            </FlexBox> */}
            {loginType === "client" && (
              <>
                <Grid container spacing={2} mt={"1rem"}>
                  <Grid item xs={12}>
                    <GoogleLogin
                      className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-fullWidth MuiButtonBase-root makeStyles-submit-58 css-n0ogpc-MuiButtonBase-root-MuiButton-root"
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
                      buttonText="Sign in with Google"
                      onSuccess={(response) =>
                        handleSuccess(response, onSucces, alertProviderLoginErrorTreatment)
                      }
                      accessType=""
                      onFailure={onFailureGoogle}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={false}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </Container>
        <Container
          maxWidth="sm"
          style={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <img width="100%" height="100%" src={"images/logo_wescan_orange.png"}></img>
          </Grid>
          <Grid item xs={3}></Grid>
        </Container>
      </Grid>
    </>
  );
}
