import React from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import "./styles.css";
import { Box, Card, CardContent, Container } from '@material-ui/core';
import swalert from 'sweetalert';
import DropZoneImageUpload from '../../components/ImageUpload/DropZoneImageUpload';
import { ArrowBack } from '@material-ui/icons';
import { SlabUploadInput, UploadSlabs, UploadSlabsVariables } from '../../data/graphQLModel';
import { UPLOAD_SLAB_WESCAN } from '../../data/graphQLQueries';
import { useMutation } from '@apollo/react-hooks';
import toast from 'react-hot-toast'
import { file2Base64 } from '../../utils/utils';
import getKeyValueMetadata from '../../utils/getKeyValueMetadata';

const LOADING_TOAST = "LOADING_TOAST"

export default function CreateSlab() {
  const history = useHistory();

  const [mutationUploadSlabs] = useMutation<UploadSlabs, UploadSlabsVariables>(UPLOAD_SLAB_WESCAN)

  const onUpload = async (files: File[]) => {
    swalert({
      title: "Upload",
      text: `Upload ${files.length} file${files.length > 1 ? 's' : ''}?`,
      // @ts-ignore
      buttons: true,
      dangerMode: false,
    }).then(async (willUpload) => {
      if (willUpload) {
        await handleUpload(files)
      }
    });
  }

  const handleUpload = async (files: File[]) => {
    toast.loading("Uploading", { id: LOADING_TOAST })

    let slab_upload_input: SlabUploadInput[] = []
    try {
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        const metadata = await getKeyValueMetadata(element)
        const base = await file2Base64(element)
        slab_upload_input.push({
          strfile: base.substring(base.indexOf(',') + 1),
          strfiletype: base.substring(base.indexOf("/") + 1, base.indexOf(";")),
          metadata: JSON.stringify(metadata),
          name: element.name.split('.')[0] // remove file extension
        })
      }
      await mutationUploadSlabs({
        variables: {
          input: {
            upload: slab_upload_input
          }
        }
      }).then((done) => {
        if (!done?.errors) {
          toast.dismiss(LOADING_TOAST)
          toast.success(`Done`);
          history.push('/slabmanagement');
        } else {
          toast.dismiss(LOADING_TOAST)
          toast.error(`Error while uploading ${done?.errors[0]?.message}`);
        }
      })
    } catch (err: any) {
      toast.dismiss(LOADING_TOAST)
      toast.error(`Error while uploading ${err.toString()}`);
    }
  }


  function handleGoBack(event: any) {
    event.preventDefault();
    history.goBack()
  }

  return (
    <div className="container" >
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>New Product</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <DropZoneImageUpload onUpload={onUpload} />
          </CardContent>
        </Card>
        <Button
          onClick={handleGoBack}
          fullWidth
          color="primary"
          variant="contained"
          // className={classes.submit}
          sx={{ height: "50px", width: '200px', fontSize: '20px', mt: 2 }}
          startIcon={<ArrowBack sx={{ mt: 0.2 }} />}
        >
          Back
        </Button>
      </Container>
    </div>
  );
}
