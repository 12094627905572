/* eslint-disable */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import swalert from "sweetalert";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { Box, Card, CardContent, Container, Grid } from "@material-ui/core";
import { loadingComponent, notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { MuiThemeProps } from "../../../theme/theme";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CursorPaging,
  Packing,
  PackingTypeFilter,
  PackingTypes,
  PackingTypeSort,
  PackingTypes_packingTypes_edges_node,
  PackingUpdate,
  PackingVariables,
  ProjectFilter,
  Projects,
  ProjectSort,
  Projects_projects_edges_node,
  UpdateOnePacking,
  UpdateOnePackingVariables,
} from "../../../data/graphQLModel";
import {
  MUTATION_UPDATE_ONE_PACKING,
  QUERY_MANY_PACKAGE_TYPES,
  QUERY_MANY_PROJECTS,
  QUERY_ONE_PACKING,
} from "../../../data/graphQLQueries";
import { decodeBase64 } from "../../../utils/utils";
import AutocompleteWithQuery from "../../../components/Form/AutocompleteWithQuery";
import { queryValuesForFilter } from "../../../utils/ApolloClientUtils";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "80%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function EditPackage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [packageTypes, setPackageTypes] = useState<PackingTypes_packingTypes_edges_node[]>([]);
  const [projects, setProjects] = useState<Projects_projects_edges_node[]>([]);

  const handleChange = (e: React.ChangeEvent<any>) => {
    if (e.target.value === undefined) {
      setForm({
        ...form,
        [e.target.name]: null,
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const [form, setForm] = useState<Partial<PackingUpdate> & { id: string; code: string }>({
    id: "",
    code: "",
    packing: undefined,
    packingtype: undefined,
    observation: undefined,
    project: undefined,
  });

  let { parpack } = useParams() as { parpack: string };

  useQuery<Packing, PackingVariables>(QUERY_ONE_PACKING, {
    variables: {
      id: decodeBase64(parpack),
    },
    skip: !!!parpack || (typeof parpack === "string" && parpack?.trim() === ""),
    onCompleted(data) {
      if (!!data.packing) {
        setForm({
          code: data.packing.code,
          id: data.packing.id,
          observation: data.packing?.observation,
          packing: data.packing.packing,
          packingtype: data.packing?.packingtype?.id,
          project: data.packing?.project?.id,
        });
      }
    },
  });

  const [updateOnePackage] = useMutation<UpdateOnePacking, UpdateOnePackingVariables>(
    MUTATION_UPDATE_ONE_PACKING
  );

  function changeform(e: any) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  async function submitForm(e: any) {
    e.preventDefault();

    if (
      form.code === "" ||
      (form.code.length || 0) < 2 ||
      form.packing === "" ||
      (form?.packing?.length || 0) < 5
    ) {
      swalert("Information!", "Missing information!", "warning");
      return;
    }

    dispatch(loadingComponent());

    try {
      await updateOnePackage({
        variables: {
          input: {
            id: form.id,
            update: {
              observation: form.observation,
              packing: form.packing,
              packingtype: form.packingtype,
              project: form.project,
            },
          },
        },
      });
      swalert("Success.", "Package update with success", "success");
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert(
        "Failed.",
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Erro",
        "error"
      );
      return;
    }
    dispatch(notLoadingComponent());
    // history.push('/customerstables/packages');
    history.goBack();
  }

  function handleCancel(event: any) {
    event.preventDefault();
    // history.push('/customerstables/packages/');
    history.goBack();
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>Edit Package</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm} style={{ width: "100%" }}>
              {form.code && (
                <>
                  <div>
                    <FormControl fullWidth className={classes.marginCode} variant="outlined">
                      <TextField
                        id="CodeProject"
                        label="Code"
                        name="code"
                        value={form.code}
                        placeholder="Code"
                        disabled
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                      <TextField
                        id="idPacking"
                        label="Package"
                        name="packing"
                        value={form.packing}
                        onChange={changeform}
                        placeholder="Packing"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                  <div>
                    <Grid key="package-type-grid" item md={4} xs={12}>
                      <FormControl fullWidth>
                        <AutocompleteWithQuery
                          items={packageTypes}
                          label="Package Types"
                          name="packingtype"
                          onChange={handleChange}
                          title="Package Types"
                          value={form.packingtype}
                          onTextChange={async (value: string) => {
                            await queryValuesForFilter<
                              PackingTypeFilter,
                              CursorPaging,
                              PackingTypeSort,
                              PackingTypes
                            >(
                              value,
                              QUERY_MANY_PACKAGE_TYPES,
                              (res) => {
                                let endRes = res?.data?.packingTypes?.edges?.map((el) => el.node);
                                setPackageTypes(endRes);
                              },
                              {
                                filter: {
                                  or: [{ code: { iLike: value } }],
                                },
                                paging: {
                                  first: 30,
                                },
                              }
                            );
                          }}
                          getField={(node: PackingTypes_packingTypes_edges_node) => {
                            return `${node.code} - ${node.observation}`;
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </div>
                  <div>
                    <Grid key="project-grid" item md={4} xs={12}>
                      <FormControl fullWidth>
                        <AutocompleteWithQuery
                          items={projects}
                          label="Projects"
                          name="project"
                          onChange={handleChange}
                          title="Projects"
                          value={form.project}
                          onTextChange={async (value: string) => {
                            await queryValuesForFilter<
                              ProjectFilter,
                              CursorPaging,
                              ProjectSort,
                              Projects
                            >(
                              value,
                              QUERY_MANY_PROJECTS,
                              (res) => {
                                let endRes = res?.data?.projects?.edges?.map((el) => el.node);
                                setProjects(endRes);
                              },
                              {
                                filter: {
                                  or: [
                                    { code: { iLike: value } },
                                    { description: { iLike: value } },
                                    { observation: { iLike: value } },
                                    { project: { iLike: value } },
                                  ],
                                },
                                paging: {
                                  first: 30,
                                },
                              }
                            );
                          }}
                          getField={(node: Projects_projects_edges_node) => {
                            return node.project;
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </div>
                  <div>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                      <TextField
                        id="obsProject"
                        label="Observation"
                        name="observation"
                        placeholder="Observation"
                        onChange={changeform}
                        value={form.observation}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        multiline
                        rows={5}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                </>
              )}
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      disabled={
                        !!!form.code ||
                        form.code === "" ||
                        form.code.length <= 2 ||
                        !!!form.packingtype ||
                        form.packingtype === "" ||
                        !!!form.packing ||
                        form.packing === ""
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Save
                      <Save style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
