/*eslint-disable */
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, CardContent, FormControl } from "@material-ui/core/";
import { Helmet } from "react-helmet";
import GallerySlabs from "../../../components/projectgallery/slab.projectgallery";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import queryString from "query-string";

export default function BlockInProjectGallery() {
  const history = useHistory();
  const { search, ...location } = useLocation();
  const { keyValue } = queryString.parse(search) as {
    keyValue?: string;
  };
  const [slabs, setSlabs] = useState<any[]>([]);
  const [form, setForm] = useState({ code: "", project: "" });

  useEffect(() => {
    if (!!location) {
      if (!!!location.state) {
        history.goBack();
      } else {
        let state = location.state as { key: string; slabs: any[]; qtd: number; project: string };
        setSlabs(state.slabs);
        setForm({ code: state.key, project: state.project });
      }
    }
  }, [location]);

  return (
    <div className="container">
      <Helmet>
        <title>Block Gallery | WeScan</title>
      </Helmet>
      {form.code !== "" && (
        <>
          <Card style={{ marginTop: "50px", marginBottom: "10px" }}>
            <CardContent>
              <h3>Block: {form.code}</h3>
              <h6>Project: {form.project}</h6>
            </CardContent>
          </Card>
          <div className="cards">
            {slabs?.map((stn, key) => (
              <GallerySlabs key={key} slab={stn} keyValue={keyValue} />
            ))}
          </div>{" "}
        </>
      )}
      <div className="btnform">
        <div>
          <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
            <Button
              type="submit"
              onClick={() => {
                history.goBack();
              }}
              fullWidth
              color="primary"
              variant="contained"
              style={{ height: "50px", width: "200px", fontSize: "20px" }}
            >
              <ArrowBackIosIcon />
              Back
            </Button>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
