import React, { useEffect, useState } from 'react';
//@ts-ignore
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import TablePageHeaderComponent from '../../../components/Table/TablePageHeaderComponent';
import GeneralTableComponent from '../../../components/Table/GeneralTableComponent';
import { useQuery } from '@apollo/react-hooks'
import { APPLICATION_TYPES } from '../../../data/graphQLQueries';
import { ApplicationTypes, ApplicationTypesVariables } from '../../../data/graphQLModel';

const headCells = [
  { id: 'development_type.name', numeric: false, disablePadding: false, label: 'Development Type' },
  { id: 'application_typology', numeric: false, disablePadding: false, label: 'Application Typology' },
  { id: 'application_site.name', numeric: false, disablePadding: false, label: 'Site' },
  { id: 'description.details', numeric: false, disablePadding: false, label: 'Description 1' },
  { id: 'description.description', numeric: false, disablePadding: false, label: 'Description 2' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date' },
];


export default function ApplicationTypesTable() {
  const { loading, error, data } = useQuery<ApplicationTypes,
    ApplicationTypesVariables>(APPLICATION_TYPES, {
      variables: {
        paging: { first: 50 }
      }
    })
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error</div>
  }

  return (
    <>
      <Helmet>
        <title>Application Types | WeScan</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <TablePageHeaderComponent buttonTitle={'New Application Type'}
            entityNamePlural={'Application Types'} pathEntity={'#'}
          />
          {data && <Box sx={{ pt: 3 }}>
            <GeneralTableComponent data={data.applicationTypes.edges.map((edge) => edge.node)} headCells={headCells}
              orderByDefault={'createdAt'} pathEntity={'#'} disableEdit={true} disableRemove={true}
              disableDetails={true} disableSelection={true} key={'Application Types'}
            />
          </Box>}
        </Container>
      </Box>
    </>
  )
}
