import { BoxProps, Button, Divider, Theme, Typography } from "@material-ui/core";
import { Cancel, CloudUpload } from "@material-ui/icons";
import { Box, SxProps } from "@material-ui/system";
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { grey } from "../../theme/themeColors";
import FileIconSwitch from "../../utils/FileIconSwitch";
import FlexBox from "../GeneralComponents/FlexBox";

export interface DropZoneProps {
  onUpload: (file: File[]) => Promise<void>;
  refresh?: boolean;
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
  accept: string;
  multiple: boolean;
  imageUpload?: boolean;
  title?: string;
  showUploadButton?: boolean;
  saveFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  parentBoxSxProps?: SxProps<any>;
  toReplaceExisting?: boolean;
}

const DropZone: React.FC<DropZoneProps> = ({
  onUpload,
  refresh,
  setRefresh,
  accept,
  multiple,
  saveFiles,
  showUploadButton = true,
  imageUpload = false,
  title = "Drag & Drop Files",
  parentBoxSxProps = {
    mb: "0.2rem",
  },
  toReplaceExisting = false,
}) => {
  const [files, setFiles] = React.useState<File[]>();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    multiple,
    accept,
    maxFiles: 5,
  });

  const onFileRemove = () => {
    setFiles(undefined);
  };

  useEffect(() => {
    if (refresh) {
      onFileRemove();
      setRefresh && setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!!saveFiles) {
      saveFiles(files as any);
    }
  }, [files]);

  const isImported = () => {
    if (acceptedFiles && files && files.length > 0) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
          border="1px dashed"
          borderColor="grey.400"
          borderRadius="10px"
          bgcolor={isDragActive ? "grey.200" : "none"}
          sx={{
            transition: "all 250ms ease-in-out",
            outline: "none",
            p: 2,
          }}
        >
          <Divider sx={{ width: "200px", mx: "auto" }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {files &&
              files.length > 0 &&
              files.map((file, ind) => {
                const objectUrl = URL.createObjectURL(file);
                return (
                  <Box
                    height={128}
                    width={"auto"}
                    padding={3}
                    minWidth={128}
                    bgcolor="white"
                    borderRadius="10px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="1px solid"
                    borderColor={grey[300]}
                    style={{ cursor: "pointer" }}
                    ml={ind === 0 ? "auto" : 0}
                    mr={ind === files.length - 1 ? "auto" : "10px"}
                    key={ind + ""}
                  >
                    {/* @ts-ignore */}
                    {imageUpload ? (
                      <img src={objectUrl} style={{ width: 100, height: "auto", maxHeight: 120 }} />
                    ) : (
                      <FlexBox sx={{ flexDirection: "column", alignItems: "center" }}>
                        {FileIconSwitch(file)}
                        <Typography>{file.name}</Typography>
                      </FlexBox>
                    )}
                  </Box>
                );
              })}
          </Box>

          <input {...getInputProps()} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              color="primary"
              type="button"
              sx={{
                bgcolor: "primary.light",
                px: "2rem",
                mt: "22px",
                width: "250px",
                mr: 2,
              }}
              onClick={() => onFileRemove()}
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
            {showUploadButton && (
              <Button
                color="secondary"
                type="button"
                sx={{
                  bgcolor: "primary.light",
                  px: "2rem",
                  mt: "22px",
                  width: "250px",
                }}
                onClick={() => onUpload(files as any)}
                startIcon={<CloudUpload />}
              >
                {toReplaceExisting ? "Upload & Replace" : "Upload"}
              </Button>
            )}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
          border="1px dashed"
          borderColor="grey.400"
          borderRadius="10px"
          bgcolor={isDragActive ? "grey.200" : "none"}
          sx={{
            transition: "all 250ms ease-in-out",
            outline: "none",
            p: 2,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Typography variant="h5" mb={2} color="grey.600">
            {title} ({accept})
          </Typography>

          <Divider sx={{ width: "200px", mx: "auto" }} />

          <Typography
            color="grey.600"
            bgcolor={isDragActive ? "grey.200" : "background.paper"}
            lineHeight="1"
            px={2}
            mt={-1.25}
            mb={2}
          >
            or
          </Typography>

          <Button
            color="primary"
            type="button"
            sx={{
              bgcolor: "primary.light",
              px: "2rem",
              mb: "22px",
            }}
          >
            Select files
          </Button>

          <Typography color="grey.600">Upload limit 60mb</Typography>
        </Box>
      );
    }
  };
  return <Box sx={parentBoxSxProps}>{isImported()}</Box>;
};

export default DropZone;
