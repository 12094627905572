import { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";

export enum IAuthProviders {
  "google" = "google",
  "microsoft" = "microsoft",
  "wechat" = "wechat",
}

export interface IProviderData {
  provider: IAuthProviders;
  res_google?: GoogleLoginResponse | GoogleLoginResponseOffline;
  res_microsoft?: any;
  res_wechat?: any;
}

export function useGoogleAuthentication() {
  const handleSuccess = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
    onSuccess: (result: any) => void,
    onError: (message: string) => void
  ) => {
    if ("accessToken" in response) {
      const accessToken = response.accessToken;

      await fetch(`${process.env.REACT_APP_API_BASEURL_V2}/auth/google/callback`, {
        method: "POST",
        body: JSON.stringify({
          token: accessToken,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (res) => {
          if (res.ok) {
            let response = await res.json();
            onSuccess(response);
          } else {
            let errRes = await res.json();
            onError(errRes?.message);
          }
        })
        .catch(onError);
    }
  };

  return {
    handleSuccess,
  };
}
