/* eslint-disable */
import React from "react";
import { useHistory } from "react-router-dom";
import swalert from "sweetalert";
import { Box, Card, Container, IconButton } from "@material-ui/core";
import { MUTATION_CREATE_ONE_JOB } from "../../../data/graphQLQueries";
import { useMutation } from "@apollo/react-hooks";
import {
  CreateOneJob,
  CreateOneJobVariables,
  DescState,
  JobInput,
  JobNumberingOrder,
  Job_job,
} from "../../../data/graphQLModel";
import * as yup from "yup";
import { copyObject } from "../../../utils/utils";
import { isNumeric } from "../../utils/utils";
import JobForm from "./forms/JobForm";
import { H2 } from "../../../components/GeneralComponents/Typography";
import FlexBox from "../../../components/GeneralComponents/FlexBox";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export default function CreateJob() {
  const history = useHistory();

  const [mutationCreateOneJob] = useMutation<CreateOneJob, CreateOneJobVariables>(
    MUTATION_CREATE_ONE_JOB
  );

  /**
   * Create Job after form submition
   * @param form form value of JobInput object
   */
  async function submitForm(form: JobInput) {
    const numberFields: (keyof CreateOneJobVariables["input"]["job"])[] = [
      "totalslab",
      "startNumber",
    ];

    try {
      // await slabServices.updateProjectSlab(slabs, form);
      // remove variables from form
      const input_data = copyObject(form, []);
      //delete 0's, empty strings
      for (const key in input_data) {
        if (Object.prototype.hasOwnProperty.call(input_data, key)) {
          let typed_key = key as keyof typeof input_data;
          const element = input_data[typed_key];
          //0's, empty strings
          if (element === 0 || (typeof element === "string" && element.trim() === "")) {
            delete input_data[typed_key];
          } else {
            if (numberFields.indexOf(typed_key) !== -1 && isNumeric(element)) {
              //@ts-ignore
              input_data[typed_key] = Number(element);
            } else if (!element && typeof element === "undefined") {
              //@ts-ignore
              input_data[typed_key] = null;
            }
          }
        }
      }
      await mutationCreateOneJob({
        variables: {
          input: {
            job: input_data,
          },
        },
      });
      swalert("Job", "Job was created", "success");
      if (history?.length <= 1) {
        history.push("/slabsprocess/jobs");
      } else {
        history.goBack();
      }
    } catch (error: any) {
      console.log(error);
      swalert("Job", `Error creating Job: ${error?.toString()}`, "warning");
    }
  }

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <Container sx={{ pt: "1rem" }}>
      <Box mb={1}>
        <Card sx={{ p: 1 }}>
          <FlexBox sx={{ alignItems: "center" }}>
            <IconButton
              type="submit"
              disabled={history?.length <= 1}
              onClick={() => history?.goBack()}
              color="primary"
              sx={{ mr: 1 }}
            >
              <ArrowBackIosIcon fontSize="large" />
            </IconButton>{" "}
            <H2>Create Job</H2>
          </FlexBox>
        </Card>
      </Box>
      <Card sx={{ p: "2rem" }}>
        <JobForm
          handleCancel={handleCancel}
          initialValues={createJobInitialValues}
          validationSchema={createJobCheckoutSchema}
          submitForm={submitForm}
        />
      </Card>
    </Container>
  );
}

export const createJobInitialValues: JobInput & { endNumber?: number | null | undefined } = {
  code: "",
  description: "",
  jobtype: "",
  scan: "",
  isblock: false,
  totalslab: 1,
  startNumber: 1,
  numberingOrder: JobNumberingOrder.ASC,
  separator: "-",
  descstate: DescState.EXECUTION,
  endNumber: 1,
};

export const createJobCheckoutSchema = (data?: Job_job, invalidJobCodes?: string[]) =>
  yup.object().shape(
    {
      code: yup
        .string()
        .notOneOf(invalidJobCodes ?? [], "Invalid code. Already exists.")
        .required("Required")
        .nullable(),
      description: yup.string().nullable(),
      jobtype: yup.string(),
      scan: yup.string().required("Required"),
      blocknumber: yup.string().notRequired().nullable(),
      category: yup.string().notRequired().nullable(),
      commercial_finishing: yup.string().notRequired().nullable(),
      commercial_variety: yup.string().notRequired().nullable(),
      finish: yup.string().notRequired().nullable(),
      packing: yup.string().notRequired().nullable(),
      project: yup.string().notRequired().nullable(),
      stone_edge: yup.string().notRequired().nullable(),
      stone_model: yup.string().notRequired().nullable(),
      typematerial: yup.string().notRequired().nullable(),
      variety: yup.string().notRequired().nullable(),
      isblock: yup.boolean().nullable(),
      descstate: yup.mixed<DescState>().oneOf(Object.values(DescState)).required("Required"),
      separator: yup
        .string()
        .nullable()
        .notRequired()
        .matches(/^([^0-9]*)$/, "Can't be a number."),
      block: yup.string().nullable().notRequired(),
      numberingOrder: yup
        .mixed<JobNumberingOrder>()
        .oneOf(Object.values(JobNumberingOrder))
        .required("Required")
        .nullable(),
      startNumber: yup
        .number()
        .when(["numberingOrder", "endNumber"], (values: any[], schema: yup.NumberSchema) => {
          let numberingOrder = values[0] as JobNumberingOrder;
          let endNumber = values[1] as number;
          return numberingOrder === JobNumberingOrder.DESC
            ? schema.min(endNumber || 1, "Should be greater or equal to End Number").required()
            : schema.max(endNumber || 1, "Should be less or equal to End Number").required();
        }),
      endNumber: yup
        .number()
        .when(["numberingOrder", "startNumber"], (values: any[], schema: yup.NumberSchema) => {
          let numberingOrder = values[0] as JobNumberingOrder;
          let startNumber = values[1] as number;
          return numberingOrder === JobNumberingOrder.ASC
            ? schema.min(startNumber || 1, "Should be greater or equal to Start Number").required()
            : schema.max(startNumber || 1, "Should be less or equal to Start Number").required();
        }),
      prefix: yup.string().required("Prefix is required").nullable(),
    },
    [["endNumber", "startNumber"]]
  );
