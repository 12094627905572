import { useState } from "react";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  CursorPaging,
  ProjectFilter,
  Projects,
  ProjectSort,
  Projects_projects_edges_node,
} from "../../data/graphQLModel";
import { QUERY_MANY_PROJECTS } from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { EntityAutocompleteProps } from "./types";

/**
 * ## Reusable Stone Model Autocomplete Component
 *
 * ### Default Autocomplete property values:
 *
 * #### name: project
 * #### label: Projects
 * #### continueToLoadResultsInComponent: true
 * #### shouldFieldBeLocked: false
 *
 */
const ProjectAutocomplete = ({
  errors,
  continueToLoadResultsInComponent = true,
  shouldFieldBeLocked = false,
  helperText,
  value,
  onChange,
  ...props
}: EntityAutocompleteProps) => {
  const [projects, setProjects] = useState<Projects_projects_edges_node[]>([]);

  return (
    <AutocompleteWithQuery
      onChange={onChange}
      value={value}
      items={projects}
      label="Projects"
      name="project"
      title="Projects"
      TextFieldProps={{
        error: !!errors,
        helperText: helperText,
        label: "Projects",
        disabled: shouldFieldBeLocked,
      }}
      onTextChange={async (value: string) => {
        await queryValuesForFilter<ProjectFilter, CursorPaging, ProjectSort, Projects>(
          value,
          QUERY_MANY_PROJECTS,
          (res) => {
            let endRes = res?.data?.projects?.edges?.map((el) => el.node);
            setProjects(endRes);
          },
          {
            filter: {
              or: [
                { code: { iLike: value } },
                { description: { iLike: value } },
                { observation: { iLike: value } },
                { project: { iLike: value } },
              ],
            },
            paging: {
              first: 30,
            },
          }
        );
      }}
      getField={(node: Projects_projects_edges_node) => {
        return node.project;
      }}
      {...props}
      AutocompleteProps={{
        disabled: shouldFieldBeLocked,
        ...props?.AutocompleteProps,
      }}
    />
  );
};

export default ProjectAutocomplete;
