export const layoutConstant = {
  topbarHeight: 40,
  grocerySidenavWidth: 400,
  containerWidth: 1200,
  mobileNavHeight: 64,
  headerHeight: 68,
  topBarPaginationContainerHeight: 100,
  mobileHeaderHeight: 64,
  stickyBarZIndex: 1001,
  topBarZIndex: 10,
};
