import { Avatar } from "@material-ui/core";
import { Box } from "@material-ui/system";

export interface ImageListComponentProps {
  images: string[];
  selectedImage: number;
  setSelectedImage: React.Dispatch<React.SetStateAction<number>>;
  direction?: "vertical" | "horizontal";
  applyMarginOnFirstChild?: boolean;
  maxHeight?: number | string;
}

export const ImageListComponent = ({
  images,
  selectedImage,
  setSelectedImage,
  direction = "horizontal",
  applyMarginOnFirstChild = true,
  maxHeight = undefined,
}: ImageListComponentProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        ml: direction === "vertical" ? 1 : 0,
        mr: direction === "vertical" ? 1 : 0,
        flexDirection: direction === "horizontal" ? "row" : "column",
      }}
    >
      {images &&
        images.length > 1 &&
        images.map((url, ind) => (
          <Box
            height={64}
            width={64}
            minWidth={64}
            bgcolor="white"
            borderRadius="10px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            border="1px solid"
            style={{ cursor: "pointer" }}
            ml={ind === 0 ? (applyMarginOnFirstChild ? "auto" : 0) : 0}
            mr={direction === "horizontal" ? (ind === images.length - 1 ? "auto" : "10px") : "auto"}
            mt={direction === "vertical" ? (ind === 0 ? 0 : 1) : "auto"}
            borderColor={selectedImage === ind ? "red" : "black"}
            onClick={() => setSelectedImage(ind)}
            key={ind + ""}
          >
            {/* @ts-ignore */}
            <Avatar src={url} variant="square" height={40} />
          </Box>
        ))}
    </Box>
  );
};
