import Layout from "./layout";
import Pages from "./pages/routes";
import { useSelector } from "react-redux";
import Loading from "./components/LoadingFullPage/loading";
import { useEffect, useState } from "react";
import { wrapperFunction } from "./services/azureAppInsights";
import { useLocation } from "react-router-dom";

function App() {
  const isLoading = useSelector((state) => state.isloading.loading);
  const account = useSelector((state) => state.account);
  const [isAuth, setIsAuth] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsAuth(Boolean(account.user) && account?.user?.authType !== "shareableLink");
  }, [account.user]);

  useEffect(() => {
    const element = document.getElementById("myThreeJsCanvas");
    if (!!element) {
      element.remove();
    }
  }, [location?.pathname]);

  return (
    <Layout isAuthenticated={isAuth}>
      <Pages />
      {isLoading && <Loading />}
    </Layout>
  );
}

export default wrapperFunction(App);
