/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Loadingpage from "../../components/LoadingFullPage/loading";
import { loadingComponent, notLoadingComponent } from "../../actions/isLoadingActions";
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: theme.palette.grey,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Register() {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [errormessage, setErrorMessage ] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.account.user);

  useEffect(() => {
    if (user) {
      history.push('/')
    }
  }, []);

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: ''
  });

  function changeform(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value })
  }

  async function submitForm() {
    //  e.preventDefault();
    // setIsLoading(true);
    await dispatch(loadingComponent());


    try {
      //await dispatch(signIn(form.username, form.password));
      // setIsLoading(false);

    } catch (error) {
      // setErrorMessage(error)
      setIsLoading(false);
    }
    // setIsLoading(false);
    // setForm({username:'', password:''}) onSubmit={submitForm}  >
    await dispatch(notLoadingComponent());

  }

  return (
    <>
      {
        isLoading ? <Loadingpage />
          :
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <PersonAddIcon fontSize='large' />
              </Avatar>
              <Typography component="h1" variant="h5">
                Register
              </Typography>
              <form className={classes.form} >
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  onChange={changeform}
                  label="First Name"
                  id="firstname"
                  name="firstname"
                  autoFocus
                />
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  onChange={changeform}
                  label="Last Name"
                  id="lastname"
                  name="lastname"
                />

                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  onChange={changeform}
                  label="Email Address"
                  id="email"
                  name="email"
                />
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  onChange={changeform}
                  label="password"
                  id="password"
                  type="password"
                  name="password"
                  autoComplete="current-password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  colors="primary.main"
                  className={classes.submit}
                  onClick={submitForm}
                >
                  Register
                </Button>
                <Grid container>
                  <Grid item>
                    <Link href="/login" variant="body2">
                      {"I have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
      }
    </>
  );
}