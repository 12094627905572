import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  Tabs,
  Tab,
  FormControl,
  Skeleton,
  TextField,
  Container,
  CircularProgress,
  useTheme,
  TablePagination,
} from "@material-ui/core/";
import { Helmet } from "react-helmet";
import ToSharePackageGalleryComponent from "../../../../components/gallery/ToSharePackageGalleryComponent";
import GallerySlabs from "../../../../components/projectgallery/slab.projectgallery";
import { CloudDownload } from "@material-ui/icons";
import { downloadProjectV1 } from "../../../../utils/downloadImages";

import "./styles.css";
import FlexBox from "../../../../components/GeneralComponents/FlexBox";
import {
  QUERY_ONE_SHARED_PROJECT,
  QUERY_SHARED_PROJECT_PACKAGES,
  QUERY_SHARED_PROJECT_SLABS,
} from "../../../../data/graphQLQueries";
import {
  CommercialVariety_commercialVariety_annexedDocuments_nodes,
  CursorPaging,
  SharedProject,
  SharedProjectPackages,
  SharedProjectPackagesVariables,
  SharedProjectSlabs,
  SharedProjectSlabsVariables,
  SharedProjectVariables,
  SharedProject_sharedProject_packings_edges_node_slabs_edges_node,
  SlabSortFields,
  SortDirection,
} from "../../../../data/graphQLModel";
import { useParams } from "react-router-dom";
import AnnexedDocumentButtonDialog from "../../../../components/AnnexedDocs/AnnexDocumentButtonModal";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { onChangePageCursor } from "../../../../utils/utils";
import { H2 } from "../../../../components/GeneralComponents/Typography";
import { MuiThemeProps } from "../../../../theme/theme";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    "& > *": {
      padding: 1,
      marginBottom: theme.spacing(4),
    },
  },
  boxTop: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
  },
  caption: {
    marginTop: "0.8rem",
  },
}));

type ShareProjectTabValues = "block" | "bundle" | "slab";

export default function ProjectGallery() {
  const theme = useTheme();
  const classes = useStyles();
  const [annexedDocs, setAnnexedDocs] =
    useState<CommercialVariety_commercialVariety_annexedDocuments_nodes[]>();
  // by block
  const [allSlabsData, setAllSlabsData] = useState<
    SharedProject_sharedProject_packings_edges_node_slabs_edges_node[] | undefined
  >([]);
  const [form, setForm] = useState({
    id: "",
    code: "",
    project: "",
    description: "",
    observation: "",
  });
  const { prj: projectShareKeyValue } = useParams() as { prj: string };

  const [tabValue, setTabValue] = useState<ShareProjectTabValues>("slab");

  const scrollToY = (offSetTop: number | undefined) => {
    if (
      !!window &&
      typeof window !== "undefined" &&
      typeof offSetTop === "number" &&
      offSetTop >= 0
    ) {
      window.scrollTo({ top: offSetTop });
    }
  };

  //#region Projects

  const [projectLazyQuery, { data, loading, error }] = useLazyQuery<
    SharedProject,
    SharedProjectVariables
  >(QUERY_ONE_SHARED_PROJECT, {
    fetchPolicy: "cache-first",
  });
  const project = useQuery<SharedProject, SharedProjectVariables>(QUERY_ONE_SHARED_PROJECT, {
    variables: {
      keyValue: projectShareKeyValue,
      packagesPaging: {
        first: 1,
      },
      slabsPaging: {
        first: 1,
      },
    },
    pollInterval: 30000,
    fetchPolicy: "cache-first",
    onCompleted(data) {
      setForm({
        code: data.sharedProject.code,
        description: data.sharedProject.description as string,
        id: data.sharedProject.id,
        observation: data.sharedProject.observation as string,
        project: data.sharedProject.project,
      });
    },
  });

  //#endregion

  //#region Slabs variables
  const slabsParentRef = useRef<HTMLElement>(null);
  const [slabsPageNumber, setSlabsPageNumber] = useState(0);
  const [slabsPaging, setSlabsPaging] = useState<CursorPaging>({ first: 9 });

  const {
    data: slabsData,
    loading: slabsLoading,
    error: slabsError,
    previousData: slabsPreviousData,
    ...slabs
  } = useQuery<SharedProjectSlabs, SharedProjectSlabsVariables>(QUERY_SHARED_PROJECT_SLABS, {
    variables: {
      slabsSorting: [
        {
          direction: SortDirection.DESC,
          field: SlabSortFields.createdAt,
        },
      ],
      slabsPaging,
      keyValue: projectShareKeyValue,
    },
    fetchPolicy: "cache-first",
    pollInterval: 30000,
  });
  const handleSlabPageChange = (e: any, page: number) => {
    onChangePageCursor(
      e,
      page,
      slabsPageNumber,
      !!slabsPaging.first ? slabsPaging.first : !!slabsPaging.last ? slabsPaging.last : 10,
      slabsData?.sharedProject?.slabs?.pageInfo || ({} as any),
      (pageNumber: number, newPaging: CursorPaging) => {
        setSlabsPaging(newPaging);
        setSlabsPageNumber(pageNumber);
        scrollToY(slabsParentRef?.current?.offsetTop);
      }
    );
  };
  //#endregion

  //#region Packages variables
  const packageParentRef = useRef<HTMLElement>(null);
  const [packagesPageNumber, setPackagesPageNumber] = useState(0);
  const [packagesPaging, setPackagesPaging] = useState<CursorPaging>({ first: 9 });
  const {
    data: packagesData,
    loading: packagesLoading,
    error: packagesError,
    previousData: packagesPreviousData,
  } = useQuery<SharedProjectPackages, SharedProjectPackagesVariables>(
    QUERY_SHARED_PROJECT_PACKAGES,
    {
      variables: {
        packagesPaging: packagesPaging,
        keyValue: projectShareKeyValue,
      },
      fetchPolicy: "cache-first",
      pollInterval: 30000,
    }
  );
  const handlePackagePageChange = (e: any, page: number) => {
    onChangePageCursor(
      e,
      page,
      packagesPageNumber,
      !!packagesPaging.first
        ? packagesPaging.first
        : !!packagesPaging.last
        ? packagesPaging.last
        : 10,
      packagesData?.sharedProject?.packings?.pageInfo || ({} as any),
      (pageNumber: number, newPaging: CursorPaging) => {
        setPackagesPaging(newPaging);
        setPackagesPageNumber(pageNumber);
        scrollToY(packageParentRef?.current?.offsetTop);
      }
    );
  };
  //#endregion

  //#region Blocks Variables
  // const blocksParentRef = useRef<HTMLElement>(null);
  // const [blocksPageNumber, setBlocksPageNumber] = useState(0);
  // const [blocksPaging, setBlocksPaging] = useState<CursorPaging>({ first: 10 });
  // const blocks = useQuery<SharedBlocks, SharedBlocksVariables>(QUERY_MANY_SHARED_BLOCKS, {
  //   variables: {
  //     paging: blocksPaging,
  //   },
  //   fetchPolicy: "cache-first",
  // });
  // const handleBlockPageChange = (e: any, page: number) => {
  //   onChangePageCursor(
  //     e,
  //     page,
  //     blocksPageNumber,
  //     !!blocksPaging.first
  //       ? blocksPaging.first
  //       : !!blocksPaging.last
  //       ? blocksPaging.last
  //       : 10,
  //       blocks.data?.sharedBlocks?.pageInfo || ({} as any),
  //     (pageNumber: number, newPaging: CursorPaging) => {
  //       setBlocksPaging(newPaging);
  //       setBlocksPageNumber(pageNumber);
  //     }
  //   );
  // };
  //#endregion

  useEffect(() => {
    prepareDownloadAndAnnexedDocs();
  }, []);

  const prepareDownloadAndAnnexedDocs = async () => {
    try {
      await projectLazyQuery({
        variables: {
          packagesPaging: {
            first: 30,
          },
          slabsPaging: {
            first: 200,
          },
          keyValue: projectShareKeyValue,
        },
        onCompleted(data) {
          // get slabs
          let packagedata = data?.sharedProject.packings?.edges.map((el) => el.node);
          let nopackagedata = data?.sharedProject.slabs?.edges.map((el) => el.node);
          const input = !!nopackagedata ? [...nopackagedata] : [];
          packagedata?.forEach((el) => {
            let slabsArr = el?.slabs?.edges.map((el) => el.node);
            if (slabsArr) {
              slabsArr?.forEach((el_slb) => {
                input.push(el_slb);
              });
            }
          });
          setAllSlabsData(input);

          // get annexDocs
          let packageSlabsWithDocs: CommercialVariety_commercialVariety_annexedDocuments_nodes[] =
            [];

          data?.sharedProject?.packings?.edges.forEach((pckg) => {
            pckg.node.slabs?.edges.forEach((slb) => {
              if (
                slb?.node?.commercial_variety?.annexedDocuments &&
                slb?.node?.commercial_variety?.annexedDocuments?.nodes?.length > 0
              ) {
                packageSlabsWithDocs.push(
                  ...slb?.node?.commercial_variety?.annexedDocuments?.nodes
                );
              }
            });
          });
          data?.sharedProject.slabs?.edges?.forEach((slb) => {
            if (
              slb?.node?.commercial_variety?.annexedDocuments &&
              slb?.node?.commercial_variety?.annexedDocuments?.nodes?.length > 0
            ) {
              packageSlabsWithDocs.push(...slb?.node?.commercial_variety?.annexedDocuments?.nodes);
            }
          });
          setAnnexedDocs(packageSlabsWithDocs);
        },
        onError(error) {
          if (error) throw new Error(error.message);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Project Share | WeScan</title>
      </Helmet>
      <Card style={{ marginBottom: "10px" }}>
        <CardContent>
          <FlexBox flexDirection={"row"} justifyContent="space-between" mb={3}>
            <H2>Shared Project</H2>
            <FlexBox justifyContent={"flex-end"} flexDirection="row">
              {annexedDocs && annexedDocs.length > 0 && (
                <AnnexedDocumentButtonDialog
                  parentBoxSxProps={{ textAlign: "right" }}
                  key="AnnexedDocFileDropZone"
                  onCancel={() => {}}
                  annexedDocs={annexedDocs}
                />
              )}
              <Box ml="1rem">
                <Button
                  disabled={!!!allSlabsData || allSlabsData.length === 0}
                  onClick={() => {
                    allSlabsData &&
                      allSlabsData.length > 0 &&
                      //@ts-ignore
                      downloadProjectV1(allSlabsData, form.description);
                  }}
                  startIcon={
                    loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <CloudDownload
                        color={allSlabsData && allSlabsData.length > 0 ? "primary" : "disabled"}
                      />
                    )
                  }
                >
                  Download Project
                </Button>
              </Box>
            </FlexBox>
          </FlexBox>
          <Grid container maxWidth="xl" justifyContent={"space-between"}>
            <Grid container spacing={3} item md={5} xs={12}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  {project?.loading ? (
                    <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                      <TextField value={""} />
                    </Skeleton>
                  ) : (
                    <TextField
                      id="CodeProject"
                      label="Code"
                      name="code"
                      value={project?.data?.sharedProject?.code}
                      placeholder="Code"
                      disabled
                      variant="outlined"
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  {project?.loading ? (
                    <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                      <TextField value={""} />
                    </Skeleton>
                  ) : (
                    <TextField
                      id="projProject"
                      label="Project"
                      name="project"
                      value={project?.data?.sharedProject?.project}
                      placeholder="Project"
                      disabled
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  {project?.loading ? (
                    <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                      <TextField value={""} />
                    </Skeleton>
                  ) : (
                    <TextField
                      id="descProject"
                      label="Description"
                      name="description"
                      disabled
                      value={project?.data?.sharedProject?.description}
                      placeholder="Description"
                      required
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="outlined">
                {project?.loading ? (
                  <Skeleton
                    component={"div"}
                    variant="rectangular"
                    width={"100%"}
                    height={270}
                    sx={{ marginTop: 2 }}
                  >
                    <TextField value={""} />
                  </Skeleton>
                ) : (
                  <TextField
                    id="obsProject"
                    label="Observation"
                    name="observation"
                    placeholder="Observation"
                    disabled
                    value={project?.data?.sharedProject?.observation}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={11}
                    variant="outlined"
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Tabs
        value={tabValue}
        onChange={(ev, value: ShareProjectTabValues) => setTabValue(value)}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="inherit"
      >
        <Tab
          label={`Slabs (${
            slabsData?.sharedProject.slabs?.totalCount ||
            slabsPreviousData?.sharedProject.slabs?.totalCount ||
            0
          })`}
          value="slab"
        />
        {/* <Tab label={`Blocks (${blocks?.data?.sharedBlocks?.edges?.length || 0})`} value="block" /> */}
        <Tab
          label={`Bundle (${
            packagesData?.sharedProject?.packings?.totalCount ||
            packagesPreviousData?.sharedProject?.packings?.totalCount ||
            0
          })`}
          value="bundle"
        />
      </Tabs>
      {/* {blocks?.data?.sharedBlocks && blocks?.data?.sharedBlocks?.edges?.length > 0 && tabValue === "block" && (
            <>
              <Card>
                <CardContent>
                  <h3 style={{ marginTop: 10 }}>Blocks</h3>
                </CardContent>
              </Card>
              <Grid container spacing={2} mt={1} mb={3}>
                {blocks?.data?.sharedBlocks?.edges?.map((block, key) => {
                  return (
                    <Grid item xs={4} key={key}>
                      <ToShareBlockGalleryComponent key={key} stone={{
                        key: block.node.id,
                        qtd: block.node.slabs?.totalCount || 0,
                        slabs: 
                      }} />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )} */}
      {/* Slabs */}
      {tabValue === "slab" && (
        <Box ref={slabsParentRef}>
          <Card sx={{ mt: 1 }}>
            <CardContent>
              <h3 style={{ marginTop: 10 }}>Slabs without Bundle</h3>
            </CardContent>
          </Card>
          {slabsData &&
          !slabsLoading &&
          !slabsError &&
          slabsData?.sharedProject?.slabs?.edges &&
          slabsData.sharedProject.slabs.edges.length > 0 ? (
            <Grid container spacing={2} mt={1}>
              {slabsData.sharedProject.slabs?.edges.map((stn, key) => (
                <Grid item xs={12} md={4} key={key}>
                  <GallerySlabs key={key} slab={stn.node} keyValue={projectShareKeyValue} />
                </Grid>
              ))}
            </Grid>
          ) : (
            slabs &&
            !slabsLoading && (
              <Box sx={{ alignItems: "center", justifyContent: "center", p: 5, width: "100%" }}>
                <h3
                  style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {slabsError ? slabsError.message : "No Results"}
                </h3>
              </Box>
            )
          )}
          {(slabsLoading || (!!!slabsData && !!slabsPreviousData)) && (
            <Grid container spacing={2} mt={1} height={1200}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((stn, key) => (
                <Grid item xs={12} md={4} key={key}>
                  <Skeleton variant="rectangular" height="100%" />
                </Grid>
              ))}
            </Grid>
          )}
          <Box
            // className={classes.root}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: 10,
            }}
          >
            {!!(
              slabsData?.sharedProject?.slabs?.totalCount ||
              slabsPreviousData?.sharedProject?.slabs?.totalCount
            ) &&
              slabsPaging?.first &&
              (slabsData?.sharedProject?.slabs?.totalCount ||
                slabsPreviousData?.sharedProject?.slabs?.totalCount ||
                0) > slabsPaging?.first && (
                <FlexBox
                  component={"tr"}
                  sx={{ alignItems: "center", justifyContent: "center", p: 5 }}
                >
                  <TablePagination
                    classes={{
                      selectLabel: classes.caption,
                      displayedRows: classes.caption,
                      root: classes.root,
                    }}
                    count={
                      slabsData?.sharedProject?.slabs?.totalCount ||
                      slabsPreviousData?.sharedProject?.slabs?.totalCount ||
                      0
                    }
                    rowsPerPage={slabsPaging.first}
                    page={slabsPageNumber}
                    onPageChange={handleSlabPageChange}
                    onRowsPerPageChange={(event) => {
                      let newLimit = parseInt(event.target.value, 10);
                      setSlabsPaging({ first: newLimit });
                      setSlabsPageNumber(0);
                    }}
                    rowsPerPageOptions={[9, 12, 15, 18]}
                    labelRowsPerPage="Results per page"
                  />
                </FlexBox>
              )}
          </Box>
        </Box>
      )}

      {/* Bundles/Packages */}
      {tabValue === "bundle" && (
        <Box ref={packageParentRef}>
          <Card>
            <CardContent>
              <h3 style={{ marginTop: 10 }}>Packages/Bundles</h3>
            </CardContent>
          </Card>
          <Grid container spacing={2} mt={1}>
            {packagesData &&
            !packagesLoading &&
            !packagesError &&
            packagesData?.sharedProject?.packings?.edges &&
            packagesData?.sharedProject?.packings?.edges?.length > 0
              ? packagesData?.sharedProject?.packings?.edges?.map((pck, key) => {
                  return (
                    <Grid item xs={4} key={key}>
                      <ToSharePackageGalleryComponent
                        key={key}
                        data_package={pck.node}
                        dataSlabs={pck.node.slabs?.edges.map((el) => el.node)}
                        projectKeyValue={projectShareKeyValue}
                      />
                    </Grid>
                  );
                })
              : !packagesLoading && (
                  <Box sx={{ alignItems: "center", justifyContent: "center", p: 5, width: "100%" }}>
                    <h3
                      style={{
                        textAlign: "center",
                        verticalAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {packagesError ? packagesError.message : "No Results"}
                    </h3>
                  </Box>
                )}
            {(packagesLoading || (!!!packagesData && !!packagesPreviousData)) && (
              <Grid container spacing={2} mt={1} height={1200}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((stn, key) => (
                  <Grid item xs={12} md={4} key={key}>
                    <Skeleton variant="rectangular" height="100%" />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          {!!(
            packagesData?.sharedProject?.packings?.totalCount ||
            packagesPreviousData?.sharedProject?.packings?.totalCount
          ) &&
            packagesPaging?.first &&
            (packagesData?.sharedProject?.packings?.totalCount ||
              packagesPreviousData?.sharedProject?.packings?.totalCount ||
              0) > packagesPaging?.first && (
              <FlexBox
                component={"tr"}
                sx={{ alignItems: "center", justifyContent: "center", p: 5 }}
              >
                <TablePagination
                  classes={{
                    selectLabel: classes.caption,
                    displayedRows: classes.caption,
                    root: classes.root,
                  }}
                  count={
                    packagesData?.sharedProject?.packings?.totalCount ||
                    packagesPreviousData?.sharedProject?.packings?.totalCount ||
                    0
                  }
                  rowsPerPage={packagesPaging.first}
                  page={packagesPageNumber}
                  onPageChange={handlePackagePageChange}
                  onRowsPerPageChange={(event) => {
                    let newLimit = parseInt(event.target.value, 10);
                    setPackagesPaging({ first: newLimit });
                    setPackagesPageNumber(0);
                  }}
                  rowsPerPageOptions={[9, 12, 15, 18]}
                  labelRowsPerPage="Results per page"
                />
              </FlexBox>
            )}
        </Box>
      )}
    </Container>
  );
}
