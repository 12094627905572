import { download } from "./utils";

export default async function downloadImage (url: string, extension: string, file_name?: string, 
  resolution: 'Original' | 'Medium' | 'Low' = 'Original', metadata?: object, withMetadata = false): Promise<void> {
    // download original file
    const file = await download(url);
    //Split image name
    const nameSplit = url.split("/");
    // get file name
    const finalName = file_name ? file_name : nameSplit.pop();
    let blobURL = URL.createObjectURL(file);
    let quality = resolution === 'Original' ? 100 : resolution === 'Medium' ? 80 : 70
    
    if(resolution !== 'Original' || !withMetadata){
      const img = new Image()
      img.src = blobURL
  
      img.onload = async () => {
        const [newWidth, newHeight] = calculateSizes(img, resolution);
        // const newLink = await resizeFile(file, newWidth, newHeight, 'JPEG', quality)
        const link = document.createElement('a')
        link.href = img.src;
        link.download = ""+finalName+"";
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)  
      }
    }else {
      const link = document.createElement('a')
      link.href = blobURL;
      link.download = ""+finalName+"";
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) 
    }

    
  };
  
  function calculateSizes(img: HTMLImageElement, resolution: 'Original' | 'Medium' | 'Low') {
    let width = img.width;
    let height = img.height;
  
    // calculate the width and height, constraining the proportions
    if(resolution === 'Medium'){
      width = width * (2/3)
      height = height * (2/3)
    }
    else if (resolution === 'Low'){
      width = width * (1/2)
      height = height * (1/2)
    }
  
    return [width, height];
  }

// const resizeFile = async (file: Blob, maxWidth: number, maxHeight: number, compressFormat: string, quality: number): Promise<string> =>
// new Promise((resolve) => {
//   Resizer.imageFileResizer(
//     file,
//     maxWidth,
//     maxHeight,
//     compressFormat,
//     quality,
//     0,
//     (uri) => {
//       resolve(uri as string);
//     },
//     "base64"
//   );
// });