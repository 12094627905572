import { NetworkStatus, useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ArrowForward,
  Assignment,
  Cancel,
  PlayArrow,
  RemoveRedEye,
  Stop,
  ViewList,
} from "@material-ui/icons";
import { isEqual } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import FlexBox from "../../components/GeneralComponents/FlexBox";
import ImageBox from "../../components/GeneralComponents/ImageBox";
import { H2, H3, H5 } from "../../components/GeneralComponents/Typography";
import SlabGalleryCard, {
  getFinish,
  getMaterial,
  getVariety,
} from "../../components/slabs.components/SlabGalleryCard";
import NewGeneralTableComponent, {
  ICompleteHeadCells,
} from "../../components/Table/NewGeneralTableComponent";
import {
  CreateOneJob,
  CreateOneJobVariables,
  CursorPaging,
  DescState,
  JobFilter,
  JobInput,
  JobsForScannerConsole,
  JobsForScannerConsoleVariables,
  JobsForScannerConsole_jobs_edges_node,
  JobSortFields,
  NextSlabs,
  NextSlabsVariables,
  NextSlabs_nextSlabs_job,
  Scanner,
  ScannerStateEnum,
  ScannerVariables,
  Slabs,
  SlabSortFields,
  SlabsVariables,
  Slabs_slabs_nodes,
  SortDirection,
  SubscribeToScannerOperation,
  SubscribeToScannerOperationVariables,
  SubscribeToScannerUpload,
  SubscribeToScannerUploadVariables,
  UpdateOneJob,
  UpdateOneJobVariables,
} from "../../data/graphQLModel";
import {
  MUTATION_CREATE_ONE_JOB,
  MUTATION_UPDATE_ONE_JOB,
  QUERY_JOBS_FOR_SCANNER_CONSOLE,
  QUERY_NEXT_SLABS,
  QUERY_ONE_SCANNER,
  QUERY_SLABS,
  SCANNER_OPERATION_STATE_SUBSCRIPTION,
  SCANNER_UPLOAD_STATE_SUBSCRIPTION,
} from "../../data/graphQLQueries";
import {
  deleteZerosAndEmptyStringsAndOtherKeys,
  getSlabImageSafe,
  onChangePageCursor,
} from "../../utils/utils";
import CatalogDialogForm from "../Catalog/editBundleDialog";
import JobForm from "../SlabsProcess/Jobs/forms/JobForm";
import { isSlabOperationState, isSlabUploadState } from "./utils";
import swalert from "sweetalert";
import { createJobCheckoutSchema, createJobInitialValues } from "../SlabsProcess/Jobs/create";

const steps = [
  { label: "Scanning", step: ScannerStateEnum.Scanning },
  { label: "Uploading", step: ScannerStateEnum.Uploading },
  { label: "Created Slab", step: ScannerStateEnum.Done },
];

const ScannerConsoleComponent: React.FC<undefined> = () => {
  const history = useHistory();
  const theme = useTheme();
  const { scanner } = useParams<{ scanner: string }>();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  //#region Scanner Region
  const {
    data: scannerData,
    previousData: scannerPreviousData,
    loading: scannerLoading,
    error: scannerError,
  } = useQuery<Scanner, ScannerVariables>(QUERY_ONE_SCANNER, {
    variables: {
      id: !!scanner ? window.atob(scanner) : "",
    },
    skip: !!!scanner || scanner?.trim() === "",
  });
  //#endregion

  //#region Slabs Region
  const [openDetailsDialog, setOpenDetailsDialog] = useState<boolean>(false);
  const [lastSlab, setLastSlab] = useState<Slabs_slabs_nodes>();
  const [slabToCatalog, setSlabToCatalog] = useState<Slabs_slabs_nodes>();
  const toggleCatalogDialog = useCallback(() => {
    lastSlab && setSlabToCatalog({ ...lastSlab });
    setOpenDetailsDialog((openDetailsDialog) => !openDetailsDialog);
  }, [lastSlab]);
  const { loading: lastSlabsLoading, refetch: lastSlabsRefetch } = useQuery<Slabs, SlabsVariables>(
    QUERY_SLABS,
    {
      variables: {
        filter: {
          scan: {
            eq: !!scanner ? window.atob(scanner) : "",
          },
        },
        paging: {
          limit: 1,
        },
        sorting: [
          {
            direction: SortDirection.DESC,
            field: SlabSortFields.createdAt,
          },
        ],
      },
      skip: !!!scanner || scanner?.trim() === "",
      onCompleted(data) {
        if (!!data && !!data?.slabs && data?.slabs?.nodes?.length > 0) {
          setLastSlab(data.slabs.nodes[0]);
        }
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const {
    data: nextSlabsData,
    loading: nextSlabsLoading,
    error: nextSlabsError,
    refetch: nextSlabsRefetch,
  } = useQuery<NextSlabs, NextSlabsVariables>(QUERY_NEXT_SLABS, {
    skip: !!!scanner || scanner?.trim() === "",
    notifyOnNetworkStatusChange: true,
    variables: {
      scanner: !!scanner ? window.atob(scanner) : "",
      next: 3,
    },
  });

  /**
   * What index of NextSlabs should be shown in "Next".
   * @returns Index 1 or 2
   */
  const getIndexOfNextToShow = () => {
    let indexOfNextSlabWithTheSameCodeAsCurrentSlabData =
      currentScannerStateSlabData?.code &&
      nextSlabsData?.nextSlabs &&
      nextSlabsData?.nextSlabs?.findIndex((el) => el.code === currentScannerStateSlabData.code);
    return indexOfNextSlabWithTheSameCodeAsCurrentSlabData === -1
      ? 0
      : !!currentScannerStateSlabData?.code
      ? currentScannerStateSlabData.code === nextSlabsData?.nextSlabs[0]?.code
        ? 1
        : 2
      : 1;
  };

  //#endregion

  //#region Jobs Region
  const [activeJobsFilter, setActiveJobsFilter] = useState<JobFilter>({
    and: [
      {
        descstate: {
          eq: DescState.EXECUTION,
        },
        scan: {
          eq: !!scanner ? window.atob(scanner) : "",
        },
      },
    ],
  });
  const [activeJobsPaging, setActiveJobsPaging] = useState<CursorPaging>({ first: 5 });
  const [activeJobsPageNumber, setActiveJobsPageNumber] = useState<number>(0);

  const {
    data: activeJobsData,
    previousData: activeJobsPreviousData,
    loading: activeJobsLoading,
    error: activeJobsError,
    networkStatus: activeJobsNetworkStatus,
    refetch: activeJobsRefetch,
  } = useQuery<JobsForScannerConsole, JobsForScannerConsoleVariables>(
    QUERY_JOBS_FOR_SCANNER_CONSOLE,
    {
      variables: {
        filter: activeJobsFilter,
        sorting: [
          {
            direction: SortDirection.ASC,
            field: JobSortFields.scheduledAt,
          },
        ],
        paging: activeJobsPaging,
      },
      skip: !!!scanner || scanner?.trim() === "",
      notifyOnNetworkStatusChange: true,
    }
  );

  const [pausedJobsFilter, setPausedJobsFilter] = useState<JobFilter>({
    and: [
      {
        descstate: {
          eq: DescState.STOPPED,
        },
        scan: {
          eq: !!scanner ? window.atob(scanner) : "",
        },
      },
    ],
  });
  const [pausedJobsPaging, setPausedJobsPaging] = useState<CursorPaging>({ first: 5 });
  const [pausedJobsPageNumber, setPausedJobsPageNumber] = useState<number>(0);

  const {
    data: pausedJobsData,
    previousData: pausedJobsPreviousData,
    loading: pausedJobsLoading,
    error: pausedJobsError,
    networkStatus: pausedJobsNetworkStatus,
    refetch: pausedJobsRefetch,
  } = useQuery<JobsForScannerConsole, JobsForScannerConsoleVariables>(
    QUERY_JOBS_FOR_SCANNER_CONSOLE,
    {
      variables: {
        filter: pausedJobsFilter,
        sorting: [
          {
            direction: SortDirection.ASC,
            field: JobSortFields.sequence,
          },
        ],
        paging: pausedJobsPaging,
      },
      skip: !!!scanner || scanner?.trim() === "",
      notifyOnNetworkStatusChange: true,
    }
  );

  /**
   * Update one job mutation, will only be used to update state
   */
  const [mutationUpdateOneJob] = useMutation<UpdateOneJob, UpdateOneJobVariables>(
    MUTATION_UPDATE_ONE_JOB,
    {
      async onCompleted(data, clientOptions) {
        swalert("Job", "Job was Updated", "success");
        // Essentially run resetConsole but without querying for the previous slab again
        setScannerStatePreviousData([]);
        setCurrentScannerStateSlabData(undefined);
        setScannerStateCurrentData(undefined);
        setCurrentScannerStateImageArray(undefined);
        setResetTimer(undefined);
        await activeJobsRefetch();
        await nextSlabsRefetch();
        await pausedJobsRefetch();
      },
      onError(error, clientOptions) {
        console.log(error);
        swalert("Job", `Error updating Job state: ${error.message}`, "warning");
      },
    }
  );

  const [mutationCreateOneJob] = useMutation<CreateOneJob, CreateOneJobVariables>(
    MUTATION_CREATE_ONE_JOB
  );

  async function submitForm(form: JobInput) {
    const numberFields: (keyof CreateOneJobVariables["input"]["job"])[] = ["totalslab"];

    try {
      // remove variables from form
      const input_data = deleteZerosAndEmptyStringsAndOtherKeys(form, numberFields, []);

      await mutationCreateOneJob({
        variables: {
          input: {
            job: input_data,
          },
        },
        onCompleted(data, clientOptions) {
          swalert("Job", "Job was created", "success");
          resetConsole();
        },
        onError(error, clientOptions) {
          console.log(error);
          swalert("Job", `Error creating Job: ${error.message}`, "warning");
        },
      });
    } catch (error: any) {
      console.log(error);
      swalert("Job", `Error creating Job: ${error?.toString()}`, "warning");
    }
  }

  // is viewing jobs dialog control
  const [isViewingJobsTableDialog, setIsViewingJobsTableDialog] = useState<boolean>(false);

  /**
   * Reusable Go To Button for Jobs Table
   * @returns Go to button for jobs table
   */
  const JobsGoToButton = ({ selected }: { selected: JobsForScannerConsole_jobs_edges_node }) => (
    <IconButton
      onClick={() => {
        history.push({
          pathname: `/slabsprocess/jobs/details/` + selected.id,
        });
      }}
    >
      <ArrowForward />
    </IconButton>
  );

  /**
   * Function to Change a Job's status with prompt
   * @param job Job to change status
   */
  const updateJobStatusWithPrompt = (
    job: NextSlabs_nextSlabs_job,
    newState: DescState,
    stateDescription: string
  ) => {
    swalert({
      title: `${stateDescription} Job`,
      text: `Are you sure you want to ${stateDescription?.toUpperCase()} Job "${job.code}"?`,
      icon: "warning",
      //@ts-ignore
      buttons: true,
      dangerMode: true,
    }).then((willCancel: boolean) => {
      if (willCancel) {
        mutationUpdateOneJob({
          variables: {
            input: {
              id: job.id,
              update: {
                descstate: newState,
              },
            },
          },
        });
      }
    });
  };

  /**
   * Reusable PAUSE button for Jobs Table
   * @returns Pause Button for jobs table
   */
  const JobsPauseButton = ({ selected }: { selected: JobsForScannerConsole_jobs_edges_node }) => (
    <IconButton
      color="warning"
      onClick={() => updateJobStatusWithPrompt(selected, DescState.STOPPED, "Pause")}
    >
      <Stop />
    </IconButton>
  );

  /**
   * Reusable RESUME button for Jobs Table
   * @returns Resume Button for jobs table
   */
  const JobsResumeButton = ({ selected }: { selected: JobsForScannerConsole_jobs_edges_node }) => (
    <IconButton
      color="success"
      onClick={() => updateJobStatusWithPrompt(selected, DescState.EXECUTION, "Resume")}
    >
      <PlayArrow />
    </IconButton>
  );

  /**
   * Reusable Component for Active Jobs Table
   * @returns Active Jobs Table
   */
  const ActiveJobsTableComponent = () => {
    return (
      <NewGeneralTableComponent
        tableSubTitle={
          <Grid container>
            {/* Title */}
            <Grid item xs={5}>
              <Typography component={"h5"} style={{ fontSize: 25 }}>
                Active Jobs
              </Typography>
            </Grid>
            {/* Buttons */}
            <Grid item container spacing={1} xs={7}>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    history.push(`/slabsprocess/jobs/create`);
                  }}
                  fullWidth
                  startIcon={<BsPlus fontSize="large" />}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  New
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  onClick={() => {
                    history.push(`/slabsprocess/jobs`);
                  }}
                  startIcon={<ViewList fontSize="large" />}
                  variant="contained"
                >
                  All
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
        tableProps={{
          className: undefined,
        }}
        ignoreEmptyRows
        data={!!activeJobsData?.jobs ? activeJobsData?.jobs?.edges?.map((edge) => edge.node) : []}
        loading={activeJobsLoading || activeJobsNetworkStatus === NetworkStatus.fetchMore}
        error={activeJobsError}
        LoadingComponent={() => (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        onChangePage={(e: any, page: number) => {
          onChangePageCursor(
            e,
            page,
            activeJobsPageNumber,
            !!activeJobsPaging.first
              ? activeJobsPaging.first
              : !!activeJobsPaging.last
              ? activeJobsPaging.last
              : 10,
            activeJobsData?.jobs?.pageInfo || ({} as any),
            (pageNumber: number, newPaging: CursorPaging) => {
              setActiveJobsPaging(newPaging);
              setActiveJobsPageNumber(pageNumber);
            }
          );
        }}
        onRowsPerPageChange={(event) => {
          setActiveJobsPaging({ first: parseInt(event.target.value, 10) });
        }}
        page={activeJobsPageNumber}
        rowsPerPage={
          !!activeJobsPaging.first
            ? activeJobsPaging.first
            : !!activeJobsPaging.last
            ? activeJobsPaging.last
            : 10
        }
        headCells={activeJobsHeadCells}
        totalCount={
          activeJobsData?.jobs?.totalCount || activeJobsPreviousData?.jobs?.totalCount || 0
        }
        handleSort={(field, order) => {
          // ignore
        }}
        paperProps={{
          style: {
            height: "100%",
          },
        }}
        orderByField={""}
        orderDirection={SortDirection.DESC}
        sortableFields={[]}
        key={"jobs"}
        getValues={activeJobsGetRowValues(
          JobsPauseButton,
          JobsGoToButton,
          nextSlabsData?.nextSlabs[0]?.job
        )}
        disableRemove
        disableEdit
        disableDetails
        rowsPerPageOptions={[5, 10, 15, 20]}
        detailsPath={""}
        pathEntity={""}
        disableSelectAll
        disableSelection
      />
    );
  };

  /**
   * Reusable Component for Paused Jobs Table
   * @returns Paused Jobs Table
   */
  const PausedJobsTableComponent = () => {
    return (
      <NewGeneralTableComponent
        tableSubTitle={
          <Grid container>
            {/* Title */}
            <Grid item xs={5}>
              <Typography component={"h5"} style={{ fontSize: 25 }}>
                Paused Jobs
              </Typography>
            </Grid>
          </Grid>
        }
        tableProps={{
          className: undefined,
        }}
        ignoreEmptyRows
        data={!!pausedJobsData?.jobs ? pausedJobsData?.jobs?.edges?.map((edge) => edge.node) : []}
        loading={pausedJobsLoading || pausedJobsNetworkStatus === NetworkStatus.fetchMore}
        error={pausedJobsError}
        LoadingComponent={() => (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        onChangePage={(e: any, page: number) => {
          onChangePageCursor(
            e,
            page,
            pausedJobsPageNumber,
            !!pausedJobsPaging.first
              ? pausedJobsPaging.first
              : !!pausedJobsPaging.last
              ? pausedJobsPaging.last
              : 10,
            pausedJobsData?.jobs?.pageInfo || ({} as any),
            (pageNumber: number, newPaging: CursorPaging) => {
              setPausedJobsPaging(newPaging);
              setPausedJobsPageNumber(pageNumber);
            }
          );
        }}
        onRowsPerPageChange={(event) => {
          setPausedJobsPaging({ first: parseInt(event.target.value, 10) });
        }}
        page={pausedJobsPageNumber}
        rowsPerPage={
          !!pausedJobsPaging.first
            ? pausedJobsPaging.first
            : !!pausedJobsPaging.last
            ? pausedJobsPaging.last
            : 10
        }
        headCells={activeJobsHeadCells}
        totalCount={
          pausedJobsData?.jobs?.totalCount || pausedJobsPreviousData?.jobs?.totalCount || 0
        }
        handleSort={(field, order) => {
          // ignore
        }}
        paperProps={{
          style: {
            height: "100%",
          },
        }}
        orderByField={""}
        orderDirection={SortDirection.DESC}
        sortableFields={[]}
        key={"pausedjobs"}
        getValues={activeJobsGetRowValues(JobsResumeButton, JobsGoToButton)}
        disableRemove
        disableEdit
        disableDetails
        rowsPerPageOptions={[5, 10, 15, 20]}
        detailsPath={""}
        pathEntity={""}
        disableSelectAll
        disableSelection
      />
    );
  };
  //#endregion

  //#region Scanner State Region
  /**
   * Store subscription current state (easier to clean-up)
   */
  const [scannerStateCurrentData, setScannerStateCurrentData] = useState<
    | SubscribeToScannerUpload["subscribeToScannerSlabUploadState"]
    | SubscribeToScannerOperation["subscribeToScannerOperationState"]
  >();
  /**
   * Store all previous data in an array to show messages to the user
   */
  const [scannerStatePreviousData, setScannerStatePreviousData] = useState<
    (
      | SubscribeToScannerUpload["subscribeToScannerSlabUploadState"]
      | SubscribeToScannerOperation["subscribeToScannerOperationState"]
    )[]
  >([]);
  /**
   * Current Slab Data after scan
   */
  const [currentScannerStateSlabData, setCurrentScannerStateSlabData] =
    useState<Slabs_slabs_nodes>();
  /**
   * Current image data
   */
  const [currentScannerStateImageArray, setCurrentScannerStateImageArray] = useState<string[]>();

  /**
   * timer
   */
  const [resetTimer, setResetTimer] = useState<number>();

  /**
   * Subscribe to Scanner Slab Uploads (states are managed by the API)
   */
  const { data: scannerSlabUploadStateData } = useSubscription<
    SubscribeToScannerUpload,
    SubscribeToScannerUploadVariables
  >(SCANNER_UPLOAD_STATE_SUBSCRIPTION, {
    variables: {
      filter: {
        id: {
          eq: !!scanner ? window.atob(scanner) : "",
        },
      },
    },
    skip: !!!scanner || scanner?.trim() === "",
    onData(options) {
      // state publish start date of newest data from the subscription
      let startedAt = options?.data?.data?.subscribeToScannerSlabUploadState?.startedAt;
      // state publish start date of previous/current data from the subscription
      let currenttartedAt = scannerStateCurrentData?.startedAt;

      // if the date is different, then it's a new scan/upload, resetConsole
      if (
        !!startedAt &&
        !!currenttartedAt &&
        !isEqual(new Date(startedAt), new Date(currenttartedAt)) &&
        !!currenttartedAt &&
        isSlabUploadState(scannerStateCurrentData)
      ) {
        resetConsole();
      }
      // set newest data as current data
      setScannerStateCurrentData(options.data.data?.subscribeToScannerSlabUploadState);
    },
  });

  /**
   * Subscribe to Scanner Operation State (states are managed by the Scanner)
   */
  const { data: scannerOperationStateData } = useSubscription<
    SubscribeToScannerOperation,
    SubscribeToScannerOperationVariables
  >(SCANNER_OPERATION_STATE_SUBSCRIPTION, {
    variables: {
      filter: {
        id: {
          eq: !!scanner ? window.atob(scanner) : "",
        },
      },
    },
    skip: !!!scanner || scanner?.trim() === "",
    onData(options) {
      // set newest operation data as current data if upload hasn't started
      if (!!!scannerStateCurrentData || isSlabOperationState(scannerStateCurrentData)) {
        setScannerStateCurrentData(options.data.data?.subscribeToScannerOperationState);
      }
    },
  });

  /**
   * Side effect of subscription data change
   */
  useEffect(() => {
    if (!!scannerStateCurrentData) {
      let scannerState = scannerStateCurrentData;
      let newArray = [...scannerStatePreviousData];
      newArray.push(scannerState);
      setScannerStatePreviousData(newArray);
      if (isSlabUploadState(scannerState) && !!scannerState.slab) {
        setCurrentScannerStateSlabData(scannerState.slab);
      }
      if (
        isSlabUploadState(scannerState) &&
        !!scannerState.imagesArray &&
        scannerState.imagesArray.length > 0
      ) {
        // Make sure images were already uploaded and actually exist (image url received might've been generated before the actual upload of successful)
        scannerState.imagesArray.forEach((img) => {
          fetch(img)
            .then((res) => {
              if (res?.status === 200) {
                setCurrentScannerStateImageArray([
                  ...(currentScannerStateImageArray ? currentScannerStateImageArray : []),
                  img,
                ]);
              }
            })
            .catch((err) => {
              // do nothing
            });
        });
      }
    } else {
      setScannerStatePreviousData([]);
    }
  }, [scannerStateCurrentData]);

  const [resettingConsole, setResettingConsole] = useState<boolean>(false);
  /**
   * Resets subscription data and refetches job, counter and lastslab data
   */
  const resetConsole = async () => {
    setResettingConsole(true);
    try {
      setScannerStatePreviousData([]);
      setCurrentScannerStateSlabData(undefined);
      setScannerStateCurrentData(undefined);
      setCurrentScannerStateImageArray(undefined);
      setResetTimer(undefined);
      await lastSlabsRefetch();
      await activeJobsRefetch();
      await pausedJobsRefetch();
      await nextSlabsRefetch();
    } catch (err) {
      // ignore error, will be handled by another catch, this is used just to make sure "setResettingConsole(false)" runs
    }
    setResettingConsole(false);
  };

  // Run when state is "Done" or "Failed"
  useEffect(() => {
    if (
      scannerSlabUploadStateData?.subscribeToScannerSlabUploadState &&
      scannerSlabUploadStateData.subscribeToScannerSlabUploadState.state === ScannerStateEnum.Done
    ) {
      // reset timer to 30 seconds, after which the console will be reset
      setResetTimer(30);
    } else if (
      scannerSlabUploadStateData?.subscribeToScannerSlabUploadState &&
      scannerSlabUploadStateData.subscribeToScannerSlabUploadState.state === ScannerStateEnum.Failed
    ) {
      // reset console on failure
      resetConsole();
    } else {
      setResetTimer(undefined);
    }
  }, [scannerSlabUploadStateData]);

  useEffect(() => {
    if (typeof resetTimer === "number") {
      if (resetTimer === 0) {
        resetConsole();
      }

      // exit early when we reach 0
      if (!resetTimer) return;

      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
        setResetTimer(resetTimer - 1);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [resetTimer]);

  //#endregion

  return (
    <Grid
      key={"main-grid"}
      container
      direction={!!xl ? "row" : "column"}
      sx={{
        p: 3,
        minHeight: !!window ? window.innerHeight : undefined,
      }}
      spacing={xl ? 2 : 0}
    >
      {/* Active Jobs */}
      <Grid key={"active-jobs-grid"} item xl={3}>
        {xl && (
          <Grid>
            <ActiveJobsTableComponent />
            <PausedJobsTableComponent />
          </Grid>
        )}
        <FlexBox
          sx={{
            width: "100%",
            display: xl ? "none" : "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={!!!lastSlab}
            variant="contained"
            onClick={() => {
              setIsViewingJobsTableDialog(true);
            }}
            startIcon={<RemoveRedEye fontSize="large" />}
          >
            Job Orders (
            {activeJobsData?.jobs?.totalCount || activeJobsPreviousData?.jobs?.totalCount || 0})
          </Button>
          <Dialog
            onClose={() => {
              setIsViewingJobsTableDialog(false);
            }}
            open={isViewingJobsTableDialog}
          >
            <ActiveJobsTableComponent />
            <PausedJobsTableComponent />
          </Dialog>
        </FlexBox>
      </Grid>
      {/* Scanner Activity */}
      <Grid
        key={"scanner-activity-grid"}
        container
        item
        xl={9}
        lg={12}
        spacing={2}
        direction="row"
        sx={{ justifyContent: "space-around" }}
      >
        {/* Last */}
        <Grid key={"last-grid"} item lg={4}>
          <Box key={"last-card-grid"} sx={{ px: 7, pt: 3 }}>
            <Card sx={{ mb: 1 }}>
              <H2 style={{ textAlign: "center" }}>Previous</H2>
            </Card>
            {lastSlabsLoading || !!!lastSlab ? (
              <Skeleton
                component={"div"}
                variant="rectangular"
                width={"100%"}
                sx={{ marginTop: 2 }}
              >
                <SlabGalleryCard
                  slab={{
                    code: "",
                    id: "",
                    height: 1,
                    width: 1,
                    thickness: 1,
                    images: [],
                    variety: null,
                    commercial_finishing: null,
                    commercial_variety: null,
                    finish: null,
                    typematerial: null,
                    packing: null,
                  }}
                />
              </Skeleton>
            ) : (
              <SlabGalleryCard slab={lastSlab} />
            )}
          </Box>
          <Box
            key={"button-previous-catalog-grid"}
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              pb: 3,
            }}
          >
            <Button
              sx={{
                fontSize: "20px",
                width: "75%",
                mt: 1,
              }}
              disabled={!!!lastSlab}
              variant="contained"
              onClick={toggleCatalogDialog}
              startIcon={<Assignment sx={{ mt: 0.5 }} fontSize="large" />}
            >
              Catalog
            </Button>
          </Box>
        </Grid>
        {/* Active */}
        <Grid key={"active-grid"} item lg={4}>
          <Card key={"title-card-active"} sx={{ mb: 1 }}>
            <H2 style={{ textAlign: "center" }}>Active</H2>
          </Card>
          {/* Image */}
          <ImageBox
            className="card"
            sx={{
              backgroundColor: theme.palette.background.paper,
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
            hoverEffect={false}
          >
            {currentScannerStateImageArray ||
            (isSlabUploadState(scannerStateCurrentData) && scannerStateCurrentData?.slab) ? (
              <img
                src={
                  getSlabImageSafe(
                    {
                      images: currentScannerStateImageArray as string[],
                      ...(isSlabUploadState(scannerStateCurrentData)
                        ? scannerStateCurrentData?.slab
                        : {}),
                    },
                    false
                  ) || "/images/imagemissing.png"
                }
              />
            ) : (
              <Box sx={{ backgroundColor: theme.palette.grey[300] }}>
                <img />
                {/* // If Scanning or Uploading, show Circular Progress */}
                {(scannerStateCurrentData?.state === ScannerStateEnum.Scanning ||
                  scannerStateCurrentData?.state === ScannerStateEnum.Uploading) && (
                  <CircularProgress
                    size={40}
                    sx={{ position: "absolute", top: "40%", right: "45%" }}
                  />
                )}
              </Box>
            )}
          </ImageBox>
          {/* Body */}
          <Card key={"body-card-current"}>
            <CardContent>
              <Stepper
                activeStep={steps.findIndex((el) => el.step === scannerStateCurrentData?.state)}
                alternativeLabel
              >
                {steps.map((label) => (
                  <Step key={label.step}>
                    <StepLabel>{label.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {/* On Waiting or Error states, don't show any other info */}
              {scannerStateCurrentData?.state === ScannerStateEnum.Failed ||
                (scannerStateCurrentData?.state === ScannerStateEnum.Waiting && (
                  <FlexBox sx={{ justifyContent: "center" }}>
                    {scannerStateCurrentData?.state === ScannerStateEnum.Waiting ? (
                      <H2 sx={{ mt: 1 }}>Waiting...</H2>
                    ) : (
                      <H2 sx={{ mt: 1 }}>Error</H2>
                    )}
                  </FlexBox>
                ))}
              {!!nextSlabsData?.nextSlabs && !resettingConsole && (
                <Box mt={2}>
                  <H5 color={theme.palette.primary.main}>
                    {/* If current slab code is not defined yet, show next one in line*/}
                    {!!currentScannerStateSlabData?.code
                      ? currentScannerStateSlabData.code
                      : nextSlabsData?.nextSlabs[0]?.code || ""}
                  </H5>
                  {!!nextSlabsData?.nextSlabs[0]?.job && !!nextSlabsData?.nextSlabs[0]?.job?.id && (
                    <>
                      <H3 sx={{ fontWeight: "bold" }}>
                        {getVariety(nextSlabsData?.nextSlabs[0]?.job)}
                      </H3>
                      <H5 color={theme.palette.grey[600]}>
                        Finishing: {getFinish(nextSlabsData?.nextSlabs[0]?.job)}
                      </H5>
                      <H5 color={theme.palette.grey[600]}>
                        Material: {getMaterial(nextSlabsData?.nextSlabs[0]?.job)}
                      </H5>
                      {!scannerStatePreviousData?.length && (
                        <Grid
                          container
                          direction={"row"}
                          xs={12}
                          alignItems="center"
                          justifyContent="space-around"
                          flexDirection={"row"}
                          mt={2}
                        >
                          <Grid item xs={5}>
                            <Button
                              onClick={() => {
                                !!nextSlabsData?.nextSlabs[0]?.job &&
                                  updateJobStatusWithPrompt(
                                    nextSlabsData?.nextSlabs[0]?.job,
                                    DescState.CANCELED,
                                    "Cancel"
                                  );
                              }}
                              fullWidth
                              color="error"
                              variant="contained"
                            >
                              <Cancel sx={{ marginRight: "0.5rem" }} />
                              Cancel Job
                            </Button>
                          </Grid>
                          <Grid item xs={5}>
                            <Button
                              onClick={() => {
                                !!nextSlabsData?.nextSlabs[0]?.job &&
                                  updateJobStatusWithPrompt(
                                    nextSlabsData?.nextSlabs[0]?.job,
                                    DescState.STOPPED,
                                    "Pause"
                                  );
                              }}
                              fullWidth
                              variant="contained"
                              color="warning"
                              sx={{ color: theme.palette.text.primary }}
                            >
                              <Stop sx={{ marginRight: "0.5rem" }} />
                              Pause Job
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                  {!!currentScannerStateSlabData && (
                    <H5 fontWeight="bold" fontSize={17} color={"grey.600"}>
                      {currentScannerStateSlabData?.width ? currentScannerStateSlabData?.width : 0}{" "}
                      X{" "}
                      {currentScannerStateSlabData?.height
                        ? currentScannerStateSlabData?.height
                        : 0}{" "}
                      X{" "}
                      {currentScannerStateSlabData?.thickness
                        ? currentScannerStateSlabData?.thickness
                        : 0}
                    </H5>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
          {typeof resetTimer === "number" && (
            <Box>
              <LinearProgress variant="determinate" value={(resetTimer * 100) / 30} />
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button onClick={() => resetConsole()}>Skip</Button>
              </Box>
            </Box>
          )}
          {/* Scanner Subscription Message Grid */}
          <Grid key={"scanner-messages"} container>
            {/* Map first to create a copy and then reverse */}
            {scannerStatePreviousData
              ?.map((el) => el)
              ?.reverse()
              .slice(0, 3)
              .map((subMsg, ind) => {
                return (
                  <Grid key={`scanner-message-${ind}`} item sx={{ width: "100%", mt: 1 }}>
                    <Alert
                      sx={ind !== 0 ? { mx: 2.5, opacity: "50%" } : undefined}
                      severity={
                        subMsg.state === ScannerStateEnum.Failed
                          ? "error"
                          : subMsg.state === ScannerStateEnum.Done
                          ? "success"
                          : "info"
                      }
                      variant="standard"
                    >
                      {subMsg.stateDescription}
                    </Alert>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
        {/* Next */}
        <Grid key={"next-grid"} item lg={4}>
          <Box sx={{ px: 7, py: 3 }}>
            <Card sx={{ mb: 1 }}>
              <H2 style={{ textAlign: "center" }}>Next</H2>
            </Card>
            {!!nextSlabsLoading ? (
              <Skeleton
                component={"div"}
                variant="rectangular"
                width={"100%"}
                sx={{ marginTop: 2 }}
              >
                <SlabGalleryCard
                  slab={{
                    code: "",
                    id: "",
                    height: 1,
                    width: 1,
                    thickness: 1,
                    images: [],
                    variety: null,
                    commercial_finishing: null,
                    commercial_variety: null,
                    finish: null,
                    typematerial: null,
                    packing: null,
                  }}
                />
              </Skeleton>
            ) : !!nextSlabsData?.nextSlabs[getIndexOfNextToShow()]?.job ? (
              <SlabGalleryCard
                slab={{
                  code: nextSlabsData?.nextSlabs[getIndexOfNextToShow()]?.code,
                  id: "",
                  height: null,
                  width: null,
                  thickness: null,
                  images: [],
                  slabImages: [],
                  commercial_variety:
                    nextSlabsData?.nextSlabs[getIndexOfNextToShow()]?.job?.commercial_variety,
                  commercial_finishing:
                    nextSlabsData?.nextSlabs[getIndexOfNextToShow()]?.job?.commercial_finishing,
                  finish: nextSlabsData?.nextSlabs[getIndexOfNextToShow()]?.job?.finish,
                  variety: nextSlabsData?.nextSlabs[getIndexOfNextToShow()]?.job?.variety,
                  packing: nextSlabsData?.nextSlabs[getIndexOfNextToShow()]?.job?.packing,
                  typematerial: nextSlabsData?.nextSlabs[getIndexOfNextToShow()]?.job?.typematerial,
                }}
                enableDetails={false}
                enableOpenInNewTab={false}
                hoverEffect={false}
                showStock={false}
              />
            ) : (
              <FlexBox sx={{ width: "100%", flexDirection: "column" }}>
                <Card sx={{ width: "100%" }}>
                  <CardContent>
                    <H2 sx={{ textAlign: "center" }}>
                      {nextSlabsData?.nextSlabs[getIndexOfNextToShow()]?.code}
                    </H2>
                  </CardContent>
                </Card>
                {(!!scannerData?.scanner?.id || !!scannerPreviousData?.scanner?.id) && (
                  <JobForm
                    handleCancel={() => {}}
                    initialValues={{
                      ...createJobInitialValues,
                      scan: (!!scannerData?.scanner?.id
                        ? scannerData?.scanner?.id
                        : scannerPreviousData?.scanner?.id) as string,
                    }}
                    validationSchema={createJobCheckoutSchema}
                    submitForm={submitForm}
                    showSimplified
                  />
                )}
              </FlexBox>
            )}
          </Box>
        </Grid>
      </Grid>
      {slabToCatalog && (
        <CatalogDialogForm
          isToCatalog={true}
          setIsToCatalog={(value) => {}}
          onCancel={() => {
            toggleCatalogDialog();
          }}
          onSubmit={async () => {
            toggleCatalogDialog();
            await lastSlabsRefetch();
          }}
          openDetailsDialog={openDetailsDialog}
          toggleDialog={toggleCatalogDialog}
          stones={[slabToCatalog]}
          isToCancelCataloguing={false}
        />
      )}
    </Grid>
  );
};

export default ScannerConsoleComponent;

export const activeJobsHeadCells: ICompleteHeadCells[] = [
  { id: "job", numeric: false, disablePadding: false, label: "Job" },
  { id: "progress", numeric: false, disablePadding: false, label: "Progress" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "", numeric: false, disablePadding: false, label: "" },
];

export const activeJobsGetRowValues = (
  actionElement: React.FC<{ selected: JobsForScannerConsole_jobs_edges_node }>,
  goToElement: React.FC<{ selected: JobsForScannerConsole_jobs_edges_node }>,
  jobInExecution?: JobsForScannerConsole_jobs_edges_node | null
): ((row: JobsForScannerConsole_jobs_edges_node, index?: number) => ICompleteHeadCells[]) => {
  return (row: JobsForScannerConsole_jobs_edges_node, index?: number): ICompleteHeadCells[] => [
    {
      id: row?.description || "",
      numeric: false,
      disablePadding: false,
      label: "Job",
    },
    {
      id: `${row.scannedSlabs || 0}/${row.totalslab || 0}`,
      numeric: false,
      disablePadding: false,
      label: "Progress",
    },
    {
      id:
        row.descstate === DescState.EXECUTION
          ? index === 0 && jobInExecution?.code === row.code
            ? "Executing"
            : "Awaiting"
          : "Paused",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "",
      element: actionElement,
    },
    {
      id: "details",
      numeric: false,
      disablePadding: false,
      label: "",
      element: goToElement,
    },
  ];
};
