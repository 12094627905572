import React, { FormEvent, useEffect, useRef, useState } from "react";
import "date-fns";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
  TextFieldProps,
  CircularProgress,
} from "@material-ui/core";
import { error, warning } from "../../theme/themeColors";
import useDebounce from "../../hooks/useDebounce";
import { MuiThemeProps } from "../../theme/theme";
import { createFilterOptions } from "@material-ui/unstyled";

const filter = createFilterOptions();

export type AutocompleteDataType = "string" | "object";

export interface AutocompleteWithQueryProps {
  items: any[];
  title: string;
  value: string | null | undefined;
  label: string;
  name: string;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  getField?: ([...args]: any) => string;
  AutocompleteProps?: Partial<AutocompleteProps<any, any, any, any, any>>;
  TextFieldProps?: TextFieldProps;
  onClear?: () => void;
  onTextChange: (input: string) => Promise<void>;
  dataType?: AutocompleteDataType;
  continueToLoadResultsInComponent?: boolean;
  onBlur?: (value: string) => void;
  newItem?: any;
  clearTextOnSelect?: boolean;
  enableCreation?: boolean;
  getCreationPrompt?: (value: string) => object;
}

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 308,
  },
  oldFormControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

export default function AutocompleteWithQuery({
  items,
  title,
  value,
  name,
  onChange,
  getField = (value: string) => value,
  AutocompleteProps,
  TextFieldProps,
  onClear,
  onTextChange,
  dataType = "object",
  continueToLoadResultsInComponent = true,
  onBlur,
  newItem,
  label,
  clearTextOnSelect = false,
  enableCreation = false,
  getCreationPrompt,
}: AutocompleteWithQueryProps) {
  const textFieldRef = useRef<TextFieldProps>(null);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const classes = useStyles();

  const handleTextChange = async (val: string) => {
    setIsLoadingResults(true);
    await onTextChange(val);
    setIsLoadingResults(false);
  };

  const getTrueValue = () => {
    return !!value && dataType === "object"
      ? items?.find((el) => el.id === value)
        ? items?.find((el) => el.id === value)
        : null
      : items?.find((el) => el === value)
      ? items?.find((el) => el === value)
      : null;
  };

  const [trueValue, setTrueValue] = useState<any>(getTrueValue());

  // get true value on value prop change
  useEffect(() => {
    let val = getTrueValue();
    // if value exists and there's no true value
    // then it means the value is an ObjectID that was sent from outside this component
    // and not selected in by opening the items list
    if (!!!val && !!value && continueToLoadResultsInComponent) {
      handleTextChange(value);
    }
    // if val exists, set
    else {
      setTrueValue(val);
    }
  }, [value]);

  // make sure to change trueVal if value is an ObjectID and was sent from outside this component
  useEffect(() => {
    if (!!items && items.length > 0 && !!!trueValue && !!value && value !== "new") {
      setTrueValue(getTrueValue());
    }
  }, [items]);

  // make sure to change trueVal if a newItem was set for creation
  useEffect(() => {
    if (!!newItem) {
      setTrueValue(newItem);
    }
  }, [newItem]);

  // query more results on debouncedSearchTerm change (100ms after input)
  useEffect(() => {
    // if debouncedSearchTerm exists, then it means the textinput field isn't empty
    if (!!debouncedSearchTerm && continueToLoadResultsInComponent) {
      handleTextChange(debouncedSearchTerm);
    }
    // if it doesn't exist and the trueVal is null, then it means the field is empty and we should
    // query the first batch of results
    else if (
      (!!!getTrueValue() && !!!value) ||
      (!!!getTrueValue() && !continueToLoadResultsInComponent)
    ) {
      handleTextChange("");
    }
  }, [debouncedSearchTerm]);

  const sortArray = (arr: any[]) => {
    let sorted = arr?.map((el) => el)?.sort((a, b) => -getField(b)?.localeCompare(getField(a)));
    return sorted;
  };

  return (
    <Autocomplete
      className={classes.formControl}
      {...AutocompleteProps}
      loading={isLoadingResults}
      loadingText="Loading..."
      id={"select-" + title}
      onChange={(e, newValue, reason) => {
        let foundItem = items.find((el) => el.id === newValue?.id || el === newValue);
        const eV = {
          target: {
            ...newValue,
            name: name,
            value: dataType === "object" ? newValue?.id : newValue,
            fieldUiValue: `${label}: ${!!foundItem ? getField(foundItem) : newValue}`,
          },
        };
        onChange(eV);
        if (reason === "clear") {
          handleTextChange("");
          onClear && onClear();
          // @ts-ignore
        } else if (clearTextOnSelect && !!textFieldRef.current?.blur) {
          // @ts-ignore
          textFieldRef?.current?.blur();
          handleTextChange("");
        }
      }}
      value={trueValue}
      options={!!items && items.length >= 1 ? sortArray(items) : !!newItem ? [newItem] : []}
      filterSelectedOptions
      clearOnEscape={true}
      filterOptions={
        !!enableCreation && !!getCreationPrompt
          ? (options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (
                params.inputValue !== "" &&
                (!!!filtered || filtered.length === 0) &&
                !isLoadingResults
              ) {
                filtered.push({
                  ...getCreationPrompt(params.inputValue),
                });
              }

              return filtered;
            }
          : undefined
      }
      onBlur={(e) => {
        //@ts-ignore
        !!onBlur && onBlur(e?.target?.value as string);
      }}
      getOptionLabel={(i) => {
        return getField(i);
      }}
      renderInput={(params) => {
        return (
          <TextField
            inputRef={textFieldRef}
            FormHelperTextProps={{
              style: { color: !!TextFieldProps?.error ? error.main : warning.main },
            }}
            label={label}
            {...params}
            {...TextFieldProps}
            InputProps={{
              ...params.InputProps,
              endAdornment: isLoadingResults ? (
                <CircularProgress size={20} />
              ) : (
                params.InputProps.endAdornment
              ),
            }}
            inputProps={{
              ...params.inputProps,
              onChange: (ev: FormEvent<HTMLInputElement>) => {
                params?.inputProps?.onChange && params.inputProps.onChange(ev);
                setSearchTerm(ev.currentTarget.value);
              },
              onBlur: (ev: any) => {
                // onTextChange("");
                params?.inputProps?.onBlur && params.inputProps.onBlur(ev);
              },
            }}
          />
        );
      }}
    />
  );
}
