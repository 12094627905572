import toast from "react-hot-toast";
import { Project_project, Slabs_slabs_nodes } from "../data/graphQLModel";
import { getProject } from "./ApolloClientUtils";
import { downloadAndZip, IURLAndNameInput } from "./ImageDownloadUtils";
import { getImageEntityURLString } from "./utils";

interface downloadProjectProps {
  id: string;
  project: Project_project;
}

export const downloadProject = async ({ id, project }: downloadProjectProps) => {
  toast.loading("Downloading...", { id: "DOWNLOADING_TOAST" });
  const projectEntity = !!project
    ? project
    : await getProject({ id: id, slabsPaging: { first: 200 } });
  if (projectEntity) {
    let slabsWithoutPack: IURLAndNameInput[] | undefined = projectEntity.slabs?.edges?.map(
      (el) => ({
        images: el?.node.slabImages.map((el) => getImageEntityURLString(el, false) as string),
        name: el.node.code,
      })
    );
    let slabsWithPack: IURLAndNameInput[] = [];
    projectEntity.packings?.edges.forEach((pack) => {
      pack.node.slabs?.edges.forEach((el) => {
        slabsWithPack.push({
          images: el?.node?.slabImages.map((el) => getImageEntityURLString(el, false) as string),
          name: el.node.code,
        });
      });
    });
    const res = [...(slabsWithoutPack ? slabsWithoutPack : []), ...slabsWithPack];
    // download images + excel
    await downloadAndZip(res, projectEntity?.project)
      .then(() => {
        toast.success(`Downloaded Project "${projectEntity.project}"`);
      })
      .catch((err: any) => {
        toast.error(`Error while downloading Project: ${err}`);
      });
  }
  toast.dismiss("DOWNLOADING_TOAST");
};

export const downloadProjectV1 = async (
  slabs: Partial<Slabs_slabs_nodes>[],
  projectName: string
) => {
  toast.loading("Downloading...", { id: "DOWNLOADING_TOAST" });
  if (!!slabs) {
    const res: IURLAndNameInput[] | undefined = slabs?.map((el) => ({
      images: el?.slabImages?.map((el) => getImageEntityURLString(el, false) as string) as string[],
      name: el?.code as string,
    }));
    // download images + excel
    await downloadAndZip(res, projectName)
      .then(() => {
        toast.success(`Downloaded Project "${projectName}"`);
      })
      .catch((err: any) => {
        toast.error(`Error while downloading Project: ${err}`);
      });
  }
  toast.dismiss("DOWNLOADING_TOAST");
};
