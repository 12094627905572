import React, { useRef, useState } from "react";
import { Box, Slider, TextField, useTheme, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useLocation } from "react-router-dom";
import "./edit.css";
import { fabric } from "fabric";
import {
  SortDirection,
  ImageColorFilterSortFields,
  Slabs_slabs_nodes,
  ImageColorFilters,
  ImageColorFiltersVariables,
  ImageColorFilters_imageColorFilters_edges_node_valueFilter,
  UpdateOneImageColorFilter,
  UpdateOneImageColorFilterVariables,
  CreateOneImageColorFilter,
  CreateOneImageColorFilterVariables,
  CommercialFinishingFilter,
  CommercialFinishings,
  CommercialFinishingSort,
  CommercialFinishings_commercialFinishings_edges_node,
  CommercialVarieties,
  CommercialVarietiesVariables,
  CommercialVarieties_commercialVarieties_edges_node,
  CommercialVarietySort,
  CursorPaging,
  Slabs_slabs_nodes_slabImages,
} from "../../data/graphQLModel";
import {
  QUERY_MANY_IMAGECOLORFILTERS,
  MUTATION_CREATE_ONE_IMAGECOLORFILTER,
  MUTATION_UPDATE_ONE_IMAGECOLORFILTER,
  QUERY_COMMERCIAL_FINISHINGS,
  QUERY_COMMERCIAL_VARIETIES,
} from "../../data/graphQLQueries";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { H5 } from "../../components/GeneralComponents/Typography";
import { FabricJSCanvasComponent } from "../../components/ImageFilters/FabricJSCanvas";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import { isObjectId, queryValuesForFilter } from "../../utils/ApolloClientUtils";
import FullScreenActivityIndicator from "../../components/GeneralComponents/FullScreenActivityIndicator";
import ImageFilterBar from "../../components/ImageFilters/ImageFilterBar";
import { makeStyles } from "@material-ui/styles";
import { Add, Clear, Delete, Restore, Save } from "@material-ui/icons";
import toast from "react-hot-toast";
import sweetalert from "sweetalert";

export interface EditImageEventTarget extends EventTarget {
  width: number;
  height: number;
}

interface EditImageControls {
  /**
   * Control ID
   */
  id: number;
  /**
   * Control Name
   */
  name: string;
  /**
   * Control Property to apply value to
   */
  property: keyof ImageColorFilters_imageColorFilters_edges_node_valueFilter;
  /**
   * Default value (numeric)
   */
  default: number;
  /**
   * Min. value (numeric)
   */
  min: number;
  /**
   * Max. value (numeric)
   */
  max: number;
  /**
   * Value per step (numeric)
   */
  step: number;
}

export interface EditImageFilter {
  /**
   * ID
   */
  id: string;
  /**
   * Filter Name
   */
  name: string;
  /**
   * Settings values
   */
  valueFilter: ImageColorFilters_imageColorFilters_edges_node_valueFilter;
  /**
   * Commercial Variety Relation
   */
  commercial_variety?: string;
  /**
   * Commercial Finishing Relation
   */
  commercial_finishing?: string;
}

interface EditImageComponentProps {
  /**
   * Selected Slab ID
   */
  slabid: string;
  /**
   * Slab Data
   */
  slab: Slabs_slabs_nodes;
}

const CONTROLS: EditImageControls[] = [
  {
    id: 0,
    name: "Exposure",
    property: "exposure",
    default: 0,
    min: -0.2,
    max: 0.2,
    step: 0.01,
  },
  {
    id: 1,
    name: "Brightness",
    property: "brightness",
    default: 0,
    min: -0.2,
    max: 0.2,
    step: 0.01,
  },
  {
    id: 2,
    name: "Contrast",
    property: "contrast",
    default: 0,
    min: -0.2,
    max: 0.2,
    step: 0.01,
  },
  {
    id: 3,
    name: "Gamma",
    property: "gamma",
    default: 1,
    min: 0.5,
    max: 1.5,
    step: 0.01,
  },
  {
    id: 4,
    name: "Saturation",
    property: "saturation",
    default: 0,
    min: -0.5,
    max: 0.5,
    step: 0.01,
  },
  {
    id: 6,
    name: "Temperature",
    property: "temperature",
    default: 6550,
    min: 4000,
    max: 7000,
    step: 1,
  },
  {
    id: 5,
    name: "Hue",
    property: "hue_rotation",
    default: 0,
    min: -0.05,
    max: 0.05,
    step: 0.0001,
  },
];

const PRESET_FILTERS: EditImageFilter[] = [
  {
    id: "0",
    name: "Original",
    valueFilter: {
      exposure: 0,
      brightness: 0,
      saturation: 0,
      contrast: 0,
      gamma: 1,
      hue_rotation: 0,
      temperature: 6550,
    },
    commercial_finishing: undefined,
    commercial_variety: undefined,
  },
];

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      width: "60%",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      right: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      right: 0,
    },
  },
});

const EditImageComponent = (props: EditImageComponentProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [commercialVarieties, setCommercialVarieties] = useState<
    CommercialVarieties_commercialVarieties_edges_node[]
  >([]);
  const [commercialFinishings, setCommercialFinishings] = useState<
    CommercialFinishings_commercialFinishings_edges_node[]
  >([]);
  const [currentFilter, setCurrentFilter] = useState<EditImageFilter>(
    JSON.parse(JSON.stringify({ ...PRESET_FILTERS[0] }))
  );
  const [filters, setFilters] = useState<EditImageFilter[]>(
    JSON.parse(JSON.stringify(PRESET_FILTERS))
  );

  const [selectedImage, setSelectedImage] = useState<Slabs_slabs_nodes_slabImages>(
    props?.slab?.slabImages[0]
  );

  const handleAutCompleteChange = (e: React.ChangeEvent<any>) => {
    if (e.target.value === undefined) {
      setCurrentFilter({ ...currentFilter, [e.target.name]: undefined });
    } else {
      setCurrentFilter({ ...currentFilter, [e.target.name]: e.target.value });
    }
  };

  const setAllFilters = (data: ImageColorFilters) => {
    let allFilters = JSON.parse(JSON.stringify(PRESET_FILTERS)) as EditImageFilter[];
    let edges = data.imageColorFilters.edges;
    edges.forEach((edge) => {
      let filter = edge.node;
      let newFilter: EditImageFilter = {
        id: filter.id,
        name: filter.name,
        valueFilter: {
          ...filter.valueFilter,
        },
        commercial_finishing: filter.commercial_finishing?.id,
        commercial_variety: filter.commercial_variety?.id,
      };
      allFilters.push(newFilter);
    });
    setFilters(allFilters);
  };

  const canSubmit = () => {
    return (
      !!currentFilter &&
      !!currentFilter?.commercial_variety &&
      currentFilter?.commercial_variety?.trim() !== ""
    );
  };

  const { error, loading, refetch, variables, data } = useQuery<
    ImageColorFilters,
    ImageColorFiltersVariables
  >(QUERY_MANY_IMAGECOLORFILTERS, {
    variables: {
      sorting: [
        {
          direction: SortDirection.DESC,
          field: ImageColorFilterSortFields.createdAt,
        },
      ],
      paging: {
        first: 50,
      },
      filter: {
        inactive: { isNot: true },
      },
    },
    onCompleted(data) {
      setAllFilters(data);
    },
  });

  const [mutationCreateOneColorFilter, { loading: loadingCreateOne, error: errorCreateOne }] =
    useMutation<CreateOneImageColorFilter, CreateOneImageColorFilterVariables>(
      MUTATION_CREATE_ONE_IMAGECOLORFILTER
    );
  const [mutationUpdateOneColorFilter, { loading: loadingUpdateOne, error: errorUpdateOne }] =
    useMutation<UpdateOneImageColorFilter, UpdateOneImageColorFilterVariables>(
      MUTATION_UPDATE_ONE_IMAGECOLORFILTER
    );

  async function deleteImageColorFilter(filterId: string) {
    // Exists in the backend
    if (isObjectId(filterId)) {
      try {
        sweetalert({
          title: "DELETE",
          text: `Are you sure you want to delete this filter?`,
          icon: "warning",
          //@ts-ignore
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete: boolean) => {
          if (willDelete) {
            await mutationUpdateOneColorFilter({
              variables: {
                input: {
                  id: filterId,
                  update: {
                    inactive: true,
                  },
                },
              },
            }).then(async (res) => {
              if (!!res?.errors) {
                toast.error(res.errors.map((el) => el.message).join("; "));
              } else {
                await refetch(variables);
              }
            });
          }
        });
      } catch (err: any) {
        data && setAllFilters(data);
        toast.error(err?.message);
      }
    }
    // Doesn't exist in the backend
    else {
      let newFilters = [...filters].filter((el) => el.id !== filterId);
      setFilters(newFilters);
    }
  }

  async function createImageColorFilter(newFilter: EditImageFilter) {
    await mutationCreateOneColorFilter({
      variables: {
        input: {
          imageColorFilter: {
            name: newFilter.name,
            valueFilter: {
              exposure: newFilter.valueFilter.exposure,
              brightness: newFilter.valueFilter.brightness,
              contrast: newFilter.valueFilter.contrast,
              gamma: newFilter.valueFilter.gamma,
              hue_rotation: newFilter.valueFilter.hue_rotation,
              saturation: newFilter.valueFilter.saturation,
              temperature: newFilter.valueFilter.temperature,
            },
            commercial_finishing: newFilter.commercial_finishing,
            commercial_variety: newFilter.commercial_variety as string,
          },
        },
      },
    })
      .then((result) => {
        if (!!result?.errors) {
          toast.error(result.errors.map((el) => el.message).join("; "));
          data && setAllFilters(data);
          setCurrentFilter(filters[0]);
        }
      })
      .catch((err) => {
        data && setAllFilters(data);
        setCurrentFilter(filters[0]);
        toast.error(err?.message);
      });
  }

  async function updateImageColorFilter(filter: EditImageFilter) {
    let newFilter = {
      name: filter.name,
      settings: filter.valueFilter,
      id: filter.id,
    };

    await mutationUpdateOneColorFilter({
      variables: {
        input: {
          id: filter.id,
          update: {
            name: filter.name,
            valueFilter: {
              exposure: newFilter.settings.exposure,
              brightness: newFilter.settings.brightness,
              contrast: newFilter.settings.contrast,
              gamma: newFilter.settings.gamma,
              hue_rotation: newFilter.settings.hue_rotation,
              saturation: newFilter.settings.saturation,
              temperature: newFilter.settings.temperature,
            },
          },
        },
      },
    })
      .then((result) => {
        if (!!result?.errors) {
          toast.error(result.errors.map((el) => el.message).join("; "));
          data && setAllFilters(data);
          setCurrentFilter(filters[0]);
        }
      })
      .catch((err) => {
        data && setAllFilters(data);
        setCurrentFilter(filters[0]);
        toast.error(err?.message);
      });
  }

  async function saveFilter(filter: EditImageFilter, type: "update" | "create") {
    if (type === "update") {
      updateImageColorFilter(filter);
    } else {
      createImageColorFilter(filter);
    }
    await refetch({ ...variables });
  }

  function changeSettings(newFilter: EditImageFilter) {
    setCurrentFilter(newFilter);
  }

  function handleChange(e: any) {
    let thisCurrentFilter = { ...currentFilter };
    let key =
      e.target.name.toLowerCase() as keyof ImageColorFilters_imageColorFilters_edges_node_valueFilter;
    thisCurrentFilter.valueFilter[key] = Number(e.target.value);
    setCurrentFilter(thisCurrentFilter);
  }

  function resetFilter(e: any) {
    let foundInData = data?.imageColorFilters?.edges?.find((el) => el.node.id === currentFilter.id);
    let forReset: EditImageFilter = foundInData
      ? {
          valueFilter: {
            brightness: foundInData?.node.valueFilter.brightness,
            contrast: foundInData?.node.valueFilter.contrast,
            exposure: foundInData?.node.valueFilter.exposure,
            gamma: foundInData?.node.valueFilter.gamma,
            hue_rotation: foundInData?.node.valueFilter.hue_rotation,
            saturation: foundInData?.node.valueFilter.saturation,
            temperature: foundInData?.node.valueFilter.temperature,
          },
          commercial_finishing: foundInData.node.commercial_finishing?.id,
          commercial_variety: foundInData.node.commercial_variety?.id,
        }
      : JSON.parse(JSON.stringify({ ...PRESET_FILTERS[0] }));
    changeSettings({
      ...forReset,
      id: currentFilter.id,
      name: currentFilter.name,
    });
  }

  function saveImage(image: string) {
    //image transformations have to be repeated in a background canvas with full resolution (6000x4000)
    //because displayed canvas only has 1000x600 and so the saved transformed image would have that resolution
    // var canvas = document.createElement("canvas");
    //@ts-ignore TODO: ERROR
    fabric.filterBackend = fabric.initFilterBackend();
    fabric.filterBackend = new fabric.WebglFilterBackend();

    //fabric.filterBackend = new fabric.WebglFilterBackend();
    //fabric.textureSize = 16384;

    fabric.Image.fromURL(
      image,
      function (img) {
        img.filters = [
          new fabric.Image.filters.Brightness({ brightness: 0 }),
          new fabric.Image.filters.Contrast({ contrast: 0.5 }),
          //@ts-ignore
          new fabric.Image.filters.Gamma({ gamma: [1, 1, 1] }),
          new fabric.Image.filters.Saturation({ saturation: 0 }),
          //@ts-ignore
          new fabric.Image.filters.HueRotation({ rotation: 0 }),
        ];

        img.applyFilters();
        const ext = "webp";
        const base64 = img.toDataURL({
          format: ext,
          enableRetinaScaling: true,
        });
        const link = document.createElement("a");
        link.href = base64;
        link.download = `eraser_example.${ext}`;
        link.click();
      },
      { crossOrigin: "anonymous" }
    );

    // var ctx = canvas.getContext("2d");

    // const img = new Image();
    // img.src = this.props.imageUrl;
    // img.setAttribute("crossOrigin", "");
    // img.onload = () => {
    //   var imgInstance=fabric.Image(img,{crossOrigin:"anonymous"})
    //   var brightness = convertRange(this.state.currentEdit.brightness, [0, 2], [-100, 100]);
    //   var contrast = convertRange(this.state.currentEdit.contrast, [0, 200], [-100, 100]);

    //   canvas.width = img.width;
    //   canvas.height = img.height;

    //   const ext = "png";
    //   const base64 = canvas.toDataURL({
    //     format: ext,
    //     enableRetinaScaling: true,
    //   });
    //   const link = document.createElement("a");
    //   link.href = base64;
    //   link.download = `eraser_example.${ext}`;
    //   link.click();

    //console.log(editedImage.split("data:image/webp;base64,").pop())

    // this.upload(editedImage.split("data:image/webp;base64,").pop());
    // };

    //this.upload(editedImage)
    //canvas.remove()
  }

  const ref_box = useRef<HTMLDivElement>();

  return (
    <Grid direction={"column"} minWidth="100%" minHeight="100%" bgcolor={theme.palette.grey[300]}>
      <Grid xs={12} md={8} item container spacing={10}>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            bgcolor: theme.palette.grey[300],
            borderWidth: 10,
            borderColor: theme.palette.primary.main,
          }}
        >
          <Box ref={ref_box} height={"100%"} width={"100%"}>
            <FabricJSCanvasComponent
              id="react-canvas"
              imageUrl={selectedImage.original}
              filter={currentFilter.valueFilter}
              filterbar={false}
              parentRef={ref_box}
            />
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={5}
          sx={{
            bgcolor: theme.palette.grey[300],
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
          }}
        >
          <Grid item xs={10}>
            <Box>
              <Box
                sx={{
                  mt: 2,
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flex: 8 }}>
                  <TextField
                    label="Filter Name"
                    variant="outlined"
                    fullWidth
                    value={currentFilter.name}
                    onChange={(e) => {
                      setCurrentFilter({ ...currentFilter, name: e.target.value });
                    }}
                  />
                </Box>
              </Box>
              {CONTROLS.map((control) => (
                <Box key={"control-" + control.id} mr={1}>
                  <H5 id="input-slider">{control.name}</H5>
                  <Grid container spacing={1} alignItems="center" columns={13}>
                    <Grid item xs={10}>
                      <Slider
                        disabled={currentFilter.name === "Original"}
                        name={control.property}
                        value={currentFilter.valueFilter[control.property]}
                        aria-labelledby={`input-slider-${control.name}`}
                        step={control.step}
                        min={control.min}
                        max={control.max}
                        onChange={handleChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        disabled={currentFilter.name === "Original"}
                        className={classes.input}
                        fullWidth
                        variant="standard"
                        value={currentFilter.valueFilter[control.property]}
                        size="small"
                        name={control.property}
                        type="number"
                        onChange={handleChange}
                        inputProps={{
                          step: control.step,
                          min: control.min,
                          max: control.max,
                          type: "number",
                          "aria-labelledby": "input-slider",
                        }}
                        InputProps={{
                          style: {},
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Grid container direction="row" spacing={2} mt={1} pr={1}>
                <Grid item xs={12} md={6}>
                  <AutocompleteWithQuery
                    AutocompleteProps={{
                      className: "",
                      disabled: currentFilter.name === "Original",
                    }}
                    items={commercialVarieties}
                    label="Commercial Variety"
                    name="commercial_variety"
                    title="Commercial Variety"
                    onChange={handleAutCompleteChange}
                    value={currentFilter?.commercial_variety}
                    TextFieldProps={{
                      label: "Commercial Variety",
                    }}
                    onTextChange={async (value: string) => {
                      await queryValuesForFilter<
                        CommercialVarietiesVariables["filter"],
                        CursorPaging,
                        CommercialVarietySort,
                        CommercialVarieties
                      >(
                        value,
                        QUERY_COMMERCIAL_VARIETIES,
                        (res) => {
                          let endRes = res?.data?.commercialVarieties?.edges?.map((el) => el.node);
                          setCommercialVarieties(endRes);
                        },
                        {
                          filter: {
                            or: [{ code: { iLike: value } }, { name: { iLike: value } }],
                          },
                          paging: { first: 25 },
                        }
                      );
                    }}
                    getField={(node: CommercialVarieties_commercialVarieties_edges_node) => {
                      return `${node.code} - ${node.name}`;
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutocompleteWithQuery
                    AutocompleteProps={{
                      className: "",
                      disabled: currentFilter.name === "Original",
                    }}
                    items={commercialFinishings}
                    label="Commercial Finishings"
                    name="commercial_finishing"
                    onChange={handleAutCompleteChange}
                    title="Commercial Finishings"
                    value={currentFilter?.commercial_finishing}
                    TextFieldProps={{
                      label: "Commercial Finishings",
                    }}
                    onTextChange={async (value: string) => {
                      await queryValuesForFilter<
                        CommercialFinishingFilter,
                        CursorPaging,
                        CommercialFinishingSort,
                        CommercialFinishings
                      >(
                        value,
                        QUERY_COMMERCIAL_FINISHINGS,
                        (res) => {
                          let endRes = res?.data?.commercialFinishings?.edges?.map((el) => el.node);
                          setCommercialFinishings(endRes);
                        },
                        {
                          filter: { or: [{ code: { iLike: value } }] },
                          paging: { first: 40 },
                        }
                      );
                    }}
                    getField={(node: CommercialFinishings_commercialFinishings_edges_node) => {
                      return `${node.code} - ${node.description.pt}`;
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Grid container alignItems={"center"} flexDirection="column" spacing={2}>
              <Grid item>
                <IconButton
                  disabled={isCreating}
                  onClick={() => {
                    let newfilter = {
                      id: "newFilter",
                      name: "---",
                      valueFilter: { ...PRESET_FILTERS[0].valueFilter },
                    };
                    setFilters([...filters, newfilter]);
                    setCurrentFilter(newfilter);
                    setIsCreating(true);
                  }}
                >
                  <Add fontSize="large" color={isCreating ? "disabled" : "primary"} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  disabled={currentFilter.id === PRESET_FILTERS[0].id || !canSubmit()}
                  onClick={() => {
                    setIsCreating(false);
                    saveFilter(
                      currentFilter,
                      !!data?.imageColorFilters?.edges?.find(
                        (el) => el.node.id === currentFilter.id
                      )
                        ? "update"
                        : "create"
                    );
                  }}
                >
                  <Save
                    fontSize="large"
                    color={
                      currentFilter.id === PRESET_FILTERS[0].id || !canSubmit()
                        ? "disabled"
                        : "primary"
                    }
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  disabled={!isCreating}
                  onClick={() => {
                    setIsCreating(false);
                    let newFilters = filters.slice(0, filters.length - 1);
                    setFilters(newFilters);
                    setCurrentFilter({ ...JSON.parse(JSON.stringify({ ...PRESET_FILTERS[0] })) });
                  }}
                >
                  <Clear fontSize="large" color={!isCreating ? "disabled" : "primary"} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={resetFilter}
                  disabled={currentFilter.id === PRESET_FILTERS[0].id}
                >
                  <Restore
                    fontSize="large"
                    color={currentFilter.id === PRESET_FILTERS[0].id ? "disabled" : "primary"}
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    isObjectId(currentFilter.id) && deleteImageColorFilter(currentFilter.id);
                  }}
                  disabled={!isObjectId(currentFilter.id)}
                >
                  <Delete
                    fontSize="large"
                    color={isObjectId(currentFilter.id) ? "primary" : "disabled"}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        md={4}
        item
        sx={{
          bgcolor: theme.palette.grey[300],
          mt: 10,
        }}
      >
        <ImageFilterBar
          id="filterBar"
          imageUrl={selectedImage.thumbnail_original}
          filters={filters}
          changeSettings={changeSettings}
          deleteFilters={deleteImageColorFilter}
          filtersLoading={loadingCreateOne || loadingUpdateOne || loading}
        />
      </Grid>
      {(loadingCreateOne || loadingUpdateOne || loading) && <FullScreenActivityIndicator />}
    </Grid>
  );
};

export type EditScreenParams = {
  /**
   * Selected Slab ID
   */
  slabid: string;
  /**
   * Slab Data
   */
  slab: Slabs_slabs_nodes;
};

export default function Edit() {
  const location = useLocation<EditScreenParams>();

  if (!!!location?.state?.slab) {
    return <></>;
  }

  return <EditImageComponent slabid={location?.state?.slabid} slab={location?.state?.slab} />;
}
