import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const appInsightsFrontEnd = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.NEXT_PUBLIC_APP_INSIGHTS_KEY,
    maxBatchSizeInBytes: 20000,
    maxBatchInterval: 15000,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    connectionString:
      "InstrumentationKey=c52a3ede-035d-482f-ac54-2eb29720fa54;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    autoTrackPageVisitTime: true,
    enableUnhandledPromiseRejectionTracking: true,
  },
});

appInsightsFrontEnd.loadAppInsights();
appInsightsFrontEnd.clearAuthenticatedUserContext();

const wrapperFunction = (Component: any) => withAITracking(reactPlugin, Component, "Test Name");
export { appInsightsFrontEnd, reactPlugin, wrapperFunction };
