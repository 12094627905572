import { useState } from "react";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  CommercialFinishingFilter,
  CommercialFinishings,
  CommercialFinishingSort,
  CommercialFinishings_commercialFinishings_edges_node,
  CursorPaging,
} from "../../data/graphQLModel";
import { QUERY_COMMERCIAL_FINISHINGS } from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { EntityAutocompleteProps } from "./types";

/**
 * ## Reusable Commercial Finishing Autocomplete Component
 *
 * ### Default Autocomplete property values:
 *
 * #### name: commercial_finishing
 * #### label: Commercial Finishings
 * #### continueToLoadResultsInComponent: true
 * #### shouldFieldBeLocked: false
 *
 */
const CommercialFinishingAutocomplete = ({
  errors,
  continueToLoadResultsInComponent = true,
  shouldFieldBeLocked = false,
  helperText,
  value,
  onChange,
  ...props
}: EntityAutocompleteProps) => {
  const [commercialFinishings, setCommercialFinishings] = useState<
    CommercialFinishings_commercialFinishings_edges_node[]
  >([]);

  return (
    <AutocompleteWithQuery
      onChange={onChange}
      value={value}
      items={commercialFinishings}
      label="Commercial Finishings"
      name="commercial_finishing"
      title="Commercial Finishings"
      TextFieldProps={{
        error: !!errors,
        helperText: helperText,
        label: "Commercial Finishings",
        disabled: shouldFieldBeLocked,
      }}
      onTextChange={async (value: string) => {
        await queryValuesForFilter<
          CommercialFinishingFilter,
          CursorPaging,
          CommercialFinishingSort,
          CommercialFinishings
        >(
          value,
          QUERY_COMMERCIAL_FINISHINGS,
          (res) => {
            let endRes = res?.data?.commercialFinishings?.edges?.map((el) => el.node);
            setCommercialFinishings(endRes);
          },
          {
            filter: { or: [{ code: { iLike: value } }] },
            paging: { first: 40 },
          }
        );
      }}
      getField={(node: CommercialFinishings_commercialFinishings_edges_node) => {
        return `${node.code} - ${node.description.pt}`;
      }}
      {...props}
      AutocompleteProps={{
        disabled: shouldFieldBeLocked,
        ...props?.AutocompleteProps,
      }}
    />
  );
};

export default CommercialFinishingAutocomplete;
