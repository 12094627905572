import { useState } from "react";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  CursorPaging,
  Varieties,
  Varieties_varieties_edges_node,
  VarietyFilter,
  VarietySort,
} from "../../data/graphQLModel";
import { QUERY_VARIETIES } from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { EntityAutocompleteProps } from "./types";

/**
 * ## Reusable Variety Autocomplete Component
 *
 * ### Default Autocomplete property values:
 *
 * #### name: variety
 * #### label: System Variety
 * #### continueToLoadResultsInComponent: false
 * #### shouldFieldBeLocked: false
 *
 */
const VarietyAutocomplete = ({
  errors,
  continueToLoadResultsInComponent = false,
  shouldFieldBeLocked = false,
  helperText,
  value,
  onChange,
  ...props
}: EntityAutocompleteProps) => {
  const [varieties, setVarieties] = useState<Varieties_varieties_edges_node[]>([]);

  return (
    <AutocompleteWithQuery
      onChange={onChange}
      value={value}
      items={varieties}
      label="System Variety"
      name="variety"
      title="System Variety"
      TextFieldProps={{
        error: !!errors,
        helperText: helperText,
        label: "System Variety",
        disabled: shouldFieldBeLocked,
      }}
      continueToLoadResultsInComponent={continueToLoadResultsInComponent}
      onTextChange={async (value: string) => {
        await queryValuesForFilter<VarietyFilter, CursorPaging, VarietySort, Varieties>(
          value,
          QUERY_VARIETIES,
          (res) => {
            let endRes = res?.data?.varieties?.edges?.map((el) => el.node);
            setVarieties(endRes);
          },
          {
            filter: {
              or: [{ code: { iLike: value } }, { normativedesig: { iLike: value } }],
            },
            paging: { first: 200 },
          }
        );
      }}
      getField={(node: Varieties_varieties_edges_node) => {
        return `${node.code} - ${node.normativedesig}`;
      }}
      {...props}
      AutocompleteProps={{
        disabled: shouldFieldBeLocked,
        ...props?.AutocompleteProps,
      }}
    />
  );
};

export default VarietyAutocomplete;
