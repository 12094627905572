import { NetworkStatus, useQuery } from "@apollo/react-hooks";
import { Box, Container, IconButton, LinearProgress } from "@material-ui/core";
import { ArrowForward, Visibility } from "@material-ui/icons";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import NewGeneralTableComponent from "../../../components/Table/NewGeneralTableComponent";
import NewTablePageHeaderComponent from "../../../components/Table/NewTablePageHeaderComponent";
import {
  CursorPaging,
  ProjectFilter,
  ProjectGallery,
  ProjectGalleryVariables,
  Projects,
  ProjectSort,
  ProjectSortFields,
  ProjectsVariables,
  SortDirection,
} from "../../../data/graphQLModel";
import { QUERY_MANY_PROJECTS_GALLERY } from "../../../data/graphQLQueries";
import { onChangePageCursor } from "../../../utils/utils";
import {
  getProjectRowsvaluesDefault,
  getProjectRowsvaluesWithAction,
  projectHeadCellsDefault,
  projectHeadCellsWithAction,
} from "./utils";

const DEFAULT_LIMIT = 10;
const DEFAULT_SORTING: ProjectSort[] = [
  { direction: SortDirection.DESC, field: ProjectSortFields.createdAt },
];
const DEFAULT_PAGING: CursorPaging = { first: DEFAULT_LIMIT };

export default function ProjectsList() {
  const history = useHistory();

  const [filter, setFilter] = useState<ProjectFilter>({});
  const [paging, setPaging] = useState<CursorPaging>(DEFAULT_PAGING);
  const [sorting, setSorting] = useState<ProjectSort[]>(DEFAULT_SORTING);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { loading, error, data, networkStatus } = useQuery<ProjectGallery, ProjectGalleryVariables>(
    QUERY_MANY_PROJECTS_GALLERY,
    {
      variables: {
        paging,
        sorting,
        filter,
      },
    }
  );

  return (
    <>
      <Helmet>
        <title>Projects | WeScan</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ mt: 10 }}>
        <NewTablePageHeaderComponent
          entityNamePlural={"Projects"}
          pathEntity={"/customerstables/projects"}
          buttonTitle="New Project"
          gridProps={{ sx: { mb: 2, mt: 2 } }}
        />
        <NewGeneralTableComponent
          data={!!data?.projects ? data?.projects?.edges?.map((edge) => edge.node) : []}
          loading={loading || networkStatus === NetworkStatus.fetchMore}
          error={error}
          LoadingComponent={() => (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          onChangePage={(e: any, page: number) => {
            onChangePageCursor(
              e,
              page,
              pageNumber,
              !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
              data?.projects?.pageInfo || ({} as any),
              (pageNumber: number, newPaging: CursorPaging) => {
                setPaging(newPaging);
                setPageNumber(pageNumber);
              }
            );
          }}
          onRowsPerPageChange={(event) => {
            setPaging({ first: parseInt(event.target.value, 10) });
          }}
          page={pageNumber}
          rowsPerPage={!!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT}
          headCells={projectHeadCellsWithAction}
          totalCount={data?.projects?.totalCount || 0}
          handleSort={(field, order) => {
            setSorting([{ field: field as ProjectSortFields, direction: order }]);
          }}
          orderByField={sorting[0].field}
          orderDirection={sorting[0].direction}
          sortableFields={Object.values(ProjectSortFields)}
          key={"proj"}
          getValues={getProjectRowsvaluesWithAction(({ selected }) => (
            <IconButton
              onClick={() => {
                history.push({
                  pathname: `/customerstables/projects/detailsproject/` + btoa(selected.id),
                });
              }}
            >
              <ArrowForward />
            </IconButton>
          ))}
          disableSelectAll
          disableMultipleSelection
          PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
          tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          editPath={"/customerstables/projects/editproject"}
          pathEntity={"/customerstables/projects"}
          deletePath={"/customerstables/projects/delete"}
          detailsPath={"/customerstables/projects/detailsproject"}
          base64OrDataOrIdOnDelete={"Data"}
        />
      </Container>
    </>
  );
}
