import React, { useEffect, useState } from "react";
import { EditImageFilter } from "../../pages/editimages";
import FilterImage from "./FilterImage";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";
import { H2 } from "../GeneralComponents/Typography";
import { Box } from "@material-ui/system";
import Carousel from "../Carousel/Carousel";
import useWindowSize from "../../hooks/useWindowSize";
import { roundTo } from "../../utils/utils";

interface ImageFilterBarProps {
  /**
   * Component ID
   */
  id: string;
  /**
   * Image URL
   */
  imageUrl: string;
  /**
   * Applied Filters
   */
  filters: EditImageFilter[];
  /**
   * Function to change settings
   */
  changeSettings: (settings: EditImageFilter) => void;
  /**
   * Funtion to delete filters
   */
  deleteFilters: (filterId: string) => Promise<void>;
  /**
   * Filters loading
   */
  filtersLoading: boolean;
}

const NUMBER_OF_SLIDES = 8;

const ImageFilterBar = (props: ImageFilterBarProps) => {
  const width = useWindowSize();
  const [newFilterArray, setNewFilterArray] = useState<any[]>();

  useEffect(() => {
    if (!!props?.filters && !props.filtersLoading) {
      let newLength =
        props.filters.length > NUMBER_OF_SLIDES
          ? props.filters.length % NUMBER_OF_SLIDES === 0
            ? props.filters.length
            : roundTo(props.filters.length, NUMBER_OF_SLIDES)
          : props.filters.length;
      let newArray = new Array<(typeof props.filters)[0]>(newLength).fill(
        { ...props.filters[0], id: "999" },
        0,
        newLength
      );
      props.filters.forEach((flt, ind) => {
        newArray[ind] = flt;
      });
      setNewFilterArray(newArray);
    }
  }, [props.filters, props.filtersLoading]);

  return (
    <Box maxWidth={width}>
      <H2>Filters</H2>
      <Carousel
        totalSlides={newFilterArray?.length || 0}
        visibleSlides={NUMBER_OF_SLIDES}
        infinite={false}
        hasMasterSpinner={props.filtersLoading}
        dotColor="black"
        showDots
        dotGroupMarginTop="10px"
        step={NUMBER_OF_SLIDES}
      >
        {!props?.filtersLoading &&
          newFilterArray?.map((filter, ind) => {
            return (
              <Box
                key={filter.id + ind + ""}
                sx={{ p: 1 }}
                style={{
                  borderWidth: 10,
                  borderColor: "blue",
                  borderTopWidth: 2,
                }}
              >
                {filter.id === "999" || filter.id === "1" || filter.id === "0" ? null : (
                  <IconButton
                    sx={{ position: "absolute", top: 0, zIndex: 100, right: "2rem" }}
                    aria-label="delete"
                    color="primary"
                    onClick={() => props.deleteFilters(filter.id)}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                {filter.id !== "999" ? (
                  <FilterImage
                    key={filter.id}
                    imageUrl={props.imageUrl}
                    filter={filter}
                    changeSettings={props.changeSettings}
                  />
                ) : (
                  <Box></Box>
                )}
              </Box>
            );
          })}
      </Carousel>
    </Box>
  );
};

export default ImageFilterBar;
