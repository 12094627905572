/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import swalert from "sweetalert";
import { notLoadingComponent } from "../../actions/isLoadingActions";
import Button from "@material-ui/core/Button";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Save, Cancel, Close, DoubleArrow } from "@material-ui/icons";
import {
  Grid,
  Box,
  Alert,
  ToggleButton,
  DialogActions,
  DialogProps,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography,
  IconButton,
} from "@material-ui/core";
import { copyObject, getNumberFromEnd, getSlabImageSafe, getThumbnail } from "../../utils/utils";
import { MuiThemeProps } from "../../theme/theme";
import {
  BlockModel,
  BlockModelFilter,
  BlockModelSort,
  BlockModelsToFilter,
  BlockModelsToFilter_blockModels_edges_node,
  BlockModels_blockModels_edges_node,
  BlockModelVariables,
  BundleCreation,
  CatalogManySlabs,
  CatalogManySlabsVariables,
  Categories,
  CategoriesVariables,
  Categories_categories_edges_node,
  CategorySort,
  CommercialFinishingFilter,
  CommercialFinishings,
  CommercialFinishingSort,
  CommercialFinishings_commercialFinishings_edges_node,
  CommercialVarieties,
  CommercialVarietiesVariables,
  CommercialVarieties_commercialVarieties_edges_node,
  CommercialVarietySort,
  CursorPaging,
  FinishingFilter,
  Finishings,
  FinishingSort,
  Finishings_finishings_edges_node,
  ImageColorFilters,
  ImageColorFiltersVariables,
  MaterialTypeFilter,
  MaterialTypes,
  MaterialTypeSort,
  MaterialTypes_materialTypes_edges_node,
  PackingFilter,
  Packings,
  PackingSort,
  Packings_packings_edges_node,
  ProjectFilter,
  Projects,
  ProjectSort,
  Projects_projects_edges_node,
  Slabs,
  SlabSortFields,
  SlabsVariables,
  Slabs_slabs_nodes,
  SortDirection,
  StoneEdgeFilter,
  StoneEdges,
  StoneEdgeSort,
  StoneEdges_stoneEdges_edges_node,
  StoneModelFilter,
  StoneModels,
  StoneModelSort,
  StoneModels_stoneModels_edges_node,
  Varieties,
  Varieties_varieties_edges_node,
  VarietyFilter,
  VarietySort,
} from "../../data/graphQLModel";
import Carousel from "../../components/Carousel/Carousel";
import {
  MUTATION_CATALOG_SLABS,
  QUERY_BLOCKS_TO_FILTER,
  QUERY_CATEGORIES,
  QUERY_COMMERCIAL_FINISHINGS,
  QUERY_COMMERCIAL_VARIETIES,
  QUERY_FINISHINGS,
  QUERY_MANY_IMAGECOLORFILTERS,
  QUERY_MANY_PACKINGS,
  QUERY_MANY_PROJECTS,
  QUERY_MATERIALTYPES,
  QUERY_ONE_BLOCKMODEL,
  QUERY_SLABS,
  QUERY_STONE_EDGES,
  QUERY_STONE_MODELS,
  QUERY_VARIETIES,
} from "../../data/graphQLQueries";
import { apolloClient } from "../../services_v2/apollo.graphql";
import { H4 } from "../../components/GeneralComponents/Typography";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { isNumeric } from "../utils/utils";
import FlexBox from "../../components/GeneralComponents/FlexBox";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as yup from "yup";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import FullScreenActivityIndicator from "../../components/GeneralComponents/FullScreenActivityIndicator";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

interface ICatalogForm {
  code: string;
  prefix?: string;
  start?: number;
  height: number;
  width: number;
  thickness: number;
  extheight: number;
  extwidth: number;
  extthickness: number;
  stkheight: number;
  stkwidth: number;
  stkthickness: number;
  purchaseheight: number;
  purchasewidth: number;
  purchasethickness: number;
  category: string | null | undefined;
  typematerial: string | null | undefined;
  finish: string | null | undefined;
  project: string | null | undefined;
  variety: string | null | undefined;
  block: string | null | undefined;
  packing: string | null | undefined;
  commercial_finishing: string | null | undefined;
  commercial_variety: string | null | undefined;
  stone_model: string | null | undefined;
  stone_edge: string | null | undefined;
}

const checkoutSchema = (
  isMultiple: boolean,
  isToCancelCataloguing: boolean,
  minStart: number,
  prefixCannotBeEqual: string,
  shouldHavePrefix: boolean
) =>
  yup.object().shape({
    code: isMultiple ? yup.string() : yup.string().nullable().required("Required"),
    prefix:
      shouldHavePrefix && isMultiple
        ? yup
            .string()
            .required("Prefix is a required field if you intend to change Slabs' codes")
            .notOneOf([prefixCannotBeEqual])
        : yup.string().notOneOf([prefixCannotBeEqual]),
    start: yup.number().when("prefix", {
      // verifies if prefix exists
      is: (prefix: string) => {
        return (
          !!prefix &&
          typeof prefix === "string" &&
          prefix.trim() !== "" &&
          isMultiple &&
          shouldHavePrefix
        );
      },
      // true
      then: () => yup.number().min(minStart).required(),
      // false
      otherwise: () => yup.number().min(minStart),
    }),
    height: yup.number(),
    width: yup.number(),
    thickness: yup.number(),
    extheight: yup.number(),
    extwidth: yup.number(),
    extthickness: yup.number(),
    stkheight: yup.number(),
    stkwidth: yup.number(),
    stkthickness: yup.number(),
    purchaseheight: yup.number(),
    purchasewidth: yup.number(),
    purchasethickness: yup.number(),
    category: yup.string().nullable(),
    typematerial: yup.string().nullable(),
    finish: yup.string().nullable(),
    project: yup.string().nullable(),
    variety: yup.string().nullable(),
    blocknumber: isToCancelCataloguing
      ? yup.string().nullable().required("Required")
      : yup.string().nullable(),
    packing: yup.string().nullable(),
    erp_id: yup.string().nullable(),
    commercial_finishing: yup.string().nullable(),
    commercial_variety: yup.string().nullable(),
    stone_model: yup.string().nullable(),
    stone_edge: yup.string().nullable(),
  });

interface CatalogDialogFormProps {
  stones: Slabs_slabs_nodes[];
  isToCatalog: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  isToCancelCataloguing: boolean;
  DialogProps?: Partial<DialogProps>;
  toggleDialog: () => void;
  openDetailsDialog: boolean;
  setIsToCatalog: (value: React.SetStateAction<boolean>) => void;
}

type FormInfoKeysType = {
  [key in keyof Partial<ICatalogForm>]: { warning: string; disable?: boolean };
};
type FormInfoType = { [keys in keyof Partial<ICatalogForm>]: FormInfoKeysType };

export default function CatalogDialogForm({
  stones,
  onSubmit,
  onCancel,
  isToCatalog,
  DialogProps,
  toggleDialog,
  openDetailsDialog,
  setIsToCatalog,
  isToCancelCataloguing = false,
}: CatalogDialogFormProps) {
  // control var to check if user wants to use the information
  const [useFirstSlabInfo, setUseFirstSlabInfo] = useState<boolean>(true);

  // number to assign as start number in
  const [minBundleCatalogNumber, setMinBundleCatalogNumber] = useState<number>(1);

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme<MuiThemeProps>();

  //#region form options data
  const [varieties, setVarieties] = useState<Varieties_varieties_edges_node[]>([]);
  const [finishings, setFinishings] = useState<Finishings_finishings_edges_node[]>([]);
  const [commercialVarieties, setCommercialVarieties] = useState<
    CommercialVarieties_commercialVarieties_edges_node[]
  >([]);
  const [materialTypes, setMaterialTypes] = useState<MaterialTypes_materialTypes_edges_node[]>([]);
  const [commercialFinishings, setCommercialFinishings] = useState<
    CommercialFinishings_commercialFinishings_edges_node[]
  >([]);
  const [categories, setCategories] = useState<Categories_categories_edges_node[]>([]);
  const [stoneModels, setStoneModels] = useState<StoneModels_stoneModels_edges_node[]>([]);
  const [stoneEdges, setStoneEdges] = useState<StoneEdges_stoneEdges_edges_node[]>([]);
  const [blocks, setBlocks] = useState<BlockModelsToFilter_blockModels_edges_node[]>([]);
  const [packages, setPackages] = useState<Packings_packings_edges_node[]>([]);
  const [projects, setProjects] = useState<Projects_projects_edges_node[]>([]);

  const [newBlockToCreate, setNewBlockToCreate] = useState<
    BlockModelsToFilter_blockModels_edges_node | undefined
  >();
  //#endregion

  const [mutationCatalogSlabs] = useMutation<CatalogManySlabs, CatalogManySlabsVariables>(
    MUTATION_CATALOG_SLABS
  );

  /**
   * Object with warning info about a field and which field's behaviour was responsible for the warning
   */
  const [formInfo, setFormInfo] = useState<FormInfoType>();

  // block states
  const [isLoadingBlockInfo, setIsLoadingBlockInfo] = useState<boolean>(false);
  const [isPossibleToUseBlockOnSlabCode, setIsPossibleToUseBlockOnSlabCode] =
    useState<boolean>(false);
  const [isUsingBlockOnSlabCode, setIsUsingBlockOnSlabCode] = useState<boolean>(false);
  const [prefixToBlock, setPrefixToBlock] = useState<string>();

  /**
   * Function that return the next Number to Start
   * @param blockCode Form BLock's code
   * @returns next Number to Start
   */
  const getStartNumber = async (blockCode: string): Promise<void> => {
    const current = await apolloClient
      .query<Slabs, SlabsVariables>({
        query: QUERY_SLABS,
        variables: {
          filter: {
            block: { eq: blockCode },
          },
          sorting: [
            {
              direction: SortDirection.DESC,
              field: SlabSortFields.updatedAt,
            },
          ],
          paging: { limit: 1 },
        },
      })
      .then((res) => {
        if (res?.data?.slabs?.nodes.length > 0) {
          let samePrefix = res.data.slabs.nodes[0].code?.split("_")?.shift() == form.prefix;
          if (samePrefix) {
            return getNumberFromEnd(res.data.slabs.nodes[0].code) || 0;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      })
      .catch((err) => {
        return 0;
      });

    setFieldValue("start", current + 1);
    setMinBundleCatalogNumber(current + 1);
  };

  /**
   * Find block info
   * @param block Block ID
   */
  const findBlockCatalogInfo = async (
    block: string | null | undefined,
    formInfo: FormInfoType | undefined
  ) => {
    if (!!block && block.trim() !== "N/A" && block.trim() !== "") {
      setIsLoadingBlockInfo(true);
      let newFormInfo: FormInfoType = { ...formInfo };
      // first try: get blockmodel info
      await apolloClient
        .query<BlockModel, BlockModelVariables>({
          query: QUERY_ONE_BLOCKMODEL,
          variables: {
            id: block,
          },
        })
        .then((res) => {
          const { data } = res;
          const blockModelData = data?.blockModel;
          if (!!blockModelData) {
            let toChangeKeys: (keyof BlockModels_blockModels_edges_node)[] = [
              "materialtype",
              "variety",
              "commercial_variety",
            ];
            let toApplyKeys: (keyof ICatalogForm)[] = [
              "typematerial",
              "variety",
              "commercial_variety",
            ];

            toChangeKeys.forEach((el, ind) => {
              let elementBlock = blockModelData?.[el] as { [key: string]: any; id: string };
              if (elementBlock?.id) {
                setFieldValue(toApplyKeys[ind], elementBlock?.id);
                let toAdd: FormInfoKeysType = {
                  block: { warning: `From Block ${blockModelData.tenantCode}`, disable: true },
                };
                newFormInfo = {
                  ...newFormInfo,
                  [toApplyKeys[ind]]: toAdd,
                };
              }
            });
          }
        })
        .catch((err) => {});
      setFormInfo(newFormInfo);
      setIsLoadingBlockInfo(false);
      await setFieldValue("category", categories?.find((ctgr) => ctgr.code === "SL")?.id);
    }
  };

  const getInitialValues = (): ICatalogForm => {
    const isBundle = stones.length > 1;
    const firstStone = stones[0];
    return {
      code: isBundle ? "" : firstStone.code,
      height: isBundle ? 0 : firstStone.height || 0,
      width: isBundle ? 0 : firstStone.width || 0,
      thickness: isBundle ? 0 : firstStone.thickness || 0,
      extheight: isBundle ? 0 : firstStone.extheight || 0,
      extwidth: isBundle ? 0 : firstStone.extwidth || 0,
      extthickness: isBundle ? 0 : firstStone.extthickness || 0,
      stkheight: isBundle ? 0 : firstStone.stkheight || 0,
      stkwidth: isBundle ? 0 : firstStone.stkwidth || 0,
      stkthickness: isBundle ? 0 : firstStone.stkthickness || 0,
      purchaseheight: isBundle ? 0 : firstStone.purchaseheight || 0,
      purchasewidth: isBundle ? 0 : firstStone.purchasewidth || 0,
      purchasethickness: isBundle ? 0 : firstStone.purchasethickness || 0,
      category: firstStone.category?.id || null,
      typematerial: firstStone.typematerial?.id || null,
      finish: firstStone.finish?.id || null,
      project: firstStone.project?.id || null,
      variety: firstStone.variety?.id || null,
      block: firstStone?.block?.id || null,
      packing: firstStone.packing?.id || null,
      commercial_finishing: firstStone.commercial_finishing?.id || null,
      commercial_variety: firstStone.commercial_variety?.id || null,
      stone_model: firstStone.stone_model?.id || null,
      stone_edge: firstStone.stone_edge?.id || null,
      prefix: undefined,
      start: 1,
    };
  };

  const {
    values: form,
    dirty,
    errors,
    handleChange: handleChangesFormik,
    setValues: setForm,
    handleSubmit,
    setFieldValue,
    resetForm,
    setFieldError,
    validateField,
  } = useFormik({
    initialValues: getInitialValues(),
    onSubmit: submitForm,
    validationSchema: checkoutSchema(
      stones?.length > 1,
      isToCancelCataloguing,
      minBundleCatalogNumber,
      prefixToBlock || "",
      isUsingBlockOnSlabCode
    ),
  });

  const prefixRef = useRef<TextFieldProps>(null);

  const checkPrefix = (prefix: string | null | undefined) => {
    if (!!prefix) {
      let found = stones.find((stn) => stn.code.startsWith(prefix as string));
      if (!!found && !!document && document?.activeElement?.id !== prefixRef?.current?.id) {
        setPrefixToBlock(prefix);
        setIsPossibleToUseBlockOnSlabCode(false);
      } else {
        setPrefixToBlock("");
        setIsPossibleToUseBlockOnSlabCode(true);
      }
      if (prefix == form.block) {
        getStartNumber(form.block);
      } else {
        setMinBundleCatalogNumber(1);
        setFieldValue("start", 1);
        setFieldError("start", undefined);
      }
    } else {
      setPrefixToBlock("");
      setIsPossibleToUseBlockOnSlabCode(true);
    }
  };

  useEffect(() => {
    let prefix = form.prefix;
    checkPrefix(prefix);
  }, [form.prefix]);

  useEffect(() => {
    if (isPossibleToUseBlockOnSlabCode) {
      validateField("prefix");
      validateField("start");
      if (!!form?.block) {
        setIsUsingBlockOnSlabCode(true);
      }
    } else {
      setIsUsingBlockOnSlabCode(false);
      setPrefixToBlock(undefined);
      setFieldError("prefix", undefined);
      setFieldError("start", undefined);
    }
  }, [isPossibleToUseBlockOnSlabCode, errors?.prefix, errors?.start, form?.block]);

  /**
   * Since Formik's handleChange function, by default, deletes fields with undefined values from
   * its values object, it's required to set the field value itself to "null" whithout using formik's handleChange
   * in order to keep the value as "empty" and send it over the API to override whatever value it has on the Database
   * @param e React.ChangeEvent<any>
   */
  const handleChange = (e: React.ChangeEvent<any>) => {
    if (e.target.value === undefined) {
      setFieldValue(e.target.name, null);
    } else {
      handleChangesFormik(e);
    }
  };

  const checkErrorsOrNotDirty = () => {
    let keysOfErrors = Object.keys(errors);
    return keysOfErrors?.length > 0 || !dirty;
  };

  async function submitForm(values: ICatalogForm) {
    const numberFields = [
      "width",
      "height",
      "thickness",
      "extheight",
      "extwidth",
      "extthickness",
      "stkheight",
      "stkwidth",
      "stkthickness",
      "purchaseheight",
      "purchasewidth",
      "purchasethickness",
    ];

    try {
      // await slabServices.updateProjectSlab(slabs, form);
      // remove variables from form (bundleRules)
      const input_data = copyObject(values, ["prefix", "start"]);
      //delete 0's, empty strings

      for (const key in input_data) {
        if (Object.prototype.hasOwnProperty.call(input_data, key)) {
          let typed_key = key as keyof typeof input_data;
          const element = input_data[typed_key];
          //0's, empty strings
          if (element === 0 || (typeof element === "string" && element.trim() === "")) {
            delete input_data[typed_key];
          } else {
            if (numberFields.indexOf(typed_key) !== -1 && isNumeric(element)) {
              //@ts-ignore
              input_data[typed_key] = Number(element);
            } else if (!element && typeof element === "undefined") {
              //@ts-ignore
              input_data[typed_key] = null;
            }
          }
        }
      }

      if (!!newBlockToCreate) {
        delete input_data.block;
        //@ts-ignore
        input_data.blocknumber = newBlockToCreate.wescanCode;
      }

      const bundleRules: BundleCreation | null | undefined =
        !!values.prefix &&
        values.prefix.trim() !== "" &&
        stones.length > 1 &&
        isPossibleToUseBlockOnSlabCode &&
        isUsingBlockOnSlabCode
          ? {
              prefix: values.prefix,
              start: values.start || 0,
            }
          : undefined;

      await mutationCatalogSlabs({
        variables: {
          input: {
            catalog: {
              data: {
                ...input_data,
                iscatalogued: isToCancelCataloguing ? false : isToCatalog,
                cancelCataloguing: isToCancelCataloguing,
              },
              slabs: stones.map((el) => el.id as string),
              bundleRules,
            },
          },
        },
      });
      swalert(
        "Slab Catalog",
        isToCancelCataloguing ? "Canceled Cataloguing" : "Cataloged Successfully",
        "success"
      );

      dispatch(notLoadingComponent());
      onSubmit();
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert(
        "Error",
        !!error?.message && typeof error?.message === "string" ? error.message : "Erro",
        "warning"
      );
    }
    setFormInfo(undefined);
  }

  function handleCancel(event: any) {
    event?.preventDefault();
    resetForm({
      errors: undefined,
      values: {} as ICatalogForm,
      touched: undefined,
      isSubmitting: undefined,
      isValidating: undefined,
    });
    setFormInfo(undefined);
    onCancel();
  }

  // fill with first slab
  const fillFormWithOrWithoutFirstSlabInfo = (useInfo: boolean) => {
    const firstStone = stones[0];
    let newForm = {
      ...form,
      category: useInfo ? firstStone.category?.id || null : null,
      typematerial: useInfo ? firstStone.typematerial?.id || null : null,
      finish: useInfo ? firstStone.finish?.id || null : null,
      project: useInfo ? firstStone.project?.id || null : null,
      variety: useInfo ? firstStone.variety?.id || null : null,
      block: useInfo ? firstStone?.block?.id || null : null,
      packing: useInfo ? firstStone.packing?.id || null : null,
      commercial_finishing: useInfo ? firstStone.commercial_finishing?.id || null : null,
      commercial_variety: useInfo ? firstStone.commercial_variety?.id || null : null,
      stone_model: useInfo ? firstStone.stone_model?.id || null : null,
      stone_edge: useInfo ? firstStone.stone_edge?.id || null : null,
    };
    if (!useInfo) {
      newForm.prefix = undefined;
    } else if (firstStone?.block?.id) {
      findBlockCatalogInfo(firstStone.block?.id, formInfo);
    }
    setForm(newForm);
    // form info
    let newFormInfo = { ...formInfo };

    toast.success(useInfo ? `Using Slab's "${firstStone?.code}" data` : "Cleared Form");
  };

  /**
   * Get helper text from either error or formInfo objects (error has priority)
   * @param fieldName Name of field to get Helper Text from
   * @returns Error or FormInfo (warning)
   */
  const getHelperText = (fieldName: keyof FormInfoType) => {
    return !!errors?.[fieldName]
      ? errors?.[fieldName]
      : !!formInfo?.[fieldName] && !isEmpty(formInfo?.[fieldName])
      ? getHelperTextFromFormInfo(formInfo[fieldName] as FormInfoKeysType)
      : "";
  };

  /**
   *
   * @param fieldName
   * @param info
   */
  const getHelperTextFromFormInfo = (info: FormInfoKeysType): string => {
    let warnings: string[] = [];
    for (const key in info) {
      let trueKey = key as keyof typeof info;
      if (Object.prototype.hasOwnProperty.call(info, trueKey)) {
        const element = info[trueKey];
        if (!!element?.warning) {
          warnings.push(element.warning);
        }
      }
    }
    return warnings.join("/ ");
  };

  const shouldFieldBeLocked = (fieldName: keyof FormInfoType): boolean => {
    let formInfoOfField = formInfo?.[fieldName];
    if (!!formInfoOfField) {
      let key: keyof FormInfoKeysType;
      for (key in formInfoOfField) {
        if (Object.prototype.hasOwnProperty.call(formInfoOfField, key)) {
          const element = formInfoOfField[key];
          if (element?.disable) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const onClear = (fieldName: keyof ICatalogForm) => {
    let keyToRemove = fieldName as keyof typeof formInfo;
    let fieldNamesToClear: (keyof ICatalogForm)[] = [];
    // clear all FormInfo fields related to this fieldName and collect fields to clear
    let newFormInfo = copyObject(formInfo, [keyToRemove]);
    for (const key in newFormInfo) {
      let trueKey = key as keyof typeof newFormInfo;
      if (Object.prototype.hasOwnProperty.call(newFormInfo, trueKey)) {
        const element = newFormInfo[trueKey];
        if (!!element?.[keyToRemove]) {
          fieldNamesToClear.push(trueKey);
          delete element[keyToRemove];
          // check if object is now empty (not other warnings)
          if (isEmpty(element)) {
            delete newFormInfo[trueKey];
          }
        }
      }
    }
    setFormInfo(newFormInfo);

    // clear form value related to fieldName
    let newForm = { ...form };
    fieldNamesToClear.forEach((key) => {
      if (!!newForm?.[key]) {
        setFieldValue(key, null);
      }
    });
  };

  // change prefix on block change
  useEffect(() => {
    if (!!stones && stones.length > 1) {
      if (!!form.block && !!blocks && blocks.length > 0) {
        let found = blocks.find((el) => el.id === form.block);
        setFieldValue("prefix", !!found?.tenantCode ? found.tenantCode : found?.wescanCode);
      } else if (!!form.block && newBlockToCreate) {
        setFieldValue("prefix", newBlockToCreate.tenantCode);
      }
      if (!!form.block) {
        getStartNumber(form.block);
      }
    }
  }, [form.block, blocks]);

  /**
   * Function used to show the code the slab is going to have after cataloguing
   * @param slab Slab to be catalogued
   * @param ind Slab index on array of slabs
   * @returns Future code
   */
  const getCodeResultAfterBundleCatalog = (slab: Slabs_slabs_nodes, ind: number) => {
    return `${form.prefix}_${(form.start || 0) + ind}`;
  };

  //#region AutoFilters/ImageColorFilters
  const [autoFiltersLazyQuery, { data: dataColorFilters, error: errorColorFilters }] = useLazyQuery<
    ImageColorFilters,
    ImageColorFiltersVariables
  >(QUERY_MANY_IMAGECOLORFILTERS);

  useEffect(() => {
    if (!!form.commercial_variety || !!form.commercial_finishing) {
      autoFiltersLazyQuery({
        variables: {
          filter: {
            and: [
              {
                commercial_finishing: { eq: form.commercial_finishing },
                commercial_variety: { eq: form.commercial_variety },
              },
            ],
          },
        },
      });
    } else {
    }
  }, [form.commercial_finishing, form.commercial_variety]);
  //#endregion

  return (
    <Dialog
      {...DialogProps}
      open={openDetailsDialog}
      maxWidth={"xl"}
      fullWidth
      onClose={(e) => {
        handleCancel(e);
      }}
    >
      <DialogTitle
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          pl: 2.5,
          pr: 2.5,
          zIndex: 101,
        }}
      >
        <FlexBox key="flexbox-title">
          <h1>{isToCancelCataloguing ? "Cancel Cataloguing" : "Catalog"}</h1>
        </FlexBox>
        <FlexBox key="flexbox-action">
          {!isToCancelCataloguing && (
            <FormControlLabel
              sx={{ mr: "1rem" }}
              control={
                <Switch
                  checked={isToCatalog}
                  onChange={() => setIsToCatalog(!isToCatalog)}
                  color="secondary"
                  name="isToCatalog"
                  inputProps={{
                    "aria-label": "primary checkbox",
                  }}
                  sx={{ mt: 1 }}
                />
              }
              // label="View Products in Stock Only"
              labelPlacement="start"
              label={<Typography variant="h4">Flag as Catalogued</Typography>}
            />
          )}
          <IconButton onClick={() => toggleDialog()}>
            <Close className="close" fontSize="medium" color="primary" />
          </IconButton>
        </FlexBox>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {stones.length <= 3 ? (
              <Grid container spacing={2}>
                {stones[0]?.code &&
                  stones.map((stn, key) => (
                    <Grid
                      key={"key" + key}
                      item
                      xs={
                        !stones[0]?.erp_id || (stones[0]?.erp_id && stones[0]?.erp_id.trim() === "")
                          ? 4
                          : 4
                      }
                    >
                      <img
                        className="card-img-top"
                        style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat" }}
                        src={getSlabImageSafe(stn, true) || "../../../images/imagemissing.png"}
                        alt={stn.code}
                      />
                      <FlexBox
                        sx={{ justifyContent: !!form.prefix ? "space-evenly" : "flex-start" }}
                      >
                        <h5 className="card-title" style={{ flex: 1 }}>
                          {stn.code}
                        </h5>
                        {!!form.prefix &&
                          isPossibleToUseBlockOnSlabCode &&
                          isUsingBlockOnSlabCode && <DoubleArrow style={{ flex: 1 }} />}
                        {!!form.prefix &&
                          isPossibleToUseBlockOnSlabCode &&
                          isUsingBlockOnSlabCode && (
                            <h5 className="card-title" style={{ fontWeight: 300, flex: 1 }}>
                              {getCodeResultAfterBundleCatalog(stn, key)} (NEW)
                            </h5>
                          )}
                      </FlexBox>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Carousel totalSlides={stones.length} visibleSlides={3} infinite={true}>
                {stones[0]?.code &&
                  stones.map((stn, ind) => (
                    <div key={stn.code + "-" + ind} className="card">
                      <img
                        style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat" }}
                        src={getSlabImageSafe(stn, true) || "../../../images/imagemissing.png"}
                        className="card-img-top"
                        alt={stn.code}
                      />
                      <FlexBox
                        sx={{ justifyContent: !!form.prefix ? "space-evenly" : "flex-start" }}
                      >
                        <h5 className="card-title" style={{ flex: 1 }}>
                          {stn.code}
                        </h5>
                        {!!form.prefix &&
                          isPossibleToUseBlockOnSlabCode &&
                          isUsingBlockOnSlabCode && <DoubleArrow style={{ flex: 1 }} />}
                        {!!form.prefix &&
                          isPossibleToUseBlockOnSlabCode &&
                          isUsingBlockOnSlabCode && (
                            <h5 className="card-title" style={{ fontWeight: 300, flex: 1 }}>
                              {getCodeResultAfterBundleCatalog(stn, ind)} (NEW)
                            </h5>
                          )}
                      </FlexBox>
                    </div>
                  ))}
              </Carousel>
            )}
          </Box>
          {stones && stones.length > 1 && (
            <FlexBox sx={{ alignItems: "center" }}>
              <Alert severity="warning">
                Multiple Slabs - All fields have been filled with the first Slab's "
                {stones[0]?.code}" data by default.
              </Alert>
              <ToggleButton
                value="check"
                color="success"
                selected={useFirstSlabInfo}
                contextMenu={"Use first Slab's Data"}
                onChange={() => {
                  let newValue = !useFirstSlabInfo;
                  setUseFirstSlabInfo(newValue);
                  fillFormWithOrWithoutFirstSlabInfo(newValue);
                }}
                sx={{ marginLeft: "1rem", borderWidth: 1, borderColor: theme.palette.grey[400] }}
              >
                <Box>{useFirstSlabInfo ? "Using data" : "Not using data"}</Box>
              </ToggleButton>
            </FlexBox>
          )}
          {form && (
            <>
              <Grid
                container
                spacing={4}
                sx={{ padding: "2px", marginTop: stones && stones.length === 1 ? 0 : 1 }}
              >
                <Grid key="block-grid" item md={4} xs={12}>
                  <AutocompleteWithQuery
                    items={blocks}
                    label="Block"
                    name="block"
                    onChange={(e: React.ChangeEvent<any>) => {
                      onClear("block");
                      findBlockCatalogInfo(e.target.value, formInfo);
                      let found = blocks.find((el) => el.id === e.target.value);
                      setFieldValue(
                        "prefix",
                        !!found?.tenantCode ? found?.tenantCode : found?.wescanCode
                      );
                      setNewBlockToCreate(undefined);
                      handleChange(e);
                    }}
                    title="Block"
                    value={form.block}
                    onClear={() => onClear("block")}
                    TextFieldProps={{
                      error: !!errors?.block,
                      helperText: getHelperText("block"),
                      label: "Block",
                    }}
                    onBlur={(value) => {
                      if (
                        !!value &&
                        value.trim() !== "" &&
                        !!!blocks.find((blk) => blk.tenantCode == value || blk.wescanCode == value)
                      ) {
                        setNewBlockToCreate({ id: "new", tenantCode: value, wescanCode: value });
                        setFieldValue("block", value);
                        setFormInfo({
                          ...formInfo,
                          block: { block: { warning: `Creating New Block "${value}"` } },
                        });
                        if (stones.length > 1) {
                          setFieldValue("prefix", value);
                        }
                      }
                    }}
                    newItem={newBlockToCreate}
                    onTextChange={async (value: string) => {
                      await queryValuesForFilter<
                        BlockModelFilter,
                        CursorPaging,
                        BlockModelSort,
                        BlockModelsToFilter
                      >(
                        value,
                        QUERY_BLOCKS_TO_FILTER,
                        (res) => {
                          let endRes = res?.data?.blockModels?.edges?.map((el) => el.node);
                          setBlocks(endRes);
                        },
                        {
                          filter: {
                            or: [
                              { wescanCode: { iLike: value } },
                              { tenantCode: { iLike: value } },
                            ],
                          },
                          paging: {
                            first: 40,
                          },
                        }
                      );
                    }}
                    getField={(node: (typeof blocks)[number]) => {
                      return !!node.tenantCode ? node.tenantCode : node.wescanCode;
                    }}
                  />
                </Grid>
                {stones && stones.length === 1 && (
                  <Grid key="code-grid" item md={4} xs={12}>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                      <TextField
                        id="code"
                        label="Code"
                        name="code"
                        onChange={handleChange}
                        value={form.code || ""}
                        placeholder="Code"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        error={!!errors?.code}
                        helperText={!!errors?.code ? errors?.code : ""}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              {stones && stones.length > 1 && (
                <Grid item md={8} xs={12}>
                  <Grid key="switch-and-alert-grid" container md={12} xs={12} spacing={1}>
                    <Grid key="switch-grid" item md={4} xs={4}>
                      <FormControlLabel
                        sx={{ mr: "1rem" }}
                        control={
                          <Switch
                            checked={isUsingBlockOnSlabCode}
                            onChange={() => {
                              if (!isPossibleToUseBlockOnSlabCode) {
                                setFieldValue("prefix", undefined);
                              }
                              setIsUsingBlockOnSlabCode(!isUsingBlockOnSlabCode);
                            }}
                            color="secondary"
                            name="setShouldUseBlockOnSlabCode"
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                            sx={{ mt: 1 }}
                          />
                        }
                        // label="View Products in Stock Only"
                        labelPlacement="start"
                        label={<Typography variant="h5">Change Slabs' Codes</Typography>}
                      />
                    </Grid>
                    {!isPossibleToUseBlockOnSlabCode && (
                      <Grid key="alert-grid" item md={7} xs={7}>
                        <FlexBox sx={{ alignItems: "center" }}>
                          <Alert severity="warning">
                            Changing the code of the Slabs using this prefix is disabled because it
                            was already used to name at least one of the selected Slabs
                          </Alert>
                        </FlexBox>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid key="code-grid" item md={4} xs={12}>
                      <FormControl fullWidth className={classes.margin} variant="outlined">
                        <TextField
                          disabled={!isUsingBlockOnSlabCode}
                          id="prefix"
                          label="Slabs Prefix"
                          name="prefix"
                          inputRef={prefixRef}
                          onChange={handleChange}
                          value={form.prefix || ""}
                          placeholder="ex: BLK1234"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(e) => {
                            checkPrefix(e.currentTarget.value);
                          }}
                          variant="outlined"
                          error={isUsingBlockOnSlabCode ? !!errors?.prefix : false}
                          helperText={
                            isUsingBlockOnSlabCode ? (!!errors?.prefix ? errors?.prefix : "") : ""
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid key="code-start-grid" item md={2} xs={12}>
                      <TextField
                        disabled={!isUsingBlockOnSlabCode}
                        id="start"
                        label="Number To Start"
                        name="start"
                        onChange={handleChange}
                        value={form.start || ""}
                        placeholder="ex: 1"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: minBundleCatalogNumber,
                        }}
                        variant="outlined"
                        error={isUsingBlockOnSlabCode ? !!errors?.start : false}
                        helperText={
                          isUsingBlockOnSlabCode ? (!!errors?.start ? errors?.start : "") : ""
                        }
                      />
                    </Grid>
                  </Grid>
                  {!!form.prefix && isUsingBlockOnSlabCode && (
                    <Typography sx={{ ml: 1 }}>
                      {form.prefix}_{form.start}
                    </Typography>
                  )}
                </Grid>
              )}
              {/* MEASUREMENTS */}
              <hr />
              {stones && stones.length === 1 && (
                <>
                  <H4 sx={{ mb: "0.7rem" }}>Measurements</H4>
                  <Grid key="reg-measurements-grid" container spacing={6}>
                    <Grid key="width-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="width"
                          onChange={handleChange}
                          className={classes.margin}
                          label="Width"
                          type="number"
                          name="width"
                          placeholder="Width"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.width}
                          error={!!errors?.width}
                          helperText={!!errors?.width ? errors?.width : ""}
                        />
                      </FormControl>
                    </Grid>
                    <Grid key="thickness-grid" item md={4} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          id="thickness"
                          onChange={handleChange}
                          className={classes.margin}
                          label="Thickness"
                          type="number"
                          name="thickness"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.thickness}
                          error={!!errors?.thickness}
                          helperText={!!errors?.thickness ? errors?.thickness : ""}
                        />
                      </FormControl>
                    </Grid>
                    <Grid key="height-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="height"
                          onChange={handleChange}
                          className={classes.margin}
                          label="Length"
                          type="number"
                          name="height"
                          placeholder="Length"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.height}
                          error={!!errors?.height}
                          helperText={!!errors?.height ? errors?.height : ""}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* EXTERNAL MEASUREMENTS */}
                  <Grid key="ext-measurements-grid" container spacing={6}>
                    <Grid key="extwidth-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="extwidth"
                          className={classes.margin}
                          label="External Width"
                          type="number"
                          name="extwidth"
                          placeholder="External Width"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.extwidth}
                          error={!!errors?.extwidth}
                          helperText={!!errors?.extwidth ? errors?.extwidth : ""}
                        />
                      </FormControl>
                    </Grid>
                    <Grid key="extthickness-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="extthickness"
                          className={classes.margin}
                          label="External Thickness"
                          placeholder="External Thickness"
                          type="number"
                          name="extthickness"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.extthickness}
                          error={!!errors?.extthickness}
                          helperText={!!errors?.extthickness ? errors?.extthickness : ""}
                        />
                      </FormControl>
                    </Grid>
                    <Grid key="extheight-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="extheight"
                          className={classes.margin}
                          label="External Length"
                          type="number"
                          name="extheight"
                          placeholder="External Length"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.extheight}
                          error={!!errors?.extheight}
                          helperText={!!errors?.extheight ? errors?.extheight : ""}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* PURCHASE MEASUREMENTS */}
                  <Grid key="prch-measurements-grid" container spacing={6}>
                    <Grid key="purchasewidth-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="purchasewidth"
                          className={classes.margin}
                          label="Purchase Width"
                          type="number"
                          name="purchasewidth"
                          placeholder="Purchaise Width"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.purchasewidth}
                          error={!!errors?.purchasewidth}
                          helperText={!!errors?.purchasewidth ? errors?.purchasewidth : ""}
                        />
                      </FormControl>
                    </Grid>
                    <Grid key="purchasethickness-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="purchasethickness"
                          className={classes.margin}
                          label="Purchase Thickness"
                          placeholder="Purchaise Thickness"
                          type="number"
                          name="purchasethickness"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.purchasethickness}
                          error={!!errors?.purchasethickness}
                          helperText={!!errors?.purchasethickness ? errors?.purchasethickness : ""}
                        />
                      </FormControl>
                    </Grid>
                    <Grid key="purchaseheight-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="purchaseheight"
                          className={classes.margin}
                          label="Purchase Length"
                          type="number"
                          name="purchaseheight"
                          placeholder="Purchaise Length"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.purchaseheight}
                          error={!!errors?.purchaseheight}
                          helperText={!!errors?.purchaseheight ? errors?.purchaseheight : ""}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* STOCK MEASUREMENTS */}
                  <Grid key="stk-measurements-grid" container spacing={6}>
                    <Grid key="stkwidth-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="stkwidth"
                          className={classes.margin}
                          label="Stock Width"
                          type="number"
                          name="stkwidth"
                          placeholder="Stock Width"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.stkwidth}
                          error={!!errors?.stkwidth}
                          helperText={!!errors?.stkwidth ? errors?.stkwidth : ""}
                        />
                      </FormControl>
                    </Grid>
                    <Grid key="stkthickness-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="stkthickness"
                          className={classes.margin}
                          label="Stock Thickness"
                          type="number"
                          name="stkthickness"
                          placeholder="Stock Thickness"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.stkthickness}
                          error={!!errors?.stkthickness}
                          helperText={!!errors?.stkthickness ? errors?.stkthickness : ""}
                        />
                      </FormControl>
                    </Grid>
                    <Grid key="stkheight-grid" item md={4} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          id="stkheight"
                          className={classes.margin}
                          label="Stock Length"
                          type="number"
                          name="stkheight"
                          placeholder="Stock Length"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: {
                              width: "100%",
                            },
                          }}
                          value={form.stkheight}
                          error={!!errors?.stkheight}
                          helperText={!!errors?.stkheight ? errors?.stkheight : ""}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <hr />
                </>
              )}
              <H4 sx={{ mb: "0.7rem" }}>Classification</H4>
              {/* selectables */}
              <Grid key="classification-one-grid" container spacing={6}>
                <Grid key="category-grid" item md={4} xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteWithQuery
                      items={categories}
                      label="Category"
                      name="category"
                      title="Categories"
                      onChange={handleChange}
                      value={form.category}
                      TextFieldProps={{
                        error: !!errors?.category,
                        helperText: !!errors?.category ? errors?.category : "",
                        label: "Category",
                        disabled: shouldFieldBeLocked("category"),
                      }}
                      AutocompleteProps={{
                        disabled: shouldFieldBeLocked("category"),
                      }}
                      continueToLoadResultsInComponent={false}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          CategoriesVariables["filter"],
                          CursorPaging,
                          CategorySort,
                          Categories
                        >(
                          value,
                          QUERY_CATEGORIES,
                          (res) => {
                            let endRes = res?.data?.categories?.edges?.map((el) => el.node);
                            setCategories(endRes);
                          },
                          {
                            filter: { and: [{ code: { iLike: value } }] },
                            paging: { first: 40 },
                          }
                        );
                      }}
                      getField={(node: Categories_categories_edges_node) => {
                        if (!!node.companyNames && node.companyNames.length > 0) {
                          let obj = node.companyNames[0];
                          return `${obj.code} - ${obj.description}`;
                        }
                        return `${node.code} - ${node.description.pt}`;
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid key="typematerial-grid" item md={4} xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteWithQuery
                      items={materialTypes}
                      label="Material Type"
                      name="typematerial"
                      onChange={handleChange}
                      title="Materials Type"
                      value={form.typematerial}
                      onClear={() => onClear("typematerial")}
                      TextFieldProps={{
                        error: !!errors?.typematerial,
                        helperText: getHelperText("typematerial"),
                        label: "Material Type",
                        disabled: shouldFieldBeLocked("typematerial"),
                      }}
                      AutocompleteProps={{
                        disabled: shouldFieldBeLocked("typematerial"),
                      }}
                      continueToLoadResultsInComponent={false}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          MaterialTypeFilter,
                          CursorPaging,
                          MaterialTypeSort,
                          MaterialTypes
                        >(
                          value,
                          QUERY_MATERIALTYPES,
                          (res) => {
                            let endRes = res?.data?.materialTypes?.edges?.map((el) => el.node);
                            setMaterialTypes(endRes);
                          },
                          {
                            filter: { or: [{ code: { iLike: value } }] },
                            paging: { first: 70 },
                          }
                        );
                      }}
                      getField={(node: MaterialTypes_materialTypes_edges_node) => {
                        if (!!node.companyNames && node.companyNames.length > 0) {
                          let obj = node.companyNames[0];
                          return `${obj.code} - ${obj.description}`;
                        }
                        return `${node.code} - ${node.description.pt}`;
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid key="commercial_variety-grid" item md={4} xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteWithQuery
                      items={commercialVarieties}
                      label="Commercial Variety"
                      name="commercial_variety"
                      title="Commercial Variety"
                      onChange={handleChange}
                      onClear={() => onClear("commercial_variety")}
                      value={form.commercial_variety}
                      TextFieldProps={{
                        error: !!errors?.commercial_variety,
                        helperText: getHelperText("commercial_variety"),
                        label: "Commercial Variety",
                        disabled: shouldFieldBeLocked("commercial_variety"),
                      }}
                      AutocompleteProps={{
                        disabled: shouldFieldBeLocked("commercial_variety"),
                      }}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          CommercialVarietiesVariables["filter"],
                          CursorPaging,
                          CommercialVarietySort,
                          CommercialVarieties
                        >(
                          value,
                          QUERY_COMMERCIAL_VARIETIES,
                          (res) => {
                            let endRes = res?.data?.commercialVarieties?.edges?.map(
                              (el) => el.node
                            );
                            setCommercialVarieties(endRes);
                          },
                          {
                            filter: {
                              or: [{ code: { iLike: value } }, { name: { iLike: value } }],
                            },
                            paging: { first: 25 },
                          }
                        );
                      }}
                      getField={(node: CommercialVarieties_commercialVarieties_edges_node) => {
                        return `${node.code} - ${node.name}`;
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid key="classification-two-grid" container spacing={6}>
                <Grid key="models-grid" item md={4} xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteWithQuery
                      items={stoneModels}
                      label="Models"
                      name="stone_model"
                      onChange={handleChange}
                      title="Models"
                      onClear={() => onClear("stone_model")}
                      value={form.stone_model}
                      TextFieldProps={{
                        error: !!errors?.stone_model,
                        helperText: getHelperText("stone_model"),
                        label: "Models",
                        disabled: shouldFieldBeLocked("stone_model"),
                      }}
                      AutocompleteProps={{
                        disabled: shouldFieldBeLocked("stone_model"),
                      }}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          StoneModelFilter,
                          CursorPaging,
                          StoneModelSort,
                          StoneModels
                        >(
                          value,
                          QUERY_STONE_MODELS,
                          (res) => {
                            let endRes = res?.data?.stoneModels?.edges?.map((el) => el.node);
                            setStoneModels(endRes);
                          },
                          {
                            filter: {
                              or: [{ code: { iLike: value } }, { name: { iLike: value } }],
                            },
                            paging: { first: 40 },
                          }
                        );
                      }}
                      getField={(node: StoneModels_stoneModels_edges_node) => {
                        return `${node.code} - ${node.name}`;
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid key="commercial_finishing-grid" item md={4} xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteWithQuery
                      items={commercialFinishings}
                      label="Commercial Finishings"
                      name="commercial_finishing"
                      onChange={handleChange}
                      title="Commercial Finishings"
                      value={form.commercial_finishing}
                      onClear={() => onClear("commercial_finishing")}
                      TextFieldProps={{
                        error: !!errors?.commercial_finishing,
                        helperText: getHelperText("commercial_finishing"),
                        label: "Commercial Finishings",
                        disabled: shouldFieldBeLocked("commercial_finishing"),
                      }}
                      AutocompleteProps={{
                        disabled: shouldFieldBeLocked("commercial_finishing"),
                      }}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          CommercialFinishingFilter,
                          CursorPaging,
                          CommercialFinishingSort,
                          CommercialFinishings
                        >(
                          value,
                          QUERY_COMMERCIAL_FINISHINGS,
                          (res) => {
                            let endRes = res?.data?.commercialFinishings?.edges?.map(
                              (el) => el.node
                            );
                            setCommercialFinishings(endRes);
                          },
                          {
                            filter: { or: [{ code: { iLike: value } }] },
                            paging: { first: 40 },
                          }
                        );
                      }}
                      getField={(node: CommercialFinishings_commercialFinishings_edges_node) => {
                        return `${node.code} - ${node.description.pt}`;
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid key="stone_edge-grid" item md={4} xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteWithQuery
                      items={stoneEdges}
                      label="Edges"
                      name="stone_edge"
                      onChange={handleChange}
                      title="Edges"
                      value={form.stone_edge}
                      onClear={() => onClear("stone_edge")}
                      TextFieldProps={{
                        error: !!errors?.stone_edge,
                        helperText: getHelperText("stone_edge"),
                        label: "Edges",
                        disabled: shouldFieldBeLocked("stone_edge"),
                      }}
                      AutocompleteProps={{
                        disabled: shouldFieldBeLocked("stone_edge"),
                      }}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          StoneEdgeFilter,
                          CursorPaging,
                          StoneEdgeSort,
                          StoneEdges
                        >(
                          value,
                          QUERY_STONE_EDGES,
                          (res) => {
                            let endRes = res?.data?.stoneEdges?.edges?.map((el) => el.node);
                            setStoneEdges(endRes);
                          },
                          {
                            filter: {
                              or: [
                                { code: { iLike: value } },
                                { name: { iLike: value } },
                                { description: { iLike: value } },
                              ],
                            },
                            paging: { first: 40 },
                          }
                        );
                      }}
                      getField={(node: StoneEdges_stoneEdges_edges_node) => {
                        return `${node.code} - ${node.name}`;
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {(!!form.commercial_variety || !!form.commercial_finishing) &&
                !!dataColorFilters?.imageColorFilters?.edges &&
                dataColorFilters?.imageColorFilters?.edges?.length > 0 && (
                  <Alert severity="warning">
                    Filter '{dataColorFilters?.imageColorFilters?.edges[0].node.name}' will be
                    applied to the images after Cataloging based on the Commercial Variety and
                    Finishing values
                  </Alert>
                )}
              <hr />
              <H4 sx={{ mb: "0.7rem" }}>Projects & Bundles</H4>
              <Grid key="projects-and-bundles-grid" container spacing={6}>
                <Grid key="project-grid" item md={4} xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteWithQuery
                      items={projects}
                      label="Projects"
                      name="project"
                      onChange={handleChange}
                      title="Projects"
                      value={form.project}
                      TextFieldProps={{
                        error: !!errors?.project,
                        helperText: !!errors?.project ? errors?.project : "",
                        label: "Projects",
                      }}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          ProjectFilter,
                          CursorPaging,
                          ProjectSort,
                          Projects
                        >(
                          value,
                          QUERY_MANY_PROJECTS,
                          (res) => {
                            let endRes = res?.data?.projects?.edges?.map((el) => el.node);
                            setProjects(endRes);
                          },
                          {
                            filter: {
                              or: [
                                { code: { iLike: value } },
                                { description: { iLike: value } },
                                { observation: { iLike: value } },
                                { project: { iLike: value } },
                              ],
                            },
                            paging: {
                              first: 30,
                            },
                          }
                        );
                      }}
                      getField={(node: Projects_projects_edges_node) => {
                        return node.project;
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid key="packing-grid" item md={4} xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteWithQuery
                      items={packages}
                      label="Bundles"
                      name="packing"
                      value={form.packing}
                      onChange={handleChange}
                      title="Bundles"
                      TextFieldProps={{
                        error: !!errors?.packing,
                        helperText: !!errors?.packing ? errors?.packing : "",
                        label: "Bundles",
                      }}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          PackingFilter,
                          CursorPaging,
                          PackingSort,
                          Packings
                        >(
                          value,
                          QUERY_MANY_PACKINGS,
                          (res) => {
                            let endRes = res?.data?.packings?.edges?.map((el) => el.node);
                            setPackages(endRes);
                          },
                          {
                            filter: {
                              or: [{ code: { iLike: value } }, { packing: { iLike: value } }],
                            },
                            paging: { first: 40 },
                          }
                        );
                      }}
                      getField={(node: Packings_packings_edges_node) => {
                        return node.packing;
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <hr />
              <H4 sx={{ mb: "0.7rem" }}>Default System Classification</H4>
              <Grid key="default-system-classification-grid" container spacing={6}>
                <Grid key="finish-grid" item md={4} xs={12}>
                  <FormControl variant="outlined">
                    <AutocompleteWithQuery
                      items={finishings}
                      label="System Finishing"
                      name="finish"
                      value={form.finish}
                      onChange={handleChange}
                      title="System Finishing"
                      onClear={() => onClear("finish")}
                      TextFieldProps={{
                        error: !!errors?.finish,
                        helperText: getHelperText("finish"),
                        label: "System Finishing",
                        disabled: shouldFieldBeLocked("finish"),
                      }}
                      AutocompleteProps={{
                        disabled: shouldFieldBeLocked("finish"),
                      }}
                      continueToLoadResultsInComponent={false}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          FinishingFilter,
                          CursorPaging,
                          FinishingSort,
                          Finishings
                        >(
                          value,
                          QUERY_FINISHINGS,
                          (res) => {
                            let endRes = res?.data?.finishings?.edges?.map((el) => el.node);
                            setFinishings(endRes);
                          },
                          {
                            filter: {
                              or: [{ code: { iLike: value } }],
                            },
                            paging: {
                              first: 200,
                            },
                          }
                        );
                      }}
                      getField={(node: Finishings_finishings_edges_node) => {
                        return `${node.code} - ${
                          node?.description?.pt ? node?.description?.pt : ""
                        }`;
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid key="variety-grid" item md={4} xs={12}>
                  <FormControl variant="outlined">
                    <AutocompleteWithQuery
                      items={varieties}
                      label="System Variety"
                      name="variety"
                      value={form.variety}
                      onChange={handleChange}
                      title="System Variety"
                      onClear={() => onClear("variety")}
                      TextFieldProps={{
                        error: !!errors?.variety,
                        helperText: getHelperText("variety"),
                        label: "System Variety",
                        disabled: shouldFieldBeLocked("variety"),
                      }}
                      AutocompleteProps={{
                        disabled: shouldFieldBeLocked("variety"),
                      }}
                      continueToLoadResultsInComponent={false}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          VarietyFilter,
                          CursorPaging,
                          VarietySort,
                          Varieties
                        >(
                          value,
                          QUERY_VARIETIES,
                          (res) => {
                            let endRes = res?.data?.varieties?.edges?.map((el) => el.node);
                            setVarieties(endRes);
                          },
                          {
                            filter: {
                              or: [
                                { code: { iLike: value } },
                                { normativedesig: { iLike: value } },
                              ],
                            },
                            paging: { first: 200 },
                          }
                        );
                      }}
                      getField={(node: Varieties_varieties_edges_node) => {
                        return `${node.code} - ${node.normativedesig}`;
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid
          key="action-grid"
          container
          spacing={2}
          style={{ marginRight: "50px", justifyContent: "flex-end" }}
        >
          <Grid key="submit-button-grid" item>
            <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
              <Button
                onClick={(e) => handleSubmit()}
                fullWidth
                disabled={isToCancelCataloguing ? false : checkErrorsOrNotDirty()}
                variant="contained"
                // className={classes.submit}
                style={{
                  height: "50px",
                  width: "200px",
                  fontSize: "20px",
                }}
              >
                <Typography variant="inherit" sx={{ mb: "3px" }}>
                  {isToCancelCataloguing ? "Save" : "Catalog"}
                </Typography>
                <Save style={{ marginLeft: "20px" }} />
              </Button>
            </FormControl>
          </Grid>
          <Grid key="cancel-button-grid" item>
            <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
              <Button
                onClick={handleCancel}
                fullWidth
                color="inherit"
                variant="contained"
                style={{
                  height: "50px",
                  width: "200px",
                  fontSize: "20px",
                }}
              >
                <Typography variant="inherit" sx={{ mb: "3px" }}>
                  Cancelar
                </Typography>
                <Cancel style={{ marginLeft: "20px" }} />
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </DialogActions>
      {isLoadingBlockInfo && <FullScreenActivityIndicator />}
    </Dialog>
  );
}
