import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { Box, Card, CardContent, Container, Grid, Typography } from "@material-ui/core";
import swalert from "sweetalert";
import { MuiThemeProps } from "../../theme/theme";
import { loadingComponent, notLoadingComponent } from "../../actions/isLoadingActions";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

interface CreateEntityFormProps {
  mutation: ([...args]: any) => Promise<void>;
  entityForm: { [key: string]: any };
  entityName: string;
  path: string;
  requiredFields: string[];
}

export default function CreateEntityForm({
  mutation,
  entityForm,
  entityName,
  path,
  requiredFields,
}: CreateEntityFormProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setForm] = useState(entityForm);

  function changeform(e: any) {
    const { name, value } = e.target;
    let finalValue = !!value ? value : null;
    setForm({ ...form, [name]: finalValue });
  }

  async function submitForm(e: any) {
    e.preventDefault();

    dispatch(loadingComponent());

    try {
      await mutation(form).then((res) => {
        swalert("Success.", `${entityName} created with success`, "success");
      });
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert("Failed.", error, "error");
      return;
    }
    dispatch(notLoadingComponent());
    history.push(path);
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push(path);
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>New {entityName}</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {Object.keys(entityForm).map((el) => {
                  return (
                    <FormControl fullWidth className={classes.marginCode} variant="outlined">
                      <TextField
                        sx={{
                          height: el === "description" ? 100 : "auto",
                        }}
                        id={el}
                        label={el}
                        name={el}
                        placeholder={el}
                        required={
                          requiredFields ? !!requiredFields?.find((key) => el === key) : true
                        }
                        focused
                        onChange={changeform}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  );
                })}
              </Box>
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      disabled={
                        !!Object.keys(form)?.find((key) => !!!form?.[key] || form?.[key] === "")
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Create
                      <Save style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
