import { CardContent, useTheme, Typography, IconButton } from "@material-ui/core";
import { Box } from "@material-ui/system";
import {
  BlockCreationType,
  BlockModelsGallery_blockModels_edges_node,
  ImageFace,
} from "../../data/graphQLModel";
import { H3, H4, H5 } from "../GeneralComponents/Typography";
import { Image as ImageIcon, OpenInNew, ThreeDRotation } from "@material-ui/icons";
import FlexBox from "../GeneralComponents/FlexBox";
import ImageBox from "../GeneralComponents/ImageBox";
import { getSlabImageSafe, openNewTab } from "../../utils/utils";

interface BlockGalleryCard {
  handleClick: () => void;
  block: BlockModelsGallery_blockModels_edges_node;
}

const getBlockPhoto = (block: BlockModelsGallery_blockModels_edges_node): string => {
  switch (block.creationType) {
    case BlockCreationType.JOB:
      if (!!block?.slabs?.edges && block?.slabs?.edges?.length > 0) {
        return getSlabImageSafe(block?.slabs?.edges[0]?.node) || "/images/imagemissing.png";
      } else {
        return "/images/imagemissing.png";
      }
    case BlockCreationType.USER:
      if (!!block?.slabs?.edges && block?.slabs?.edges?.length > 0) {
        return getSlabImageSafe(block?.slabs?.edges[0]?.node) || "/images/imagemissing.png";
      } else {
        return "/images/imagemissing.png";
      }
    default:
      if (!!block.regularImages) {
        let front = block.regularImages.find((el) => el.imageFace === ImageFace.FRONT);
        let single = block.regularImages.find((el) => el.imageFace === ImageFace.SINGLE);
        return !!front
          ? !!front.thumbnail_edited
            ? front.thumbnail_edited
            : front.thumbnail_original
          : single
          ? !!single.thumbnail_edited
            ? single.thumbnail_edited
            : single.thumbnail_original
          : "/images/imagemissing.png";
      } else {
        return "/images/imagemissing.png";
      }
  }
};

const getVariety = (block: BlockModelsGallery_blockModels_edges_node) => {
  return block?.commercial_variety?.code
    ? `${block?.commercial_variety.code} - ${block.commercial_variety.name}`
    : block?.variety?.normativedesig
    ? block.variety.normativedesig
    : "";
};

const getMaterial = (block: BlockModelsGallery_blockModels_edges_node) => {
  return !!block?.materialtype?.description?.pt ? block?.materialtype?.description?.pt : "";
};

const BlockGalleryCard = ({ block, handleClick }: BlockGalleryCard) => {
  const theme = useTheme();

  return (
    <ImageBox
      sx={{
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      className="card"
      hoverEffect
    >
      <a href={"/slabsprocess/blocks/details/" + btoa(block.id)}>
        <img
          onClick={handleClick}
          src={getBlockPhoto(block)}
          alt={!!block.tenantCode ? block.tenantCode : block.wescanCode}
        />
        <CardContent
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            p: 1,
          }}
        >
          {!!block?.regularImages && block?.regularImages?.length > 0 && (
            <FlexBox
              sx={{
                flexDirection: "row",
                width: 80,
                justifyContent: "space-evenly",
                position: "absolute",
                top: "200px",
                backgroundColor: theme.palette.grey[50],
                right: 0,
              }}
            >
              <Typography variant="h5">{block?.regularImages?.length}</Typography>
              <ImageIcon fontSize="large" color="action" />
            </FlexBox>
          )}
          <Box sx={{ flex: 10 }} onClick={handleClick}>
            <H3
              fontWeight="bold"
              fontSize={18}
              ellipsis
              mt={1}
              color="grey.800"
              textTransform="capitalize"
            >
              {!!block.tenantCode ? block.tenantCode : block.wescanCode}
            </H3>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <H5 fontWeight="light" fontSize={17} color={"grey.600"} textTransform="capitalize">
                {"Material: "}
              </H5>
              <H5
                ellipsis
                fontWeight="bold"
                fontSize={17}
                color={"primary.main"}
                ml={1}
                textTransform="capitalize"
              >
                {getMaterial(block)}
              </H5>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <H5 fontWeight="light" fontSize={17} color={"grey.600"} textTransform="capitalize">
                {"Variety: "}
              </H5>
              <H5
                ellipsis
                fontWeight="bold"
                fontSize={17}
                color={"primary.main"}
                ml={1}
                textTransform="capitalize"
              >
                {getVariety(block)}
              </H5>
            </Box>
          </Box>
          <Box sx={{ flex: 2 }}>
            <Box flexDirection="row" display="flex" justifyContent={"space-between"}>
              <H4>{block?.slabs?.totalCount || 0} Slabs</H4>
              <Box>{!!block?.scannedBy && <ThreeDRotation />}</Box>
            </Box>
            <FlexBox sx={{ justifyContent: "flex-end" }}>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  openNewTab("/slabsprocess/blocks/details/" + btoa(block.id));
                }}
                size={"medium"}
              >
                <OpenInNew fontSize="medium" />
              </IconButton>
            </FlexBox>
          </Box>
        </CardContent>
      </a>
    </ImageBox>
  );
};

export default BlockGalleryCard;
