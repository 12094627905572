import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Box, LinearProgress } from "@material-ui/core";
import { NetworkStatus, useQuery } from "@apollo/react-hooks";
import {
  CommercialVarieties,
  CommercialVarietiesVariables,
  CommercialVarietySortFields,
  SortDirection,
  CommercialVarietySort,
  CursorPaging,
  CommercialVarieties_commercialVarieties_edges_node,
} from "../../../data/graphQLModel";
import { QUERY_COMMERCIAL_VARIETIES } from "../../../data/graphQLQueries";
import NewTablePageHeaderComponent from "../../../components/Table/NewTablePageHeaderComponent";
import { IHeadCells } from "../../../components/Table/GeneralTableComponent";
import NewGeneralTableComponent from "../../../components/Table/NewGeneralTableComponent";
import { onChangePageCursor } from "../../../utils/utils";

const DEFAULT_LIMIT = 10;

const commVarHeadCells = [
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "materialtype.description.pt",
    numeric: false,
    disablePadding: false,
    label: "MaterialType",
  },
  {
    id: "system_variety.normativedesig",
    numeric: false,
    disablePadding: false,
    label: "System Variety",
  },
  { id: "createdAt", numeric: false, disablePadding: false, label: "Date" },
];

const getCommVarRowValues = (
  row: CommercialVarieties_commercialVarieties_edges_node
): IHeadCells[] => {
  return [
    {
      id: row.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    {
      id: row.name || "",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: row?.materialtype?.description?.pt || "",
      numeric: false,
      disablePadding: false,
      label: "MaterialType",
    },
    {
      id: row?.system_variety?.normativedesig || "",
      numeric: false,
      disablePadding: false,
      label: "System Variety",
    },
    {
      id: String(row.createdAt),
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
};

export default function CommercialVarietiesList() {
  const [paging, setPaging] = useState<CursorPaging>({ first: DEFAULT_LIMIT });
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sorting, setSorting] = useState<CommercialVarietySort[]>([
    { direction: SortDirection.DESC, field: CommercialVarietySortFields.createdAt },
  ]);

  const { loading, error, data, networkStatus } = useQuery<
    CommercialVarieties,
    CommercialVarietiesVariables
  >(QUERY_COMMERCIAL_VARIETIES, {
    variables: {
      paging,
      sorting,
    },
  });

  return (
    <>
      <Helmet>
        <title>Commercial Varieties | WeScan</title>
      </Helmet>
      <Box
        sx={{
          py: 3,
        }}
      >
        <NewTablePageHeaderComponent
          hasButton={true}
          buttonTitle={"New Comm. Varietiy"}
          entityNamePlural={"Commercial Varieties"}
          pathEntity={"/customerstables/commvarieties"}
          gridProps={{ sx: { mb: 2, mt: 2 } }}
        />
        <NewGeneralTableComponent
          data={
            !!data?.commercialVarieties
              ? data?.commercialVarieties?.edges?.map((edge) => edge.node)
              : []
          }
          loading={loading || networkStatus === NetworkStatus.fetchMore}
          error={error}
          LoadingComponent={() => (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          onChangePage={(e: any, page: number) => {
            onChangePageCursor(
              e,
              page,
              pageNumber,
              !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
              data?.commercialVarieties?.pageInfo || ({} as any),
              (pageNumber: number, newPaging: CursorPaging) => {
                setPaging(newPaging);
                setPageNumber(pageNumber);
              }
            );
          }}
          onRowsPerPageChange={(event) => {
            setPaging({ first: parseInt(event.target.value, 10) });
          }}
          page={pageNumber}
          rowsPerPage={!!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT}
          headCells={commVarHeadCells}
          totalCount={data?.commercialVarieties?.totalCount || 0}
          handleSort={(field, order) => {
            setSorting([{ field: field as CommercialVarietySortFields, direction: order }]);
          }}
          orderByField={sorting[0].field}
          orderDirection={sorting[0].direction}
          sortableFields={Object.values(CommercialVarietySortFields)}
          pathEntity={"/customerstables/commvarieties"}
          editPath={"/customerstables/commvarieties/details"}
          key={"Commercial Varieties"}
          getValues={getCommVarRowValues}
          base64OrDataOnEdit={"Base64"}
          base64OrDataOnDetails={"Base64"}
          base64OrDataOrIdOnDelete={"Data"}
          deletePath={"/customerstables/commvarieties/delete"}
          disableRemove={false}
          disableSelection={false}
          disableSelectAll={true}
          disableMultipleSelection={true}
          PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
          tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
        />
      </Box>
    </>
  );
}
