/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { Box, Card, CardContent, Container } from "@material-ui/core";
import swalert from "sweetalert";
import { DeleteOutline } from "@material-ui/icons";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import { useMutation } from "@apollo/react-hooks";
import {
  DeleteManySlabs,
  DeleteManySlabsVariables,
  Slabs_slabs_nodes,
} from "../../data/graphQLModel";
import { DELETE_MANY_SLABS } from "../../data/graphQLQueries";
import toast from "react-hot-toast";
import { getSlabImageSafe } from "../../utils/utils";

const DELETING_TOAST = "DELETING_TOAST";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    flex: "1",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    overflow: "hidden",
    // backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: "250ch",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

export default function DeleteSlab() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<Slabs_slabs_nodes>();
  const [slabs, setSlabs] = useState<Slabs_slabs_nodes[]>([]);

  const [mutationDeleteManySlabs] = useMutation<DeleteManySlabs, DeleteManySlabsVariables>(
    DELETE_MANY_SLABS
  );

  const validateSlab = () => {
    //@ts-ignore
    const data = location.data || location.state;
    if (data === undefined) {
      history.push("/slabmanagement");
      return;
    }

    setSlabs(data);
  };

  useEffect(() => {
    validateSlab();
  }, []);

  async function submitForm(e: any) {
    e.preventDefault();
    toast.loading("Deleting Slabs", { id: DELETING_TOAST });
    try {
      await mutationDeleteManySlabs({
        variables: {
          input: {
            filter: {
              id: { in: slabs.map((el: any) => el.id) },
            },
          },
        },
      });
      toast.dismiss(DELETING_TOAST);
      swalert("Success.", "Slab delete with success", "success");
    } catch (error: any) {
      toast.dismiss(DELETING_TOAST);
      swalert("Failed.", error.toString(), "error");
      return;
    }
    history.push("/slabmanagement");
  }

  async function confirmSubmission(e: any) {
    e.preventDefault();

    if (slabs?.length === 0 || slabs?.length < 1) {
      swalert("Information!", "Missing information!", "warning");
      return;
    }

    swalert({
      title: "DELETE",
      text: "Are you sure you want to delete these Products?",
      icon: "warning",
      //@ts-ignore
      buttons: true,
      dangerMode: true,
    }).then((willDelete: boolean) => {
      if (willDelete) {
        submitForm(e);
      }
    });
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/slabmanagement");
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>Delete Slabs</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={confirmSubmission}>
              {slabs && (
                <div className={classes.root}>
                  <ImageList
                    rowHeight={220}
                    className={classes.imageList}
                    cols={slabs.length < 4 ? slabs.length : 3}
                    gap={5}
                  >
                    {slabs.map((item: any) => (
                      <ImageListItem key={item.id}>
                        <img
                          src={getSlabImageSafe(item, false) || "images/imagemissing.png"}
                          alt={item.description}
                        />
                        <ImageListItemBar
                          title={item.code}
                          subtitle={<span>Description: {item.description}</span>}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>
              )}
              <div className="btnform">
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      onClick={confirmSubmission}
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                      color="secondary"
                    >
                      Delete
                      <DeleteOutline style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      onClick={handleCancel}
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
