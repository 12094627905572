/* eslint-disable */
import React, { useState } from "react";
import { AttachFile, Close } from "@material-ui/icons";
import {
  DialogProps,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import { H2 } from "../../components/GeneralComponents/Typography";
import FlexBox from "../../components/GeneralComponents/FlexBox";
import DropZone, { DropZoneProps } from "../ImageUpload/DropZone";
import ListOfAnnexedDocuments from "./ListOfAnnexedDocuments";
import { CommercialVariety_commercialVariety_annexedDocuments_nodes } from "../../data/graphQLModel";
import { SxProps } from "@material-ui/system";

interface AnnexeDocumentsDialogProps {
  onCancel: () => void;
  DialogProps?: Partial<DialogProps>;
  buttonTitle?: string;
  dialogTitle?: string;
  annexedDocs?: CommercialVariety_commercialVariety_annexedDocuments_nodes[];
  parentBoxSxProps?: SxProps<any>;
  DropZoneProps?: DropZoneProps;
}

export default function AnnexedDocumentButtonDialog({
  onCancel,
  DialogProps,
  buttonTitle = "Annexed Files",
  annexedDocs,
  parentBoxSxProps,
  DropZoneProps,
  dialogTitle = "Annexed Files",
}: AnnexeDocumentsDialogProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleDialog = () => setIsOpen(!isOpen);
  return (
    <Box sx={parentBoxSxProps}>
      <Button
        startIcon={<AttachFile />}
        onClick={() => {
          toggleDialog();
        }}
      >
        {buttonTitle} ({!!annexedDocs ? annexedDocs.length : 0})
      </Button>
      <Dialog
        {...DialogProps}
        open={isOpen}
        maxWidth={"xl"}
        fullWidth
        onClose={(e) => {
          onCancel();
        }}
      >
        <DialogTitle
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            pl: 2.5,
            pr: 2.5,
            zIndex: 101,
          }}
        >
          <H2>{dialogTitle}</H2>
          <FlexBox>
            <IconButton onClick={() => toggleDialog()}>
              <Close className="close" fontSize="medium" color="primary" />
            </IconButton>
          </FlexBox>
        </DialogTitle>
        <DialogContent>
          <ListOfAnnexedDocuments direction="row" documents={annexedDocs} key="annexDocs" />
          {DropZoneProps && (
            <DropZone
              {...DropZoneProps}
              accept={DropZoneProps.accept ? DropZoneProps.accept : ".pdf,.xlsx,.txt"}
              title={DropZoneProps.title ? DropZoneProps.title : "Drag & Drop Files"}
              toReplaceExisting={annexedDocs && annexedDocs.length > 0}
              multiple={DropZoneProps.multiple ? DropZoneProps.multiple : false}
              key="AnnexedDocFileDropZone"
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
