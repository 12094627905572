/* eslint-disable */
import { useHistory } from "react-router-dom";
import { Typography, CardContent, Box, useTheme } from "@material-ui/core";
import { Check, NotInterested } from "@material-ui/icons";
import "./styles.css";
import { getSlabImageSafe } from "../../utils/utils";
import { SharedPackage_sharedPackage_slabs_edges_node } from "../../data/graphQLModel";
import ImageBox from "../GeneralComponents/ImageBox";

const getVariety = (slab: SharedPackage_sharedPackage_slabs_edges_node) => {
  return (
    " " +
    (slab?.commercial_variety?.name
      ? slab?.commercial_variety.name
      : slab?.variety?.normativedesig
      ? slab.variety.normativedesig
      : "< >")
  );
};

const getFinish = (slab: SharedPackage_sharedPackage_slabs_edges_node) => {
  return slab?.commercial_finishing?.description
    ? slab?.commercial_finishing?.description?.pt
    : slab?.finish?.description?.pt
    ? slab?.finish?.description?.pt
    : "< >";
};

const getMaterial = (slab: SharedPackage_sharedPackage_slabs_edges_node) => {
  return slab?.commercial_variety?.materialtype?.description?.pt
    ? slab?.commercial_variety?.materialtype?.description.pt
    : slab?.typematerial?.description?.pt
    ? slab?.typematerial?.description?.pt
    : "< >";
};

interface StoneCatalogProps {
  slab: SharedPackage_sharedPackage_slabs_edges_node;
  keyValue: string | undefined;
}

export default function StoneCatalog({ slab, keyValue }: StoneCatalogProps) {
  const history = useHistory();
  const theme = useTheme();

  const handleGetSlabDetails = () => {
    if (!!keyValue && !!slab.id) {
      history.push(`/share/slab/?slab=${btoa(slab.id)}&keyValue=${keyValue}`);
    } else if (!!slab.id) {
      history.push("/share/slab/" + btoa(slab.id));
    }
  };

  return (
    <ImageBox
      sx={{
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
      }}
      hoverEffect
      onClick={handleGetSlabDetails}
    >
      <img src={getSlabImageSafe(slab, true) || ""} className="card-img-top" alt={slab.code} />
      <CardContent>
        <Typography variant="button" component="div" align="left">
          <Box fontWeight="fontWeightBold" fontSize={17} marginY="5px">
            {getVariety(slab)}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box fontWeight="fontWeightLight" fontSize={14} color={"grey.600"}>
              {"Finishing: "}
            </Box>
            <Box fontWeight="fontWeightBold" fontSize={14} marginLeft={1} color={"grey.600"}>
              {getFinish(slab)}
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box fontWeight="fontWeightLight" fontSize={14} color={"grey.600"}>
              {"Material: "}
            </Box>
            <Box fontWeight="fontWeightBold" fontSize={14} marginLeft={1} color={"grey.600"}>
              {getMaterial(slab)}
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box fontWeight="fontWeightLight" fontSize={14} color={"grey.600"}>
              WxHxT:
            </Box>
            <Box fontWeight="fontWeightBold" fontSize={14} marginLeft={1} color={"grey.600"}>
              {slab.width ? slab.width : 0} X {slab.height ? slab.height : 0} X{" "}
              {slab.thickness ? slab.thickness : 0}
            </Box>
          </Box>
          <Box fontWeight="fontWeightBold" fontSize={14} color={"grey.600"}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <Box fontWeight="fontWeightBold" fontSize={14} color={"grey.600"}>
                {slab.code ? slab.code : " < > "}
              </Box>
              {slab.inStock ? (
                <Check
                  style={{
                    color: "green",
                    width: 30,
                    height: 30,
                    marginLeft: 5,
                  }}
                />
              ) : (
                <NotInterested
                  style={{
                    color: "red",
                    width: 30,
                    height: 30,
                    marginLeft: 5,
                  }}
                />
              )}
            </div>
          </Box>
        </Typography>
      </CardContent>
    </ImageBox>
  );
}
