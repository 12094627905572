import { ExpandedTags, GpsTags } from "exifreader";
import getImageMetadata from "./getImageMetadata";

const metaKeys = ["xmp"];

export default async function getKeyValueMetadata(
  file: File
): Promise<{ [key: string]: string | number }> {
  let mappedMetadata: { [key: string]: string | number } = {};
  // it tries to catch metadata, if it fails (check catch)
  try {
    const metadata = await getImageMetadata(file);

    metaKeys.forEach((key_string) => {
      for (const key in metadata[key_string as keyof typeof metadata]) {
        if (
          Object.prototype.hasOwnProperty.call(metadata[key_string as keyof typeof metadata], key)
        ) {
          //@ts-ignore
          const element = metadata[key_string as keyof typeof metadata][key];
          if (element.description) {
            mappedMetadata[key] = element.description;
          }
        }
      }
    });

    for (const key in metadata?.gps) {
      if (Object.prototype.hasOwnProperty.call(metadata?.gps, key)) {
        const element = metadata?.gps[key as keyof GpsTags];
        if (element) {
          mappedMetadata[key] = element;
        }
      }
    }
  } catch (err) {
    //do nothing
  }
  // and return initial value of mappedMetadata
  return mappedMetadata;
}
