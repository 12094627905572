import { fabric } from "fabric";

/**
 * Exposure filter class
 * @class fabric.Image.filters.Exposure
 * @memberOf fabric.Image.filters
 * @extends fabric.Image.filters.BaseFilter
 * @see {@link fabric.Image.filters.Exposure#initialize} for constructor definition
 * @see {@link http://fabricjs.com/image-filters|ImageFilters demo}
 * @example
 * var filter = new fabric.Image.filters.Exposure({
 *   add here an example of how to use your filter
 * });
 * object.filters.push(filter);
 * object.applyFilters();
 */
fabric.Image.filters.Exposure = fabric.util.createClass(
  fabric.Image.filters.BaseFilter,
  /** @lends fabric.Image.filters.Exposure.prototype */ {
    /**
     * Filter type
     * @param {String} type
     * @default
     */
    type: "Exposure",

    /**
     * Fragment source for the Exposure program
     */
    /**
     */
    fragmentSource:
      "precision highp float;\n" +
      "uniform sampler2D uTexture;\n" +
      "uniform float uExposure;\n" +
      "varying vec2 vTexCoord;\n" +
      "void main() {\n" +
      "vec4 color = texture2D(uTexture, vTexCoord);\n" +
      // add your gl code here
      "float exposure=uExposure;\n" +
      "vec3 inColor = color.xyz; \n" +
      "vec3 outColor = (1.0 + exposure) * inColor;\n" +
      "gl_FragColor = vec4(outColor, color.a);\n" +
      "}",

    /**
     * Exposure value, from -1 to 1.
     * translated to -255 to 255 for 2d
     * 0.0039215686 is the part of 1 that get translated to 1 in 2d
     * @param {Number} exposure
     * @default
     */
    exposure: 0,

    /**
     * Describe the property that is the filter parameter
     * @param {String} m
     * @default
     */
    mainParameter: "exposure",

    /**
     * Apply the Exposure operation to a Uint8ClampedArray representing the pixels of an image.
     *
     * @param {Object} options
     * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
     */
    applyTo2d: function (options) {
      if (this.exposure === 0) {
        // early return if the parameter value has a neutral value
        return;
      }
      var imageData = options.imageData,
        data = imageData.data,
        i,
        len = data.length;
      for (i = 0; i < len; i += 4) {
        // insert here your code to modify data[i]
      }
    },

    /**
     * Return WebGL uniform locations for this filter's shader.
     *
     * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
     * @param {WebGLShaderProgram} program This filter's compiled shader program.
     */
    getUniformLocations: function (gl, program) {
      return {
        uexposure: gl.getUniformLocation(program, "uExposure"),
      };
    },

    /**
     * Send data from this filter to its shader program's uniforms.
     *
     * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
     * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
     */
    sendUniformData: function (gl, uniformLocations) {
      gl.uniform1f(uniformLocations.uexposure, this.exposure);
    },
  }
);

/**
 * Create filter instance from an object representation
 * @static
 * @param {Object} object Object to create an instance from
 * @returns {Promise<fabric.Image.filters.Exposure>}
 */
fabric.Image.filters.Exposure.fromObject = fabric.Image.filters.BaseFilter.fromObject;

/**
 * Temperature filter class
 * @class fabric.Image.filters.Temperature
 * @memberOf fabric.Image.filters
 * @extends fabric.Image.filters.BaseFilter
 * @see {@link fabric.Image.filters.Temperature#initialize} for constructor definition
 * @see {@link http://fabricjs.com/image-filters|ImageFilters demo}
 * @example
 * var filter = new fabric.Image.filters.Temperature({
 *   add here an example of how to use your filter
 * });
 * object.filters.push(filter);
 * object.applyFilters();
 */
fabric.Image.filters.Temperature = fabric.util.createClass(
  fabric.Image.filters.BaseFilter,
  /** @lends fabric.Image.filters.Temperature.prototype */ {
    /**
     * Filter type
     * @param {String} type
     * @default
     */
    type: "Temperature",

    /**
     * Fragment source for the temperature program
     */
    /**
     * https://www.shadertoy.com/view/4sc3D7
     */
    fragmentSource:
      "precision highp float;\n" +
      "uniform sampler2D uTexture;\n" +
      "uniform float uTemperature;\n" +
      "varying vec2 vTexCoord;\n" +
      "#define WithQuickAndDirtyLuminancePreservation  \n" +
      "const float LuminancePreservationFactor = 1.0;\n" +
      "// Valid from 1000 to 40000 K (and additionally 0 for pure full white)\n" +
      "vec3 colorTemperatureToRGB(const in float temperature){\n" +
      "// Values from: http://blenderartists.org/forum/showthread.php?270332-OSL-Goodness&p=2268693&viewfull=1#post2268693\n" +
      "mat3 m = (temperature <= 6500.0) ? mat3(vec3(0.0, -2902.1955373783176, -8257.7997278925690),\n" +
      "vec3(0.0, 1669.5803561666639, 2575.2827530017594),\n" +
      "vec3(1.0, 1.3302673723350029, 1.8993753891711275)) : \n" +
      "mat3(vec3(1745.0425298314172, 1216.6168361476490, -8257.7997278925690),\n" +
      "vec3(-2666.3474220535695, -2173.1012343082230, 2575.2827530017594),\n" +
      "vec3(0.55995389139931482, 0.70381203140554553, 1.8993753891711275)); \n" +
      "return mix(clamp(vec3(m[0] / (vec3(clamp(temperature, 1000.0, 40000.0)) + m[1]) + m[2]), vec3(0.0), vec3(1.0)), vec3(1.0), smoothstep(1000.0, 0.0, temperature));\n" +
      "}\n" +
      "void main() {\n" +
      "vec4 color = texture2D(uTexture, vTexCoord);\n" +
      // add your gl code here
      "float temperature=uTemperature;\n" +
      "float temperatureStrength = 1.0;\n" +
      "vec3 inColor = color.xyz; \n" +
      "vec3 outColor = mix(inColor, inColor * colorTemperatureToRGB(temperature), temperatureStrength);\n" +
      "#ifdef WithQuickAndDirtyLuminancePreservation\n" +
      "outColor *= mix(1.0, dot(inColor, vec3(0.2126, 0.7152, 0.0722)) / max(dot(outColor, vec3(0.2126, 0.7152, 0.0722)), 1e-5), LuminancePreservationFactor); \n" +
      "#endif\n" +
      // "fragColor = vec4(outColor, color.a);\n" +
      "gl_FragColor = vec4(outColor, color.a);\n" +
      "}",

    /**
     * Temperature value, from -1 to 1.
     * translated to -255 to 255 for 2d
     * 0.0039215686 is the part of 1 that get translated to 1 in 2d
     * @param {Number} temperature
     * @default
     */
    temperature: 0,

    /**
     * Describe the property that is the filter parameter
     * @param {String} m
     * @default
     */
    mainParameter: "temperature",

    /**
     * Apply the Temperature operation to a Uint8ClampedArray representing the pixels of an image.
     *
     * @param {Object} options
     * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
     */
    applyTo2d: function (options) {
      if (this.temperature === 0) {
        // early return if the parameter value has a neutral value
        return;
      }
      var imageData = options.imageData,
        data = imageData.data,
        i,
        len = data.length;
      for (i = 0; i < len; i += 4) {
        // insert here your code to modify data[i]
      }
    },

    /**
     * Return WebGL uniform locations for this filter's shader.
     *
     * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
     * @param {WebGLShaderProgram} program This filter's compiled shader program.
     */
    getUniformLocations: function (gl, program) {
      return {
        utemperature: gl.getUniformLocation(program, "uTemperature"),
      };
    },

    /**
     * Send data from this filter to its shader program's uniforms.
     *
     * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
     * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
     */
    sendUniformData: function (gl, uniformLocations) {
      gl.uniform1f(uniformLocations.utemperature, this.temperature);
    },
  }
);

/**
 * Create filter instance from an object representation
 * @static
 * @param {Object} object Object to create an instance from
 * @returns {Promise<fabric.Image.filters.Temperature>}
 */
fabric.Image.filters.Temperature.fromObject = fabric.Image.filters.BaseFilter.fromObject;
