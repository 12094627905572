import { useMutation } from "@apollo/react-hooks";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DeleteOneEntityForm from "../../../components/Form/DeleteEntityForm";
import LoadingFitCenter from "../../../components/GeneralComponents/LoadingFitCenter";
import {
  DeleteOneStoneEdge,
  DeleteOneStoneEdgeVariables,
  StoneEdges_stoneEdges_edges_node,
} from "../../../data/graphQLModel";
import { MUTATION_DELETE_ONE_STONE_EDGE } from "../../../data/graphQLQueries";

export default function DeleteStoneEdge() {
  const location = useLocation<
    StoneEdges_stoneEdges_edges_node[] | StoneEdges_stoneEdges_edges_node
  >();

  const [mutationDeleteOneStoneEdge] = useMutation<DeleteOneStoneEdge, DeleteOneStoneEdgeVariables>(
    MUTATION_DELETE_ONE_STONE_EDGE
  );

  const deleteOne = async (id: string) => {
    await mutationDeleteOneStoneEdge({
      variables: {
        input: {
          id: id,
        },
      },
    });
  };

  return !!location.state ? (
    <DeleteOneEntityForm
      mutation={deleteOne}
      entity_object={
        (location.state as StoneEdges_stoneEdges_edges_node[]).length > 0
          ? (location.state as StoneEdges_stoneEdges_edges_node[])[0]
          : (location.state as StoneEdges_stoneEdges_edges_node)
      }
      entity_name={"Stone Edge"}
      path={"/customerstables/stoneedge"}
    />
  ) : (
    <LoadingFitCenter />
  );
}
