import { 
    LOADING,
    NOT_LOADING
 } from "../actions/isLoadingActions";

const INITIAL_STATE = {
    loading: false,
}

const isLoadingReducer = (state= INITIAL_STATE, action ) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                loading: action.payload.loading
            }
        }
        case NOT_LOADING: {
            return {
                ...state,
                loading: action.payload.loading
            }
        }
        default: {
            return state;
        }
    }
}

export default isLoadingReducer;