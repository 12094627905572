import React, { useEffect, useState } from "react";
//@ts-ignore
import { Helmet } from "react-helmet";
import { Box, Container, IconButton, LinearProgress } from "@material-ui/core";
import { NetworkStatus, useMutation, useQuery } from "@apollo/react-hooks";
import NewGeneralTableComponent, {
  ICompleteHeadCells,
} from "../../components/Table/NewGeneralTableComponent";
import {
  BlockModelFilter,
  BlockModels,
  BlockModelSort,
  BlockModelSortFields,
  BlockModelsVariables,
  BlockModels_blockModels_edges_node,
  CursorPaging,
  DeleteManyBlockModels,
  DeleteManyBlockModelsVariables,
  SortDirection,
} from "../../data/graphQLModel";
import { copyObject, onChangePageCursor } from "../../utils/utils";
import {
  MUTATION_DELETE_MANY_BLOCKMODELS,
  QUERY_MANY_BLOCKMODELS,
  QUERY_SCANNERS,
} from "../../data/graphQLQueries";
import NewTablePageHeaderComponent from "../../components/Table/NewTablePageHeaderComponent";
import AutocompleteFilterNew from "../../components/CatalogFilters/AutoCompleteFilterNew";
import { format } from "date-fns";
import { Delete } from "@material-ui/icons";
import swalert from "sweetalert";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

const DELETING_TOAST = "DELETING_TOAST";

export const blockHeadCells: ICompleteHeadCells[] = [
  { id: "Code", numeric: false, disablePadding: false, label: "Code" },
  { id: "basearea", numeric: false, disablePadding: false, label: "Base Area" },
  { id: "volume", numeric: false, disablePadding: false, label: "Volume" },
  { id: "material", numeric: false, disablePadding: false, label: "Material" },
  { id: "variety", numeric: false, disablePadding: false, label: "Variety" },
  { id: "scanner", numeric: false, disablePadding: false, label: "Scanner" },
  { id: "createdAt", numeric: false, disablePadding: false, label: "Date" },
];

export const getBlockRowValues = (
  row: BlockModels_blockModels_edges_node
): ICompleteHeadCells[] => {
  return [
    {
      id: (!!row?.tenantCode && row.tenantCode !== "" ? row.tenantCode : row.wescanCode) + "",
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    {
      id: row?.baseArea + "",
      numeric: true,
      disablePadding: false,
      label: "Base Area",
    },
    {
      id: (row?.volume || 0) + "",
      numeric: true,
      disablePadding: false,
      label: "Volume",
    },
    {
      id: row?.materialtype?.description?.pt || "",
      numeric: false,
      disablePadding: false,
      label: "Material",
    },
    {
      id: !!row?.commercial_variety?.name
        ? row?.commercial_variety?.name
        : row?.variety?.normativedesig || "",
      numeric: false,
      disablePadding: false,
      label: "Variety",
    },
    {
      id: row?.scannedBy?.code || "",
      numeric: false,
      disablePadding: false,
      label: "Scanner",
    },
    {
      id: format(new Date(row.createdAt), "yyyy-MM-dd"),
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
};

const DEFAULT_LIMIT = 10;
const DEFAULT_SORTING: BlockModelSort[] = [
  { direction: SortDirection.DESC, field: BlockModelSortFields.createdAt },
];
const DEFAULT_PAGING: CursorPaging = { first: 10 };

export default function BlockList() {
  const history = useHistory();

  const [filter, setFilter] = useState<BlockModelFilter>({});
  const [paging, setPaging] = useState<CursorPaging>(DEFAULT_PAGING);
  const [sorting, setSorting] = useState<BlockModelSort[]>(DEFAULT_SORTING);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const handleAutoCompleteChange = (e: any) => {
    let key = e.target.name as keyof BlockModelFilter;
    let value = e.target.value as string;
    if (e.target.value) {
      setFilter({ ...filter, [key]: { eq: value } });
    } else {
      const newJobFilters = copyObject(filter, [key]);
      setFilter(newJobFilters);
    }
  };

  // Delete block function
  const [mutationDeleteManyBlocks] = useMutation<
    DeleteManyBlockModels,
    DeleteManyBlockModelsVariables
  >(MUTATION_DELETE_MANY_BLOCKMODELS);

  /**
   * Delete Many Blocks Function
   * @param blocks Blocks to Delete
   */
  const deleteMany = async (
    blocks: {
      [key: string]: any;
      id: string;
    }[]
  ) => {
    if (!!blocks && blocks.length > 0) {
      swalert({
        title: "DELETE",
        text: "Are you sure you want to delete these Blocks?",
        icon: "warning",
        //@ts-ignore
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete: boolean) => {
        if (willDelete) {
          toast.loading("Deleting Blocks", { id: DELETING_TOAST });
          await mutationDeleteManyBlocks({
            variables: {
              input: {
                filter: { id: { in: blocks.map((el) => el.id) } },
              },
            },
          })
            .then(async (res) => {
              toast.dismiss(DELETING_TOAST);
              if (!!res?.errors) {
                swalert("Error", res?.errors?.map((el) => el.message)?.join("; "), "error");
              } else {
                swalert("Success.", "Blocks Deleted", "success");
                window?.location?.reload();
              }
            })
            .catch((err) => {
              toast.dismiss(DELETING_TOAST);
              swalert("Error", err?.toString(), "error");
            });
        }
      });
    } else {
      swalert("Error", "Encountered an error when attempting to delete the Blocks", "error");
    }
  };

  const { loading, error, data, networkStatus, refetch } = useQuery<
    BlockModels,
    BlockModelsVariables
  >(QUERY_MANY_BLOCKMODELS, {
    variables: {
      paging,
      sorting,
      filter,
    },
  });

  useEffect(() => {
    setPaging(DEFAULT_PAGING);
    setSorting(DEFAULT_SORTING);
    setPageNumber(0);
  }, [filter]);

  return (
    <>
      <Helmet>
        <title>Block Table | WeScan</title>
      </Helmet>
      <Box
        sx={{
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          <NewTablePageHeaderComponent
            hasButton={false}
            buttonTitle={"New Block"}
            entityNamePlural={"Blocks"}
            pathEntity={"/slabsprocess/block"}
            gridProps={{ sx: { mb: 2, mt: 2 } }}
          />
          <NewGeneralTableComponent
            data={!!data?.blockModels ? data?.blockModels?.edges?.map((edge) => edge.node) : []}
            loading={loading || networkStatus === NetworkStatus.fetchMore}
            error={error}
            LoadingComponent={() => (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
            onChangePage={(e: any, page: number) => {
              onChangePageCursor(
                e,
                page,
                pageNumber,
                !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
                data?.blockModels?.pageInfo || ({} as any),
                (pageNumber: number, newPaging: CursorPaging) => {
                  setPaging(newPaging);
                  setPageNumber(pageNumber);
                }
              );
            }}
            onRowsPerPageChange={(event) => {
              setPaging({ first: parseInt(event.target.value, 10) });
            }}
            page={pageNumber}
            rowsPerPage={
              !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT
            }
            headCells={blockHeadCells}
            totalCount={data?.blockModels?.totalCount || 0}
            handleSort={(field, order) => {
              setSorting([{ field: field as BlockModelSortFields, direction: order }]);
            }}
            orderByField={sorting[0].field}
            orderDirection={sorting[0].direction}
            sortableFields={Object.values(BlockModelSortFields)}
            pathEntity={"/slabsprocess/blocks"}
            editPath={"/slabsprocess/blocks/details"}
            key={"Blocks"}
            getValues={getBlockRowValues}
            base64OrDataOnEdit={"Base64"}
            base64OrDataOnDetails={"Base64"}
            base64OrDataOrIdOnDelete={"ID"}
            disableRemove={true}
            disableSelection={false}
            disableSelectAll={true}
            disableMultipleSelection={false}
            PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
            tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            toolbarOptions={[
              {
                element: (
                  <IconButton aria-label="delete">
                    <Delete />
                  </IconButton>
                ),
                onClick: (params) => {
                  deleteMany(params);
                },
                title: "",
              },
            ]}
          />
        </Container>
      </Box>
    </>
  );
}
