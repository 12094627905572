export const getDefaultValueOfAutocompleteFirstQuery = (
  res: any[],
  filterToSelectDefaultItemForFirstQueryResults: {
    [itemKey: string]: string | number | boolean | null | undefined;
  }
): { [keys: string]: any; id: string } | undefined => {
  return res.find((itm) => {
    for (const key in filterToSelectDefaultItemForFirstQueryResults) {
      if (
        Object.prototype.hasOwnProperty.call(filterToSelectDefaultItemForFirstQueryResults, key)
      ) {
        const filterValue = filterToSelectDefaultItemForFirstQueryResults[key];
        if (itm[key as unknown as keyof typeof itm] !== filterValue) {
          return false;
        }
      }
    }
    return true;
  });
};
