/* eslint-disable */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams, NavLink } from "react-router-dom";
import { Box, Card, CardContent, Grid, IconButton, Skeleton } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import swalert from "sweetalert";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import CustomersTable from '../../customers/CustomersTable';
import { loadingComponent, notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { Edit, RemoveCircleOutline } from "@material-ui/icons";
import { MuiThemeProps } from "../../../theme/theme";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { MUTATION_UPDATE_MANY_SLABS, QUERY_ONE_PACKING } from "../../../data/graphQLQueries";
import {
  Packing,
  PackingVariables,
  SlabFilter,
  Slabs_slabs_nodes,
  UpdateManySlabs,
  UpdateManySlabsVariables,
} from "../../../data/graphQLModel";
import SlabsTable from "../../../components/slabs.components/SlabsTable";
import { H2 } from "../../../components/GeneralComponents/Typography";
import ShareLinkButtonDialog from "../../../components/ShareLink/DialogShareLink";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  margin: {
    marginBottom: "2ch",
    marginTop: "2ch",
  },
  margintwo: {
    marginBottom: "2ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function DetailsProject() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  let { parpack } = useParams() as { parpack: string };

  const { loading, error, data } = useQuery<Packing, PackingVariables>(QUERY_ONE_PACKING, {
    variables: {
      id: atob(parpack),
    },
  });

  // ########################################## START SLABS ##########################################
  const [slabsFilter, setSlabsFilter] = useState<SlabFilter>({ packing: { eq: atob(parpack) } });

  const [mutationUpdateManySlabs] = useMutation<UpdateManySlabs, UpdateManySlabsVariables>(
    MUTATION_UPDATE_MANY_SLABS
  );

  async function removeSlabs(slabs: Partial<Slabs_slabs_nodes>[]) {
    swalert({
      title: "Remove",
      text: "Are you sure you want to remove these Slabs from the package?",
      icon: "warning",
      //@ts-ignore
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete: boolean) => {
      if (willDelete) {
        dispatch(loadingComponent());
        await mutationUpdateManySlabs({
          variables: {
            input: {
              filter: {
                id: { in: slabs.map((el) => el.id as string) },
              },
              update: {
                packing: null,
              },
            },
          },
        })
          .then((res) => {
            if (!!res.errors) {
              swalert("Failed.", res?.errors?.toString(), "error");
            } else {
              swalert("Success.", "Slabs removed", "success");
              setSlabsFilter(JSON.parse(JSON.stringify(slabsFilter)));
            }
          })
          .catch((err) => {
            dispatch(notLoadingComponent());
            swalert("Failed.", err, "error");
          });
        dispatch(notLoadingComponent());
      }
    });
  }

  // ########################################## END SLABS ##########################################

  function handleGoBack(event: any) {
    event.preventDefault();
    // history.push('/customerstables/packages');
    history.goBack();
  }

  // ########################################## END STATE CHANGE ##########################################

  // const handleStockMovement = async (e: any) => {
  //   e.preventDefault();
  //   swalert({
  //     text: "Observation",
  //     content: {
  //       element: "input",
  //     },
  //     //@ts-ignore
  //     button: {
  //       text: "Send",
  //       closeModal: false,
  //     },
  //   }).then((obs) => {
  //     if (obs) {
  //       let opt = packageInStock ? "SHIPPED" : "RECEIVED";
  //       confirmStockMovement(e, obs, opt);
  //     }
  //   });
  // };

  // async function confirmStockMovement(e: any, obs: string, mov: any) {
  //   e.preventDefault();

  //   swalert({
  //     title: "Package Stock Movement",
  //     text: "Are you sure?",
  //     icon: "warning",
  //     //@ts-ignore
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete: boolean) => {
  //     if (willDelete) {
  //       submitStockMovement(e, obs, mov);
  //     }
  //   });
  // }

  // async function submitStockMovement(e: any, obs: string, mov: any) {
  //   e.preventDefault();

  //   dispatch(loadingComponent());
  //   try {
  //     //GUILHERME
  //     let messageMovStock = "";
  //     if (packageInStock) {
  //       messageMovStock = await movStockServices.createMovStockFromPackage(
  //         data?.packing?.id,
  //         mov,
  //         obs
  //       );
  //     } else {
  //       messageMovStock = await movStockServices.deleteMovStockPackage(data?.packing?.id);
  //     }
  //     swalert("Success.", messageMovStock, "success");
  //   } catch (error: any) {
  //     dispatch(notLoadingComponent());
  //     swalert("Failed.", error, "error");
  //     return;
  //   }
  //   dispatch(notLoadingComponent());
  // }

  // ########################################## END STOCK MOVEMENT ##########################################

  return (
    <div>
      <Box style={{ marginBottom: "10px" }}>
        <Card>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <FormControl variant="outlined">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  type="submit"
                  disabled={history?.length <= 1}
                  onClick={handleGoBack}
                  color="primary"
                  sx={{ mr: 1 }}
                >
                  <ArrowBackIosIcon fontSize="large" />
                </IconButton>
                <H2>Details Package</H2>
              </Box>
            </FormControl>

            <Box
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <NavLink to={"/customerstables/packages/editpackage/" + parpack}>
                <Button
                  disabled={!!!data?.packing}
                  startIcon={<Edit />}
                  color="primary"
                  variant="contained"
                  style={{ position: "relative", marginBottom: "5px", marginRight: "10px" }}
                >
                  Edit Package
                </Button>
              </NavLink>

              <ShareLinkButtonDialog
                shareType="package"
                sharedId={data?.packing?.id || ""}
                disabled={!!!data?.packing}
                buttonProps={{
                  style: { position: "relative", marginBottom: "5px", marginRight: "10px" },
                  color: "secondary",
                  variant: "contained",
                }}
              />
            </Box>
          </div>
        </Card>
      </Box>
      <Card>
        <CardContent>
          <form className={classes.form}>
            {!error && (
              <>
                {/* <Box style={{ display: "flex", flexBasis: 3, margin: 20 }}>
                  <h4 style={{ flex: 2 }}>Package Stock Actions:</h4>
                  <Button
                    onClick={(e) => handleStockMovement(e)}
                    color="primary"
                    variant="contained"
                    style={{ position: "relative" }}
                  >
                    {packageInStock ? "Clear Stock" : "Undo Stock Movement"}
                  </Button>
                </Box> */}
                <Grid container justifyContent="space-around">
                  <Grid item md={5} xs={12}>
                    <Grid>
                      <FormControl fullWidth variant="outlined">
                        {loading ? (
                          <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                            <TextField value={""} />
                          </Skeleton>
                        ) : (
                          <TextField
                            id="CodeProcking"
                            label="Code"
                            name="code"
                            value={data?.packing?.code}
                            placeholder="Code"
                            disabled
                            variant="outlined"
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid>
                      <FormControl fullWidth variant="outlined">
                        {loading ? (
                          <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                            <TextField value={""} />
                          </Skeleton>
                        ) : (
                          <TextField
                            id="idPackage"
                            label="Package"
                            className={classes.margin}
                            name="package"
                            value={data?.packing?.packing}
                            placeholder="Package"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid>
                      <FormControl fullWidth variant="outlined">
                        {loading ? (
                          <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                            <TextField value={""} />
                          </Skeleton>
                        ) : (
                          <TextField
                            id="typepack"
                            label="Package Type"
                            name="type"
                            className={classes.margintwo}
                            disabled
                            value={data?.packing?.packingtype?.type}
                            placeholder="Package Type"
                            required
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                          />
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth variant="outlined">
                      {loading ? (
                        <Skeleton
                          component={"div"}
                          variant="rectangular"
                          width={"100%"}
                          height={200}
                          sx={{ marginTop: 2 }}
                        >
                          <TextField value={""} />
                        </Skeleton>
                      ) : (
                        <TextField
                          id="obsPackage"
                          label="Observation"
                          name="observation"
                          placeholder="Observation"
                          disabled
                          value={data?.packing?.observation}
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={9}
                          variant="outlined"
                        />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid marginTop={4} marginBottom={10}>
              <SlabsTable
                requestAll={true}
                showHeader={false}
                filter={slabsFilter}
                showEye
                tableProps={{
                  disableSelection: false,
                  paperProps: {
                    style: {
                      height: "100%",
                    },
                  },
                  toolbarOptions: [
                    {
                      element: (
                        <IconButton aria-label="Remove Slabs">
                          <RemoveCircleOutline />
                        </IconButton>
                      ),
                      onClick: (params) => {
                        removeSlabs(params);
                      },
                      title: "",
                    },
                  ],
                }}
              />
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
