import { useQuery } from "@apollo/react-hooks";
import { Card, CardActionArea, CardContent, CardMedia, Container, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { H1, H4 } from "../../components/GeneralComponents/Typography";
import { Scanners, ScannersVariables } from "../../data/graphQLModel";
import { QUERY_SCANNERS } from "../../data/graphQLQueries";

const ScannersPage: React.FC<undefined> = () => {
  const history = useHistory();
  const scanners = useQuery<Scanners, ScannersVariables>(QUERY_SCANNERS, {
    onCompleted(data) {
      // If user only has one Scanner, go to that Scanner's page
      if (!!data?.scanners?.edges && data.scanners?.edges?.length === 1) {
        history.push(`/scanners/${window?.btoa(data.scanners?.edges[0]?.node?.id)}`);
      }
    },
  });

  return (
    <Grid container spacing={3} sx={{ justifyContent: "flex-start", mt: 10 }} direction="row">
      {scanners?.data?.scanners?.edges?.map((el) => {
        return (
          <Grid item lg={3}>
            <Card key={el.node.id}>
              <CardActionArea
                onClick={() => {
                  history.push(`/scanners/${window?.btoa(el.node.id)}`);
                }}
              >
                <CardMedia
                  component="img"
                  image="/images/products/scanner_horizontal.png"
                  alt="scanner"
                />
                <CardContent>
                  <H1>{el?.node?.description}</H1>
                  <H4 color="text.secondary"></H4>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ScannersPage;
