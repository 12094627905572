import { useState } from "react";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  CategoriesVariables,
  CursorPaging,
  CategorySort,
  Categories,
  Categories_categories_edges_node,
} from "../../data/graphQLModel";
import { QUERY_CATEGORIES } from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { EntityAutocompleteProps } from "./types";
import { getDefaultValueOfAutocompleteFirstQuery } from "./utils";

/**
 * ## Reusable Category Autocomplete Component
 *
 * ### Default Autocomplete property values:
 *
 * #### name: category
 * #### label: Category
 * #### continueToLoadResultsInComponent: false
 * #### shouldFieldBeLocked: false
 *
 */
const CategoryAutocomplete = ({
  errors,
  continueToLoadResultsInComponent = false,
  shouldFieldBeLocked = false,
  helperText,
  value,
  onChange,
  selectItemOnFirstQueryIfSoloRecord,
  filterToSelectDefaultItemForFirstQueryResults,
  ...props
}: EntityAutocompleteProps) => {
  const [categories, setCategories] = useState<Categories_categories_edges_node[]>([]);
  const [firstQueryDone, setFirstQueryDone] = useState<boolean>(false);

  return (
    <AutocompleteWithQuery
      onChange={onChange}
      value={value}
      items={categories}
      label="Category"
      name="category"
      title="Categories"
      TextFieldProps={{
        error: !!errors,
        helperText: helperText,
        label: "Category",
        disabled: shouldFieldBeLocked,
      }}
      continueToLoadResultsInComponent={continueToLoadResultsInComponent}
      onTextChange={async (value: string) => {
        await queryValuesForFilter<
          CategoriesVariables["filter"],
          CursorPaging,
          CategorySort,
          Categories
        >(
          value,
          QUERY_CATEGORIES,
          (res) => {
            let endRes = res?.data?.categories?.edges?.map((el) => el.node);
            if (!firstQueryDone) {
              setFirstQueryDone(true);
              if (!!endRes && endRes.length === 1 && !!selectItemOnFirstQueryIfSoloRecord) {
                onChange({
                  target: {
                    value: endRes[0].id,
                    name: "category",
                  },
                });
              } else if (
                !!filterToSelectDefaultItemForFirstQueryResults &&
                !!endRes &&
                endRes.length > 1
              ) {
                let defaultItem = getDefaultValueOfAutocompleteFirstQuery(
                  endRes,
                  filterToSelectDefaultItemForFirstQueryResults
                );
                !!defaultItem &&
                  onChange({
                    target: {
                      value: defaultItem.id,
                      name: "category",
                    },
                  });
              }
            }
            setCategories(endRes);
          },
          {
            filter: { and: [{ code: { iLike: value } }] },
            paging: { first: 40 },
          }
        );
      }}
      getField={(node: Categories_categories_edges_node) => {
        if (!!node.companyNames && node.companyNames.length > 0) {
          let obj = node.companyNames[0];
          return `${obj.code} - ${obj.description}`;
        }
        return `${node.code} - ${node.description.pt}`;
      }}
      {...props}
      AutocompleteProps={{
        disabled: shouldFieldBeLocked,
        ...props?.AutocompleteProps,
      }}
    />
  );
};

export default CategoryAutocomplete;
