/* eslint-disable */
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Skeleton,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import swalert from "sweetalert";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "./../styles.css";
import { MuiThemeProps } from "../../../theme/theme";
import { NetworkStatus, useMutation, useQuery } from "@apollo/react-hooks";
import {
  CursorPaging,
  PackingsGallery,
  PackingsGalleryVariables,
  PackingSort,
  PackingSortFields,
  Project,
  ProjectVariables,
  SortDirection,
  UpdateManyPackings,
  UpdateManyPackingsVariables,
} from "../../../data/graphQLModel";
import {
  MUTATION_UPDATE_MANY_PACKINGS,
  QUERY_MANY_PACKINGS_GALLERY,
  QUERY_ONE_PROJECT,
} from "../../../data/graphQLQueries";
import NewGeneralTableComponent from "../../../components/Table/NewGeneralTableComponent";
import { decodeBase64, onChangePageCursor } from "../../../utils/utils";
import { getPackagesRowDefaultValue, packingHeadCellsDefault } from "../Packages/utils";

let proj = {};
const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  margin: {
    marginBottom: "2ch",
    marginTop: "2ch",
  },
  margintwo: {
    marginBottom: "2ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

const DEFAULT_LIMIT_PACKAGES = 10;
const DEFAULT_SORTING_PACKAGES: PackingSort[] = [
  { direction: SortDirection.DESC, field: PackingSortFields.createdAt },
];
const DEFAULT_PAGING_PACKAGES: CursorPaging = { first: DEFAULT_LIMIT_PACKAGES };

export default function AddPackingProject() {
  const classes = useStyles();
  const history = useHistory();

  let { prj } = useParams() as { prj: string };

  const [mutationUpdateManyPackages] = useMutation<UpdateManyPackings, UpdateManyPackingsVariables>(
    MUTATION_UPDATE_MANY_PACKINGS
  );

  const {
    loading: projectLoading,
    error: projectError,
    data: projectData,
  } = useQuery<Project, ProjectVariables>(QUERY_ONE_PROJECT, {
    variables: {
      id: decodeBase64(prj),
      packagesPaging: { first: 0 },
      slabsPaging: { first: 0 },
    },
    onError(error) {
      swalert("Comunication Error", error?.message, "warning");
      history.push("/customerstables/projects");
    },
  });

  const [packagesPaging, setPackagesPaging] = useState<CursorPaging>(DEFAULT_PAGING_PACKAGES);
  const [packagesSorting, setPackagesSorting] = useState<PackingSort[]>(DEFAULT_SORTING_PACKAGES);
  const [packagesPageNumber, setPackagesPageNumber] = useState<number>(0);
  const {
    loading: packagesLoading,
    data: packagesData,
    error: packagesError,
    networkStatus: packagesNetworkStatus,
  } = useQuery<PackingsGallery, PackingsGalleryVariables>(QUERY_MANY_PACKINGS_GALLERY, {
    variables: {
      filter: {
        project: {
          eq: null,
        },
      },
    },
  });

  function handleCancel(event: any) {
    event.preventDefault();
    history.goBack();
  }

  const addPackagesToProject = async (packageIds: string[]) => {
    const errorSwalert = (error: any) => {
      swalert(
        "Failed.",
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Erro",
        "error"
      );
    };

    await mutationUpdateManyPackages({
      variables: {
        input: {
          filter: {
            id: { in: packageIds },
          },
          update: {
            project: projectData?.project?.id,
          },
        },
      },
      onCompleted(data, clientOptions) {
        if (!!data?.updateManyPackings) {
          swalert("Success", "Packages added to Project", "success");
          history.goBack();
        } else {
          errorSwalert("Error Updating Packages");
        }
      },
      onError(error, clientOptions) {
        errorSwalert(error);
      },
    }).catch((err) => {
      errorSwalert(err);
    });
  };

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <Box>
                <h2 style={{ marginBottom: "-10px" }}>Add Packing to Project</h2>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <Grid container justifyContent="space-around">
              <Grid item md={5} xs={12}>
                <Grid>
                  <FormControl fullWidth variant="outlined">
                    {projectLoading ? (
                      <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                        <TextField value={""} />
                      </Skeleton>
                    ) : (
                      <TextField
                        id="CodeProject"
                        label="Code"
                        name="code"
                        value={projectData?.project?.code}
                        placeholder="Code"
                        disabled
                        variant="outlined"
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid>
                  <FormControl fullWidth variant="outlined">
                    {projectLoading ? (
                      <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                        <TextField value={""} />
                      </Skeleton>
                    ) : (
                      <TextField
                        id="projProject"
                        label="Project"
                        className={classes.margin}
                        name="project"
                        value={projectData?.project?.project}
                        placeholder="Project"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid>
                  <FormControl fullWidth variant="outlined">
                    {projectLoading ? (
                      <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                        <TextField value={""} />
                      </Skeleton>
                    ) : (
                      <TextField
                        id="descProject"
                        label="Description"
                        name="description"
                        className={classes.margintwo}
                        disabled
                        value={projectData?.project?.description}
                        placeholder="Description"
                        required
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid>
                  <FormControl fullWidth variant="outlined">
                    {projectLoading ? (
                      <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                        <TextField value={""} />
                      </Skeleton>
                    ) : (
                      <TextField
                        id="totalArea"
                        label="Area Total"
                        name="totalArea"
                        className={classes.margintwo}
                        disabled
                        value={projectData?.project?.totalArea + " m" + "\u00b2"}
                        placeholder="Area p/ metro quadrado"
                        required
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth variant="outlined">
                  {projectLoading ? (
                    <Skeleton
                      component={"div"}
                      variant="rectangular"
                      width={"100%"}
                      height={270}
                      sx={{ marginTop: 2 }}
                    >
                      <TextField value={""} />
                    </Skeleton>
                  ) : (
                    <TextField
                      id="obsProject"
                      label="Observation"
                      name="observation"
                      placeholder="Observation"
                      disabled
                      value={projectData?.project?.observation}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline
                      rows={11}
                      variant="outlined"
                    />
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid style={{ padding: "2px" }}>
              <NewGeneralTableComponent
                data={
                  !!packagesData?.packings
                    ? packagesData?.packings?.edges?.map((edge) => edge.node)
                    : []
                }
                loading={packagesLoading || packagesNetworkStatus === NetworkStatus.fetchMore}
                error={packagesError}
                LoadingComponent={() => (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
                onChangePage={(e: any, page: number) => {
                  onChangePageCursor(
                    e,
                    page,
                    packagesPageNumber,
                    !!packagesPaging.first
                      ? packagesPaging.first
                      : !!packagesPaging.last
                      ? packagesPaging.last
                      : DEFAULT_LIMIT_PACKAGES,
                    packagesData?.packings?.pageInfo || ({} as any),
                    (pageNumber: number, newPaging: CursorPaging) => {
                      setPackagesPaging(newPaging);
                      setPackagesPageNumber(pageNumber);
                    }
                  );
                }}
                onRowsPerPageChange={(event) => {
                  setPackagesPaging({ first: parseInt(event.target.value, 10) });
                }}
                page={packagesPageNumber}
                rowsPerPage={
                  !!packagesPaging.first
                    ? packagesPaging.first
                    : !!packagesPaging.last
                    ? packagesPaging.last
                    : DEFAULT_LIMIT_PACKAGES
                }
                headCells={packingHeadCellsDefault}
                totalCount={packagesData?.packings?.totalCount || 0}
                handleSort={(field, order) => {
                  setPackagesSorting([{ field: field as PackingSortFields, direction: order }]);
                }}
                orderByField={packagesSorting[0].field}
                orderDirection={packagesSorting[0].direction}
                sortableFields={Object.values(PackingSortFields)}
                key={"pck"}
                getValues={getPackagesRowDefaultValue}
                PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
                tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
                rowsPerPageOptions={[10, 25, 50, 100]}
                disableSelectAll
                disableDetails
                disableRemove
                disableEdit
                tableSubTitle="Select Packages"
                pathEntity={""}
                toolbarOptions={[
                  {
                    element: <Button style={{ width: "30%" }}>Add to Project</Button>,
                    onClick: (params) => {
                      addPackagesToProject(params.map((el) => el.id));
                    },
                    title: "",
                  },
                ]}
              />
            </Grid>
            <div className="btnform">
              <div>
                <FormControl
                  fullWidth
                  style={{ margin: "15px", marginLeft: "40px" }}
                  variant="outlined"
                >
                  <Button
                    type="submit"
                    onClick={handleCancel}
                    fullWidth
                    color="primary"
                    variant="contained"
                    style={{ height: "50px", width: "200px", fontSize: "20px" }}
                  >
                    <ArrowBackIosIcon style={{ marginLeft: "20px" }} />
                    Go Back
                  </Button>
                </FormControl>
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
