import { Backdrop, CircularProgress, colors } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProps } from "../../theme/theme";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
	root: {
		position: "absolute",
		zIndex: theme.zIndex.drawer - 1,
		opacity: 0.5,
		left: 20,
	},
}));

export default function LoadingFitCenter() {
	const classes = useStyles();
	return (
		<Backdrop open className={classes.root}>
			<CircularProgress />
		</Backdrop>
	);
}
