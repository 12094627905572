import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Skeleton,
  TextField,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { useQuery } from "@apollo/react-hooks";
import { User, UserVariables } from "../../data/graphQLModel";
import { USER_BY_ID } from "../../data/graphQLQueries";
import { UserToken } from "../../utils/Types";

const states = [
  {
    value: "lisbon",
    label: "Lisbon",
  },
  {
    value: "porto",
    label: "Porto",
  },
  {
    value: "coimbra",
    label: "Coimbra",
  },
];

const AccountProfileDetails = (props: { user: UserToken }) => {
  const { data, loading, error } = useQuery<User, UserVariables>(USER_BY_ID, {
    variables: {
      id: props.user.id,
    },
  });

  return (
    <>
      <Card>
        <CardHeader subheader="The information about user loged" title="Profile" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              {data?.user && (
                <TextField
                  fullWidth
                  // helperText="Please specify the first name"
                  label="First name"
                  name="firstName"
                  required
                  value={data?.user.firstname}
                  variant="outlined"
                  disabled
                />
              )}
              {loading && (
                <TextField>
                  <Skeleton />
                </TextField>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {data?.user && (
                <TextField
                  fullWidth
                  label="Last name"
                  name="lastName"
                  required
                  value={data?.user.lastname}
                  variant="outlined"
                  disabled
                />
              )}
              {loading && (
                <TextField>
                  <Skeleton />
                </TextField>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {data?.user && (
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  required
                  value={data.user.email}
                  variant="outlined"
                  disabled
                />
              )}
              {loading && (
                <TextField>
                  <Skeleton />
                </TextField>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default AccountProfileDetails;
