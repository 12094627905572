import { useQuery } from "@apollo/react-hooks";
import { Fragment } from "react";
import { Slab, SlabVariables } from "../../data/graphQLModel";
import { SLAB_BY_ID } from "../../data/graphQLQueries";
import { SlabDetailsComponent } from "./SlabDetailsComponent";

export interface SlabDetailsComponentProps {
  slab_id: string;
}

export const SlabDetailsWithQueryComponent = ({ slab_id }: SlabDetailsComponentProps) => {
  const { loading, error, data } = useQuery<Slab, SlabVariables>(SLAB_BY_ID, {
    variables: {
      id: slab_id,
    },
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error</div>;
  }

  return (
    <Fragment>
      {data && data.slab && <SlabDetailsComponent hideMagnify slab={data.slab} showNavButtons />}
    </Fragment>
  );
};
