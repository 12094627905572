/* eslint-disable */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Skeleton,
  Typography,
} from "@material-ui/core";
import { Class, Check, NotInterested } from "@material-ui/icons";
import { useQuery } from "@apollo/react-hooks";
import {
  SharedSlab,
  SharedSlabVariables,
  SharedSlab_sharedSlab,
} from "../../../../data/graphQLModel";
import { QUERY_ONE_SHARED_SLAB } from "../../../../data/graphQLQueries";
import { download } from "../../../../utils/ImageDownloadUtils";
import { MuiThemeProps } from "../../../../theme/theme";
import { decodeBase64, getSlabImageSafe } from "../../../../utils/utils";
import queryString from "query-string";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  first: {
    width: "90%",
    marginBottom: "3ch",
  },
  second: {
    width: "100%",
    marginBottom: "3ch",
  },
  margin: {
    marginBottom: "3ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
  headerMatType: {
    fontSize: 20,
    fontWeight: "lighter",
    marginRight: 10,
  },
  headerVariety: {
    fontWeight: "bold",
    fontSize: 20,
  },
  descriptionDefault: {
    color: theme.palette.grey[600],
    fontSize: 14,
    marginBottom: "3px",
  },
  aboutHeader: {
    fontSize: 18,
  },
  aboutText: {
    color: theme.palette.grey[600],
    fontSize: 14,
  },
}));

const getHeader = (slab: SharedSlab_sharedSlab, className: string, classNameVar: string) => {
  const matType = slab?.commercial_variety?.materialtype
    ? slab?.commercial_variety?.materialtype?.description?.pt
    : slab?.typematerial?.description?.pt
    ? slab?.typematerial?.description?.pt
    : "No Type";
  const variety = slab?.commercial_variety
    ? slab?.commercial_variety?.name
    : slab?.variety?.normativedesig
    ? slab?.variety?.normativedesig
    : "No Name";
  return (
    <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
      <Typography className={className}>{matType}</Typography>
      <Typography className={className}>{"-"}</Typography>
      <Typography className={classNameVar}>{variety}</Typography>
    </Box>
  );
};

export default function DetailsSlabGallery() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  let { slb } = useParams<{ slb: string }>();
  const { search } = useLocation();
  const { keyValue, slab } = queryString.parse(search || slb) as {
    slab?: string;
    keyValue?: string;
  };

  const { data, loading, error } = useQuery<SharedSlab, SharedSlabVariables>(
    QUERY_ONE_SHARED_SLAB,
    {
      skip: (!!!slb || slb?.trim() === "") && (!!!slab || slab?.trim() === ""),
      variables: {
        id: !!slab ? decodeBase64(slab) : decodeBase64(slb),
      },
      fetchPolicy: "cache-first",
      pollInterval: 30000,
    }
  );

  useEffect(() => {
    validateimg();
  }, []);

  const validateimg = () => {
    if ((slb === undefined || slb === "") && !!!slab) {
      console.log("pushing to root", slb, slab);
      history.push("/");
      return;
    }
  };

  function handleCancel(event: any) {
    event.preventDefault();
    history.goBack();
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <h2 style={{ marginBottom: "-10px" }}>Details Slab</h2>
              </Box>
              {data?.sharedSlab && data?.sharedSlab.inStock && (
                <Box style={{ alignItems: "center" }}>
                  <Check style={{ color: "green", width: 30, height: 30, marginLeft: 15 }} />
                  <Typography>In Stock</Typography>
                </Box>
              )}
              {data?.sharedSlab && !data?.sharedSlab.inStock && (
                <Box style={{ alignItems: "center" }}>
                  <NotInterested style={{ color: "red", width: 30, height: 30, marginLeft: 15 }} />
                  <Typography>No Stock</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form}>
              <Grid container spacing={3} sx={{ display: "flex", flexDirection: "row" }}>
                <Grid item md={6} xs={12}>
                  {!!data && !loading && !!!error ? (
                    <div className="card">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getSlabImageSafe(data?.sharedSlab, false) || "#"}
                      >
                        <img
                          src={
                            getSlabImageSafe(data?.sharedSlab, false) ||
                            "../../../images/imagemissing.png"
                          }
                          className="card-img-top"
                          alt={data?.sharedSlab.code}
                        />
                      </a>
                    </div>
                  ) : loading ? (
                    <Skeleton variant="rectangular" height={400} />
                  ) : (
                    <Alert severity="error">{!!error ? error.message : "Erro"}</Alert>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  {!!!error && (
                    <>
                      {data?.sharedSlab ? (
                        getHeader(data?.sharedSlab, classes.headerMatType, classes.headerVariety)
                      ) : (
                        <Typography height={10} width="100%">
                          <Skeleton />
                        </Typography>
                      )}
                      {data?.sharedSlab?.category && (
                        <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                          <Class color={"disabled"} />
                          {loading ? (
                            <Typography width={"100%"}>
                              <Skeleton />
                            </Typography>
                          ) : (
                            <Typography className={classes.descriptionDefault}>
                              {data?.sharedSlab?.category
                                ? data?.sharedSlab.category.description.pt
                                : ""}
                            </Typography>
                          )}
                        </Box>
                      )}
                      <Typography className={classes.descriptionDefault}>
                        {loading ? (
                          <Skeleton />
                        ) : (
                          `Dimensions: ${
                            data?.sharedSlab.width ? data?.sharedSlab.width / 10 : 0
                          }cm by${" "}
                        ${data?.sharedSlab.height ? data?.sharedSlab.height / 10 : 0}cm`
                        )}
                      </Typography>
                      <Typography className={classes.descriptionDefault}>
                        {loading ? (
                          <Skeleton />
                        ) : (
                          `Thickness:${" "}
                        ${data?.sharedSlab.thickness ? data?.sharedSlab.thickness / 10 : 0}cm`
                        )}
                      </Typography>
                      {loading ? (
                        <Typography>
                          <Skeleton />
                        </Typography>
                      ) : (
                        <Typography className={classes.descriptionDefault}>
                          Area:{" "}
                          {data?.sharedSlab.area
                            ? data?.sharedSlab.area
                            : (
                                Number(
                                  Number(data?.sharedSlab?.width ? data?.sharedSlab?.width : "0") /
                                    1000
                                ) *
                                Number(
                                  Number(
                                    data?.sharedSlab?.height ? data?.sharedSlab?.height : "0"
                                  ) / 1000
                                )
                              ).toFixed(2)}
                          m2
                        </Typography>
                      )}
                      {loading ? (
                        <Typography>
                          <Skeleton />
                        </Typography>
                      ) : (
                        <Typography className={classes.descriptionDefault}>
                          Stone Type:{" "}
                          {data?.sharedSlab?.commercial_variety?.materialtype
                            ? data?.sharedSlab?.commercial_variety?.materialtype?.description?.pt
                            : data?.sharedSlab?.typematerial
                            ? data?.sharedSlab?.typematerial?.description?.pt
                            : ""}
                        </Typography>
                      )}
                      {loading ? (
                        <Typography>
                          <Skeleton />
                        </Typography>
                      ) : (
                        <Typography className={classes.descriptionDefault}>
                          Stone Name:{" "}
                          {data?.sharedSlab?.commercial_variety
                            ? data?.sharedSlab?.commercial_variety.name
                            : data?.sharedSlab?.variety?.normativedesig
                            ? data?.sharedSlab?.variety?.normativedesig
                            : ""}
                        </Typography>
                      )}
                      {loading ? (
                        <Typography>
                          <Skeleton />
                        </Typography>
                      ) : (
                        <Typography className={classes.descriptionDefault}>
                          Finishing:{" "}
                          {data?.sharedSlab?.commercial_finishing
                            ? data?.sharedSlab?.commercial_finishing.description.pt
                            : ""}
                        </Typography>
                      )}
                      {loading ? (
                        <Typography>
                          <Skeleton />
                        </Typography>
                      ) : (
                        <Typography className={classes.descriptionDefault}>
                          Package:{" "}
                          {data?.sharedSlab?.packing ? data?.sharedSlab?.packing.packing : ""}
                        </Typography>
                      )}
                      {loading ? (
                        <Typography>
                          <Skeleton />
                        </Typography>
                      ) : (
                        <Typography className={classes.descriptionDefault}>
                          Location:{" "}
                          {data?.sharedSlab.inStock
                            ? data?.sharedSlab?.company?.name
                              ? data?.sharedSlab?.company?.name
                              : ""
                            : "Customer"}
                        </Typography>
                      )}
                      {loading ? (
                        <Typography>
                          <Skeleton />
                        </Typography>
                      ) : (
                        <Typography className={classes.descriptionDefault}>
                          Product ID: {data?.sharedSlab.code}
                        </Typography>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid>
                <Typography
                  style={{ marginTop: "10px", marginBottom: "5px" }}
                  className={classes.aboutHeader}
                >
                  ABOUT{" "}
                  {data?.sharedSlab?.commercial_variety
                    ? data?.sharedSlab?.commercial_variety.name
                    : ""}
                </Typography>
                <Typography style={{ marginBottom: "10px" }} className={classes.aboutText}>
                  {data?.sharedSlab?.commercial_variety?.description
                    ? data?.sharedSlab?.commercial_variety.description
                    : "No Description"}
                </Typography>
              </Grid>
              <Box style={{ maxHeight: "130px" }}>
                <Card style={{ maxHeight: "130px" }}>
                  {data?.sharedSlab?.slabImages?.[0] && (
                    <CardContent>
                      <h5 style={{ marginBottom: "10px" }}>Download Options</h5>
                      <CardContent style={{ marginBottom: "-30px" }}>
                        <a
                          href={getSlabImageSafe(data?.sharedSlab, false) || ""}
                          download
                          onClick={(e) => download(getSlabImageSafe(data?.sharedSlab, false) || "")}
                        >
                          Original Large Size Image
                        </a>
                      </CardContent>
                      <CardContent>
                        <a
                          href={getSlabImageSafe(data?.sharedSlab, false) || ""}
                          download
                          onClick={(e) => download(getSlabImageSafe(data?.sharedSlab, true) || "")}
                        >
                          Original Small Size Image
                        </a>
                      </CardContent>
                    </CardContent>
                  )}
                  <CardContent></CardContent>
                </Card>
              </Box>
              <div className="btnform">
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      <ArrowBackIosIcon />
                      Back
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
