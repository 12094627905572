import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import "./../styles.css";
import { Box, Card, CardContent, Container, Grid } from "@material-ui/core";
import swalert from "sweetalert";
import { MuiThemeProps } from "../../../theme/theme";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  CommercialVariety,
  CommercialVarietyInput,
  CommercialVarietyVariables,
  CreateOneAnnexedDocument,
  CreateOneAnnexedDocumentVariables,
  CursorPaging,
  DocumentReferenceType,
  MaterialTypeFilter,
  MaterialTypes,
  MaterialTypeSort,
  MaterialTypes_materialTypes_edges_node,
  UpdateOneCommercialVariety,
  UpdateOneCommercialVarietyVariables,
  Varieties,
  Varieties_varieties_edges_node,
  VarietyFilter,
  VarietySort,
} from "../../../data/graphQLModel";
import { ApolloError, FetchResult, useMutation, useQuery } from "@apollo/react-hooks";
import {
  MUTATION_UPDATE_ONE_COMMERCIAL_VARIETY,
  QUERY_MATERIALTYPES,
  QUERY_ONE_COMMERCIAL_VARIETY,
  QUERY_VARIETIES,
  UPLOAD_ONE_ANNEXED_DOCUMENT,
} from "../../../data/graphQLQueries";
import { uploadApolloClient } from "../../../services_v2/apollo.graphql";
import AnnexedDocumentButtonDialog from "../../../components/AnnexedDocs/AnnexDocumentButtonModal";
import AutocompleteWithQuery from "../../../components/Form/AutocompleteWithQuery";
import { queryValuesForFilter } from "../../../utils/ApolloClientUtils";

const validationSchema = () =>
  yup.object().shape({
    code: yup.string().required().nullable(),
    materialtype: yup.string().nullable(),
    description: yup.string().nullable(),
    name: yup.string().required().nullable(),
    system_variety: yup.string().nullable(),
  });

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "100%",
    alignItems: "flex-start",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function EditCommercialVariety() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { commvar } = useParams() as { commvar: string };
  const [files, setFiles] = useState<File[]>([]);
  const [systemVars, setSystemVars] = useState<any[]>([]);
  const [materialtypes, setMaterialtypes] = useState<any[]>([]);

  const { loading, error, data } = useQuery<CommercialVariety, CommercialVarietyVariables>(
    QUERY_ONE_COMMERCIAL_VARIETY,
    {
      variables: {
        id: atob(commvar),
      },
      onCompleted(data) {
        let newData = data?.commercialVariety;
        if (newData) {
          setValues({
            code: newData.code,
            materialtype: newData.materialtype?.id as string,
            description: newData.description as string,
            name: newData.name as string,
            system_variety: newData.system_variety?.id as string,
          });
        }
      },
    }
  );

  function changeform(e: any) {
    const { name, value } = e.target;
    let finalValue = !!value ? value : null;
    setFieldValue(name, finalValue);
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/customerstables/commvarieties");
  }

  const [mutationUpdateCommVar] = useMutation<
    UpdateOneCommercialVariety,
    UpdateOneCommercialVarietyVariables
  >(MUTATION_UPDATE_ONE_COMMERCIAL_VARIETY);

  const onSubmit = async (values: CommercialVarietyInput) => {
    const promisesToRun: Promise<FetchResult<any, Record<string, any>, Record<string, any>>>[] = [];
    if (dirty) {
      const createPromise = mutationUpdateCommVar({
        variables: {
          input: {
            id: data?.commercialVariety?.id as string,
            update: {
              materialtype: values.materialtype,
              system_variety: values.system_variety as string,
              description: values.description,
              name: values.name,
            },
          },
        },
      });
      promisesToRun.push(createPromise);
      if (!!files && files.length > 0) {
        const fileToUpload = files[0];
        const uploadPromise = uploadApolloClient.mutate<
          CreateOneAnnexedDocument,
          CreateOneAnnexedDocumentVariables
        >({
          mutation: UPLOAD_ONE_ANNEXED_DOCUMENT,
          variables: {
            input: {
              annexedDocument: {
                ref_commercial_variety: data?.commercialVariety?.id,
                name: fileToUpload.name,
                reference_type: DocumentReferenceType.commercial_variety,
              },
            },
            file: fileToUpload,
          },
        });
        promisesToRun.push(uploadPromise);
      }
    }
    await Promise.all(promisesToRun)
      .then((res) => {
        swalert("Success", "Commercial Variety updated", "success");
        history.goBack();
      })
      .catch((err: ApolloError) => {
        let errorString =
          err.message +
          "; " +
          err.clientErrors.map((el) => el.message).join("; ") +
          err.graphQLErrors.map((el) => el.message).join("; ");
        swalert("Failed.", errorString, "error");
      });
  };

  const onUpload = async (): Promise<void> => {
    await uploadApolloClient
      .mutate<CreateOneAnnexedDocument, CreateOneAnnexedDocumentVariables>({
        mutation: UPLOAD_ONE_ANNEXED_DOCUMENT,
        variables: {
          input: {
            annexedDocument: {
              ref_commercial_variety: data?.commercialVariety?.id,
              name: files[0].name,
              reference_type: DocumentReferenceType.commercial_variety,
            },
          },
          file: files[0],
        },
      })
      .then((res) => {
        swalert("Success", "Commercial Variety updated", "success");
        history.goBack();
      })
      .catch((err: ApolloError) => {
        let errorString =
          err.message +
          "; " +
          err.clientErrors.map((el) => el.message).join("; ") +
          err.graphQLErrors.map((el) => el.message).join("; ");
        swalert("Failed.", errorString, "error");
      });
  };

  const { values, dirty, errors, handleChange, setValues, handleSubmit, setFieldValue } = useFormik(
    {
      initialValues: {
        code: "",
        materialtype: "",
        description: "",
        name: "",
        system_variety: "",
      },
      onSubmit,
      validationSchema: validationSchema(),
    }
  );

  return (
    <Container style={{ marginTop: "70px" }}>
      <Box style={{ marginBottom: "10px" }}>
        <Card>
          <CardContent>
            <h2 style={{ marginBottom: "-10px" }}>Edit Comm. Variety</h2>
          </CardContent>
        </Card>
      </Box>
      <hr />
      <Card>
        <CardContent>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Box sx={{ flex: 10, display: "flex", flexDirection: "row" }}>
                <Box>
                  <FormControl className={classes.margin} variant="outlined">
                    <TextField
                      id="code"
                      label="Code"
                      name="code"
                      placeholder="Code"
                      required
                      focused
                      onChange={changeform}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={values.code}
                    />
                  </FormControl>
                  <div>
                    <FormControl className={classes.margin} variant="outlined">
                      <TextField
                        id="name"
                        label="Name"
                        name="name"
                        onChange={changeform}
                        placeholder="Name"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={values.name}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl className={classes.margin} variant="outlined">
                      <TextField
                        id="description"
                        label="Description"
                        name="description"
                        onChange={changeform}
                        placeholder="Description"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        multiline
                        rows={5}
                        variant="outlined"
                        value={values.description}
                      />
                    </FormControl>
                  </div>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Grid container direction={"row"} style={{ marginTop: -8, flexDirection: "row" }}>
                    <Grid container item xs={12}>
                      <Grid key="mat-type-grid" item md={4} xs={12}>
                        <FormControl fullWidth>
                          <AutocompleteWithQuery
                            items={materialtypes || []}
                            label="Material Types*"
                            name="materialtype"
                            onChange={changeform}
                            title="Material Types"
                            value={values.materialtype}
                            onTextChange={async (value: string) => {
                              await queryValuesForFilter<
                                MaterialTypeFilter,
                                CursorPaging,
                                MaterialTypeSort,
                                MaterialTypes
                              >(
                                value,
                                QUERY_MATERIALTYPES,
                                (res) => {
                                  let endRes = res?.data?.materialTypes?.edges?.map(
                                    (el) => el.node
                                  );
                                  setMaterialtypes(endRes);
                                },
                                {
                                  filter: {
                                    or: [{ code: { iLike: value } }],
                                  },
                                  paging: {
                                    first: 30,
                                  },
                                }
                              );
                            }}
                            getField={(node: MaterialTypes_materialTypes_edges_node) => {
                              return `${node.code} - ${node?.description.pt || ""}`;
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid key="variety-grid" item md={4} xs={12}>
                        <FormControl variant="outlined">
                          <AutocompleteWithQuery
                            items={systemVars}
                            label="System Variety"
                            name="system_variety"
                            value={values.system_variety}
                            onChange={changeform}
                            title="System Variety"
                            onClear={() => {
                              changeform({ name: "system_variety", value: null });
                            }}
                            onTextChange={async (value: string) => {
                              await queryValuesForFilter<
                                VarietyFilter,
                                CursorPaging,
                                VarietySort,
                                Varieties
                              >(
                                value,
                                QUERY_VARIETIES,
                                (res) => {
                                  let endRes = res?.data?.varieties?.edges?.map((el) => el.node);
                                  setSystemVars(endRes);
                                },
                                {
                                  filter: {
                                    or: [
                                      { code: { iLike: value } },
                                      { normativedesig: { iLike: value } },
                                    ],
                                  },
                                  paging: { first: 20 },
                                }
                              );
                            }}
                            getField={(node: Varieties_varieties_edges_node) => {
                              return `${node.code} - ${node.normativedesig}`;
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} ml={1}>
                      <Grid container spacing={2} xs={12} style={{ marginTop: 2 }}>
                        {values.system_variety ? (
                          <>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="alternativedesig"
                                label="Alternative Design"
                                value={
                                  systemVars?.find((el) => el?.id === values.system_variety)
                                    ?.alternativedesig
                                }
                                placeholder="Alternative Design"
                                disabled
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="lithologicalclass"
                                label="Lithological Class"
                                value={
                                  systemVars?.find((el) => el?.id === values.system_variety)
                                    ?.lithologicalclass?.description
                                }
                                placeholder="Lithological Class"
                                disabled
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="districtquarry"
                                label="District Quary"
                                value={
                                  systemVars?.find((el) => el?.id === values.system_variety)
                                    ?.districtquarry
                                }
                                placeholder="District Quary"
                                disabled
                                variant="outlined"
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item md={6} xs={12}></Grid>
                            <Grid item md={6} xs={12}></Grid>
                            <Grid item md={6} xs={12}></Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <AnnexedDocumentButtonDialog
                parentBoxSxProps={{ flex: 2 }}
                DropZoneProps={{
                  accept: ".pdf,.xlsx,.txt",
                  multiple: false,
                  onUpload: onUpload,
                  title: "Drag & Drop Annex Document",
                  imageUpload: false,
                  saveFiles: setFiles,
                  showUploadButton: true,
                }}
                key="AnnexedDocFileDropZone"
                onCancel={() => {}}
                annexedDocs={data?.commercialVariety?.annexedDocuments?.nodes}
              />
            </Box>
            <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
              <div>
                <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                  <Button
                    type="submit"
                    onClick={handleCancel}
                    fullWidth
                    color="primary"
                    variant="contained"
                    style={{ height: "50px", width: "200px", fontSize: "20px" }}
                  >
                    Cancel
                    <Cancel style={{ marginLeft: "20px" }} />
                  </Button>
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                  <Button
                    disabled={
                      values.code === "" ||
                      values.code.length < 2 ||
                      values.name === "" ||
                      !!!values.code ||
                      !!!values.name ||
                      !dirty
                    }
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ height: "50px", width: "200px", fontSize: "20px" }}
                  >
                    Edit
                    <Save style={{ marginLeft: "20px" }} />
                  </Button>
                </FormControl>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}
