/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Alert } from "@material-ui/core";
import slabServices from "../../services/slab.Services";
import StoneHome from "../../components/home/stone.home";
import "./home.css";
// import { useLazyQuery, useQuery } from "@apollo/react-hooks";
// import { Scanners, ScannersVariables, Slabs, SlabsVariables } from "../../data/graphQLModel";
// import { QUERY_SCANNERS, QUERY_SLABS } from "../../data/graphQLQueries";

export default function Catalog() {
  const [slabs, setSlabs] = useState([]); //= useSelector(state => state.slabs.slabs);
  // const [errorMessage, setErrorMessage] = useState<string>();
  let { scan } = useParams<{ scan?: string }>();

  // const [querySlabs] = useLazyQuery<Slabs,SlabsVariables>(QUERY_SLABS)
  // const [queryScanners] = useLazyQuery<Scanners, ScannersVariables>(QUERY_SCANNERS)

  // const getLastScans = async (snumber: string) => {
  //   return await queryScanners({
  //     variables: {
  //       paging: {first: 1},
  //       filter: {
  //         snumber: {eq: snumber}
  //       }
  //     }
  //   }).then(async resScan => {
  //     if(!!resScan?.data?.scanners && resScan?.data?.scanners?.edges?.length > 0){

  //     }else{
  //       setErrorMessage("Scanner não existe.")
  //     }
  //   })
  // }

  // useEffect(() => {
  //   if(!!scan && typeof scan === "string" && scan?.trim() !== ""){

  //   }
  // }, [scan])

  async function getAllSlab() {
    try {
      let d = await slabServices.getLastSlabs(scan);
      setSlabs(await d);
    } catch (error) {}
  }

  useEffect(() => {
    getAllSlab();
    setInterval(reloadPageSlabs, 9 * 1000, "1");
  }, []);

  const reloadPageSlabs = () => {
    getAllSlab();
  };

  return (
    <div>
      <Helmet>
        <title>Slabs | WeScan</title>
      </Helmet>
      <div className="slabshome">
        {slabs &&
          slabs.map((stn, key) => (
            <div key={key} className="column">
              <StoneHome key={key} mykey={key} stone={stn} />
            </div>
          ))}
        {/* {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>} */}
      </div>
    </div>
  );
}
