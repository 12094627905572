import { useMutation } from "@apollo/react-hooks";
import CreateEntityForm from "../../../components/Form/CreateEntityForm";
import {
  CreateOneStoneEdge,
  CreateOneStoneEdgeVariables,
  StoneEdgeInput,
} from "../../../data/graphQLModel";
import { MUTATION_CREATE_ONE_STONE_EDGE } from "../../../data/graphQLQueries";

const form: StoneEdgeInput = {
  code: "",
  name: "",
  description: "",
};

const requiredFields: (keyof StoneEdgeInput)[] = ["code", "description", "name"];

export default function CreateStoneEdge() {
  const [mutationCreateOneStoneEdge] = useMutation<CreateOneStoneEdge, CreateOneStoneEdgeVariables>(
    MUTATION_CREATE_ONE_STONE_EDGE
  );

  const createOne = async (form: StoneEdgeInput) => {
    await mutationCreateOneStoneEdge({
      variables: {
        input: {
          stoneEdge: form,
        },
      },
    });
  };

  return (
    <CreateEntityForm
      mutation={createOne}
      entityForm={form}
      entityName={"Stone Edge"}
      path={"/customerstables/stoneedge"}
      requiredFields={requiredFields}
    />
  );
}
