import { Box } from "@material-ui/system";
import { MutableRefObject, useRef } from "react";
import { EditImageFilter } from "../../pages/editimages";
import { H5 } from "../GeneralComponents/Typography";
import { FabricJSCanvasComponent } from "./FabricJSCanvas";

interface FilterImageComponentProps {
  /**
   * Image URL
   */
  imageUrl: string;
  /**
   * Filter
   */
  filter: EditImageFilter;
  /**
   * Functino to change settings
   */
  changeSettings: (settings: EditImageFilter) => void;
}

export default function FilterImage({
  imageUrl,
  filter,
  changeSettings,
}: FilterImageComponentProps) {
  function handleClick(e: any) {
    changeSettings(filter);
  }

  const ref_box = useRef<HTMLDivElement>();

  return (
    <Box onClick={handleClick} ref={ref_box}>
      <FabricJSCanvasComponent
        id={"react-canvas-filtered-" + filter.id}
        imageUrl={imageUrl}
        filter={filter.valueFilter}
        filterbar={true}
        parentRef={ref_box}
      />
      <H5>{filter.name}</H5>
    </Box>
  );
}
