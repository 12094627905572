import { styled } from "@material-ui/core";
import { compose, display, spacing } from "@material-ui/system";

const DefaultImage = styled("img")(compose(spacing, display));

DefaultImage.defaultProps = {
  display: "block",
};

export default DefaultImage;
