import { useQuery } from "@apollo/react-hooks";
import {
  Box,
  colors,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close, RemoveRedEye } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useCallback, useEffect, useState } from "react";
import { TwinStones, TwinStonesVariables } from "../../data/graphQLModel";
import { TWIN_AI } from "../../data/graphQLQueries";
import useWindowSize from "../../hooks/useWindowSize";
import { MuiThemeProps } from "../../theme/theme";
import Carousel from "../Carousel/Carousel";
import { SlabDetailsWithQueryComponent } from "./SlabDetailsWithQuery";

const useStyles = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    margin: "auto",
    overflow: "hidden",
    transition: "all 250ms ease-in-out",
    borderRadius: "8px",

    "&:hover": {
      "& $imageHolder": {
        "& .extra-icons": {
          display: "flex",
        },
      },
    },

    "@media only screen and (max-width: 768px)": {
      "& $imageHolder": {
        "& .extra-icons": {
          display: "flex",
        },
      },
    },
  },
  imageHolder: {
    position: "relative",
    display: "inlin-block",
    textAlign: "center",

    "& .extra-icons": {
      display: "none",
      flexDirection: "column",
      position: "absolute",
      top: "7px",
      right: "15px",
      cursor: "pointer",
      zIndex: 2,
    },

    [theme.breakpoints.down(1)]: {
      display: "block",
    },
  },
  offerChip: {
    position: "absolute",
    fontSize: "10px",
    fontWeight: 600,
    paddingLeft: 3,
    paddingRight: 3,
    top: "10px",
    left: "10px",
  },
  details: {
    padding: "1rem",

    "& .title, & .categories": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    "& .icon-holder": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },

    "& .favorite-icon": {
      cursor: "pointer",
    },
  },
  dialogContent: {
    paddingBottom: "1.25rem",
  },
}));

export interface TwinStonesComponentProps {
  slab_id: string;
  hoverEffect?: boolean;
}

export const TwinStonesComponent = ({ slab_id, hoverEffect = true }: TwinStonesComponentProps) => {
  const [visibleSlides, setVisibleSlides] = useState<number>(4);
  const width = useWindowSize();
  const [open, setOpen] = useState(false);
  const classes = useStyles({ hoverEffect });
  const [selected, setSelected] = useState<string | null | undefined>();

  const toggleDialog = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  const { loading, error, data } = useQuery<TwinStones, TwinStonesVariables>(TWIN_AI, {
    variables: {
      id: slab_id,
    },
  });

  useEffect(() => {
    if (width < 500) setVisibleSlides(1);
    else if (width < 650) setVisibleSlides(3);
    else if (width < 950) setVisibleSlides(4);
    else setVisibleSlides(6);
  }, [width]);

  return (
    <Box>
      <Box
        bgcolor={colors.red[400]}
        width={130}
        textAlign={"center"}
        borderRadius={5}
        mb={2}
        p={0.5}
      >
        <Typography style={{ fontSize: 20, color: "white" }}>Twin Stones</Typography>
      </Box>
      {!error && !loading && data && (
        <Carousel
          totalSlides={data.twinStones.length}
          visibleSlides={visibleSlides}
          infinite={false}
        >
          {data?.twinStones &&
            data.twinStones.length > 1 &&
            data.twinStones.map((twinstone_data, ind) => (
              <Box
                component={"div"}
                height={160}
                width={250}
                minWidth={250}
                display="flex"
                key={ind + ""}
              >
                <div className="extra-icons">
                  {twinstone_data?.slab?.id && (
                    <IconButton
                      style={{ padding: "3px", position: "absolute", marginLeft: 210, zIndex: 10 }}
                      onClick={() => {
                        if (twinstone_data?.slab?.id) {
                          setSelected(twinstone_data?.slab?.id);
                          toggleDialog();
                        }
                      }}
                    >
                      <RemoveRedEye color="secondary" fontSize="medium" />
                    </IconButton>
                  )}
                </div>
                {twinstone_data?.slab &&
                  twinstone_data?.slab.width &&
                  twinstone_data?.slab.height && (
                    <img
                      src={twinstone_data.thumbnail}
                      alt={twinstone_data?.slab.code}
                      style={{
                        alignSelf: "center",
                        transform: `rotate(${
                          twinstone_data?.slab.width < twinstone_data?.slab.height ? 90 : 0
                        }deg)`,
                        width: `${
                          twinstone_data?.slab.width > twinstone_data?.slab.height
                            ? "240px"
                            : "auto"
                        }`,
                        height: `${
                          twinstone_data?.slab.width < twinstone_data?.slab.height
                            ? "240px"
                            : "auto"
                        }`,
                        marginLeft: `${
                          twinstone_data?.slab.width < twinstone_data?.slab.height ? "-30px" : "0"
                        }`,
                        // marginLeft: `${(twinstone_data?.slab.width < twinstone_data?.slab.height) ? '70px' : '0'}`,
                      }}
                    />
                  )}
              </Box>
            ))}
        </Carousel>
      )}
      {selected && (
        <Dialog open={open} maxWidth={false} onClose={() => toggleDialog()}>
          <DialogTitle>Twin Stone</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <SlabDetailsWithQueryComponent slab_id={selected} />
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}
              onClick={() => toggleDialog()}
            >
              <Close className="close" fontSize="medium" color="primary" />
            </IconButton>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};
