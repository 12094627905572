import { format } from "date-fns";
import { ICompleteHeadCells } from "../../../components/Table/NewGeneralTableComponent";
import { ProjectGallery_projects_edges_node } from "../../../data/graphQLModel";
import { getImageEntityURLString } from "../../../utils/utils";

export const projectHeadCellsDefault: ICompleteHeadCells[] = [
  { id: "slabImages", numeric: false, disablePadding: false, label: "" },
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "project", numeric: false, disablePadding: false, label: "Project" },
  { id: "observation", numeric: false, disablePadding: false, label: "Observation" },
  { id: "date", numeric: false, disablePadding: false, label: "Date" },
  { id: "slabs", numeric: false, disablePadding: false, label: "Slabs" },
];

export const getProjectRowsvaluesDefault = (
  row: ProjectGallery_projects_edges_node
): ICompleteHeadCells[] => {
  return [
    {
      id:
        row?.slabs?.edges?.[0]?.node?.slabImages?.map((el) => getImageEntityURLString(el) || "") ||
        "",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: row?.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    {
      id: row?.project,
      numeric: false,
      disablePadding: false,
      label: "Project",
    },
    {
      id: row.observation || "",
      numeric: false,
      disablePadding: false,
      label: "Packing Type",
    },
    {
      id: format(new Date(String(row.createdAt)), "yyyy-MM-dd") || "",
      numeric: false,
      disablePadding: false,
      label: "Packing Type",
    },
    {
      id: (row?.slabs?.totalCount || 0) + "",
      numeric: true,
      disablePadding: false,
      label: "Slabs",
    },
  ];
};

export const projectHeadCellsWithAction: ICompleteHeadCells[] = [
  { id: "slabImages", numeric: false, disablePadding: false, label: "" },
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "project", numeric: false, disablePadding: false, label: "Project" },
  { id: "observation", numeric: false, disablePadding: false, label: "Observation" },
  { id: "date", numeric: false, disablePadding: false, label: "Date" },
  { id: "slabs", numeric: false, disablePadding: false, label: "Slabs" },
  { id: "", numeric: false, disablePadding: false, label: "" },
];

export const getProjectRowsvaluesWithAction = (
  element: React.FC<{ selected: ProjectGallery_projects_edges_node }>
): ((row: ProjectGallery_projects_edges_node) => ICompleteHeadCells[]) => {
  return (row: ProjectGallery_projects_edges_node): ICompleteHeadCells[] => [
    {
      id:
        row?.slabs?.edges?.[0]?.node?.slabImages?.map((el) => getImageEntityURLString(el) || "") ||
        "",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: row?.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    {
      id: row?.project,
      numeric: false,
      disablePadding: false,
      label: "Project",
    },
    {
      id: row.observation || "",
      numeric: false,
      disablePadding: false,
      label: "Packing Type",
    },
    {
      id: format(new Date(String(row.createdAt)), "yyyy-MM-dd") || "",
      numeric: false,
      disablePadding: false,
      label: "Packing Type",
    },
    {
      id: (row?.slabs?.totalCount || 0) + "",
      numeric: true,
      disablePadding: false,
      label: "Slabs",
    },
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "",
      element: element,
    },
  ];
};
