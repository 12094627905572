import React, { useEffect, useState } from "react";
//@ts-ignore
import { Helmet } from "react-helmet";
import { Box, Card, Grid, LinearProgress } from "@material-ui/core";
import { NetworkStatus, useQuery } from "@apollo/react-hooks";
import { QUERY_JOBS, QUERY_JOB_TYPES, QUERY_SCANNERS } from "../../../data/graphQLQueries";
import {
  CursorPaging,
  DescState,
  JobFilter,
  Jobs,
  JobSort,
  JobSortFields,
  JobsVariables,
  Jobs_jobs_edges_node,
  JobtypeFilter,
  JobTypes,
  JobtypeSort,
  JobTypes_jobtypes_edges_node,
  ScannerFilter,
  Scanners,
  ScannerSort,
  Scanners_scanners_edges_node,
  SortDirection,
} from "../../../data/graphQLModel";
import {
  copyObject,
  getEndDateFromFilter,
  getFieldValueOfObjectByPreference,
  getStartDateFromFilter,
  onChangePageCursor,
  onEndDateChangeGraphQLFilters,
  onStartDateChangeGraphQLFilters,
} from "../../../utils/utils";
import NewTablePageHeaderComponent from "../../../components/Table/NewTablePageHeaderComponent";
import NewGeneralTableComponent, {
  ICompleteHeadCells,
} from "../../../components/Table/NewGeneralTableComponent";
import AutocompleteWithQuery from "../../../components/Form/AutocompleteWithQuery";
import { queryValuesForFilter } from "../../../utils/ApolloClientUtils";
import AutocompleteFilterJustText from "../../../components/Filters/AutoCompleteFilterJustText";
import SelectDate from "../../../components/CatalogFilters/SelectDate";

export const jobHeadCells: ICompleteHeadCells[] = [
  { id: "sequence", numeric: false, disablePadding: false, label: "Sequence" },
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "description", numeric: false, disablePadding: false, label: "Description" },
  { id: "jobtype", numeric: false, disablePadding: false, label: "Job Type" },
  { id: "blocknumber", numeric: false, disablePadding: false, label: "Block Nº" },
  { id: "totalslabs", numeric: false, disablePadding: false, label: "Num. Scans" },
  { id: "descstate", numeric: false, disablePadding: false, label: "State" },
  { id: "scan", numeric: false, disablePadding: false, label: "Scan" },
  { id: "company", numeric: false, disablePadding: false, label: "Company" },
  { id: "createdAt", numeric: false, disablePadding: false, label: "Date" },
];

export const getJobRowValues = (row: Jobs_jobs_edges_node): ICompleteHeadCells[] => {
  return [
    {
      id: row?.sequence + "",
      numeric: false,
      disablePadding: false,
      label: "Sequence",
    },
    {
      id: row?.code,
      numeric: false,
      disablePadding: false,
      label: "Code",
    },
    {
      id: row?.description || "",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: row?.jobtype?.description || "",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: row?.block?.tenantCode || row?.block?.wescanCode || row.blocknumber || "",
      numeric: false,
      disablePadding: false,
      label: "Block",
    },
    {
      id: (row?.scannedSlabs || 0) + "/" + row?.totalslab,
      numeric: false,
      disablePadding: false,
      label: "Num. Scans",
    },
    {
      id: row?.descstate || "",
      numeric: false,
      disablePadding: false,
      label: "State",
    },
    {
      id: row?.scan?.description || "",
      numeric: false,
      disablePadding: false,
      label: "Scanner",
    },
    {
      id: row?.company?.name || "",
      numeric: false,
      disablePadding: false,
      label: "Company",
    },
    {
      id: String(
        getFieldValueOfObjectByPreference(row, [
          "createdAt",
          "createdate",
          "updatedAt",
          "lastupdate",
        ])
      ),
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
};

const defaultDateFilters: JobFilter = {};

const DEFAULT_LIMIT = 10;
const DEFAULT_SORTING: JobSort[] = [
  { direction: SortDirection.DESC, field: JobSortFields.createdAt },
];
const DEFAULT_PAGING: CursorPaging = { first: 10 };

export default function JobsList() {
  const [filter, setFilter] = useState<JobFilter>({
    ...defaultDateFilters,
  });
  const [paging, setPaging] = useState<CursorPaging>(DEFAULT_PAGING);
  const [sorting, setSorting] = useState<JobSort[]>(DEFAULT_SORTING);
  const [pageNumber, setPageNumber] = useState<number>(0);

  // AUTOCOMPLETE VALUE STATES

  const [scanners, setScanners] = useState<Scanners_scanners_edges_node[]>([]);
  const [jobTypes, setJobTypes] = useState<JobTypes_jobtypes_edges_node[]>([]);

  const handleAutoCompleteChange = (e: any) => {
    let key = e.target.name as keyof JobFilter;
    let value = e.target.value as string;
    if (e.target.value) {
      setFilter({ ...filter, [key]: { eq: value } });
    } else {
      const newJobFilters = copyObject(filter, [key]);
      setFilter(newJobFilters);
    }
  };

  // DATE FILTER

  const { loading, error, data, networkStatus } = useQuery<Jobs, JobsVariables>(QUERY_JOBS, {
    variables: {
      paging,
      sorting,
      filter,
    },
  });

  const handleStartDateChange = (date: any) => {
    onStartDateChangeGraphQLFilters(filter, date, (newAnd) => {
      setFilter({
        ...filter,
        and: newAnd,
      });
    });
  };

  const handleEndDateChange = (date: any) => {
    onEndDateChangeGraphQLFilters(filter, date, (newAnd) => {
      setFilter({
        ...filter,
        and: newAnd,
      });
    });
  };

  useEffect(() => {
    setPaging(DEFAULT_PAGING);
    setSorting(DEFAULT_SORTING);
    setPageNumber(0);
  }, [filter]);

  return (
    <>
      <Helmet>
        <title>Jobs | WeScan</title>
      </Helmet>
      <Box
        sx={{
          py: 3,
          mb: 5,
        }}
      >
        <>
          <NewTablePageHeaderComponent
            hasButton={true}
            buttonTitle={"New Job"}
            entityNamePlural={"Jobs"}
            pathEntity={"/slabsprocess/jobs"}
            gridProps={{ sx: { mb: 2, mt: 2 } }}
          />
          <Card sx={{ mb: 2 }}>
            <Grid
              container
              spacing={3}
              mt={1}
              padding={1}
              sx={{ justifyContent: "center", alignItems: "center", mt: -3 }}
              flexDirection={"row"}
            >
              <Grid item lg={3} xs={12}>
                <Grid item sx={{ justifyContent: "flex-end", ml: 3 }}>
                  <SelectDate
                    label={"Start Date"}
                    selectedDate={getStartDateFromFilter(filter)}
                    handleDateChange={handleStartDateChange}
                    maxDate={getEndDateFromFilter(filter)}
                  />

                  <SelectDate
                    label={"End Date"}
                    selectedDate={getEndDateFromFilter(filter)}
                    handleDateChange={handleEndDateChange}
                    minDate={getStartDateFromFilter(filter)}
                  />
                </Grid>
              </Grid>
              <Grid item lg={3} xs={12}>
                <AutocompleteFilterJustText
                  items={Object.keys(DescState)}
                  title={"State"}
                  label={"State"}
                  value={filter?.descstate?.eq}
                  onChange={handleAutoCompleteChange}
                  field=""
                  name={"descstate"}
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                <AutocompleteWithQuery
                  AutocompleteProps={{ className: "" }}
                  items={jobTypes}
                  label="Job Types"
                  TextFieldProps={{ label: "Job Types" }}
                  name="jobtype"
                  value={filter?.jobtype?.eq}
                  onChange={handleAutoCompleteChange}
                  title="Job Types"
                  getField={(node: JobTypes_jobtypes_edges_node) => {
                    return `${node.code} - ${node.description}`;
                  }}
                  onTextChange={async (value: string) => {
                    await queryValuesForFilter<JobtypeFilter, CursorPaging, JobtypeSort, JobTypes>(
                      value,
                      QUERY_JOB_TYPES,
                      (res) => {
                        let endResJobTypes = res?.data?.jobtypes?.edges?.map((el) => el.node);
                        setJobTypes(endResJobTypes);
                      },
                      {
                        filter: {
                          or: [{ code: { iLike: value } }, { description: { iLike: value } }],
                        },
                        paging: { first: 40 },
                      }
                    );
                  }}
                  continueToLoadResultsInComponent
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                <AutocompleteWithQuery
                  AutocompleteProps={{ className: "" }}
                  TextFieldProps={{ label: "Scanners" }}
                  items={scanners}
                  label="Scanners"
                  name="scan"
                  value={filter?.scan?.eq}
                  onChange={handleAutoCompleteChange}
                  title="Scanners"
                  getField={(node: Scanners_scanners_edges_node) => {
                    return node.description;
                  }}
                  onTextChange={async (value: string) => {
                    await queryValuesForFilter<ScannerFilter, CursorPaging, ScannerSort, Scanners>(
                      value,
                      QUERY_SCANNERS,
                      (res) => {
                        let endResScanner = res?.data?.scanners?.edges?.map((el) => el.node);
                        setScanners(endResScanner);
                      },
                      {
                        filter: {
                          or: [{ code: { iLike: value } }, { description: { iLike: value } }],
                        },
                        paging: { first: 40 },
                      }
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Card>
          <NewGeneralTableComponent
            data={!!data?.jobs ? data?.jobs?.edges?.map((edge) => edge.node) : []}
            loading={loading || networkStatus === NetworkStatus.fetchMore}
            error={error}
            LoadingComponent={() => (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
            onChangePage={(e: any, page: number) => {
              onChangePageCursor(
                e,
                page,
                pageNumber,
                !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
                data?.jobs?.pageInfo || ({} as any),
                (pageNumber: number, newPaging: CursorPaging) => {
                  setPaging(newPaging);
                  setPageNumber(pageNumber);
                }
              );
            }}
            onRowsPerPageChange={(event) => {
              setPaging({ first: parseInt(event.target.value, 10) });
            }}
            page={pageNumber}
            rowsPerPage={
              !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT
            }
            headCells={jobHeadCells}
            totalCount={data?.jobs?.totalCount || 0}
            handleSort={(field, order) => {
              setSorting([{ field: field as JobSortFields, direction: order }]);
            }}
            orderByField={sorting[0].field}
            orderDirection={sorting[0].direction}
            sortableFields={Object.values(JobSortFields)}
            pathEntity={"/slabsprocess/jobs"}
            editPath={"/slabsprocess/jobs/details"}
            key={"Job"}
            getValues={getJobRowValues}
            base64OrDataOnEdit={"ID"}
            base64OrDataOnDetails={"ID"}
            base64OrDataOrIdOnDelete={"ID"}
            deletePath={"/slabsprocess/jobs/delete"}
            disableRemove={false}
            disableSelection={false}
            disableSelectAll={true}
            disableMultipleSelection={true}
            PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
            tableContainerProps={{ sx: { minHeight: 440, maxHeight: 440 } }}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </>
      </Box>
    </>
  );
}
