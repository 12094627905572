import React, { useEffect, useState } from 'react';
//@ts-ignore
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import TablePageHeaderComponent from '../../../components/Table/TablePageHeaderComponent';
import GeneralTableComponent from '../../../components/Table/GeneralTableComponent';
import { useQuery } from '@apollo/react-hooks'
import { ENVIRONMENTAL_FACTORS } from '../../../data/graphQLQueries';
import { EnvironmentalFactors, EnvironmentalFactorsVariables, EnvironmentalFactors_environmentalFactors_edges_node } from '../../../data/graphQLModel';

const headCells = [
  // { id: 'name', numeric: false, disablePadding: false, label: 'Factor Name' },
  { id: 'application_type', numeric: false, disablePadding: false, label: 'Application Typology' },
  { id: 'level', numeric: false, disablePadding: false, label: 'Level' },
  { id: 'measurement', numeric: false, disablePadding: false, label: 'Measurement' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'range_start', numeric: false, disablePadding: false, label: 'From' },
  { id: 'range_end', numeric: false, disablePadding: false, label: 'To' },
];

const groupByName = (env_factors: EnvironmentalFactors_environmentalFactors_edges_node[]):
  [{ element: EnvironmentalFactors_environmentalFactors_edges_node[] }] => {

  let toReturn = [] as unknown as [{ element: EnvironmentalFactors_environmentalFactors_edges_node[] }]
  let tempResult = {} as { [key: string]: { name: string, count: number } }

  for (let { name } of env_factors)
    tempResult[name] = {
      name,
      count: tempResult[name] ? tempResult[name].count + 1 : 1
    }

  for (const key in tempResult) {
    if (Object.prototype.hasOwnProperty.call(tempResult, key)) {
      const element = tempResult[key];
      toReturn.push({ element: env_factors.filter((el) => el.name === element.name) })
    }
  }

  return toReturn
}


export default function EnvironmentalFactorsTable() {
  const [isDataTreated, setIsDataTreated] = useState<boolean>(false)
  const { loading, error, data } = useQuery<EnvironmentalFactors,
    EnvironmentalFactorsVariables>(ENVIRONMENTAL_FACTORS, {
      variables: {
        paging: { first: 200 }
      }
    })
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error</div>
  }



  return (
    <>
      <Helmet>
        <title>Environmental Factors | WeScan</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <TablePageHeaderComponent buttonTitle={"New Factor Score"}
            entityNamePlural={"Environmental Factor Score"} pathEntity={'#'}
          />
          <Grid>
            {data &&
              groupByName(data.environmentalFactors.edges.map((edge) => edge.node)).map((el, ind) => {
                return (
                  <Box sx={{ mt: 5 }}>
                    <GeneralTableComponent data={el.element} headCells={headCells}
                      orderByDefault={'level'} pathEntity={'#'}
                      disableEdit={true} disableRemove={true}
                      key={el.element[0].name}
                      tableSubTitle={el.element[0].name}
                      disableDetails={true}
                      disableSelection={true}
                      tableComponent='container'
                    />
                  </Box>
                )
              })
            }
          </Grid>
        </Container>
      </Box>
    </>
  )
}
