import { apolloClient } from "../services_v2/apollo.graphql";
import { MUTATION_LOGIN, USER_BY_ID } from "../data/graphQLQueries";
import {
  Login,
  LoginVariables,
  Login_login,
  User,
  UserVariables,
  User_user,
} from "../data/graphQLModel";

class AuthServices {
  signIn = async (username: string, password: string): Promise<Login_login> => {
    return await apolloClient
      .mutate<Login, LoginVariables>({
        mutation: MUTATION_LOGIN,
        variables: {
          loginInput: {
            password: password,
            username: username,
          },
        },
      })
      .then((res) => {
        if (res.data && !res.errors) {
          this.setV2Token(res.data?.login.access_token);
          return res.data?.login;
        } else {
          throw new Error(
            !!res.errors && res.errors.length > 0
              ? res.errors?.map((el) => el.message).join("; ")
              : "Login Failed"
          );
        }
      });
  };

  setV2Token = (token: string) => {
    localStorage.setItem("accessTokenV2", token);
  };

  getV2Token = () => localStorage.getItem("accessTokenV2");

  signOut = () => {
    this.removeToken();
  };

  removeToken = () => {
    localStorage.removeItem("accessTokenV2");
  };

  getUserV2 = async (id: string): Promise<User_user | null> => {
    return await apolloClient
      .query<User, UserVariables>({
        query: USER_BY_ID,
        variables: {
          id: id,
        },
      })
      .then((res) => res?.data?.user);
  };

  isAuthenticated = () => !!this.getV2Token();
}

const authServices = new AuthServices();

export default authServices;
