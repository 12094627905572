import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { Box, Card, CardContent, Container, Grid } from "@material-ui/core";
import swalert from "sweetalert";
import { MuiThemeProps } from "../../../theme/theme";
import AutocompleteWithQuery from "../../../components/Form/AutocompleteWithQuery";
import {
  PackingTypeFilter,
  CursorPaging,
  PackingTypeSort,
  PackingTypes,
  PackingTypes_packingTypes_edges_node,
  ProjectFilter,
  ProjectSort,
  Projects,
  Projects_projects_edges_node,
  CreateOnePacking,
  CreateOnePackingVariables,
} from "../../../data/graphQLModel";
import {
  MUTATION_CREATE_ONE_PACKING,
  QUERY_MANY_PACKAGE_TYPES,
  QUERY_MANY_PROJECTS,
} from "../../../data/graphQLQueries";
import { queryValuesForFilter } from "../../../utils/ApolloClientUtils";
import { useMutation } from "@apollo/react-hooks";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "80%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function CreatePackage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [packageTypes, setPackageTypes] = useState<PackingTypes_packingTypes_edges_node[]>([]);
  const [projects, setProjects] = useState<Projects_projects_edges_node[]>([]);

  const [form, setForm] = useState({
    code: "",
    packing: "",
    observation: "",
    project: "",
    packingtype: "",
  });

  function changeform(e: any) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  const [mutationCreateOnePacking] = useMutation<CreateOnePacking, CreateOnePackingVariables>(
    MUTATION_CREATE_ONE_PACKING
  );

  async function submitForm(e: any) {
    e.preventDefault();
    if (form.code === "" || form.code.length < 2 || form.packingtype === "") {
      swalert("Information!", "Missing information!", "warning");
      return;
    }
    try {
      var res = await mutationCreateOnePacking({
        variables: {
          input: {
            packing: {
              code: form.code,
              packing: form.packing,
              observation: form.observation,
              packingtype: form.packingtype === "" ? null : form.packingtype,
              project: form.project === "" ? null : form.project,
            },
          },
        },
      });
      if (res) swalert("Success.", "Package created with success", "success");
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert(
        "Failed.",
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Erro",
        "error"
      );
      return;
    }
    dispatch(notLoadingComponent());
    history.push("/customerstables/packages");
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/customerstables/packages");
  }

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>New Package</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm} style={{ width: "100%" }}>
              <div>
                <FormControl fullWidth className={classes.marginCode} variant="outlined">
                  <TextField
                    id="CodePackage"
                    label="Code"
                    name="code"
                    placeholder="Code"
                    required
                    focused
                    onChange={changeform}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                  <TextField
                    id="packing"
                    label="Package"
                    name="packing"
                    onChange={changeform}
                    placeholder="Package"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div>
                <Grid key="package-type-grid" item md={4} xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteWithQuery
                      items={packageTypes}
                      label="Package Types*"
                      name="packingtype"
                      onChange={changeform}
                      title="Package Types"
                      value={form.packingtype}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          PackingTypeFilter,
                          CursorPaging,
                          PackingTypeSort,
                          PackingTypes
                        >(
                          value,
                          QUERY_MANY_PACKAGE_TYPES,
                          (res) => {
                            let endRes = res?.data?.packingTypes?.edges?.map((el) => el.node);
                            setPackageTypes(endRes);
                          },
                          {
                            filter: {
                              or: [{ code: { iLike: value } }],
                            },
                            paging: {
                              first: 30,
                            },
                          }
                        );
                      }}
                      getField={(node: PackingTypes_packingTypes_edges_node) => {
                        return `${node.code} - ${node.observation}`;
                      }}
                    />
                  </FormControl>
                </Grid>
              </div>
              <div>
                <Grid key="project-grid" item md={4} xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteWithQuery
                      items={projects}
                      label="Projects"
                      name="project"
                      onChange={changeform}
                      title="Projects"
                      value={form.project}
                      onTextChange={async (value: string) => {
                        await queryValuesForFilter<
                          ProjectFilter,
                          CursorPaging,
                          ProjectSort,
                          Projects
                        >(
                          value,
                          QUERY_MANY_PROJECTS,
                          (res) => {
                            let endRes = res?.data?.projects?.edges?.map((el) => el.node);
                            setProjects(endRes);
                          },
                          {
                            filter: {
                              or: [
                                { code: { iLike: value } },
                                { description: { iLike: value } },
                                { observation: { iLike: value } },
                                { project: { iLike: value } },
                              ],
                            },
                            paging: {
                              first: 30,
                            },
                          }
                        );
                      }}
                      getField={(node: Projects_projects_edges_node) => {
                        return node.project;
                      }}
                    />
                  </FormControl>
                </Grid>
              </div>
              <div>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  style={{ marginTop: 20 }}
                  variant="outlined"
                >
                  <TextField
                    id="obsPackage"
                    label="Observation"
                    name="observation"
                    placeholder="Observation"
                    onChange={changeform}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={5}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      disabled={
                        !!!form.code ||
                        form.code === "" ||
                        form.code.length <= 2 ||
                        !!!form.packingtype ||
                        form.packingtype === "" ||
                        !!!form.packing ||
                        form.packing === ""
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Create
                      <Save style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
