/* eslint-disable */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams, NavLink } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
} from "@material-ui/core";
import QueueIcon from "@material-ui/icons/Queue";
import FormControl from "@material-ui/core/FormControl";
import swalert from "sweetalert";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { loadingComponent, notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { MuiThemeProps } from "../../../theme/theme";
import { NetworkStatus, useMutation, useQuery } from "@apollo/react-hooks";
import {
  CommercialVariety_commercialVariety_annexedDocuments_nodes,
  CursorPaging,
  PackingFilter,
  PackingsGallery,
  PackingsGalleryVariables,
  PackingSort,
  PackingSortFields,
  Project,
  ProjectVariables,
  Project_project_packings_edges_node,
  SlabFilter,
  Slabs_slabs_nodes,
  SortDirection,
  UpdateManyPackings,
  UpdateManyPackingsVariables,
  UpdateManySlabs,
  UpdateManySlabsVariables,
  UpdateOneProject,
  UpdateOneProjectVariables,
} from "../../../data/graphQLModel";
import {
  MUTATION_UPDATE_MANY_PACKINGS,
  MUTATION_UPDATE_MANY_SLABS,
  MUTATION_UPDATE_ONE_PROJECT,
  QUERY_MANY_PACKINGS_GALLERY,
  QUERY_ONE_PROJECT,
  QUERY_SLABS,
} from "../../../data/graphQLQueries";
import NewGeneralTableComponent from "../../../components/Table/NewGeneralTableComponent";
import AnnexedDocumentButtonDialog from "../../../components/AnnexedDocs/AnnexDocumentButtonModal";
import SlabsTable from "../../../components/slabs.components/SlabsTable";
import { getPackagesRowDefaultValue, packingHeadCellsDefault } from "../Packages/utils";
import { Delete, RemoveCircleOutline } from "@material-ui/icons";
import { H2 } from "../../../components/GeneralComponents/Typography";
import ShareLinkButtonDialog from "../../../components/ShareLink/DialogShareLink";
import { decodeBase64, onChangePageCursor } from "../../../utils/utils";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  margin: {
    marginBottom: "2ch",
    marginTop: "2ch",
  },
  margintwo: {
    marginBottom: "2ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function DetailsProject() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [packing, setPacking] = useState<Project_project_packings_edges_node[]>();
  const [active, setActive] = useState<boolean>();
  const [projectInStock, setProjectInStock] = useState<boolean>();
  const [annexedDocs, setAnnexedDocs] =
    useState<CommercialVariety_commercialVariety_annexedDocuments_nodes[]>();

  let { prj } = useParams() as any;

  const { loading, error, data, refetch, variables } = useQuery<Project, ProjectVariables>(
    QUERY_ONE_PROJECT,
    {
      variables: {
        id: decodeBase64(prj),
        packagesPaging: { first: 0 },
        slabsPaging: { first: 0 },
      },
      onCompleted(data) {
        let newData = data?.project;
        if (!!newData) {
          setProjectInStock(!!newData.inStock);
          setActive(!!newData.active);
          setPacking(newData.packings?.edges.map((el) => el.node));
          let packageSlabsWithDocs: CommercialVariety_commercialVariety_annexedDocuments_nodes[] =
            [];
          newData.packings?.edges.forEach((pckg) => {
            pckg.node.slabs?.edges.forEach((slb) => {
              if (
                slb?.node?.commercial_variety?.annexedDocuments &&
                slb?.node?.commercial_variety?.annexedDocuments?.nodes?.length > 0
              ) {
                packageSlabsWithDocs.push(
                  ...slb?.node?.commercial_variety?.annexedDocuments?.nodes
                );
              }
            });
          });
          newData?.slabs?.edges?.forEach((slb) => {
            if (
              slb?.node?.commercial_variety?.annexedDocuments &&
              slb?.node?.commercial_variety?.annexedDocuments?.nodes?.length > 0
            ) {
              packageSlabsWithDocs.push(...slb?.node?.commercial_variety?.annexedDocuments?.nodes);
            }
          });
          setAnnexedDocs(packageSlabsWithDocs);
        }
      },
    }
  );

  const [mutationUpdateOneProject] = useMutation<UpdateOneProject, UpdateOneProjectVariables>(
    MUTATION_UPDATE_ONE_PROJECT,
    {
      onCompleted(data, clientOptions) {
        if (!!data.updateOneProject) {
          refetch(variables);
        }
      },
    }
  );
  const [mutationUpdateManyPackings] = useMutation<UpdateManyPackings, UpdateManyPackingsVariables>(
    MUTATION_UPDATE_MANY_PACKINGS,
    {
      onCompleted(data, clientOptions) {
        if (!!data?.updateManyPackings) {
          refetch(variables);
          packageRefetch(packageVariables);
        }
      },
    }
  );
  const [mutationUpdateManySlabs, { loading: updateSlabsLoading }] = useMutation<
    UpdateManySlabs,
    UpdateManySlabsVariables
  >(MUTATION_UPDATE_MANY_SLABS, {
    onCompleted(data, clientOptions) {
      if (!!data?.updateManySlabs) {
        refetch(variables);
      }
    },
  });

  const [slabsFilter, setSlabsFilter] = useState<SlabFilter>({ project: { eq: atob(prj) } });

  async function removeSlabs(slabs: Partial<Slabs_slabs_nodes>[]) {
    swalert({
      title: "Remove",
      text: "Are you sure you want to remove these Slabs from the project?",
      icon: "warning",
      //@ts-ignore
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete: boolean) => {
      if (willDelete) {
        dispatch(loadingComponent());
        await mutationUpdateManySlabs({
          variables: {
            input: {
              filter: {
                id: { in: slabs.map((el) => el.id as string) },
              },
              update: {
                project: null,
              },
            },
          },
        })
          .then((res) => {
            if (!!res.errors) {
              swalert("Failed.", res?.errors?.toString(), "error");
            } else {
              swalert("Success.", "Slabs removed", "success");
              setSlabsFilter(JSON.parse(JSON.stringify(slabsFilter)));
            }
          })
          .catch((err) => {
            dispatch(notLoadingComponent());
            swalert("Failed.", err, "error");
          });
        dispatch(notLoadingComponent());
      }
    });
  }

  function handleGoBack(event: any) {
    event.preventDefault();
    // To prevent users to return to tables instead of gallery
    history.goBack();
  }

  //#region Packages/Bundles
  const [packageFilter, setPackageFilter] = useState<PackingFilter>({
    project: { eq: decodeBase64(prj) },
  });
  const [packagePaging, setPackagePaging] = useState<CursorPaging>({ first: 10 });
  const [packageSorting, setPackageSorting] = useState<PackingSort[]>([
    { field: PackingSortFields.createdAt, direction: SortDirection.DESC },
  ]);
  const [packagesPageNumber, setPackagePageNumber] = useState<number>(0);

  const {
    loading: packageLoading,
    error: packageError,
    data: packageData,
    networkStatus: packageNetworkStatus,
    variables: packageVariables,
    refetch: packageRefetch,
  } = useQuery<PackingsGallery, PackingsGalleryVariables>(QUERY_MANY_PACKINGS_GALLERY, {
    variables: {
      paging: packagePaging,
      sorting: packageSorting,
      filter: packageFilter,
    },
  });
  //#endregion

  // ########################################## START STATE CHANGE ##########################################

  const handleState = async (e: any) => {
    e.preventDefault();

    swalert({
      title: "Change Project State",
      text: "Are you sure?",
      icon: "warning",
      //@ts-ignore
      buttons: true,
      dangerMode: true,
    }).then((willChange: boolean) => {
      if (willChange) {
        submitProjectChange(e);
      }
    });
  };

  async function submitProjectChange(e: any) {
    e.preventDefault();
    await mutationUpdateOneProject({
      variables: {
        input: {
          id: data?.project?.id as string,
          update: {
            active: !active,
          },
        },
      },
    });
    dispatch(loadingComponent());
    try {
      swalert("Success.", "Applied changes to project " + data?.project?.project, "success");
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert("Failed.", error, "error");
      return;
    }
    dispatch(notLoadingComponent());
  }

  // ########################################## END STATE CHANGE ##########################################

  // const handleStockMovement = async (e: any) => {
  //   e.preventDefault();
  //   swalert({
  //     text: "Observation",
  //     content: {
  //       element: "input",
  //     },
  //     //@ts-ignore
  //     button: {
  //       text: "Send",
  //       closeModal: false,
  //     },
  //   }).then((obs) => {
  //     if (obs) {
  //       let opt = projectInStock ? "SHIPPED" : "RECEIVED";
  //       confirmStockMovement(e, obs, opt);
  //     }
  //   });
  // };

  // async function confirmStockMovement(e: any, obs: string, mov: any) {
  //   e.preventDefault();

  //   swalert({
  //     title: "Project Stock Movement",
  //     text: "Are you sure?",
  //     icon: "warning",
  //     //@ts-ignore
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       submitStockMovement(e, obs, mov);
  //     }
  //   });
  // }

  async function removePackage(package_ids: string[]) {
    swalert({
      title: "Remove",
      text: "Are you sure you want to remove these Packages?",
      icon: "warning",
      //@ts-ignore
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete: boolean) => {
      if (willDelete) {
        dispatch(loadingComponent());
        await mutationUpdateManyPackings({
          variables: {
            input: {
              filter: {
                id: { in: package_ids },
              },
              update: {
                project: null,
              },
            },
          },
        })
          .then((res) => {
            if (!!res.errors) {
              swalert("Failed.", res?.errors?.toString(), "error");
            } else {
              swalert("Success.", "Packages removed", "success");
              setPacking(packing?.filter((pck) => package_ids.indexOf(pck.id) === -1));
            }
          })
          .catch((err) => {
            dispatch(notLoadingComponent());
            swalert("Failed.", err, "error");
          });
        dispatch(notLoadingComponent());
      }
    });
  }

  // async function submitStockMovement(e: any, obs: string, mov: any) {
  //   e.preventDefault();

  //   dispatch(loadingComponent());
  //   try {
  //     //GUILHERME
  //     let messageMovStock = "";
  //     if (projectInStock) {
  //       messageMovStock = await movStockServices.createMovStockFromProject(
  //         data?.project?.id,
  //         mov,
  //         obs
  //       );
  //     } else {
  //       messageMovStock = await movStockServices.deleteMovStockProject(data?.project?.id);
  //     }
  //     swalert("Success.", messageMovStock, "success");
  //   } catch (error: any) {
  //     dispatch(notLoadingComponent());
  //     swalert("Failed.", error, "error");
  //     return;
  //   }
  //   dispatch(notLoadingComponent());
  // }

  // ########################################## END STOCK MOVEMENT ##########################################

  return (
    <div>
      <Box style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <FormControl variant="outlined">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    type="submit"
                    disabled={history?.length <= 1}
                    onClick={handleGoBack}
                    color="primary"
                    sx={{ mr: 1 }}
                  >
                    <ArrowBackIosIcon fontSize="large" />
                  </IconButton>
                  <H2>Details Project</H2>
                </Box>
              </FormControl>
              <Box
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                }}
              >
                <NavLink to={"/customerstables/projects/detailsproject/addpackaging/" + prj}>
                  <Button
                    startIcon={<QueueIcon />}
                    disabled={!active}
                    color="primary"
                    variant="contained"
                    style={{ position: "relative", marginBottom: "5px", marginRight: "10px" }}
                  >
                    Add Packages
                  </Button>
                </NavLink>

                <ShareLinkButtonDialog
                  shareType="project"
                  sharedId={data?.project?.id || ""}
                  disabled={!!!data?.project}
                  buttonProps={{
                    style: { position: "relative", marginBottom: "5px", marginRight: "10px" },
                    color: "secondary",
                    variant: "contained",
                  }}
                />
              </Box>
            </div>
          </Card>
        </Box>

        <Card>
          <CardContent>
            <form className={classes.form}>
              {!error && (
                <>
                  {annexedDocs && annexedDocs.length > 0 && (
                    <AnnexedDocumentButtonDialog
                      parentBoxSxProps={{ textAlign: "right" }}
                      key="AnnexedDocFileDropZone"
                      onCancel={() => {}}
                      annexedDocs={annexedDocs}
                    />
                  )}
                  <Box style={{ display: "flex", flexBasis: 3, margin: 20 }}>
                    <h4 style={{ flex: 2 }}>Project Actions:</h4>
                    <Button
                      color={!active ? "primary" : "inherit"}
                      onClick={(e) => handleState(e)}
                      variant="contained"
                      style={{ position: "relative", marginRight: "20px" }}
                    >
                      {active ? "Disable" : "Activate"}
                    </Button>
                    {/* <Button
                      disabled={!active}
                      onClick={(e) => handleStockMovement(e)}
                      color="primary"
                      variant="contained"
                      style={{ position: "relative" }}
                    >
                      {projectInStock ? "Clear Stock" : "Undo Stock Movement"}
                    </Button> */}
                  </Box>
                  <Grid container justifyContent="space-around">
                    <Grid item md={5} xs={12}>
                      <Grid>
                        <FormControl fullWidth variant="outlined">
                          {loading ? (
                            <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                              <TextField value={""} />
                            </Skeleton>
                          ) : (
                            <TextField
                              id="CodeProject"
                              label="Code"
                              name="code"
                              value={data?.project?.code}
                              placeholder="Code"
                              disabled
                              variant="outlined"
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid>
                        <FormControl fullWidth variant="outlined">
                          {loading ? (
                            <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                              <TextField value={""} />
                            </Skeleton>
                          ) : (
                            <TextField
                              id="projProject"
                              label="Project"
                              className={classes.margin}
                              name="project"
                              value={data?.project?.project}
                              placeholder="Project"
                              disabled
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid>
                        <FormControl fullWidth variant="outlined">
                          {loading ? (
                            <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                              <TextField value={""} />
                            </Skeleton>
                          ) : (
                            <TextField
                              id="descProject"
                              label="Description"
                              name="description"
                              className={classes.margintwo}
                              disabled
                              value={data?.project?.description}
                              placeholder="Description"
                              required
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid>
                        <FormControl fullWidth variant="outlined">
                          {loading ? (
                            <Skeleton component={"div"} width={"100%"} sx={{ fontSize: 50 }}>
                              <TextField value={""} />
                            </Skeleton>
                          ) : (
                            <TextField
                              id="totalArea"
                              label="Area Total"
                              name="totalArea"
                              className={classes.margintwo}
                              disabled
                              value={data?.project?.totalArea + " m" + "\u00b2"}
                              placeholder="Area p/ metro quadrado"
                              required
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                            />
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth variant="outlined">
                        {loading ? (
                          <Skeleton
                            component={"div"}
                            variant="rectangular"
                            width={"100%"}
                            height={270}
                            sx={{ marginTop: 2 }}
                          >
                            <TextField value={""} />
                          </Skeleton>
                        ) : (
                          <TextField
                            id="obsProject"
                            label="Observation"
                            name="observation"
                            placeholder="Observation"
                            disabled
                            value={data?.project?.observation}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            multiline
                            rows={11}
                            variant="outlined"
                          />
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid>
                    <NewGeneralTableComponent
                      tableSubTitle="Bundles"
                      PaperAndTableWrapperProps={{ style: { minWidth: "100%" } }}
                      data={
                        !!packageData?.packings
                          ? packageData?.packings?.edges?.map((edge) => edge.node)
                          : []
                      }
                      loading={packageLoading || packageNetworkStatus === NetworkStatus.fetchMore}
                      error={packageError}
                      LoadingComponent={() => (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress />
                        </Box>
                      )}
                      onChangePage={(e: any, page: number) => {
                        onChangePageCursor(
                          e,
                          page,
                          packagesPageNumber,
                          !!packagePaging.first
                            ? packagePaging.first
                            : !!packagePaging.last
                            ? packagePaging.last
                            : 10,
                          packageData?.packings?.pageInfo || ({} as any),
                          (pageNumber: number, newPaging: CursorPaging) => {
                            setPackagePaging(newPaging);
                            setPackagePageNumber(pageNumber);
                          }
                        );
                      }}
                      onRowsPerPageChange={(event) => {
                        setPackagePaging({ first: parseInt(event.target.value, 10) });
                      }}
                      page={packagesPageNumber}
                      rowsPerPage={
                        !!packagePaging.first
                          ? packagePaging.first
                          : !!packagePaging.last
                          ? packagePaging.last
                          : 10
                      }
                      headCells={packingHeadCellsDefault}
                      totalCount={packageData?.packings?.totalCount || 0}
                      handleSort={(field, order) => {
                        setPackageSorting([
                          { field: field as PackingSortFields, direction: order },
                        ]);
                      }}
                      ignoreEmptyRows
                      orderByField={packageSorting[0].field}
                      orderDirection={packageSorting[0].direction}
                      sortableFields={Object.values(PackingSortFields)}
                      key={"pack"}
                      getValues={getPackagesRowDefaultValue}
                      disableRemove
                      disableEdit
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      detailsPath={"/customerstables/packages/detailspackage"}
                      pathEntity={"/customerstables/packages/detailspackage"}
                      disableSelectAll
                      toolbarOptions={[
                        {
                          element: (
                            <IconButton aria-label="Remove Packages">
                              <RemoveCircleOutline />
                            </IconButton>
                          ),
                          onClick: (params) => {
                            removePackage(params.map((el) => el.id));
                          },
                          title: "",
                        },
                      ]}
                    />
                  </Grid>
                </>
              )}
              <Grid>
                <SlabsTable
                  requestAll={false}
                  showHeader={false}
                  filter={slabsFilter}
                  showEye
                  tableProps={{
                    tableSubTitle: "Slabs",
                    toolbarOptions: [
                      {
                        element: (
                          <IconButton aria-label="Remove Slabs">
                            <RemoveCircleOutline />
                          </IconButton>
                        ),
                        onClick: (params) => {
                          removeSlabs(params);
                        },
                        title: "",
                      },
                    ],
                    paperProps: {
                      style: {
                        height: "100%",
                      },
                    },
                    loading: !!updateSlabsLoading,
                  }}
                />
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}
