/* eslint-disable */
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import axios from "axios";
import { appInsightsFrontEnd } from "./azureAppInsights";
const prod = "https://apistonify.azurewebsites.net/";
const envvar = process.env.REACT_APP_API_ENDPOINT_V1;
const GRAPHQL_ENDPOINT = !!envvar ? envvar : prod;
const Axios = async (qry) => {
  return axios({
    url: GRAPHQL_ENDPOINT,
    method: "post",
    data: {
      query: qry,
    },
  })
    .then((result) => {
      if (result.data?.error) {
        result.data?.error?.errors.forEach((err) => {
          appInsightsFrontEnd.trackException(
            {
              exception: new Error(err),
              properties: { data: qry, ...err },
            },
            { data: qry, ...err }
          );
          appInsightsFrontEnd.trackTrace(
            { message: err?.message, severityLevel: SeverityLevel.Error },
            { data: qry, ...err }
          );
        });
      }
      return result.data.data;
    })
    .catch((err) => {
      appInsightsFrontEnd.trackException(
        {
          exception: err,
          properties: { data: qry, message: err?.message },
        },
        { data: qry, message: err?.message }
      );
      appInsightsFrontEnd.trackTrace(
        { message: err?.message, severityLevel: SeverityLevel.Error },
        { data: qry, message: err?.message }
      );
      throw err;
    });
};

export default Axios;
