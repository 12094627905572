import React from "react";
import { Dialog, DialogContent, IconButton, DialogProps } from "@material-ui/core";
import { Close, KeyboardArrowRight, OpenInNew } from "@material-ui/icons";
import { SlabDetailsWithQueryComponent } from "./SlabDetailsWithQuery";
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProps } from "../../theme/theme";

const useStylesDialog = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    margin: "auto",
    overflow: "hidden",
    transition: "all 250ms ease-in-out",
    borderRadius: "8px",

    "&:hover": {
      "& $imageHolder": {
        "& .extra-icons": {
          display: "flex",
        },
      },
    },

    "@media only screen and (max-width: 768px)": {
      "& $imageHolder": {
        "& .extra-icons": {
          display: "flex",
        },
      },
    },
  },
  imageHolder: {
    position: "relative",
    display: "inlin-block",
    textAlign: "center",

    "& .extra-icons": {
      display: "none",
      flexDirection: "column",
      position: "absolute",
      top: "7px",
      right: "15px",
      cursor: "pointer",
      zIndex: 2,
    },

    [theme.breakpoints.down(1)]: {
      display: "block",
    },
  },
  offerChip: {
    position: "absolute",
    fontSize: "10px",
    fontWeight: 600,
    paddingLeft: 3,
    paddingRight: 3,
    top: "10px",
    left: "10px",
  },
  details: {
    padding: "1rem",

    "& .title, & .categories": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    "& .icon-holder": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },

    "& .favorite-icon": {
      cursor: "pointer",
    },
  },
  dialogContent: {
    paddingBottom: "1.25rem",
  },
  dialogPaper: {
    minHeight: "60vh",
    maxHeight: "60vh",
  },
}));

interface SlabDetailsDialogProps {
  DialogProps?: Partial<DialogProps>;
  open: boolean;
  toggle: () => void;
  slab: string;
}

const SlabDetailsDialog = ({ DialogProps, open, slab, toggle }: SlabDetailsDialogProps) => {
  const classesDialog = useStylesDialog({ hoverEffect: true });

  return (
    <Dialog
      open={open}
      maxWidth={"lg"}
      classes={{
        paper: classesDialog.dialogPaper,
      }}
      fullWidth
      onClose={toggle}
      {...DialogProps}
    >
      <DialogContent className={classesDialog.dialogContent}>
        <SlabDetailsWithQueryComponent slab_id={slab} />
        <IconButton
          style={{
            position: "absolute",
            top: "0",
            right: "0",
          }}
          onClick={toggle}
        >
          <Close className="close" fontSize="medium" color="primary" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default SlabDetailsDialog;
