import React, { useEffect, useState } from "react";
import { Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core";
import { Helmet } from "react-helmet";
import BundleGallery from "../../components/Bundles/BundleGallery";
import BlockGallery from "../../components/Blocks/BlockGallery";
import SlabGallery from "../../components/slabs.components/SlabGallery";
import ProjectGalleryComponent from "../../components/Projects/ProjectGalleryComponent";
import useLocationQuery from "../../hooks/useLocationQuery";
import { validateValueOfType } from "../../utils/utils";
import { useHistory, useLocation } from "react-router-dom";

const AggregateByValues = ["block", "slab", "bundle", "projects"] as const;
type AggregateByType = typeof AggregateByValues[number];

const Catalog = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const queryArgs = useLocationQuery();
  const location = useLocation();
  const history = useHistory();

  const [isSideOpen, setIsSideOpen] = useState(true);
  const toggleSide = () => setIsSideOpen(!isSideOpen);

  const TopElement = () => {
    return (
      <Tabs
        value={validateValueOfType(queryArgs.get("type"), AggregateByValues, "block")}
        onChange={(e, val) => {
          const params = new URLSearchParams({ type: val });
          history.replace({ pathname: location.pathname, search: params.toString() });
        }}
        aria-label="simple tabs example"
      >
        <Tab label="Blocks" value={"block"} />
        <Tab label="Bundles" value={"bundle"} />
        <Tab label="Slabs" value={"slab"} />
        <Tab label="Projects" value={"projects"} />
      </Tabs>
    );
  };

  useEffect(() => {
    if (matches && isSideOpen) {
      toggleSide();
    } else if (!matches && !isSideOpen) {
      toggleSide();
    }
  }, [matches]);

  const AggregateSwitch = (by: AggregateByType) => {
    switch (by) {
      case "slab":
        return (
          <SlabGallery TopElement={TopElement} isSideOpen={isSideOpen} toggleSide={toggleSide} />
        );
      case "block":
        return (
          <BlockGallery TopElement={TopElement} isSideOpen={isSideOpen} toggleSide={toggleSide} />
        );
      case "bundle":
        return (
          <BundleGallery TopElement={TopElement} isSideOpen={isSideOpen} toggleSide={toggleSide} />
        );
      case "projects":
        return (
          <ProjectGalleryComponent
            TopElement={TopElement}
            isSideOpen={isSideOpen}
            toggleSide={toggleSide}
          />
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>Gallery | WeScan</title>
      </Helmet>
      {AggregateSwitch(validateValueOfType(queryArgs.get("type"), AggregateByValues, "block"))}
    </>
  );
};

export default Catalog;
