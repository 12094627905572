/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
//@ts-ignore
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Fab,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Pagination from "@material-ui/lab/Pagination";
import swalert from "sweetalert";
import Stone from "../../components/stone";
import { MuiThemeProps } from "../../theme/theme";
import {
  BlockModelFilter,
  BlockModelSort,
  BlockModelsToFilter,
  BlockModelsToFilter_blockModels_edges_node,
  BooleanFieldComparison,
  Categories,
  CategoriesVariables,
  Categories_categories_edges_node,
  CategorySort,
  CommercialFinishingFilter,
  CommercialFinishings,
  CommercialFinishingSort,
  CommercialFinishings_commercialFinishings_edges_node,
  CommercialVarieties,
  CommercialVarietiesVariables,
  CommercialVarieties_commercialVarieties_edges_node,
  CommercialVarietySort,
  CursorPaging,
  FinishingFilter,
  Finishings,
  FinishingSort,
  Finishings_finishings_edges_node,
  JobFilter,
  Jobs,
  JobSort,
  Jobs_jobs_edges_node,
  MaterialTypeFilter,
  MaterialTypes,
  MaterialTypeSort,
  MaterialTypes_materialTypes_edges_node,
  PackingFilter,
  Packings,
  PackingSort,
  Packings_packings_edges_node,
  ProjectFilter,
  Projects,
  ProjectSort,
  Projects_projects_edges_node,
  ScannerFilter,
  Scanners,
  ScannerSort,
  Scanners_scanners_edges_node,
  SlabFilter,
  Slabs,
  SlabSortFields,
  SlabsVariables,
  Slabs_slabs_nodes,
  SortDirection,
  StoneEdgeFilter,
  StoneEdges,
  StoneEdgeSort,
  StoneEdges_stoneEdges_edges_node,
  StoneModelFilter,
  StoneModels,
  StoneModelSort,
  StoneModels_stoneModels_edges_node,
  Varieties,
  Varieties_varieties_edges_node,
  VarietyFilter,
  VarietySort,
} from "../../data/graphQLModel";
import {
  QUERY_BLOCKS_TO_FILTER,
  QUERY_CATEGORIES,
  QUERY_COMMERCIAL_FINISHINGS,
  QUERY_COMMERCIAL_VARIETIES,
  QUERY_FINISHINGS,
  QUERY_JOBS,
  QUERY_MANY_PACKINGS,
  QUERY_MANY_PROJECTS,
  QUERY_MATERIALTYPES,
  QUERY_SCANNERS,
  QUERY_SLABS,
  QUERY_STONE_EDGES,
  QUERY_STONE_MODELS,
  QUERY_VARIETIES,
} from "../../data/graphQLQueries";
import { apolloClient } from "../../services_v2/apollo.graphql";
import {
  copyObject,
  getEndDateFromFilter,
  getStartDateFromFilter,
  onEndDateChangeGraphQLFilters,
  onStartDateChangeGraphQLFilters,
} from "../../utils/utils";
import { Block, BurstMode, Clear, ClearAll, RemoveCircle, Search, Sync } from "@material-ui/icons";
import SlabSelectionMenuTab from "./slabSelectionMenuTab";
import CatalogDialogForm from "./editBundleDialog";
import PaginationContainerStructure from "./PaginationContainerStructure";
import BazarCard from "../../components/GeneralComponents/BazarCard";
import TuneIcon from "@material-ui/icons/Tune";
import { layoutConstant } from "../../utils/constants";
import { H3 } from "../../components/GeneralComponents/Typography";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import FlexBox from "../../components/GeneralComponents/FlexBox";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import SelectDate from "../../components/CatalogFilters/SelectDate";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";

const useStyles = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  boxTop: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
  },
  boxBottom: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
  },
  buttonStyle: {
    height: "50px",
    fontSize: "20px",
  },
}));

type PossibleTimeFields = "createdate" | "updatedAt" | "createdAt";

export default function Catalog() {
  const theme = useTheme();
  const client = useApolloClient();
  // catalog states
  // switch toggle state
  const [isToCatalog, setIsToCatalog] = useState<boolean>(true);
  const [timeField, setTimeField] = useState<PossibleTimeFields>("createdate");

  const classes = useStyles();
  const defaultDateFilters: SlabFilter = {
    iscatalogued: { isNot: true },
    cancelCataloguing: { isNot: true },
  };

  const [isLoadingSlabs, setIsLoadingSlabs] = useState<boolean>(false);

  // catalog details bar
  const [isFixed, setIsFixed] = useState(false);
  const toggleIsFixed = useCallback((fixed: boolean) => {
    setIsFixed(fixed);
  }, []);

  // DETAILS AND CATALOG DIALOG
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const toggleDialog = useCallback(() => {
    setOpenDetailsDialog((openDetailsDialog) => !openDetailsDialog);
  }, []);
  const [isToCancelCataloguing, setToCancelCataloguing] = useState(false);
  const [isSideOpen, setIsSideOpen] = useState(true);
  const toggleSide = () => setIsSideOpen(!isSideOpen);
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (matches && isSideOpen) {
      toggleSide();
    } else if (!matches && !isSideOpen) {
      toggleSide();
    }
  }, [matches]);

  // Filters
  const [textSearch, setTextSearch] = useState<string | undefined>();
  const [jobs, setJobs] = useState<Jobs_jobs_edges_node[]>([]);
  const [scans, setScans] = useState<Scanners_scanners_edges_node[]>([]);
  const [varieties, setVarieties] = useState<Varieties_varieties_edges_node[]>([]);
  const [finishings, setFinishings] = useState<Finishings_finishings_edges_node[]>([]);
  const [commercialVarieties, setCommercialVarieties] = useState<
    CommercialVarieties_commercialVarieties_edges_node[]
  >([]);
  const [materialTypes, setMaterialTypes] = useState<MaterialTypes_materialTypes_edges_node[]>([]);
  const [commercialFinishings, setCommercialFinishings] = useState<
    CommercialFinishings_commercialFinishings_edges_node[]
  >([]);
  const [categories, setCategories] = useState<Categories_categories_edges_node[]>([]);
  const [stoneModels, setStoneModels] = useState<StoneModels_stoneModels_edges_node[]>([]);
  const [stoneEdges, setStoneEdges] = useState<StoneEdges_stoneEdges_edges_node[]>([]);
  const [blocks, setBlocks] = useState<BlockModelsToFilter_blockModels_edges_node[]>([]);
  const [packages, setPackages] = useState<Packings_packings_edges_node[]>([]);
  const [projects, setProjects] = useState<Projects_projects_edges_node[]>([]);
  const [listOfSelectedSlabs, setListOfSelectedSlabs] = useState<Slabs_slabs_nodes[]>(
    [] as Slabs_slabs_nodes[]
  );

  const account = useSelector<any>((state) => state.account.filter_stock) as
    | boolean
    | undefined
    | null;

  // global filters
  const [globalFilters, setGlobalFilters] = useState<SlabFilter>({
    ...defaultDateFilters,
  });
  // filters value of getField shown in UI
  const [filtersUIValue, setFiltersUIValue] = useState<{
    [key in keyof Partial<SlabFilter>]: string;
  }>({});

  const history = useHistory();

  //Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [slabsPerPage, setSlabsPerPage] = useState<number>(12);

  // GOTO PAGE
  const [goToPageNumber, setGoToPageNumber] = useState(1);

  useEffect(() => {
    if (account) {
      setGlobalFilters({ ...globalFilters, inStock: { is: account } });
    } else {
      const newGlobalFilters = copyObject<SlabFilter>(globalFilters, ["inStock"]);
      setGlobalFilters(newGlobalFilters);
    }
  }, [account]);

  const getSlabsV2 = async (
    globalFilters: SlabFilter,
    slabsPerPage: number,
    pageNumber: number,
    isAfterCatalog = false
  ) => {
    if (isAfterCatalog) {
      // await client.cache.
      client.cache.evict({ id: "ROOT_QUERY", fieldName: "slabs" });
      client.cache.gc();
      setListOfSelectedSlabs([]);
    }

    setIsLoadingSlabs(true);
    const result = await apolloClient.query<Slabs, SlabsVariables>({
      query: QUERY_SLABS,
      variables: {
        filter: globalFilters,
        sorting: [
          {
            direction: SortDirection.DESC,
            field: SlabSortFields[timeField],
          },
        ],
        paging: {
          limit: slabsPerPage,
          offset: slabsPerPage * pageNumber,
        },
      },
      fetchPolicy: isAfterCatalog ? "no-cache" : "cache-first",
    });
    setIsLoadingSlabs(false);
    setSlabsV2(result);
  };

  const [slabsV2, setSlabsV2] = useState<ApolloQueryResult<Slabs>>();

  // changes on filters or number of slabs per page
  useEffect(() => {
    if (pageNumber >= 0) {
      getSlabsV2(globalFilters, slabsPerPage, pageNumber);
    } else {
      setPageNumber(0);
    }
  }, [pageNumber, slabsPerPage]);

  useEffect(() => {
    setListOfSelectedSlabs([]);
    getSlabsV2(globalFilters, slabsPerPage, 0);
  }, [globalFilters]);

  const changePage = async (event: any, value: number) => {
    if (value - 1 === pageNumber) return;

    setPageNumber(value - 1);
  };

  // submit control
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  useEffect(() => {
    if (isSubmit) {
      getSlabsV2(globalFilters, slabsPerPage, pageNumber, true);
      setIsSubmit(false);
    }
  }, [isSubmit]);

  const handleCatalog = (cancelCatalog = false) => {
    listOfSelectedSlabs.length < 1
      ? swalert(
          "Sem chapas para catalogar.",
          "Deve seleccionar as chapas para Catalogar.",
          "warning"
        )
      : haveImgCatalog(cancelCatalog);
  };

  const haveImgCatalog = (cancelCatalog: boolean) => {
    toggleDialog();
    setToCancelCataloguing(cancelCatalog);
  };

  //#region Filters

  const handleStartDateChange = (date: any) => {
    onStartDateChangeGraphQLFilters(globalFilters, date, (newAnd) => {
      setGlobalFilters({
        ...globalFilters,
        and: newAnd,
      });
    });
  };

  const handleEndDateChange = (date: any) => {
    onEndDateChangeGraphQLFilters(globalFilters, date, (newAnd) => {
      setGlobalFilters({
        ...globalFilters,
        and: newAnd,
      });
    });
  };

  const handleAutoCompleteChange = (e: any) => {
    let key = e.target.name as keyof SlabFilter;
    let value = e.target.value as string;
    let uiValue = e.target.fieldUiValue as string;
    if (e.target.value) {
      setGlobalFilters({ ...globalFilters, [key]: { eq: value } });
      setFiltersUIValue({ ...filtersUIValue, [key]: uiValue });
    } else {
      const newGlobalFilters = copyObject(globalFilters, [key]);
      setGlobalFilters(newGlobalFilters);
      const newFiltersUIValue = copyObject(filtersUIValue, [key]);
      setFiltersUIValue(newFiltersUIValue);
    }
  };

  const handleClearFilter = async () => {
    setPageNumber(0);
    setGlobalFilters(defaultDateFilters);
  };

  //#endregion

  const selectImageChanged = (e: { checked: boolean; stone: Slabs_slabs_nodes }) => {
    if (e.checked) {
      let newArray = [...listOfSelectedSlabs, e.stone];
      setListOfSelectedSlabs(newArray);
    } else {
      //Remove value
      var index = listOfSelectedSlabs.indexOf(e.stone as never);
      let newArray = [...listOfSelectedSlabs];
      if (index > -1) {
        newArray.splice(index, 1);
        setListOfSelectedSlabs(newArray);
      }
    }
  };

  const handleEditImage = () => {
    if (listOfSelectedSlabs.length > 1) {
      swalert("You selected more than one image.", "Please select only one.", "warning");
      return;
    } else if (listOfSelectedSlabs.length < 1) {
      swalert("You have not selected any images.", "Please select one.", "warning");
      return;
    }
    const toEdit: Slabs_slabs_nodes = listOfSelectedSlabs[0];
    history.push({
      pathname: "/catalog/editimage/" + btoa(toEdit?.slabImages[0]?.original),
      state: { slabid: toEdit.id, imageid: toEdit?.slabImages[0]?.original, slab: toEdit },
    });
    // swalert("Service unavailable.", "Please try again later.", "warning");
  };

  const selectAllSlabsInPage = () => {
    if (slabsV2?.data) {
      // filter only for those not selected
      let newSelectedSlabs = slabsV2.data.slabs.nodes.filter(
        (el) => listOfSelectedSlabs.find((el_selected) => el.id === el_selected.id) === undefined
      );
      // add new selections
      setListOfSelectedSlabs([...listOfSelectedSlabs, ...newSelectedSlabs]);
    }
  };

  const onClearSelection = () => {
    setListOfSelectedSlabs([]);
  };

  const handleSearch = (value: string | undefined) => {
    if (!!value && value.trim() !== "") {
      setGlobalFilters({
        ...globalFilters,
        code: {
          iLike: value,
        },
      });
    }
  };

  const cataloguedButtonFilterRef = useRef<any>();
  const cancelCataloguingButtonFilterRef = useRef<any>();

  const GenerateFilterChips = (): JSX.Element => {
    let keysToIgnore: (keyof SlabFilter)[] = ["and"];
    let keysToOnlyShowIfTrue: (keyof SlabFilter)[] = ["cancelCataloguing", "iscatalogued"];
    let activeKeys = Object.keys(globalFilters) as (keyof SlabFilter)[];

    const dataForChips: { key: keyof SlabFilter; label: string }[] = [];

    for (let index = 0; index < activeKeys.length; index++) {
      const key = activeKeys[index];
      if (keysToIgnore.indexOf(key) !== -1) {
        // do nothing
      } else if (keysToOnlyShowIfTrue.indexOf(key) !== -1) {
        let filter = globalFilters[key] as BooleanFieldComparison;
        if (filter.isNot) {
          // do nothing
        } else {
          dataForChips.push({
            label: key,
            key: key,
          });
        }
      } else {
        dataForChips.push({
          label: filtersUIValue[key] as string,
          key: key,
        });
      }
    }

    return (
      <>
        <Hidden only={["sm", "xs"]}>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 5,
              maxHeight: layoutConstant.headerHeight,
              marginLeft: 2,
            }}
            spacing={1}
          >
            <Grid item>
              <H3 sx={{ fontWeight: "bold" }}>Filters: </H3>
            </Grid>
            {dataForChips.map((el) => (
              <Grid item>
                <Chip
                  label={el?.label}
                  onDelete={() => {
                    let newFilters = copyObject(globalFilters, []);
                    delete newFilters[el?.key];
                    setGlobalFilters(newFilters);
                    let newFiltersUIValue = copyObject(filtersUIValue, []);
                    delete newFiltersUIValue[el?.key];
                    setFiltersUIValue(newFiltersUIValue);
                  }}
                  deleteIcon={<RemoveCircle />}
                />
              </Grid>
            ))}
            {(!!!dataForChips || dataForChips.length === 0) && (
              <Grid item>
                <H3 sx={{ fontWeight: "300" }}>Empty</H3>
              </Grid>
            )}
          </Grid>
        </Hidden>
        <Hidden only={["lg", "md", "xl"]}>
          <Grid container></Grid>
        </Hidden>
      </>
    );
  };

  const FilterComponent = () => {
    return (
      <>
        <FlexBox marginTop={1}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <Grid item>
              <ToggleButton
                ref={cataloguedButtonFilterRef}
                value="check"
                color="primary"
                selected={!!!globalFilters?.iscatalogued}
                contextMenu={"Show Catalogued"}
                onChange={() => {
                  cataloguedButtonFilterRef && cataloguedButtonFilterRef.current.blur();
                  if (!!globalFilters?.iscatalogued) {
                    const newGlobalFilters = copyObject(globalFilters, ["iscatalogued"]);
                    setGlobalFilters(newGlobalFilters);
                  } else {
                    setGlobalFilters({
                      ...globalFilters,
                      iscatalogued: { isNot: true },
                    });
                  }
                }}
              >
                <Box>
                  <BurstMode fontSize="large" color="action" titleAccess="Show Catalogued" />
                  <Typography>Show Catalogued</Typography>
                </Box>
              </ToggleButton>
            </Grid>
            <Grid item>
              <ToggleButton
                ref={cancelCataloguingButtonFilterRef}
                value="check"
                color="secondary"
                selected={!!!globalFilters?.cancelCataloguing}
                contextMenu={"Show Canceled"}
                onChange={() => {
                  cancelCataloguingButtonFilterRef &&
                    cancelCataloguingButtonFilterRef.current.blur();
                  if (!!globalFilters?.cancelCataloguing) {
                    const newGlobalFilters = copyObject(globalFilters, ["cancelCataloguing"]);
                    setGlobalFilters(newGlobalFilters);
                  } else {
                    setGlobalFilters({
                      ...globalFilters,
                      cancelCataloguing: { isNot: true },
                    });
                  }
                }}
              >
                <Box>
                  <Block fontSize="large" color="action" titleAccess="Show Canceled" />
                  <Typography>Show Canceled</Typography>
                </Box>
              </ToggleButton>
            </Grid>
          </Grid>
        </FlexBox>
        <hr />
        <Box className={classes.boxTop}>
          <SelectDate
            label={"Start Date"}
            selectedDate={getStartDateFromFilter(globalFilters)}
            handleDateChange={handleStartDateChange}
            maxDate={getEndDateFromFilter(globalFilters)}
          />
          <SelectDate
            label={"End Date"}
            selectedDate={getEndDateFromFilter(globalFilters)}
            handleDateChange={handleEndDateChange}
            minDate={getStartDateFromFilter(globalFilters)}
          />
        </Box>
        <hr />
        <FormControl fullWidth variant="outlined">
          <TextField
            id="textsearch"
            label="Search"
            name="textsearch"
            placeholder="Search Slab"
            value={textSearch || ""}
            onChange={(e) => {
              setTextSearch(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch(textSearch);
              }
            }}
            InputProps={{
              startAdornment: (
                <IconButton
                  onClick={() => {
                    handleSearch(textSearch);
                  }}
                >
                  <Search fontSize="medium" />
                </IconButton>
              ),
              endAdornment: !!textSearch && textSearch.trim() !== "" && (
                <IconButton
                  onClick={() => {
                    setTextSearch(undefined);
                    const newGlobalFilters = copyObject(globalFilters, ["code"]);
                    setGlobalFilters(newGlobalFilters);
                  }}
                >
                  <Clear fontSize="medium" />
                </IconButton>
              ),
            }}
          />
        </FormControl>
        <Grid mt={1}>
          <AutocompleteWithQuery
            AutocompleteProps={{ className: "oldFormControl" }}
            items={blocks}
            label="Block"
            name="block"
            onChange={handleAutoCompleteChange}
            title="Block"
            TextFieldProps={{
              label: "Block",
            }}
            value={globalFilters?.block?.eq}
            onTextChange={async (value: string) => {
              await queryValuesForFilter<
                BlockModelFilter,
                CursorPaging,
                BlockModelSort,
                BlockModelsToFilter
              >(
                value,
                QUERY_BLOCKS_TO_FILTER,
                (res) => {
                  let endRes = res?.data?.blockModels?.edges?.map((el) => el.node);
                  setBlocks(endRes);
                },
                {
                  filter: {
                    or: [{ wescanCode: { iLike: value } }, { tenantCode: { iLike: value } }],
                  },
                  paging: {
                    first: 40,
                  },
                }
              );
            }}
            getField={(node: (typeof blocks)[number]) => {
              return !!node.tenantCode ? node.tenantCode : node.wescanCode;
            }}
          />
        </Grid>
        <hr />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={categories}
          label="Category"
          name="category"
          title="Categories"
          onChange={handleAutoCompleteChange}
          value={globalFilters?.category?.eq}
          TextFieldProps={{
            label: "Category",
          }}
          continueToLoadResultsInComponent={false}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<
              CategoriesVariables["filter"],
              CursorPaging,
              CategorySort,
              Categories
            >(
              value,
              QUERY_CATEGORIES,
              (res) => {
                let endRes = res?.data?.categories?.edges?.map((el) => el.node);
                setCategories(endRes);
              },
              {
                filter: { and: [{ code: { iLike: value } }] },
                paging: { first: 40 },
              }
            );
          }}
          getField={(node: Categories_categories_edges_node) => {
            if (!!node.companyNames && node.companyNames.length > 0) {
              let obj = node.companyNames[0];
              return `${obj.code} - ${obj.description}`;
            }
            return `${node.code} - ${node.description.pt}`;
          }}
        />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={materialTypes}
          label="Material Type"
          name="typematerial"
          onChange={handleAutoCompleteChange}
          title="Materials Type"
          value={globalFilters?.typematerial?.eq}
          TextFieldProps={{
            label: "Material Type",
          }}
          continueToLoadResultsInComponent={false}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<
              MaterialTypeFilter,
              CursorPaging,
              MaterialTypeSort,
              MaterialTypes
            >(
              value,
              QUERY_MATERIALTYPES,
              (res) => {
                let endRes = res?.data?.materialTypes?.edges?.map((el) => el.node);
                setMaterialTypes(endRes);
              },
              {
                filter: { or: [{ code: { iLike: value } }] },
                paging: { first: 70 },
              }
            );
          }}
          getField={(node: MaterialTypes_materialTypes_edges_node) => {
            if (!!node.companyNames && node.companyNames.length > 0) {
              let obj = node.companyNames[0];
              return `${obj.code} - ${obj.description}`;
            }
            return `${node.code} - ${node.description.pt}`;
          }}
        />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={commercialVarieties}
          label="Commercial Variety"
          name="commercial_variety"
          title="Commercial Variety"
          onChange={handleAutoCompleteChange}
          value={globalFilters?.commercial_variety?.eq}
          TextFieldProps={{
            label: "Commercial Variety",
          }}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<
              CommercialVarietiesVariables["filter"],
              CursorPaging,
              CommercialVarietySort,
              CommercialVarieties
            >(
              value,
              QUERY_COMMERCIAL_VARIETIES,
              (res) => {
                let endRes = res?.data?.commercialVarieties?.edges?.map((el) => el.node);
                setCommercialVarieties(endRes);
              },
              {
                filter: {
                  or: [{ code: { iLike: value } }, { name: { iLike: value } }],
                },
                paging: { first: 25 },
              }
            );
          }}
          getField={(node: CommercialVarieties_commercialVarieties_edges_node) => {
            return `${node.code} - ${node.name}`;
          }}
        />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={varieties}
          label="System Variety"
          name="variety"
          value={globalFilters?.variety?.eq}
          onChange={handleAutoCompleteChange}
          title="System Variety"
          TextFieldProps={{
            label: "System Variety",
          }}
          continueToLoadResultsInComponent={false}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<VarietyFilter, CursorPaging, VarietySort, Varieties>(
              value,
              QUERY_VARIETIES,
              (res) => {
                let endRes = res?.data?.varieties?.edges?.map((el) => el.node);
                setVarieties(endRes);
              },
              {
                filter: {
                  or: [{ code: { iLike: value } }, { normativedesig: { iLike: value } }],
                },
                paging: { first: 200 },
              }
            );
          }}
          getField={(node: Varieties_varieties_edges_node) => {
            return `${node.code} - ${node.normativedesig}`;
          }}
        />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={stoneModels}
          label="Models"
          name="stone_model"
          onChange={handleAutoCompleteChange}
          title="Models"
          value={globalFilters?.stone_model?.eq}
          TextFieldProps={{
            label: "Models",
          }}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<StoneModelFilter, CursorPaging, StoneModelSort, StoneModels>(
              value,
              QUERY_STONE_MODELS,
              (res) => {
                let endRes = res?.data?.stoneModels?.edges?.map((el) => el.node);
                setStoneModels(endRes);
              },
              {
                filter: {
                  or: [{ code: { iLike: value } }, { name: { iLike: value } }],
                },
                paging: { first: 40 },
              }
            );
          }}
          getField={(node: StoneModels_stoneModels_edges_node) => {
            return `${node.code} - ${node.name}`;
          }}
        />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={commercialFinishings}
          label="Commercial Finishings"
          name="commercial_finishing"
          onChange={handleAutoCompleteChange}
          title="Commercial Finishings"
          value={globalFilters?.commercial_finishing?.eq}
          TextFieldProps={{
            label: "Commercial Finishings",
          }}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<
              CommercialFinishingFilter,
              CursorPaging,
              CommercialFinishingSort,
              CommercialFinishings
            >(
              value,
              QUERY_COMMERCIAL_FINISHINGS,
              (res) => {
                let endRes = res?.data?.commercialFinishings?.edges?.map((el) => el.node);
                setCommercialFinishings(endRes);
              },
              {
                filter: { or: [{ code: { iLike: value } }] },
                paging: { first: 40 },
              }
            );
          }}
          getField={(node: CommercialFinishings_commercialFinishings_edges_node) => {
            return `${node.code} - ${node.description.pt}`;
          }}
        />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={finishings}
          label="System Finishing"
          name="finish"
          value={globalFilters?.finish?.eq}
          onChange={handleAutoCompleteChange}
          title="System Finishing"
          TextFieldProps={{
            label: "System Finishing",
          }}
          continueToLoadResultsInComponent={false}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<FinishingFilter, CursorPaging, FinishingSort, Finishings>(
              value,
              QUERY_FINISHINGS,
              (res) => {
                let endRes = res?.data?.finishings?.edges?.map((el) => el.node);
                setFinishings(endRes);
              },
              {
                filter: {
                  or: [{ code: { iLike: value } }],
                },
                paging: {
                  first: 200,
                },
              }
            );
          }}
          getField={(node: Finishings_finishings_edges_node) => {
            return `${node.code} - ${node?.description?.pt ? node?.description?.pt : ""}`;
          }}
        />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={stoneEdges}
          label="Edges"
          name="stone_edge"
          onChange={handleAutoCompleteChange}
          title="Edges"
          value={globalFilters?.stone_edge?.eq}
          TextFieldProps={{
            label: "Edges",
          }}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<StoneEdgeFilter, CursorPaging, StoneEdgeSort, StoneEdges>(
              value,
              QUERY_STONE_EDGES,
              (res) => {
                let endRes = res?.data?.stoneEdges?.edges?.map((el) => el.node);
                setStoneEdges(endRes);
              },
              {
                filter: {
                  or: [
                    { code: { iLike: value } },
                    { name: { iLike: value } },
                    { description: { iLike: value } },
                  ],
                },
                paging: { first: 40 },
              }
            );
          }}
          getField={(node: StoneEdges_stoneEdges_edges_node) => {
            return `${node.code} - ${node.name}`;
          }}
        />
        <hr />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={packages}
          label="Bundles"
          name="packing"
          value={globalFilters?.packing?.eq}
          onChange={handleAutoCompleteChange}
          title="Bundles"
          TextFieldProps={{
            label: "Bundles",
          }}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<PackingFilter, CursorPaging, PackingSort, Packings>(
              value,
              QUERY_MANY_PACKINGS,
              (res) => {
                let endRes = res?.data?.packings?.edges?.map((el) => el.node);
                setPackages(endRes);
              },
              {
                filter: {
                  or: [{ code: { iLike: value } }, { packing: { iLike: value } }],
                },
                paging: { first: 40 },
              }
            );
          }}
          getField={(node: Packings_packings_edges_node) => {
            return node.packing;
          }}
        />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={projects}
          label="Projects"
          name="project"
          onChange={handleAutoCompleteChange}
          title="Projects"
          value={globalFilters?.project?.eq}
          TextFieldProps={{
            label: "Projects",
          }}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<ProjectFilter, CursorPaging, ProjectSort, Projects>(
              value,
              QUERY_MANY_PROJECTS,
              (res) => {
                let endRes = res?.data?.projects?.edges?.map((el) => el.node);
                setProjects(endRes);
              },
              {
                filter: {
                  or: [
                    { code: { iLike: value } },
                    { description: { iLike: value } },
                    { observation: { iLike: value } },
                    { project: { iLike: value } },
                  ],
                },
                paging: {
                  first: 30,
                },
              }
            );
          }}
          getField={(node: Projects_projects_edges_node) => {
            return node.project;
          }}
        />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={jobs}
          label="Jobs"
          name="job"
          onChange={handleAutoCompleteChange}
          title="Jobs"
          value={globalFilters?.job?.eq}
          TextFieldProps={{
            label: "Jobs",
          }}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<JobFilter, CursorPaging, JobSort, Jobs>(
              value,
              QUERY_JOBS,
              (res) => {
                let endRes = res?.data?.jobs?.edges?.map((el) => el.node);
                setJobs(endRes);
              },
              {
                filter: {
                  or: [{ code: { iLike: value } }],
                },
                paging: {
                  first: 30,
                },
              }
            );
          }}
          getField={(node: Jobs_jobs_edges_node) => {
            return `${node.code}`;
          }}
        />
        <AutocompleteWithQuery
          AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
          items={scans}
          label="Scans"
          name="scan"
          onChange={handleAutoCompleteChange}
          title="Scans"
          value={globalFilters?.scan?.eq}
          TextFieldProps={{
            label: "Scans",
          }}
          onTextChange={async (value: string) => {
            await queryValuesForFilter<ScannerFilter, CursorPaging, ScannerSort, Scanners>(
              value,
              QUERY_SCANNERS,
              (res) => {
                let endRes = res?.data?.scanners?.edges?.map((el) => el.node);
                setScans(endRes);
              },
              {
                filter: {
                  or: [{ code: { iLike: value } }, { description: { iLike: value } }],
                },
                paging: {
                  first: 30,
                },
              }
            );
          }}
          getField={(node: Scanners_scanners_edges_node) => {
            return `${node.code} - ${node.description}`;
          }}
        />
      </>
    );
  };

  return (
    <Box ml={"-1rem"} mr={"-1rem"}>
      {listOfSelectedSlabs && listOfSelectedSlabs.length > 0 && (
        <SlabSelectionMenuTab
          handleCatalog={handleCatalog}
          handleEditImage={handleEditImage}
          isFixed={isFixed}
          lisSlab={listOfSelectedSlabs}
          toggleIsFixed={toggleIsFixed}
          onClear={onClearSelection}
          selectAllSlabsInPage={selectAllSlabsInPage}
        />
      )}
      {/* Filters & Slabs */}
      <PaginationContainerStructure
        isOpen={isSideOpen}
        toggle={() => setIsSideOpen(!isSideOpen)}
        top={
          <BazarCard
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {GenerateFilterChips()}
            <FlexBox>
              <Button
                value="check"
                color="inherit"
                contextMenu={"Sync Results"}
                onClick={() => {
                  getSlabsV2(globalFilters, slabsPerPage, pageNumber, true);
                }}
                sx={{ mr: 2, borderWidth: "0px", "&:focus": { border: "none" } }}
              >
                <Typography>Sync</Typography>
                <Sync fontSize="large" color="action" titleAccess="Sync Slabs" />
              </Button>
              <Button
                value="check"
                color="inherit"
                contextMenu={"Open filter"}
                onClick={() => {
                  setIsSideOpen(!isSideOpen);
                }}
                sx={{ mr: 2, borderWidth: "0px", "&:focus": { border: "none" } }}
              >
                {!!isSideOpen ? (
                  <Typography>Close Filter</Typography>
                ) : (
                  <Typography>
                    Filter
                    {!!filtersUIValue ? `  (${Object.keys(filtersUIValue).length})` : ""}
                  </Typography>
                )}
                <TuneIcon fontSize="large" color="action" titleAccess="Open Filter" />
              </Button>
            </FlexBox>
          </BazarCard>
        }
        side={
          <>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                onClick={handleClearFilter}
                fullWidth
                startIcon={<ClearAll fontSize="large" />}
              >
                Clear Filters {!!filtersUIValue ? `  (${Object.keys(filtersUIValue).length})` : ""}
              </Button>
            </Box>
            {FilterComponent()}
          </>
        }
        sideMobile={<>{FilterComponent()}</>}
        sideMobileActions={
          <Box bgcolor={theme.palette.common.white} width={"100%"} p={2}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={6}>
                <Fab
                  sx={{
                    p: 2,
                    zIndex: 30,
                    width: "100%",
                    borderRadius: 5,
                  }}
                  color="primary"
                  onClick={handleClearFilter}
                  disabled={!Object.keys(filtersUIValue)?.length}
                >
                  Clear {!!filtersUIValue ? `  (${Object.keys(filtersUIValue).length})` : ""}
                </Fab>
              </Grid>
              <Grid item sm={6} xs={6}>
                <Fab
                  sx={{
                    p: 2,
                    zIndex: 30,
                    width: "100%",
                    borderRadius: 5,
                  }}
                  onClick={() => setIsSideOpen(false)}
                  color="secondary"
                >
                  Apply
                </Fab>
              </Grid>
            </Grid>
          </Box>
        }
        resultsLoading={isLoadingSlabs || !!slabsV2?.loading}
        childrenPagination={
          <Box
            className={classes.root}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {!!slabsV2?.data?.slabs?.totalCount &&
              Math.round(slabsV2?.data?.slabs?.totalCount / slabsPerPage) > 1 && (
                <FlexBox sx={{ alignItems: "center" }}>
                  <FlexBox mr={2}>
                    <Typography sx={{ mr: 1, mt: 0.8 }}>Results per page:</Typography>
                    <Select
                      labelId="results-per-page-select-label"
                      id="results-per-page-select"
                      value={slabsPerPage}
                      onChange={(value) => {
                        setSlabsPerPage(Number(value.target.value));
                        setPageNumber(0);
                      }}
                      variant="standard"
                    >
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={18}>18</MenuItem>
                    </Select>
                  </FlexBox>
                  <Pagination
                    count={Math.round(slabsV2?.data?.slabs?.totalCount / slabsPerPage)}
                    page={pageNumber >= 0 ? pageNumber + 1 : pageNumber + 2}
                    onChange={changePage}
                    variant="outlined"
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                </FlexBox>
              )}
            {!!slabsV2?.data?.slabs.totalCount &&
              Math.round(slabsV2?.data?.slabs?.totalCount / slabsPerPage) > 1 && (
                <Box style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <Button
                    className="btncatalog"
                    style={{
                      fontSize: "15px",
                      width: "100px",
                      marginRight: "10px",
                    }}
                    variant="contained"
                    onClick={() => changePage(null, goToPageNumber)}
                  >
                    Go To
                  </Button>
                  <TextField
                    variant="standard"
                    value={goToPageNumber}
                    type={"number"}
                    inputProps={{
                      min: 1,
                      max: slabsV2?.data?.slabs.totalCount,
                    }}
                    onChange={(e: any) => {
                      setGoToPageNumber(e.target.value);
                    }}
                  />
                </Box>
              )}
          </Box>
        }
      >
        <>
          {slabsV2 &&
          !slabsV2?.loading &&
          !slabsV2?.error &&
          slabsV2?.data &&
          slabsV2?.data.slabs.nodes.length > 0 ? (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={isSideOpen ? { sm: 1, md: 3 } : { sm: 1, md: 3 }}
              columns={
                isSideOpen
                  ? { xs: 1, sm: 2, md: 2, lg: 3, xl: 4 }
                  : { xs: 1, sm: 2, md: 4, lg: 5, xl: 6 }
              }
              alignItems="center"
            >
              {slabsV2?.data &&
                slabsV2?.data?.slabs &&
                slabsV2?.data?.slabs?.nodes.map((stn, index) => {
                  let isChecked: boolean =
                    listOfSelectedSlabs &&
                    listOfSelectedSlabs.length > 0 &&
                    !!listOfSelectedSlabs.find((el) => el.id === stn.id);
                  return (
                    <Grid item lg={1} md={1} sm={1} key={index}>
                      <Stone
                        key={stn.id}
                        stone={stn}
                        isChecked={isChecked}
                        listOfChecked={listOfSelectedSlabs}
                        onChange={(stone: any, checked: any) =>
                          selectImageChanged({
                            stone,
                            checked,
                          })
                        }
                      />
                    </Grid>
                  );
                })}
            </Grid>
          ) : (
            slabsV2 &&
            !slabsV2?.loading && (
              <Box sx={{ alignItems: "center" }}>
                <h3
                  style={{
                    textAlign: "center",
                    verticalAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  No Results
                </h3>
              </Box>
            )
          )}
        </>
      </PaginationContainerStructure>
      {listOfSelectedSlabs && listOfSelectedSlabs.length > 0 && openDetailsDialog && (
        <CatalogDialogForm
          isToCatalog={isToCatalog}
          setIsToCatalog={setIsToCatalog}
          onCancel={() => {
            setToCancelCataloguing(false);
            toggleDialog();
          }}
          onSubmit={() => {
            setListOfSelectedSlabs([]);
            setIsSubmit(true);
            setIsToCatalog(true);
            setToCancelCataloguing(false);
            toggleDialog();
          }}
          openDetailsDialog={openDetailsDialog}
          toggleDialog={toggleDialog}
          stones={listOfSelectedSlabs}
          isToCancelCataloguing={isToCancelCataloguing}
        />
      )}
    </Box>
  );
}
