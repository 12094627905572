import { useMutation, useQuery } from "@apollo/react-hooks";
import { useState } from "react";
import { useParams } from "react-router-dom";
import EditEntityForm from "../../../components/Form/EditEntityForm";
import {
  StoneModel,
  StoneModelUpdate,
  StoneModelVariables,
  UpdateOneStoneModel,
  UpdateOneStoneModelVariables,
} from "../../../data/graphQLModel";
import {
  MUTATION_UPDATE_ONE_STONE_MODEL,
  QUERY_ONE_STONE_MODEL,
} from "../../../data/graphQLQueries";
import { decodeBase64 } from "../../../utils/utils";

const requiredFields: (keyof StoneModelUpdate)[] = ["description", "name"];

export default function EditStoneModel() {
  const params = useParams<{ stonemodel: string }>();

  const [form, setForm] = useState<StoneModelUpdate>({
    name: "",
    description: "",
  });

  const { data, loading, error } = useQuery<StoneModel, StoneModelVariables>(
    QUERY_ONE_STONE_MODEL,
    {
      variables: {
        id: !!params.stonemodel ? decodeBase64(params.stonemodel) : "",
      },
      skip: !!!params.stonemodel || params.stonemodel === "",
      onCompleted(data) {
        if (!!data) {
          setForm({
            name: data.stoneModel?.name,
            description: data.stoneModel?.description,
          });
        }
      },
    }
  );

  const [mutationUpdateOneStoneModel] = useMutation<
    UpdateOneStoneModel,
    UpdateOneStoneModelVariables
  >(MUTATION_UPDATE_ONE_STONE_MODEL);

  const onSubmit = async (values: StoneModelUpdate) => {
    await mutationUpdateOneStoneModel({
      variables: {
        input: {
          id: data?.stoneModel?.id as string,
          update: values,
        },
      },
    });
  };

  return (
    <EditEntityForm
      mutation={onSubmit}
      entityForm={form}
      currentData={data?.stoneModel ? { code: data?.stoneModel?.code } : { code: "" }}
      entityName={"Stone Edge"}
      path={"/customerstables/stonemodel"}
      requiredFields={requiredFields}
      isLoadingRecord={loading}
    />
  );
}
