import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { GrDocumentTxt } from "react-icons/gr";
import { HiDocument } from "react-icons/hi";

const FileIconSwitch = (file: File | string) => {
  let extension = file instanceof File ? file.name.split(".").pop() : file.split(".").pop();
  // get .pdf, .xlsx, etc..
  switch (extension) {
    case "pdf":
      return <BsFillFileEarmarkPdfFill size={75} />;
    case "xlsx":
      return <RiFileExcel2Fill size={75} />;
    case "txt":
      return <GrDocumentTxt size={75} />;
    default:
      return <HiDocument size={75} />;
  }
};

export default FileIconSwitch;
