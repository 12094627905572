import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import swalert from "sweetalert";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Box, Card, CardContent, Grid, IconButton, Typography } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import "../../layout/Content/index";
import { MuiThemeProps } from "../../theme/theme";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  BlockModel,
  BlockModelVariables,
  DeleteOneBlockModel,
  DeleteOneBlockModelVariables,
} from "../../data/graphQLModel";
import { MUTATION_DELETE_ONE_BLOCKMODEL, QUERY_ONE_BLOCKMODEL } from "../../data/graphQLQueries";
import toast from "react-hot-toast";
import { BlockDetailsComponent } from "../../components/Blocks/BlocksDetailsComponent";
import SlabsTable from "../../components/slabs.components/SlabsTable";
import {
  getSlabRowValuesForBlockDetails,
  slabHeadCellsForBlockDetails,
} from "../../components/Blocks/BlockDetailsSlabTableFields";
import { H2 } from "../../components/GeneralComponents/Typography";
import { GrCube } from "react-icons/gr";
import FlexBox from "../../components/GeneralComponents/FlexBox";
import BazarCard from "../../components/GeneralComponents/BazarCard";

const LOADING_TOAST = "LOADING_TOAST";
const DELETING_TOAST = "DELETING_TOAST";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  first: {
    width: "90%",
    marginBottom: "3ch",
  },
  second: {
    width: "100%",
    marginBottom: "3ch",
  },
  margin: {
    marginBottom: "3ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
  headerMatType: {
    fontSize: 20,
    fontWeight: "lighter",
    marginRight: 10,
  },
  headerVariety: {
    fontWeight: "bold",
    fontSize: 20,
  },
  descriptionDefault: {
    color: theme.palette.grey[600],
    fontSize: 14,
    marginBottom: "3px",
  },
  aboutHeader: {
    fontSize: 18,
  },
  aboutText: {
    color: theme.palette.grey[600],
    fontSize: 14,
  },
}));

export default function DetailsBlock() {
  const classes = useStyles();
  const history = useHistory();

  let { block } = useParams() as { block: string };

  const { loading, error, data, refetch } = useQuery<BlockModel, BlockModelVariables>(
    QUERY_ONE_BLOCKMODEL,
    { variables: { id: atob(block) } }
  );

  // model vars
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const toggleModel = useCallback(() => {
    setIsModelOpen((isModelOpen) => !isModelOpen);
  }, []);

  useEffect(() => {
    if (loading) {
      toast.loading("Loading...", { id: LOADING_TOAST });
    } else {
      toast.dismiss(LOADING_TOAST);
    }
  }, [loading]);

  // Delete block function
  const [mutationDeleteBlock] = useMutation<DeleteOneBlockModel, DeleteOneBlockModelVariables>(
    MUTATION_DELETE_ONE_BLOCKMODEL
  );

  if (error) {
    swalert("Error", error.toString(), "error");
    return <></>;
  }

  function handleGoBack(event: any) {
    event.preventDefault();
    // To prevent users to return to tables instead of gallery
    history.goBack();
  }

  /**
   * Delete Block Function
   * @param id ID of block to delete
   */
  const deleteOne = async (id: string | undefined) => {
    if (!!id) {
      swalert({
        title: "DELETE",
        text: "Are you sure you want to delete this Block?",
        icon: "warning",
        //@ts-ignore
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete: boolean) => {
        if (willDelete) {
          toast.loading("Deleting Block", { id: DELETING_TOAST });
          await mutationDeleteBlock({
            variables: {
              input: {
                id: id,
              },
            },
          })
            .then((res) => {
              toast.dismiss(DELETING_TOAST);
              if (!!res?.errors) {
                swalert("Error", res?.errors?.map((el) => el.message)?.join("; "), "error");
              } else {
                swalert("Success.", "Block Deleted", "success");
                history.push("/slabsprocess/blocks");
              }
            })
            .catch((err) => {
              toast.dismiss(DELETING_TOAST);
              swalert("Error", err?.toString(), "error");
            });
        }
      });
    } else {
      swalert("Error", "Encountered an error when attempting to delete Block", "error");
    }
  };

  return (
    <div>
      {!loading && data && data.blockModel && (
        <>
          <Box style={{ marginBottom: "10px" }}>
            <Card>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl variant="outlined">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      type="submit"
                      disabled={history?.length <= 1}
                      onClick={handleGoBack}
                      color="primary"
                      sx={{ mr: 1 }}
                    >
                      <ArrowBackIosIcon fontSize="large" />
                    </IconButton>
                    <H2>
                      {!!data?.blockModel
                        ? !!data?.blockModel?.tenantCode
                          ? data?.blockModel?.tenantCode
                          : data?.blockModel?.wescanCode
                        : ""}
                    </H2>
                  </Box>
                </FormControl>

                <Button
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  size="large"
                  color="error"
                  variant="contained"
                  style={{ marginBottom: "-10px" }}
                  startIcon={<DeleteOutline />}
                  onClick={() => deleteOne(data.blockModel?.id)}
                >
                  Delete
                </Button>
              </CardContent>
            </Card>
          </Box>
          <hr />
          <Card>
            <CardContent>
              <form className={classes.form}>
                {data && data.blockModel && (
                  <>
                    {data?.blockModel &&
                      !!data.blockModel?.slabs?.totalCount &&
                      data.blockModel?.slabs?.totalCount > 0 && (
                        <>
                          <SlabsTable
                            parentProps={{ sx: { display: isModelOpen ? "none" : "block" } }}
                            showHeader={false}
                            filter={{ block: { eq: data.blockModel.id } }}
                            tableProps={{
                              headCells: slabHeadCellsForBlockDetails,
                              getValues: getSlabRowValuesForBlockDetails,
                              disableSelection: true,
                              paperProps: {
                                style: {
                                  height: "100%",
                                },
                              },
                            }}
                          />
                          <hr />
                        </>
                      )}
                    <BazarCard sx={{ p: 2, my: 2 }} elevation={1}>
                      <FlexBox sx={{ flexDirection: "row" }}>
                        <GrCube size={"2%"} />
                        <H2 ml={2}>Block Details</H2>
                      </FlexBox>
                    </BazarCard>
                    <BlockDetailsComponent
                      block={data.blockModel}
                      toggleModel={toggleModel}
                      isModelOpen={isModelOpen}
                    />
                    <hr />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Grid style={{ flex: 1 }}>
                        <Typography
                          style={{
                            marginTop: "10px",
                            marginBottom: "5px",
                          }}
                          className={classes.aboutHeader}
                        >
                          ABOUT{" "}
                          {data.blockModel?.commercial_variety
                            ? data.blockModel?.commercial_variety.name
                            : ""}
                        </Typography>
                        <Typography
                          style={{
                            marginBottom: "10px",
                          }}
                          className={classes.aboutText}
                        >
                          {data.blockModel?.commercial_variety?.description
                            ? data.blockModel?.commercial_variety.description
                            : "No Description"}
                        </Typography>
                      </Grid>
                    </Box>
                  </>
                )}
              </form>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
}
