import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { Box, Card, CardContent, Container } from "@material-ui/core";
import swalert from "sweetalert";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CommercialFinishing,
  CommercialFinishingVariables,
  UpdateOneCommercialFinishing,
  UpdateOneCommercialFinishingVariables,
} from "../../../data/graphQLModel";
import {
  MUTATION_UPDATE_ONE_COMMERCIAL_FINISHING,
  QUERY_ONE_COMMERCIAL_FINISHING,
} from "../../../data/graphQLQueries";
import { decodeBase64 } from "../../../utils/utils";
import { MuiThemeProps } from "../../../theme/theme";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "80%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function EditCommercialFinishings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { commfin } = useParams() as { commfin: string };

  const [form, setForm] = useState({ code: "", description: "", observation: "", id: "" });

  const [mutationEditOneCommercialFinishing] = useMutation<
    UpdateOneCommercialFinishing,
    UpdateOneCommercialFinishingVariables
  >(MUTATION_UPDATE_ONE_COMMERCIAL_FINISHING);

  useQuery<CommercialFinishing, CommercialFinishingVariables>(QUERY_ONE_COMMERCIAL_FINISHING, {
    variables: {
      id: decodeBase64(commfin),
    },
    skip: !!!commfin || (typeof commfin === "string" && commfin?.trim() === ""),
    onCompleted(data) {
      if (!!data.commercialFinishing) {
        setForm({
          code: data.commercialFinishing.code,
          id: data.commercialFinishing.id,
          observation: data.commercialFinishing?.observation?.pt as string,
          description: data.commercialFinishing.description?.pt as string,
        });
      }
    },
  });

  function changeform(e: any) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  async function submitForm(e: any) {
    e.preventDefault();

    if (
      form.code === "" ||
      form.code.length < 2 ||
      form.description === "" ||
      form.description.length < 5 ||
      form.observation === ""
    ) {
      swalert("Information!", "Missing information!", "warning");
      return;
    }

    // dispatch(loadingComponent());

    try {
      var res = await mutationEditOneCommercialFinishing({
        variables: {
          input: {
            id: form.id,
            update: {
              description: {
                pt: form.description,
              },
              observation: {
                pt: form.observation,
              },
            },
          },
        },
      });
      if (res) swalert("Success.", "Variety created with success", "success");
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert(
        "Failed.",
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Erro",
        "error"
      );
      return;
    }
    // dispatch(notLoadingComponent());
    history.push("/customerstables/commfinishings");
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/customerstables/commfinishings");
  }
  // console.log(form)

  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>Edit Comm. Finishing</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm} style={{ width: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <FormControl fullWidth className={classes.marginCode} variant="outlined">
                    <TextField
                      disabled
                      id="code"
                      label="Code"
                      name="code"
                      placeholder="Code"
                      onChange={changeform}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={form.code}
                    />
                  </FormControl>
                  <div>
                    <FormControl fullWidth className={classes.marginCode} variant="outlined">
                      <TextField
                        id="description"
                        label="Description"
                        name="description"
                        onChange={changeform}
                        placeholder="Description"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={form.description}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth className={classes.marginCode} variant="outlined">
                      <TextField
                        id="observation"
                        label="Observation"
                        name="observation"
                        onChange={changeform}
                        placeholder="Observation"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={form.observation}
                      />
                    </FormControl>
                  </div>
                </Box>
              </Box>
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      disabled={
                        !!!form.code ||
                        form.code === "" ||
                        form.code.length < 2 ||
                        !!!form.description ||
                        form.description === "" ||
                        !!!form.observation ||
                        form.observation === ""
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Edit
                      <Save style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
