import { useState } from "react";
import AutocompleteWithQuery from "../../components/Form/AutocompleteWithQuery";
import {
  CommercialVarieties,
  CommercialVarietiesVariables,
  CommercialVarieties_commercialVarieties_edges_node,
  CommercialVarietySort,
  CursorPaging,
} from "../../data/graphQLModel";
import { QUERY_COMMERCIAL_VARIETIES } from "../../data/graphQLQueries";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import { EntityAutocompleteProps } from "./types";

/**
 * ## Reusable Commercial Variety Autocomplete Component
 *
 * ### Default Autocomplete property values:
 *
 * #### name: commercial_variety
 * #### label: Commercial Variety
 * #### continueToLoadResultsInComponent: false
 * #### shouldFieldBeLocked: false
 *
 */
const CommercialVarietyAutocomplete = ({
  errors,
  continueToLoadResultsInComponent = true,
  shouldFieldBeLocked = false,
  helperText,
  value,
  onChange,
  ...props
}: EntityAutocompleteProps) => {
  const [commercialVarieties, setCommercialVarieties] = useState<
    CommercialVarieties_commercialVarieties_edges_node[]
  >([]);

  return (
    <AutocompleteWithQuery
      onChange={onChange}
      value={value}
      items={commercialVarieties}
      label="Commercial Variety"
      name="commercial_variety"
      title="Commercial Variety"
      TextFieldProps={{
        error: !!errors,
        helperText: helperText,
        label: "Commercial Variety",
        disabled: shouldFieldBeLocked,
      }}
      onTextChange={async (value: string) => {
        await queryValuesForFilter<
          CommercialVarietiesVariables["filter"],
          CursorPaging,
          CommercialVarietySort,
          CommercialVarieties
        >(
          value,
          QUERY_COMMERCIAL_VARIETIES,
          (res) => {
            let endRes = res?.data?.commercialVarieties?.edges?.map((el) => el.node);
            setCommercialVarieties(endRes);
          },
          {
            filter: {
              or: [{ code: { iLike: value } }, { name: { iLike: value } }],
            },
            paging: { first: 25 },
          }
        );
      }}
      getField={(node: CommercialVarieties_commercialVarieties_edges_node) => {
        return `${node.code} - ${node.name}`;
      }}
      {...props}
      AutocompleteProps={{
        disabled: shouldFieldBeLocked,
        ...props?.AutocompleteProps,
      }}
    />
  );
};

export default CommercialVarietyAutocomplete;
