import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { signOut } from "../../actions/accountActions";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    alignSelf: "center",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    alignSelf: "center",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    alignSelf: "center",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function Account() {
  const account = useSelector((state) => state.account);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticate = !!account.user;


  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleRegister = () => {
    setIsOpen(false);
    history.push('/register');
  };

  const handleLogin = () => {
    setIsOpen(false);
    history.push('/login');
  };

  const handleSignOut = () => {
    handleClose();
    dispatch(signOut());
    history.push('/login')
  };

  const handleAccount = () => {
    handleClose();
    history.push('/account')
  };

  return (
    <>
      <Avatar
        ref={ref}
        onClick={handleOpen}
        src={account.user && account.user.avatar}
        className={classes.large} />
      {
        isAuthenticate ?
          <Menu
            anchorEl={ref.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            open={isOpen}
            onClose={handleClose}
            getContentAnchorEl={null}
          >
            <MenuItem onClick={handleAccount}>Profile</MenuItem>
            {/* <MenuItem>Favorite</MenuItem> */}
            <MenuItem onClick={handleSignOut}>LogOut</MenuItem>
          </Menu>

          :
          <Menu
            anchorEl={ref.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            open={isOpen}
            onClose={handleClose}
            getContentAnchorEl={null}
          >
            <MenuItem onClick={handleRegister}>
              Register
            </MenuItem>
            <MenuItem onClick={handleLogin}>Login</MenuItem>
          </Menu>
      }

      {isAuthenticate && <div onClick={handleOpen} style={{ alignSelf: "center", marginLeft: 3, marginRight: 3 }}>  </div>}
    </>
  )
}

export default Account;