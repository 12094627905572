import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';

const AccountProfile = (props) => (
  <Card {...props}>
    <CardContent style={{marginBottom:"7px"}}>
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          marginBottom:"15px",
          marginTop:"15px"
        }}
      >
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {props.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="h2"
        >
          {props.valor}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <Button
        color="primary"
        fullWidth
        disabled
        variant="text"
      >
          
      </Button>
    </CardActions>
  </Card>
);

export default AccountProfile;
