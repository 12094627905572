/*eslint-disable */
import { Card, CardActionArea, Typography, CardContent, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  SharedPackages_sharedPackages_edges_node_slabs_edges_node,
  SharedPackages_sharedPackages_edges_node,
} from "../../data/graphQLModel";
import { getSlabImageSafe } from "../../utils/utils";
import "./styles.css";

const getVariety = (slab: SharedPackages_sharedPackages_edges_node_slabs_edges_node) => {
  return slab?.commercial_variety?.name
    ? slab?.commercial_variety.name
    : slab?.variety?.normativedesig
    ? slab.variety.normativedesig
    : "< >";
};

const getFinish = (slab: SharedPackages_sharedPackages_edges_node_slabs_edges_node) => {
  return slab?.commercial_finishing?.description
    ? slab?.commercial_finishing?.description?.pt
    : slab?.finish?.description?.pt
    ? slab?.finish?.description?.pt
    : "";
};

const getMaterial = (slab: SharedPackages_sharedPackages_edges_node_slabs_edges_node) => {
  return slab?.commercial_variety?.materialtype?.description?.pt
    ? slab?.commercial_variety?.materialtype?.description.pt
    : slab?.typematerial?.description?.pt
    ? slab?.typematerial?.description?.pt
    : "";
};

export default function ToSharePackageGalleryComponent({
  data_package,
  dataSlabs,
  projectKeyValue,
}: {
  data_package: SharedPackages_sharedPackages_edges_node;
  dataSlabs?: SharedPackages_sharedPackages_edges_node_slabs_edges_node[];
  projectKeyValue: string | undefined;
}) {
  const history = useHistory();
  const firstSlab = dataSlabs && dataSlabs?.length > 0 ? dataSlabs[0] : null;
  const handleGetPackageDetails = () => {
    if (!!projectKeyValue) {
      history.push({
        pathname: `/packagegallery/sharepackages/?pck=${btoa(
          data_package?.id
        )}&projectKeyValue=${projectKeyValue}`,
      });
    } else {
      history.push({
        pathname: `/packagegallery/sharepackages/${btoa(data_package?.id)}`,
      });
    }
  };
  return (
    <Card>
      <CardActionArea onClick={handleGetPackageDetails}>
        <Box>
          <Typography
            style={{
              position: "absolute",
              color: "white",
              padding: 4,
              borderRadius: 2,
              fontSize: 22,
              backgroundColor: "black",
            }}
          >
            {data_package.packing}
          </Typography>
          <img
            src={!!firstSlab ? getSlabImageSafe(firstSlab, true) || "" : "/images/imagemissing.png"}
            className="card-img-top"
            style={{ width: "100%", height: "100%" }}
            alt={data_package.code}
          />
        </Box>
        <CardContent>
          <Typography variant="button" component="div" align="left">
            <Box fontWeight="fontWeightBold" fontSize={17} marginY="5px">
              {firstSlab && getVariety(firstSlab)}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box fontWeight="fontWeightLight" fontSize={13} color={"grey.600"}>
                {"Finishing: "}
              </Box>
              <Box fontWeight="fontWeightBold" fontSize={13} marginLeft={1} color={"grey.600"}>
                {firstSlab && getFinish(firstSlab)}
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box fontWeight="fontWeightLight" fontSize={13} color={"grey.600"}>
                {"Material: "}
              </Box>
              <Box fontWeight="fontWeightBold" fontSize={13} marginLeft={1} color={"grey.600"}>
                {firstSlab && getMaterial(firstSlab)}
              </Box>
            </Box>
            <Box borderTop={1} marginY={"5px"} />
            <Box fontWeight="fontWeightLight" fontSize={13}>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{data_package?.slabs?.totalCount || 0} Slabs</Typography>
              </div>
            </Box>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
