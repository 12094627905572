import { Box, Button, Grid, IconButton, useTheme } from "@material-ui/core";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  BlockModel_blockModel,
  BlockModel_blockModel_regularImages,
  ImageFace,
} from "../../data/graphQLModel";
import { makeStyles } from "@material-ui/styles";
import { MuiThemeProps } from "../../theme/theme";
import { ImageListComponent } from "../slabs.components/ImageListComponent";
import FlexBox from "../GeneralComponents/FlexBox";
import ViewBlockModelComponent from "./ViewBlockModel";
import ReactImageMagnify from "@blacklab/react-image-magnify";
import { H3, H4, H5 } from "../GeneralComponents/Typography";
import { Crop, ThreeDRotation } from "@material-ui/icons";
import BlockFaceCropper from "./BlockFaceCropper";

export interface BlockDetailsComponentProps {
  block: BlockModel_blockModel;
  isModelOpen: boolean;
  toggleModel: () => void;
}

export interface ISelectedImageInfo {
  width: number;
  height: number;
  naturalWidth?: number;
  naturalHeight?: number;
}

const useStyles = makeStyles(({ palette, ...theme }: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "100%",
  },
  first: {
    width: "90%",
    marginBottom: "3ch",
  },
  second: {
    width: "100%",
    marginBottom: "3ch",
  },
  margin: {
    marginBottom: "3ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
  headerMatType: {
    fontSize: 20,
    fontWeight: "lighter",
    marginRight: 10,
  },
  headerVariety: {
    fontWeight: "bold",
    fontSize: 20,
  },
  descriptionDefault: {
    color: palette.grey[600],
    fontSize: 14,
    marginBottom: "3px",
  },
  aboutHeader: {
    fontSize: 18,
  },
  aboutText: {
    color: palette.grey[600],
    fontSize: 14,
  },
}));

const checkIfBlockHasEveryFace = (blockImages: BlockModel_blockModel_regularImages[]): boolean => {
  let checkFound = {
    [ImageFace.BACK]: false,
    [ImageFace.FRONT]: false,
    [ImageFace.LEFT]: false,
    [ImageFace.RIGHT]: false,
    [ImageFace.TOP]: false,
  };

  blockImages.forEach((el) => {
    if (el.imageFace !== ImageFace.SINGLE) {
      checkFound[el.imageFace] = true;
    }
  });

  for (const key in checkFound) {
    if (Object.prototype.hasOwnProperty.call(checkFound, key)) {
      const element = checkFound[key as keyof typeof checkFound];
      if (!element) return false;
    }
  }

  return blockImages.length >= 5;
};

export const BlockDetailsComponent = ({
  block,
  isModelOpen,
  toggleModel,
}: BlockDetailsComponentProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const [selectedImage, setSelectedImage] = useState<number>(0);

  const imageContainerRef = useRef<any>(null);
  const [imageContainerHeight, setImageContainerHeight] = useState<number>(0);
  const [imageContainerWidth, setImageContainerWidth] = useState<number>(0);

  const [selectedImageInfo, setSelectedImageInfo] = useState<ISelectedImageInfo>({
    height: 400,
    width: 600,
  });

  const [isCroppingBlockFace, setIsCroppingBlockFace] = useState<boolean>(false);

  useEffect(() => {
    if (!!imageContainerRef?.current) {
      setImageContainerHeight(imageContainerRef?.current?.offsetHeight || 0);
      // get true width by subtracting imagelist width from parent width
      let firstChildWidth = imageContainerRef?.current?.firstChild?.offsetWidth;
      let containerWidth = imageContainerRef?.current?.offsetWidth;
      setImageContainerWidth(
        !!firstChildWidth && !!containerWidth
          ? containerWidth - firstChildWidth
          : containerWidth || 0
      );
    }
  }, [imageContainerRef?.current]);

  const calcAndSetImageSize = (natWidth: number, natHeight: number) => {
    if (!!natWidth && !!natHeight) {
      let newWidth = (natWidth * imageContainerHeight) / natHeight;
      // if width too large set new height based on possible max width
      if (newWidth > imageContainerWidth) {
        setSelectedImageInfo({
          height: (natHeight * newWidth) / natWidth,
          width: imageContainerWidth,
          naturalHeight: natHeight,
          naturalWidth: natWidth,
        });
      } else {
        setSelectedImageInfo({
          height: imageContainerHeight,
          width: newWidth,
          naturalHeight: natHeight,
          naturalWidth: natWidth,
        });
      }
    }
  };

  const onScroll = (e: any) => {
    calcAndSetImageSize(selectedImageInfo.width, selectedImageInfo.height);
  };

  return (
    <div onScroll={onScroll}>
      {block && (
        <Fragment>
          {/* MAIN CONTAINER */}
          <Grid container>
            {/* LEFT SIDE | ImageList & MAGNIFY */}
            <Grid item container md={6} xs={12} ref={imageContainerRef} height={"50vh"}>
              <Grid item xs={2}>
                <ImageListComponent
                  maxHeight={"50vh"}
                  images={
                    block?.regularImages && block?.regularImages.length > 0
                      ? block?.regularImages?.map((el) => el.original)
                      : []
                  }
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  direction="vertical"
                  applyMarginOnFirstChild={false}
                />
              </Grid>
              <Grid item xs={10}>
                {!isModelOpen && (
                  <Grid
                    container
                    className="extra-icons"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      position: "absolute",
                      zIndex: 10,
                      width: selectedImageInfo.width,
                    }}
                  >
                    {!!block?.regularImages &&
                      !block?.regularImages[selectedImage]?.original?.startsWith("data") && (
                        <Grid item>
                          <IconButton
                            onClick={() => setIsCroppingBlockFace(true)}
                            title="Crop Image"
                            disabled={isCroppingBlockFace}
                          >
                            <Crop
                              sx={{
                                cursor: "pointer",
                                ":hover": {
                                  backgroundColor: theme.palette.grey[400],
                                  borderRadius: 3,
                                },
                                height: 40,
                                width: 40,
                              }}
                              fontSize="large"
                              color={!isCroppingBlockFace ? "primary" : "disabled"}
                            />
                          </IconButton>
                        </Grid>
                      )}
                    {!!block?.regularImages && !!block?.regularImages[selectedImage]?.imageFace && (
                      <Grid item>
                        <H4
                          sx={{
                            ml: `${selectedImageInfo.width - 130}px`,
                            backgroundColor: theme.palette.background.paper,
                            p: 0.5,
                            mt: `${selectedImageInfo.height - 50}px`,
                          }}
                        >
                          {block?.regularImages?.[selectedImage]?.imageFace}
                        </H4>
                      </Grid>
                    )}
                  </Grid>
                )}
                {!!block?.regularImages && block?.regularImages.length > 0 ? (
                  !!imageContainerHeight &&
                  (!!selectedImage || selectedImage === 0) && (
                    <ReactImageMagnify
                      imageProps={{
                        src:
                          block?.regularImages && block?.regularImages[selectedImage]?.original
                            ? block?.regularImages[selectedImage]?.original
                            : "/images/imagemissing.png",
                        width: selectedImageInfo.width,
                        height: selectedImageInfo.height,
                        onLoad(e) {
                          //@ts-ignore
                          let natWidth = e?.target?.naturalWidth as number;
                          //@ts-ignore
                          let natHeight = e?.target?.naturalHeight as number;
                          calcAndSetImageSize(natWidth, natHeight);
                        },
                        style: {
                          width: selectedImageInfo.width,
                          height: selectedImageInfo.height,
                        },
                      }}
                      magnifiedImageProps={{
                        height: selectedImageInfo.height * 5,
                        width: selectedImageInfo.width * 5,
                        src:
                          block?.regularImages && block?.regularImages[selectedImage]?.original
                            ? block?.regularImages[selectedImage]?.original
                            : "/images/imagemissing.png",
                      }}
                      // Portal is the element where the magnified image appears
                      portalProps={{
                        style: {
                          zIndex: 101,
                          position: "absolute",
                          inset: "0px auto auto 0px",
                          // this calc was wrong in the lib so we had to fix it
                          // imageContainerRef.current?.children[1]?.offsetLeft is the number of pixels that the Grid containing the image is from the left end
                          // of the window, add that to the width of the image and you get the correct position of the Portal
                          transform: `translate(${
                            imageContainerRef.current?.children[1]?.offsetLeft +
                            selectedImageInfo.width
                          }px, ${imageContainerRef.current.offsetTop}px)`,
                        },
                      }}
                    />
                  )
                ) : (
                  <img
                    style={{
                      width: selectedImageInfo.width,
                      height: selectedImageInfo.height,
                    }}
                    src={
                      block?.regularImages && block?.regularImages[selectedImage]?.original
                        ? block?.regularImages[selectedImage]?.original
                        : "/images/imagemissing.png"
                    }
                  />
                )}
              </Grid>
            </Grid>
            {/* RIGHT SIDE | DESCRIPTION VALUES */}
            <Grid
              item
              md={6}
              xs={12}
              style={{
                flex: 1,
                marginLeft: "20px",
                justifyContent: "center",
              }}
            >
              <H5 className={classes.aboutHeader}>
                Dimensions: {block.extheight} | {block.extwidth}
              </H5>
              <H5 className={classes.aboutHeader}>
                Base Area: {block.baseArea ? block.baseArea : "? "}m2
              </H5>
              <H5 className={classes.aboutHeader}>
                Volume: {block.volume ? block.volume : "? "}m3
              </H5>
              <H5 className={classes.aboutHeader}>
                Stone Type:{" "}
                {!!block?.materialtype?.description?.pt ? block?.materialtype?.description?.pt : ""}
              </H5>
              <H5 className={classes.aboutHeader}>
                Stone Name:{" "}
                {block?.commercial_variety
                  ? block?.commercial_variety.name
                  : block?.variety?.normativedesig
                  ? block?.variety?.normativedesig
                  : ""}
              </H5>
              <H5 className={classes.aboutHeader}>WeScan Code: {block.wescanCode}</H5>
              <H5 className={classes.aboutHeader}>Tenant Code: {block.tenantCode}</H5>
              <FlexBox flexDirection={"column"}>
                {!!block.modelFileURL && !!block.modelFileExtension && (
                  <a href={block.modelFileURL} target="_blank">
                    Model File
                  </a>
                )}
                {!!block.materialFileUrl && block.materialFileExtension && (
                  <a href={block.materialFileUrl} target="_blank">
                    Material Settings File
                  </a>
                )}
              </FlexBox>
              {((!!block.materialFileUrl && !!block.modelFileURL && block?.regularImages) ||
                (block?.regularImages && checkIfBlockHasEveryFace(block.regularImages))) && (
                <Button
                  onClick={toggleModel}
                  variant="contained"
                  sx={{ mt: 5 }}
                  startIcon={<ThreeDRotation />}
                >
                  View Model
                </Button>
              )}
            </Grid>
          </Grid>
          {((!!block.materialFileUrl && !!block.modelFileURL && block?.regularImages) ||
            (block?.regularImages && checkIfBlockHasEveryFace(block.regularImages))) && (
            <ViewBlockModelComponent block={block} isOpen={isModelOpen} toggle={toggleModel} />
          )}
          {(!!selectedImage || selectedImage === 0) &&
            !!block?.regularImages &&
            !!block?.regularImages?.[selectedImage] && (
              <BlockFaceCropper
                image={block?.regularImages[selectedImage]}
                onCancel={() => setIsCroppingBlockFace(false)}
                open={isCroppingBlockFace}
                block={block}
                onConfirm={(file, face) => {
                  if (block?.regularImages) {
                    setIsCroppingBlockFace(false);
                    let foundCroppedImageOfFaceIndex = block?.regularImages.findIndex(
                      (el) => el.imageFace === face && el.original.startsWith("data")
                    );
                    if (foundCroppedImageOfFaceIndex === -1) {
                      block?.regularImages.push({
                        ...block?.regularImages[selectedImage],
                        original: file,
                        imageFace: face,
                      });
                    } else {
                      block.regularImages[foundCroppedImageOfFaceIndex] = {
                        ...block?.regularImages[selectedImage],
                        original: file,
                        imageFace: face,
                      };
                    }
                    setSelectedImage(block?.regularImages?.length - 1);
                  }
                }}
              />
            )}
        </Fragment>
      )}
    </div>
  );
};
