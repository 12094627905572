import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const orders = [
  {
    id: uuid(),
    ref: 'CDD1049',
    width: 20.9,
    thickness: 10.00,
    height: 30.5,
    customer: {
      name: 'Ekaterina Tankova'
    },
    slab:'https://stonifyblob1.blob.core.windows.net/wescanhome/thumbnail_46368d40-e0a3-42b3-8aee-955bc892fd08.jpg',
    createdAt: 1555016400000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1048',
    width: 25.9,
    thickness: 15.00,
    height: 30.5,
    customer: {
      name: 'Cao Yu'
    },
    slab:'https://stonifyblob1.blob.core.windows.net/wescanhome/thumbnail_46368d40-e0a3-42b3-8aee-955bc892fd08.jpg',
    createdAt: 1555016400000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1047',
    width: 20.9,
    thickness: 10.00,
    height: 30.5,
    customer: {
      name: 'Alexa Richardson'
    },
    slab:'https://stonifyblob1.blob.core.windows.net/wescanhome/thumbnail_46368d40-e0a3-42b3-8aee-955bc892fd08.jpg',
    createdAt: 1554930000000,
    status: 'refunded'
  },
  {
    id: uuid(),
    ref: 'CDD1046',
    width: 20.9,
    thickness: 12.00,
    height: 39.5,
    customer: {
      name: 'Anje Keizer'
    },
    slab:'https://stonifyblob1.blob.core.windows.net/wescanhome/thumbnail_46368d40-e0a3-42b3-8aee-955bc892fd08.jpg',
    createdAt: 1554757200000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1045',
    width: 30.9,
    thickness: 18.00,
    height: 50.5,
    customer: {
      name: 'Clarke Gillebert'
    },
    slab:'https://stonifyblob1.blob.core.windows.net/wescanhome/thumbnail_46368d40-e0a3-42b3-8aee-955bc892fd08.jpg',
    createdAt: 1554670800000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1044',
    width: 27.9,
    thickness: 7.00,
    height: 40.5,
    customer: {
      name: 'Adam Denisov'
    },
    slab:'https://stonifyblob1.blob.core.windows.net/wescanhome/thumbnail_46368d40-e0a3-42b3-8aee-955bc892fd08.jpg',
    createdAt: 1554670800000,
    status: 'delivered'
  }
];

const LatestOrders = (props) => (
  <Card {...props}>
    <CardHeader title="Latest Slabs"/>
    <Divider />
    <PerfectScrollbar>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Slab
              </TableCell>
              <TableCell>
                Code
              </TableCell>
              <TableCell>
                width
              </TableCell>
              <TableCell>
                thickness
              </TableCell>
              <TableCell>
                height
              </TableCell>
              <TableCell sortDirection="desc">
                <Tooltip enterDelay={300} title="Sort" >
                  <TableSortLabel active direction="desc">
                    Date
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow hover key={order.id} >
                <TableCell>
                  <Avatar src={order.slab} sx={{ mr: 2 }}/>
                </TableCell>
                <TableCell>
                  {order.ref}
                </TableCell>
                <TableCell>
                  {order.width}
                </TableCell>
                <TableCell>
                  {order.thickness}
                </TableCell>
                <TableCell>
                  {order.height}
                </TableCell>
                <TableCell>
                  {moment(order.createdAt).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>
                  <Chip color="primary" label={order.status} size="small" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Button
        color="primary"
        endIcon={<ArrowRightIcon />}
        size="small"
        variant="text"
      >
        Go to Slabs Table
      </Button>
    </Box>
  </Card>
);

export default LatestOrders;
