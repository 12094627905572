/* eslint-disable */
import jwt_decode from "jwt-decode";
import { loadingComponent, notLoadingComponent } from "./isLoadingActions";
import authServices from "../services/auth.Services";
import { apolloClient } from "../services_v2/apollo.graphql";
import { MUTATION_AUTH_SHARED_LINK } from "../data/graphQLQueries";
export const LOGIN_SUCESS = "@ACCOUNT/LOGIN_SUCCESS";
export const SILENT_SUCESS = "@ACCOUNT/SILENT_SUCESS";
export const SIGNOUT = "@ACCOUNT/SIGNOUT";
export const CHANGE_FILTER = "@ACCOUNT/CHANGE_FILTER";
export const TOKENV2_STATE = "@ACCOUNT/TOKENV2_STATE";
export const SHAREABLE_AUTH = "@ACCOUNT/SHAREABLE_AUTH";

const signIn = async (username, password) => {
  const { access_token: token } = await authServices.signIn(username, password);
  const user = await jwt_decode(token);
  user.token = token;
  return user;
};

const authShareable = (keyValue) => {
  return async (dispatch) => {
    await apolloClient
      .query({
        query: MUTATION_AUTH_SHARED_LINK,
        variables: {
          keyValue,
        },
      })
      .then((res) => {
        dispatch({
          type: SHAREABLE_AUTH,
          payload: {
            user: {
              token: res.data.autenticateSharedLink.temporaryToken,
              authType: "shareableLink",
            },
            token: res.data.autenticateSharedLink.temporaryToken,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const setUserData = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("accessTokenV2");
    let user = await jwt_decode(token);
    if (user) {
      user.token = token;
    }
    dispatch({
      type: SILENT_SUCESS,
      payload: {
        user,
      },
    });
  };
};

const signOut = () => {
  return (dispatch) => {
    const user = authServices.signOut();
    dispatch({
      type: SIGNOUT,
    });
  };
};

const changeStockFilter = (value) => {
  localStorage.setItem("FILTER_STOCK", value);
  return async (dispatch) => {
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        filter_stock: value,
      },
    });
  };
};

export { signIn, setUserData, signOut, changeStockFilter, authShareable };
