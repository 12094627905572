import { useMutation, useQuery } from "@apollo/react-hooks";
import { useState } from "react";
import { useParams } from "react-router-dom";
import EditEntityForm from "../../../components/Form/EditEntityForm";
import {
  StoneEdge,
  StoneEdgeUpdate,
  StoneEdgeVariables,
  UpdateOneStoneEdge,
  UpdateOneStoneEdgeVariables,
} from "../../../data/graphQLModel";
import { MUTATION_UPDATE_ONE_STONE_EDGE, QUERY_ONE_STONE_EDGE } from "../../../data/graphQLQueries";
import { decodeBase64 } from "../../../utils/utils";

const requiredFields: (keyof StoneEdgeUpdate)[] = ["description", "name"];

export default function EditStoneEdge() {
  const params = useParams<{ stoneedge: string }>();

  const [form, setForm] = useState<StoneEdgeUpdate>({
    name: "",
    description: "",
  });

  const { data, loading, error } = useQuery<StoneEdge, StoneEdgeVariables>(QUERY_ONE_STONE_EDGE, {
    variables: {
      id: !!params.stoneedge ? decodeBase64(params.stoneedge) : "",
    },
    skip: !!!params.stoneedge || params.stoneedge === "",
    onCompleted(data) {
      if (!!data) {
        setForm({
          name: data.stoneEdge?.name,
          description: data.stoneEdge?.description,
        });
      }
    },
  });

  const [mutationUpdateOneStoneEdge] = useMutation<UpdateOneStoneEdge, UpdateOneStoneEdgeVariables>(
    MUTATION_UPDATE_ONE_STONE_EDGE
  );

  const onSubmit = async (values: StoneEdgeUpdate) => {
    await mutationUpdateOneStoneEdge({
      variables: {
        input: {
          id: data?.stoneEdge?.id as string,
          update: values,
        },
      },
    });
  };

  return (
    <EditEntityForm
      mutation={onSubmit}
      entityForm={form}
      currentData={data?.stoneEdge ? { code: data?.stoneEdge?.code } : { code: "" }}
      entityName={"Stone Edge"}
      path={"/customerstables/stoneedge"}
      requiredFields={requiredFields}
      isLoadingRecord={loading}
    />
  );
}
