import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  Hidden,
  Grid,
  Chip,
  Box,
  FormControl,
  IconButton,
  TextField,
  TablePagination,
  Button,
  Typography,
} from "@material-ui/core";
import { Clear, RemoveCircle, Search, Tune } from "@material-ui/icons";
import { H3 } from "../GeneralComponents/Typography";
import {
  CursorPaging,
  PackingFilter,
  PackingsGallery,
  PackingsGalleryVariables,
  PackingSort,
  PackingSortFields,
  PackingTypeFilter,
  PackingTypes,
  PackingTypeSort,
  PackingTypes_packingTypes_edges_node,
  SortDirection,
} from "../../data/graphQLModel";
import { QUERY_MANY_PACKAGE_TYPES, QUERY_MANY_PACKINGS_GALLERY } from "../../data/graphQLQueries";
import { layoutConstant } from "../../utils/constants";
import {
  onChangePageCursor,
  copyObject,
  getEndDateFromFilter,
  getStartDateFromFilter,
  onEndDateChangeGraphQLFilters,
  onStartDateChangeGraphQLFilters,
} from "../../utils/utils";
import PaginationContainerStructure from "../../pages/Catalog/PaginationContainerStructure";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { MuiThemeProps } from "../../theme/theme";
import SelectDate from "../CatalogFilters/SelectDate";
import AutocompleteWithQuery from "../Form/AutocompleteWithQuery";
import { queryValuesForFilter } from "../../utils/ApolloClientUtils";
import BazarCard from "../GeneralComponents/BazarCard";
import FlexBox from "../GeneralComponents/FlexBox";
import BundleGalleryCard from "./BundleGalleryCard";

const DEFAULT_LIMIT = 12;
const DEFAULT_SORTING: PackingSort[] = [
  { direction: SortDirection.DESC, field: PackingSortFields.createdAt },
];
const DEFAULT_PAGING: CursorPaging = { first: DEFAULT_LIMIT };
const DEFAULT_FILTER: PackingFilter = {};

interface BundleGalleryProps {
  TopElement: () => JSX.Element;
  isSideOpen: boolean;
  toggleSide: () => void;
}

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    "& > *": {
      padding: 1,
      marginBottom: theme.spacing(4),
    },
  },
  boxTop: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
  },
  caption: {
    marginTop: "0.8rem",
  },
}));

const BundleGallery = ({ TopElement, isSideOpen, toggleSide }: BundleGalleryProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [filter, setFilter] = useState<PackingFilter>(DEFAULT_FILTER);
  const [paging, setPaging] = useState<CursorPaging>(DEFAULT_PAGING);
  const [sorting, setSorting] = useState<PackingSort[]>(DEFAULT_SORTING);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [limit, setLimit] = useState<number>(DEFAULT_LIMIT);
  // filters value of getField shown in UI
  const [filtersUIValue, setFiltersUIValue] = useState<{
    [key in keyof Partial<PackingFilter>]: string;
  }>({});

  //#region Filters
  const [textSearch, setTextSearch] = useState<string | undefined>();
  const [packingTypes, setPackingTypes] = useState<PackingTypes_packingTypes_edges_node[]>([]);
  //#endregion

  const { data, loading, error } = useQuery<PackingsGallery, PackingsGalleryVariables>(
    QUERY_MANY_PACKINGS_GALLERY,
    {
      variables: {
        filter,
        paging,
        sorting,
      },
    }
  );

  useEffect(() => {
    setPaging({ first: limit });
    setPageNumber(0);
  }, [filter]);

  const handlePageChange = (e: any, page: number) => {
    onChangePageCursor(
      e,
      page,
      pageNumber,
      !!paging.first ? paging.first : !!paging.last ? paging.last : DEFAULT_LIMIT,
      data?.packings?.pageInfo || ({} as any),
      (pageNumber: number, newPaging: CursorPaging) => {
        setPaging(newPaging);
        setPageNumber(pageNumber);
      }
    );
  };

  const handleStartDateChange = (date: any) => {
    onStartDateChangeGraphQLFilters(filter, date, (newAnd) => {
      setFilter({
        ...filter,
        and: newAnd,
      });
    });
  };

  const handleEndDateChange = (date: any) => {
    onEndDateChangeGraphQLFilters(filter, date, (newAnd) => {
      setFilter({
        ...filter,
        and: newAnd,
      });
    });
  };

  const handleSearch = (value: string | undefined) => {
    if (!!value && value.trim() !== "") {
      setFilter({
        ...filter,
        or: [
          {
            code: {
              iLike: value,
            },
          },
          {
            packing: {
              iLike: value,
            },
          },
        ],
      });
    } else {
      let newFilter = { ...filter };
      delete newFilter.or;
      setFilter(newFilter);
    }
  };

  const handleAutoCompleteChange = (e: any) => {
    let key = e.target.name as keyof PackingFilter;
    let value = e.target.value as string;
    let uiValue = e.target.fieldUiValue as string;
    if (e.target.value) {
      setFilter({ ...filter, [key]: { eq: value } });
      setFiltersUIValue({ ...filtersUIValue, [key]: uiValue });
    } else {
      const newfilter = copyObject(filter, [key]);
      setFilter(newfilter);
      const newFiltersUIValue = copyObject(filtersUIValue, [key]);
      setFiltersUIValue(newFiltersUIValue);
    }
  };

  const GenerateFilterChips = (): JSX.Element => {
    let keysToIgnore: (keyof PackingFilter)[] = ["and", "or"];
    let activeKeys = Object.keys(filter) as (keyof PackingFilter)[];

    const dataForChips: { key: keyof PackingFilter; label: string }[] = [];

    activeKeys.forEach((key) => {
      if (keysToIgnore.indexOf(key) === -1) {
        dataForChips.push({
          label: filtersUIValue[key] as string,
          key: key,
        });
      }
    });

    return (
      <>
        <Hidden only={["sm", "xs"]}>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 5,
              maxHeight: layoutConstant.headerHeight,
              marginLeft: 2,
            }}
            spacing={1}
          >
            <Grid item>
              <H3 sx={{ fontWeight: "bold" }}>Filters: </H3>
            </Grid>
            {dataForChips.map((el) => (
              <Grid item>
                <Chip
                  label={el?.label}
                  onDelete={() => {
                    let newFilters = copyObject(filter, []);
                    delete newFilters[el?.key];
                    setFilter(newFilters);
                    let newFiltersUIValue = copyObject(filtersUIValue, []);
                    delete newFiltersUIValue[el?.key];
                    setFiltersUIValue(newFiltersUIValue);
                  }}
                  deleteIcon={<RemoveCircle />}
                />
              </Grid>
            ))}
            {(!!!dataForChips || dataForChips.length === 0) && (
              <Grid item>
                <H3 sx={{ fontWeight: "300" }}>Empty</H3>
              </Grid>
            )}
          </Grid>
        </Hidden>
        <Hidden only={["lg", "md", "xl"]}>
          <Grid container></Grid>
        </Hidden>
      </>
    );
  };

  return (
    <PaginationContainerStructure
      top={
        <BazarCard
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FlexBox sx={{ flexDirection: "column" }}>
            <TopElement />
            {GenerateFilterChips()}
          </FlexBox>
          <Button
            value="check"
            color="inherit"
            contextMenu={"Open filter"}
            onClick={toggleSide}
            sx={{ mr: 2, borderWidth: "0px", "&:focus": { border: "none" } }}
          >
            {!!isSideOpen ? (
              <Typography>Close Filter</Typography>
            ) : (
              <Typography>
                Filter
                {!!filtersUIValue ? `  (${Object.keys(filtersUIValue).length})` : ""}
              </Typography>
            )}
            <Tune fontSize="large" color="action" titleAccess="Open Filter" />
          </Button>
        </BazarCard>
      }
      isOpen={isSideOpen}
      toggle={toggleSide}
      side={
        <>
          <Box className={classes.boxTop}>
            <SelectDate
              label={"Start Date"}
              selectedDate={getStartDateFromFilter(filter)}
              handleDateChange={handleStartDateChange}
              maxDate={getEndDateFromFilter(filter)}
            />
            <SelectDate
              label={"End Date"}
              selectedDate={getEndDateFromFilter(filter)}
              handleDateChange={handleEndDateChange}
              minDate={getStartDateFromFilter(filter)}
            />
          </Box>
          <hr />
          <FormControl fullWidth variant="outlined">
            <TextField
              id="textsearch"
              label="Search"
              name="textsearch"
              placeholder="Search Block"
              value={textSearch || ""}
              onChange={(e) => {
                setTextSearch(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch(textSearch);
                }
              }}
              InputProps={{
                startAdornment: (
                  <IconButton
                    onClick={() => {
                      handleSearch(textSearch);
                    }}
                  >
                    <Search fontSize="medium" />
                  </IconButton>
                ),
                endAdornment: !!textSearch && textSearch.trim() !== "" && (
                  <IconButton
                    onClick={() => {
                      setTextSearch(undefined);
                      const newfilter = copyObject(filter, ["or"]);
                      setFilter(newfilter);
                    }}
                  >
                    <Clear fontSize="medium" />
                  </IconButton>
                ),
              }}
            />
          </FormControl>
          <hr />
          <AutocompleteWithQuery
            AutocompleteProps={{ className: "oldFormControl", sx: { mb: 1 } }}
            items={packingTypes}
            label="Packing Type"
            name="packingtype"
            onChange={handleAutoCompleteChange}
            title="Packing Type"
            value={filter?.packingtype?.eq}
            TextFieldProps={{
              label: "Packing Type",
            }}
            continueToLoadResultsInComponent={false}
            onTextChange={async (value: string) => {
              await queryValuesForFilter<
                PackingTypeFilter,
                CursorPaging,
                PackingTypeSort,
                PackingTypes
              >(
                value,
                QUERY_MANY_PACKAGE_TYPES,
                (res) => {
                  let endRes = res?.data?.packingTypes?.edges?.map((el) => el.node);
                  setPackingTypes(endRes);
                },
                {
                  filter: { or: [{ code: { iLike: value } }] },
                  paging: { first: 40 },
                }
              );
            }}
            getField={(node: PackingTypes_packingTypes_edges_node) => {
              return `${node.code} - ${node.observation}`;
            }}
          />
        </>
      }
      resultsLoading={loading}
      childrenPagination={
        <div
          className={classes.root}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              marginBottom: "15px",
              display: "flex",
              top: 0,
            }}
          >
            <TablePagination
              classes={{
                selectLabel: classes.caption,
                displayedRows: classes.caption,
                root: classes.root,
              }}
              count={data?.packings?.totalCount || 0}
              rowsPerPage={limit}
              page={pageNumber}
              onPageChange={handlePageChange}
              onRowsPerPageChange={(event) => {
                let newLimit = parseInt(event.target.value, 10);
                setLimit(newLimit);
                setPaging({ first: newLimit });
                setPageNumber(0);
              }}
              rowsPerPageOptions={[9, 12, 15, 18]}
              labelRowsPerPage="Results per page"
            />
          </Box>
        </div>
      }
    >
      <>
        {!loading && !!!error && data && data?.packings?.edges?.length > 0 ? (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={isSideOpen ? { sm: 1, md: 3 } : { sm: 1, md: 3 }}
            columns={
              isSideOpen
                ? { xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }
                : { xs: 1, sm: 2, md: 4, lg: 5, xl: 6 }
            }
            alignItems="center"
          >
            {data &&
              data?.packings &&
              data?.packings?.edges?.map((pck, index) => {
                return (
                  <Grid item lg={1} md={1} sm={1} key={index}>
                    <BundleGalleryCard key={pck.node.id} bundle={pck.node} />
                  </Grid>
                );
              })}
          </Grid>
        ) : (
          !loading && (
            <Box sx={{ alignItems: "center" }}>
              <h3
                style={{
                  textAlign: "center",
                  verticalAlign: "center",
                  fontWeight: "bold",
                }}
              >
                No Results
              </h3>
            </Box>
          )
        )}
      </>
    </PaginationContainerStructure>
  );
};

export default BundleGallery;
