import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  Save,
  Cancel,
  Delete,
  RemoveRedEye,
  Info,
  Remove,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { FormikHelpers, useFormik } from "formik";
import AutocompleteFilterJustText from "../../../../components/Filters/AutoCompleteFilterJustText";
import { H3, H4, H5, Small } from "../../../../components/GeneralComponents/Typography";
import {
  DescState,
  JobInput,
  JobUpdate,
  Job_job,
  BlockModel,
  BlockModelFilter,
  BlockModelSort,
  BlockModelsToFilter,
  BlockModels_blockModels_edges_node,
  BlockModelVariables,
  CursorPaging,
  BlockModelsToFilter_blockModels_edges_node,
  SlabsVariables,
  Slabs,
  SortDirection,
  SlabSortFields,
  JobNumberingOrder,
  JobsShort,
  JobsShortVariables,
  JobsForScannerConsole,
  JobsForScannerConsoleVariables,
  JobTypes,
  JobTypesVariables,
} from "../../../../data/graphQLModel";
import * as yup from "yup";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import {
  QUERY_BLOCKS_TO_FILTER,
  QUERY_JOBS_FOR_SCANNER_CONSOLE,
  QUERY_JOBS_SHORT,
  QUERY_JOB_TYPES,
  QUERY_ONE_BLOCKMODEL,
  QUERY_SLABS,
} from "../../../../data/graphQLQueries";
import { useEffect, useState } from "react";
import FullScreenActivityIndicator from "../../../../components/GeneralComponents/FullScreenActivityIndicator";
import CategoryAutocomplete from "../../../Autocompletes/CategoryAutocomplete";
import MaterialTypeAutocomplete from "../../../Autocompletes/MaterialTypeAutocomplete";
import CommercialVarietyAutocomplete from "../../../Autocompletes/CommercialVarietyAutocomplete";
import StoneModelAutocomplete from "../../../Autocompletes/StoneModelAutocomplete";
import CommercialFinishingAutocomplete from "../../../Autocompletes/CommercialFinishingAutocomplete";
import ScannerAutocomplete from "../../../Autocompletes/ScannerAutocomplete";
import StoneEdgeAutocomplete from "../../../Autocompletes/StoneEdgeAutocomplete";
import ProjectAutocomplete from "../../../Autocompletes/ProjectAutocomplete";
import PackageAutocomplete from "../../../Autocompletes/PackageAutocomplete";
import FinishingAutocomplete from "../../../Autocompletes/FinishingAutocomplete";
import VarietyAutocomplete from "../../../Autocompletes/VarietyAutocomplete";
import AutocompleteWithQuery from "../../../../components/Form/AutocompleteWithQuery";
import { queryValuesForFilter } from "../../../../utils/ApolloClientUtils";
import { copyObject, getNumberFromEnd } from "../../../../utils/utils";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/styles";
import theme, { MuiThemeProps } from "../../../../theme/theme";
import FlexBox from "../../../../components/GeneralComponents/FlexBox";
import { Box } from "@material-ui/system";

const useHelperTextStyles = makeStyles((theme: MuiThemeProps) => ({
  root: {
    color: theme.palette.warning.main,
  },
}));

export type JobInputWithEndNumber = JobInput & { endNumber?: number | null | undefined };

interface JobFormProps {
  initialValues: JobInputWithEndNumber;
  validationSchema: (data?: Job_job, invalidJobCodes?: string[]) => yup.ObjectSchema<any>;
  submitForm: ((input: JobInput) => Promise<void>) | ((input: JobUpdate) => Promise<void>);
  handleCancel: () => void;
  isUpdate?: boolean;
  data?: Job_job;
  blockUpdate?: boolean;
  isDelete?: boolean;
  showSimplified?: boolean;
}

type JobFormInfoProps = { warning: string; disable?: boolean };
type JobFormInfoKeysType = {
  [key in keyof Partial<JobInputWithEndNumber>]: JobFormInfoProps;
};
type JobFormInfoType = { [keys in keyof Partial<JobInputWithEndNumber>]: JobFormInfoKeysType };

const JobForm = ({
  initialValues,
  validationSchema,
  submitForm,
  handleCancel,
  data,
  isUpdate = false,
  blockUpdate: shouldBlockUpdate = false,
  isDelete = false,
  showSimplified = false,
}: JobFormProps) => {
  const helperTextStyles = useHelperTextStyles();
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  //#region Form States and Form helper functions
  /**
   * Object with warning info about a field and which field's behaviour was responsible for the warning
   */
  const [formInfo, setFormInfo] = useState<JobFormInfoType>();
  // invalid job codes (already exist)
  const [invalidJobCodes, setInvalidJobCodes] = useState<string[]>();
  // state of validation schema
  const [formikSchema, setFormikSchema] = useState<yup.ObjectSchema<any>>();

  // Change validation schema on state changes
  useEffect(() => {
    setFormikSchema(validationSchema(data, invalidJobCodes));
  }, [invalidJobCodes]);

  const handleSubmitForm = async (
    values: JobInputWithEndNumber,
    formikHelpers: FormikHelpers<JobInputWithEndNumber>
  ) => {
    setIsSubmiting(true);
    // delete endNumber (not a JobInput field)
    delete values.endNumber;
    await submitForm(values);
    setIsSubmiting(false);
  };

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange: handleChangesFormik,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    validateForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: formikSchema,
    onSubmit: handleSubmitForm,
  });

  // Validate form again if schema changes
  useEffect(() => {
    validateForm(values);
  }, [formikSchema]);

  /**
   * Variable to control numberingOrder change behaviour
   */
  const [prevNumberingOrder, setPrevNumberingOrder] = useState<JobNumberingOrder>();

  /**
   * Since Formik's handleChange function, by default, deletes fields with undefined values from
   * its values object, it's required to set the field value itself to "null" whithout using formik's handleChange
   * in order to keep the value as "empty" and send it over the API to override whatever value it has on the Database
   * @param e React.ChangeEvent<any>
   */
  const handleChange = (e: React.ChangeEvent<any>) => {
    let key = e.target.name as keyof Partial<JobInputWithEndNumber>;
    // Remove form info
    if (!!formInfo && !!formInfo?.[key]) {
      let newFormInfo = { ...formInfo };
      delete newFormInfo[key];
      setFormInfo(newFormInfo);
    }

    if (e.target.value === undefined) {
      setFieldValue(e.target.name, null);
    } else {
      handleChangesFormik(e);
    }
  };

  /**
   * Clears all form info fields generated by this field after its value cleared
   * @param fieldName Name fo the field to clear
   */
  const onClear = (fieldName: keyof JobInputWithEndNumber) => {
    let keyToRemove = fieldName as keyof typeof formInfo;
    let fieldNamesToClear: (keyof JobInputWithEndNumber)[] = [];
    // clear all FormInfo fields related to this fieldName and collect fields to clear
    let newFormInfo = copyObject(formInfo, [keyToRemove]);
    for (const key in newFormInfo) {
      let trueKey = key as keyof typeof newFormInfo;
      if (Object.prototype.hasOwnProperty.call(newFormInfo, trueKey)) {
        const element = newFormInfo[trueKey];
        if (!!element?.[keyToRemove]) {
          fieldNamesToClear.push(trueKey);
          delete element[keyToRemove];
          // check if object is now empty (not other warnings)
          if (isEmpty(element)) {
            delete newFormInfo[trueKey];
          }
        }
      }
    }
    setFormInfo(newFormInfo);

    // clear form value related to fieldName
    let newForm = { ...values };
    fieldNamesToClear.forEach((key) => {
      if (!!newForm?.[key]) {
        setFieldValue(key, null);
      }
    });
  };

  /**
   * Get helper text from either error or formInfo objects (error has priority)
   * @param fieldName Name of field to get Helper Text from
   * @returns Error or FormInfo (warning)
   */
  const getHelperText = (fieldName: keyof JobFormInfoType) => {
    return !!errors?.[fieldName] && dirty
      ? errors?.[fieldName]
      : !!formInfo?.[fieldName] && !isEmpty(formInfo?.[fieldName])
      ? getHelperTextFromFormInfo(formInfo[fieldName] as JobFormInfoKeysType)
      : "";
  };

  /**
   * Function to extract all form info of a field
   * @param field Field to extract all from info of
   */
  const getHelperTextFromFormInfo = (field: JobFormInfoKeysType): string => {
    let warnings: string[] = [];
    for (const key in field) {
      let trueKey = key as keyof typeof field;
      if (Object.prototype.hasOwnProperty.call(field, trueKey)) {
        const element = field[trueKey];
        if (!!element?.warning) {
          warnings.push(element.warning);
        }
      }
    }
    return warnings.join("/ ");
  };

  /**
   * Function that returns whether or not a field should be locked based on the FormInfo of the field
   * @param fieldName Name of the field to check
   * @returns Boolean (whether this field should be locked or not)
   */
  const shouldFieldBeLocked = (fieldName: keyof JobFormInfoType): boolean => {
    let formInfoOfField = formInfo?.[fieldName];
    if (!!formInfoOfField) {
      let key: keyof JobFormInfoKeysType;
      for (key in formInfoOfField) {
        if (Object.prototype.hasOwnProperty.call(formInfoOfField, key)) {
          const element = formInfoOfField[key];
          if (element?.disable) {
            return true;
          }
        }
      }
    }
    return false;
  };

  /**
   * Util function to determine whether there are really any errors in the Form (error can be an empty object: '{}')
   * @returns Boolean (has errors or not)
   */
  const hasErrors = () => {
    return !!errors && Object.keys(errors).length > 0;
  };

  /**
   * Util function to determine whether there are any existing conflicts when it comes to the codes of the resulting Slabs
   * @returns Boolean (has existing conflicts or not)
   */
  const hasExistingConflicts = () => {
    return (
      !!existingConflictCodes &&
      !!existingConflictCodes?.find(
        (conflict) => !!resultingSlabCodes?.find((resulting) => resulting === conflict)
      )
    );
  };
  //#endregion

  //#region Block and Job and Slab Codes validation and Control Region
  // block values for form
  const [blocks, setBlocks] = useState<BlockModelsToFilter_blockModels_edges_node[]>([]);

  /**
   * Block's Lazu Query to query Block data
   */
  const [blockLazyQuery, { loading: blockLoading }] = useLazyQuery<BlockModel, BlockModelVariables>(
    QUERY_ONE_BLOCKMODEL
  );
  /**
   * Slabs' Lazy Query to query Slabs' data
   */
  const [slabsLazyQuery, { data: slabsData, loading: slabsLoading }] = useLazyQuery<
    Slabs,
    SlabsVariables
  >(QUERY_SLABS);

  /**
   * Jobs Short Lazy Query to validate Code
   */
  const [jobsShortLazyQuery] = useLazyQuery<JobsShort, JobsShortVariables>(QUERY_JOBS_SHORT);
  /**
   * Jobs Lazy Query with more info to validate Code
   */
  const [jobsLazyQuery] = useLazyQuery<JobsForScannerConsole, JobsForScannerConsoleVariables>(
    QUERY_JOBS_FOR_SCANNER_CONSOLE
  );

  const { data: singleJobData } = useQuery<JobTypes, JobTypesVariables>(QUERY_JOB_TYPES, {
    variables: {
      filter: {
        isSingle: {
          is: true,
        },
      },
      paging: {
        first: 1,
      },
    },
  });

  /**
   * Validates Job Code - checks for duplicates
   * @param jobCode Job code
   */
  const validateJobCode = async (jobCode: string) => {
    await jobsShortLazyQuery({
      variables: {
        filter: {
          code: {
            eq: jobCode,
          },
        },
      },
      onCompleted(data) {
        let foundJob = data?.jobs?.edges?.[0]?.node;
        if (!!foundJob) {
          setInvalidJobCodes(
            invalidJobCodes ? [...invalidJobCodes, foundJob.code] : [foundJob.code]
          );
        }
      },
    });
  };

  /**
   * State to save new block to create (user input and not a user selection of an already existing Block)
   */
  const [newBlockToCreate, setNewBlockToCreate] = useState<
    BlockModelsToFilter_blockModels_edges_node | undefined
  >();
  // min start number
  // const [minStartNumber, setMinStartNumber] = useState<number>(1);

  /**
   * Find block info
   * @param block Block ID
   */
  const findBlockCatalogInfo = async (
    block: string | null | undefined,
    formInfo: JobFormInfoType | undefined
  ) => {
    if (!!block && block.trim() !== "N/A" && block.trim() !== "") {
      let newFormInfo: JobFormInfoType = { ...formInfo };
      // first try: get blockmodel info
      await blockLazyQuery({
        variables: {
          id: block,
        },
        async onCompleted(data) {
          const blockModelData = data?.blockModel;
          if (!!blockModelData) {
            // special vase for "code" since its a unique field
            const jobWithBlock = await jobsShortLazyQuery({
              variables: {
                filter: {
                  block: {
                    eq: blockModelData.id,
                  },
                },
              },
            });

            let toChangeKeys: (keyof BlockModels_blockModels_edges_node)[] = [
              "materialtype",
              "variety",
              "commercial_variety",
              "tenantCode",
              "tenantCode",
              "tenantCode",
            ];
            let toApplyKeys: (keyof JobInputWithEndNumber)[] = [
              "typematerial",
              "variety",
              "commercial_variety",
              "code",
              "prefix",
              "description",
            ];

            toChangeKeys.forEach((el, ind) => {
              let elementBlock = blockModelData?.[el] as
                | { [key: string]: any; id: string }
                | string;
              let toAdd: JobFormInfoKeysType | undefined = undefined;
              // string value
              if (typeof elementBlock === "string") {
                if (toApplyKeys[ind] === "code") {
                  let countOfBlocks = jobWithBlock?.data?.jobs?.totalCount || 0;
                  // set field value
                  setFieldValue(toApplyKeys[ind], `${elementBlock}J${countOfBlocks + 1}`);
                  // set field error to undefined since a new value was added
                  setFieldError(toApplyKeys[ind], undefined);
                } else {
                  // set field value
                  setFieldValue(toApplyKeys[ind], elementBlock);
                  // set field error to undefined since a new value was added
                  setFieldError(toApplyKeys[ind], undefined);
                }
                // add form info to notify the user why the field's value has changed
                toAdd = {
                  block: { warning: `From Block ${blockModelData.tenantCode}` },
                };
              }
              // reference/relation field with id
              else if (elementBlock?.id) {
                // set field value
                setFieldValue(toApplyKeys[ind], elementBlock?.id);
                // set field error to undefined since a new value was added
                setFieldError(toApplyKeys[ind], undefined);
                // add form info to notify the user why the field's value has changed
                toAdd = {
                  block: { warning: `From Block ${blockModelData.tenantCode}`, disable: true },
                };
              }

              if (!!toAdd) {
                newFormInfo = {
                  ...newFormInfo,
                  [toApplyKeys[ind]]: toAdd,
                };
              }
            });
            setFormInfo(newFormInfo);
            // validate new code
            validateJobCode(blockModelData.tenantCode);

            /**
             * Try to find Single Job Types with either this block's ID or tenantCode in order to have an estimated count of the resulting Slabs
             * Single Jobs might exists if the tenant has an integration agent running a sync task
             */
            if (!!singleJobData?.jobtypes?.edges[0]) {
              let jobSingleTypeId = singleJobData?.jobtypes?.edges[0].node.id;
              await jobsLazyQuery({
                variables: {
                  filter: {
                    or: [
                      {
                        block: {
                          eq: blockModelData.id,
                        },
                        jobtype: {
                          eq: jobSingleTypeId,
                        },
                      },
                      {
                        blocknumber: {
                          eq: blockModelData.tenantCode,
                        },
                        jobtype: {
                          eq: jobSingleTypeId,
                        },
                      },
                    ],
                  },
                  paging: {
                    first: 1,
                  },
                },
                onCompleted(data) {
                  if (!!data?.jobs?.totalCount) {
                    setFieldValue(
                      "endNumber",
                      values.numberingOrder !== JobNumberingOrder.DESC
                        ? (values.startNumber ? values.startNumber - 1 : 0) + data.jobs.totalCount
                        : data.jobs.totalCount - (values.startNumber ? values.startNumber - 1 : 0)
                    );
                    if (!!!!data.jobs?.edges[0]?.node?.commercial_finishing) {
                      setFieldValue(
                        "commercial_finishing",
                        data.jobs?.edges[0]?.node?.commercial_finishing.id
                      );
                      newFormInfo.commercial_finishing = {
                        block: {
                          warning: `From Block ${blockModelData.tenantCode}`,
                          disable: false,
                        },
                      };
                    }
                  }
                },
              });

              setFormInfo(newFormInfo);
            }
          }
        },
        /**
         * TODO: only using network-only due to this bug: https://github.com/apollographql/apollo-client/issues/10076
         * when apollo is updated, remove this
         */
        fetchPolicy: "network-only",
      }).catch((err) => {
        console.log(err);
      });
      // await setFieldValue("category", categories?.find((ctgr) => ctgr.code === "SL")?.id); TODO
    }
  };

  /**
   * Function that return the next Number to Start
   * @param blockId Form BLock's ID
   * @param blockCode Form BLock's Code
   * @param separator Slab prefix and count separator
   * @returns next Number to Start
   */
  const getStartNumber = async (blockId = "", blockCode = "", separator = ""): Promise<void> => {
    await slabsLazyQuery({
      query: QUERY_SLABS,
      variables: {
        filter: {
          and: [
            {
              code: { iLike: `${blockCode}${separator}` },
            },
          ],
        },
        sorting: [
          {
            direction: SortDirection.DESC,
            field: SlabSortFields.updatedAt,
          },
        ],
        paging: { limit: 30 },
      },
      onCompleted(data) {
        let current = 0;
        if (data?.slabs && data?.slabs?.nodes.length > 0 && values.prefix) {
          // save conflicts
          setExistingConflictCodes(data.slabs.nodes.map((el) => el.code));
          // check if slab has the same prefix
          let samePrefix = data.slabs.nodes[0].code?.startsWith(values.prefix);
          // get the next number
          if (samePrefix) {
            current = getNumberFromEnd(data.slabs.nodes[0].code) || 0;
          }
        }
        if (!!current) {
          setFieldValue("startNumber", current + 1);
          setFieldValue("endNumber", current + 1);
        }
        // setMinStartNumber(current + 1);
      },
      onError(error) {
        values.startNumber !== 1 && setFieldValue("startNumber", 1);
        // setMinStartNumber(1);
      },
      /**
       * TODO: only using network-only due to this bug: https://github.com/apollographql/apollo-client/issues/10076
       * when apollo is updated, remove this
       */
      fetchPolicy: "network-only",
    }).catch((err) => {
      // force reset
      values.startNumber !== 1 && setFieldValue("startNumber", 1);
      // setMinStartNumber(1);
    });
  };

  /**
   * Util function to check and validate prefix value
   * @param prefix Form's Prefix value
   * @param separator Form's Separator value (default to empty string: "")
   */
  const checkPrefix = (prefix: string | null | undefined, separator = "") => {
    if (!!prefix) {
      let foundBlock = blocks?.find((el) => el.tenantCode === prefix || el.wescanCode === prefix);
      getStartNumber(
        values?.block || undefined,
        foundBlock?.tenantCode ?? foundBlock?.wescanCode,
        separator
      );
    } else {
      // setMinStartNumber(1);
      setFieldValue("startNumber", 1);
      setFieldError("startNumber", undefined);
    }
  };

  /**
   * Use effect that runs every time prefix or separator change in order to validate both fields
   */
  useEffect(() => {
    if (!!values?.prefix && !!!shouldBlockUpdate && !!!data?.scannedSlabs) {
      checkPrefix(values.prefix, values?.separator ? values?.separator : "");
    }
  }, [values?.prefix, values?.separator]);

  /**
   * Start the creation of a new block with a string input
   * @param value New Block's code
   */
  const startNewBlockCreation = (value: string) => {
    if (
      !!value &&
      value.trim() !== "" &&
      !!!blocks.find((blk) => blk.tenantCode == value || blk.wescanCode == value)
    ) {
      setNewBlockToCreate({ id: "new", tenantCode: value, wescanCode: value });
      // Set Values
      setFieldValue("block", value);
      setFieldValue("code", `${value}J1`);
      setFieldValue("prefix", value);

      // Reset Errors
      setFieldError("block", undefined);
      setFieldError("code", undefined);
      setFieldError("prefix", undefined);

      // Set Form Info
      setFormInfo({
        ...formInfo,
        block: { block: { warning: `Creating New Block "${value}"` } },
        code: {
          block: {
            warning: `From Block ${value}`,
          },
        },
        prefix: {
          block: {
            warning: `From Block ${value}`,
          },
        },
      });
    }
  };

  //#endregion

  //#region Resulting Slabs Region
  // resulting slab codes from prefix
  const [resultingSlabCodes, setResultingSlabCodes] = useState<string[]>();
  // resulting slab codes from prefix
  const [existingConflictCodes, setExistingConflictCodes] = useState<string[]>();
  // is viewing resulting slabs control
  const [isViewingResultingSlabs, setIsViewingResultingSlabs] = useState<boolean>(false);
  // is submiting with duplicate slabs control
  const [isSubmitingDuplicateSlabs, setIsSubmitingDuplicateSlabs] = useState<boolean>(false);

  /**
   * Function to get the codes of every future slab of this job
   * @param prefix Slabs' Prefix
   * @param order Job's Numbering Order
   * @param startNumber Job's Start Number
   * @param endNumber Job's Total Slabs
   * @param separator Slab Prefix and Count separator
   */
  const getResultingSlabsList = (
    prefix: string,
    order = JobNumberingOrder.ASC,
    startNumber = 1,
    endNumber = 1,
    separator = ""
  ) => {
    let newValue: string[] = [];
    let totalslab =
      order === JobNumberingOrder.DESC && typeof startNumber === "number"
        ? startNumber - (endNumber || 1) + 1
        : typeof values.startNumber === "number"
        ? (values.endNumber || 1) - values.startNumber + 1
        : 1;
    for (let index = 0; index < totalslab; index++) {
      let slabCode = `${prefix}${separator}${(order === JobNumberingOrder.ASC
        ? startNumber + index
        : startNumber - index
      ).toLocaleString("en-US", {
        minimumIntegerDigits: 3,
      })}`;
      newValue.push(slabCode);
    }
    setResultingSlabCodes(newValue);
  };

  /**
   * UseEffect to check resulting slabs' codes
   */
  useEffect(() => {
    if (!!values.prefix) {
      // use ternary op. to make sure parameter is never passes as null since it's !== undefined
      getResultingSlabsList(
        values.prefix,
        values.numberingOrder || undefined,
        values.startNumber === null ? undefined : values.startNumber,
        values.endNumber === null ? undefined : values.endNumber,
        values.separator === null ? undefined : values.separator
      );
    } else {
      setResultingSlabCodes([]);
    }
  }, [values.prefix, values.startNumber, values.endNumber, values.separator]);

  /**
   * Additional useEffect to make sure resulting slabs list changes on ordering change (will force useEffect above to run since startNumber)
   */
  useEffect(() => {
    if (
      values?.numberingOrder === JobNumberingOrder.DESC &&
      !!values.startNumber &&
      !!values.totalslab &&
      prevNumberingOrder === JobNumberingOrder.ASC
    ) {
      // setMinStartNumber(minStartNumber + (values?.totalslab || 1));
      let oldStart = values.startNumber;
      let oldEnd = values.endNumber;
      setFieldValue("startNumber", oldEnd);
      setFieldValue("endNumber", oldStart);
    } else if (
      values?.numberingOrder === JobNumberingOrder.ASC &&
      prevNumberingOrder === JobNumberingOrder.DESC
    ) {
      // setMinStartNumber(minStartNumber - (values?.totalslab || 1));
      let oldStart = values.startNumber;
      let oldEnd = values.endNumber;
      setFieldValue("startNumber", oldEnd);
      setFieldValue("endNumber", oldStart);
    }
  }, [values.numberingOrder]);

  /**
   * Additional useEffect to sync endNumber
   */
  useEffect(() => {
    if (
      values?.numberingOrder === JobNumberingOrder.DESC &&
      typeof values.startNumber === "number"
    ) {
      setFieldValue("totalslab", values.startNumber - (values.endNumber || 1) + 1);
    } else if (typeof values.startNumber === "number") {
      setFieldValue("totalslab", (values.endNumber || 1) - values.startNumber + 1);
    }
  }, [values.startNumber, values.endNumber]);

  //#endregion

  return (
    <>
      <form onSubmit={handleSubmit}>
        {showSimplified ? (
          // SIMPLIFIED VERSION
          <Box>
            {/* Line 1 */}
            <Grid container item xs={12} spacing={1} sx={{ mt: 1 }}>
              <Grid key="block-grid" item xs={10}>
                <AutocompleteWithQuery
                  items={blocks}
                  label="Block"
                  name="block"
                  getCreationPrompt={(val) => {
                    return {
                      id: "create",
                      tenantCode: `Create new Block "${val}" +`,
                      trueValue: val,
                    } as (typeof blocks)[number] & { trueValue: string };
                  }}
                  enableCreation
                  onChange={(e: React.ChangeEvent<any>) => {
                    // ID comes from "getCreationPrompt"
                    if (e.target.id === "create") {
                      startNewBlockCreation(e.target.trueValue);
                    } else {
                      onClear("block");
                      findBlockCatalogInfo(e.target.value, formInfo);
                      setNewBlockToCreate(undefined);
                      handleChange(e);
                    }
                  }}
                  title="Block"
                  value={values.block}
                  onClear={() => onClear("block")}
                  TextFieldProps={{
                    error: !!errors?.block,
                    helperText: getHelperText("block"),
                    label: "Block",
                    disabled: shouldBlockUpdate || !!data?.scannedSlabs,
                  }}
                  AutocompleteProps={{
                    disabled: shouldBlockUpdate || !!data?.scannedSlabs,
                    className: undefined,
                  }}
                  onBlur={startNewBlockCreation}
                  newItem={newBlockToCreate}
                  onTextChange={async (value: string) => {
                    await queryValuesForFilter<
                      BlockModelFilter,
                      CursorPaging,
                      BlockModelSort,
                      BlockModelsToFilter
                    >(
                      value,
                      QUERY_BLOCKS_TO_FILTER,
                      (res) => {
                        let endRes = res?.data?.blockModels?.edges?.map((el) => el.node);
                        setBlocks(endRes);
                      },
                      {
                        filter: {
                          or: [
                            { wescanCode: { iLike: value } },
                            { tenantCode: { iLike: value } },
                            { tenantCode: { eq: value } },
                          ],
                        },
                        paging: {
                          first: 40,
                        },
                      }
                    );
                  }}
                  getField={(node: (typeof blocks)[number]) => {
                    return !!node.tenantCode ? node.tenantCode : node.wescanCode;
                  }}
                />
              </Grid>
              {/* <Grid key="totalslab" item xs={4}>
                <TextField
                  disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                  id="totalslab"
                  label="Total Scans"
                  type="number"
                  name="totalslab"
                  placeholder="Total Scans"
                  onChange={handleChange}
                  inputProps={{
                    min: 1,
                    style: {
                      width: "100%",
                    },
                  }}
                  value={values.totalslab || 0}
                  error={!!touched && !!errors.totalslab}
                  helperText={!!touched && errors.totalslab}
                  onFocus={(event) => {
                    event?.target?.select();
                  }}
                />
              </Grid> */}
            </Grid>
            {/* Line 2 */}
            <Grid container item xs={12} spacing={1} sx={{ mt: 1 }}>
              <Grid key="code-grid" item xs={5}>
                <TextField
                  disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                  id="code"
                  label="Code"
                  name="code"
                  onChange={handleChange}
                  value={values.code || ""}
                  placeholder="Code"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  onBlur={(e) => {
                    validateJobCode(e.target.value).then(() => {
                      // set description as code's value in short form
                      setFieldValue("description", e.target.value);
                    });
                  }}
                  variant="outlined"
                  error={!!errors?.code && !!dirty}
                  helperText={getHelperText("code")}
                  FormHelperTextProps={{
                    classes: !!errors.code
                      ? undefined
                      : {
                          root: helperTextStyles.root,
                        },
                  }}
                />
              </Grid>
              <Grid key="prefix-grid" item xs={5}>
                <TextField
                  disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                  id="prefix"
                  label="Slabs Prefix"
                  name="prefix"
                  onChange={handleChange}
                  value={values.prefix || ""}
                  placeholder="ex: BLK1234"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={(e) => {
                    checkPrefix(e.currentTarget.value);
                  }}
                  variant="outlined"
                  error={!!errors?.prefix && !!dirty}
                  helperText={getHelperText("prefix")}
                  FormHelperTextProps={{
                    classes: !!errors.prefix
                      ? undefined
                      : {
                          root: helperTextStyles.root,
                        },
                  }}
                />
              </Grid>
              <Grid key="separator-grid" item xs={2}>
                <TextField
                  disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                  id="separator"
                  label="Separator"
                  name="separator"
                  onChange={handleChange}
                  value={values.separator || ""}
                  placeholder="ex: -"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 1,
                  }}
                  onBlur={(e) => {
                    checkPrefix(e.currentTarget.value);
                  }}
                  variant="outlined"
                  error={!!errors?.separator}
                  helperText={getHelperText("separator")}
                  FormHelperTextProps={{
                    classes: !!errors.separator
                      ? undefined
                      : {
                          root: helperTextStyles.root,
                        },
                  }}
                  onFocus={(event) => {
                    event?.target?.select();
                  }}
                />
              </Grid>
            </Grid>
            {/* Line 3 */}
            <Grid container item xs={12} spacing={1} sx={{ mt: 1 }}>
              <Grid key="code-start-number-grid" item xs={4}>
                <TextField
                  disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                  id="startNumber"
                  label="Start Number"
                  name="startNumber"
                  onChange={handleChange}
                  value={values.startNumber || ""}
                  placeholder="ex: 1"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min:
                      values.numberingOrder === JobNumberingOrder.DESC ? values.endNumber || 1 : 1,
                    max:
                      values.numberingOrder === JobNumberingOrder.ASC
                        ? values.endNumber || 1
                        : undefined,
                  }}
                  variant="outlined"
                  error={!!errors?.startNumber}
                  helperText={getHelperText("startNumber")}
                  FormHelperTextProps={{
                    classes: !!errors.startNumber
                      ? undefined
                      : {
                          root: helperTextStyles.root,
                        },
                  }}
                  onFocus={(event) => {
                    event?.target?.select();
                  }}
                />
              </Grid>
              <Grid key="code-end-number-grid" item xs={4}>
                <TextField
                  disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                  id="endNumber"
                  label="End Number"
                  name="endNumber"
                  onChange={handleChange}
                  value={values.endNumber || 1}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min:
                      values.numberingOrder === JobNumberingOrder.ASC ? values.startNumber || 1 : 1,
                    max:
                      values.numberingOrder === JobNumberingOrder.DESC
                        ? values.startNumber || 1
                        : undefined,
                  }}
                  variant="outlined"
                  onFocus={(event) => {
                    event?.target?.select();
                  }}
                  error={!!errors?.endNumber}
                  helperText={getHelperText("endNumber")}
                  FormHelperTextProps={{
                    classes: !!errors.endNumber
                      ? undefined
                      : {
                          root: helperTextStyles.root,
                        },
                  }}
                />
              </Grid>
              <Grid key="numbering-order-grid" item xs={4} sx={{ alignItems: "center" }}>
                <ToggleButton
                  value="check"
                  color={values.numberingOrder !== JobNumberingOrder.DESC ? "success" : "primary"}
                  selected={true}
                  contextMenu={"Numbering Order"}
                  onChange={() => {
                    setPrevNumberingOrder(values.numberingOrder || undefined);
                    setFieldValue(
                      "numberingOrder",
                      values.numberingOrder !== JobNumberingOrder.DESC
                        ? JobNumberingOrder.DESC
                        : JobNumberingOrder.ASC
                    );
                  }}
                >
                  <FlexBox sx={{ alignItems: "center" }}>
                    {values.numberingOrder !== JobNumberingOrder.DESC ? (
                      <KeyboardArrowUp
                        fontSize="large"
                        color="action"
                        titleAccess="Numbering Order"
                      />
                    ) : (
                      <KeyboardArrowDown
                        fontSize="large"
                        color="action"
                        titleAccess="Numbering Order"
                      />
                    )}
                    <Typography>
                      Numbers{" "}
                      {values.numberingOrder !== JobNumberingOrder.DESC
                        ? "Ascending"
                        : "Descending"}
                    </Typography>
                  </FlexBox>
                </ToggleButton>
              </Grid>
            </Grid>
            {/* Line 4 */}
            {!!resultingSlabCodes && !!resultingSlabCodes.length && (
              <Grid container xs={12} sx={{ mt: 1 }}>
                <Grid
                  container
                  item
                  xs={12}
                  flexDirection="row"
                  direction={"row"}
                  justifyContent={"space-between"}
                  sx={{ alignItems: "center", px: 3 }}
                >
                  <H5>{resultingSlabCodes[0]}</H5>
                  <Remove />
                  <H5>{resultingSlabCodes[resultingSlabCodes.length - 1]}</H5>
                </Grid>
                {hasExistingConflicts() && (
                  <Grid item xs={12} mt={1}>
                    <Alert
                      severity={"warning"}
                      variant="standard"
                      action={
                        <IconButton
                          onClick={() => {
                            setIsViewingResultingSlabs(true);
                          }}
                        >
                          <RemoveRedEye />
                        </IconButton>
                      }
                    >
                      Conflicts in Slab codes
                    </Alert>
                  </Grid>
                )}
                {!!resultingSlabCodes && resultingSlabCodes.length > 0 && (
                  <SimpleDialog
                    title={
                      isSubmitingDuplicateSlabs
                        ? "Job will replace the duplicate Slabs. Continue?"
                        : "Job's Resulting Slabs"
                    }
                    list={resultingSlabCodes}
                    open={isViewingResultingSlabs || isSubmitingDuplicateSlabs}
                    onClose={() => {
                      setIsViewingResultingSlabs(false);
                      setIsSubmitingDuplicateSlabs(false);
                    }}
                    onSubmit={isSubmitingDuplicateSlabs ? handleSubmit : undefined}
                    conflictList={existingConflictCodes}
                    scannedSlabs={data?.scannedSlabs}
                  />
                )}
              </Grid>
            )}
            {/* Remaining */}
            <Grid item xs={12} mt={2}>
              <MaterialTypeAutocomplete
                onChange={handleChange}
                value={values?.typematerial}
                errors={errors?.typematerial}
                shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("typematerial")}
                helperText={getHelperText("typematerial")}
                AutocompleteProps={{ className: undefined }}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <CommercialVarietyAutocomplete
                onChange={handleChange}
                value={values?.commercial_variety}
                errors={errors?.commercial_variety}
                shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("commercial_variety")}
                helperText={getHelperText("commercial_variety")}
                AutocompleteProps={{ className: undefined }}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <CommercialFinishingAutocomplete
                onChange={handleChange}
                value={values?.commercial_finishing}
                errors={errors?.commercial_finishing}
                shouldFieldBeLocked={
                  shouldBlockUpdate || shouldFieldBeLocked("commercial_finishing")
                }
                helperText={getHelperText("commercial_finishing")}
                AutocompleteProps={{ className: undefined }}
              />
            </Grid>
            {/* Hide */}
            <Grid sx={{ display: "none" }} item xs={12} mt={1}>
              <CategoryAutocomplete
                onChange={handleChange}
                value={values?.category}
                errors={errors?.category}
                shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("category")}
                helperText={getHelperText("category")}
                AutocompleteProps={{
                  className: undefined,
                }}
                filterToSelectDefaultItemForFirstQueryResults={{
                  isResultOfSlabScan: true,
                }}
              />
            </Grid>
            {/* Buttons */}
            <Grid container item xs={12} spacing={1} sx={{ alignItems: "center", mt: 1 }}>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    !!hasExistingConflicts() ? setIsSubmitingDuplicateSlabs(true) : handleSubmit();
                  }}
                  fullWidth
                  variant="contained"
                  disabled={
                    // has errors
                    hasErrors() ||
                    // or is not dirt
                    !!!dirty
                  }
                >
                  <Save style={{ marginRight: "20px" }} />
                  New Job
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          // FULL VERSION
          <>
            {/* Header */}
            {/* Block, Total Scans & Naming Rules */}
            {/* Title & Resulting Slabs Button */}
            <Grid container spacing={3}>
              <Grid item container xs={12} md={6} direction="row">
                <Grid item container xs={12}>
                  <FlexBox>
                    <H4>Block, Total Scans & Naming Rules</H4>
                    {!!data?.scannedSlabs && (
                      <H4 sx={{ fontWeight: "bold", ml: 1 }}>
                        {"(Disabled - "}
                        {data?.scannedSlabs + " Slabs Scanned)"}
                      </H4>
                    )}
                  </FlexBox>
                  {/* Line 1 */}
                  <Grid container item xs={12} spacing={1} sx={{ mt: 1 }}>
                    <Grid key="block-grid" item xs={10}>
                      <AutocompleteWithQuery
                        items={blocks}
                        label="Block"
                        name="block"
                        getCreationPrompt={(val) => {
                          return {
                            id: "create",
                            tenantCode: `Create new Block "${val}" +`,
                            trueValue: val,
                          } as (typeof blocks)[number] & { trueValue: string };
                        }}
                        enableCreation
                        onChange={(e: React.ChangeEvent<any>) => {
                          // ID comes from "getCreationPrompt"
                          if (e.target.id === "create") {
                            startNewBlockCreation(e.target.trueValue);
                          } else {
                            onClear("block");
                            findBlockCatalogInfo(e.target.value, formInfo);
                            setNewBlockToCreate(undefined);
                            handleChange(e);
                          }
                        }}
                        title="Block"
                        value={values.block}
                        onClear={() => onClear("block")}
                        TextFieldProps={{
                          error: !!errors?.block,
                          helperText: getHelperText("block"),
                          label: "Block",
                          disabled: shouldBlockUpdate || !!data?.scannedSlabs,
                        }}
                        AutocompleteProps={{
                          disabled: shouldBlockUpdate || !!data?.scannedSlabs,
                          className: undefined,
                        }}
                        onBlur={startNewBlockCreation}
                        newItem={newBlockToCreate}
                        onTextChange={async (value: string) => {
                          await queryValuesForFilter<
                            BlockModelFilter,
                            CursorPaging,
                            BlockModelSort,
                            BlockModelsToFilter
                          >(
                            value,
                            QUERY_BLOCKS_TO_FILTER,
                            (res) => {
                              let endRes = res?.data?.blockModels?.edges?.map((el) => el.node);
                              setBlocks(endRes);
                            },
                            {
                              filter: {
                                or: [
                                  { wescanCode: { iLike: value } },
                                  { tenantCode: { iLike: value } },
                                ],
                              },
                              paging: {
                                first: 40,
                              },
                            }
                          );
                        }}
                        getField={(node: (typeof blocks)[number]) => {
                          return !!node.tenantCode ? node.tenantCode : node.wescanCode;
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* Line 2 */}
                  <Grid container item xs={12} spacing={1} sx={{ mt: 1 }}>
                    <Grid key="code-start-number-grid" item xs={4}>
                      <TextField
                        disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                        id="startNumber"
                        label="Start Number"
                        name="startNumber"
                        onChange={handleChange}
                        value={values.startNumber || ""}
                        placeholder="ex: 1"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min:
                            values.numberingOrder === JobNumberingOrder.DESC
                              ? values.endNumber || 1
                              : 1,
                          max:
                            values.numberingOrder === JobNumberingOrder.ASC
                              ? values.endNumber || 1
                              : undefined,
                        }}
                        variant="outlined"
                        error={!!errors?.startNumber}
                        helperText={getHelperText("startNumber")}
                        FormHelperTextProps={{
                          classes: !!errors.startNumber
                            ? undefined
                            : {
                                root: helperTextStyles.root,
                              },
                        }}
                        onFocus={(event) => {
                          event?.target?.select();
                        }}
                      />
                    </Grid>
                    <Grid key="code-end-number-grid" item xs={4}>
                      <TextField
                        disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                        id="endNumber"
                        label="End Number"
                        name="endNumber"
                        onChange={handleChange}
                        value={values.endNumber || 1}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min:
                            values.numberingOrder === JobNumberingOrder.ASC
                              ? values.startNumber || 1
                              : 1,
                          max:
                            values.numberingOrder === JobNumberingOrder.DESC
                              ? values.startNumber || 1
                              : undefined,
                        }}
                        variant="outlined"
                        onFocus={(event) => {
                          event?.target?.select();
                        }}
                        error={!!errors?.endNumber}
                        helperText={getHelperText("endNumber")}
                        FormHelperTextProps={{
                          classes: !!errors.endNumber
                            ? undefined
                            : {
                                root: helperTextStyles.root,
                              },
                        }}
                      />
                    </Grid>
                    <Grid key="numbering-order-grid" item xs={4} sx={{ alignItems: "center" }}>
                      <ToggleButton
                        value="check"
                        color={
                          values.numberingOrder !== JobNumberingOrder.DESC ? "success" : "primary"
                        }
                        selected={true}
                        contextMenu={"Numbering Order"}
                        onChange={() => {
                          setPrevNumberingOrder(values.numberingOrder || undefined);
                          setFieldValue(
                            "numberingOrder",
                            values.numberingOrder !== JobNumberingOrder.DESC
                              ? JobNumberingOrder.DESC
                              : JobNumberingOrder.ASC
                          );
                        }}
                      >
                        <FlexBox sx={{ alignItems: "center" }}>
                          {values.numberingOrder !== JobNumberingOrder.DESC ? (
                            <KeyboardArrowUp
                              fontSize="large"
                              color="action"
                              titleAccess="Numbering Order"
                            />
                          ) : (
                            <KeyboardArrowDown
                              fontSize="large"
                              color="action"
                              titleAccess="Numbering Order"
                            />
                          )}
                          <Typography>
                            Numbers{" "}
                            {values.numberingOrder !== JobNumberingOrder.DESC
                              ? "Ascending"
                              : "Descending"}
                          </Typography>
                        </FlexBox>
                      </ToggleButton>
                    </Grid>
                  </Grid>
                  {/* Line 3 */}
                  <Grid container item xs={12} spacing={1} sx={{ mt: 1 }}>
                    <Grid key="code-grid" item xs={5}>
                      <TextField
                        disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                        id="code"
                        label="Code"
                        name="code"
                        onChange={handleChange}
                        value={values.code || ""}
                        placeholder="Code"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onBlur={(e) => {
                          validateJobCode(e.target.value).then(() => {
                            // set description as code's value in short form
                            setFieldValue("description", e.target.value);
                          });
                        }}
                        variant="outlined"
                        error={!!errors?.code && !!dirty}
                        helperText={getHelperText("code")}
                        FormHelperTextProps={{
                          classes: !!errors.code
                            ? undefined
                            : {
                                root: helperTextStyles.root,
                              },
                        }}
                      />
                    </Grid>
                    <Grid key="prefix-grid" item xs={5}>
                      <TextField
                        disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                        id="prefix"
                        label="Slabs Prefix"
                        name="prefix"
                        onChange={handleChange}
                        value={values.prefix || ""}
                        placeholder="ex: BLK1234"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onBlur={(e) => {
                          checkPrefix(e.currentTarget.value);
                        }}
                        variant="outlined"
                        error={!!errors?.prefix && !!dirty}
                        helperText={getHelperText("prefix")}
                        FormHelperTextProps={{
                          classes: !!errors.prefix
                            ? undefined
                            : {
                                root: helperTextStyles.root,
                              },
                        }}
                      />
                    </Grid>
                    <Grid key="separator-grid" item xs={2}>
                      <TextField
                        disabled={shouldBlockUpdate || !!data?.scannedSlabs}
                        id="separator"
                        label="Separator"
                        name="separator"
                        onChange={handleChange}
                        value={values.separator || ""}
                        placeholder="ex: -"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          maxLength: 1,
                        }}
                        onBlur={(e) => {
                          checkPrefix(e.currentTarget.value);
                        }}
                        variant="outlined"
                        error={!!errors?.separator}
                        helperText={getHelperText("separator")}
                        FormHelperTextProps={{
                          classes: !!errors.separator
                            ? undefined
                            : {
                                root: helperTextStyles.root,
                              },
                        }}
                        onFocus={(event) => {
                          event?.target?.select();
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* Line 4 */}
                  {!!resultingSlabCodes && !!resultingSlabCodes.length && (
                    <Grid container xs={12} sx={{ mt: 1 }}>
                      <Grid
                        container
                        item
                        xs={12}
                        flexDirection="row"
                        direction={"row"}
                        justifyContent={"space-between"}
                        sx={{ alignItems: "center", px: 3 }}
                      >
                        <H5>{resultingSlabCodes[0]}</H5>
                        <Remove />
                        <H5>{resultingSlabCodes[resultingSlabCodes.length - 1]}</H5>
                      </Grid>
                      {hasExistingConflicts() && (
                        <Grid item xs={12} mt={1}>
                          <Alert
                            severity={"warning"}
                            variant="standard"
                            action={
                              <IconButton
                                onClick={() => {
                                  setIsViewingResultingSlabs(true);
                                }}
                              >
                                <RemoveRedEye />
                              </IconButton>
                            }
                          >
                            Conflicts in Slab codes
                          </Alert>
                        </Grid>
                      )}
                      {!!resultingSlabCodes && resultingSlabCodes.length > 0 && (
                        <SimpleDialog
                          title={
                            isSubmitingDuplicateSlabs
                              ? "Job will replace the duplicate Slabs. Continue?"
                              : "Job's Resulting Slabs"
                          }
                          list={resultingSlabCodes}
                          open={isViewingResultingSlabs || isSubmitingDuplicateSlabs}
                          onClose={() => {
                            setIsViewingResultingSlabs(false);
                            setIsSubmitingDuplicateSlabs(false);
                          }}
                          onSubmit={isSubmitingDuplicateSlabs ? handleSubmit : undefined}
                          conflictList={existingConflictCodes}
                          scannedSlabs={data?.scannedSlabs}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item container xs={12} md={6}>
                <FlexBox mb={1}>
                  <H4>Scanner</H4>
                </FlexBox>
                <Grid container item xs={12} spacing={1} mb={1}>
                  <Grid item md={6} xs={12}>
                    <ScannerAutocomplete
                      selectItemOnFirstQueryIfSoloRecord
                      onChange={handleChange}
                      value={values?.scan}
                      errors={errors?.scan}
                      shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("scan")}
                      helperText={errors?.scan}
                      AutocompleteProps={{
                        className: undefined,
                      }}
                    />
                  </Grid>
                  <Grid container item md={6} xs={12}>
                    <Grid item xs={10}>
                      <AutocompleteFilterJustText
                        disabled={
                          (!!data?.taskprocesses &&
                            data?.taskprocesses?.nodes[0]?.slabcount >=
                              data?.taskprocesses?.nodes[0]?.totalslab) ||
                          isDelete ||
                          (!!data && data?.scannedSlabs >= data?.totalslab)
                        }
                        items={Object.keys(DescState)?.filter(
                          (el) => el !== DescState.PENDING && el !== DescState.CONCLUDED
                        )}
                        title={"State*"}
                        label={"State*"}
                        value={values.descstate}
                        onChange={(e: React.ChangeEvent<any>) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        field=""
                        name={"descstate"}
                        error={!!touched && !!errors.descstate}
                        helperText={!!touched && errors.descstate}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip
                        title={
                          <Box>
                            <H3 color={theme.palette.background.default}>State Descriptions</H3>
                            <Small color="inherit">
                              CANCELED - Cancels a Job, Scanner will ignore it when uploading a
                              Slab.
                            </Small>
                            <br />
                            <Small color="inherit">
                              CONCLUDED - Concludes a Job, Scanner will ignore it when uploading a
                              Slab.
                            </Small>
                            <br />
                            <Small color="inherit">
                              EXECUTION - Activates a Job, Scanner will run this Job if it is the
                              most recent.
                            </Small>
                            <br />
                            <Small color="inherit">
                              PENDING - Deactivates a Job. Scanner will ignore this Job when
                              uploading a Slab.
                            </Small>
                            <br />
                            <Small color="inherit">
                              STOPPED - Pauses a Job. Scanner will ignore this Job when uploading a
                              Slab.
                            </Small>
                            <br />
                          </Box>
                        }
                      >
                        <Info />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <FlexBox mb={1}>
                  <H4>Classification</H4>
                </FlexBox>
                <Grid container spacing={1} mt={0.1}>
                  <Grid item md={6} xs={12}>
                    <CategoryAutocomplete
                      onChange={handleChange}
                      value={values?.category}
                      errors={errors?.category}
                      shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("category")}
                      helperText={getHelperText("category")}
                      AutocompleteProps={{
                        className: undefined,
                      }}
                      filterToSelectDefaultItemForFirstQueryResults={{
                        isResultOfSlabScan: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <MaterialTypeAutocomplete
                      onChange={handleChange}
                      value={values?.typematerial}
                      errors={errors?.typematerial}
                      shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("typematerial")}
                      helperText={getHelperText("typematerial")}
                      AutocompleteProps={{
                        className: undefined,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} mt={0.1}>
                  <Grid item md={6} xs={12}>
                    <CommercialVarietyAutocomplete
                      onChange={handleChange}
                      value={values?.commercial_variety}
                      errors={errors?.commercial_variety}
                      shouldFieldBeLocked={
                        shouldBlockUpdate || shouldFieldBeLocked("commercial_variety")
                      }
                      helperText={getHelperText("commercial_variety")}
                      AutocompleteProps={{
                        className: undefined,
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CommercialFinishingAutocomplete
                      onChange={handleChange}
                      value={values?.commercial_finishing}
                      errors={errors?.commercial_finishing}
                      shouldFieldBeLocked={
                        shouldBlockUpdate || shouldFieldBeLocked("commercial_finishing")
                      }
                      helperText={getHelperText("commercial_finishing")}
                      AutocompleteProps={{
                        className: undefined,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Other Classifications */}
            <hr />
            <H4>Other Classifications</H4>
            <Grid container spacing={3} mt={0.1}>
              <Grid item lg={4} xs={12}>
                <ProjectAutocomplete
                  onChange={handleChange}
                  value={values?.project}
                  errors={errors?.project}
                  shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("project")}
                  helperText={getHelperText("project")}
                  AutocompleteProps={{
                    className: undefined,
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <PackageAutocomplete
                  onChange={handleChange}
                  value={values?.packing}
                  errors={errors?.packing}
                  shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("packing")}
                  helperText={getHelperText("packing")}
                  AutocompleteProps={{
                    className: undefined,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.1}>
              <Grid item lg={4} xs={12}>
                <StoneModelAutocomplete
                  onChange={handleChange}
                  value={values?.stone_model}
                  errors={errors?.stone_model}
                  shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("stone_model")}
                  helperText={getHelperText("stone_model")}
                  AutocompleteProps={{
                    className: undefined,
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <StoneEdgeAutocomplete
                  onChange={handleChange}
                  value={values?.stone_edge}
                  errors={errors?.stone_edge}
                  shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("stone_edge")}
                  helperText={getHelperText("stone_edge")}
                  AutocompleteProps={{
                    className: undefined,
                  }}
                />
              </Grid>
            </Grid>
            {/* Default System Classification */}
            <hr />
            <H4>Default System Classification </H4>
            <Grid container spacing={3} mt={0.1}>
              <Grid item lg={4} xs={12}>
                <FinishingAutocomplete
                  onChange={handleChange}
                  value={values?.finish}
                  errors={errors?.finish}
                  shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("finish")}
                  helperText={getHelperText("finish")}
                  AutocompleteProps={{
                    className: undefined,
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <VarietyAutocomplete
                  onChange={handleChange}
                  value={values?.variety}
                  errors={errors?.variety}
                  shouldFieldBeLocked={shouldBlockUpdate || shouldFieldBeLocked("variety")}
                  helperText={getHelperText("variety")}
                  AutocompleteProps={{
                    className: undefined,
                  }}
                />
              </Grid>
            </Grid>
            {/* Buttons */}
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "flex-end",
                mt: 1,
                mb: 3,
              }}
            >
              {!!isDelete && (
                <Grid item>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="error"
                    style={{
                      height: "50px",
                      width: "200px",
                      fontSize: "20px",
                    }}
                    disabled={
                      data?.descstate === DescState.EXECUTION ||
                      (!!data && data.scannedSlabs >= data.totalslab)
                    }
                  >
                    <Delete style={{ marginRight: "20px" }} />
                    Delete Job
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  onClick={handleCancel}
                  fullWidth
                  color="inherit"
                  variant="contained"
                  style={{
                    height: "50px",
                    width: "200px",
                    fontSize: "20px",
                  }}
                >
                  <Cancel style={{ marginRight: "20px" }} />
                  Cancel
                </Button>
              </Grid>
              {!!!isDelete && (
                <Grid item>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      height: "50px",
                      width: "200px",
                      fontSize: "20px",
                    }}
                    disabled={
                      // has errors
                      hasErrors() ||
                      // or is not dirt
                      !!!dirty
                    }
                  >
                    <Save style={{ marginRight: "20px" }} />
                    {!isUpdate ? "New Job" : "Edit Job"}
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </form>
      {(isSubmiting || blockLoading) && <FullScreenActivityIndicator />}
    </>
  );
};

export default JobForm;

export interface SimpleDialogProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  submitButtonText?: string;
  list: string[];
  conflictList?: string[];
  scannedSlabs?: number;
}

function SimpleDialog(props: SimpleDialogProps) {
  const theme = useTheme();
  const { onClose, open, list, conflictList, scannedSlabs, onSubmit, submitButtonText, title } =
    props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <List sx={{ p: 1 }}>
        {list.map((value, ind) => {
          let conflict = conflictList?.find((el) => el === value);
          return (
            <ListItem key={value}>
              <ListItemText>
                <H4
                  color={
                    !!conflict
                      ? theme.palette.error.main
                      : !!scannedSlabs && ind + 1 < scannedSlabs
                      ? theme.palette.success.main
                      : theme.palette.text.disabled
                  }
                >
                  {`${ind + 1} - ${value}${
                    conflict
                      ? ` (duplicate)`
                      : !!scannedSlabs && ind + 1 < scannedSlabs
                      ? " (scanned)"
                      : ""
                  }`}
                </H4>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
      {!!onSubmit && (
        <DialogActions>
          <Button onClick={onClose} fullWidth color="inherit" variant="contained">
            <Cancel style={{ marginRight: "20px" }} />
            Cancel
          </Button>
          <Button onClick={onSubmit} fullWidth variant="contained">
            <Save style={{ marginRight: "20px" }} />
            {submitButtonText ? submitButtonText : "Submit"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
