import { ApolloProvider } from "@apollo/react-hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { initializeApollo } from "../services_v2/apollo.graphql";

export const ApolloGlobalProvider: React.FC = ({ children }: any) => {
  const account = useSelector((state: any) => state?.account) as any;
  const [token, setToken] = useState<string | undefined | null>(account?.user?.token);

  const apolloClient = useMemo(() => initializeApollo(token), [token]);
  useEffect(() => {
    let tkn_in_storage = localStorage.getItem("accessTokenV2");
    let apollo_tkn =
      //@ts-ignore
      apolloClient?.link?.options?.headers?.Authorization?.split(" ")[1] as string;
    if (!!!account?.user?.token) {
      if (tkn_in_storage !== token) {
        setToken(localStorage.getItem("accessTokenV2"));
      }
    } else {
      if (account?.user?.token !== token) {
        setToken(account?.user?.token);
      }
    }
  }, [account?.user?.token]);
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
