import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import Save from "@material-ui/icons/Save";
import Cancel from "@material-ui/icons/CancelPresentationSharp";
import { notLoadingComponent } from "../../../actions/isLoadingActions";
import "./../styles.css";
import { Box, Card, CardContent, Container, Grid } from "@material-ui/core";
import swalert from "sweetalert";
import { MuiThemeProps } from "../../../theme/theme";
import AutocompleteWithQuery from "../../../components/Form/AutocompleteWithQuery";
import { queryValuesForFilter } from "../../../utils/ApolloClientUtils";
import {
  CreateOneCommercialVariety,
  CreateOneCommercialVarietyVariables,
  CursorPaging,
  MaterialTypeFilter,
  MaterialTypes,
  MaterialTypeSort,
  MaterialTypes_materialTypes_edges_node,
  Varieties,
  Varieties_varieties_edges_node,
  VarietyFilter,
  VarietySort,
} from "../../../data/graphQLModel";
import {
  MUTATION_CREATE_ONE_COMMERCIAL_VARIETY,
  QUERY_MATERIALTYPES,
  QUERY_VARIETIES,
} from "../../../data/graphQLQueries";
import { useMutation } from "@apollo/react-hooks";

const useStyles = makeStyles((theme: MuiThemeProps) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  form: {
    width: "80%",
  },
  margin: {
    width: "60ch",
    marginBottom: "4ch",
  },
  marginCode: {
    width: "40ch",
    marginBottom: "4ch",
  },
}));

export default function CreateCommercialVariety() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [systemVars, setSystemVars] = useState<Varieties_varieties_edges_node[]>([]);
  const [materialtypes, setMaterialtypes] = useState<MaterialTypes_materialTypes_edges_node[]>();

  const [form, setForm] = useState<{
    code: string;
    description: string;
    system_variety?: string;
    materialtype?: string;
    name: string;
  }>({
    code: "",
    description: "",
    system_variety: undefined,
    materialtype: undefined,
    name: "",
  });

  const [mutationCreateOneCommercialVariety] = useMutation<
    CreateOneCommercialVariety,
    CreateOneCommercialVarietyVariables
  >(MUTATION_CREATE_ONE_COMMERCIAL_VARIETY);

  function changeform(e: any) {
    const { name, value } = e.target;
    let newVal = !!value && typeof value === "string" && value?.trim() === "" ? undefined : value;
    setForm({ ...form, [name]: newVal });
  }

  async function submitForm(e: any) {
    e.preventDefault();

    if (
      form.code === "" ||
      form.code.length < 2 ||
      form.description === "" ||
      form.description.length < 5 ||
      form.materialtype === "" ||
      form.name === ""
    ) {
      swalert("Information!", "Missing information!", "warning");
      return;
    }

    // dispatch(loadingComponent());

    try {
      var res = await mutationCreateOneCommercialVariety({
        variables: {
          input: {
            commercialVariety: {
              code: form.code,
              materialtype: form.materialtype as string,
              description: form.description,
              name: form.name,
              system_variety: form.system_variety,
            },
          },
        },
      });
      if (res) swalert("Success.", "Variety created with success", "success");
    } catch (error: any) {
      dispatch(notLoadingComponent());
      swalert(
        "Failed.",
        typeof error === "string"
          ? error
          : typeof error?.message === "string"
          ? error?.message
          : "Erro",
        "error"
      );
      return;
    }
    // dispatch(notLoadingComponent());
    history.push("/customerstables/commvarieties");
  }

  function handleCancel(event: any) {
    event.preventDefault();
    history.push("/customerstables/commvarieties");
  }
  return (
    <div className="container">
      <Container style={{ marginTop: "70px" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Card>
            <CardContent>
              <h2 style={{ marginBottom: "-10px" }}>New Comm. Variety</h2>
            </CardContent>
          </Card>
        </Box>
        <hr />
        <Card>
          <CardContent>
            <form className={classes.form} onSubmit={submitForm} style={{ width: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <FormControl className={classes.margin} variant="outlined">
                    <TextField
                      id="code"
                      label="Code"
                      name="code"
                      placeholder="Code"
                      required
                      focused
                      onChange={changeform}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={form.code}
                    />
                  </FormControl>
                  <div>
                    <FormControl className={classes.margin} variant="outlined">
                      <TextField
                        id="name"
                        label="Name"
                        name="name"
                        onChange={changeform}
                        placeholder="Name"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={form.name}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl className={classes.margin} variant="outlined">
                      <TextField
                        id="description"
                        label="Description"
                        name="description"
                        onChange={changeform}
                        placeholder="Description"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        multiline
                        variant="outlined"
                        value={form.description}
                      />
                    </FormControl>
                  </div>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Grid container direction={"row"} style={{ marginTop: -8, flexDirection: "row" }}>
                    <Grid container item xs={12}>
                      <Grid key="mat-type-grid" item md={4} xs={12}>
                        <FormControl fullWidth>
                          <AutocompleteWithQuery
                            items={materialtypes || []}
                            label="Material Types*"
                            name="materialtype"
                            onChange={changeform}
                            title="Material Types"
                            value={form.materialtype}
                            onTextChange={async (value: string) => {
                              await queryValuesForFilter<
                                MaterialTypeFilter,
                                CursorPaging,
                                MaterialTypeSort,
                                MaterialTypes
                              >(
                                value,
                                QUERY_MATERIALTYPES,
                                (res) => {
                                  let endRes = res?.data?.materialTypes?.edges?.map(
                                    (el) => el.node
                                  );
                                  setMaterialtypes(endRes);
                                },
                                {
                                  filter: {
                                    or: [{ code: { iLike: value } }],
                                  },
                                  paging: {
                                    first: 30,
                                  },
                                }
                              );
                            }}
                            getField={(node: MaterialTypes_materialTypes_edges_node) => {
                              return `${node.code} - ${node?.description.pt || ""}`;
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid key="variety-grid" item md={4} xs={12}>
                        <FormControl variant="outlined">
                          <AutocompleteWithQuery
                            items={systemVars}
                            label="System Variety"
                            name="system_variety"
                            value={form.system_variety}
                            onChange={changeform}
                            title="System Variety"
                            onClear={() => {
                              changeform({ name: "system_variety", value: undefined });
                            }}
                            onTextChange={async (value: string) => {
                              await queryValuesForFilter<
                                VarietyFilter,
                                CursorPaging,
                                VarietySort,
                                Varieties
                              >(
                                value,
                                QUERY_VARIETIES,
                                (res) => {
                                  let endRes = res?.data?.varieties?.edges?.map((el) => el.node);
                                  setSystemVars(endRes);
                                },
                                {
                                  filter: {
                                    or: [
                                      { code: { iLike: value } },
                                      { normativedesig: { iLike: value } },
                                    ],
                                  },
                                  paging: { first: 20 },
                                }
                              );
                            }}
                            getField={(node: Varieties_varieties_edges_node) => {
                              return `${node.code} - ${node.normativedesig}`;
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} ml={1}>
                      <Grid container spacing={2} xs={12} style={{ marginTop: 2 }}>
                        {form.system_variety ? (
                          <>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="alternativedesig"
                                label="Alternative Design"
                                value={
                                  systemVars?.find((el) => el?.id === form.system_variety)
                                    ?.alternativedesig
                                }
                                placeholder="Alternative Design"
                                disabled
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="lithologicalclass"
                                label="Lithological Class"
                                value={
                                  systemVars?.find((el) => el?.id === form.system_variety)
                                    ?.lithologicalclass?.description
                                }
                                placeholder="Lithological Class"
                                disabled
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="districtquarry"
                                label="District Quary"
                                value={
                                  systemVars?.find((el) => el?.id === form.system_variety)
                                    ?.districtquarry
                                }
                                placeholder="District Quary"
                                disabled
                                variant="outlined"
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item md={6} xs={12}></Grid>
                            <Grid item md={6} xs={12}></Grid>
                            <Grid item md={6} xs={12}></Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <div className="btnform" style={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <FormControl fullWidth style={{ margin: "15px" }} variant="outlined">
                    <Button
                      type="submit"
                      onClick={handleCancel}
                      fullWidth
                      color="primary"
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Cancel
                      <Cancel style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined" style={{ margin: "15px" }}>
                    <Button
                      disabled={
                        form.code === "" ||
                        form.code.length < 2 ||
                        form.name === "" ||
                        form.description === "" ||
                        !!!form.code ||
                        !!!form.name ||
                        !!!form.description ||
                        !!!form.materialtype ||
                        form.materialtype === ""
                      }
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{ height: "50px", width: "200px", fontSize: "20px" }}
                    >
                      Create
                      <Save style={{ marginLeft: "20px" }} />
                    </Button>
                  </FormControl>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
