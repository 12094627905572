/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreVert";
import Account from "./account";
import Mobile from "./mobilecomponents";
import NavBar from "../../components/NavBar";
import WeScanLogo from "../../globalImages/Wescan_Logo Icone Home.png";
import FlexBox from "../../components/GeneralComponents/FlexBox";
import { useTheme } from "@material-ui/core";
import { H4 } from "../../components/GeneralComponents/Typography";

const useStyles = makeStyles((theme) => ({
  grow: {
    position: "fixed",
    zIndex: 1000,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
export default function Navigation({ isFixed, iam, isAuthenticated }) {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const account = useSelector((state) => state.account.user);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [personName, setPersonName] = React.useState([]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  return (
    <div
      className={classes.grow}
      style={{ display: isAuthenticated ? "block" : "none", width: "100%" }}
    >
      <AppBar position="sticky" color="inherit" elevation={0}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {isAuthenticated && (
            <FlexBox sx={{ flexDirection: "row", alignItems: "center" }}>
              <NavBar user={account} />
              <img
                src={WeScanLogo}
                alt="WeScan Logo"
                style={{
                  width: "5%",
                  height: "2%",
                  cursor: "pointer",
                  marginTop: "0.5rem",
                }}
                onClick={() => history.push("/")}
              />
              <NavLink to="/scanners" className={"nav-link"}>
                <H4
                  mt={1}
                  sx={{ ":hover": { color: theme.palette.primary.dark } }}
                  color={
                    location.pathname === "/scanners"
                      ? theme.palette.primary.main
                      : theme.palette.grey[600]
                  }
                >
                  Scanners
                </H4>
              </NavLink>
              <NavLink to="/catalog" className={"nav-link"}>
                <H4
                  ml={-2}
                  mt={1}
                  sx={{ ":hover": { color: theme.palette.primary.dark } }}
                  color={
                    location.pathname === "/catalog"
                      ? theme.palette.primary.main
                      : theme.palette.grey[600]
                  }
                >
                  Catalog
                </H4>
              </NavLink>
              <NavLink to="/gallery" className={"nav-link"}>
                <H4
                  mt={1}
                  ml={-2}
                  sx={{ ":hover": { color: theme.palette.primary.dark } }}
                  color={
                    location.pathname === "/gallery"
                      ? theme.palette.primary.main
                      : theme.palette.grey[600]
                  }
                >
                  Gallery
                </H4>
              </NavLink>
            </FlexBox>
          )}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* <Email/>
          <Notification/> */}
            <Account />
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Mobile />
    </div>
  );
}
