import { Avatar, Box, Card, CardContent, Typography } from "@material-ui/core";
import { UserToken } from "../../utils/Types";

const AccountProfile = (props: { user: UserToken }) => (
  <Card {...props}>
    <CardContent style={{ marginBottom: "7px" }}>
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginBottom: "15px",
          marginTop: "15px",
        }}
      >
        <Avatar
          src={props.user.avatar}
          style={{
            height: 120,
            width: 120,
            marginBottom: "15px",
          }}
        />
        <Typography color="textPrimary" gutterBottom variant="h4">
          {props.user.name}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

export default AccountProfile;
